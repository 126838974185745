import { ComponentProps } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { NullableIntegerField } from '../NullableIntegerField';

type IntegerFiledProps = ComponentProps<typeof NullableIntegerField>;
type Props = Omit<IntegerFiledProps, 'value' | 'defaultValue' | 'onChange' | 'onBlur' | 'error'> & {
  name: string;
  skipErrorText?: boolean;
};

export const RHFIntegerField: React.FC<Props> = ({
  name,
  helperText,
  fullWidth = true,
  skipErrorText = false,
  ...other
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <NullableIntegerField
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          fullWidth
          value={value}
          error={!!error}
          helperText={error && !skipErrorText ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
};
