import { Box, Stack } from '@mui/material';
import { ComponentProps, ReactElement, cloneElement } from 'react';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Box> | ComponentProps<typeof Stack>;

export type AtPageLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtPageLocator: React.FC<AtPageLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-page': name });
};
