// System
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Hooks
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Features
import { OperatorVehicleCard } from '../../OperatorVehicleCard/OperatorVehicleCard';

// Styles
import Styles from './SetupOperatorApproachDrawer.module.scss';

export const SetupOperatorApproachDrawer = () => {
  const {
    showApproachModal,
    approachedVehicle,
    handleDrawerCommand,
    handleSetShowVehicleDrawer,
    handleSetApproachedVehicle,
    handleApproachServiceStateUpdate,
  } = useOperatorVehicles();

  return (
    <SetupDrawer
      name="SetupOperatorApproach"
      open={showApproachModal}
      onClose={() => handleSetApproachedVehicle(false, null)}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorApproachDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Vehicle Details
          </Typography>
          <Box className={Styles.CloseIcon} onClick={() => handleSetApproachedVehicle(false, null)}>
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        {approachedVehicle && (
          <Box sx={{ mt: '24px' }}>
            <OperatorVehicleCard vehicleData={approachedVehicle} />
          </Box>
        )}
        <Box className={Styles.Footer}>
          <Button
            onClick={() => {
              handleSetShowVehicleDrawer(true);
              handleApproachServiceStateUpdate();
            }}
            className={Styles.ApproachCTA}
            variant="contained"
          >
            Approach
          </Button>
        </Box>
      </Box>
    </SetupDrawer>
  );
};
