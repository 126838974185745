import { SetupVehicleFormState } from '@Forms/vehicles/SetupVehicleFormContext';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { UpdateVehicleDto } from '@contracts/api/dto/vehicles/vehicles/UpdateVehicleDto';

export const useSetupVehicleUseCaseMapper = (vehicle: any) => {
  return {
    toFormState: async (
      vehicleId: string,
      brandId: string
    ): Promise<SetupVehicleFormState | undefined> => {
      if (!vehicle) {
        return undefined;
      }

      return {
        id: vehicle.id,
        name: vehicle.name ?? null,
        categoryId: vehicle.categoryId ?? null,
        licensePlate: vehicle.licensePlate,
        qrCode: vehicle.qrCode,
        vin: vehicle.vin,
        brandId,
        serviceState: vehicle.serviceState,
        iotStatus: vehicle.iotStatus,
        batteryLevel: vehicle.batteryLevel,
        powerStatus: vehicle.powerStatus,
        chargingStatus: vehicle.chargingStatus,
        stateOfCharge: vehicle.stateOfCharge,
        odometer: vehicle.odometer,
        lastSeenOnline: vehicle.lastSeenOnline ?? '',
        lastMovement: vehicle.lastMovement ?? '',
        iotImei: vehicle.iotImei,
        rentalStatus: vehicle.rentalStatus,
        category:
          {
            name: vehicle.category?.name ?? null,
            vehicleType: vehicle.category?.vehicleType ?? null,
            branch: {
              timezone: vehicle.category?.branch?.timezone ?? null,
            },
          } ?? null,
      };
    },
    toDto: (formState: SetupVehicleFormState): UpdateRequest<UpdateVehicleDto> => {
      return {
        id: formState.id,
        body: {
          categoryId: formState.categoryId ?? null,
          licensePlate: formState.licensePlate,
          name: formState.name ?? null,
          qrCode: formState.qrCode,
          vin: formState.vin,
        },
      };
    },
  };
};
