import { CSSProperties, ComponentProps } from 'react';
import {
  Avatar,
  Box,
  Stack,
  SelectChangeEvent,
  Theme,
  ButtonProps,
  useTheme,
  Typography,
} from '@mui/material';

import { Brand } from '@contracts/domain/Brand';
import { SystemStyleObject } from '@mui/system';

import { useButtonHover } from '@Components/shared/styles/useButtonHover';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { IconicButton } from '@Components/shared/buttons/IconicButton';

import { BrandSelect } from '../BrandSelect';

type Props = {
  width?: CSSProperties['width'];
  sx?: SystemStyleObject<Theme>;
  options: Brand[];
  value?: Brand['id'];
  onChange?: (event: SelectChangeEvent<Brand['id']>, child?: React.ReactNode) => void;
  onCreateClick?: () => void;
};

type InputComponentProps = ComponentProps<ComponentProps<typeof BrandSelect>['inputComponent']>;

const AvatarBrandInput: React.FC<InputComponentProps> = ({ value, onClick, sx, open }) => {
  const buttonProps: ButtonProps = { variant: 'contained', color: 'inherit' };
  const ellipsis = useEllipsis();
  const { customShadows } = useTheme();
  const hoverStyle = useButtonHover(buttonProps);

  const text = value?.name;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      width="100%"
      sx={[{ padding: 2, boxShadow: customShadows.card, borderRadius: 2 }, sx ?? {}]}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        width="100%"
      >
        <Avatar>{text?.substring(0, 1)}</Avatar>
        <Box sx={{ maxWidth: '370px', ...ellipsis }}>
          <AtLocator type="readonly-text" name="BrandName">
            <Typography variant="subtitle2">{text}</Typography>
          </AtLocator>
        </Box>
      </Stack>
      <AtLocator type="button" name="Expand">
        <IconicButton {...buttonProps} onClick={onClick} sx={{ ...(open && hoverStyle) }}>
          <AssetIcon src="chevron-down" size="s" />
        </IconicButton>
      </AtLocator>
    </Stack>
  );
};

export const AvatarBrandSelect: React.FC<Props> = ({ sx, ...rest }) => {
  return (
    <BrandSelect
      {...rest}
      sx={{ width: '100%', ...sx }}
      inputComponent={AvatarBrandInput}
      popupWidth="518px"
    />
  );
};
