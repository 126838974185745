import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';

import { SetupBranchHeader } from './SetupBranchHeader';
import { SetupBranchTabs } from './SetupBranchTabs';
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';

export const SetupBranchContent: React.FC = () => {
  const { handleDrawerCancel, submittable, handleSubmitBranchDrawer } = useBranchDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout onClose={handleDrawerCancel}>
          <SetupBranchHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={() => handleSubmitBranchDrawer()}
          saveDisabled={!submittable}
          onCancelClick={handleDrawerCancel}
        />
      }
    >
      <SetupBranchTabs />
    </TabDrawerLayout>
  );
};
