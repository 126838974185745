// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  getSelectedRole,
  setSelectedRole,
  setShowRoleDrawer,
  setShowDeleteRoleModalDialog,
  deleteSelectedRole,
} from '@Modal/reducers/roles/rolesDrawerSlice';

import { useSetupRoleForm, useManageSetupRoleSchema } from '@Forms/roles/SetupRoleFormContext';
import { useRolesTable } from './useRolesTable';

export const useRolesDrawer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { showSelectedRoleDrawer, selectedRoleData, showDeleteRoleModalDialog } = useSelector(
    (state: StoreInterface) => state?.roleDrawer
  );

  // Other
  const { enqueueSnackbar } = useSnackbar();
  const { fetchRolesTableContent } = useRolesTable();

  // Form Controls
  const { control, handleSubmit, reset } = useSetupRoleForm();
  const { setSchema } = useManageSetupRoleSchema();

  const { isDirty, errors } = useFormState({ control });

  const handleRoleRowClick = async (id: string) => {
    return dispatch(getSelectedRole(id))
      .unwrap()
      .then(() => dispatch(setShowRoleDrawer(true)))
      .catch((error: string) => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleRoleDelete = async (id: string) => {
    return dispatch(deleteSelectedRole(id))
      .unwrap()
      .then(() => {
        dispatch(setShowDeleteRoleModalDialog(false));
        dispatch(setShowRoleDrawer(false));
        fetchRolesTableContent();
        enqueueSnackbar('Role has been successfully deleted', {
          variant: 'success',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      })
      .catch((error: string) => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return {
    // State
    selectedRoleData,
    showSelectedRoleDrawer,
    showDeleteRoleModalDialog,

    // Actions
    setSelectedRole,
    handleRoleDelete,
    setShowRoleDrawer,
    handleRoleRowClick,
    setShowDeleteRoleModalDialog,
  };
};
