import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupRoundContent } from './SetupRoundContent';
import { useRoundDrawer } from '@Modal/hooks/rounds/useRoundDrawer';
import { useDispatch } from 'react-redux';

export const SetupRoundDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { showRoundDrawer, setShowRoundDrawer } = useRoundDrawer();
  return (
    <SetupDrawer
      name="SetupRound"
      open={showRoundDrawer}
      onClose={() => dispatch(setShowRoundDrawer(false))}
    >
      <SetupRoundContent />
    </SetupDrawer>
  );
};
