import { Stack } from '@mui/material';
import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';

// Styles
import Styles from './CreateRoundConfigContent.module.scss';

export const CreateRoundConfigContent = () => {
  return (
    <Stack className={Styles.CreateRoundConfigContent}>
      <RHFIntegerField name="bettingTime" label="Betting Time" />
      <RHFIntegerField name="waitingTime" label="Waiting Time" />
      <RHFIntegerField name="maxCrashPoint" label="Max Crash Point" />
      <RHFIntegerField name="margin" label="Margin" />
    </Stack>
  );
};
