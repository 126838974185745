import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export enum VehicleIotLinkOperation {
  link = 'link',
  ulink = 'unlink',
}

export type CreateVehicleIotLinkFormState = {
  operationType: VehicleIotLinkOperation;
  imei: string;
};

export const CreateVehicleIotLinkFormStateDefault: CreateVehicleIotLinkFormState = {
  operationType: VehicleIotLinkOperation.link,
  imei: '',
};

export const CreateVehicleIotLinkSchemaDefault: ObjectSchema<
  Partial<CreateVehicleIotLinkFormState>
> = object<CreateVehicleIotLinkFormState>().shape({
  operationType: string()
    .oneOf([VehicleIotLinkOperation.link, VehicleIotLinkOperation.ulink])
    .required(),
  imei: string().required(),
});

type CreateVehicleIotLinkFormContextType = {
  schema: ObjectSchema<Partial<CreateVehicleIotLinkFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateVehicleIotLinkFormState>>>;
  methods?: UseFormReturn<CreateVehicleIotLinkFormState>;
};

const createVehicleIotLinkFromContextDefault = {
  schema: CreateVehicleIotLinkSchemaDefault,
  setSchema: noop,
} satisfies CreateVehicleIotLinkFormContextType;

export const CreateVehicleIotLinkFormConext = createContext<CreateVehicleIotLinkFormContextType>(
  createVehicleIotLinkFromContextDefault
);

export const useCreateVehicleIotLinkForm = () => {
  const { methods } = useContext(CreateVehicleIotLinkFormConext);
  if (!methods) {
    throw new Error('CreateVehicleIotLinkForm is empty');
  }

  return methods;
};

export const useManageCreateVehicleIotLinkSchema = () => {
  const { setSchema } = useContext(CreateVehicleIotLinkFormConext);

  return { setSchema };
};
