// Components
import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';

// Hooks
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

export const SetupMapZoneCancelModalDialog: React.FC = () => {
  const { showCancelMapZoneDialog, handleCancelMapZoneModal } = useBranchesMaps();
  return (
    <>
      {showCancelMapZoneDialog && (
        <CancelModal open={showCancelMapZoneDialog}>
          <ImageCancelDialogLayout
            title="Cancel Map changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelMapZoneModal('cancel')}
                onConfirm={() => handleCancelMapZoneModal('confirm')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in map zones
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
