import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

// System
import { Stack } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { BalanceCardSelect } from './BalanceCardSelect';

// Redux
import { useCustomerBalance } from '@Modal/hooks/customers/balance/useCustomerBalance';
import { GroupedTransactions } from './GroupedTransactions';

// Types
import { SelectedCustomerBalanceTransactionsType } from '@Modal/reducers/customers/types';
type KeyMap = {
  [key: string]: string | string[];
};

export const Balances = () => {
  const methods = useForm();
  const { setValue } = useForm();
  const {
    fetchCustomerWallets,
    customerWallets,
    groupTransactionsByDateCategory,
    fetchSelectedCustomerBalanceTransactions,
  } = useCustomerBalance();

  const groupedTransactions = groupTransactionsByDateCategory();

  const fetchCustomerWalletsHandler = async () => {
    await fetchCustomerWallets();
    await fetchSelectedCustomerBalanceTransactions();
  };

  const categorizeKeys = (groupedTransactions: SelectedCustomerBalanceTransactionsType) => {
    const keyMap: KeyMap = {};

    for (const key in groupedTransactions) {
      if (groupedTransactions.hasOwnProperty(key)) {
        if (['Today', 'Yesterday', 'This Month'].includes(key)) {
          keyMap[key] = key;
        } else if (/^\w+,\s\d{4}$/.test(key)) {
          keyMap[key] = keyMap[key] || [];
          // @ts-ignore
          keyMap[key].push(key);
        }
      }
    }

    return keyMap;
  };

  // Function to render transactions
  const renderTransactions = (
    groupedTransactions: SelectedCustomerBalanceTransactionsType,
    keyMap: KeyMap
  ) => {
    const renderedTransactions = [];
    const isDate =
      /^(January|February|March|April|May|June|July|August|September|October|November|December), \d{4}$/;

    for (const key in keyMap) {
      if (keyMap?.hasOwnProperty(key)) {
        if (isDate.test(key)) {
          // @ts-ignore
          keyMap[key].forEach((mmYYKey: string) => {
            renderedTransactions.push(
              <GroupedTransactions
                key={mmYYKey}
                dateCategory={mmYYKey}
                transactions={groupedTransactions[mmYYKey]}
              />
            );
          });
        } else {
          renderedTransactions.push(
            <GroupedTransactions
              key={key}
              dateCategory={key}
              transactions={groupedTransactions[key]}
            />
          );
        }
      }
    }
    return renderedTransactions;
  };

  // Usage:
  const keyMap = categorizeKeys(groupedTransactions);
  const renderedTransactions = renderTransactions(groupedTransactions, keyMap);

  useEffect(() => {
    fetchCustomerWalletsHandler();
  }, []);

  useEffect(() => {
    const wallets = customerWallets ?? [];
    if (wallets?.length > 0) {
      setValue('balanceCardSelect', wallets[0].id);
    }
  }, [customerWallets, setValue]);

  return (
    <Stack spacing={3} sx={{ padding: '24px' }}>
      <FormProvider {...methods}>
        {customerWallets && customerWallets.length > 0 ? (
          <BalanceCardSelect />
        ) : (
          <Stack justifyContent="center" alignItems="center">
            <AssetIcon src="rental-loading" sx={{ width: '20px', height: '20px' }} />
          </Stack>
        )}
        {renderTransactions.length > 0 && renderedTransactions}
      </FormProvider>
    </Stack>
  );
};
