import { ComponentProps, RefAttributes, ReactElement } from 'react';
import { Button, SxProps, Theme } from '@mui/material';

type Props = Omit<ComponentProps<typeof Button>, 'endIcon' | 'startIcon' | 'children'> & {
  children: ReactElement<{ sx: SxProps<Theme> }>;
};

export const IconicButton: React.FC<RefAttributes<HTMLButtonElement> & Props> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      variant="contained"
      {...rest}
      sx={{
        minWidth: '26px',
        minHeight: '26px',
        paddingLeft: 0,
        paddingRight: 0,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
