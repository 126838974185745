// System
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useVehicleNotes } from '@Modal/hooks/vehicles/notes/useVehicleNotes';

// Styles
import Styles from './RemoveVehicleNoteModalDialog.module.scss';

export const RemoveVehicleNoteModalDialog = () => {
  const { showDeleteVehicleNote, handleCreateCancel, deleteVehicleNoteHandler } = useVehicleNotes();

  return (
    <ClosableModal
      name="DeleteNote"
      title="Delete note"
      open={showDeleteVehicleNote}
      onClose={handleCreateCancel}
      className={Styles.RemoveVehicleNoteModalDialog}
    >
      <CreateDialogLayout>
        <Typography variant="body1">You are about to delete this note permanently</Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={deleteVehicleNoteHandler}
            >
              Delete
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
