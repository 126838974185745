import { useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import {
  setLimit,
  setPage,
  setTotal,
  setColumnVisibility,
  setProviderTransactionTableFilters,
} from '@Modal/reducers/providerTransactions/providerTransactionTableSlice';

// Types
import { StoreInterface } from 'src/app/types';
import { FilterItemType } from '@Modal/reducers/types';

export const useProviderTransactionsTable = () => {
  const dispatch = useDispatch();

  const {
    page,
    limit,
    total,
    selectedFilters,
    columnVisibility,
    columnVisibilityOptions,
    providerTransactionTableFilters,
  } = useSelector((state: StoreInterface) => state?.providerTransactionTable);

  const providerTransactionsListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);
    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...providerTransactionsListFilters, ...column];
    if (providerTransactionsListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = providerTransactionsListFilters.filter(
        (col) => col !== column[0]
      );
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setProviderTransactionTableFilters(filter));
  };

  return {
    // State
    page,
    total,
    limit,
    selectedFilters,
    columnVisibility,
    visiblityCustomizable,
    columnVisibilityOptions,
    providerTransactionsListFilters,
    providerTransactionTableFilters,
    // Actions
    setLimit,
    setPage,
    setTotal,
    handleFilterSelect,
    handleApplyColumnVisibility,
    setProviderTransactionTableFilters,
  };
};
