// System
import { Stack, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const TransactionTitle: React.FC = () => {
  return (
    <AtLocator type="container" name="BranchTitle">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <AtLocator type="readonly-text" name="Title">
          <Typography variant="h3">Round Transactions</Typography>
        </AtLocator>
      </Stack>
    </AtLocator>
  );
};
