import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export type CreateVehicleFormState = {
  name?: string;
  licensePlate?: string;
  qrCode: string;
  vin?: string;
};

export const createVehicleFormStateDefault: CreateVehicleFormState = {
  name: '',
  licensePlate: '',
  qrCode: '',
  vin: '',
};

export const createVehicleSchemaDefault: ObjectSchema<Partial<CreateVehicleFormState>> = object<
  Partial<CreateVehicleFormState>
>().shape({
  name: string(),
  licensePlate: string(),
  qrCode: string().required(),
  vin: string(),
});

type CreateVehicleFormContextType = {
  schema: ObjectSchema<Partial<CreateVehicleFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateVehicleFormState>>>;
  methods?: UseFormReturn<CreateVehicleFormState>;
};

const createVehicleFormContextDefault = {
  schema: createVehicleSchemaDefault,
  setSchema: noop,
} satisfies CreateVehicleFormContextType;

export const CreateVehicleFormContext = createContext<CreateVehicleFormContextType>(
  createVehicleFormContextDefault
);

export const useCreateVehicleForm = () => {
  const { methods } = useContext(CreateVehicleFormContext);
  if (!methods) {
    throw new Error('CreateVehicleFormContext is empty');
  }

  return methods;
};

export const useManageCreateVehicleSchema = () => {
  const { setSchema } = useContext(CreateVehicleFormContext);

  return { setSchema };
};
