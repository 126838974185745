// System
import { Button, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useCreateAviatorUser } from '@Modal/hooks/users/useCreateUser';

export const UserTitle: React.FC = () => {
  const dispatch = useDispatch();
  const { setShowCreateUserForm } = useCreateAviatorUser();

  return (
    <AtLocator type="container" name="BranchTitle">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '4px 0' }}
      >
        <AtLocator type="readonly-text" name="Title">
          <Typography variant="h3">Users</Typography>
        </AtLocator>
        <Button
          variant="contained"
          onClick={() => dispatch(setShowCreateUserForm(true))}
          size="large"
          sx={{ paddingY: '2px', height: '45px' }}
        >
          Create User
        </Button>
      </Stack>
    </AtLocator>
  );
};
