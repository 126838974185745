// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useCustomersDrawer } from '../useCustomerDrawer';
import { useCustomerRentals } from '../rentals/useCustomerRentals';

// Actions
import {
  setShowSignOutCustomerModalDialog,
  setForceSignOut,
  signOutCustomerRequest,
} from '@Modal/reducers/customers/signOutCustomer/signOutCustomerSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

export const useSignOutCustomer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { customerRentals } = useCustomerRentals();

  const { showSignOutCustomerModalDialog, forceSignOut } = useSelector(
    (state: StoreInterface) => state?.signOutCustomer
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCustomerData } = useCustomersDrawer();

  const handleSignOutCustomerRequest = () => {
    dispatch(signOutCustomerRequest(selectedCustomerData.id))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Customer successfully signed out.', {
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleSignOutCustomerModalDialog = (arg: boolean) => {
    dispatch(setShowSignOutCustomerModalDialog(arg));
  };

  return {
    // Store
    forceSignOut,
    showSignOutCustomerModalDialog,
    submittable:
      customerRentals.length === 0 ||
      (customerRentals.length > 0 && customerRentals[0]?.state === 'ENDED') ||
      forceSignOut,
    showWarning: customerRentals?.length > 0 && customerRentals[0]?.state !== 'ENDED',

    // Actions
    setForceSignOut,
    handleSignOutCustomerRequest,
    setShowSignOutCustomerModalDialog,
    handleSignOutCustomerModalDialog,
  };
};
