import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { defaultOperatorListFilter } from '@utils/tables/operator/operatorListFilter';

// Type
const initialState = {
  selectedFilters: defaultOperatorListFilter,
  showOperatorFiltersDrawer: false,
};

const operatorFiltersSlice = createSlice({
  name: 'operatorFiltersSlice',
  initialState,
  reducers: {
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultOperatorListFilter;
    },
    setShowOperatorFiltersDrawer: (state, { payload }) => {
      state.showOperatorFiltersDrawer = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: operatorFiltersReducer } = operatorFiltersSlice;
export const { setSelectedFilters, resetSelectedFilters, setShowOperatorFiltersDrawer } =
  operatorFiltersSlice.actions;
