import { SetupReplaceQRFormStateDTO } from '@Forms/operator/SetupReplaceQRFormContext';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';

export const useSetupReplaceQRUseCaseMapper = (vehicle: any) => {
  return {
    toFormState: async (): Promise<SetupReplaceQRFormStateDTO | undefined> => {
      if (!vehicle) {
        return undefined;
      }

      return {
        qrCode: vehicle.iotImei,
      };
    },
    toDto: (formState: SetupReplaceQRFormStateDTO): UpdateRequest<SetupReplaceQRFormStateDTO> => {
      return {
        // @ts-ignore
        qrCode: formState.iotImei,
      };
    },
  };
};
