import { Box, Stack, Typography } from '@mui/material';
import { RHFTextField } from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';

// Styles
import Styles from './CreateBrandContent.module.scss';

export const CreateBrandContent: React.FC = () => {
  return (
    <Stack className={Styles.CreateBrandContent}>
      <AtLocator key="name" type="text-field" name="name">
        <RHFTextField label="Brand name" variant="outlined" name="name" id="create-brand-name" />
      </AtLocator>
      <Box className={Styles.Box}>
        <AtLocator key="name description" type="readonly-text" name="NameDescription">
          <Typography variant="caption" className={Styles.Text}>
            Please create brand, before creating country branch
          </Typography>
        </AtLocator>
      </Box>
    </Stack>
  );
};
