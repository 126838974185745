// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { useMemo, BaseSyntheticEvent, useCallback } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useCustomersDrawer } from '../useCustomerDrawer';
import { deleteCustomerRequest } from '@Modal/reducers/customers/deleteCustomer/customerDeleteSlice';
import { useDeleteCustomerForm } from '@Forms/customers/deleteCustomer/DeleteCustomerNoteFormContext';
import { useCustomerRentals } from '../rentals/useCustomerRentals';

// Actions
import {
  setShowDeleteCustomerModalDialog,
  setForceDelete,
} from '@Modal/reducers/customers/deleteCustomer/customerDeleteSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useCustomerBalance } from '../balance/useCustomerBalance';
import Cookies from 'js-cookie';

export const useDeleteCustomer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { customerRentals } = useCustomerRentals();
  const { selectedWallet } = useCustomerBalance();

  const { showDeleteCustomerModalDialog, forceDelete } = useSelector(
    (state: StoreInterface) => state?.deleteCustomer
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } = useDeleteCustomerForm();
  const { isDirty } = useFormState({ control });

  const { selectedCustomerData } = useCustomersDrawer();
  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  const handleDeleteCustomerRequest = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          deleteCustomerRequest({
            id: selectedCustomerData.id,
            newNote: { ...formData, authorId: currentUser?.id },
          })
        )
          .unwrap()
          .then(() => {
            reset();
            enqueueSnackbar('Customer was successfully deleted', {
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    [selectedCustomerData]
  );

  const handleDeleteCustomerModalDialog = (arg: boolean) => {
    reset();
    dispatch(setShowDeleteCustomerModalDialog(arg));
  };

  return {
    // Store
    forceDelete,
    showDeleteCustomerModalDialog,
    submittable:
      (customerRentals.length === 0 && isDirty && Number(selectedWallet.balance) >= 0) ||
      (customerRentals.length > 0 &&
        isDirty &&
        customerRentals[0]?.state === 'ENDED' &&
        Number(selectedWallet.balance) >= 0) ||
      forceDelete,
    showWarning:
      (customerRentals?.length > 0 &&
        (Number(selectedWallet.balance) < 0 || customerRentals[0]?.state !== 'ENDED')) ||
      (customerRentals.length === 0 && Number(selectedWallet.balance) < 0),
    // Actions
    setForceDelete,
    handleDeleteCustomerRequest,
    setShowDeleteCustomerModalDialog,
    handleDeleteCustomerModalDialog,
  };
};
