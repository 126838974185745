// System
import { useDispatch } from 'react-redux';
import { Polygon } from '@react-google-maps/api';

// Contracts
import { MapZoneType } from '@contracts/domain/keys';
import { EditStatus } from '@contracts/common/EditStatus';

// Types
import { AppDispatch } from 'src/app/store';
import { mapZoneTypeColors } from '../../shared/MapZoneType/useMapZoneType';

// Hooks
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  holeColor: string;
  fillOpacity: number;
};

const PolygonView: React.FC<PolygonProps> = ({
  targetMapZoneType,
  strokeColor,
  fillColor,
  holeColor,
  fillOpacity,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    mapPaths,
    setSelectedMapZoneId,
    selectedMapZoneIndex,
    setSelectedMapZoneIndex,
    handleUpdateMapZone,
    handleUpdateMapPathsStatus,
  } = useBranchesMaps();

  const filteredMapPathes = mapPaths
    .map((zone, index) => [zone, index] as const)
    .filter(
      ([zone]) => zone.mapZoneType === targetMapZoneType && zone.editStatus !== EditStatus.Deleted
    );

  return (
    <>
      {filteredMapPathes.map(([mapPath, index]) => (
        <Polygon
          key={index}
          paths={mapPath.path}
          options={{
            ...polygonOptions,
            draggable: index === selectedMapZoneIndex,
            editable: index === selectedMapZoneIndex,
            fillColor: mapPath.type === 'ring' ? fillColor : holeColor,
            strokeColor,
            fillOpacity: fillOpacity + (index === selectedMapZoneIndex ? 0.4 : 0),
          }}
          onClick={() => {
            dispatch(setSelectedMapZoneIndex(index));
            dispatch(setSelectedMapZoneId(mapPath.id));
            handleUpdateMapPathsStatus(mapPath.id);
          }}
          onUnmount={handleUpdateMapZone(index)}
        />
      ))}
    </>
  );
};

export const MapEditMode: React.FC = () => {
  return (
    <>
      {mapZoneTypeColors.map((zoneType) => (
        <PolygonView
          key={zoneType.type}
          targetMapZoneType={zoneType.type}
          strokeColor={zoneType.color}
          fillColor={zoneType.fillColor ?? zoneType.color}
          holeColor={zoneType.holeColor ?? zoneType.color}
          fillOpacity={zoneType.opacity}
        />
      ))}
    </>
  );
};
