import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useAddUser } from '@Modal/hooks/roles/useAddUser';
import { useDispatch } from 'react-redux';

export const CreateAddUserCancelModalDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { showCancelAddUserModalDialog, setShowCancelAddUserModalDialog, handleCancelTakeMeBack } =
    useAddUser();

  return (
    <>
      {showCancelAddUserModalDialog && (
        <CancelModal open={showCancelAddUserModalDialog}>
          <ImageCancelDialogLayout
            title="Cancel add user changes?"
            actions={
              <CancelDialogActions
                onReject={() => dispatch(setShowCancelAddUserModalDialog(false))}
                onConfirm={() => handleCancelTakeMeBack()}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in add user settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
