import { Dispatch, createContext } from 'react';

import { noop } from '@utils/noop';
import { VehicleCategoryItemDto } from '@contracts/api/dto/vehicles/categories/VehicleCategoryItemDto';

import { SetupVehicleUseCaseStatus } from './SetupVehicleUseCaseStatus';

type SetupVehicleUseCaseStatusContextType = {
  status: SetupVehicleUseCaseStatus;
  setStatus: Dispatch<SetupVehicleUseCaseStatus>;
  open: boolean;
  setOpen: Dispatch<boolean>;
  categoryList: VehicleCategoryItemDto[] | undefined;
  setCategoryList: Dispatch<VehicleCategoryItemDto[] | undefined>;
};

export type SetupVehicleDialogParams = {
  vehicleId: string;
  brandId: string;
};

const setupBranchUseCaseStatusContextDefault: SetupVehicleUseCaseStatusContextType = {
  status: SetupVehicleUseCaseStatus.Initial,
  setStatus: noop,
  open: false,
  setOpen: noop,
  categoryList: undefined,
  setCategoryList: noop,
};

export const SetupVehicleUseCaseStatusContext = createContext<SetupVehicleUseCaseStatusContextType>(
  setupBranchUseCaseStatusContextDefault
);
