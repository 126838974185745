// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Types
import { StoreInterface } from 'src/app/types';
import { Roles } from '@Pages/roles.enum';

// Components
import { ICONS } from '@Components/layouts/dashboard/nav/config-navigation';
import CampaignIcon from '@mui/icons-material/Campaign';
import { BranchPage } from '@Pages/BranchPage';
import { MapPage } from '@Pages/MapPage';
import { PricingPage } from '@Pages/PricingPage';
import { RolesPage } from '@Pages/RolesPage';
// import { TransactionsPage } from '@Pages/TransactionsPage';
import { TransactionPage } from '@Pages/TransactionPage/TransactionPage';
import CustomerRoute from '@Routes/CustomerRoute';
import VehicleRoute from '@Routes/VehicleRoute';
import Cookies from 'js-cookie';
import { OperatorPage } from '@Pages/OperatorPage';
import { ProvidersRoute } from '@Routes/ProvidersRoute/ProvidersRoute';
import { RoundsRoute } from '@Routes/RoundsRoute';
import { CampaignsPage } from '@Pages/Campaigns';
import { AnalyticsPage } from '@Pages/AnalyticPage';

export const useRoles = () => {
  const { currentUser } = useSelector((state: StoreInterface) => state.authorization);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  const allRoutes = [
    // {
    //   path: '/map',
    //   component: MapPage,
    //   roles: [
    //     Roles.SuperAdmin,
    //     Roles.Admin,
    //     Roles.FleetManager,
    //     Roles.Customer,
    //     Roles.CustomerSupportL1,
    //     Roles.CustomerSupportL2,
    //     Roles.DataAnalyst,
    //     Roles.Accountant,
    //     Roles.MarketingManager,
    //   ],
    //   title: 'Map',
    //   icon: ICONS.map,
    // },
    // {
    //   path: '/customers',
    //   component: CustomerRoute,
    //   roles: [
    //     Roles.SuperAdmin,
    //     Roles.Admin,
    //     Roles.CustomerSupportL1,
    //     Roles.CustomerSupportL2,
    //     Roles.DataAnalyst,
    //     Roles.MarketingManager,
    //   ],
    //   title: 'Customers',
    //   icon: ICONS.user,
    // },
    // {
    //   path: '/vehicles',
    //   component: VehicleRoute,
    //   roles: [
    //     Roles.SuperAdmin,
    //     Roles.Admin,
    //     Roles.FleetManager,
    //     Roles.CustomerSupportL1,
    //     Roles.CustomerSupportL2,
    //   ],
    //   title: 'Vehicles',
    //   icon: ICONS.car,
    // },
    // {
    //   path: '/rentals',
    //   component: 'RentalsPage',
    //   roles: [
    //     Roles.SuperAdmin,
    //     Roles.Admin,
    //     Roles.FleetManager,
    //     Roles.CustomerSupportL1,
    //     Roles.CustomerSupportL2,
    //     Roles.DataAnalyst,
    //     Roles.MarketingManager,
    //   ],
    //   title: 'Rentals',
    //   icon: ICONS.rentals,
    // },
    // {
    //   path: '/branches',
    //   component: BranchPage,
    //   roles: [Roles.SuperAdmin, Roles.Admin],
    //   title: 'Branch',
    //   icon: ICONS.branch,
    // },
    // {
    //   path: '/pricing',
    //   component: PricingPage,
    //   roles: [
    //     Roles.SuperAdmin,
    //     Roles.Admin,
    //     Roles.CustomerSupportL1,
    //     Roles.CustomerSupportL2,
    //     Roles.DataAnalyst,
    //     Roles.Accountant,
    //     Roles.MarketingManager,
    //   ],
    //   title: 'Pricing',
    //   icon: ICONS.pricing,
    // },
    // {
    //   path: '/transactions',
    //   component: TransactionsPage,
    //   roles: [
    //     Roles.SuperAdmin,
    //     Roles.CustomerSupportL1,
    //     Roles.CustomerSupportL2,
    //     Roles.DataAnalyst,
    //     Roles.Accountant,
    //   ],
    //   title: 'Transactions',
    //   icon: ICONS.navTransactions,
    // },
    // {
    //   path: '/operator',
    //   title: 'Operator',
    //   component: OperatorPage,
    //   roles: [Roles.FleetOperator, Roles.SuperAdmin, Roles.FleetMechanic],
    //   icon: ICONS.roles,
    // },
    // {
    //   path: '/roles',
    //   component: RolesPage,
    //   roles: [Roles.SuperAdmin],
    //   title: 'Roles',
    //   icon: ICONS.roles,
    // },
    // providers
    {
      path: '/providers',
      component: ProvidersRoute,
      roles: [
        Roles.SuperAdmin,
        Roles.Admin,
        Roles.CustomerSupportL1,
        Roles.CustomerSupportL2,
        Roles.DataAnalyst,
        Roles.MarketingManager,
      ],
      title: 'Providers',
      icon: ICONS.user,
    },
    {
      path: '/rounds',
      component: RoundsRoute,
      roles: [
        Roles.SuperAdmin,
        Roles.Admin,
        Roles.CustomerSupportL1,
        Roles.CustomerSupportL2,
        Roles.DataAnalyst,
        Roles.MarketingManager,
      ],
      title: 'Rounds',
      icon: ICONS.pricing,
    },
    {
      path: '/transactions',
      component: TransactionPage,
      roles: [
        Roles.SuperAdmin,
        Roles.CustomerSupportL1,
        Roles.CustomerSupportL2,
        Roles.DataAnalyst,
        Roles.Accountant,
      ],
      title: 'Transactions',
      icon: ICONS.navTransactions,
    },
    // {
    //   path: '/roles',
    //   component: RolesPage,
    //   roles: [Roles.SuperAdmin],
    //   title: 'Roles',
    //   icon: ICONS.roles,
    // },
    {
      path: '/users',
      component: RolesPage,
      roles: [Roles.SuperAdmin],
      title: 'Users',
      icon: ICONS.roles,
    },
    {
      path: '/campaigns',
      component: RolesPage,
      roles: [Roles.SuperAdmin],
      title: 'Campaigns',
      icon: ICONS.rentals,
    },
    {
      path: '/analytics',
      component: AnalyticsPage,
      roles: [Roles.SuperAdmin],
      title: 'Analytics',
      icon: ICONS.analytics,
    },
  ];

  useEffect(() => {
    if (currentUser?.role) {
      setLoading(true);
      const filteredRoutes = allRoutes.filter((route) => route.roles.includes(currentUser?.role));
      // @ts-ignore
      setAvailableRoutes(filteredRoutes);

      Cookies.set('AvailableRoutes', JSON.stringify(filteredRoutes), {
        expires: 1,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    loading,
    allRoutes,
    availableRoutes,
    setAvailableRoutes,
  };
};
