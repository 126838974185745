import { Button, Stack, Divider, Theme, Box } from '@mui/material';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { FormProvider } from 'react-hook-form';
import { useSetupCustomerForm } from '@Forms/customers/SetupCustomerFormContext';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { SystemStyleObject } from '@mui/system';
import { useEffect } from 'react';
import { RentalsNoteCard } from './RentalsNoteCard';
import { useRentalsNote } from '@Modal/hooks/rentals/useRentalsNote';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';
import { useSnackbar } from 'notistack';

const useSx = () => {
  return {
    button: {
      width: '95px',
      marginY: 2,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const RentalsNotes: React.FC = () => {
  const methods = useSetupCustomerForm();
  const style = useSx();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRental } = useRentalsDrawer();
  const { setShowCreateRentalNoteForm, getRentalNotes, rentalNotes } = useRentalsNote();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getRentalNotes(selectedRental.id))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error?.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, []);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setShowCreateRentalNoteForm(true));
            }}
            sx={style.button}
          >
            Add note
          </Button>
          <Divider />
          {rentalNotes.length === 0 ? (
            <Box
              sx={{
                width: '100%',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                fontWeight: '600',
              }}
            >
              No Results
            </Box>
          ) : (
            <Stack spacing={2}>
              {(rentalNotes ?? []).map((item) => {
                return (
                  <RentalsNoteCard
                    text={item.note}
                    id={item.id}
                    customersId={item.rentalId}
                    createdAt={item.createdAt}
                  />
                );
              })}
            </Stack>
          )}
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
