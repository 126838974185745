import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateVehicleNoteFormContext,
  CreateVehicleNoteFormState,
  CreateVehicleNoteSchemaDefault,
  CreateVehicleNoteFormStateDefault,
} from '@Forms/vehicles/CreateVehicleNoteFormContext';

export const CreateVehicleNoteFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateVehicleNoteFormState>>>(
    CreateVehicleNoteSchemaDefault
  );
  const methods = useForm({
    defaultValues: CreateVehicleNoteFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateVehicleNoteFormContext.Provider value={value}>
      {children}
    </CreateVehicleNoteFormContext.Provider>
  );
};
