import { SetupCustomerFormState } from '@Forms/customers/SetupCustomerFormContext';
import { UpdateCustomerDto } from '@contracts/api/dto/customers/UpdateCustomerDto';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { getLocalISODate } from '@utils/data/date/getLocalISODate';
import { CustomerItemType } from '@Modal/reducers/customers/types';

export const useSetupCustomerUseCaseMapper = (customer: CustomerItemType | null) => {
  return {
    toFormState: async (customerId: string): Promise<SetupCustomerFormState | undefined> => {
      if (!customer) {
        return undefined;
      }

      return {
        // @ts-ignore
        accountDetails:
          customer?.accountDetails?.map((accountDetails) => ({
            createdAt: accountDetails?.createdAt,
            firstName: accountDetails?.firstName,
            id: accountDetails?.id,
            lastName: accountDetails?.lastName,
            personalId: accountDetails?.personalId,
            secondaryEmail: accountDetails?.secondaryEmail,
            secondaryPhone: accountDetails?.secondaryPhone,
            updatedAt: accountDetails?.updatedAt,
            username: accountDetails?.username,
          })) || [],
        createdAt: customer?.createdAt,
        customer: {
          accountId: customer?.customer?.accountId,
          brandId: customer?.customer?.brandId,
          createdAt: customer?.customer?.createdAt,
          deletedAt: customer?.customer?.deletedAt,
          id: customer?.customer?.id,
          newsletter: customer?.customer?.newsletter,
          status: customer?.customer?.status,
          updatedAt: customer?.customer?.updatedAt,
          userAppLanguage: customer?.customer?.userAppLanguage,
        },
        email: customer?.email,
        id: customer?.id,
        phone: customer?.phone,
        // sourceProjectId: customer?.sourceProjectId,
      };
    },
    toDto: (formState: SetupCustomerFormState): UpdateRequest<Partial<UpdateCustomerDto>> => ({
      id: formState?.id,
      body: {
        accountDetails: formState?.accountDetails?.map((details) => ({
          attributes: details?.attributes,
          createdAt: details?.createdAt,
          firstName: details?.firstName,
          lastName: details?.lastName,
          personalId: details?.personalId,
          secondaryEmail: details?.secondaryEmail,
          secondaryPhone: details?.secondaryPhone,
          updatedAt: details?.updatedAt,
          username: details?.username,
        })),
        createdAt: formState?.createdAt,
        customer: {
          accountId: formState?.customer?.accountId,
          brandId: formState?.customer?.brandId,
          createdAt: formState?.customer?.createdAt,
          deletedAt: formState?.customer?.deletedAt,
          id: formState?.customer?.id,
          newsletter: formState?.customer?.newsletter,
          status: formState?.customer?.status,
          updatedAt: formState?.customer?.updatedAt,
          userAppLanguage: formState?.customer?.userAppLanguage,
        },
        email: formState?.email,
        phone: formState?.phone,
        sourceProjectId: formState?.sourceProjectId,
      },
    }),
  };
};
