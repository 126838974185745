// System
import { Box, Stack, Typography } from '@mui/material';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { RentalEventIcon } from './RentalEventIcon';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Types
import { EventCardProps } from '@Modal/reducers/rentals/types';

// Styles
import Styles from './EventCard.module.scss';

export const EventCard: React.FC<EventCardProps> = ({
  command,
  initiator,
  activeZones,
  createdAt,
}) => {
  const convertActiveZoneArrToStr = activeZones ? activeZones.join(', ') : activeZones;

  return (
    <Stack className={Styles.EventCard}>
      <Stack className={Styles.EventCardContainer}>
        <Stack className={Styles.IconAndTitleContainer}>
          <RentalEventIcon event={command} />
          <Stack className={Styles.ValueLabelContainer}>
            <Typography className={Styles.ValueFont}>Rental {command}</Typography>
            <Typography className={Styles.LabelFont}>
              <DateLabel date={new Date(createdAt)} format="long" />
            </Typography>
          </Stack>
        </Stack>
        <Stack className={Styles.ValueLabelContainer}>
          <Typography className={Styles.ValueFont}>{initiator}</Typography>
          <Typography className={Styles.LabelFont}>Initiator</Typography>
        </Stack>
        {activeZones !== null ? (
          <Stack className={Styles.ZoneContainer}>
            <Typography className={Styles.ValueFont}>{convertActiveZoneArrToStr}</Typography>
            <Typography className={Styles.LabelFont}>Zone</Typography>
          </Stack>
        ) : (
          <Stack className={Styles.ZoneContainer}></Stack>
        )}
      </Stack>
      <Box className={Styles.EventDividerContainer}>
        {command !== 'stop' && command !== 'feedback' ? (
          <AssetIcon src="rental-event-divider" />
        ) : (
          ''
        )}
      </Box>
    </Stack>
  );
};
