import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export type CreateVehicleNoteFormState = {
  note: string;
};

export const CreateVehicleNoteFormStateDefault: CreateVehicleNoteFormState = {
  note: '',
};

export const CreateVehicleNoteSchemaDefault: ObjectSchema<Partial<CreateVehicleNoteFormState>> =
  object<Partial<CreateVehicleNoteFormState>>().shape({
    note: string()
      .required('Note is required')
      .test('min-characters', 'Note must be at least 2 non-space characters long', (value) => {
        const nonSpaceChars = value.replace(/\s/g, '').length;
        return nonSpaceChars >= 2;
      }),
  });


type CreateVehicleNoteFormContextType = {
  schema: ObjectSchema<Partial<CreateVehicleNoteFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateVehicleNoteFormState>>>;
  methods?: UseFormReturn<CreateVehicleNoteFormState>;
};

const createVehicleNoteFormContextDefault = {
  schema: CreateVehicleNoteSchemaDefault,
  setSchema: noop,
} satisfies CreateVehicleNoteFormContextType;

export const CreateVehicleNoteFormContext = createContext<CreateVehicleNoteFormContextType>(
  createVehicleNoteFormContextDefault
);

export const useCreateVehicleNoteForm = () => {
  const { methods } = useContext(CreateVehicleNoteFormContext);
  if (!methods) {
    throw new Error('CreateVehicleNoteFormContext is empty');
  }

  return methods;
};

export const useManageCreateVehicleSchema = () => {
  const { setSchema } = useContext(CreateVehicleNoteFormContext);

  return { setSchema };
};
