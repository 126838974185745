// System
import React from 'react';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

// Components
import { BalanceCard } from '../BalanceCard';

// Types
import { SelectedCustomerBalanceTransactionType } from '@Modal/reducers/customers/types';

type Props = {
  transactions: SelectedCustomerBalanceTransactionType[];
  dateCategory: string;
};

export const GroupedTransactions: React.FC<Props> = ({ transactions, dateCategory }): any => {
  const hasSuccessfulTransactions = transactions.some(
    (transaction) => transaction.transaction_status !== 'REJECTED'
  );

  if (!hasSuccessfulTransactions) {
    return null;
  }
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          fontWeight: '600',
          color: 'black',
          marginBottom: '20px',
        }}
      >
        {dateCategory}
      </Typography>
      <Stack direction={'column'} spacing={2}>
        {transactions?.map((transaction: any) => {
          if (transaction.transaction_status !== 'REJECTED') {
            return (
              <BalanceCard
                key={transaction?.transaction_id}
                id={transaction?.transaction_id}
                type="RENTAL"
                name={transaction?.transaction_type}
                time={transaction?.transaction_created_at}
                currency={transaction?.transaction_currency}
                value={transaction?.transaction_amount}
              />
            );
          }
        })}
      </Stack>
    </Box>
  );
};
