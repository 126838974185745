// System
import { Box } from '@mui/system';

// Features
import { Divider, Stack } from '@mui/material';
import { AnalyticsTitle } from '@Features/analytics/AnalyticsTitle';
import { AnalyticsList } from '@Features/analytics/AnalyticsList';
import { AnalyticsTotals } from '@Features/analytics/AnalyticsList/AnalyticsTotals';
import { AnalyticsChart } from '@Features/analytics/Chart/AnalyticsChart';

export const AnalyticsPage = () => {
  return (
    <Box>
      <Stack sx={{ height: '100%' }}>
        <AnalyticsTitle />
        <Divider />
        <AnalyticsChart />
        <Divider />
        <AnalyticsTotals />
        <AnalyticsList />
      </Stack>
    </Box>
  );
};
