import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';
import { UpdateCustomerType } from './types';

// GET
export const getSelectedCustomer = createAsyncThunk(
  'customer/getSelectedCustomer',
  async (customerId: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/customers/${customerId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT
export const updateCustomer = createAsyncThunk(
  'customer/updateCustomerData',
  async (customerData: UpdateCustomerType, thunkApi) => {
    return axiosCustomerManagementInstance
      .put(`/customers/update/${customerData.customerId}`, {
        ...customerData.customerFormState,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showCustomerDrawer: false,
  showCancelCustomerDrawer: false,
  selectedCustomerData: {},
};

const customersDrawerSlice = createSlice({
  name: 'customerDrawer',
  initialState,
  reducers: {
    setShowCustomerDrawer: (state, { payload }) => {
      state.showCustomerDrawer = payload;
    },
    setShowCancelCustomerDrawer: (state, { payload }) => {
      state.showCancelCustomerDrawer = payload;
    },
    setSelectedCustomer: (state, { payload }) => {
      state.selectedCustomerData = payload;
    },
  },
  extraReducers: {
    [getSelectedCustomer.fulfilled?.type]: (state, action) => {
      state.selectedCustomerData = action.payload;
    },
    [updateCustomer.fulfilled?.type]: (state, action) => {
      state.selectedCustomerData = { ...state?.selectedCustomerData, ...action.payload };
      state.showCustomerDrawer = false;
    },
  },
});

export const { reducer: customersDrawerReducer } = customersDrawerSlice;
export const { setShowCancelCustomerDrawer, setShowCustomerDrawer } = customersDrawerSlice.actions;
