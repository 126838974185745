// Components
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupAddUserDrawerContent } from './SetupAddUserDrawerContent';

// Redux
import { useAddUser } from '@Modal/hooks/roles/useAddUser';

export const SetupAddUserDrawer: React.FC = () => {
  const { showAddUserModalDialog, handleCreateCancel } = useAddUser();

  return (
    <SetupDrawer
      name="SetupAddUser"
      onClose={() => handleCreateCancel()}
      open={showAddUserModalDialog}
    >
      <SetupAddUserDrawerContent />
    </SetupDrawer>
  );
};
