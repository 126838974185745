import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateRentalsNoteFormContext,
  CreateRentalsNoteFormState,
  CreateRentalsNoteSchemaDefault,
  CreateRentalsNoteFormStateDefault,
} from '@Forms/rentals/Notes/CreateRentalsNoteFormContext';

export const CreateRentalsNoteFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateRentalsNoteFormState>>>(
    CreateRentalsNoteSchemaDefault
  );
  const methods = useForm({
    defaultValues: CreateRentalsNoteFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateRentalsNoteFormContext.Provider value={value}>
      {children}
    </CreateRentalsNoteFormContext.Provider>
  );
};
