import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import {
  OpenSendCommandDialogName,
  useSendCommandDialog,
} from '@Features/providers/store/dialogs/useSendCommandDialog';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

export const SetupVehicleRetireDialog = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDialogName, handleCloseDialog] = useSendCommandDialog((state) => [
    state.openDialogName,
    state.closeDialog,
  ]);

  // @TODO: Refactor using FSD approach

  // const vehicleIotImei = useGetVehicle((state) => state.data?.iotImei);
  // const sendCommand = useSendCommand((state) => state.post);

  const handleRetire = () => {
    // sendCommand({ command: 'retire', imei: vehicleIotImei ?? null }).then(
    //   () =>
    //     enqueueSnackbar('Command has been successfully executed', {
    //       autoHideDuration: 2000,
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       },
    //     }),
    //   () =>
    //     enqueueSnackbar('Command was not executed.', {
    //       autoHideDuration: 2000,
    //       variant: 'error',
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       },
    //     })
    // );
    handleCloseDialog();
  };

  return (
    <ClosableModal
      open={openDialogName === OpenSendCommandDialogName.Retire}
      name="setupVehicleRetireDialog"
      title="Retire vehicle"
      onClose={handleCloseDialog}
    >
      <CreateDialogLayout>
        <Typography marginBottom={2}>
          Retired vehicle won’t be shown on the map, but will remain in the system
        </Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCloseDialog}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button color="error" type="submit" variant="contained" onClick={handleRetire}>
              Retire
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
