import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number, boolean, date, array } from 'yup';
import { AccountDetailsArray } from '@Modal/reducers/customers/types';

export type SetupCustomerFormState = {
  accountDetails: AccountDetailsArray;
  createdAt: string | null;
  customer: {
    accountId: string | null;
    brandId: string | null;
    createdAt: string | null;
    deletedAt: string | null;
    id: string | null;
    newsletter: boolean | null;
    status: string | null;
    updatedAt: string | null;
    userAppLanguage: string | null;
  };
  email: string | number | null;
  id: string | null;
  phone: string | number | null;
  sourceProjectId: string | null;
};

export const setupCustomerFormStateDefault: SetupCustomerFormState = {
  accountDetails: [],
  createdAt: '',
  customer: {
    accountId: '',
    brandId: '',
    createdAt: '',
    deletedAt: '',
    id: '',
    newsletter: null,
    status: '',
    updatedAt: '',
    userAppLanguage: '',
  },
  email: '',
  id: '',
  phone: '',
  sourceProjectId: '',
};

// @ts-ignore
export const setupCustomerSchemaDefault: ObjectSchema<Partial<SetupCustomerFormState>> =
  object<SetupCustomerFormState>().shape({
    accountDetails: array().of(
      object().shape({
        attributes: object().shape({}),
        createdAt: string().nullable().optional(),
        firstName: string().nullable().optional(),
        lastName: string().nullable().optional(),
        personalId: string().nullable().optional(),
        secondaryEmail: string().nullable().optional(),
        secondaryPhone: string().nullable().optional(),
        updatedAt: string().nullable().optional(),
        username: string().nullable().optional(),
      })
    ),
    createdAt: string().nullable().optional(),
    customer: object().shape({
      accountId: string().optional().nullable(),
      brandId: string().optional().nullable(),
      createdAt: string().optional().nullable(),
      deletedAt: string().optional().nullable(),
      id: string().optional().nullable(),
      newsletter: boolean().nullable().optional(),
      status: string().optional().nullable(),
      updatedAt: string().optional().nullable(),
      userAppLanguage: string().optional().nullable(),
    }),
    email: string().optional().nullable(),
    id: string().optional().nullable(),
    phone: string().optional().nullable(),
    sourceProjectId: string().optional().nullable(),
  });

type SetupCustomerFormContextType = {
  schema: ObjectSchema<Partial<SetupCustomerFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupCustomerFormState>>>;
  methods?: UseFormReturn<SetupCustomerFormState>;
};

const createCustomerFormContextDefault = {
  schema: setupCustomerSchemaDefault,
  setSchema: noop,
} satisfies SetupCustomerFormContextType;

export const SetupCustomerFormContext = createContext<SetupCustomerFormContextType>(
  createCustomerFormContextDefault
);

export const useSetupCustomerForm = () => {
  const { methods } = useContext(SetupCustomerFormContext);
  if (!methods) {
    throw new Error('SetupCustomerFormContext is empty');
  }

  return methods;
};

export const useManageSetupCustomerSchema = () => {
  const { setSchema } = useContext(SetupCustomerFormContext);

  return { setSchema };
};
