// Components
import { Button, Typography } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Types
type Props = {
  showModal: boolean;
  closeModal: () => void;
  deleteMapZone: () => void;
};

export const RemoveMapZoneModalDialog: React.FC<Props> = ({
  showModal,
  closeModal,
  deleteMapZone,
}) => {
  return (
    <ClosableModal
      name="DeleteMapZone"
      title="Delete map zones"
      open={showModal}
      onClose={closeModal}
      sx={{ width: '592px' }}
    >
      <CreateDialogLayout>
        <Typography variant="body1">Are you sure you want to delete the selected area?</Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={closeModal}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button type="submit" variant="contained" color="error" onClick={deleteMapZone}>
              Delete
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
