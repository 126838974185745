import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ReplaceQRFormContext,
  SetupReplaceQRFormStateDTO,
  replaceQRFormStateDefault,
  replaceQRSchemaDefault,
} from '@Forms/operator/SetupReplaceQRFormContext';

export const ReplaceQRFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<SetupReplaceQRFormStateDTO>>>(replaceQRSchemaDefault);
  const methods = useForm({
    defaultValues: replaceQRFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return <ReplaceQRFormContext.Provider value={value}>{children}</ReplaceQRFormContext.Provider>;
};
