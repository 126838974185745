import { Stack } from '@mui/material';
import parseISO from 'date-fns/parseISO';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useRoundDrawer } from '@Modal/hooks/rounds/useRoundDrawer';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

export const RoundDetails = () => {
  const { selectedRoundData } = useRoundDrawer();
  const { _id, multiplierCrash, createdAt, roundHash, clientHash, serverHash } = selectedRoundData;

  return (
    <Stack direction="column" spacing={3}>
      <FieldRowLabel label="ID" value={_id ? _id : 'No Result'} />
      <FieldRowLabel
        label="Multiplier Crash"
        value={multiplierCrash ? multiplierCrash : 'No Result'}
      />
      <FieldRowLabel
        label="Created At"
        value={
          createdAt ? <DateLabel date={new Date(parseISO(createdAt))} format="long" /> : 'No Result'
        }
      />
      <FieldRowLabel
        label="Round Hash"
        value={roundHash ? <TruncateText text={roundHash} maxLength={25} /> : 'No Result'}
      />
      <FieldRowLabel
        label="Client Hash"
        value={clientHash ? <TruncateText text={clientHash} maxLength={25} /> : 'No Result'}
      />
      <FieldRowLabel
        label="Server Hash"
        value={serverHash ? <TruncateText text={serverHash} maxLength={25} /> : 'No Result'}
      />
    </Stack>
  );
};
