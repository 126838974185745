import { ComponentProps, ReactElement, cloneElement } from 'react';
import { Alert } from '@mui/material';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Alert>;

export type AtAlertLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtAlertLocator: React.FC<AtAlertLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-alert': name });
};
