import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import { CategoriesType } from './types';

export const getBranchCategories = createAsyncThunk(
  'branches/getBranchCategories',
  async (id: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`v1/branches/categories/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const getBranchUnassignedCategories = createAsyncThunk(
  'branches/getBranchUnassignedCategories',
  async (id: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`v1/branches/categories/unassigned/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue({ items: res.data.items, activeBrandId: id });
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

// Type
const initialState = {
  showBranchDrawer: false,
  showCancelBranchDrawer: false,
  categories: [],
  selectedCategory: null,
};

const branchDrawerSlice = createSlice({
  name: 'branchDrawer',
  initialState,
  reducers: {
    setShowBranchDrawer: (state, { payload }) => {
      state.showBranchDrawer = payload;
    },
    setShowCancelBranchDrawer: (state, { payload }) => {
      state.showCancelBranchDrawer = payload;
    },
    setCheckedCategories: (state, { payload }) => {
      // @ts-ignore
      state.assignedCategories = payload;
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    setCategories: (state, { payload }) => {
      const { categories } = state;
      const { categoryId, branchId } = payload;
      const modifiedCategories = categories.map((cat: CategoriesType) =>
        cat.id === categoryId ? { ...cat, selected: !cat.selected, branchId } : cat
      );

      // @ts-ignore
      state.categories = modifiedCategories;
    },
    updateCategories: (state, { payload }) => {
      state.categories = payload;
    },
    clearCategories: (state) => {
      state.categories = [];
    },
  },
  extraReducers: {
    [getBranchCategories.pending?.type]: (state, _) => {
      state.categories = [];
    },
    [getBranchCategories.fulfilled?.type]: (state, action) => {
      const { categories } = state;
      const { payload } = action;

      const modifiedPayload = payload.map((cat: CategoriesType) => {
        return { ...cat, selected: true };
      });

      const uniqueCategories = [
        ...categories.filter(
          (existingCat: CategoriesType) =>
            !modifiedPayload.some((newCat: CategoriesType) => newCat.id === existingCat.id)
        ),
        ...modifiedPayload,
      ];

      state.selectedCategory = payload[0];
      // @ts-ignore
      state.categories = uniqueCategories;
    },
    [getBranchUnassignedCategories.pending?.type]: (state, _) => {
      state.categories = [];
    },
    [getBranchUnassignedCategories.fulfilled?.type]: (state, action) => {
      const { categories } = state;
      const { payload } = action;
      const { items } = payload;
      const modifiedPayload = items.map((cat: CategoriesType) => {
        return { ...cat, selected: false };
      });

      const uniqueCategories = [
        ...categories.filter(
          (existingCat: CategoriesType) =>
            !modifiedPayload.some((newCat: CategoriesType) => newCat.id === existingCat.id)
        ),
        ...modifiedPayload,
      ];

      // @ts-ignore
      state.categories = uniqueCategories;
    },
  },
});

export const { reducer: branchDrawerReducer } = branchDrawerSlice;
export const {
  setCategories,
  clearCategories,
  setShowBranchDrawer,
  setSelectedCategory,
  setCheckedCategories,
  setShowCancelBranchDrawer,
} = branchDrawerSlice.actions;
