import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const CancelDialogMessageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Box padding={2} width="320px">
      <Typography variant="body1" align="center">
        {children}
      </Typography>
    </Box>
  );
};
