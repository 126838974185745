import {
  defaultRentalListFilter,
  rentalListColumnVisibilityOptions,
  defaultRentalListColumnVisibility,
} from '@utils/tables/rentals/rentalsListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultRentalListFilter,
  rowsPerPage: 10,
  page: 1,
  columnVisibility: defaultRentalListColumnVisibility,
  columnVisibilityOptions: rentalListColumnVisibilityOptions,
};

const rentalsTableSlice = createSlice({
  name: 'rentalsTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultRentalListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: rentalsTableReducer } = rentalsTableSlice;
export const {
  setColumnVisibility,
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
} = rentalsTableSlice.actions;
