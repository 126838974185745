import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelDialogMessageLayout,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

export const SetupVehicleCategoryCancelModalDialog: React.FC = () => {
  const { showCancelCategoryDrawer, handleCancelTakeMeBack } = useVehicleCategoryDrawer();

  return (
    <>
      {showCancelCategoryDrawer && (
        <CancelModal open={showCancelCategoryDrawer}>
          <ImageCancelDialogLayout
            title="Cancel vehicle changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('cancel')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in vehicle settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
