// Components
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { TableColumnHeader } from '@Components/shared/tables/columns';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Utils
import {
  RoleListColumns,
  RoleListFilter,
  roleListColumns,
} from '@utils/tables/users/roleListFilter';

// Contracts
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { AviatorRoleItemDto } from '@contracts/api/dto/aviatorRoles/RoleDto';
import { Filterable } from '@contracts/common/traits/Filterable';

type HeaderOptions = {
  filter: Filterable<RoleListFilter>;
};

type Columns = TableColumnDescriptionArray<
  AviatorRoleItemDto,
  HeaderOptions,
  typeof roleListColumns
>;

type Column = TableColumnDescription<AviatorRoleItemDto, HeaderOptions, RoleListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useAviatorRoleTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'role.id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>{row?.id && <TruncateText text={row?.id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'role.name',
          title: 'Name',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.name}</Box>,
        },
        {
          name: 'role.description',
          title: 'Description',
          sx: { minWidth: '100px', maxWidth: '240px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.description}</Box>,
        },
        {
          name: 'role.isEditable',
          title: 'Is Editable',
          sx: { minWidth: '100px', maxWidth: '240px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.isEditable ? 'True' : 'False'}</Box>,
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
