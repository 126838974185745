import { FormProvider } from 'react-hook-form';

import { Stack } from '@mui/material';

import { RHFTextField } from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useSetupRoleForm } from '@Forms/roles/SetupRoleFormContext';
import { useRolesDrawer } from '@Modal/hooks/roles/useRolesDrawer';

export const Role = () => {
  const methods = useSetupRoleForm();
  const { selectedRoleData } = useRolesDrawer();

  return (
    <FormProvider {...methods}>
      <Stack direction="column" spacing={2} sx={{ padding: '16px 20px' }}>
        <AtLocator type="text-field" name="firstName" key="firstName">
          <RHFTextField
            id="firstName"
            name="firstName"
            label={'First Name'}
            variant="outlined"
            value={selectedRoleData?.account?.accountDetails[0]?.firstName}
            disabled
          />
        </AtLocator>
        <AtLocator type="text-field" name="lastName" key="lastName">
          <RHFTextField
            id="lastName"
            name="lastName"
            label={'Last Name'}
            variant="outlined"
            value={selectedRoleData?.account?.accountDetails[0]?.lastName}
            disabled
          />
        </AtLocator>
        <AtLocator type="text-field" name="email" key="email">
          <RHFTextField
            id="email"
            name="email"
            label={'Email'}
            variant="outlined"
            value={selectedRoleData?.account?.email}
            disabled
          />
        </AtLocator>
        <AtLocator type="select-field" name="role">
          <RHFTextField
            id="role"
            name="role"
            label={'role'}
            variant="outlined"
            value={selectedRoleData?.account?.accountDetails[0]?.role}
            disabled
          />
        </AtLocator>
        <AtLocator type="select-field" name="brand">
          <RHFTextField
            id="brand"
            name="brand"
            label={'brand'}
            variant="outlined"
            value={selectedRoleData?.brands}
            disabled
          />
        </AtLocator>
        <AtLocator type="select-field" name="secondLevelBranches">
          <RHFTextField
            id="secondLevelBranches"
            name="secondLevelBranches"
            label={'secondLevelBranches'}
            variant="outlined"
            value={selectedRoleData?.secondLevelBranches}
            disabled
          />
        </AtLocator>
        <AtLocator type="select-field" name="thirdLevelBranches">
          <RHFTextField
            id="thirdLevelBranches"
            name="thirdLevelBranches"
            label={'thirdLevelBranches'}
            variant="outlined"
            value={selectedRoleData?.thirdLevelBranches}
            disabled
          />
        </AtLocator>
      </Stack>
    </FormProvider>
  );
};
