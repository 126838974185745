import { ComponentProps, ReactElement, cloneElement } from 'react';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { RHFSelect } from '@Components/hook-form';

type ChildrenProps = ComponentProps<typeof RHFSelect>;

export type AtSelectFieldLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtSelectFieldLocator: React.FC<AtSelectFieldLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-select-field': name });
};
