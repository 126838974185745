import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupCategoryFormState,
  setupCategoryFormStateDefault,
  setupCategorySchemaDefault,
  SetupCategoryFormContext,
} from '@Forms/vehicles/SetupCategoryFormContext';

export const SetupCategoryFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<SetupCategoryFormState>>>(
    setupCategorySchemaDefault
  );
  const methods = useForm({
    defaultValues: setupCategoryFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupCategoryFormContext.Provider value={value}>{children}</SetupCategoryFormContext.Provider>
  );
};
