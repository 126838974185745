import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const providerListColumns = [
  'provider.id',
  'provider.createdAt',
  'provider.updatedAt',
  'provider.name',
  'provider.apiUrl',
  'provider.publicKey',
  'provider.privateKey',
  'provider.passPhrase',
  'provider.maxWin',
  'provider.minBet',
  'provider.maxBet',
  'provider.betSuggestions',
  'provider.language',
  'provider.minAutoCashout',
  'provider.defaultAutoCashout',
  'provider.maxAutoCashout',
  'provider.currency',
] as const;

export type ProviderListColumns = (typeof providerListColumns)[number];

export type ProviderListFilter = {
  'provider.id': string | undefined;
  'provider.createdAt': string | undefined;
  'provider.updatedAt': string | undefined;
  'provider.name': string | undefined;
  'provider.apiUrl': string | undefined;
  'provider.publicKey': string | undefined;
  'provider.privateKey': string | undefined;
  'provider.passPhrase': string | undefined;
  'provider.maxWin': number | undefined;
  'provider.minBet': number | undefined;
  'provider.maxBet': number | undefined;
  'provider.betSuggestions': string | string[];
  'provider.language': string | undefined;
  'provider.minAutoCashout': number | undefined;
  'provider.defaultAutoCashout': number | undefined;
  'provider.maxAutoCashout': number | undefined;
  'provider.currency': string | undefined;
};

export const defaultProviderListFilter: ProviderListFilter = {
  'provider.id': undefined,
  'provider.createdAt': undefined,
  'provider.updatedAt': undefined,
  'provider.name': undefined,
  'provider.apiUrl': undefined,
  'provider.publicKey': undefined,
  'provider.privateKey': undefined,
  'provider.passPhrase': undefined,
  'provider.maxWin': undefined,
  'provider.minBet': undefined,
  'provider.maxBet': undefined,
  'provider.betSuggestions': [],
  'provider.language': undefined,
  'provider.minAutoCashout': undefined,
  'provider.defaultAutoCashout': undefined,
  'provider.maxAutoCashout': undefined,
  'provider.currency': undefined,
};

export const providerListColumnVisibilitySettings = {
  'provider.id': ColumnVisibilityType.Visible,
  'provider.createdAt': ColumnVisibilityType.CanBeHidden,
  'provider.name': ColumnVisibilityType.CanBeHidden,
  'provider.apiUrl': ColumnVisibilityType.CanBeHidden,
  'provider.maxWin': ColumnVisibilityType.CanBeHidden,
  'provider.minBet': ColumnVisibilityType.CanBeHidden,
  'provider.maxBet': ColumnVisibilityType.CanBeHidden,
  'provider.currency': ColumnVisibilityType.CanBeHidden,
  'provider.maxAutoCashout': ColumnVisibilityType.CanBeHidden,
  'provider.defaultAutoCashout': ColumnVisibilityType.CanBeHidden,
  'provider.minAutoCashout': ColumnVisibilityType.CanBeHidden,
  'provider.updatedAt': ColumnVisibilityType.CanBeVisible,
  'provider.publicKey': ColumnVisibilityType.CanBeVisible,
  'provider.privateKey': ColumnVisibilityType.CanBeVisible,
  'provider.passPhrase': ColumnVisibilityType.CanBeVisible,
  'provider.betSuggestions': ColumnVisibilityType.CanBeVisible,
  'provider.language': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<ProviderListColumns>;

export const defaultProviderListColumnVisibility = defaultColumnVisibilityParams(
  providerListColumnVisibilitySettings
);

export const providerListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  providerListColumns,
  providerListColumnVisibilitySettings
);
