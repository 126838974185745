import { ComponentProps, ReactElement, cloneElement } from 'react';
import { Box, Typography } from '@mui/material';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Box> | ComponentProps<typeof Typography>;

export type AtReadonlyTextLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtReadonlyTextLocator: React.FC<AtReadonlyTextLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-readonly-text': name });
};
