import React from 'react';
import { Stack, Typography } from '@mui/material';
import { RHFDatePicker, RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';
import dayjs from 'dayjs';

type Props = {
  status: boolean;
};

export const PersonalInformation: React.FC<Props> = ({ status }) => {
  const { selectedCustomerData } = useCustomersDrawer();

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <AtLocator type="readonly-text" name="PersonalInformation">
          <Typography variant="button">Personal information</Typography>
        </AtLocator>
      </Stack>
      <Stack direction="column" spacing={1.5}>
        <AtLocator type="text-field" name="id">
          <RHFTextField disabled name="id" id="setup-customer-id" label="ID" variant="outlined" />
        </AtLocator>
        <AtLocator type="text-field" name="firstname">
          <RHFTextField
            disabled={selectedCustomerData?.customer?.status === 'blocked'}
            name="firstName"
            id="setup-customer-first-name"
            label="First name"
            variant="outlined"
            value={selectedCustomerData?.accountDetails[0]?.firstName}
          />
        </AtLocator>
        <AtLocator type="text-field" name="lastname">
          <RHFTextField
            disabled={selectedCustomerData?.customer?.status === 'blocked'}
            name="lastName"
            id="setup-customer-last-name"
            label="Last name"
            variant="outlined"
            value={selectedCustomerData?.accountDetails[0]?.lastName}
          />
        </AtLocator>
        {/* <AtLocator type="text-field" name="dob">
          <RHFDatePicker
            value={`${dayjs(selectedCustomerData?.accountDetails[0]?.attributes?.birthday).format(
              'YYYY-MM-DD HH:mm:ss'
            )}`}
            name="birthday"
            label="Date of birth"
            status={status}
          />
        </AtLocator> */}
      </Stack>
    </Stack>
  );
};
