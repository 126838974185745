import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number } from 'yup';

export type CreateBranchFormState = {
  brandId: string;
  parentId: string;
  name: string;
  level: number;
};

export const createBranchFormStateDefault: CreateBranchFormState = {
  brandId: '',
  parentId: '',
  name: '',
  level: 0,
};

export const createBranchSchemaDefault: ObjectSchema<Partial<CreateBranchFormState>> = object<
  Partial<CreateBranchFormState>
>().shape({
  brandId: string().required(),
  parentId: string().required(),
  name: string()
    .required()
    .matches(/^\w+( +\w+)*$/g, '* This field cannot contain blankspaces'),
  level: number().required(),
});

type CreateBranchFormContextType = {
  schema: ObjectSchema<Partial<CreateBranchFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateBranchFormState>>>;
  methods?: UseFormReturn<CreateBranchFormState>;
};

const createBranchFormContextDefault = {
  schema: createBranchSchemaDefault,
  setSchema: noop,
} satisfies CreateBranchFormContextType;

export const CreateBranchFormContext = createContext<CreateBranchFormContextType>(
  createBranchFormContextDefault
);

export const useCreateBranchForm = () => {
  const { methods } = useContext(CreateBranchFormContext);
  if (!methods) {
    throw new Error('CreateBranchFormContext is empty');
  }

  return methods;
};

export const useManageCreateBranchSchema = () => {
  const { setSchema } = useContext(CreateBranchFormContext);

  return { setSchema };
};
