// System
import React from 'react';
import { Stack, Typography } from '@mui/material';

// Keys
import { BatteryLevel } from '@contracts/domain/keys';

// Components
import SvgColor from '@Components/svg-color/SvgColor';

// Helpers
import { generateBatteryteIcon, generateTxtColor } from '@Components/shared/maps/CustomMarkerIcons';

type Props = {
  level: BatteryLevel;
  batteryPercentage?: number | string;
};

export const BatteryLevelIconLabel: React.FC<Props> = ({ level, batteryPercentage }) => {
  const color = generateTxtColor(level);
  const icon = generateBatteryteIcon(level);

  return (
    <Typography variant="subtitle2" color={color}>
      <Stack direction="row">
        <SvgColor src={icon} color={color} sx={{ marginX: 1 }} />
        <span>{batteryPercentage !== undefined ? `${batteryPercentage} %` : `N/A`}</span>
      </Stack>
    </Typography>
  );
};
