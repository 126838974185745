import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosAuthorizationInstance } from '@utils/axiosInstances';
import { DeleteCustomerType } from '../types';

//

// Delete
export const deleteCustomerRequest = createAsyncThunk(
  'customer/deleteCustomerRequest',
  async (customerData: DeleteCustomerType, thunkApi) => {
    const config = {
      headers: {
        'x-private-key': process.env.REACT_APP_BACKOFFICE_PRIVATE_KEY,
      },
      data: { ...customerData.newNote },
    };
    return axiosAuthorizationInstance
      .delete(`/api/v1/accounts/${customerData.id}`, config)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showDeleteCustomerModalDialog: false,
  forceDelete: false,
};

const deleteCustomerSlice = createSlice({
  name: 'deleteCustomer',
  initialState,
  reducers: {
    setShowDeleteCustomerModalDialog: (state, { payload }) => {
      state.showDeleteCustomerModalDialog = payload;
    },
    setForceDelete: (state, { payload }) => {
      state.forceDelete = payload;
    },
  },
  extraReducers: {
    [deleteCustomerRequest.fulfilled?.type]: (state) => {
      state.showDeleteCustomerModalDialog = false;
    },
  },
});

export const { reducer: deleteCustomerReducer } = deleteCustomerSlice;
export const { setShowDeleteCustomerModalDialog, setForceDelete } = deleteCustomerSlice.actions;
