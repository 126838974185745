// Components
import { SetupRoundTabs } from './SetupRoundTabs/SetupRoundTabs';
import { SetupRoundHeader } from './SetupRoundHeader/SetupRoundHeader';
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';

// Redux
import { useDispatch } from 'react-redux';
import { useRoundDrawer } from '@Modal/hooks/rounds/useRoundDrawer';

export const SetupRoundContent: React.FC = () => {
  const { setShowRoundDrawer } = useRoundDrawer();
  const dispatch = useDispatch();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout
          currentSlug="/rounds"
          onClose={() => dispatch(setShowRoundDrawer(false))}
        >
          <SetupRoundHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={() => ''}
          saveDisabled={true}
          onCancelClick={() => dispatch(setShowRoundDrawer(false))}
        />
      }
    >
      <SetupRoundTabs />
    </TabDrawerLayout>
  );
};
