import { Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SystemStyleObject } from '@mui/system';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabEx } from '@Components/shared/selects/TabEx';
import { useTabs } from '@Components/shared/hooks/useTabs';
import { TabsDrawerLayout } from '@Components/shared/layouts/TabsDrawerLayout';
import { Details } from './tabs/Details';
import { RentalsNotes } from '@Features/rentals/Notes';
import { Events } from './tabs/Events';
import { RentalTransactions } from './tabs/Transactions';
import { PricingBreakdown } from './tabs/PricingBreakdown';

const useSx = () => {
  return {
    panel: { padding: 0, height: '100%' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupRentalTabs: React.FC = () => {
  const { currentTab, handleSwithTab } = useTabs('1');
  const styles = useSx();

  return (
    <TabContext value={currentTab}>
      <TabsDrawerLayout
        header={
          <AtLocator type="tab-list" name="SetupRental">
            <TabList
              sx={{ height: '100%', width: '560px', overflow: 'auto' }}
              onChange={handleSwithTab}
              aria-label="Information tabs"
            >
              <TabEx label="Details" value="1" />
              <TabEx label="Pricing Breakdown" value="2" />
              <TabEx label="Events" value="3" />
              <TabEx label="Notes" value="4" />
              <TabEx label="Transactions" value="5" />
            </TabList>
          </AtLocator>
        }
      >
        <TabPanel value="1" sx={styles.panel}>
          <Details />
        </TabPanel>
        <TabPanel value="2" sx={{ ...styles.panel, overflow: 'hidden' }}>
          <PricingBreakdown />
        </TabPanel>
        <TabPanel value="3" sx={styles.panel}>
          <Events />
        </TabPanel>
        <TabPanel value="4" sx={styles.panel}>
          <RentalsNotes />
        </TabPanel>
        <TabPanel value="5" sx={styles.panel}>
          <RentalTransactions />
        </TabPanel>
      </TabsDrawerLayout>
    </TabContext>
  );
};
