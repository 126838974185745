import { TableColumnHeader } from '@Components/shared/tables/columns';
import {
  ProviderListColumns,
  ProviderListFilter,
  providerListColumns,
} from '@utils/tables/providers/providerListFilter';

import { ProviderItemDto } from '@contracts/api/dto/providers/ProviderDto';
import { Filterable } from '@contracts/common/traits/Filterable';
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { useMemo } from 'react';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';
import { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

type HeaderOptions = {
  filter: Filterable<ProviderListFilter>;
};

type Columns = TableColumnDescriptionArray<
  ProviderItemDto,
  HeaderOptions,
  typeof providerListColumns
>;

type Column = TableColumnDescription<ProviderItemDto, HeaderOptions, ProviderListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useProviderTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'provider.id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>{row?.id && <TruncateText text={row?.id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'provider.createdAt',
          title: 'Created At',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'provider.updatedAt',
          title: 'Updated At',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'provider.name',
          title: 'Name',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.name}</Box>,
        },
        {
          name: 'provider.apiUrl',
          title: 'Api Url',
          sx: { minWidth: '190px', maxWidth: '240px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {
                <>
                  {row?.apiUrl && (
                    <TruncateText text={row?.apiUrl} maxLength={15} textStyles={styles.id} />
                  )}
                </>
              }
            </Box>
          ),
        },
        {
          name: 'provider.publicKey',
          title: 'Public Key',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.publicKey && (
                <TruncateText text={row?.publicKey} maxLength={15} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'provider.privateKey',
          title: 'Private Key',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.privateKey && (
                <TruncateText text={row?.privateKey} maxLength={15} textStyles={styles.id} />
              )}
            </Box>
          ),
        },

        {
          name: 'provider.passPhrase',
          title: 'Pass Phrase',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.passPhrase && (
                <TruncateText text={row?.passPhrase} maxLength={15} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'provider.maxWin',
          title: 'Max Win',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.maxWin}</Box>,
        },
        {
          name: 'provider.minBet',
          title: 'Min Bet',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.minBet}</Box>,
        },
        {
          name: 'provider.maxBet',
          title: 'Max Bet',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.maxBet}</Box>,
        },
        {
          name: 'provider.betSuggestions',
          title: 'Bet Suggestions',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.betSuggestions}</Box>,
        },
        {
          name: 'provider.language',
          title: 'Language',
          sx: { minWidth: '200px', maxWidth: '220px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.language}</Box>,
        },
        {
          name: 'provider.minAutoCashout',
          title: 'Min Auto Cashout',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.minAutoCashout}</Box>,
        },
        {
          name: 'provider.defaultAutoCashout',
          title: 'Default Auto Cashout',
          sx: { minWidth: '180px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.defaultAutoCashout}</Box>,
        },
        {
          name: 'provider.maxAutoCashout',
          title: 'Max Auto Cashout',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.maxAutoCashout}</Box>,
        },
        {
          name: 'provider.currency',
          title: 'Currency',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.currency}</Box>,
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
