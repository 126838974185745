import { ObjectSchema, object, string } from 'yup';

import {
  CreateVehicleIotLinkFormState,
  VehicleIotLinkOperation,
} from '@Forms/vehicles/CreateVehicleIotLinkFormContext';
import { useMemo } from 'react';

export const useCreateVehicleIotLinkUseCasesSchema =
  (): ObjectSchema<CreateVehicleIotLinkFormState> => {
    return useMemo(
      () =>
        object<CreateVehicleIotLinkFormState>().shape({
          operationType: string()
            .oneOf([VehicleIotLinkOperation.link, VehicleIotLinkOperation.ulink])
            .required('Something went wrong'),
          imei: string().required('IMEI is required'),
        }),
      []
    );
  };
