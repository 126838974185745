import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, number } from 'yup';

export type SetupPricingFormStateDTO = {
  unlockPrice: number | null;
  minutePrice: number | null;
  parkingPrice: number | null;
};

export const setupPricingFormStateDefault: SetupPricingFormStateDTO = {
  unlockPrice: 0,
  minutePrice: 0,
  parkingPrice: 0,
};

export const setupPricingSchemaDefault: ObjectSchema<Partial<SetupPricingFormStateDTO>> =
  object<SetupPricingFormStateDTO>().shape({
    unlockPrice: number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Unlock price is required')
      .min(0)
      .max(100)
      .nullable(),
    minutePrice: number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Minute price is required')
      .min(0)
      .max(100)
      .nullable(),
    parkingPrice: number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Parking price is required')
      .min(0)
      .max(100)
      .nullable(),
  });

type SetupPricingFormContextType = {
  schema: ObjectSchema<Partial<SetupPricingFormStateDTO>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupPricingFormStateDTO>>>;
  methods?: UseFormReturn<SetupPricingFormStateDTO>;
};

const createPricingFormContextDefault = {
  schema: setupPricingSchemaDefault,
  setSchema: noop,
} satisfies SetupPricingFormContextType;

export const SetupPricingFormContext = createContext<SetupPricingFormContextType>(
  createPricingFormContextDefault
);

export const useSetupPricingForm = () => {
  const { methods } = useContext(SetupPricingFormContext);
  if (!methods) {
    throw new Error('SetupPricingFormContext is empty');
  }

  return methods;
};

export const useManageSetupPricingSchema = () => {
  const { setSchema } = useContext(SetupPricingFormContext);

  return { setSchema };
};
