// System
import { Button, Typography, Stack, Checkbox } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useSignOutCustomer } from '@Modal/hooks/customers/signOutCustomer/useSignOutCustomer';

// Styles
import Styles from './SignOutCustomerModalDialog.module.scss';

export const SignOutCustomerModalDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    showSignOutCustomerModalDialog,
    handleSignOutCustomerModalDialog,
    handleSignOutCustomerRequest,
    submittable,
    forceSignOut,
    setForceSignOut,
    showWarning,
  } = useSignOutCustomer();
  return (
    <ClosableModal
      name="signOutCustomer"
      title="Sign Out Customer"
      open={showSignOutCustomerModalDialog}
      onClose={() => {
        handleSignOutCustomerModalDialog(false);
        dispatch(setForceSignOut(false));
      }}
      className={Styles.SignOutCustomerModalDialog}
    >
      <>
        {showWarning && (
          <Stack className={Styles.WarningContainer}>
            <AssetIcon src="ic_error" className={Styles.WarningIcon} />
            <Typography variant="subtitle2" className={Styles.WarningText}>
              User has existing activity
            </Typography>
          </Stack>
        )}
        <CreateDialogLayout>
          <Typography>Do you want to sing out user from all devices?</Typography>
          <Stack className={Styles.ForceCheckboxContainer}>
            {showWarning && (
              <>
                <Typography variant="body2">Force (Ignore existing rentals)</Typography>
                <Checkbox
                  checked={forceSignOut}
                  onChange={() => dispatch(setForceSignOut(!forceSignOut))}
                />
              </>
            )}
          </Stack>
          <CreateDialogFooterLayout>
            <AtLocator type="button" name="Cancel">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  handleSignOutCustomerModalDialog(false), dispatch(setForceSignOut(false));
                }}
              >
                Cancel
              </Button>
            </AtLocator>
            <AtLocator type="button" name="Submit">
              <Button
                type="submit"
                color="error"
                variant="contained"
                disabled={!submittable}
                onClick={handleSignOutCustomerRequest}
              >
                Sign Out
              </Button>
            </AtLocator>
          </CreateDialogFooterLayout>
        </CreateDialogLayout>
      </>
    </ClosableModal>
  );
};
