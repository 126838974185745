import { noop } from '@utils/noop';
import { UseFormReturn } from 'react-hook-form';
import { Dispatch, createContext, useContext } from 'react';
import { ObjectSchema, object, string, number } from 'yup';

export type CreatePricingFormStateDTO = {
  name: string;
  vehicleType: string;
  secondLvlBranchId: string | null;
  thirdLvlBranchId: string | null;
  categoryId: string | null;
  unlockPrice: number | null;
  minutePrice: number | null;
  parkingPrice: number | null;
};

export const createPricingFormStateDefault: CreatePricingFormStateDTO = {
  name: '',
  vehicleType: '',
  secondLvlBranchId: '',
  thirdLvlBranchId: '',
  categoryId: '',
  unlockPrice: 0,
  minutePrice: 0,
  parkingPrice: 0,
};

export const createPricingSchemaDefault: ObjectSchema<Partial<CreatePricingFormStateDTO>> = object<
  Partial<CreatePricingFormStateDTO>
>().shape({
  name: string().required('Name is required'),
  vehicleType: string().required('Vehicle type is required'),
  secondLvlBranchId: string().required('Second level branch id is required'),
  thirdLvlBranchId: string().required('Third level branch id is required'),
  categoryId: string().required('Vehicle category id is required'),
  unlockPrice: number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required('Unlock price is required')
    .min(0)
    .max(100)
    .nullable(),
  minutePrice: number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required('Minute price is required')
    .min(0)
    .max(100)
    .nullable(),
  parkingPrice: number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required('Parking price is required')
    .min(0)
    .max(100)
    .nullable(),
});

type CreatePricingFormContextType = {
  schema: ObjectSchema<Partial<CreatePricingFormStateDTO>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreatePricingFormStateDTO>>>;
  methods?: UseFormReturn<CreatePricingFormStateDTO>;
};

const createPricingFormContextDefault = {
  schema: createPricingSchemaDefault,
  setSchema: noop,
} satisfies CreatePricingFormContextType;

export const CreatePricingFormContext = createContext<CreatePricingFormContextType>(
  createPricingFormContextDefault
);

export const useCreatePricingForm = () => {
  const { methods } = useContext(CreatePricingFormContext);
  if (!methods) {
    throw new Error('CreatePricingFormContext is empty');
  }

  return methods;
};

export const useManageCreatePricingSchema = () => {
  const { setSchema } = useContext(CreatePricingFormContext);

  return { setSchema };
};
