// System
import React from 'react';
import { Box } from '@mui/system';

// Features
import { ToggleableContentMobile } from '@Features/maps/Filters/ToggleableContentOperator/ToggleableContentOperator';

// Components
import { OperatorFilterTag } from '@Components/operator/OperatorFilterTag';

// Styles
const FilterDataContainer = {
  display: 'flex',
  flexDirectrion: 'row',
  gap: '12px',
  marginTop: '16px',
  maxWidth: '100vw',
  flexWrap: 'wrap',
  paddingLeft: '16px',
};

// Types
type Props = {
  filtersData: any[];
  sectionName: string;
  onClick?: (arg: any) => void;
};

export const OperatorSimpleFilterTemplate: React.FC<Props> = ({
  filtersData,
  sectionName,
  onClick,
}) => {
  return (
    <Box>
      <ToggleableContentMobile name={sectionName} key="OperatorVehicleServiceState">
        <Box sx={FilterDataContainer}>
          {filtersData.map((filter) => {
            return (
              <OperatorFilterTag
                isChecked={filter?.isChecked}
                onClick={() => (onClick ? onClick(filter) : null)}
                key={filter}
                name={filter?.title}
              />
            );
          })}
        </Box>
      </ToggleableContentMobile>
    </Box>
  );
};
