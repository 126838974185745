import ReactDOM from 'react-dom/client';
import { RootContainer } from '@Components/root/RootContainer';
import { App } from './app/App';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://api.staging.aviator.studio:82/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjMiLCJuYW1lIjoiSm9obiBEb2UiLCJpYXQiOjE1MTYyMzkwMjJ9.z__LV80pBSF1gtPyyx743i3i14R9lrXMlPnlIxYUNnA';
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   // uri: 'https://api.dev.aviator.studio:82/graphql',
//   uri: process.env.REACT_APP_GRAPHQL_API_PROXY_URL,
//   cache: new InMemoryCache(),
// });

const jsx = (
  <ApolloProvider client={client}>
    <RootContainer>
      <App />
    </RootContainer>
  </ApolloProvider>
);

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(jsx);
}
