import React from 'react';
import { Button, Stack } from '@mui/material';

// Styles
import Styles from './SetupAviatorRoleDrawerFooter.module.scss';

// Redux
import { useDispatch } from 'react-redux';
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

export const SetupAviatorRoleDrawerFooter: React.FC = () => {
  const { setShowAviatorRoleDrawer, setShowAvaitorRoleDeleteModal } = useAviatorRoleDrawer();
  const dispatch = useDispatch();
  return (
    <Stack className={Styles.SaveSettingsButtonPanel}>
      <Button
        type="submit"
        color="error"
        onClick={() => dispatch(setShowAvaitorRoleDeleteModal(true))}
      >
        Delete
      </Button>
      <Stack className={Styles.SaveCancelContainer}>
        <Button className={Styles.SaveCTA} type="submit" disabled={true}>
          Save settings
        </Button>
        <Button
          className={Styles.CancelCTA}
          variant="outlined"
          onClick={() => dispatch(setShowAviatorRoleDrawer(false))}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
