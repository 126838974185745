// System
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Types
import { AppDispatch } from 'src/app/store';
import { BackofficeLiveVehicle, DeviceInfo, RentalInfo } from '@Modal/reducers/vehicles/types';

type HashTable = {
  [key: number]: BackofficeLiveVehicle;
};

// Redux
import { getLiveMapVehicles } from '@Modal/reducers/vehicles/vehicles/vehicleMapSlice';

// Services
import {
  updateLiveMapHashTableUpperLevel,
  updateLiveMHashTableDeeperLevel,
} from '@utils/services/liveMapSocket';

export const useLiveMap = (hashVehicles: HashTable, setHashVehicles: (arg: HashTable) => void) => {
  const [liveMapVehicles, setLiveMapVehicles] = useState<BackofficeLiveVehicle[]>([]);

  // Hooks
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  // Hashtable
  const hashTableVehicles = useRef<HashTable>({});

  const getBackOfficeVehicles = async () => {
    return dispatch(getLiveMapVehicles())
      .unwrap()
      .then((result: BackofficeLiveVehicle[]) => {
        const hashResult = result.reduce(function (map, obj: BackofficeLiveVehicle) {
          if (obj.iotImei) {
            // @ts-ignore
            map[obj.iotImei] = obj;

            return map;
          }

          return map;
        }, {});

        setHashVehicles(hashResult);
        setLiveMapVehicles(result);
        hashTableVehicles.current = hashResult;
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleOmniHeartBeat = (data: DeviceInfo[]) => {
    if (Object.keys(hashVehicles).length > 0) {
      if (data?.length > 0) {
        const hashResult = data.reduce(function (map, obj: DeviceInfo): HashTable {
          if (obj.iotImei) {
            // @ts-ignore
            map[obj.iotImei || obj.imei] = obj;

            return map;
          }
          // @ts-ignore

          return map;
        }, {});

        setHashVehicles({ ...hashResult, ...hashVehicles });
      }
    }
  };

  const handleOmniScooterInfo = (data: DeviceInfo) => {
    if (hashTableVehicles && hashTableVehicles.current) {
      if (data && data.imei) {
        // @ts-ignore
        setHashVehicles((prevHashVehicles) => {
          const updatedVehicles = updateLiveMHashTableDeeperLevel(
            prevHashVehicles,
            'deviceInfo',
            data
          );

          // Return a new object to ensure React detects the change
          return { ...updatedVehicles };
        });
      }
    }
  };

  const handleOmniScooterUpdate = (data: BackofficeLiveVehicle[]) => {
    if (Object.keys(hashVehicles).length > 0) {
      if (data?.length > 0) {
        // @ts-ignore
        setHashVehicles((prevHash: HashTable) => {
          // @ts-ignore
          const hashResult = data.reduce(function (map, obj: DeviceInfo): HashTable {
            if (prevHash[+obj.iotImei || +obj.imei]) {
              // @ts-ignore
              map[+obj.iotImei || +obj.imei] = obj;
            }
            return map;
          }, {});

          return { ...prevHash, ...hashResult };
        });
      }
    }
  };

  const handleOmniPositionTracking = (data: DeviceInfo[]) => {
    if (Object.keys(hashVehicles).length > 0) {
      if (data?.length > 0) {
        // @ts-ignore
        setHashVehicles((prevHash: HashTable) => {
          const hashResult = data.reduce(function (map, obj: DeviceInfo): HashTable {
            if (prevHash[+obj.iotImei || +obj.imei]) {
              // @ts-ignore
              map[+obj.iotImei || +obj.imei] = obj;
            }

            return map;
          }, {});
          return { ...prevHash, ...hashResult };
        });
      }
    }
  };

  const handleOmniRideStatusTracking = (data: RentalInfo) => {
    if (Object.keys(hashVehicles).length > 0) {
      if (data && data.iotImei) {
        // @ts-ignore
        setHashVehicles((prevState) => {
          const updatedVehicles = updateLiveMapHashTableUpperLevel(prevState, 'rentalStatus', data);

          return { ...updatedVehicles };
        });
      }
    }
  };

  return {
    // Handlers
    getBackOfficeVehicles,
    omniHeartBeat: handleOmniHeartBeat,
    omniUpdate: handleOmniScooterUpdate,
    omniScooterInfo: handleOmniScooterInfo,
    omniRentalInfo: handleOmniRideStatusTracking,
    omniPositionTracking: handleOmniPositionTracking,
    // Data
    forceRerender: liveMapVehicles,
    hashTableVehicles: hashTableVehicles?.current,
  };
};
