import {
  defaultVehicleListFilter,
  vehicleListColumnVisibilityOptions,
  defaultVehicleListColumnVisibility,
} from '@utils/tables/vehicles/vehicleListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultVehicleListFilter,
  rowsPerPage: 10,
  page: 1,
  columnVisibility: defaultVehicleListColumnVisibility,
  columnVisibilityOptions: vehicleListColumnVisibilityOptions,
};

const vehicleTableSlice = createSlice({
  name: 'vehicleTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultVehicleListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: vehicleTableReducer } = vehicleTableSlice;
export const {
  setColumnVisibility,
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
} = vehicleTableSlice.actions;
