import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const providerTransactionListColumns = [
  'providerTransaction._id',
  'providerTransaction.createdAt',
  'providerTransaction.category',
  'providerTransaction.language',
  'providerTransaction.currency',
  'providerTransaction.roundId',
  'providerTransaction.transactionId',
  'providerTransaction.operation',
  'providerTransaction.reason',
  'providerTransaction.providerId',
  'providerTransaction.playerId',
  'providerTransaction.amount',
  'providerTransaction.multiplier',
  'providerTransaction.balance',
  'providerTransaction.gameId',
] as const;

export type ProviderTransactionListColumns = (typeof providerTransactionListColumns)[number];

export type ProviderTransactionListFilter = {
  'providerTransaction._id': string | undefined;
  'providerTransaction.createdAt': string | undefined;
  'providerTransaction.category': string | undefined;
  'providerTransaction.language': string | undefined;
  'providerTransaction.currency': string | undefined;
  'providerTransaction.roundId': string | undefined;
  'providerTransaction.transactionId': string | undefined;
  'providerTransaction.operation': string | undefined;
  'providerTransaction.reason': string | undefined;
  'providerTransaction.providerId': string | undefined;
  'providerTransaction.playerId': string | undefined;
  'providerTransaction.amount': number | undefined;
  'providerTransaction.multiplier': number | undefined;
  'providerTransaction.balance': number | undefined;
  'providerTransaction.gameId': string | undefined;
};

export const defaultProviderTransactionListFilter: ProviderTransactionListFilter = {
  'providerTransaction._id': undefined,
  'providerTransaction.createdAt': undefined,
  'providerTransaction.language': undefined,
  'providerTransaction.category': undefined,
  'providerTransaction.currency': undefined,
  'providerTransaction.roundId': undefined,
  'providerTransaction.transactionId': undefined,
  'providerTransaction.operation': undefined,
  'providerTransaction.reason': undefined,
  'providerTransaction.providerId': undefined,
  'providerTransaction.playerId': undefined,
  'providerTransaction.amount': undefined,
  'providerTransaction.multiplier': undefined,
  'providerTransaction.balance': undefined,
  'providerTransaction.gameId': undefined,
};

export const providerTransactionListColumnVisibilitySettings = {
  'providerTransaction._id': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.createdAt': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.language': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.category': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.currency': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.roundId': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.transactionId': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.operation': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.reason': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.providerId': ColumnVisibilityType.CanBeVisible,
  'providerTransaction.playerId': ColumnVisibilityType.CanBeVisible,
  'providerTransaction.amount': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.multiplier': ColumnVisibilityType.CanBeVisible,
  'providerTransaction.balance': ColumnVisibilityType.CanBeHidden,
  'providerTransaction.gameId': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<ProviderTransactionListColumns>;

export const defaultProviderTransactionListColumnVisibility = defaultColumnVisibilityParams(
  providerTransactionListColumnVisibilitySettings
);

export const providerTransactionListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  providerTransactionListColumns,
  providerTransactionListColumnVisibilitySettings
);
