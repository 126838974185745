// System
import { Box } from '@mui/system';
import { useEffect } from 'react';

// Components

// Hooks
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useRolesTable } from '@Modal/hooks/roles/useRolesTable';

// Features
import { RoleTitle } from '@Features/roles/RoleTitle';
import { RoleList } from '@Features/roles/RolesList';
import { NoTableContentLayout } from '@Components/no-content-layout/NoTableContentLayout/NoTableContentLayout';
import { useAddUser } from '@Modal/hooks/roles/useAddUser';
import { useDispatch } from 'react-redux';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const RolesPage = () => {
  const { activeBrandId } = useBranches();
  const { fetchRolesTableContent, selectedFilters, roles } = useRolesTable();
  const { handleSubmitAddUser, setShowAddUserModalDialog } = useAddUser();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchRolesTableContent();
  }, [selectedFilters, activeBrandId]);

  return (
    <AtLocator type="page" name="roles">
      <Box>
        <RoleTitle />
        {/* <RoleList /> */}
      </Box>
    </AtLocator>
  );
};
