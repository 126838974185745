import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const DefaultIcon = ({ width, height }: CardProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C9.76815 1.33337 11.4638 2.03575 12.7141 3.286C13.9643 4.53624 14.6667 6.23193 14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004ZM7.82004 10.4067L10.8667 6.40671V6.38671C11.012 6.19617 11.0446 5.94259 10.9522 5.72149C10.8599 5.5004 10.6565 5.34538 10.4189 5.31483C10.1812 5.28428 9.94533 5.38283 9.80004 5.57337L7.28004 8.90671L6.19337 7.52004C6.0469 7.33188 5.81116 7.23616 5.57497 7.26893C5.33878 7.30171 5.13802 7.458 5.0483 7.67893C4.95859 7.89987 4.99356 8.15188 5.14004 8.34004L6.76671 10.4134C6.89392 10.5744 7.08819 10.6678 7.29337 10.6667C7.49971 10.6662 7.69418 10.5702 7.82004 10.4067Z"
        fill="#00AB55"
      />
    </svg>
  </svg>
);
