import { useContext, useMemo } from 'react';
import { ActionWithPopoverContext } from './ActionWithPopoverContext';

export const useActionWithPopover = () => {
  const [anchorEl, setAnchorEl] = useContext(ActionWithPopoverContext);
  return useMemo(
    () => ({
      open: anchorEl !== null,
      handleHide:
        <T extends any[]>(onHide?: (...params: T) => void) =>
        (...params: T) => {
          setAnchorEl(null);
          onHide?.(...params);
        },
    }),
    [anchorEl, setAnchorEl]
  );
};
