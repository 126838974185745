import { correctIntegerString } from '@utils/data/number/integer/correctIntegerString';
import { parseIntegerString } from '@utils/data/number/integer/parseIntegerString';
import { validateIntegerString } from '@utils/data/number/integer/validateIntegerString';
import { localeNumberFormat } from '@utils/data/number/localeNumberFormat';
import { useMemo } from 'react';

export type IntegerFormatOptions = {
  sign?: boolean;
  group?: boolean;
};

export const useIntegerFormat = ({ sign, group }: IntegerFormatOptions = {}) => {
  return useMemo(() => {
    const format = localeNumberFormat();
    const options: Intl.NumberFormatOptions = {
      signDisplay: sign ? 'auto' : 'never',
      useGrouping: group === true,
    };

    return {
      parse: parseIntegerString(),
      validate: validateIntegerString({ group, sign }),
      correct: correctIntegerString({ group, sign }),
      format: (value: number) => format(value, options),
    };
  }, [group, sign]);
};
