import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const VisaCardIcon = ({ width, height }: CardProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="24" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.19 14.44C24.1961 12.9153 22.9386 12.2567 21.9627 11.7456C21.3449 11.4221 20.84 11.1577 20.84 10.77C20.84 10.44 21.16 10.09 21.84 10C22.6493 9.91993 23.4651 10.0617 24.2 10.41L24.62 8.41C23.9036 8.14136 23.1451 8.00252 22.38 8C20.02 8 18.38 9.26 18.38 11.06C18.38 12.39 19.57 13.13 20.47 13.57C21.37 14.01 21.72 14.32 21.71 14.72C21.71 15.34 20.97 15.62 20.28 15.63C19.4289 15.641 18.589 15.4344 17.84 15.03L17.41 17.03C18.2575 17.3608 19.1603 17.5271 20.07 17.52C22.58 17.52 24.23 16.28 24.24 14.36L24.19 14.44ZM17.7 8.16L15.7 17.41H13.3L15.3 8.16H17.7ZM27.76 14.16L29.02 10.69L29.75 14.16H27.76ZM32.65 17.44H30.43L30.14 16.06H27.13L26.64 17.44H24.12L27.71 8.87C27.8755 8.46225 28.27 8.19402 28.71 8.19H30.71L32.65 17.44ZM10.45 17.38L14.33 8.13H11.73L9.24 14.42L8.24 9.07C8.15148 8.52635 7.68081 8.12776 7.13 8.13H3.06L3 8.4C3.81634 8.55972 4.60927 8.82179 5.36 9.18C5.67976 9.33034 5.89644 9.63826 5.93 9.99L7.84 17.38H10.45Z"
        fill="#2A2A6C"
      />
    </svg>
  </svg>
);
