// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useCustomersDrawer } from '../useCustomerDrawer';
import { useCustomerRentals } from '../rentals/useCustomerRentals';

// Actions
import {
  setForceBlock,
  blockUnblockCustomerRequest,
  setShowBlockCustomerModalDialog,
  setShowUnblockCustomerModalDialog,
  setIsBlocked,
} from '@Modal/reducers/customers/blockCustomer/blockUnblockCustomerSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { getCustomersList } from '@Modal/reducers/customers/customersSlice';
import { useBranches } from '@Modal/hooks/branches/useBranch';

export const useBlockUnblockCustomer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { customerRentals } = useCustomerRentals();
  const { activeBrandId } = useBranches();
  const { handleCustomerRowClick } = useCustomersDrawer();

  const { showBlockCustomerModalDialog, forceBlock, showUnblockCustomerModalDialog, isBlocked } =
    useSelector((state: StoreInterface) => state?.blockUnblockCustomer);

  const { selectedFilters } = useSelector((state: StoreInterface) => state?.customersTable);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const { selectedCustomerData } = useCustomersDrawer();

  const handleBlockUnblockCustomerRequest = () => {
    dispatch(setIsBlocked(false));
    dispatch(
      blockUnblockCustomerRequest({
        id: selectedCustomerData.id,
        // status: isBlocked ? 'active' : 'blocked',
        status: selectedCustomerData?.customer?.status === 'blocked' ? 'active' : 'blocked',
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getCustomersList({ selectedFilters, brandId: activeBrandId }));
        handleCustomerRowClick(selectedCustomerData.id);
        dispatch(setShowBlockCustomerModalDialog(false));
        dispatch(setShowUnblockCustomerModalDialog(false));
        enqueueSnackbar('Customer status successfully has changed.', {
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleBlockCustomerModalDialog = (arg: boolean) => {
    dispatch(setShowBlockCustomerModalDialog(arg));
  };

  const handleUnblockCustomerModalDialog = (arg: boolean) => {
    dispatch(setShowUnblockCustomerModalDialog(arg));
  };

  return {
    // Store
    isBlocked,
    forceBlock,
    showBlockCustomerModalDialog,
    showUnblockCustomerModalDialog,
    submittable:
      customerRentals.length === 0 ||
      (customerRentals.length > 0 && customerRentals[0]?.state === 'ENDED') ||
      forceBlock,
    showWarning: customerRentals?.length > 0 && customerRentals[0]?.state !== 'ENDED',

    // Actions
    setForceBlock,
    handleBlockUnblockCustomerRequest,
    handleBlockCustomerModalDialog,
    setShowBlockCustomerModalDialog,
    handleUnblockCustomerModalDialog,
    setShowUnblockCustomerModalDialog,
  };
};
