// System
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Instances
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import { OperatorVehicleFetchParams } from './types';
import { CreateVehicleNoteType, UpdateVehicleType } from '@Modal/reducers/vehicles/types';
import {
  PutMultipleOperatorVehicleStateDTO,
  PutOperatorVehicleStateDTO,
  SendOperatorVehicleCommandDTO,
} from './dto';

const initialState = {
  vehicleNotes: [],
  vehiclesOnMap: [],
  selectedNoteId: null,
  fetchedVehicle: null,
  approachedVehicle: null,
  showVehicleModal: false,
  showApproachModal: false,
  showCommandsModal: false,
  showOperatorIoTModal: false,
  showCreateNotesModal: false,
  showServiceStateModal: false,
  showOperatorProfileModal: false,
  showOperatorVehicleNotes: false,
  showOperatorReplaceQRModal: false,
  showOperatorDeleteNoteModal: false,
};

// GET Vehicle Notes
export const getOperatorVehicleNotes = createAsyncThunk(
  'operator/getOperatorVehicleNotes',
  async (id: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/vehicles/${id}/notes`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// GET Vehicles On Map
export const getOperatorVehiclesOnMap = createAsyncThunk(
  'operator/getOperatorVehiclesOnMap',
  async (vehicleMapData: OperatorVehicleFetchParams, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post(`v1/vehicle-data/current/backoffice`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// GET Selected Operator Vehicle
export const getSelectedOperatorVehicle = createAsyncThunk(
  'operator/getSelectedOperatorVehicle',
  async (vehicleID: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/vehicles/${vehicleID}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// GET Selected operator vehicle notes
export const geOperatortVehicleNotes = createAsyncThunk(
  'operator/geOperatortVehicleNotes',
  async (id: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/vehicles/${id}/notes`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST IoT Command
export const postOperatorIoTCommand = createAsyncThunk(
  'operator/postOperatorIoTCommand',
  async (sendCommandDTO: SendOperatorVehicleCommandDTO, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post(`/v1/iot/send-command`, {
        ...sendCommandDTO,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST Operator vehicle note
export const postOperatorVehicleNote = createAsyncThunk(
  'operator/postOperatorVehicleNote',
  async (vehicleData: CreateVehicleNoteType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post(`/v1/vehicles/${vehicleData.id}/notes`, {
        ...vehicleData.newNote,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// DELETE Operator vehicle note
export const deleteOperatorVehicleNote = createAsyncThunk(
  'operator/deleteOperatorVehicleNote',
  async (noteId: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .delete(`/v1/vehicles/notes/${noteId}`)
      .then(() => {
        return thunkApi.fulfillWithValue(noteId);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT Operator Vehicle Service State
export const putOperatorVehicleState = createAsyncThunk(
  'operator/putOperatorVehicleState',
  async (putServiceDTO: PutOperatorVehicleStateDTO, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/vehicles/${putServiceDTO?.id}/service-state`, {
        serviceState: putServiceDTO?.serviceState,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const putMultipleOperatorVehicleState = createAsyncThunk(
  'operator/putOperatorVehicleState',
  async (putServiceDTO: PutMultipleOperatorVehicleStateDTO, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/vehicles/service-state/bulk-update`, {
        serviceState: putServiceDTO?.serviceState,
        vehicleIds: putServiceDTO?.vehicleIds,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT Operator vehicle
export const putOperatorVehicle = createAsyncThunk(
  'vehicle/updateVehicleData',
  async (vehicleData: UpdateVehicleType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/vehicles/${vehicleData.vehicleID}`, {
        ...vehicleData.vehicleFormState,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const operatorVehiclesSlice = createSlice({
  name: 'operatorVehiclesSlice',
  initialState,
  reducers: {
    setShowApproachModal: (state, { payload }) => {
      state.showApproachModal = payload?.status;
      state.approachedVehicle = payload?.vehicleInfo;
    },
    setShowCommandsModal: (state, { payload }) => {
      state.showCommandsModal = payload;
      state.showVehicleModal = !payload;
    },
    setShowServiceStateModal: (state, { payload }) => {
      state.showServiceStateModal = payload;
    },
    setShowOperatorProfileModal: (state, { payload }) => {
      state.showOperatorProfileModal = payload;
    },
    setShowIoTLinkModal: (state, { payload }) => {
      state.showOperatorIoTModal = payload;
      state.showVehicleModal = !payload;
    },
    setShowReplaceQRModal: (state, { payload }) => {
      state.showOperatorReplaceQRModal = payload;
      state.showVehicleModal = !payload;
    },
    setShowVehicleModal: (state, { payload }) => {
      state.showVehicleModal = payload;
      if (payload === false) {
        state.approachedVehicle = null;
      }
    },
    setShowOperatorVehicleNotes: (state, { payload }) => {
      state.showOperatorVehicleNotes = payload;
      state.showVehicleModal = !payload;
    },
    setShowCreateOperatorNotes: (state, { payload }) => {
      state.showCreateNotesModal = payload;
      state.showOperatorVehicleNotes = !payload;
    },
    setShowOperatorDeleteNoteModal: (state, { payload }) => {
      state.showOperatorDeleteNoteModal = payload?.status;
      state.selectedNoteId = payload?.noteId;
    },
  },
  extraReducers: {
    [getOperatorVehiclesOnMap.fulfilled?.type]: (state, { payload }) => {
      state.vehiclesOnMap = payload;
    },
    [getSelectedOperatorVehicle.fulfilled?.type]: (state, { payload }) => {
      state.fetchedVehicle = payload;
    },
    [getOperatorVehicleNotes.fulfilled?.type]: (state, { payload }) => {
      state.vehicleNotes = payload;
    },
    [postOperatorVehicleNote.fulfilled?.type]: (state) => {
      state.showCreateNotesModal = false;
      state.showOperatorVehicleNotes = true;
    },
    [deleteOperatorVehicleNote.fulfilled?.type]: (state) => {
      state.showOperatorDeleteNoteModal = false;
      state.showOperatorVehicleNotes = true;
    },
  },
});

export const { reducer: operatorVehiclesReducer } = operatorVehiclesSlice;
export const {
  setShowVehicleModal,
  setShowApproachModal,
  setShowCommandsModal,
  setShowIoTLinkModal,
  setShowReplaceQRModal,
  setShowServiceStateModal,
  setShowCreateOperatorNotes,
  setShowOperatorProfileModal,
  setShowOperatorVehicleNotes,
  setShowOperatorDeleteNoteModal,
} = operatorVehiclesSlice.actions;
