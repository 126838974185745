import { BaseSyntheticEvent, useMemo } from 'react';
// Redux
import { useBranches } from '../../branches/useBranch';
import { useSelector, useDispatch } from 'react-redux';
import {
  createVehicleCategory,
  setShowCancelVehicleCategoryForm,
  setShowCreateVehicleCategoryForm,
} from '@Modal/reducers/vehicles/categories/createVehicleCategorySlice';

import { useVehicles } from '../useVehicles';

// Form
import { useFormState } from 'react-hook-form';

// System
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useCreateCategoryForm } from '@Forms/vehicles/CreateCategoryFormContext';

export const useCreateVehicleCategory = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { activeBrandId } = useBranches();

  const { setVehicleCategoriesList } = useVehicles();
  const { showCancelVehicleCategoryForm, showCreateVehicleCategoryForm } = useSelector(
    (state: StoreInterface) => state?.createVehicleCategory
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    dispatch(setShowCreateVehicleCategoryForm(false));
    reset();
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      reset();
      dispatch(setShowCancelVehicleCategoryForm(false));
      dispatch(setShowCreateVehicleCategoryForm(true));
    } else {
      reset();
      dispatch(setShowCancelVehicleCategoryForm(false));
      dispatch(setShowCreateVehicleCategoryForm(false));
    }
  };

  // Form Controls
  const { control, handleSubmit, reset } = useCreateCategoryForm();
  const { isDirty, errors } = useFormState({ control });

  // Form Handlers
  const handleSubmitCreateVehicleCategory = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        if (activeBrandId) {
          dispatch(createVehicleCategory({ ...formData, brandId: activeBrandId }))
            .unwrap()
            .then((result) => {
              dispatch(setVehicleCategoriesList(result));
              reset();
              enqueueSnackbar('Vehicle category was successfully added', {
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            })
            .catch((error) => {
              enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            });
        } else {
          enqueueSnackbar('Brand id is empty', {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    []
  );

  return {
    // Store
    showCancelVehicleCategoryForm,
    showCreateVehicleCategoryForm,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    setShowCreateVehicleCategoryForm,
    handleSubmitCreateVehicleCategory,
  };
};
