import { ComponentProps, ReactElement, cloneElement } from 'react';
import { Box, Stack } from '@mui/material';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Box> | ComponentProps<typeof Stack>;

type WithBoxProps = {
  box: true;
  children: ReactElement<ChildrenProps & DataAttributeProps>;
};

type WithoutBox = {
  children: JSX.Element;
  box?: false;
};

export type AtContainerLocatorProps = (WithBoxProps | WithoutBox) & {
  name: string;
};

export const AtContainerLocator: React.FC<AtContainerLocatorProps> = ({ name, ...rest }) => {
  if ('box' in rest && rest.box) {
    const { children } = rest;
    return <Box data-at-container={name}>{children}</Box>;
  }
  const { children: element } = rest;
  return cloneElement(element, { 'data-at-container': name });
};
