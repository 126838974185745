import { Button } from '@mui/material';
import FormProvider from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';

import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { useCreateProviderForm } from '@Forms/providers/CreateProviderFormContext';

import { CreateProviderContent } from './CreateProviderContent';
import { useCreateProvider } from '@Modal/hooks/providers/useCreateProvider';

export const CreateProviderForm = () => {
  const methods = useCreateProviderForm();
  const { handleCreateCancel, submittable, handleSubmitCreateProvider } = useCreateProvider();

  return (
    <FormProvider methods={methods}>
      <CreateDialogLayout>
        <CreateProviderContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateProvider}
            >
              Confirm
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
