// System
import { Stack, Typography, Box } from '@mui/material';

// Redux
import { useRoundDrawer } from '@Modal/hooks/rounds/useRoundDrawer';

export const SetupRoundHeader: React.FC = () => {
  const { selectedRoundData } = useRoundDrawer();
  return (
    <Stack>
      <Box>
        <Typography variant="h6">
          {selectedRoundData?._id ? selectedRoundData?._id : 'No Result'}
        </Typography>
      </Box>
    </Stack>
  );
};
