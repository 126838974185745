import { useTheme } from '@mui/material';
import { CSSProperties, useMemo } from 'react';

import { PowerStatus } from '@contracts/domain/keys';
import { KeyValue } from '@contracts/view/KeyValue';

export const usePowerStatusColor = (): Record<PowerStatus, CSSProperties['color']> => {
  const { palette } = useTheme();
  return useMemo(
    () => ({
      off: palette.error.main,
      on: palette.success.main,
      unknown: palette.action.disabled,
    }),
    [palette]
  );
};

export const usePowerStatusTitle = (): Record<PowerStatus, string> => {
  return useMemo(() => ({ off: 'Off', on: 'On', unknown: 'Unknown' }), []);
};

export const usePowerStatusOptions = (): KeyValue<PowerStatus, string>[] => {
  const titles = usePowerStatusTitle();
  return useMemo(
    () => (Object.keys(titles) as PowerStatus[]).map((key) => ({ key, value: titles[key] })),
    [titles]
  );
};
