import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { StoreInterface } from 'src/app/types';

// Redux
import { useBranches } from './useBranch';
import { useGeneralStore } from '../useGeneralStore';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMapPaths,
  createMapZone,
  deleteMapZone,
  setMapZoneList,
  setChangeMapMode,
  setNewMapZoneList,
  setActiveCategoryId,
  setActiveMapZoneType,
  setShowMapZoneDialog,
  setSelectedMapZoneId,
  setSelectedMapZoneIndex,
  setShowDeleteMapZoneDialog,
  updateMapZone,
  setShowCancelMapZoneDialog,
  setShowClearMapZoneDialog,
  clearMapZones,
} from '@Modal/reducers/branches/mapZoneSlice';
import { useBranchDrawer } from './useBranchDrawer';

// Forms
import { useWatch } from 'react-hook-form';
import { useSetupBranchForm } from '@Forms/branches/SetupBranchFormContext';

// Types
import { AppDispatch } from 'src/app/store';
import { MapZoneType } from '@contracts/domain/keys';
import { EditStatus } from '@contracts/common/EditStatus';
import { BranchMapZone } from '@Modal/reducers/branches/types';
import { SemiGeoJSONMultiPoligon } from '@contracts/common/map/SemiGeoJSON';

// Utils
import { LiveMapServiceStateFilterType } from '../vehicles/types';
import { areEqualPaths, getPathType, toGoogleMapsMultiPoligon } from '@utils/map/geoJSON';
import { toGeoJSONPath, toMapZonePath } from '@Features/branches/SetupMapZone/geoJSON';

export const useBranchesMaps = () => {
  // Redux
  const {
    mode,
    mapPaths,
    newMapZoneList,
    selectedMapZoneId,
    showCancelMapZoneDialog,
    // Used for creation of new zone
    mapZoneList,
    defaultZoom,
    defaultCenter,
    showMapZoneDialog,
    activeMapZoneType,
    selectedMapZoneIndex,
    showDeleteMapZoneDialog,
    showClearMapZoneDialog,
  } = useSelector((state: StoreInterface) => state?.maps);

  const { selectedBranch, activeBrandId } = useBranches();
  const {
    categories: allCategories,
    selectedCategory,
    getSelectedCategoryMapZones,
  } = useBranchDrawer();
  const dispatch = useDispatch<AppDispatch>();

  const { vehicleServiceStates, mapZoneTypes } = useGeneralStore();

  const activeCategoryId = selectedCategory?.id;

  const { enqueueSnackbar } = useSnackbar();

  const [vehicleServiceState, setVehicleServiceStates] = useState<LiveMapServiceStateFilterType[]>(
    []
  );

  const generateVehicleServiceStates = () => {
    return vehicleServiceStates?.map((service) => {
      return {
        title: service?.value,
        value: service?.key,
        isChecked: false,
        operational: service?.key === 'FUNCTIONAL' || service?.key === 'INSPECT',
        key: 'serviceState',
      };
    });
  };

  useEffect(() => {
    setVehicleServiceStates(generateVehicleServiceStates());
  }, [vehicleServiceStates]);

  // Used to display polygons on map
  const mapPathList = mapZoneList
    .map((item) => item.value)
    .filter((zone) => zone?.categoryId === activeCategoryId)
    .flatMap((zone) =>
      toGoogleMapsMultiPoligon(zone.geometry).map(toMapZonePath(zone.mapZoneType, zone.id))
    );

  useEffect(() => {
    dispatch(setMapPaths(mapPathList));
  }, []);

  // Form
  const { control, watch, setValue } = useSetupBranchForm();

  const categories = watch('categories');
  const branchId = watch('id');

  useWatch({ control, name: ['categories', 'id', 'mapZones'] });

  const handleCheckmarkCategoryChange = useCallback(
    (values: string[]) => {
      const updatedCategories = allCategories
        .map((cat) => (cat.id === values[0] ? { ...cat, selected: !cat.selected } : cat))
        .map((cat) => {
          return {
            id: cat.id,
            branchId: cat.selected ? selectedBranch?.id : '',
            hidden: cat.hidden || false,
          };
        });

      setValue('categories', updatedCategories, { shouldDirty: true });
    },
    [categories, branchId, setValue, allCategories]
  );

  const handleCategoryDelete = useCallback(
    (key: number) => () => {
      setValue(
        'categories',
        categories?.map((category) =>
          +category.id === key ? { ...category, branchId: '' } : category
        ),
        { shouldDirty: true }
      );
    },
    [categories, setValue]
  );

  const handleBranchStatusStateChange = useCallback(
    (value: string) => {
      setValue('status', value, { shouldDirty: false });
    },
    [setValue]
  );

  const handleHiddenCategoryChange = useCallback(
    (key: number) => (value: boolean) => {
      setValue(
        'categories',
        categories.map((item) => (+item.id === key ? { ...item, hidden: value } : item)),
        { shouldDirty: true }
      );
    },
    [categories, setValue]
  );

  const handleCreateMapZone = (
    paths: {
      mapZoneType: MapZoneType;
      geometry: SemiGeoJSONMultiPoligon;
      pristine: boolean;
    }[]
  ) => {
    // Uncomment the following logic as needed
    if (activeCategoryId === undefined) {
      return;
    }

    const toUpdate = paths.filter((path) => !path.pristine).map(({ mapZoneType }) => mapZoneType);
    const current = mapPaths
      .filter((zone) => zone?.categoryId === activeCategoryId)
      .map((zone) => zone.mapZoneType);

    const modified = toUpdate.filter((mapZoneType) => current.includes(mapZoneType));
    const added = toUpdate.filter((mapZoneType) => !current.includes(mapZoneType));

    const newMapZones: BranchMapZone[] = [
      ...mapPaths.map((zone) =>
        zone.categoryId === activeCategoryId && modified.includes(zone.mapZoneType)
          ? {
              status: zone.status === EditStatus.Pristine ? EditStatus.Modified : zone.status,
              value: {
                ...zone,
                geometry: paths.find((p) => p.mapZoneType === zone.mapZoneType)?.geometry ?? [
                  [],
                  [],
                ],
              },
            }
          : zone
      ),
      ...added.map((mapZoneType) => ({
        status: EditStatus.Added,
        value: {
          id: undefined,
          categoryId: activeCategoryId,
          mapZoneType,
          geometry: paths.find((p) => p.mapZoneType === mapZoneType)?.geometry ?? [[], []],
        },
      })),
    ];

    dispatch(setMapPaths(newMapZones));

    // @ts-ignore
    const latestMapZone = newMapZones[newMapZones.length - 1]?.value;

    // Extract the first inner array from "geometry"
    const firstInnerArray = latestMapZone.geometry[0] || [];
    // Convert the array to a Set to remove duplicates
    const uniqueFirstInnerArray = Array.from(
      // @ts-ignore
      new Set(firstInnerArray.map(JSON.stringify)),
      JSON.parse
    );

    const updatedMapZone = {
      ...latestMapZone,
      geometry: [uniqueFirstInnerArray, ...latestMapZone.geometry.slice(1)],
    };

    dispatch(
      createMapZone({
        geometry: updatedMapZone.geometry,
        brandId: activeBrandId,
        branchId: branchId,
        categoryId: activeCategoryId,
        type: activeMapZoneType,
      })
    )
      .unwrap()
      .then(() => getSelectedCategoryMapZones(selectedCategory))
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 4000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
    dispatch(setShowMapZoneDialog(false));
  };

  const handleUpdateMapZone = useCallback(
    (i: number) => (polygon: google.maps.Polygon) => {
      const newPath = polygon
        .getPath()
        .getArray()
        .map((point) => point.toJSON());

      const areEqual = areEqualPaths(newPath, mapPaths[i].path);
      if (areEqual) {
        return;
      }

      if (getPathType(newPath) !== mapPaths[i].type) {
        newPath.reverse();
      }

      const updatedMapPaths = mapPaths.map((item, index) =>
        index !== i
          ? item
          : {
              ...item,
              editStatus:
                item.editStatus === EditStatus.Pristine ? EditStatus.Modified : item.editStatus,
              path: newPath,
            }
      );

      const paths = toGeoJSONPath(updatedMapPaths);

      const newMapZones: BranchMapZone[] = [
        ...updatedMapPaths.map((zone, index) =>
          i === index
            ? {
                status: EditStatus.Modified,
                value: {
                  ...zone,
                  geometry: paths.find((p) => p.mapZoneType === zone.mapZoneType)?.geometry ?? [
                    [],
                    [],
                  ],
                },
              }
            : zone
        ),
      ];

      const currentMapPath = newMapZones[i];
      dispatch(setMapPaths(updatedMapPaths));

      dispatch(
        updateMapZone({
          // @ts-ignore
          id: currentMapPath.value.id,
          branchId: selectedBranch?.id,
          categoryId: activeCategoryId,
          type: activeMapZoneType,
          // @ts-ignore
          geometry: currentMapPath.value.geometry,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(setShowMapZoneDialog(false));
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            autoHideDuration: 4000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    },
    [mapPaths]
  );

  const deleteMapZoneHandler = async () => {
    dispatch(deleteMapZone(selectedMapZoneId))
      .unwrap()
      .then(() => {
        dispatch(setShowMapZoneDialog(false));
        getSelectedCategoryMapZones(selectedCategory);
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 4000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  function removeDuplicateGeometries(
    arr: {
      mapZoneType: MapZoneType;
      geometry: SemiGeoJSONMultiPoligon;
      pristine: boolean;
    }[]
  ) {
    const uniqueObjects = Array.from(
      new Set(
        arr.map((obj) =>
          JSON.stringify(obj, (key, value) =>
            key === 'geometry' ? JSON.stringify(value.sort()) : value
          )
        )
      ),
      (objString) => JSON.parse(objString)
    );

    return uniqueObjects;
  }

  const handleCancelMapZoneModal = (action: string) => {
    if (action === 'confirm') {
      dispatch(setChangeMapMode('view'));
      dispatch(setShowMapZoneDialog(false));
      dispatch(setShowCancelMapZoneDialog(false));
    }

    if (action === 'cancel') {
      dispatch(setShowCancelMapZoneDialog(false));
    }
  };

  const handleUpdateMapPathsStatus = (id: string) => {
    const updatedMapPaths = mapPaths.map((item) =>
      item.id !== id
        ? item
        : {
            ...item,
            editStatus:
              item.editStatus === EditStatus.Pristine ? EditStatus.Modified : item.editStatus,
          }
    );

    dispatch(setMapPaths(updatedMapPaths));
  };

  const isUpdateCTAActive = () => {
    if (mode === 'view') {
      return true;
    }

    if (mode === 'add') {
      return mapPaths.length === mapZoneList.length;
    }

    if (mode === 'delete') {
      const arePathsDeleted =
        mapPaths.filter((path) => path.editStatus === EditStatus.Deleted).length > 0;

      return !arePathsDeleted;
    }

    if (mode === 'edit') {
      const arePathsModified =
        mapPaths.filter((path) => path.editStatus === EditStatus.Modified).length > 0;

      return !arePathsModified;
    }

    return false;
  };

  const ClearMapZoneHandler = async () => {
    dispatch(clearMapZones(activeCategoryId))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 4000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleCancelClearMapZoneDialog = () => {
    dispatch(setShowClearMapZoneDialog(false));
  };

  const handleClearButtonClick = () => {
    dispatch(setShowClearMapZoneDialog(true));
  };

  const memoizedMapZones = useMemo(() => {
    return mapZoneList;
  }, [mapZoneList]);

  return {
    // State
    mode,
    mapPaths,
    mapZoneList: memoizedMapZones,
    defaultZoom,
    mapPathList,
    defaultCenter,
    newMapZoneList,
    activeCategoryId,
    activeMapZoneType,
    selectedMapZoneId,
    vehicleServiceState,
    outOfOrderState: vehicleServiceState
      .filter((serv) => !serv.operational)
      .map((state) => state.value),
    operationalState: vehicleServiceState
      .filter((serv) => serv.operational)
      .map((state) => state.value),
    showCancelMapZoneDialog,
    // Actions
    setMapPaths,
    createMapZone,
    setMapZoneList,
    setChangeMapMode,
    showMapZoneDialog,
    isUpdateCTAActive,
    setNewMapZoneList,
    setActiveCategoryId,
    setShowMapZoneDialog,
    setActiveMapZoneType,
    handleCreateMapZone,
    handleCategoryDelete,
    setSelectedMapZoneId,
    selectedMapZoneIndex,
    handleUpdateMapZone,
    deleteMapZoneHandler,
    handleUpdateMapPathsStatus,
    showDeleteMapZoneDialog,
    removeDuplicateGeometries,
    setSelectedMapZoneIndex,
    handleCancelMapZoneModal,
    setShowCancelMapZoneDialog,
    handleHiddenCategoryChange,
    setShowDeleteMapZoneDialog,
    getSelectedCategoryMapZones,
    handleBranchStatusStateChange,
    handleCheckmarkCategoryChange,
    showClearMapZoneDialog,
    handleClearButtonClick,
    handleCancelClearMapZoneDialog,
    setShowClearMapZoneDialog,
    ClearMapZoneHandler,
  };
};
