// System
import { useDispatch } from 'react-redux';
import { Polygon } from '@react-google-maps/api';
import React, { useEffect, useMemo, useState } from 'react';

// Components
import {
  GoogleMapsContainer,
  GoogleMapsContainerContext,
  GoogleMapsContainerContextType,
  MapOptions,
} from '@Components/shared/maps/GoogleMapsContainer';

// Features
import { toMapZonePath } from '@Features/branches/SetupMapZone/geoJSON';
import { mapZoneTypeColors } from '@Features/branches/shared/MapZoneType/useMapZoneType';

// Contracts
import { MapZoneType } from '@contracts/domain/keys';
import { AllMapZoneType } from '@contracts/domain/extendedKeys';

// Utils
import { toGoogleMapsMultiPoligon } from '@utils/map/geoJSON';

// Hooks
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  mapZones: any[];
  mapZoneType: AllMapZoneType;
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  fillOpacity: number;
};
const PolygonView: React.FC<PolygonProps> = ({
  mapZones,
  mapZoneType,
  targetMapZoneType,
  strokeColor,
  fillColor,
  fillOpacity,
}) => {
  const { mapPaths } = useBranchesMaps();

  const mapZone = mapPaths.filter((zone) => zone.mapZoneType === targetMapZoneType);

  if (mapZone === undefined) {
    return null;
  }

  if (mapZoneType !== 'ALL' && mapZoneType !== targetMapZoneType) {
    return null;
  }
  const paths = mapZone.map((mapPath) => mapPath.path);

  return (
    <Polygon paths={paths} options={{ ...polygonOptions, fillColor, strokeColor, fillOpacity }} />
  );
};

type Props = {
  categoryId: number;
  mapZoneType: AllMapZoneType;
};

export const MapZoneViewer: React.FC<Props> = ({ categoryId, mapZoneType }) => {
  const {
    defaultCenter,
    defaultZoom,
    mapPaths,
    mapZoneList,
    activeCategoryId,
    setMapPaths,
    setSelectedMapZoneIndex,
  } = useBranchesMaps();

  const { selectedBranch } = useBranches();
  const { selectedCategory } = useBranchDrawer();

  const center = {
    lat: selectedBranch?.geoLocation?.lat || defaultCenter?.lat,
    lng: selectedBranch?.geoLocation?.lon || defaultCenter.lng,
  };

  const useZoom = useState<number | undefined>(defaultZoom);
  const useCenter = useState<google.maps.LatLngLiteral | undefined>(center);

  const dispatch = useDispatch();

  const mapValue = useMemo<GoogleMapsContainerContextType>(
    () => ({
      center: useCenter,
      zoom: useZoom,
      options: MapOptions,
      mapContainerStyle: { height: '213px', width: '559px' },
    }),
    [useZoom, useCenter]
  );

  useEffect(() => {
    if (mapZoneList.length > 0) {
      const mapZones = mapZoneList
        .map((item) => item.value)
        .filter((zone) => zone?.categoryId === activeCategoryId)
        .flatMap((zone) =>
          toGoogleMapsMultiPoligon(zone.geometry).map(toMapZonePath(zone.mapZoneType, zone.id))
        );
      dispatch(setMapPaths(mapZones));
      dispatch(setSelectedMapZoneIndex(undefined));
    }
  }, [mapZoneList, selectedCategory]);

  return (
    <GoogleMapsContainerContext.Provider value={mapValue}>
      <GoogleMapsContainer>
        {mapZoneTypeColors.map((zoneType) => (
          <PolygonView
            key={zoneType.type}
            targetMapZoneType={zoneType.type}
            mapZoneType={mapZoneType}
            mapZones={mapPaths}
            strokeColor={zoneType.color}
            fillColor={zoneType.fillColor ?? zoneType.color}
            fillOpacity={zoneType.opacity}
          />
        ))}
      </GoogleMapsContainer>
    </GoogleMapsContainerContext.Provider>
  );
};
