// System
import React, { useEffect, useMemo } from 'react';

// Components
import { TableBodyEx, TableContainerEx, TableEx } from '@Components/shared/tables/components';
import {
  ColumnVisibilityPresenter,
  TableHeadColumnsPresenter,
  TableHeadPresenter,
  TableRowPresenter,
} from '@Components/shared/tables/presenters';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

// Utils
import { applyColumnVisibility } from '@utils/data/applyColumnVisibility';

//  Table
import { numberFormatter, useAnalyticTableColumns } from './useAnalyticsTableColumns';

// Redux
import { useDispatch } from 'react-redux';
import { AnalyticsItemDto } from '@contracts/api/dto/analytics/AnalyticsDto';
import { useAnalyticsTable } from '@Modal/hooks/analytics/useAnalyticsTable';

// Graphql
import { useAggregatedAviatorAnalyticsQuery } from '@Src/generated.graphql';
import { setChartYAxis } from '@Modal/reducers/analytics/table/analyticsTableSlice';

export const AnalyticsTable: React.FC = () => {
  const {
    chartValue,
    tableFilters,
    setChartXAxis,
    columnVisibility,
    setTotalWonAmount,
    setTotalBetAmount,
    setTotalNumOfSpins,
    setTotalNumOfRounds,
    analyticsListFilters,
    visiblityCustomizable,
    handleExtractDates,
    handleExtractValues,
  } = useAnalyticsTable();
  const dispatch = useDispatch();
  const columns = useAnalyticTableColumns();
  const { data, loading } = useAggregatedAviatorAnalyticsQuery({
    variables: {
      start: tableFilters?.tableStartDate,
      end: tableFilters?.tableEndDate,
    },
  });
  // Set Totals
  useEffect(() => {
    if (data) {
      const copiedData = data?.aggregatedAviatorAnalytics?.records?.slice();
      const totalRoundByDate = Object?.values(data?.aggregatedAviatorAnalytics?.totalRoundByDate);

      const TotalBetAmount = copiedData?.reduce(
        (accumulator, currentVal) =>
          currentVal?.totalBetAmount ? accumulator + currentVal?.totalBetAmount : accumulator + 0,
        0
      );
      dispatch(setTotalBetAmount(numberFormatter(TotalBetAmount)));

      const TotalNumOfSpins = copiedData?.reduce(
        (accumulator, currentVal) =>
          currentVal?.totalLosingBets && currentVal?.totalWinningBets
            ? accumulator + currentVal?.totalLosingBets + currentVal?.totalWinningBets
            : accumulator + 0,
        0
      );
      dispatch(setTotalNumOfSpins(numberFormatter(TotalNumOfSpins)));

      const TotalWonAmount = copiedData?.reduce(
        (accumulator, currentVal) =>
          currentVal?.totalWonAmount ? accumulator + currentVal?.totalWonAmount : accumulator + 0,
        0
      );
      dispatch(setTotalWonAmount(numberFormatter(TotalWonAmount)));

      const TotalNumOfRounds = (totalRoundByDate as number[])?.reduce(
        (accumulator, currentVal) => accumulator + currentVal,
        0
      );
      dispatch(setTotalNumOfRounds(numberFormatter(TotalNumOfRounds)));

      // @ts-ignore
      const chartDates = copiedData ? handleExtractDates(copiedData) : null;
      // @ts-ignore
      dispatch(setChartXAxis(chartDates));
      // @ts-ignore
      const chartValues = copiedData ? handleExtractValues(copiedData, chartValue) : null;
      // @ts-ignore
      dispatch(setChartYAxis(chartValues));
    }
  }, [data, chartValue]);

  const visibleColumns = useMemo(
    () => applyColumnVisibility(columns, analyticsListFilters),
    [columns, columnVisibility]
  );

  const header = useMemo(
    () => (
      <TableHeadPresenter>
        <TableHeadColumnsPresenter columns={visibleColumns} />
        {/* @ts-ignore */}
        <ColumnVisibilityPresenter columns={columns} content={visiblityCustomizable} />
      </TableHeadPresenter>
    ),
    [columns]
  );

  if (loading) return <LoadingScreen />;

  return (
    <TableContainerEx sx={{ height: '70vh', marginBottom: '16px' }} pageNumber={1}>
      <TableEx name="analyticsList">
        {header}
        <TableBodyEx>
          {/* @ts-ignore */}
          {data?.aggregatedAviatorAnalytics?.records?.map((row: AnalyticsItemDto) => (
            <TableRowPresenter id={row.date} key={row.date} columns={visibleColumns} row={row} />
          ))}
        </TableBodyEx>
      </TableEx>
    </TableContainerEx>
  );
};
