import { useMemo, useRef, useState } from 'react';
import { SelectBoxContext } from './SelectBoxContext';

export const SelectBoxProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const value = useMemo(() => ({ open, setOpen, anchorRef }), [open, setOpen, anchorRef]);

  return <SelectBoxContext.Provider value={value}>{children}</SelectBoxContext.Provider>;
};
