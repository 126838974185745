import { SyntheticEvent, useEffect, useMemo } from 'react';
import {
  Stack,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RadioTab } from '@Components/shared/selects/RadioTab';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { TabContext, TabList } from '@mui/lab';
import { TabEx } from '@Components/shared/selects/TabEx';
import { GoogleMapsLoader } from '@Components/shared/maps/GoogleMapsLoader';
import { useAllMapZoneTypeOptions } from '@Features/branches/shared/MapZoneType';
import { MapZoneViewer } from './MapZoneViewer';
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';
import { useDispatch } from 'react-redux';
import { setActiveMapZoneType } from '@Modal/reducers/branches/mapZoneSlice';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

const useDropDown = () => {
  const options = useAllMapZoneTypeOptions();

  const mapZoneTypeDropDown = useMemo(
    () =>
      options.map(({ key, value }) => (
        <MenuItemEx key={key} value={key} name="MapZoneType">
          {value}
        </MenuItemEx>
      )),
    [options]
  );
  return {
    mapZoneTypes: mapZoneTypeDropDown,
  };
};

export const SelectBusinessZones: React.FC = () => {
  const { categories, setSelectedCategory, selectedCategory, getSelectedCategoryMapZones } =
    useBranchDrawer();

  const selectedCategories = categories.filter((cat) => cat.selected);
  const { activeMapZoneType, setShowMapZoneDialog } = useBranchesMaps();

  const dispatch = useDispatch();

  const dropDown = useDropDown();

  useEffect(() => {
    dispatch(setActiveMapZoneType('OPERATIONAL'));
  }, []);

  if (selectedCategories.length === 0) {
    return null;
  }

  const handleSwitchCategoryId = (_: SyntheticEvent<Element, Event>, value: string) => {
    const clickedCategory = selectedCategories.find((cat) => cat.id === value);
    dispatch(setSelectedCategory(clickedCategory));
    getSelectedCategoryMapZones(clickedCategory);
  };

  const handleSwitchZoneType = (value: SelectChangeEvent<string>) => {
    dispatch(setActiveMapZoneType(value.target.value));
  };

  return (
    <TabContext value={String(selectedCategory?.id)}>
      <Stack direction="column" spacing={2}>
        <AtLocator type="readonly-text" name="SelectBusinessZones">
          <Typography variant="subtitle2">Select business zones</Typography>
        </AtLocator>
        <AtLocator type="container" name="SelectCategoryTabs" box>
          <TabList
            onChange={handleSwitchCategoryId}
            aria-label="Category tabs"
            TabIndicatorProps={{ sx: { visibility: 'collapse' } }}
            sx={{ marginX: -0.25 }}
          >
            {selectedCategories.map(({ id, name }) => (
              <TabEx
                key={id}
                value={String(id)}
                label={<RadioTab text={name} checked={selectedCategory?.id === id} />}
                sx={{ paddingX: 0.25 }}
              />
            ))}
          </TabList>
        </AtLocator>
        <FormControl>
          <InputLabel color="primary" id="SetupBranch-MapEdit">
            Map edit
          </InputLabel>
          <AtLocator type="select-field" name="SelectZones">
            <Select
              label="Map edit"
              labelId="SetupBranch-MapEdit"
              size="small"
              id="outlined-basic"
              variant="outlined"
              value={activeMapZoneType}
              onChange={handleSwitchZoneType}
            >
              {dropDown.mapZoneTypes}
            </Select>
          </AtLocator>
        </FormControl>

        <AtLocator type="special-field" name="zones" box>
          <GoogleMapsLoader
            // @ts-ignore
            component={MapZoneViewer}
            props={{ categoryId: Number(selectedCategory?.id), mapZoneType: activeMapZoneType }}
          />
        </AtLocator>

        <AtLocator type="button" name="OutlineZone">
          <Button
            variant="soft"
            size="large"
            startIcon={<AssetIcon src="crop-01" size="xxl" />}
            onClick={() => dispatch(setShowMapZoneDialog(true))}
          >
            Outline zones
          </Button>
        </AtLocator>
      </Stack>
    </TabContext>
  );
};
