import { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';

// Components
import ProtectedRoute from '@Features/account/ProtectedRoute';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';
import DashboardLayout from '@Components/layouts/dashboard/DashboardLayout';

// Pages
import { OperatorPage } from './OperatorPage';
import { HealthCheck } from '@Routes/healthcheck';
import { AuthorizationPage } from './AuthorizationPage';
import { MapPage } from './MapPage';
import { RolesPage } from './RolesPage';
import { RentalsPage } from './RentalsPage';
import { BranchPage } from './BranchPage';
import { VoucherPage } from './VoucherPage';
import { PricingPage } from './PricingPage';
import VehicleRoute from '@Routes/VehicleRoute';
import { SettingsPage } from './SettingsPage';
import CustomerRoute from '@Routes/CustomerRoute';
import { TransactionsPage } from './TransactionsPage';
import { useRoles } from '@Hooks/shared/useRoles';
import NotFoundRoute from '@Routes/NotFoundRoute';
import { ProvidersPage } from './Providers';
import { RoundsPage } from './Rounds/RoundsPage';
import { TransactionPage } from './TransactionPage/TransactionPage';
import { UsersPage } from './UsersPage';
import { CampaignsPage } from './Campaigns';
import { AnalyticsPage } from './AnalyticPage';

export const AppRouter = () => {
  // useEffect(() => {
  //   const currentSocketId = localStorage.getItem('socketId');
  //   if (currentSocketId === null) {
  //     const socket: Socket = io(`${process.env.REACT_APP_VEHICLES_SOCKET_PROXY}/backoffice`, {
  //       transports: ['websocket'],
  //       reconnection: false,
  //       reconnectionDelay: 50000,
  //     });
  //     if (!socket.connected) {
  //       socket.on('connect', () => localStorage.setItem('socketId', socket?.id));
  //     }
  //     return () => {
  //       socket.disconnect();
  //     };
  //   }
  // }, []);

  const { availableRoutes, loading } = useRoles();
  const safeRoutes: string[] = availableRoutes.map((obj: any) => obj.path);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Suspense fallback="">
      <Routes>
        <Route element={<DashboardLayout />}>
          <Route element={<ProtectedRoute allowedRoutes={safeRoutes} />}>
            {/* <Route path="/map" Component={MapPage} />
            <Route path="/rentals" Component={RentalsPage} />
            <Route path="/branches" Component={BranchPage} />
            <Route path="/voucher" Component={VoucherPage} />
            <Route path="/pricing" Component={PricingPage} />
            <Route path="/vehicles" Component={VehicleRoute} />
            <Route path="/settings" Component={SettingsPage} />
            <Route path="/customers" Component={CustomerRoute} />
            <Route path="/transactions" Component={TransactionsPage} />
            <Route path="/roles" Component={RolesPage} /> */}
            <Route path="/providers" Component={ProvidersPage} />
            <Route path="/rounds" Component={RoundsPage} />
            <Route path="/transactions" Component={TransactionPage} />
            <Route path="/roles" Component={RolesPage} />
            <Route path="/users" Component={UsersPage} />
            <Route path="/campaigns" Component={CampaignsPage} />
            <Route path="/analytics" Component={AnalyticsPage} />
          </Route>
          <Route path="/notFound" Component={NotFoundRoute} />
        </Route>
        <Route path="/" Component={AuthorizationPage} />
        <Route path="/operator" Component={OperatorPage} />
        <Route path="/healthcheck" element={<HealthCheck />} />
      </Routes>
    </Suspense>
  );
};
