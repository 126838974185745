import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  BalanceModalDialogFormContext,
  BalanceModalDialogFormState,
  BalanceModalDialogSchemaDefault,
  BalanceModalDialogFormStateDefault,
} from '@Forms/customers/balance/BalanceModalDialogFormContext';

export const BalanceModalDialogFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<BalanceModalDialogFormState>>>(
    BalanceModalDialogSchemaDefault
  );
  const methods = useForm({
    defaultValues: BalanceModalDialogFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <BalanceModalDialogFormContext.Provider value={value}>
      {children}
    </BalanceModalDialogFormContext.Provider>
  );
};
