import React from 'react';
// System
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';

// Redux
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { OperatorScannerManuals } from '../OperatorScannerManuals';

// Contracts
import { BatteryLevel, IotStatus, VehicleServiceState } from '@contracts/domain/keys';

// Features
import { BatteryLevelIconLabel } from '@Features/vehicles/shared/BatteryLevel';
import { VehicleServiceStateLabel } from '@Features/vehicles/shared/VehicleServiceState';

// Styles
import Styles from './OperatorScannerFooter.module.scss';
import { IotStatusLabel } from '@Features/vehicles/shared/IotStatus';

export const OperatorScannerFooter: React.FC = () => {
  const {
    scannedVehicles,
    removeScannedVehicle,
    handleScannedVehiclesDeploy,
    handleScannedVehiclesApproach,
  } = useOperatorScan();

  return (
    <Box className={Styles.OperatorScannerFooter}>
      <OperatorScannerManuals />
      <Box className={Styles.ScannerFooterContainer}>
        {/* Scanner command options */}
        <Box className={Styles.ScannerHeader}>
          <Typography fontWeight={900} fontSize={'18px'}>
            Scan vehicles
          </Typography>
          <Box className={Styles.ScannedVehicleLength}>
            <Typography color="#FFF" fontWeight={700} fontSize={'12px'}>
              {scannedVehicles?.length}
            </Typography>
          </Box>
        </Box>
        <Box
          className={
            scannedVehicles?.length > 0
              ? Styles.ScannedVehiclesContainer_Not_Empty
              : Styles.ScannedVehiclesContainer_Empty
          }
        >
          {scannedVehicles?.length > 0 ? (
            scannedVehicles?.map((veh) => {
              return (
                <Box className={Styles.ScannedVehicleCard} key={veh?.id}>
                  <Box className={Styles.ScannedVehicleTitle}>
                    <Typography fontWeight={900} fontSize={18}>
                      {veh?.qrCode}
                    </Typography>
                    <Box className={Styles.TitleGap}>
                      <BatteryLevelIconLabel
                        level={veh?.deviceInfo?.batteryLevel as BatteryLevel}
                        batteryPercentage={+veh?.deviceInfo?.batteryStage ?? undefined}
                      />
                      <Box onClick={() => removeScannedVehicle(veh?.id)}>
                        <AssetIcon src="x-close-circled" className={Styles.AssetIcon} />
                      </Box>
                    </Box>
                  </Box>
                  <Box className={Styles.ScannedVehicleFooter}>
                    <Box className={Styles.Title}>
                      <Typography fontWeight={600} fontSize={12}>
                        IoT:
                      </Typography>
                      <IotStatusLabel status={veh?.iotStatus as IotStatus} />
                    </Box>
                    <Box className={Styles.Title}>
                      <VehicleServiceStateLabel
                        variant="ghost"
                        status={veh?.serviceState as VehicleServiceState}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box className={Styles.EmptyScannedVehiclesContainer}>
              <AssetIcon src="icons/operator/ic_empty_scan" className={Styles.AssetIcon} />
              <Typography fontSize={16} color="#637381" sx={{ width: '70%' }} textAlign={'center'}>
                Start scanning vehicles to approach or deploy them
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={Styles.FooterActionsContainer}>
          <Button
            disabled={!scannedVehicles?.length}
            className={scannedVehicles?.length ? Styles.ScanCTA_Active : Styles.ScanCTA}
            variant="contained"
            onClick={handleScannedVehiclesApproach}
          >
            Approach
          </Button>
          <Button
            disabled={!scannedVehicles?.length}
            className={scannedVehicles?.length ? Styles.ScanCTA_Active : Styles.ScanCTA}
            variant="contained"
            onClick={handleScannedVehiclesDeploy}
          >
            Deploy
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
