import { useCallback, useEffect, useMemo, useState } from 'react';
// System
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { useBranches } from '../branches/useBranch';
import { useGeneralStore } from '../useGeneralStore';
import { useBranchesMaps } from '../branches/useBranchMap';
import { useBranchesTable } from '../branches/useBranchesTable';

// Actions
import { getMapZoneTypes } from '@Modal/reducers/generalSlice';
import { fetchBranches } from '@Modal/reducers/branches/branchSlice';
import { setAreLiveMapFiltersChecked } from '@Modal/reducers/vehicles/vehicles/vehicleMapSlice';

// Types
import { LiveMapFiltersType } from './types';
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { HashTable } from '@utils/services/liveMapSocket';
import { BranchesType, MapZoneItemType } from '@Modal/reducers/branches/types';

// Enum
import { dashboardMapFilters } from './vehicleFilters.enum';

export const useLiveMapFilters = (
  mapZonesOnMap: MapZoneItemType[],
  // Array of vehicles on which we are handling all of the filters
  originalVehicles: HashTable,
  vehiclesOnMap: HashTable,
  setFilteredVehicleList: (arg: HashTable) => void,
  setFilteredMapZoneList: (arg: MapZoneItemType[]) => void
) => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  // Original array of map zone used to set data to it's original value in case if none of the filters are selected
  const { mapZoneList } = useBranchesMaps();
  const { branches, brands } = useBranches();
  const { vehicleServiceStates, mapZoneTypes } = useGeneralStore();
  const { selectedFilters: branchFilters } = useBranchesTable();

  // State
  const [batteryRangeValue, setBatteryRangeValue] = useState({ from: 0, to: 100 });
  const [areSomeFiltersChecked, setAreFiltersChecked] = useState(false);
  const [liveMapFilters, setLiveMapFilters] = useState<LiveMapFiltersType>({
    branch: [],
    serviceState: [],
    batteryLevel: dashboardMapFilters.batteryLevel,
    iotStatus: dashboardMapFilters.iotStatus,
    rentalStatus: dashboardMapFilters.rentalStatus,
    vehicleType: dashboardMapFilters.vehicleType,
    powerStatusData: dashboardMapFilters.powerStatus,
    areaType: [],
    batteryPercentage: { from: 0, to: 100 },
    babyBranches: [],
  });

  const { showOperatorFiltersDrawer, selectedFilters } = useSelector(
    (state: StoreInterface) => state?.operatorFilters
  );

  useEffect(() => {
    if (!mapZoneTypes.length) {
      dispatch(getMapZoneTypes());
    }
  }, []);

  // Fetch All Branches For Operator Only
  const handleGetAllBranchesForMapPage = () => {
    dispatch(fetchBranches({ selectedFilters: branchFilters }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const generateBabyBranches = (id: string) => {
    const thirdLevelLevelBabyBranches = branches.filter(
      (branch: BranchesType) => branch?.parentId === id
    );

    const thirdLevelLevelBabyBranchesIds = thirdLevelLevelBabyBranches.map((branch) => branch?.id);

    const babyBranches = thirdLevelLevelBabyBranches.map((branch) => {
      return {
        ...branch,
        isChecked: false,
        brandId: id,
        dataLength: Object.values(originalVehicles)?.filter((veh) =>
          thirdLevelLevelBabyBranchesIds.includes(veh?.category?.branchId)
        )?.length,
        key: 'category',
        nestedKey: 'branchId',
      };
    });

    const dataLength = Object.values(originalVehicles)?.filter((veh) =>
      thirdLevelLevelBabyBranchesIds.includes(veh?.category?.branchId)
    )?.length;

    return { babyBranches, dataLength };
  };

  const generateBrandsWithBranch = () => {
    const generatedBrands = branches
      .filter((br) => br.level === 2)
      ?.map((brand) => {
        return {
          id: brand?.id,
          title: brand?.name,
          isChecked: false,
          dataLength: generateBabyBranches(brand?.id)?.dataLength,
          childBranches: generateBabyBranches(brand?.id)?.babyBranches,
          key: 'category',
          nestedKey: 'branchId',
          value: brand?.id,
        };
      });

    return generatedBrands;
  };

  const generateVehicleServiceStates = () => {
    return vehicleServiceStates?.map((service) => {
      return {
        title: service?.value,
        value: service?.key,
        isChecked: false,
        operational: service?.key === 'FUNCTIONAL' || service?.key === 'INSPECT',
        key: 'serviceState',
      };
    });
  };

  const generateMapZoneTypes = () => {
    return mapZoneTypes?.map((zone) => {
      return {
        title: zone?.value,
        value: zone?.key,
        isChecked: false,
      };
    });
  };

  const handleSetLiveMapFilters = useCallback(() => {
    return setLiveMapFilters({
      branch: generateBrandsWithBranch(),
      serviceState: generateVehicleServiceStates(),
      batteryLevel: dashboardMapFilters.batteryLevel,
      iotStatus: dashboardMapFilters.iotStatus,
      rentalStatus: dashboardMapFilters.rentalStatus,
      vehicleType: dashboardMapFilters.vehicleType,
      powerStatusData: dashboardMapFilters.powerStatus,
      areaType: generateMapZoneTypes(),
      batteryPercentage: { from: 0, to: 100 },
      babyBranches: [],
    });
  }, [branches, brands, originalVehicles]);

  const handleClearLiveMapFilters = () => {
    setLiveMapFilters({
      branch: generateBrandsWithBranch(),
      serviceState: generateVehicleServiceStates(),
      batteryLevel: dashboardMapFilters.batteryLevel,
      iotStatus: dashboardMapFilters.iotStatus,
      rentalStatus: dashboardMapFilters.rentalStatus,
      vehicleType: dashboardMapFilters.vehicleType,
      powerStatusData: dashboardMapFilters.powerStatus,
      areaType: generateMapZoneTypes(),
      batteryPercentage: { from: 0, to: 100 },
      babyBranches: [],
    });

    setBatteryRangeValue({ from: 0, to: 100 });
  };

  useEffect(() => {
    if (Object.keys(originalVehicles)?.length > 0 && liveMapFilters?.branch?.length === 0) {
      handleSetLiveMapFilters();
    }
  }, [branches, brands, originalVehicles]);

  // Filter handlers
  useEffect(() => {
    const flattenFilters = [
      ...liveMapFilters.branch,
      ...liveMapFilters.serviceState,
      ...liveMapFilters.batteryLevel,
      ...liveMapFilters.iotStatus,
      ...liveMapFilters.rentalStatus,
      ...liveMapFilters.vehicleType,
      ...liveMapFilters.powerStatusData,
      ...liveMapFilters.areaType,
      ...liveMapFilters.babyBranches,
    ];

    const areNoneChecked = flattenFilters.some((filter) => filter.isChecked);
    const areMapZoneFiltersChecked = liveMapFilters.areaType.some((area) => area.isChecked);
    const isBatteryPercentageRangeChanged =
      liveMapFilters.batteryPercentage.from === 0 && liveMapFilters.batteryPercentage.to === 100;

    setAreFiltersChecked(areNoneChecked || !isBatteryPercentageRangeChanged);
    dispatch(setAreLiveMapFiltersChecked(areNoneChecked || !isBatteryPercentageRangeChanged));

    // If none of the filters are checked, restore the original set of vehicles on the map.
    if (!areNoneChecked && isBatteryPercentageRangeChanged) {
      setFilteredVehicleList({});
      setFilteredMapZoneList(mapZoneList);
    }

    if (!areMapZoneFiltersChecked) {
      setFilteredMapZoneList(mapZoneList);
    }
  }, [liveMapFilters]);

  // @ts-ignore
  function filterVehiclesByActiveFilters(vehicles, filters) {
    const filteredVehicles = {};
    const filtersWithoutIoT = filters.filter((filt: { key: string }) => filt.key !== 'iotStatus');
    const iotStatuses = filters.filter((filt: { key: string }) => filt.key === 'iotStatus');

    Object.entries(vehicles).forEach(([id, vehicle]) => {
      // Group filters by type
      const filterGroups = {};
      // @ts-ignore
      filtersWithoutIoT.forEach((filter) => {
        // @ts-ignore
        if (!filterGroups[filter.key]) {
          // @ts-ignore
          filterGroups[filter.key] = [];
        }
        // @ts-ignore
        filterGroups[filter.key].push(filter);
      });

      // Apply filters by type
      const filterResults = Object.values(filterGroups).map((filterGroup) => {
        // @ts-ignore
        if (filterGroup.length === 1) {
          // Single filter, apply AND logic
          // @ts-ignore.env
          return filterGroup.every((filter) => {
            const { key, nestedKey } = filter;
            // @ts-ignore
            if (nestedKey) {
              // @ts-ignore
              return vehicle[key]?.[nestedKey] && vehicle[key]?.[nestedKey] === filter.value;
            } else {
              // @ts-ignore
              return vehicle[key] && vehicle[key] === filter.value;
            }
          });
        } else {
          // Multiple filters of the same type, apply OR logic
          // @ts-ignore
          return filterGroup.some((filter) => {
            const { key, nestedKey } = filter;
            if (nestedKey) {
              // @ts-ignore
              return vehicle[key]?.[nestedKey] && vehicle[key]?.[nestedKey] === filter.value;
            } else {
              // @ts-ignore
              return vehicle[key] && vehicle[key] === filter.value;
            }
          });
        }
      });

      // Apply AND logic across all filter results
      if (filterResults.every((result) => result)) {
        // @ts-ignore
        filteredVehicles[id] = vehicle;
      }
    });

    const selectedIoTStatuses = new Set(
      iotStatuses.map((it: { value: string }) => it.value.toLowerCase())
    );

    const filterByIoTStatus = Object.entries(originalVehicles).reduce((acc, [id, vehicle]) => {
      if (selectedIoTStatuses.has(vehicle.iotStatus.toLowerCase())) {
        // @ts-ignore
        acc[id] = vehicle; // Include this vehicle if its IoT status is selected
      }
      return acc;
    }, {});

    if (filtersWithoutIoT?.length > 0) {
      return { ...filteredVehicles, ...filterByIoTStatus };
    } else {
      return filterByIoTStatus;
    }
  }

  // Filter data based on selected filters
  useEffect(() => {
    const flattenFilters = [
      ...liveMapFilters.serviceState,
      ...liveMapFilters.batteryLevel,
      ...liveMapFilters.iotStatus,
      ...liveMapFilters.rentalStatus,
      ...liveMapFilters.vehicleType,
      ...liveMapFilters.powerStatusData,
      ...liveMapFilters.areaType,
      ...liveMapFilters.babyBranches,
    ];

    const checkedFilters = flattenFilters.filter((va) => va.isChecked);
    if (checkedFilters.length > 0) {
      const filteredVehicleList = filterVehiclesByActiveFilters(originalVehicles, checkedFilters);
      setFilteredVehicleList(
        Object.keys(filteredVehicleList).length > 0 ? filteredVehicleList : {}
      );
    }
  }, [liveMapFilters]);

  const handleFilterVehiclesByBranch = (branch: BranchesType) => {
    // Find the parent branch and update its child branches' checked states
    const parentBranch = liveMapFilters.branch.find((parent) => parent?.id === branch?.parentId);
    const updatedBabyBranches = parentBranch?.childBranches.map((child) =>
      child?.id === branch?.id ? { ...child, isChecked: !child.isChecked } : child
    );

    // Update the parent branch with the new state of child branches
    const updatedParentBranch = {
      ...parentBranch,
      childBranches: updatedBabyBranches,
      isChecked: updatedBabyBranches?.every((child) => child.isChecked),
    };

    const selectedBranches = updatedBabyBranches
      ?.filter((baby) => baby.isChecked)
      .map((branch) => {
        return {
          isChecked: true,
          value: branch?.id,
          key: 'category',
          nestedKey: 'branchId',
        };
      });

    // Update the live map filters with the new branch structure
    const updatedLiveMapFilters = liveMapFilters.branch.map((branchFilt) =>
      branchFilt?.id === parentBranch?.id ? updatedParentBranch : branchFilt
    );

    // Update the state with the new set of filtered vehicles and live map filters
    // @ts-ignore
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      branch: updatedLiveMapFilters,
      babyBranches: selectedBranches,
    }));
  };

  const handleFilterVehiclesByBrand = (brand: {
    id: string;
    title: string;
    isChecked: boolean;
    childBranches: BranchesType[];
  }) => {
    // Toggle the isChecked state for all child branches of the brand
    const updatedBabyBranches = brand?.childBranches.map((child) => ({
      ...child,
      isChecked: !child.isChecked, // Toggle state for a bulk action on the brand
    }));

    // Determine the new checked state for the brand based on its child branches
    const updatedBrandAndKids = {
      ...brand,
      childBranches: updatedBabyBranches,
      isChecked: updatedBabyBranches.every((child) => child.isChecked),
      key: 'category',
      nestedKey: 'brandId',
      value: brand?.id,
    };

    const selectedBranches = updatedBabyBranches
      ?.filter((baby) => baby.isChecked)
      .map((branch) => {
        return {
          isChecked: true,
          value: branch?.id,
          key: 'category',
          nestedKey: 'branchId',
        };
      });

    // Update the live map filters with the new brand state
    const updatedBrands = liveMapFilters.branch.map((va) =>
      va?.id === brand?.id ? updatedBrandAndKids : va
    );

    // Update the state with the new filters and the filtered set of vehicles
    // @ts-ignore
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      branch: updatedBrands,
      babyBranches: selectedBranches,
    }));
  };

  const handleFilterServiceStateCondition = (targetServiceState: string, isChecked: boolean) => {
    // Determine if we are targeting operational services
    const targetingOperational = targetServiceState === 'OPERATIONAL';

    const updatedServiceStates = liveMapFilters?.serviceState.map((service) => {
      const isOperationalService = service.operational;
      if (isOperationalService && targetingOperational) {
        return { ...service, isChecked: !service.isChecked };
      }

      if (!isOperationalService && !targetingOperational) {
        return { ...service, isChecked: !service.isChecked };
      }
      return service;
    });

    setLiveMapFilters({ ...liveMapFilters, serviceState: updatedServiceStates });
  };

  const handleFilterVehiclesByService = (serviceState: {
    title: string;
    value: string;
    isChecked: boolean;
    operational: boolean;
  }) => {
    if (serviceState?.value === 'OUT_OF_ORDER' || serviceState?.value === 'OPERATIONAL') {
      handleFilterServiceStateCondition(serviceState?.value, serviceState?.isChecked);
    } else {
      const updatedServiceState = liveMapFilters?.serviceState?.map((serv) =>
        serv?.value === serviceState?.value ? { ...serv, isChecked: !serv?.isChecked } : serv
      );

      setLiveMapFilters({ ...liveMapFilters, serviceState: updatedServiceState });
    }
  };

  const handleFilterByBatteryLevel = (batteryLevel: {
    batteryLevel: string;
    iconName: string;
    isChecked: boolean;
  }) => {
    // Directly update the batteryLevel filter for the given battery level
    const updatedBatteryFilters = liveMapFilters.batteryLevel.map((bat) =>
      bat.iconName === batteryLevel.iconName ? { ...bat, isChecked: !bat.isChecked } : bat
    );

    // Update liveMapFilters with the new battery level filters
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      batteryLevel: updatedBatteryFilters,
    }));
  };

  const handleFilterByBatteryPercentage = (batteryPercentage: { from: number; to: number }) => {
    // Update liveMapFilters with the new battery percentage range
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      batteryPercentage: batteryPercentage,
    }));

    const dataToFilter = areSomeFiltersChecked ? vehiclesOnMap : originalVehicles;

    if (batteryRangeValue?.from > batteryRangeValue?.to) {
      setBatteryRangeValue({
        ...batteryRangeValue,
        from: batteryRangeValue.to,
        to: batteryRangeValue.from,
      });

      // Filter vehicles based on the updated battery percentage range
      const filteredVehicles = Object.entries(dataToFilter).reduce((acc, [id, vehicle]) => {
        const powerStatus = +vehicle.deviceInfo.powerStatus;
        if (powerStatus >= batteryPercentage.to && powerStatus <= batteryPercentage.from) {
          // @ts-ignore
          acc[id] = vehicle; // Include this vehicle if its power status is within the selected range
        }
        return acc;
      }, {});

      setFilteredVehicleList(filteredVehicles);
    } else {
      // Filter vehicles based on the updated battery percentage range
      const filteredVehicles = Object.entries(dataToFilter).reduce((acc, [id, vehicle]) => {
        const powerStatus = +vehicle.deviceInfo.powerStatus;
        if (powerStatus >= batteryPercentage.from && powerStatus <= batteryPercentage.to) {
          // @ts-ignore
          acc[id] = vehicle; // Include this vehicle if its power status is within the selected range
        }
        return acc;
      }, {});

      setFilteredVehicleList(filteredVehicles);
    }
  };

  const handleFilterByIoTStatus = (iot: { iotStatusTitle: string; isChecked: boolean }) => {
    // Update the IoT status filter directly within liveMapFilters
    const updateIoTStatusFilter = liveMapFilters.iotStatus.map((it) =>
      it.iotStatusTitle === iot.iotStatusTitle ? { ...it, isChecked: !it.isChecked } : it
    );

    // Apply the updated IoT status filter to liveMapFilters
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      iotStatus: updateIoTStatusFilter,
    }));
  };

  const handleFilterByRentalStatus = (rentalStatus: {
    rentalStatusTitle: string;
    isChecked: boolean;
  }) => {
    // Directly update the rentalStatus filter for the given rental status
    const updatedRentalStatusFilters = liveMapFilters.rentalStatus.map((rental) =>
      rental.rentalStatusTitle === rentalStatus.rentalStatusTitle
        ? { ...rental, isChecked: !rental.isChecked }
        : rental
    );

    // Update liveMapFilters with the new rental status filters
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      rentalStatus: updatedRentalStatusFilters,
    }));
  };

  const handleFilterByVehicleType = (vehicleType: {
    vehicleTypeTitle: string;
    isChecked: boolean;
  }) => {
    // Directly update the vehicle Type filter for the given rental status
    const updatedVehicleTypeFilters = liveMapFilters.vehicleType.map((type) =>
      type.vehicleTypeTitle === vehicleType.vehicleTypeTitle
        ? { ...type, isChecked: !type.isChecked }
        : type
    );

    // Update liveMapFilters with the new vehicle Type filters
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      vehicleType: updatedVehicleTypeFilters,
    }));
  };

  const handleFilterByPowerStatus = (powerStatus: {
    powerStatusTitle: string;
    isChecked: boolean;
    value?: string;
  }) => {
    // Update the powerStatusData filters directly based on the input
    const updatedPowerStatusFilters = liveMapFilters.powerStatusData.map((pw) =>
      pw.powerStatusTitle === powerStatus.powerStatusTitle
        ? { ...pw, isChecked: !pw.isChecked }
        : pw
    );

    // Apply the updated filters to liveMapFilters
    setLiveMapFilters((prevFilters) => ({
      ...prevFilters,
      powerStatusData: updatedPowerStatusFilters,
    }));
  };

  const handleFilterByAreaType = (area: { title: string; value: string; isChecked: boolean }) => {
    const updateAreaStatusFilter = liveMapFilters?.areaType.map((zone) =>
      area.title === zone.title ? { ...zone, isChecked: !zone.isChecked } : zone
    );

    const allSelectedMapZones = updateAreaStatusFilter
      .filter((zone) => zone.isChecked)
      .map((zone) => zone.value);

    const filteredMapZones = mapZonesOnMap.filter((zone) =>
      // @ts-ignore
      allSelectedMapZones.includes(zone?.value?.mapZoneType)
    );

    const filteredOriginalMapZones = mapZoneList.filter((zone) =>
      allSelectedMapZones.includes(zone?.value?.mapZoneType)
    );

    setLiveMapFilters({
      ...liveMapFilters,
      areaType: updateAreaStatusFilter,
    });

    setFilteredMapZoneList([...filteredMapZones, ...filteredOriginalMapZones]);
  };

  const handleRangeValueChange = (range: number, key: string) => {
    if (!isNaN(range)) {
      if (range >= 0 && range <= 100) {
        setBatteryRangeValue({ ...batteryRangeValue, [key]: range });
      } else if (range > 100) {
        setBatteryRangeValue({ ...batteryRangeValue, [key]: 100 });
      } else if (range < 0) {
        setBatteryRangeValue({ ...batteryRangeValue, [key]: 0 });
      }
    }
  };

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  return {
    // Store
    liveMapFilters: useMemo(() => {
      return liveMapFilters;
    }, [liveMapFilters]),
    operationalStates: liveMapFilters?.serviceState?.filter((service) => service?.operational),
    outOfOrderState: liveMapFilters?.serviceState?.filter((service) => !service?.operational),
    vehicleTypes: liveMapFilters?.vehicleType?.map((type) => type.vehicleTypeTitle),
    selectedFilters,
    batteryRangeValue,
    areSomeFiltersChecked,
    showOperatorFiltersDrawer,
    batteryRangeValueApplied: batteryRangeValue?.from !== 0 || batteryRangeValue?.to !== 100,
    // Actions
    generateMapZoneTypes,
    handleRangeValueChange,
    handleFilterByAreaType,
    handleFilterByIoTStatus,
    generateBrandsWithBranch,
    handleClearLiveMapFilters,
    handleFilterByPowerStatus,
    handleFilterByVehicleType,
    handleFilterByBatteryLevel,
    handleFilterByRentalStatus,
    handleFilterVehiclesByBrand,
    generateVehicleServiceStates,
    handleFilterVehiclesByBranch,
    handleFilterVehiclesByService,
    handleGetAllBranchesForMapPage,
    handleFilterByBatteryPercentage,
  };
};
