import { Box, Stack, Typography } from '@mui/material';

import Styles from './AnalyticsTotals.module.scss';
import { useAnalyticsTable } from '@Modal/hooks/analytics/useAnalyticsTable';

export const AnalyticsTotals: React.FC = () => {
  const { totalBetAmount, totalNumOfRounds, totalNumOfSpins, totalWonAmount } = useAnalyticsTable();

  return (
    <Stack className={Styles.AnalyticsTotals}>
      <Box className={Styles.FirstTotal}>
        <Typography className={Styles.FirstTotalLabel}>Total Rounds</Typography>
        <Typography className={Styles.FirstTotalValue}> {totalNumOfRounds} </Typography>
      </Box>
      <Box className={Styles.ThirdTotal}>
        <Typography className={Styles.ThirdTotalLabel}>Total Bets</Typography>
        <Typography className={Styles.ThirdTotalValue}> {totalNumOfSpins}</Typography>
      </Box>
      <Box className={Styles.FourthTotal}>
        <Typography className={Styles.FourthTotalLabel}>Total Bet Amount</Typography>
        <Typography className={Styles.FourthTotalValue}> {totalBetAmount}</Typography>
      </Box>
      <Box className={Styles.FifthTotal}>
        <Typography className={Styles.FifthTotalLabel}>Total Won Amount</Typography>
        <Typography className={Styles.FifthTotalValue}> {totalWonAmount}</Typography>
      </Box>
    </Stack>
  );
};
