// System
import { Button, Typography, Stack, Checkbox } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Form

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';

// Styles
import Styles from './DeleteRoleModalDialog.module.scss';
import { useRolesDrawer } from '@Modal/hooks/roles/useRolesDrawer';

export const DeleteRoleModalDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    selectedRoleData,
    showDeleteRoleModalDialog,
    setShowDeleteRoleModalDialog,
    handleRoleDelete,
  } = useRolesDrawer();

  return (
    <ClosableModal
      name="deleteRoleModalDialog"
      title="Confirmation"
      open={showDeleteRoleModalDialog}
      onClose={() => dispatch(setShowDeleteRoleModalDialog(false))}
      className={Styles.DeleteCustomerModalDialog}
    >
      <>
        <CreateDialogLayout>
          <Typography variant="body2">
            Are you sure you want delete{' '}
            {selectedRoleData &&
              `${selectedRoleData?.account?.accountDetails[0]?.firstName} ${selectedRoleData?.account?.accountDetails[0]?.lastName}`}
          </Typography>
          <CreateDialogFooterLayout>
            <AtLocator type="button" name="Cancel">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => dispatch(setShowDeleteRoleModalDialog(false))}
              >
                Cancel
              </Button>
            </AtLocator>
            <AtLocator type="button" name="Submit">
              <Button
                type="submit"
                color="error"
                variant="contained"
                onClick={() => handleRoleDelete(selectedRoleData?.id)}
              >
                Delete
              </Button>
            </AtLocator>
          </CreateDialogFooterLayout>
        </CreateDialogLayout>
      </>
    </ClosableModal>
  );
};
