import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { CreateProviderForm } from './CreateProviderForm';
import { useCreateProvider } from '@Modal/hooks/providers/useCreateProvider';

// Styles
import Styles from './CreateProviderModalDialog.module.scss';

export const CreateProviderModalDialog = () => {
  const { showCreateProviderForm, handleCreateCancel } = useCreateProvider();

  return (
    <>
      {showCreateProviderForm && (
        <ClosableModal
          name="CreateProvider"
          title="Add Provider"
          open={showCreateProviderForm}
          onClose={handleCreateCancel}
          className={Styles.CreateProviderModalDialog}
          sx={{ height: '90vh', overflowY: 'scroll' }}
        >
          <CreateProviderForm />
        </ClosableModal>
      )}
    </>
  );
};
