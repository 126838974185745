import { Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, SystemStyleObject } from '@mui/system';

// Components
import { Details } from '../tabs/Details';
import { IoTModule } from '../tabs/IoTModule';
import { VehicleRentals } from '../tabs/Rentals';
import { TabEx } from '@Components/shared/selects/TabEx';
import { useTabs } from '@Components/shared/hooks/useTabs';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabsDrawerLayout } from '@Components/shared/layouts/TabsDrawerLayout';
import { Activity } from '../tabs/Activity/Activity';

// Features
import { VehicleNotes } from '@Features/vehicles/notes/VehicleNotes';

// Styles
import Styles from './SetupVehicleTabs.module.scss';

const useSx = () => {
  return {
    panel: { padding: 0, height: '100%' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupVehicleTabs: React.FC = () => {
  const { currentTab, handleSwithTab } = useTabs('1');
  const styles = useSx();

  return (
    <Box className={Styles.SetupVehicleTabs}>
      <TabContext value={currentTab}>
        <TabsDrawerLayout
          header={
            <AtLocator type="tab-list" name="SetupVehicle">
              <TabList
                className={Styles.TablList}
                onChange={handleSwithTab}
                aria-label="Information tabs"
              >
                <TabEx label="Details" value="1" />
                <TabEx label="Rentals" value="2" />
                <TabEx label="Notes" value="3" />
                {/* Hide temporary before we create damages actual tab */}
                {/* <TabEx label="Damages" value="4" /> */}
                <TabEx label="Activity" value="4" />
                <TabEx label="IoT module" value="7" />
              </TabList>
            </AtLocator>
          }
        >
          <TabPanel value="1" sx={styles.panel}>
            <Details />
          </TabPanel>
          <TabPanel value="2" sx={styles.panel}>
            <VehicleRentals />
          </TabPanel>
          <TabPanel value="3" sx={styles.panel}>
            <VehicleNotes />
          </TabPanel>
          <TabPanel value="4" sx={styles.panel}>
            <Activity />
          </TabPanel>
          <TabPanel value="7" sx={styles.panel}>
            <IoTModule />
          </TabPanel>
        </TabsDrawerLayout>
      </TabContext>
    </Box>
  );
};
