// System
import { FormProvider } from 'react-hook-form';

// Components
import { SetupVehicleCategoryHeader } from './SetupVehicleCategoryHeader';
import { SetupVehicleCategoryContent } from './SetupVehicleCategoryContent';
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';

// Redux
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

// Form
import { useSetupCategoryForm } from '@Forms/vehicles/SetupCategoryFormContext';

export const SetupVehicleCategoryForm: React.FC = () => {
  const methods = useSetupCategoryForm();

  const { handleCreateCancel, handleSubmitVehicleCategoryForm, submittable } =
    useVehicleCategoryDrawer();

  return (
    <FormProvider {...methods}>
      <TabDrawerLayout
        header={
          <DrawerHeaderLayout onClose={handleCreateCancel}>
            <SetupVehicleCategoryHeader />
          </DrawerHeaderLayout>
        }
        footer={
          <SaveSettingsButtonPanel
            onSaveClick={handleSubmitVehicleCategoryForm}
            saveDisabled={!submittable}
            onCancelClick={handleCreateCancel}
          />
        }
      >
        <SetupVehicleCategoryContent />
      </TabDrawerLayout>
    </FormProvider>
  );
};
