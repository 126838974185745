import { Drawer } from '@mui/material';

// Form
import { SetupVehicleCategoryForm } from './SetupVehicleCategoryForm';

// Redux
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

export const SetupVehicleCategoryDrawer: React.FC = () => {
  const { showSelectedCategoryDrawer, handleCreateCancel } = useVehicleCategoryDrawer();

  return (
    <Drawer anchor="right" open={showSelectedCategoryDrawer} onClose={handleCreateCancel}>
      <SetupVehicleCategoryForm />
    </Drawer>
  );
};
