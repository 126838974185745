import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  showCreateAviatorRoleForm: false,
  showCancelCreateAviatorRoleForm: false,
};

const createAviatorRoleSlice = createSlice({
  name: 'createAviatorRoleSlice',
  initialState,
  reducers: {
    setShowCreateAviatorRoleForm: (state, { payload }) => {
      state.showCreateAviatorRoleForm = payload;
    },
    setShowCancelCreateAviatorRoleForm: (state, { payload }) => {
      state.showCancelCreateAviatorRoleForm = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: createAviatorRoleReducer } = createAviatorRoleSlice;
export const { setShowCreateAviatorRoleForm, setShowCancelCreateAviatorRoleForm } =
  createAviatorRoleSlice.actions;
