// System
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import {
  EnumTableColumnHeader,
  StringTableColumnHeader,
  TableColumnHeader,
} from '@Components/shared/tables/columns';
import { NumberRangeTableColumnHeader } from '@Components/shared/tables/columns/NumberRangeTableColumnHeader';
import { DateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/DateTableColumnHeader';

// Contracts
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { Filterable } from '@contracts/common/traits/Filterable';
import { FreebetItemDto } from '@contracts/api/dto/freebet/FreebetDto';

// Utils
import {
  FreebetListColumns,
  FreebetListFilter,
  freebetListColumns,
} from '@utils/tables/freebets/freebetsListFilter';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Redux
import { useDispatch } from 'react-redux';
import { useFreebetsTable } from '@Modal/hooks/freebets/useFreebetsTable';

type HeaderOptions = {
  filter: Filterable<FreebetListFilter>;
};

type Columns = TableColumnDescriptionArray<
  FreebetItemDto,
  HeaderOptions,
  typeof freebetListColumns
>;

type Column = TableColumnDescription<FreebetItemDto, HeaderOptions, FreebetListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useFreebetTableColumns = () => {
  const styles = useSx();
  const ellipsis = useEllipsis();
  const dispatch = useDispatch();
  const { handleFilterSelect, setFreebetTableFilters } = useFreebetsTable();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'freebet._id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'id'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>{row?._id && <TruncateText text={row?._id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'freebet.campaignId',
          title: 'Campaign ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'campaignId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>
              {row?.campaignId && (
                <TruncateText text={row?.campaignId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebet.requestId',
          title: 'Request ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'requestId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>
              {row?.requestId && (
                <TruncateText text={row?.requestId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebet.providerId',
          title: 'Provider ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>
              {row?.providerId && (
                <TruncateText text={row?.providerId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebet.playerId',
          title: 'Player ID',
          sx: { minWidth: '130px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'playerId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.playerId}</Box>,
        },
        {
          name: 'freebet.usage',
          title: 'Usage',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'minUsage',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'minUsage',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxUsage',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxUsage',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'minUsage',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'maxUsage',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.usage}</Box>,
        },
        {
          name: 'freebet.totalWin',
          title: 'Total Win',
          sx: { minWidth: '140px', maxWidth: '240px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'minTotalWin',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'minTotalWin',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxTotalWin',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxTotalWin',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'minTotalWin',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'maxTotalWin',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.totalWin}</Box>,
        },
        {
          name: 'freebet.validityDate',
          title: 'Validity Date',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'validityStartDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'validityEndDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'validityStartDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'validityEndDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.validityDate && (
                <DateLabel date={new Date(parseISO(row?.validityDate))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'freebet.description',
          title: 'Description',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.description}</Box>,
        },
        {
          name: 'freebet.count',
          title: 'Count',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'minCount',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'minCount',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxCount',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxCount',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'minCount',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'maxCount',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.count}</Box>,
        },
        {
          name: 'freebet.minMultiplier',
          title: 'Min Multiplier',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'minMultiplier',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'minMultiplier',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxMultiplier',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxMultiplier',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'minMultiplier',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'maxMultiplier',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.minMultiplier}</Box>,
        },
        {
          name: 'freebet.betNominal',
          title: 'Bet Nominal',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'minBetNominal',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'minBetNominal',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxBetNominal',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setFreebetTableFilters({
                        codeName: 'maxBetNominal',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'minBetNominal',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'maxBetNominal',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.betNominal}</Box>,
        },
        {
          name: 'freebet.status',
          title: 'Status',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName={'status'}
              options={[
                { key: 'Active', value: 'ACTIVE' },
                { key: 'Revoked', value: 'REVOKED' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.status}</Box>,
        },
        {
          name: 'freebet.createdAt',
          title: 'Created At',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'createdAtStartDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setFreebetTableFilters({
                        codeName: 'createdAtEndDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'createdAtStartDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetTableFilters({
                    codeName: 'createdAtEndDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
