import { Stack, Typography } from '@mui/material';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import parseISO from 'date-fns/parseISO';
import { TransactionStatus } from './TransactionStatus';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

export const TransactionDetails = () => {
  const { selectedTransactionData } = useTransactionsDrawer();

  return (
    <Stack direction="column" spacing={3} sx={{ padding: '24px' }}>
      <Stack direction="row" justifyContent="space-between">
        <AtLocator type="readonly-text" name="AdditionalInformation">
          <Typography variant="button">Transaction Details</Typography>
        </AtLocator>
      </Stack>
      <AtLocator type="special-field" name="id" box>
        <FieldRowLabel
          label="Back-Office ID"
          value={<TruncateText text={selectedTransactionData.id ?? 'No results'} maxLength={8} />}
        />
      </AtLocator>
      <AtLocator type="special-field" name="type" box>
        <FieldRowLabel
          label="Transaction type / ID"
          value={
            <Stack direction="row" alignItems="center">
              {selectedTransactionData.transactionType} /
              <TruncateText text={selectedTransactionData.rentalId} maxLength={8} />
            </Stack>
          }
        />
      </AtLocator>
      <AtLocator type="special-field" name="paymentId" box>
        <AtLocator type="special-field" name="paymentId" box>
          <FieldRowLabel
            label="Payment ID"
            value={
              <TruncateText
                text={selectedTransactionData.externalTransactionId ?? 'No results'}
                maxLength={8}
              />
            }
          />
        </AtLocator>
      </AtLocator>
      <AtLocator type="special-field" name="paymentMethod" box>
        <FieldRowLabel
          label="Payment Method"
          // value={selectedTransactionData.paymentMethod ?? 'No results'}
          value={'Payment Method ###'}
        />
      </AtLocator>
      {/* TODO add onClick to open customer drawer */}
      <AtLocator type="special-field" name="Customer">
        <FieldRowLabel
          label="Customer"
          value={
            <TruncateText
              text={selectedTransactionData.externalTransactionId ?? 'No results'}
              maxLength={8}
            />
          }
        />
      </AtLocator>
      <AtLocator type="special-field" name="transactionAmount" box>
        <FieldRowLabel
          label="Transaction amount"
          value={
            selectedTransactionData.currency
              ? `${selectedTransactionData.currency} ${(
                  Number(selectedTransactionData.amount) || 0
                ).toFixed(2)}`
              : 'No results'
          }
        />
      </AtLocator>
      <AtLocator type="special-field" name="transactionDate" box>
        <FieldRowLabel
          label="Transaction Date"
          value={
            <DateLabel date={parseISO(selectedTransactionData.createdAt)} format="long" /> ??
            'No results'
          }
        />
      </AtLocator>
      <AtLocator type="special-field" name="initiator" box>
        <FieldRowLabel
          label="Initiator"
          value={selectedTransactionData.initiator ?? 'No results'}
        />
      </AtLocator>
      <AtLocator type="special-field" name="status" box>
        <FieldRowLabel
          label="status"
          value={<TransactionStatus status={selectedTransactionData.status} /> ?? 'No results'}
        />
      </AtLocator>
    </Stack>
  );
};
