// System
import { FormProvider } from 'react-hook-form';

// Components
import { IotAssignForm } from './IotAssignForm';
import { IotNotAssigned } from './IotNotAssigned';
import { IoTInformation } from './IoTInformation';
import { VehicleCard } from '../Details/VehicleCard/VehicleCard';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Forms
import { useCreateVehicleIotLinkForm } from '@Forms/vehicles/CreateVehicleIotLinkFormContext';

// Redux
import { useLinkVehicleIot } from '@Modal/hooks/vehicles/vehicles/useLinkVehicleIot';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

const IotComponent = ({
  formShown,
  iotLinked,
}: {
  formShown: boolean;
  iotLinked: boolean;
}): JSX.Element => {
  if (iotLinked) {
    return <IoTInformation />;
  }
  if (formShown) {
    return <IotAssignForm />;
  }
  return <IotNotAssigned />;
};

export const IoTModule: React.FC = () => {
  const methods = useCreateVehicleIotLinkForm();

  const { selectedVehicleData } = useVehiclesDrawer();

  const { iotFormShown } = useLinkVehicleIot();
  const { iotImei } = selectedVehicleData;

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <VehicleCard vehicleData={selectedVehicleData} />
          <IotComponent formShown={iotFormShown} iotLinked={Boolean(iotImei)} />
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
