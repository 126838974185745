import React from 'react';

import { Stack } from '@mui/material';

import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const GeneralRequirements = () => {
  return (
    <Stack spacing={2}>
      <AtLocator type="text-field" name="ReservationMaximumDistance">
        <RHFIntegerField
          name="reservationDistanceMax"
          size="small"
          label="Reservation maximum distance (Meters)"
          variant="outlined"
        />
      </AtLocator>
      <AtLocator type="text-field" name="RentalMaximumDistance">
        <RHFIntegerField
          name="rentalDistanceMax"
          size="small"
          label="Rental maximum distance (Meters)"
          variant="outlined"
        />
      </AtLocator>
      {/* <AtLocator type="text-field" name="MinimumBalanceWithout">
        <RHFIntegerField
          name="minBalanceWithoutPaymentSource"
          size="small"
          label="Minimum balance without payment source"
          variant="outlined"
          formatOptions={{ sign: true }}
        />
      </AtLocator> */}
      {/* <AtLocator type="text-field" name="MinimumBalancePayment">
        <RHFIntegerField
          name="minBalanceWithPaymentSource"
          size="small"
          label="Minimum balance with Payment source"
          variant="outlined"
          formatOptions={{ sign: true }}
        />
      </AtLocator> */}
      <AtLocator type="text-field" name="MinimumAge">
        <RHFIntegerField name="minAge" size="small" label="Minimum age" variant="outlined" />
      </AtLocator>
    </Stack>
  );
};
