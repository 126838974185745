// System
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  getSingleRental,
  getSingleRentalEvents,
  getSelectedRentalPriceBreakdown,
} from '@Modal/reducers/rentals/rentalsDrawerSlice';
import {
  setShowRentalsDrawer,
  setShowEndRideModalDialog,
} from '@Modal/reducers/rentals/rentalsDrawerSlice';
import { rentalEndRide } from '@Modal/reducers/rentals/rentalsDrawerSlice';

// Forms
import { useRentalEndRideForm } from '@Forms/rentals/EndRide/RentalEndRideFormContext';

// Redux
import { useDispatch } from 'react-redux';
import { useRentalsTable } from './useRentalsTable';

export const useRentalsDrawer = () => {
  const {
    openRentalsDrawer,
    selectedRental,
    events,
    showEndRideModalDialog,
    selectedRentalEvents,
    selectedRentalPriceBreakdown,
  } = useSelector((state: StoreInterface) => state?.rentalsDrawer);
  const { reset } = useRentalEndRideForm();
  const { fetchRentalsTableContent } = useRentalsTable();
  const { enqueueSnackbar } = useSnackbar();

  const sockedID = typeof window !== 'undefined' ? localStorage.getItem('socketId') : null;

  const dispatch = useDispatch<AppDispatch>();

  const handleUpdateSelectedRentalInfo = () => {
    dispatch(getSingleRental(selectedRental.id));
  };

  const handleFetchSelectedRentalPriceBreakdown = (id: string) => {
    dispatch(getSelectedRentalPriceBreakdown(id));
  };

  const handleEndRentalModalDialog = (action: boolean) => {
    reset();
    dispatch(setShowEndRideModalDialog(action));
  };

  const handleFetchSelectedRentalEvents = () => {
    dispatch(getSingleRentalEvents(selectedRental.id));
  };

  const handleSendEndRideCommand = (actualDuration: number) => {
    dispatch(
      rentalEndRide({
        rentalId: selectedRental.id,
        operatorClientId: sockedID,
        updateDuration: actualDuration,
      })
    )
      .unwrap()
      .then(() => {
        reset();
        enqueueSnackbar('command was successfully sent', {
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        handleEndRentalModalDialog(false);
        handleUpdateSelectedRentalInfo();
        fetchRentalsTableContent();
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return {
    events,
    selectedRental,
    getSingleRental,
    openRentalsDrawer,
    setShowRentalsDrawer,
    selectedRentalEvents,
    showEndRideModalDialog,
    setShowEndRideModalDialog,
    selectedRentalPriceBreakdown,
    getSelectedRentalPriceBreakdown,
    handleSendEndRideCommand,
    handleEndRentalModalDialog,
    handleUpdateSelectedRentalInfo,
    handleFetchSelectedRentalEvents,
    handleFetchSelectedRentalPriceBreakdown,
  };
};
