import { ComponentProps, ReactElement, cloneElement } from 'react';
import { TabList } from '@mui/lab';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof TabList>;

export type AtTabListLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtTabListLocator: React.FC<AtTabListLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-tab-list': name });
};
