// System
import { useDispatch } from 'react-redux';
import { Button, Stack } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Hooks
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

// Other
import { toGeoJSONPath } from './geoJSON';

export const SetupMapZoneFooter: React.FC = () => {
  const dispatch = useDispatch();
  const {
    mode,
    mapPaths,
    handleCreateMapZone,
    setShowMapZoneDialog,
    deleteMapZoneHandler,
    setShowCancelMapZoneDialog,
    isUpdateCTAActive,
    handleClearButtonClick,
  } = useBranchesMaps();

  const handleCTAOnMode = () => {
    const paths = toGeoJSONPath(mapPaths);

    switch (mode) {
      case 'edit':
        dispatch(setShowMapZoneDialog(false));
        break;
      case 'add':
        handleCreateMapZone(paths);
        break;
      case 'delete':
        deleteMapZoneHandler();
        break;
      default:
        break;
    }
  };

  const handleCancelMapZoneDialog = () => {
    if (!isUpdateCTAActive()) {
      dispatch(setShowCancelMapZoneDialog(!isUpdateCTAActive()));
    } else {
      dispatch(setShowMapZoneDialog(false));
    }
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => handleClearButtonClick()}
          sx={{ borderRadius: '8px 0 0 8px' }}
        >
          Clear
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => ''}
          sx={{ borderRadius: '0px', marginLeft: '-1px' }}
        >
          Import
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => ''}
          sx={{ borderRadius: '0 8px 8px 0', marginLeft: '-1px' }}
        >
          Export
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
        <AtLocator key="Cancel" type="button" name="Cancel">
          <Button variant="outlined" color="inherit" onClick={handleCancelMapZoneDialog}>
            Cancel
          </Button>
        </AtLocator>
        <AtLocator key="Submit" type="button" name="Submit">
          <Button
            type="submit"
            variant="contained"
            disabled={isUpdateCTAActive()}
            onClick={handleCTAOnMode}
          >
            Update area
          </Button>
        </AtLocator>
      </Stack>
    </Stack>
  );
};
