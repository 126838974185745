import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { EnumFilterValue } from '@contracts/common/EnumFilterValue';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { SortParams } from '@contracts/common/params/SortParams';
import { RentalRating, RentalState, RentalVehicles } from '@contracts/domain/keys';

export const rentalListColumns = [
  'rental.id',
  'rental.branchName',
  'rental.type',
  'rental.state',
  'rental.customerId',
  'rental.firstName',
  'rental.lastName',
  'rental.vehicleId',
  'rental.licensePlate',
  'rental.qrCode',
  'rental.startTime',
  'rental.endTime',
  'rental.duration',
  'rental.price',
  'rental.rating',
  'rental.comment',
] as const;

export type RentalListColumns = (typeof rentalListColumns)[number];

export type RentalListFilter = {
  'rental.id': number | undefined;
  'rental.branchName': string | undefined;
  'rental.type': EnumFilterValue<RentalVehicles>;
  'rental.state': EnumFilterValue<RentalState>;
  'rental.customerId': string | undefined;
  'rental.firstName': string | undefined;
  'rental.lastName': string | undefined;
  'rental.vehicleId': number | undefined;
  'rental.licensePlate': string | undefined;
  'rental.qrCode': string | undefined;
  'rental.startTime': number | undefined;
  'rental.endTime': number | undefined;
  'rental.duration': number | undefined;
  'rental.price': number | undefined;
  'rental.rating': EnumFilterValue<RentalRating>;
  'rental.comment': string | undefined;
  limit: number;
};

export const defaultRentalListFilter: RentalListFilter = {
  'rental.id': undefined,
  'rental.branchName': undefined,
  'rental.type': {},
  'rental.state': {},
  'rental.customerId': undefined,
  'rental.firstName': undefined,
  'rental.lastName': undefined,
  'rental.vehicleId': undefined,
  'rental.licensePlate': undefined,
  'rental.qrCode': undefined,
  'rental.startTime': undefined,
  'rental.endTime': undefined,
  'rental.duration': undefined,
  'rental.price': undefined,
  'rental.rating': {},
  'rental.comment': undefined,
  limit: 25,
};

export type RentalListSortColumns =
  | 'rental.id'
  | 'rental.startTime'
  | 'rental.endTime'
  | 'rental.duration'
  | 'rental.price'
  | 'rental.vehicleId';
export type RentalListSort = SortParams<RentalListSortColumns> | undefined;
export const defaultRentalListSort: RentalListSort = undefined;

export const rentalListColumnVisibilitySettings = {
  'rental.id': ColumnVisibilityType.Visible,
  'rental.state': ColumnVisibilityType.CanBeHidden,
  'rental.qrCode': ColumnVisibilityType.CanBeHidden,
  'rental.customerId': ColumnVisibilityType.CanBeHidden,
  'rental.firstName': ColumnVisibilityType.CanBeVisible,
  'rental.lastName': ColumnVisibilityType.CanBeVisible,
  'rental.price': ColumnVisibilityType.CanBeHidden,
  'rental.duration': ColumnVisibilityType.CanBeHidden,
  'rental.startTime': ColumnVisibilityType.CanBeHidden,
  'rental.endTime': ColumnVisibilityType.CanBeHidden,
  'rental.branchName': ColumnVisibilityType.CanBeVisible,
  'rental.type': ColumnVisibilityType.CanBeVisible,
  'rental.vehicleId': ColumnVisibilityType.CanBeVisible,
  'rental.licensePlate': ColumnVisibilityType.CanBeVisible,
  'rental.rating': ColumnVisibilityType.CanBeVisible,
  'rental.comment': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<RentalListColumns>;

export const defaultRentalListColumnVisibility = defaultColumnVisibilityParams(
  rentalListColumnVisibilitySettings
);
export const rentalListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  rentalListColumns,
  rentalListColumnVisibilitySettings
);
