import { ReactNode, useCallback, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';

export const useSelectorTabs = <T extends string>(defaultTab: T) => {
  const [currentTab, setCurrentTab] = useState<T>(defaultTab);

  const handleSwitchTab = useCallback((event: SelectChangeEvent<string>, _: ReactNode) => {
    setCurrentTab(event.target.value as T);
  }, []);

  return { currentTab, handleSwitchTab, setCurrentTab };
};
