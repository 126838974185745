import { Dispatch, createContext } from 'react';
import { noop } from '@utils/noop';
import { SetupVehicleUseCaseStatus } from '../SetupVehicle';

type SetupIotModuleUseCaseStatusContextType = {
  status: SetupVehicleUseCaseStatus;
  setStatus: Dispatch<SetupVehicleUseCaseStatus>;
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
};

const defaultValue: SetupIotModuleUseCaseStatusContextType = {
  status: SetupVehicleUseCaseStatus.Initial,
  setStatus: noop,
  isOpen: false,
  setIsOpen: noop,
};

export const SetupIotModuleUseCaseStatusContext =
  createContext<SetupIotModuleUseCaseStatusContextType>(defaultValue);
