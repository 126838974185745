// Components
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupRentalContent } from './SetupRentalContent';

// Redux
import { useDispatch } from 'react-redux';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

export const SetupRentalDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { openRentalsDrawer, setShowRentalsDrawer } = useRentalsDrawer();

  return (
    <SetupDrawer
      name="SetupVehicle"
      open={openRentalsDrawer}
      onClose={() => dispatch(setShowRentalsDrawer(false))}
    >
      <SetupRentalContent />
    </SetupDrawer>
  );
};
