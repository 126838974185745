import { Box, Button, Stack, Typography, Tooltip } from '@mui/material';
import { CustomerStatus } from '@contracts/domain/keys';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import MenuPopover from '@Components/menu-popover';
import { CustomerStatusLabel } from '../shared/CustomerStatus';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';
import { usePopOvers } from '@Components/shared/hooks/usePopOvers';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { useDeleteCustomer } from '@Modal/hooks/customers/deleteCustomer/useDeleteCustomer';
import { useCustomerRentals } from '@Modal/hooks/customers/rentals/useCustomerRentals';
import { useCustomerBalance } from '@Modal/hooks/customers/balance/useCustomerBalance';
import { useSignOutCustomer } from '@Modal/hooks/customers/signOutCustomer/useSignOutCustomer';
import { useBlockUnblockCustomer } from '@Modal/hooks/customers/blockCustomer/useBlockUnblockCustomer';

export const SetupCustomerHeader: React.FC = () => {
  const { selectedCustomerData } = useCustomersDrawer();
  const { open, openPopover, closePopover } = usePopOvers();
  const { handleDeleteCustomerModalDialog } = useDeleteCustomer();
  const { handleSignOutCustomerModalDialog } = useSignOutCustomer();
  const { handleBlockCustomerModalDialog, handleUnblockCustomerModalDialog } =
    useBlockUnblockCustomer();
  const { handleGetCustomerRentals } = useCustomerRentals();
  const { fetchCustomerWallets } = useCustomerBalance();
  const ellipsis = useEllipsis();

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Stack direction="row" justifyContent="flex-start" alignContent="center" spacing={1}>
        <Box sx={{ maxWidth: '180px', alignSelf: 'center', ...ellipsis }}>
          <Typography variant="h6">
            {selectedCustomerData?.accountDetails[0]?.firstName}{' '}
            {selectedCustomerData?.accountDetails[0]?.lastName}
          </Typography>
        </Box>
        <Stack justifyContent="center">
          <CustomerStatusLabel status={selectedCustomerData.customer.status as CustomerStatus} />
        </Stack>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        sx={{ paddingX: '10px', textTransform: 'capitalize' }}
        size="small"
        endIcon={
          open ? <AssetIcon src="chevron-up" size="s" /> : <AssetIcon src="chevron-down" size="s" />
        }
        onClick={openPopover}
      >
        Actions
      </Button>
      <MenuPopover open={open} onClose={closePopover} disabledArrow sx={{ marginY: 1 }}>
        <Stack direction="row" alignItems="center">
          <MenuItemEx
            name="signOutFromDevice"
            onClick={(e) => {
              handleSignOutCustomerModalDialog(true), closePopover(e);
              handleGetCustomerRentals();
            }}
          >
            Sign out from devices
          </MenuItemEx>
          <Tooltip
            title="The user will be signed out from all devices once their session expires, and the will be forced to login again."
            arrow
            placement="bottom"
            sx={{ maxWidth: '191px' }}
          >
            <Box sx={{ lineHeight: 0, ml: 0 }}>
              <AssetIcon src="info-circle" sx={{ width: '17px', height: '17px', color: 'black' }} />
            </Box>
          </Tooltip>
        </Stack>

        <MenuItemEx
          name="deleteCustomer"
          onClick={(e) => {
            handleDeleteCustomerModalDialog(true), closePopover(e);
            handleGetCustomerRentals();
            fetchCustomerWallets();
          }}
        >
          Delete customer
        </MenuItemEx>
        <MenuItemEx
          name="block"
          onClick={(e) => {
            selectedCustomerData?.customer?.status === 'blocked'
              ? handleUnblockCustomerModalDialog(true)
              : handleBlockCustomerModalDialog(true),
              closePopover(e);
          }}
        >
          {selectedCustomerData?.customer?.status === 'blocked' ? 'Unblock' : 'Block'}
        </MenuItemEx>
      </MenuPopover>
    </Stack>
  );
};
