import { Button } from '@mui/material';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { AtLocator } from '@Components/shared/locators/AtLocator';

type Props = {
  onClick?: () => void;
};

export const CreateBrandButton: React.FC<Props> = ({ onClick }) => {
  return (
    <AtLocator type="button" name="CreateBrand">
      <Button
        variant="soft"
        startIcon={<AssetIcon src="plus" size="s" />}
        sx={{ width: '100%' }}
        onClick={onClick}
      >
        Create brand
      </Button>
    </AtLocator>
  );
};
