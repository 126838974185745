import { ComponentType, useMemo } from 'react';
import { useGoogleMapsLoader } from './useGoogleMapsLoader';

type Props<T extends {}> = {
  component: ComponentType<T>;
  props: T;
};
export const GoogleMapsLoader = <T extends {}>({ component: Component, props }: Props<T>) => {
  const { isLoaded, loadError } = useGoogleMapsLoader();
  if (loadError) {
    return <div>{String(loadError)}</div>;
  }

  return isLoaded ? <Component {...props} /> : <>Loading...</>;
};
