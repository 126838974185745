import { Divider } from '@mui/material';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

import { Common } from './Common';
import { SpeedSettings } from './SpeedSettings';
import { BatteryLevel } from './BatteryLevel';
import { IoTBatteryLevel } from './IoTBatteryLevel';
import { IoTBatteryLevelSwitcher } from './IoTBatteryLevelSwitcher';

export const Details: React.FC = () => {
  return (
    <DrawerTabPanelLayout>
      <DrawerTabSectionLayout>
        <Common />
      </DrawerTabSectionLayout>
      <Divider />
      <DrawerTabSectionLayout>
        <BatteryLevel />
        <IoTBatteryLevelSwitcher />
        <IoTBatteryLevel />
        <SpeedSettings />
      </DrawerTabSectionLayout>
    </DrawerTabPanelLayout>
  );
};
