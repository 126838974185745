import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, number, object, string } from 'yup';

export type BalanceModalDialogFormState = {
  funds: number;
  description: string;
};

export const BalanceModalDialogFormStateDefault: BalanceModalDialogFormState = {
  funds: 0,
  description: '',
};

export const BalanceModalDialogSchemaDefault: ObjectSchema<Partial<BalanceModalDialogFormState>> =
  object<Partial<BalanceModalDialogFormState>>().shape({
    funds: number().required('Fund is required').positive('Funds must be a positive number'),
    description: string().required(),
  });

type BalanceModalDialogFormContextType = {
  schema: ObjectSchema<Partial<BalanceModalDialogFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<BalanceModalDialogFormState>>>;
  methods?: UseFormReturn<BalanceModalDialogFormState>;
};

const balanceModalDialogFormContextDefault = {
  schema: BalanceModalDialogSchemaDefault,
  setSchema: noop,
} satisfies BalanceModalDialogFormContextType;

export const BalanceModalDialogFormContext = createContext<BalanceModalDialogFormContextType>(
  balanceModalDialogFormContextDefault
);

export const useBalanceModalDialogForm = () => {
  const { methods } = useContext(BalanceModalDialogFormContext);
  if (!methods) {
    throw new Error('BalanceModalDialogFormContext is empty');
  }

  return methods;
};

export const useManageBalanceModalDialogSchema = () => {
  const { setSchema } = useContext(BalanceModalDialogFormContext);

  return { setSchema };
};
