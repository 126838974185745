import { MouseEventHandler } from 'react';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

import { useTheme } from '@mui/material';
import { CustomTableButton } from '../CustomTableButton';

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
};

export const SearchTableButton: React.FC<Props> = ({ id, onClick }) => {
  const { palette } = useTheme();
  return (
    <CustomTableButton id={id} onClick={onClick} data-at-button="Search">
      <AssetIcon src="search-lg" size="m" sx={{ bgcolor: palette.primary.main }} />
    </CustomTableButton>
  );
};
