import { Stack, Typography } from '@mui/material';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';
import { useIotDrawer } from '@Modal/hooks/vehicles/iot/useIotDrawer';

export const AdditionalInfo = () => {
  const { selectedIot } = useIotDrawer()

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" justifyContent="space-between">
        <AtLocator type="readonly-text" name="AdditionalInformation">
          <Typography variant="button">Additional information</Typography>
        </AtLocator>
      </Stack>
      <AtLocator type="special-field" name="id" box>
        <FieldRowLabel label="Internal ID" value={selectedIot.id ?? 'No results'} />
      </AtLocator>
      <AtLocator type="special-field" name="type" box>
        <FieldRowLabel label="Integration type" value={selectedIot.integrationType ?? 'No results'} />
      </AtLocator>
      <AtLocator type="special-field" name="imei" box>
        <FieldRowLabel label="IMEI" value={selectedIot.imei ?? 'No results'} />
      </AtLocator>
      <AtLocator type="special-field" name="iccid" box>
        <FieldRowLabel label="ICCID" value={selectedIot.iccid ?? 'No results'} />
      </AtLocator>
      <AtLocator type="special-field" name="firmwareVersion" box>
        <FieldRowLabel label="Firmware version" value={selectedIot.firmwareVersion ?? 'No results'} />
      </AtLocator>
      <AtLocator type="special-field" name="iotType" box>
        <FieldRowLabel label="IoT type" value={selectedIot.iotType ?? 'No results'} />
      </AtLocator>
    </Stack>
  );
};
