// System
import { useSnackbar } from 'notistack';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import useResponsive from './useResponsive';

export const useVehicleCommands = () => {
  // Socket
  const isMobile = useResponsive('down', 'sm');

  const { enqueueSnackbar } = useSnackbar();

  const handleSendCommands = (socketId: string | null, command: string, imei: string[]) => {
    if (socketId) {
      return axiosBranchesVehiclesInstance
        .post('/v1/iot/send-command', {
          imei,
          clientId: socketId,
          command,
        })
        .then((res) =>
          enqueueSnackbar('Command Sent', {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: isMobile ? 'center' : 'right',
            },
          })
        )
        .catch((error) =>
          enqueueSnackbar(error, {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: isMobile ? 'center' : 'right',
            },
          })
        );
    } else {
      enqueueSnackbar('Could not send command,socket not connected', {
        autoHideDuration: 3000,
        variant: 'error',
        anchorOrigin: {
          vertical: isMobile ? 'top' : 'bottom',
          horizontal: isMobile ? 'center' : 'right',
        },
      });
    }
  };

  return {
    handleSendCommands,
  };
};
