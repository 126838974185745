import {
  ImageCancelDialogLayout,
  CancelDialogConfirmAction,
  CancelDialogRejectAction,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';
import { useIotDrawer } from '@Modal/hooks/vehicles/iot/useIotDrawer';

export const SetupIotModuleCancelDialog: React.FC = () => {
  const { showCancelIotDrawer, handleCancelTakeMeBack } = useIotDrawer();

  return (
    <>
      <CancelModal open={showCancelIotDrawer}>
        <ImageCancelDialogLayout
          title="Are you sure you want to cancel?"
          actions={[
            <CancelDialogRejectAction onClick={() => handleCancelTakeMeBack('back')}>No</CancelDialogRejectAction>,
            <CancelDialogConfirmAction onClick={() => handleCancelTakeMeBack('')}>
              Yes
            </CancelDialogConfirmAction>,
          ]}
        />
      </CancelModal>
    </>
  );
};
