// Components
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { UploadIotModulesCSVDialogForm } from './UploadIotModulesCSVDialogForm';

// Redux
import { useCSV } from '@Modal/hooks/vehicles/csv/useCSV';

export const UploadIotModulesCSVModalDialog: React.FC = () => {
  const { showIoTCSVModal, handleIoTCSVModal } = useCSV();

  return (
    <ClosableModal
      name="UploadIoTCSV"
      title="Import file"
      open={showIoTCSVModal}
      onClose={() => handleIoTCSVModal(false)}
    >
      <UploadIotModulesCSVDialogForm />
    </ClosableModal>
  );
};
