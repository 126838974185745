import { IconButton, Theme } from '@mui/material';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SystemStyleObject } from '@mui/system';
import { ComponentProps, MouseEventHandler, ReactElement } from 'react';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type Props = DataAttributeProps & {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  sx?: SystemStyleObject<Theme>;
  children: ReactElement<ComponentProps<typeof AssetIcon>>;
};

export const CustomTableButton: React.FC<Props> = ({ id, onClick, children, sx, ...rest }) => {
  return (
    <IconButton
      {...rest}
      sx={{
        padding: '5px',
        width: 'auto',
        minWidth: 'auto',
        ...sx,
      }}
      aria-describedby={id}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};
