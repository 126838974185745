import React, { useCallback, useEffect, useRef } from 'react';
// System
import { debounce } from 'lodash';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { QrScanner } from '@yudiel/react-qr-scanner';

// Redux
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';

// Components
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Features
import { OperatorScannerFooter } from '../../OperatorScannerFooter/OperatorScannerFooter';

// Types
import { ScannedVehicleType } from '../types';

// Styles
import Styles from './SetupOperatorScanDrawer.module.scss';

const MainContainerStyle = {
  '& video': {
    position: 'unset !important',
    width: '500px !important',
  },
  '& svg': {
    display: 'none',
  },
};

export const SetupOperatorScanDrawer: React.FC = () => {
  const {
    scannedVehicles,
    showOperatorScanModal,
    handleGetScannedVehicle,
    handleShowCancelModalDialog,
  } = useOperatorScan();

  const handleScan = useCallback(
    (data: ScannedVehicleType) => {
      if (data) {
        const splitQRCode: string[] = data?.text.split('/');

        handleGetScannedVehicle(splitQRCode[splitQRCode.length - 1]);
      }
    },
    [handleGetScannedVehicle, scannedVehicles]
  );

  // Debounce the handleScan function to run at most once every 3000ms
  const debouncedHandleScan = useRef(debounce(handleScan, 3000)).current;

  useEffect(() => {
    return () => {
      // Clear the debounced function on unmount
      debouncedHandleScan.cancel();
    };
  }, [debouncedHandleScan]);

  return (
    <SetupDrawer
      name="SetupOperatorScanDrawer"
      open={showOperatorScanModal}
      onClose={handleShowCancelModalDialog}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorScanDrawer} sx={MainContainerStyle}>
        <Box className={Styles.ScannerHeader}>
          <Typography fontWeight={900} fontSize={'20px'}>
            Scan
          </Typography>
          <Box role="button" onClick={handleShowCancelModalDialog}>
            <AssetIcon src="x-close" className={Styles.AssetIcon} />
          </Box>
        </Box>
        <Box className={Styles.VideoContainer}>
          <QrScanner
            onError={(error: any) => console.log('Error', error)}
            onDecode={handleGetScannedVehicle}
            scanDelay={500}
            containerStyle={{
              paddingTop: '0',
            }}
          />
        </Box>

        <OperatorScannerFooter />
      </Box>
    </SetupDrawer>
  );
};
