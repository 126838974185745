import { useMemo } from 'react';
import { LabelColor } from '@Components/label';

import { KeyValue } from '@contracts/view/KeyValue';
import { RentalStatus } from '@contracts/domain/keys';

export const useRentalStatusColor = () => {
  return useMemo(
    (): Record<RentalStatus, LabelColor> => ({
      RIDE_READY: 'primary',
      DRIVING: 'warning',
      PARKING: 'success',
      UNKNOWN: 'default',
    }),
    []
  );
};

export const useRentalStatusTitle = (): Record<RentalStatus, string> => {
  return useMemo(
    () => ({
      RIDE_READY: 'Ride Ready',
      DRIVING: 'Driving',
      PARKING: 'Parking',
      UNKNOWN: 'Unknown',
    }),
    []
  );
};

export const useRentalStatusOptions = (): KeyValue<RentalStatus, string>[] => {
  const titles = useRentalStatusTitle();
  return useMemo(
    () => (Object.keys(titles) as RentalStatus[]).map((key) => ({ key, value: titles[key] })),
    [titles]
  );
};
