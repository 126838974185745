import { ObjectSchema, object, string, array } from 'yup';
import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type CreateAviatorRoleFormState = {
  name: string;
  description: string;
  permissions: [];
  isEditable: string | boolean;
};

export const createAviatorRoleFormStateDefault: CreateAviatorRoleFormState = {
  name: '',
  description: '',
  permissions: [],
  isEditable: '',
};

// @ts-ignore
export const createAviatorRoleSchemaDefault: ObjectSchema<Partial<CreateAviatorRoleFormState>> =
  object<Partial<CreateAviatorRoleFormState>>().shape({
    name: string().required('Name is required field'),
    description: string().required('Description is required field'),
    permissions: array().required('Permissions is required field'),
    isEditable: string().required('Is Editable is required field'),
  });

type CreateAviatorRoleFormContextType = {
  schema: ObjectSchema<Partial<CreateAviatorRoleFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateAviatorRoleFormState>>>;
  methods?: UseFormReturn<CreateAviatorRoleFormState>;
};

const createAviatorRoleFormContextDefault = {
  schema: createAviatorRoleSchemaDefault,
  setSchema: noop,
} satisfies CreateAviatorRoleFormContextType;

export const CreateAviatorRoleFormContext = createContext<CreateAviatorRoleFormContextType>(
  createAviatorRoleFormContextDefault
);

export const useCreateAviatorRoleForm = () => {
  const { methods } = useContext(CreateAviatorRoleFormContext);
  if (!methods) {
    throw new Error('CreateAviatorRoleFormContext is empty');
  }

  return methods;
};

export const useManageCreateAviatorRoleSchema = () => {
  const { setSchema } = useContext(CreateAviatorRoleFormContext);

  return { setSchema };
};
