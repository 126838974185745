import { ReactNode } from 'react';
import { Button } from '@mui/material';

type Props = {
  children: ReactNode;
  onClick?: () => void;
};

export const CancelDialogRejectAction: React.FC<Props> = ({ children, onClick }) => {
  return (
    <Button variant="outlined" onClick={onClick} color="inherit">
      {children}
    </Button>
  );
};
