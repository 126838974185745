import { SetupLinkIoTFormStateDTO } from '@Forms/operator/SetupLinkIoTFormContext';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';

export const useSetupLinkIoTUseCaseMapper = (vehicle: any) => {
  return {
    toFormState: async (): Promise<SetupLinkIoTFormStateDTO | undefined> => {
      if (!vehicle) {
        return undefined;
      }

      return {
        iotImei: vehicle.iotImei,
      };
    },
    toDto: (formState: SetupLinkIoTFormStateDTO): UpdateRequest<SetupLinkIoTFormStateDTO> => {
      return {
        // @ts-ignore
        iotImei: formState.iotImei,
      };
    },
  };
};
