import { useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import {
  setChartYAxis,
  setChartXAxis,
  setChartValue,
  setTableFilters,
  setTotalWonAmount,
  setTotalBetAmount,
  setTotalNumOfSpins,
  setTotalNumOfUsers,
  setTotalNumOfRounds,
  setColumnVisibility,
} from '@Modal/reducers/analytics/table/analyticsTableSlice';

// Types
import { StoreInterface } from 'src/app/types';
import { AnalyticsItemDto } from '@contracts/api/dto/analytics/AnalyticsDto';

export const useAnalyticsTable = () => {
  const dispatch = useDispatch();

  const {
    chartYAxis,
    chartXAxis,
    chartValue,
    tableFilters,
    totalBetAmount,
    totalWonAmount,
    totalNumOfSpins,
    totalNumOfUsers,
    totalNumOfRounds,
    selectedFilters,
    columnVisibility,
    analyticTableFilters,
    columnVisibilityOptions,
  } = useSelector((state: StoreInterface) => state?.analyticsTable);

  const analyticsListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);
    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...analyticsListFilters, ...column];
    if (analyticsListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = analyticsListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const handleExtractDates = (arr: AnalyticsItemDto[]) => {
    return arr?.map((analytic) => (analytic?.date ? new Date(analytic.date).getTime() : null));
  };

  const handleExtractValues = <T extends keyof AnalyticsItemDto>(
    arr: AnalyticsItemDto[],
    val: T | 'totalBets'
  ) => {
    return arr.map((analytic) => {
      if (val === 'totalBets') {
        return analytic?.totalLosingBets || 0 + analytic?.totalWinningBets || 0;
      } else {
        return analytic[val] || 0;
      }
    });
  };

  return {
    // State
    chartYAxis,
    chartXAxis,
    chartValue,
    tableFilters,
    totalBetAmount,
    totalWonAmount,
    totalNumOfSpins,
    totalNumOfUsers,
    totalNumOfRounds,
    selectedFilters,
    columnVisibility,
    analyticTableFilters,
    analyticsListFilters,
    visiblityCustomizable,
    columnVisibilityOptions,
    // Actions
    setChartYAxis,
    setChartXAxis,
    setChartValue,
    setTableFilters,
    setTotalWonAmount,
    setTotalBetAmount,
    setTotalNumOfSpins,
    setTotalNumOfUsers,
    setTotalNumOfRounds,
    // Handlers
    handleExtractDates,
    handleExtractValues,
    handleApplyColumnVisibility,
  };
};
