import {
  defaultCustomerListFilter,
  customerListColumnVisibilityOptions,
  defaultCustomerListColumnVisibility,
} from '@utils/tables/customers/customerListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultCustomerListFilter,
  rowsPerPage: 10,
  page: 1,
  columnVisibility: defaultCustomerListColumnVisibility,
  columnVisibilityOptions: customerListColumnVisibilityOptions,
};

const customerTableSlice = createSlice({
  name: 'customerTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultCustomerListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: customerTableReducer } = customerTableSlice;
export const {
  setColumnVisibility,
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
} = customerTableSlice.actions;
