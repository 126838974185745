import { ComponentProps, ReactElement, cloneElement } from 'react';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { RHFSlider } from '@Components/hook-form';

type ChildrenProps = ComponentProps<typeof RHFSlider>;

export type AtSliderLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtSliderFieldLocator: React.FC<AtSliderLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-slider': name } as DataAttributeProps);
};
