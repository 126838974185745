import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosRentalsInstance } from '@utils/axiosInstances';
import { GetCustomerRentalParamsType } from '../types';

// GET
export const getCustomerRentals = createAsyncThunk(
  'customer/getCustomerRentals',
  async (params: GetCustomerRentalParamsType, thunkApi) => {
    const { id, offset } = params;
    return axiosRentalsInstance
      .get(`/rentals/?customerId=${id}&endTimeOrder=DESC&offset=${offset}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  customerRentals: [],
  customerRentalPagination: 0,
  pagination: null,
};

const customerRentalsSlice = createSlice({
  name: 'customerRentals',
  initialState,
  reducers: {
    setCustomerRentals: (state, { payload }) => {
      state.customerRentals = payload;
    },
    setCustomerRentalPagination: (state, { payload }) => {
      state.customerRentalPagination = payload;
    },
  },
  extraReducers: {
    [getCustomerRentals.fulfilled?.type]: (state, { payload }) => {
      // @ts-ignore
      state.customerRentals = [...state.customerRentals, ...payload.items];
      state.pagination = payload.pageInfo;
    },
  },
});

export const { reducer: customerRentalsReducer } = customerRentalsSlice;
export const { setCustomerRentals, setCustomerRentalPagination } = customerRentalsSlice.actions;
