// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';

// Types
import { StoreInterface } from 'src/app/types';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { usePricingTable } from './usePricingTable';
import {
  getSinglePricing,
  updatePricing,
  setShowPricingDrawer,
  setShowCancelPricingDrawer,
} from '@Modal/reducers/pricing/pricingDrawerSlice';

// Form
import { AppDispatch } from 'src/app/store';
import {
  SetupPricingFormStateDTO,
  useManageSetupPricingSchema,
  useSetupPricingForm,
} from '@Forms/pricing/PricingDrawer/SetupPricingFormContext';
import { useSetupPricingUseCaseMapper } from '@Hooks/pricing/SetupPricing/useSetupPricingUseCaseMapper';
import { useSetupPricingUseCaseSchema } from '@Hooks/pricing/SetupPricing/useSetupPricingUseCaseSchema';

export const usePricingDrawer = () => {
  // Redux
  const { openPricingDrawer, selectedPricing, showCancelPricing } = useSelector(
    (state: StoreInterface) => state?.pricingDrawer
  );

  const { fetchPricingTableContent } = usePricingTable();

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, handleSubmit, reset, setValue } = useSetupPricingForm();
  const { isDirty, errors } = useFormState({ control });
  const { setSchema } = useManageSetupPricingSchema();
  const mapper = useSetupPricingUseCaseMapper();
  const schema = useSetupPricingUseCaseSchema();

  const handlePricingTableRowClick = (pricingId: string) => {
    return dispatch(getSinglePricing(pricingId))
      .unwrap()
      .then(() => {
        dispatch(setShowPricingDrawer(true));
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const setPricingDrawerMapperHandler = useCallback(async () => {
    setSchema(schema);
    const formState = mapper.toFormState(selectedPricing);
    reset(formState);
  }, [selectedPricing]);

  useEffect(() => {
    if (selectedPricing) {
      setPricingDrawerMapperHandler();
    }
  }, [selectedPricing]);

  // Form Submit
  const handleSubmitGeneralForm = useMemo(() => {
    return handleSubmit(async (formData) => {
      dispatch(
        updatePricing({
          pricingId: selectedPricing?.id,
          pricingFormState: {
            unlockPrice: formData.unlockPrice,
            parkingPrice: formData.parkingPrice,
            minutePrice: formData.minutePrice,
          },
        })
      )
        .unwrap()
        .then(() => {
          fetchPricingTableContent();
          dispatch(setShowPricingDrawer(false));
          reset();
        })
        .catch((error: string) => {
          enqueueSnackbar(error, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    });
  }, [selectedPricing]);

  const handlePriceChange = (key: keyof SetupPricingFormStateDTO, value: number) => {
    setValue(key, value, { shouldDirty: true });
  };

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelPricingDrawer(true));
    } else {
      reset();
      return dispatch(setShowPricingDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelPricingDrawer(false));
      dispatch(setShowPricingDrawer(true));
    } else {
      reset();
      dispatch(setShowCancelPricingDrawer(false));
      dispatch(setShowPricingDrawer(false));
    }
  };

  return {
    // State
    selectedPricing,
    openPricingDrawer,
    showCancelPricing,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    setShowPricingDrawer,
    handlePricingTableRowClick,
    handleSubmitGeneralForm,
    setShowCancelPricingDrawer,
    handleCreateCancel,
    handleCancelTakeMeBack,
    handlePriceChange,
  };
};
