import { MapZoneType } from '@contracts/domain/keys';
import { OperatorBatteryLevelDTO, OperatorServiceStateDTO } from './operatorListFilterDTO';

export type OperatorListFilter = {
  'operator.batteryLevel': OperatorBatteryLevelDTO | undefined;
  'operator.batteryPercentage': number | undefined;
  'operator.iotBatteryLevel': OperatorBatteryLevelDTO | undefined;
  'operator.branchId': string;
  'operator.serviceState': OperatorServiceStateDTO | undefined;
  'operator.serviceStatus': string[];
  'operator.iotStatus': string[];
  'operator.powerStatus': string[];
  'operator.areaType': MapZoneType | undefined;
  'operator.lastEndedRentals': string[];
  'operator.lastSeenOnline': string[];
};

export const defaultOperatorListFilter: OperatorListFilter = {
  'operator.batteryLevel': undefined,
  'operator.batteryPercentage': undefined,
  'operator.iotBatteryLevel': undefined,
  'operator.branchId': '',
  'operator.serviceState': undefined,
  'operator.serviceStatus': [],
  'operator.iotStatus': [],
  'operator.powerStatus': [],
  'operator.areaType': undefined,
  'operator.lastEndedRentals': [],
  'operator.lastSeenOnline': [],
};
