// System
import { useCallback, useMemo, useState } from 'react';
import { Polygon } from '@react-google-maps/api';

// Contracts
import { MapZoneType } from '@contracts/domain/keys';
import { EditStatus } from '@contracts/common/EditStatus';

// Types
import { mapZoneTypeColors } from '../../shared/MapZoneType/useMapZoneType';

// Features
import { RemoveMapZoneModalDialog } from '@Features/maps/RemoveMapZoneModalDialog/RemoveMapZoneModalDialog';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useDispatch } from 'react-redux';

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  holeColor: string;
  fillOpacity: number;
};

const PolygonView: React.FC<PolygonProps> = ({
  targetMapZoneType,
  strokeColor,
  fillColor,
  holeColor,
  fillOpacity,
}) => {
  const dispatch = useDispatch();
  const {
    mapPaths,
    setMapPaths,
    selectedMapZoneId,
    selectedMapZoneIndex,
    setSelectedMapZoneId,
    setSelectedMapZoneIndex,
  } = useBranchesMaps();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const filteredMapPathes = useMemo(() => {
    return mapPaths
      .map((zone, index) => [zone, index] as const)
      .filter(
        ([zone]) => zone.mapZoneType === targetMapZoneType && zone.editStatus !== EditStatus.Deleted
      );
  }, [mapPaths]);

  const handleDoubleClick = useCallback(
    (id: string, i: number) => (e: google.maps.MapMouseEvent) => {
      e.stop();
      dispatch(setSelectedMapZoneIndex(i));
      dispatch(setSelectedMapZoneId(id));
      setShowDeleteModal(true);
    },
    [mapPaths, setMapPaths]
  );

  const handleDeleteMapZone = useCallback(() => {
    const filteredPaths = mapPaths.map((item) =>
      item.id !== selectedMapZoneId
        ? item
        : {
            ...item,
            editStatus: EditStatus.Deleted,
          }
    );

    dispatch(setMapPaths(filteredPaths));
    setShowDeleteModal(false);
  }, [mapPaths, setMapPaths, selectedMapZoneIndex]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  if (filteredMapPathes.length === 0) {
    return null;
  }

  return (
    <>
      {filteredMapPathes.map(([mapPath, index]) => (
        <Polygon
          key={index}
          paths={mapPath.path}
          options={{
            ...polygonOptions,
            draggable: false,
            editable: false,
            fillColor: mapPath.type === 'ring' ? fillColor : holeColor,
            strokeColor,
            fillOpacity,
          }}
          onDblClick={handleDoubleClick(mapPath.id, index)}
        />
      ))}

      <RemoveMapZoneModalDialog
        deleteMapZone={handleDeleteMapZone}
        showModal={showDeleteModal}
        closeModal={handleCloseDeleteModal}
      />
    </>
  );
};

export const MapDeleteMode: React.FC = () => {
  return (
    <>
      {mapZoneTypeColors.map((zoneType) => (
        <PolygonView
          key={zoneType.type}
          targetMapZoneType={zoneType.type}
          strokeColor={zoneType.color}
          fillColor={zoneType.fillColor ?? zoneType.color}
          holeColor={zoneType.holeColor ?? zoneType.color}
          fillOpacity={zoneType.opacity}
        />
      ))}
    </>
  );
};
