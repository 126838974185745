import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { TableHead, Theme, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = DataAttributeProps & {
  children: JSX.Element | JSX.Element[];
  sx?: SystemStyleObject<Theme>;
};
export const TableHeadEx: React.FC<Props> = ({ children, sx, ...dataProps }) => {
  const { palette } = useTheme();
  return (
    <TableHead sx={[{ backgroundColor: palette.grey[300] }, sx ?? {}]} {...dataProps}>
      {children}
    </TableHead>
  );
};
