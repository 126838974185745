import { Stack, Typography } from '@mui/material';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

export const SetupAddUserDrawerHeader = () => {
  const ellipsis = useEllipsis();

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Stack spacing={1} direction="row" sx={{ ...ellipsis }}>
        <Typography
          sx={{ fontSize: '18px', lineHeight: '28px', color: '0, 0, 0', fontWeight: '800' }}
        >
          Add User
        </Typography>
      </Stack>
    </Stack>
  );
};
