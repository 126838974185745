import { BaseSyntheticEvent, useMemo } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowCreateUserForm,
  setShowCancelUserForm,
} from '@Modal/reducers/users/createUser/createUserSlice';

// Form
import { useFormState } from 'react-hook-form';
import { useCreateAviatorUserForm } from '@Forms/roles/CreateAviatorUserFormContext';

// System
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// GraphQL
import { useCreateUserMutation, useGetUsersQuery } from '@Src/generated.graphql';

export const useCreateAviatorUser = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const [createUser] = useCreateUserMutation();
  const { refetch: refetchAviatorUsers } = useGetUsersQuery();

  const { showCreateUserForm, showCancelUserForm } = useSelector(
    (state: StoreInterface) => state?.createUser
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelUserForm(true));
    } else {
      return dispatch(setShowCreateUserForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelUserForm(false));
      dispatch(setShowCreateUserForm(true));
    } else {
      reset();
      dispatch(setShowCancelUserForm(false));
      dispatch(setShowCreateUserForm(false));
    }
  };

  // Form Controls
  const { control, handleSubmit, reset } = useCreateAviatorUserForm();
  const { isDirty, errors } = useFormState({ control });

  const handleSubmitCreateAviatorUser = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        const newFormData = { ...formData, roleIds: [formData?.roleIds] };
        try {
          await createUser({
            // @ts-ignore
            variables: { input: newFormData },
          });

          enqueueSnackbar('User was successfully created', {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });

          await refetchAviatorUsers();

          reset();
          dispatch(setShowCreateUserForm(false));
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    [createUser]
  );

  return {
    // Store
    showCancelUserForm,
    showCreateUserForm,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    setShowCreateUserForm,
    handleSubmitCreateAviatorUser,
  };
};
