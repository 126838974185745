import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import { CreateVehicleNoteType, VehicleNoteType } from '../types';

// GET
export const getVehicleNotes = createAsyncThunk(
  'vehicle/getVehicleNotes',
  async (id: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/vehicles/${id}/notes`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST
export const createVehicleNote = createAsyncThunk(
  'vehicle/createVehicleNote',
  async (vehicleData: CreateVehicleNoteType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post(`/v1/vehicles/${vehicleData.id}/notes`, {
        ...vehicleData.newNote,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// DELETE
export const deleteVehicleNote = createAsyncThunk(
  'vehicle/deleteVehicleNote',
  async (noteId: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .delete(`/v1/vehicles/notes/${noteId}`)
      .then(() => {
        return thunkApi.fulfillWithValue(noteId);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  vehicleNotes: [],
  selectedNoteId: 0,
  showCreateVehicleNote: false,
  showDeleteVehicleNote: false,
  showVehicleNoteCancel: false,
};

const vehicleNotesSlice = createSlice({
  name: 'createCategory',
  initialState,
  reducers: {
    setVehicleNotes: (state, { payload }) => {
      state.vehicleNotes = payload;
    },
    setShowCreateVehicleNote: (state, { payload }) => {
      state.showCreateVehicleNote = payload;
    },
    setShowCancelVehicleNote: (state, { payload }) => {
      state.showVehicleNoteCancel = payload;
    },
    setShowDeleteVehicleNote: (state, { payload }) => {
      state.showDeleteVehicleNote = payload;
    },
    setSelectedNoteId: (state, { payload }) => {
      state.selectedNoteId = payload;
    },
  },
  extraReducers: {
    [getVehicleNotes.fulfilled?.type]: (state, { payload }) => {
      state.vehicleNotes = payload;
    },
    [createVehicleNote.fulfilled?.type]: (state, { payload }) => {
      // @ts-ignore
      state.vehicleNotes = [payload, ...state.vehicleNotes];
      state.showCreateVehicleNote = false;
    },
    [deleteVehicleNote.fulfilled?.type]: (state, { payload }) => {
      const updatedVehicleNotes = state.vehicleNotes.filter(
        (note: VehicleNoteType) => note.id !== payload
      );
      // @ts-ignore
      state.vehicleNotes = updatedVehicleNotes;
      state.showDeleteVehicleNote = false;
    },
  },
});

export const { reducer: vehicleNotesReducer } = vehicleNotesSlice;
export const {
  setVehicleNotes,
  setSelectedNoteId,
  setShowCreateVehicleNote,
  setShowDeleteVehicleNote,
  setShowCancelVehicleNote,
} = vehicleNotesSlice.actions;
