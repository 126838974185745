import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import { CreateVehicleCategoryDataType } from '../types';

// POST
export const createVehicleCategory = createAsyncThunk(
  'vehicle/createNewVehicleCategory',
  async (categoryData: CreateVehicleCategoryDataType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post('/v1/categories', {
        ...categoryData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  showCreateVehicleCategoryForm: false,
  showCancelVehicleCategoryForm: false,
};

const createVehicleCategorySlice = createSlice({
  name: 'createCategory',
  initialState,
  reducers: {
    setShowCreateVehicleCategoryForm: (state, { payload }) => {
      state.showCreateVehicleCategoryForm = payload;
    },
    setShowCancelVehicleCategoryForm: (state, { payload }) => {
      state.showCancelVehicleCategoryForm = payload;
    },
  },
  extraReducers: {
    [createVehicleCategory.fulfilled?.type]: (state) => {
      state.showCreateVehicleCategoryForm = false;
    },
  },
});

export const { reducer: createVehicleCategoryReducer } = createVehicleCategorySlice;
export const { setShowCreateVehicleCategoryForm, setShowCancelVehicleCategoryForm } =
  createVehicleCategorySlice.actions;
