import { noop } from '@utils/noop';
import { Dispatch, createContext } from 'react';

type ActionWithPopoverContextType = [
  ancorEl: HTMLButtonElement | null,
  setAncorEl: Dispatch<HTMLButtonElement | null>
];

const actionWithPopoverContextDefault: ActionWithPopoverContextType = [null, noop];

export const ActionWithPopoverContext = createContext<ActionWithPopoverContextType>(
  actionWithPopoverContextDefault
);
