import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelDialogMessageLayout,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';

import { useCustomerBalance } from '@Modal/hooks/customers/balance/useCustomerBalance';

export const CancelBalanceModalDialog: React.FC = () => {
  const { showCancelBalanceModalDialog, handleCancelTakeMeBack } = useCustomerBalance();

  return (
    <>
      <CancelModal open={showCancelBalanceModalDialog}>
        <ImageCancelDialogLayout
          title="You are about to cancel changes"
          actions={
            <CancelDialogActions
              onReject={() => handleCancelTakeMeBack('back')}
              onConfirm={() => handleCancelTakeMeBack('cancel')}
            />
          }
        >
          <CancelDialogMessageLayout>
            The changes you made will not be saved
          </CancelDialogMessageLayout>
        </ImageCancelDialogLayout>
      </CancelModal>
    </>
  );
};
