import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedAvTransactionData: {},
  showAvTransactionDrawer: false,
};

const avTransactionDrawerSlice = createSlice({
  name: 'avTransactionDrawer',
  initialState,
  reducers: {
    setShowAvTransactionDrawer: (state, { payload }) => {
      state.showAvTransactionDrawer = payload;
    },
    setSelectedAvTransaction: (state, { payload }) => {
      state.selectedAvTransactionData = payload;
    },
  },
});

export const { reducer: avTransactionDrawerReducer } = avTransactionDrawerSlice;
export const { setShowAvTransactionDrawer, setSelectedAvTransaction } =
  avTransactionDrawerSlice.actions;
