import { Button } from '@mui/material';

import { AlertButtonProps } from '@Components/props/alerts/AlertButtonProps';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const DissmissSuccessAlertButton: React.FC<AlertButtonProps> = ({ onClick }) => {
  return (
    <AtLocator type="button" name="Dissmiss">
      <Button aria-label="close" size="small" variant="soft" color="success" onClick={onClick}>
        Dismiss
      </Button>
    </AtLocator>
  );
};
