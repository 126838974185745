// System
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

// Redux
import { useRoles } from '@Hooks/shared/useRoles';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  decodeAccessToken,
  logInRequest,
  refreshAccessToken,
} from '@Modal/reducers/authorization/authorizationSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { LogInParams } from '@Modal/reducers/authorization/types';

export const useAuthorization = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const authToken = Cookies.get('AuthToken');
  const refreshToken = Cookies.get('RefreshToken');

  const { allRoutes } = useRoles();
  const { accessToken, currentUser } = useSelector((state: StoreInterface) => state?.authorization);

  const { enqueueSnackbar } = useSnackbar();

  const handleRefreshToken = async () => {
    if (refreshToken) {
      return dispatch(refreshAccessToken(refreshToken))
        .unwrap()
        .catch((error) => {
          navigate('/');
          enqueueSnackbar(error, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    } else {
      navigate('/');
    }
  };

  const handleDecodeToken = async () => {
    if (authToken) {
      return dispatch(decodeAccessToken(authToken))
        .unwrap()
        .catch(() => {
          handleRefreshToken();
        });
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    if (authToken) {
      handleDecodeToken();
    }
  }, [authToken]);

  const handleAuthorizationRequests = (userData: LogInParams) => {
    dispatch(logInRequest(userData))
      .unwrap()
      .then(() => {
        const accessableRoutes = allRoutes.filter((route) =>
          route.roles.includes(currentUser?.role)
        );

        navigate(accessableRoutes[0]?.path);
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleAccountLogout = () => {
    Cookies.remove('RefreshToken');
    Cookies.remove('AuthToken');
    Cookies.remove('AuthUser');
    Cookies.remove('AvailableRoutes');
    Cookies.remove('currentUser');
    navigate('/');
  };

  return {
    // store
    allRoutes,
    currentUser,
    accessToken: authToken,
    // actions
    handleDecodeToken,
    handleAccountLogout,
    handleAuthorizationRequests,
  };
};
