import React, { useState, useEffect, useRef } from 'react';

// System
import { Box, Typography } from '@mui/material';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';
import { useCustomerRentals } from '@Modal/hooks/customers/rentals/useCustomerRentals';
import { setCustomerRentals } from '@Modal/reducers/customers/rentals/customerRentalsSlice';

// Components
import { useSnackbar } from 'notistack';
import { CustomerRentalCard } from '../CustomerRentalCard';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';

// Styles
import Styles from './CustomerRentals.module.scss';

interface RentalData {
  price: number;
  startTime: string;
  licensePlate: string;
  id: string;
  state: string;
}

export const CustomerRentals: React.FC = () => {
  const { getSingleRental, getSelectedRentalPriceBreakdown } = useRentalsDrawer();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleGetCustomerRentals,
    customerRentals,
    pagination,
    setCustomerRentalPagination,
    customerRentalPagination,
  } = useCustomerRentals();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleBoxClick = (id: string) => {
    dispatch(getSingleRental(id))
      .unwrap()
      .then(() => dispatch(getSelectedRentalPriceBreakdown(id)))
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    dispatch(setCustomerRentals([]));
    dispatch(setCustomerRentalPagination(0));
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    handleGetCustomerRentals();
    setLoading(false);
  }, [customerRentalPagination]);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        if (pagination?.totalCount && pagination?.offset < pagination?.totalCount) {
          pagination?.totalCount - pagination?.offset > 10
            ? dispatch(setCustomerRentalPagination(pagination?.offset + 10))
            : dispatch(
                setCustomerRentalPagination(
                  pagination?.offset + (pagination?.totalCount - pagination?.offset - 1)
                )
              );
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [customerRentals, customerRentalPagination]);

  return (
    <>
      <DrawerTabPanelLayout>
        <Box ref={containerRef} className={Styles.CustomerRentalsContainer}>
          {customerRentals.length !== 0 ? (
            customerRentals?.map((item: RentalData, index: number) => (
              <Box
                className={Styles.CustomerRentals}
                key={index}
                onClick={() => handleBoxClick(item.id)}
              >
                <CustomerRentalCard
                  price={item.price}
                  date={item.startTime}
                  licensePlate={item.licensePlate}
                  rentalId={item.id}
                  status={item.state}
                />
              </Box>
            ))
          ) : (
            <Box className={Styles.NoResultsContainer}>
              <Typography>No Results</Typography>
            </Box>
          )}
        </Box>
      </DrawerTabPanelLayout>
      {loading && <AssetIcon src="rental-loading" className={Styles.LoadingIcon} />}
    </>
  );
};
