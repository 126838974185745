import { Dispatch, MutableRefObject, createContext } from 'react';
import { noop } from '@utils/noop';

import { MapEditorMode, MapPath } from './MapEditorMode';

type SetupMapZoneContextType = {
  mode: MapEditorMode | undefined;
  setMode: Dispatch<MapEditorMode | undefined>;
  handleChangeMode: (value: MapEditorMode) => void | Promise<void>;
  mapPaths: MapPath[];
  setMapPaths: Dispatch<MapPath[]>;
  mapPathsRef: MutableRefObject<MapPath[]>;
  selectedIndex: number | undefined;
  setSelectedIndex: Dispatch<number | undefined>;
  error: string | undefined;
  setError: Dispatch<string | undefined>;
};

const defaultSetupMapZoneContextType: SetupMapZoneContextType = {
  mode: undefined,
  handleChangeMode: noop,
  mapPaths: [],
  setMapPaths: noop,
  mapPathsRef: { current: [] },
  setMode: noop,
  selectedIndex: undefined,
  setSelectedIndex: noop,
  error: undefined,
  setError: noop,
};

export const SetupMapZoneContext = createContext(defaultSetupMapZoneContextType);
