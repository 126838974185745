import { Comparer } from '@contracts/common/Comparer';

export const queueComparer = <T>(...comparers: Comparer<T>[]): Comparer<T> => {
  return (a, b) => {
    for (const comparer of comparers) {
      const intermidiate = comparer(a, b);
      if (intermidiate !== 0) {
        return intermidiate;
      }
    }
    return 0;
  };
};
