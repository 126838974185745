import { Box, Button, Stack, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  onApplyClick?: () => void;
  onResetClick?: () => void;
  children: JSX.Element;
  sx?: SystemStyleObject<Theme>;
};

export const TableFilterDialogLayout: React.FC<Props> = ({
  onApplyClick,
  onResetClick,
  children,
  sx,
}: Props) => {
  return (
    <Stack spacing={2} sx={[{ padding: 1 }, sx ?? {}]}>
      <Box>{children}</Box>
      <Stack direction="row" spacing={1}>
        <Button onClick={onApplyClick} size="small" variant="contained" data-at-button="Apply">
          Apply
        </Button>
        <Button
          onClick={onResetClick}
          size="small"
          variant="text"
          color="inherit"
          data-at-button="Reset"
        >
          Reset
        </Button>
      </Stack>
    </Stack>
  );
};
