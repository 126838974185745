import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { RHFSelect, RHFTextField } from '@Components/hook-form';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';

const useDropDown = () => {
  const { timezones } = useGeneralStore();
  const timezoneDropDown = useMemo(
    () =>
      timezones?.map((name) => (
        <MenuItemEx key={name} value={name} name="TimeZone">
          {name}
        </MenuItemEx>
      )),
    [timezones]
  );
  return {
    timezones: timezoneDropDown,
  };
};

export const TimezoneAndCoordinates = () => {
  const dropDown = useDropDown();
  return (
    <Stack direction="column" spacing={2}>
      <AtLocator type="readonly-text" name="TimezoneAndCoordinates">
        <Typography variant="button">Timezone & Coordinates</Typography>
      </AtLocator>
      <Stack direction="column" spacing={1.5}>
        <AtLocator type="select-field" name="timezone">
          <RHFSelect name="timezone" label="Choose Timezone" variant="outlined">
            {dropDown.timezones}
          </RHFSelect>
        </AtLocator>
        <Stack direction="row" justifyContent="space-between" spacing={1.5}>
          <AtLocator type="text-field" name="geoLocation.lat">
            <RHFTextField
              name="geoLocation.lat"
              id="setup-branch-geoLocation-lat"
              label="Latitude"
              variant="outlined"
              sx={{ flex: '1' }}
              type="number"
            />
          </AtLocator>
          <AtLocator type="text-field" name="geoLocation.lon">
            <RHFTextField
              name="geoLocation.lon"
              id="setup-branch-geoLocation-lat"
              label="Longitude"
              variant="outlined"
              sx={{ flex: '1' }}
              type="number"
            />
          </AtLocator>
        </Stack>
      </Stack>
    </Stack>
  );
};
