import React from 'react';
import { Stack } from '@mui/system';
import { Typography, Box } from '@mui/material';

import { BranchStatusLabel } from '@Features/branches/shared/labels/BranchStatusLabel';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import useResponsive from '@Hooks/shared/useResponsive';
import { useBranches } from '@Modal/hooks/branches/useBranch';

export const SetupBranchHeader: React.FC = () => {
  const { selectedBranch } = useBranches();
  const ellipsis = useEllipsis();
  const isDesktop = useResponsive('up', 'sm');

  const mobileStyles = !isDesktop
    ? {
        fontSize: 13,
        marginTop: '5px',
      }
    : null;

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Typography variant="h6">
        <AtLocator type="readonly-text" name="Title">
          <Box sx={{ ...ellipsis, maxWidth: '100%', ...mobileStyles }}>
            {selectedBranch?.name.length > 25
              ? `${selectedBranch?.name.slice(0, 25)} ...`
              : selectedBranch?.name}
          </Box>
        </AtLocator>
      </Typography>
      <BranchStatusLabel value={selectedBranch?.status} id={selectedBranch?.id} />
    </Stack>
  );
};
