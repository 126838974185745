import { useTheme } from '@mui/material';
import { CSSProperties, useMemo } from 'react';

import { VehicleServiceState } from '@contracts/domain/keys';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';

export const useVehicleServiceStateOptions = () => {
  const { vehicleServiceStates } = useGeneralStore();
  return useMemo(() => vehicleServiceStates ?? [], [vehicleServiceStates]);
};

export const useVehicleServiceStateTitle = () => {
  const { vehicleServiceStates } = useGeneralStore();
  return useMemo(() => {
    return Object.assign(
      {},
      ...vehicleServiceStates.map(({ key, value }) => ({ [key]: value } ?? []))
    );
  }, [vehicleServiceStates]);
};

export const useVehicleServiceStateColor = (): Record<
  VehicleServiceState,
  CSSProperties['color']
> => {
  const { palette } = useTheme();

  const colors: Record<VehicleServiceState, CSSProperties['color']> = {
    COLLECT: palette.error.main,
    FUNCTIONAL: palette.success.main,
    HIBERNATION: palette.error.main,
    IMPOUNDED: palette.error.main,
    INSPECT: palette.success.main,
    LOST: palette.error.main,
    MISSING_SPARE_PART: palette.error.main,
    OTHER: palette.error.main,
    READY_FOR_DEPLOYMENT: palette.error.main,
    REPAIR: palette.error.main,
    SCRAP: palette.error.main,
    SERVICE_ON_SITE: palette.error.main,
    SERVICE_WORKSHOP: palette.error.main,
    SUBMERGED: palette.error.main,
  };

  return colors;
};
