// System
import { useSelector } from 'react-redux';

// Action
import { setPagination } from '@Modal/reducers/rentals/rentalsSlice';

// Types
import { StoreInterface } from 'src/app/types';

export const useRoles = () => {
  const { roles, pagination, loadingContent } = useSelector(
    (state: StoreInterface) => state?.roles
  );

  return {
    // States
    roles,
    pagination,
    loadingContent,
    // Actions
    setPagination,
  };
};
