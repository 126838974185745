// System
import { useEffect, useMemo } from 'react';
import { Stack, Typography, MenuItem } from '@mui/material';
import { RHFSelect, RHFSwitch, RHFTextField } from '@Components/hook-form';

// Redux
import { useDispatch } from 'react-redux';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
// import { getVehicleDisplayNames, getVehicleTypes } from '@Modal/reducers/generalSlice';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useDisabledTextField } from '@Components/shared/styles/useDisabledTextField';

// Types
import { AppDispatch } from 'src/app/store';

const useDropDown = () => {
  const { vehicleDisplayNames, vehicleTypes } = useGeneralStore();

  const vehicleDisplayNameDropDown = useMemo(
    () =>
      vehicleDisplayNames.length > 0
        ? vehicleDisplayNames?.map(({ key, value }) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))
        : [],
    [vehicleDisplayNames]
  );

  const vehicleTypeDropDown = useMemo(
    () =>
      vehicleTypes.length > 0
        ? vehicleTypes?.map(({ key, value }) => (
            <MenuItem key={key} value={key}>
              {value}
            </MenuItem>
          ))
        : [],
    [vehicleTypes]
  );

  return {
    vehicleTypes: vehicleTypeDropDown,
    vehicleDisplayNames: vehicleDisplayNameDropDown,
  };
};

export const Common: React.FC = () => {
  const dropDown = useDropDown();
  const disabledTextField = useDisabledTextField();

  return (
    <Stack direction="column" spacing={1.5}>
      <AtLocator type="text-field" name="id">
        <RHFTextField
          disabled
          size="small"
          name="id"
          id="outlined-basic"
          label="ID"
          variant="outlined"
          sx={disabledTextField}
        />
      </AtLocator>
      <AtLocator type="text-field" name="name">
        <RHFTextField
          name="name"
          size="small"
          id="outlined-basic"
          label="Name"
          variant="outlined"
        />
      </AtLocator>
      <AtLocator type="select-field" name="displayName">
        <RHFSelect
          name="displayName"
          size="small"
          id="outlined-basic"
          label="Vehicle display name in dashboard"
          variant="outlined"
        >
          {dropDown?.vehicleDisplayNames}
        </RHFSelect>
      </AtLocator>
      <AtLocator type="select-field" name="vehicleType">
        <RHFSelect
          name="vehicleType"
          id="outlined-basic"
          size="small"
          label="Type"
          variant="outlined"
        >
          {dropDown?.vehicleTypes}
        </RHFSelect>
      </AtLocator>
      <AtLocator type="special-field" name="helmetTracking">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <AtLocator type="readonly-text" name="label">
            <Typography variant="body2">Helmet tracking</Typography>
          </AtLocator>
          <RHFSwitch name="helmetTracking" label="" labelPlacement="start" />
        </Stack>
      </AtLocator>
    </Stack>
  );
};
