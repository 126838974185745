import {
  defaultProviderListFilter,
  providerListColumnVisibilityOptions,
  defaultProviderListColumnVisibility,
} from '@utils/tables/providers/providerListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultProviderListFilter,
  columnVisibility: defaultProviderListColumnVisibility,
  columnVisibilityOptions: providerListColumnVisibilityOptions,
};

const providerTableSlice = createSlice({
  name: 'providerTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: providerTableReducer } = providerTableSlice;
export const { setColumnVisibility } = providerTableSlice.actions;
