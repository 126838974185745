// System
import { SystemStyleObject } from '@mui/system';
import { Stack, Box, Divider, Theme } from '@mui/material';

// Types
type Props = {
  sx?: SystemStyleObject<Theme>;
  header: JSX.Element;
  children: JSX.Element | JSX.Element[];
  footer: JSX.Element;
};

// Styles
import Styles from './TabDrawerLayout.module.scss';

export const TabDrawerLayout: React.FC<Props> = ({ header, footer, children }) => {
  return (
    <Stack className={Styles.TabDrawerLayout}>
      <Box className={Styles.HeaderContainer}>{header}</Box>
      <Box className={Styles.ChildrenContainer}>{children}</Box>
      <Stack className={Styles.FooterContainer}>{footer}</Stack>
    </Stack>
  );
};
