// System
import { Button, Typography, Stack, Checkbox } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Form
import { useDeleteCustomerForm } from './DeleteCustomerNoteFormContext';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useDeleteCustomer } from '@Modal/hooks/customers/deleteCustomer/useDeleteCustomer';

// Styles
import Styles from './DeleteCustomerModalDialog.module.scss';

export const DeleteCustomerModalDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const methods = useDeleteCustomerForm();
  const {
    showDeleteCustomerModalDialog,
    handleDeleteCustomerModalDialog,
    handleDeleteCustomerRequest,
    submittable,
    forceDelete,
    setForceDelete,
    showWarning,
  } = useDeleteCustomer();

  return (
    <ClosableModal
      name="deleteCustomer"
      title="Delete Customer"
      open={showDeleteCustomerModalDialog}
      onClose={() => {
        handleDeleteCustomerModalDialog(false);
        dispatch(setForceDelete(false));
      }}
      // sx={{ width: '592px' }}
      className={Styles.DeleteCustomerModalDialog}
    >
      <>
        {showWarning && (
          <Stack className={Styles.WarningContainer}>
            <AssetIcon src="ic_error" className={Styles.WarningIcon} />
            <Typography variant="subtitle2" className={Styles.WarningText}>
              User has existing activity
            </Typography>
          </Stack>
        )}
        <FormProvider methods={methods} onSubmit={handleDeleteCustomerRequest}>
          <CreateDialogLayout>
            <RHFTextField
              name="note"
              multiline
              minRows={4}
              maxRows={15}
              placeholder="Please provide the reason for deleting the customer."
              inputProps={{ maxLength: 500 }}
              helperText="Maximum 500 characters"
            />
            <Stack className={Styles.ForceCheckboxContainer}>
              {showWarning && (
                <>
                  <Typography variant="body2">
                    Force (Ignore existing rentals, invoices and negative balance)
                  </Typography>
                  <Checkbox
                    checked={forceDelete}
                    onChange={() => dispatch(setForceDelete(!forceDelete))}
                  />
                </>
              )}
            </Stack>
            <CreateDialogFooterLayout>
              <AtLocator type="button" name="Cancel">
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    handleDeleteCustomerModalDialog(false), dispatch(setForceDelete(false));
                  }}
                >
                  Cancel
                </Button>
              </AtLocator>
              <AtLocator type="button" name="Submit">
                <Button type="submit" color="error" variant="contained" disabled={!submittable}>
                  Delete
                </Button>
              </AtLocator>
            </CreateDialogFooterLayout>
          </CreateDialogLayout>
        </FormProvider>
      </>
    </ClosableModal>
  );
};
