import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Stack, Typography, Box } from '@mui/material';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

const useSx = () => {
  return {
    id: {
      fontWeight: '700',
      fontSize: '18px',
      lineHeight: '28px',
      marginLeft: '',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupTransactionDrawerHeader = () => {
  const ellipsis = useEllipsis();
  const { selectedTransactionData } = useTransactionsDrawer();
  const styles = useSx();

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Stack spacing={1} direction="row" sx={{ ...ellipsis }}>
        <Typography variant="h6">Transaction</Typography>{' '}
        <TruncateText text={selectedTransactionData.id} maxLength={8} textStyles={styles.id} />
      </Stack>
    </Stack>
  );
};
