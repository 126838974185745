import { TableRow, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  sx?: SystemStyleObject<Theme>;
  children: JSX.Element | JSX.Element[];
};

export const TableHeadRowEx: React.FC<Props> = ({ sx, children, ...rest }) => {
  return (
    <TableRow {...rest} sx={sx}>
      {children}
    </TableRow>
  );
};
