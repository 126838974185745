import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const MasterCardIcon = ({ width, height }: CardProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="24" rx="4" fill="white" />
        <rect x="14" y="6" width="7" height="12" fill="#F26122" />
        <path
          d="M14.9712 11.5C14.9585 9.20517 16.075 7.03192 18 5.60471C14.7064 3.18672 9.97524 3.53784 7.13225 6.41125C4.28925 9.28467 4.28925 13.7153 7.13225 16.5887C9.97524 19.4622 14.7064 19.8133 18 17.3953C16.075 15.9681 14.9585 13.7948 14.9712 11.5Z"
          fill="#EA1D25"
        />
        <path
          d="M30 11.5C29.9982 14.3713 28.3768 16.9899 25.8237 18.245C23.2705 19.5001 20.2332 19.1717 18 17.3991C21.2168 14.8372 21.7739 10.1237 19.2448 6.86699C18.8822 6.39539 18.4642 5.97022 18 5.60087C20.2332 3.8283 23.2705 3.49992 25.8237 4.75502C28.3768 6.01013 29.9982 8.6287 30 11.5V11.5Z"
          fill="#F69E1E"
        />
      </svg>
    </svg>
  );