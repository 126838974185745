import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { Button } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { useRentalsNote } from '@Modal/hooks/rentals/useRentalsNote';
import { useCreateRentalsNoteForm } from './CreateRentalsNoteFormContext';

export const AddRentalsNoteModalDialog = () => {
  const { showCreateRentalNoteForm, handleCreateNote, handleCreateNoteCancel } = useRentalsNote();
  const methods = useCreateRentalsNoteForm();

  return (
    <>
      {showCreateRentalNoteForm && (
        <ClosableModal
          name="addNote"
          title="Add note"
          open={showCreateRentalNoteForm}
          onClose={() => handleCreateNoteCancel()}
          sx={{ width: '592px' }}
        >
          <FormProvider methods={methods}>
            <CreateDialogLayout>
              <RHFTextField
                name="note"
                multiline
                minRows={4}
                maxRows={9}
                placeholder="Note description"
                inputProps={{ maxLength: 300 }}
                helperText="Maximum 300 characters"
              />
              <CreateDialogFooterLayout>
                <AtLocator type="button" name="Cancel">
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => handleCreateNoteCancel()}
                  >
                    Cancel
                  </Button>
                </AtLocator>
                <AtLocator type="button" name="Submit">
                  <Button type="submit" variant="contained" onClick={(e) => handleCreateNote(e)}>
                    Add
                  </Button>
                </AtLocator>
              </CreateDialogFooterLayout>
            </CreateDialogLayout>
          </FormProvider>
        </ClosableModal>
      )}
    </>
  );
};
