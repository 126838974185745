import { Tab, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ReactNode } from 'react';

type Props = {
  label: ReactNode;
  value: string;
  sx?: SystemStyleObject<Theme>;
};

export const TabEx: React.FC<Props> = ({ label, value, sx, ...rest }) => {
  return (
    <Tab
      {...rest}
      label={label}
      value={value}
      sx={[
        {
          paddingX: 2,
          marginX: 0,
          '&:not(:last-of-type)': {
            marginRight: 0,
          },
        },
        sx ?? {},
      ]}
      data-at-tab-value={value}
    />
  );
};
