import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateBrandFormContext,
  CreateBrandFormState,
  createBrandFormStateDefault,
  createBrandSchemaDefault,
} from '@Forms/branches/CreateBrandFormContext';

export const CreateBrandFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<CreateBrandFormState>>(createBrandSchemaDefault);
  const methods = useForm({
    defaultValues: createBrandFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateBrandFormContext.Provider value={value}>{children}</CreateBrandFormContext.Provider>
  );
};
