import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Type
import { BranchesType, CreateBranchType, UpdateBranchStatusType, GetBranchParams } from './types';

// GET
export const fetchBranches = createAsyncThunk(
  'branches/fetchBranches',
  async (params: GetBranchParams, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/branches`, {
        params:
          params?.brandId !== 'undefined' && params?.brandId
            ? { ...params.selectedFilters, brandId: params?.brandId }
            : { ...params.selectedFilters },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

export const fetchAllBranches = createAsyncThunk(
  'branches/fetchAllBranches',
  async (_, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/branches`, {})
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

export const fetchBrands = createAsyncThunk('branches/fetchBrands', async (_, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get('/v1/branches/brands')
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error || 'Rejected');
    });
});

export const getSingleBranch = createAsyncThunk(
  'branches/getSingleBranch',
  async (branchId: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/branches/${branchId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST
export const createNewBranch = createAsyncThunk(
  'branches/createNewBranch',
  async (branchData: CreateBranchType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post('/v1/branches', {
        ...branchData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT
export const updateBranch = createAsyncThunk(
  'branches/updateBranch',
  async (branchData: BranchesType, thunkApi) => {
    const {
      categories,
      code,
      currency,
      geoLocation,
      level,
      mapZoomLevel,
      name,
      parentId,
      timezone,
      vat,
      id,
    } = branchData;

    const checkGeoLoc = Object.values(geoLocation).filter((va) => va);

    return axiosBranchesVehiclesInstance
      .put(`/v1/branches/${id}`, {
        code,
        currency,
        geoLocation: checkGeoLoc.length > 0 ? geoLocation : null,
        level,
        mapZoomLevel,
        name,
        parentId,
        timezone,
        vat,
        categories,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message || 'Rejected');
      });
  }
);

export const updateBranchStatus = createAsyncThunk(
  'branches/updateBranchStatus',
  async (dataToUpdate: UpdateBranchStatusType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/branches/${dataToUpdate?.id}/status`, {
        status: dataToUpdate?.status,
      })
      .then((_) => {
        return thunkApi.fulfillWithValue({
          id: dataToUpdate?.id,
          status: dataToUpdate?.status,
        });
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

const initialState = {
  brands: [],
  branches: [],
  allBranches: [],
  categories: [],
  mapZoneList: [],
  unassignedCategories: [],
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
  selectedBrand: null,
  selectedBranch: null,
  activeBrandId: null,
  isStatusError: false,
  showUpdateStatusModal: false,
  loadingContent: true,
  selectedStatus: null,
  branchStatuses: [
    {
      key: 'operational',
      value: 'Operational',
    },
    {
      key: 'out_of_business',
      value: 'Out of business',
    },
  ],
};

const branchSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    setBranchesList: (state, { payload }) => {
      state.branches = payload;
    },
    setBrandsList: (state, { payload }) => {
      state.brands = payload;
    },
    setSelectedBrand: (state, { payload }) => {
      state.selectedBrand = payload;
      state.activeBrandId = payload?.id;
      localStorage.setItem('activeBrandId', payload?.id);
    },
    setSelectedBranch: (state, { payload }) => {
      state.selectedBranch = payload;
    },
    setShowUpdateStatusModal: (state, { payload }) => {
      state.showUpdateStatusModal = payload.modalStatus;
      state.selectedStatus = payload.status;
      state.selectedBranch = payload.selectedBranch;
    },
    setCloseUpdateStatusModal: (state, { payload }) => {
      state.showUpdateStatusModal = payload.modalStatus;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
    setLoadingBranchContent: (state, { payload }) => {
      state.loadingContent = payload;
    },
  },
  extraReducers: {
    // Get Branches API
    [fetchBranches.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;

      const branchesFullData = items;

      state.branches = branchesFullData;
      state.loadingContent = false;
      state.pagination = {
        ...state.pagination,
        count: pageInfo.totalCount,
      };
    },
    [fetchAllBranches.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;
      const branchesFullData = items;
      state.allBranches = branchesFullData;
    },
    [fetchBrands.fulfilled?.type]: (state, action) => {
      const { items } = action.payload;
      state.brands = items;
      const activeBrandFromLocal = localStorage.getItem('activeBrandId');
      if (activeBrandFromLocal) {
        const latestBrand = items?.find((brand: BranchesType) => brand.id === activeBrandFromLocal);
        // @ts-ignore
        state.activeBrandId = localStorage.getItem('activeBrandId');
        state.selectedBrand = latestBrand;
      } else {
        localStorage.setItem('activeBrandId', items?.[0]?.id);
        state.selectedBrand = items?.[0];
        state.activeBrandId = items?.[0]?.id;
      }
    },
    [getSingleBranch.fulfilled?.type]: (state, { payload }) => {
      const selectedBranch = payload;
      state.selectedBranch = selectedBranch;
    },
    // Create Branch API
    [createNewBranch.fulfilled?.type]: (state, action) => {
      const newBranch = action?.payload;
      const updatedBranchhesArray = [
        ...state.branches,
        // @ts-ignore
        { ...newBranch, parentName: state.selectedBranch?.name },
      ];
      // @ts-ignore
      state.branches = updatedBranchhesArray;
      state.selectedBranch = null;
    },
    // Update Branch Status Api
    [updateBranchStatus.fulfilled?.type]: (state, action) => {
      const updatedBranches = [...state.branches].map((branch: BranchesType) => {
        // @ts-ignore
        return branch.id === state.selectedBranch?.id
          ? { ...branch, status: state.selectedStatus }
          : branch;
      });

      const updatedSelectedBranch = updatedBranches.find(
        // @ts-ignore
        (branch) => state.selectedBranch?.id === branch.id
      );

      // @ts-ignore
      state.selectedBranch = updatedSelectedBranch;
      // @ts-ignore
      state.branches = updatedBranches;
      state.showUpdateStatusModal = false;
    },
  },
});

export const { reducer: branchReducer } = branchSlice;
export const {
  setBrandsList,
  setPagination,
  setBranchesList,
  setSelectedBrand,
  setSelectedBranch,
  setLoadingBranchContent,
  setShowUpdateStatusModal,
  setCloseUpdateStatusModal,
} = branchSlice.actions;
