import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedAviatorRoleData: {},
  showAviatorRoleDrawer: false,
  showAviatorRoleDeleteModalDialog: false,
};

const aviatorRoleDrawerslice = createSlice({
  name: 'aviatorRoleDrawerslice',
  initialState,
  reducers: {
    setSelectedAviatorRole: (state, { payload }) => {
      state.selectedAviatorRoleData = payload;
    },
    setShowAviatorRoleDrawer: (state, { payload }) => {
      state.showAviatorRoleDrawer = payload;
    },
    setShowAvaitorRoleDeleteModal: (state, { payload }) => {
      state.showAviatorRoleDeleteModalDialog = payload;
    },
  },
});

export const { reducer: aviatorRoleDrawerReducer } = aviatorRoleDrawerslice;
export const { setSelectedAviatorRole, setShowAviatorRoleDrawer, setShowAvaitorRoleDeleteModal } =
  aviatorRoleDrawerslice.actions;
