import React from 'react';

import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

import { AutoEndRental } from './AutoEndRental';
import { Settings } from './Settings';

export const NoMovement = () => {
  return (
    <DrawerTabPanelLayout>
      <DrawerTabSectionLayout>
        <Settings />
      </DrawerTabSectionLayout>
      <DrawerTabSectionLayout>
        <AutoEndRental />
      </DrawerTabSectionLayout>
    </DrawerTabPanelLayout>
  );
};
