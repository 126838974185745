import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  DeleteCustomerFormContext,
  DeleteCustomerFormState,
  DeleteCustomerSchemaDefault,
  DeleteCustomerFormStateDefault,
} from '@Forms/customers/deleteCustomer/DeleteCustomerNoteFormContext';

export const DeleteCustomerFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<DeleteCustomerFormState>>>(
    DeleteCustomerSchemaDefault
  );
  const methods = useForm({
    defaultValues: DeleteCustomerFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <DeleteCustomerFormContext.Provider value={value}>
      {children}
    </DeleteCustomerFormContext.Provider>
  );
};
