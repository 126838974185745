// System
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Hooks
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Styles
import Styles from './SetupOperatorProfileDrawer.module.scss';

// Redux
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

export const SetupOperatorProfileDrawer = () => {
  const dispatch = useDispatch();
  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  const { showOperatorProfileModal, setShowOperatorProfileModal } = useOperatorVehicles();
  const { handleAccountLogout } = useAuthorization();

  return (
    <SetupDrawer
      name="SetupOperatorProfileModal"
      open={showOperatorProfileModal}
      onClose={() => dispatch(setShowOperatorProfileModal(false))}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorApproachDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Profile
          </Typography>
          <Box
            className={Styles.CloseIcon}
            onClick={() => dispatch(setShowOperatorProfileModal(false))}
          >
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <Box className={Styles.UserPohoContainer}>
          <AssetIcon src="operatorProfilePhoto" className={Styles.UserPhoto} />
        </Box>
        <Typography fontSize={18} fontWeight={900} className={Styles.UserFullnameContainer}>
          {currentUser?.firstName !== null && currentUser?.lastName !== null
            ? `${currentUser?.firstName} ${currentUser?.lastName}`
            : null}
        </Typography>
        <Box className={Styles.InfoBoxContainer}>
          <Typography className={Styles.InfoKeyFont}>User ID</Typography>
          <Typography className={Styles.InfoValueFont}>
            {currentUser?.id ? <TruncateText text={currentUser?.id} maxLength={8} /> : null}
          </Typography>
        </Box>
        <Box className={Styles.InfoBoxContainer}>
          <Typography className={Styles.InfoKeyFont}>Full name</Typography>
          <Typography className={Styles.InfoValueFont}>
            {currentUser?.firstName !== null && currentUser?.lastName !== null
              ? `${currentUser?.firstName} ${currentUser?.lastName}`
              : null}
          </Typography>
        </Box>
        <Box className={Styles.InfoBoxContainer}>
          <Typography className={Styles.InfoKeyFont}>E-mail address</Typography>
          <Typography className={Styles.InfoValueFont}>
            {currentUser?.username ? currentUser?.username : null}
          </Typography>
        </Box>
        <Box className={Styles.Footer}>
          <Button
            onClick={() => {
              dispatch(setShowOperatorProfileModal(false)), handleAccountLogout();
            }}
            className={Styles.ApproachCTA}
            variant="contained"
          >
            Log out
          </Button>
        </Box>
      </Box>
    </SetupDrawer>
  );
};
