import { CSSProperties, useMemo } from 'react';
import { useTheme } from '@mui/material';

import { KeyValue } from '@contracts/view/KeyValue';
import { ChargingStatus } from '@contracts/domain/keys';

export const useChargingStatusColor = () => {
  const { palette } = useTheme();
  return useMemo(
    (): Record<ChargingStatus, CSSProperties['color']> => ({
      charging: palette.success.main,
      unplugged: palette.error.main,
      unknown: palette.action.disabled,
    }),
    [palette]
  );
};

export const useChargingStatusTitle = () => {
  return useMemo(
    (): Record<ChargingStatus, string> => ({
      charging: 'Charging',
      unplugged: 'Unplugged',
      unknown: 'Unknown',
    }),
    []
  );
};

export const useChargingStatusOptions = (): KeyValue<ChargingStatus, string>[] => {
  const titles = useChargingStatusTitle();
  return useMemo(
    () => (Object.keys(titles) as ChargingStatus[]).map((key) => ({ key, value: titles[key] })),
    [titles]
  );
};
