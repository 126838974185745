import React, { useMemo } from 'react';

import FormProvider, { RHFSelect, RHFTextField } from '@Components/hook-form';
import { Button, Stack, Box } from '@mui/material';

import { useCreateCategoryForm } from '@Forms/vehicles/CreateCategoryFormContext';
import { useCreateVehicleCategory } from '@Modal/hooks/vehicles/category/useCreateVehicleCategory';

// Styles
import Styles from './CreateVehicleCategoryForm.module.scss';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';

const useDropDown = () => {
  const { vehicleTypes } = useGeneralStore();

  const vehicleTypesDropdown = useMemo(
    () =>
      vehicleTypes.map(({ key, value }) => (
        <MenuItemEx key={key} value={key} name="VehicleTypes">
          {value}
        </MenuItemEx>
      )),
    [vehicleTypes]
  );
  return {
    vehicleCategoryTypes: vehicleTypesDropdown,
  };
};

export const CreateVehicleCategoryForm = () => {
  const { handleSubmitCreateVehicleCategory, submittable, handleCreateCancel } =
    useCreateVehicleCategory();

  const methods = useCreateCategoryForm();

  return (
    <FormProvider methods={methods} onSubmit={handleSubmitCreateVehicleCategory}>
      <Box className={Styles.CreateVehicleCategoryForm}>
        <RHFTextField
          id="name"
          name="name"
          label="Category name"
          variant="outlined"
          className={Styles.CategoryNameInput}
        />
        <RHFSelect
          label="Select type"
          name="vehicleType"
          variant="outlined"
          className={Styles.SelectTypeInput}
        >
          {useDropDown().vehicleCategoryTypes}
        </RHFSelect>
        <Stack className={Styles.FooterContainer}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleCreateCancel}
            className={Styles.CancelBtn}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={!submittable}>
            Confirm
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  );
};
