// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedAviatorRole,
  setShowAviatorRoleDrawer,
  setShowAvaitorRoleDeleteModal,
} from '@Modal/reducers/aviatorRoles/aviatorRoleDrawerSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// GraphQL
import { useDeleteRoleMutation, useGetRoleQuery, useGetRolesQuery } from '@Src/generated.graphql';

export const useAviatorRoleDrawer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showAviatorRoleDrawer, showAviatorRoleDeleteModalDialog, selectedAviatorRoleData } =
    useSelector((state: StoreInterface) => state?.aviatorRoleDrawer);
  const { refetch } = useGetRolesQuery();
  const { data, refetch: refetchRole } = useGetRoleQuery({ skip: true });
  const [deleteAviatorRole] = useDeleteRoleMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleAviatorRoleRowClick = async (id: string) => {
    const { data } = await refetchRole({ id: id });
    await dispatch(setSelectedAviatorRole(data?.role));
    dispatch(setShowAviatorRoleDrawer(true));
  };

  const handleAviatorRoleDelete = async () => {
    try {
      await deleteAviatorRole({
        variables: {
          input: {
            roleId: selectedAviatorRoleData?.id,
          },
        },
      });
      refetch();
      dispatch(setShowAviatorRoleDrawer(false));
      dispatch(setShowAvaitorRoleDeleteModal(false));
      enqueueSnackbar('Role has been successfully deleted', {
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        autoHideDuration: 2000,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  return {
    // Store
    showAviatorRoleDrawer,
    selectedAviatorRoleData,
    showAviatorRoleDeleteModalDialog,
    // Actions
    setSelectedAviatorRole,
    setShowAviatorRoleDrawer,
    setShowAvaitorRoleDeleteModal,
    // Handlers
    handleAviatorRoleDelete,
    handleAviatorRoleRowClick,
  };
};
