// System
import { Button } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Form
import { useCreateVehicleNoteForm } from '@Forms/vehicles/CreateVehicleNoteFormContext';

// Redux
import { useVehicleNotes } from '@Modal/hooks/vehicles/notes/useVehicleNotes';

// Styles
import Styles from './AddVehicleNoteModalDialog.module.scss';

export const AddVehicleNoteModalDialog = () => {
  const methods = useCreateVehicleNoteForm();

  const { showCreateVehicleNote, handleCreateCancel, handleSubmitVehicleNote } = useVehicleNotes();

  return (
    <>
      {showCreateVehicleNote && (
        <ClosableModal
          name="addNote"
          title="Add note"
          open={showCreateVehicleNote}
          onClose={handleCreateCancel}
          className={Styles.AddVehicleNoteModalDialog}
        >
          <FormProvider methods={methods}>
            <CreateDialogLayout>
              <RHFTextField
                name="note"
                multiline
                minRows={4}
                maxRows={9}
                placeholder="Note description"
                inputProps={{ maxLength: 300 }}
                helperText="Maximum 300 characters"
              />
              <CreateDialogFooterLayout>
                <AtLocator type="button" name="Cancel">
                  <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
                    Cancel
                  </Button>
                </AtLocator>
                <AtLocator type="button" name="Submit">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(e) => handleSubmitVehicleNote(e)}
                  >
                    Add
                  </Button>
                </AtLocator>
              </CreateDialogFooterLayout>
            </CreateDialogLayout>
          </FormProvider>
        </ClosableModal>
      )}
    </>
  );
};
