// System
import { combineReducers, configureStore } from '@reduxjs/toolkit';

// Branch Reducers
import {
  branchReducer,
  createBrandReducer,
  createBranchReducer,
  branchDrawerReducer,
  mapZoneReducer,
} from '@Modal/reducers';
import { branchTableReducer } from '@Modal/reducers/branches/branchTableSlice';

// General
import { generalReducer } from '@Modal/reducers/generalSlice';

// Rentals
import { rentalsReducer } from '@Modal/reducers/rentals/rentalsSlice';
import { rentalsNoteReducer } from '@Modal/reducers/rentals/rentalsNoteSlice';
import { rentalsTableReducer } from '@Modal/reducers/rentals/rentalsTableSlice';
import { rentalsDrawerReducer } from '@Modal/reducers/rentals/rentalsDrawerSlice';

// Vehicles
import { vehiclesReducer } from '@Modal/reducers/vehicles/vehiclesSlice';
import { vehiclesMapReducer } from '@Modal/reducers/vehicles/vehicles/vehicleMapSlice';
import { createVehicleReducer } from '@Modal/reducers/vehicles/vehicles/createVehicleSlice';
import { createVehicleCategoryReducer } from '@Modal/reducers/vehicles/categories/createVehicleCategorySlice';
import { vehicleNotesReducer } from '@Modal/reducers/vehicles/notes/vehicleNotesSlice';
import { vehicleRentalsReducer } from '@Modal/reducers/vehicles/rentals/vehicleRentalsSlice';
import { vehiclesDrawerReducer } from '@Modal/reducers/vehicles/vehicles/vehiclesDrawerSlice';
import { createVehicleIOTModuleReducer } from '@Modal/reducers/vehicles/iot/createVehicleIOTSlice';
import { vehicleCategoryDrawerReducer } from '@Modal/reducers/vehicles/categories/vehicleCategoryDrawerSlice';
import { customerRentalsReducer } from '@Modal/reducers/customers/rentals/customerRentalsSlice';
import { vehicleTableReducer } from '@Modal/reducers/vehicles/vehicles/vehicleTableSlice';
import { iotTableReducer } from '@Modal/reducers/vehicles/iot/iotTableSlice';
import { activityReducer } from '@Modal/reducers/vehicles/Activity/activitySlice';

// Customers
import { csvSliceReducer } from '@Modal/reducers/vehicles/csv/csvSlice';
import { customersReducer } from '@Modal/reducers/customers/customersSlice';
import { customerTableReducer } from '@Modal/reducers/customers/customerTableSlice';
import { customersDrawerReducer } from '@Modal/reducers/customers/customerDrawerSlice';
import { customerNotesReducer } from '@Modal/reducers/customers/notes/customerNotesSlice';
import { customerPaymentMethodReducer } from '@Modal/reducers/customers/paymentMethods/paymentMethodSlice';
import { customerBalanceReducer } from '@Modal/reducers/customers/balance/customerBalanceSlice';
import { deleteCustomerReducer } from '@Modal/reducers/customers/deleteCustomer/customerDeleteSlice';
import { signOutCustomerReducer } from '@Modal/reducers/customers/signOutCustomer/signOutCustomerSlice';
import { blockUnblockCustomerReducer } from '@Modal/reducers/customers/blockCustomer/blockUnblockCustomerSlice';

// Pricing
import { pricingReducer } from '@Modal/reducers/pricing/pricingSlice';
import { pricingTableReducer } from '@Modal/reducers/pricing/pricingTableSlice';
import { createPricingReducer } from '@Modal/reducers/pricing/createPricingSlice';
import { pricingDrawerReducer } from '@Modal/reducers/pricing/pricingDrawerSlice';
import { iotDrawerReducer } from '@Modal/reducers/vehicles/iot/iotDrawerSlice';

// Transaction
import { transactionsReducer } from '@Modal/reducers/transactions/transactionsSlice';
import { transactionsTableReducer } from '@Modal/reducers/transactions/transactionTableSlice';
import { transactionsDrawerReducer } from '@Modal/reducers/transactions/transactionDrawerSlice';

// Operator
import { operatorScanReducer } from '@Modal/reducers/operator/operatorScan/operatorScanSlice';
import { operatorFiltersReducer } from '@Modal/reducers/operator/operatorFilters/operatorFiltersSlice';
import { operatorVehiclesReducer } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Authorization
import { authorizationReducer } from '@Modal/reducers/authorization/authorizationSlice';

// Roles
import { rolesReducer } from '@Modal/reducers/roles/rolesSlice';
import { rolesTableReducer } from '@Modal/reducers/roles/rolesTableSlice';
import { addUserReducer } from '@Modal/reducers/roles/addUserSlice';
import { rolesDrawerReducer } from '@Modal/reducers/roles/rolesDrawerSlice';

// Redis
import { redisReducer } from '@Modal/reducers/redis/redisSlice';

// Providers
import { providerTableReducer } from '@Modal/reducers/providers/table/providerTableSlice';
import { createProviderReducer } from '@Modal/reducers/providers/createProvider/CreateProviderSlice';
import { providerDrawerReducer } from '@Modal/reducers/providers/drawer/providerDrawerSlice';

// Rounds
import { roundTableReducer } from '@Modal/reducers/rounds/table/roundsTableSlice';
import { roundDrawerReducer } from '@Modal/reducers/rounds/drawer/roundDrawerSlice';
import { createRoundConfigReducer } from '@Modal/reducers/rounds/createRoundConfig/CreateRoundConfigSlice';

// Transaction
import { usersReducer } from '@Modal/reducers/users/table/usersSlice';
import { userTableReducer } from '@Modal/reducers/users/table/userTableSlice';
import { createUserReducer } from '@Modal/reducers/users/createUser/createUserSlice';
import { transactionTableReducer } from '@Modal/reducers/transaction/table/transactionTableSlice';
import { avTransactionDrawerReducer } from '@Modal/reducers/transaction/drawer/avTransactionDrawerSlice';
import { providerTransactionTableReducer } from '@Modal/reducers/providerTransactions/providerTransactionTableSlice';

// Campaign
import { campaignTableReducer } from '@Modal/reducers/campaigns/table/campaignTableSlice';
import { freebetTableReducer } from '@Modal/reducers/freebets/freebetTableSlice';
import { freebetUsageTableReducer } from '@Modal/reducers/freebetsUsage/freebetUsageTableSlice';

// AviatorRoles
import { roleTableReducer } from '@Modal/reducers/aviatorRoles/roleTableSlice';
import { aviatorRoleDrawerReducer } from '@Modal/reducers/aviatorRoles/aviatorRoleDrawerSlice';
import { createAviatorRoleReducer } from '@Modal/reducers/aviatorRoles/createAviatorRoleSlice';

// Analytics
import { analyticTableReducer } from '@Modal/reducers/analytics/table/analyticsTableSlice';

const rootReducer = combineReducers({
  // Branches
  branchReducer,
  createBrandReducer,
  branchDrawerReducer,
  createBranchReducer,
  maps: mapZoneReducer,
  branchesTable: branchTableReducer,

  // General
  general: generalReducer,

  // Rentals
  rentals: rentalsReducer,
  rentalsNote: rentalsNoteReducer,
  rentalsTable: rentalsTableReducer,
  rentalsDrawer: rentalsDrawerReducer,

  // Vehicles
  csv: csvSliceReducer,
  activity: activityReducer,
  vehicles: vehiclesReducer,
  iotsTable: iotTableReducer,
  iotDrawer: iotDrawerReducer,
  vehiclesMap: vehiclesMapReducer,
  vehicleNotes: vehicleNotesReducer,
  vehiclesTable: vehicleTableReducer,
  createVehicle: createVehicleReducer,
  vehicleDrawer: vehiclesDrawerReducer,
  vehicleRentals: vehicleRentalsReducer,
  createVehicleCategory: createVehicleCategoryReducer,
  vehicleCategoryDrawer: vehicleCategoryDrawerReducer,
  createVehicleIOTModule: createVehicleIOTModuleReducer,

  // Pricing
  pricing: pricingReducer,
  pricingTable: pricingTableReducer,
  createPricing: createPricingReducer,
  pricingDrawer: pricingDrawerReducer,

  // Customers
  customers: customersReducer,
  customerNotes: customerNotesReducer,
  customerDrawer: customersDrawerReducer,
  customerRentals: customerRentalsReducer,
  customersTable: customerTableReducer,
  paymentMethods: customerPaymentMethodReducer,
  customerBalance: customerBalanceReducer,
  deleteCustomer: deleteCustomerReducer,
  signOutCustomer: signOutCustomerReducer,
  blockUnblockCustomer: blockUnblockCustomerReducer,

  // Transactions
  transactions: transactionsReducer,
  transactionDrawer: transactionsDrawerReducer,
  transactionsTable: transactionsTableReducer,

  // Operator
  operatorScan: operatorScanReducer,
  operatorFilters: operatorFiltersReducer,
  operatorVehicles: operatorVehiclesReducer,

  // Roles
  roles: rolesReducer,
  rolesTable: rolesTableReducer,
  addUser: addUserReducer,
  roleDrawer: rolesDrawerReducer,

  // Authorization
  authorization: authorizationReducer,

  // Redis
  redis: redisReducer,

  // Providers
  providersTable: providerTableReducer,
  createProvider: createProviderReducer,
  providerDrawer: providerDrawerReducer,

  // Rounds
  roundsTable: roundTableReducer,
  roundDrawer: roundDrawerReducer,
  createRoundConfig: createRoundConfigReducer,

  // Transactions
  transactionTable: transactionTableReducer,
  providerTransactionTable: providerTransactionTableReducer,
  avTransactionDrawer: avTransactionDrawerReducer,

  // Users
  users: usersReducer,
  usersTable: userTableReducer,
  createUser: createUserReducer,
  aviatorRolesTable: roleTableReducer,
  aviatorRoleDrawer: aviatorRoleDrawerReducer,
  createAviatorRole: createAviatorRoleReducer,

  // Campaigns
  campaignsTable: campaignTableReducer,
  freebetsTable: freebetTableReducer,
  freebetsUsageTable: freebetUsageTableReducer,

  // Analytics
  analyticsTable: analyticTableReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
