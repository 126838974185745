import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { AllMapZoneType } from '@contracts/domain/extendedKeys';
import { MapZoneType } from '@contracts/domain/keys';
import { KeyValue } from '@contracts/view/KeyValue';
import { useMemo } from 'react';

export const useAllMapZoneTypeOptions = () => {
  const { mapZoneTypes } = useGeneralStore();

  return useMemo(
    () => [
      ...(mapZoneTypes ?? []),
      { key: 'ALL', value: 'Show All' } as KeyValue<AllMapZoneType, string>,
    ],
    [mapZoneTypes]
  );
};

export const mapZoneTypeColors: {
  type: MapZoneType;
  color: string;
  fillColor?: string;
  holeColor?: string;
  opacity: number;
}[] = [
  {
    type: 'OPERATIONAL',
    color: '#36B37E',
    fillColor: '#ADD3D3',
    holeColor: '#C39999',
    opacity: 0.42,
  },
  { type: 'NO_PARKING', color: '#DC362E', holeColor: '#501411', opacity: 0.2 },
  { type: 'NO_PARKING_NO_RIDING', color: '#757873', holeColor: '#826D69', opacity: 0.22 },
  { type: 'SLOW', color: '#FF9C07', holeColor: '#A26304', opacity: 0.2 },
  { type: 'HIGH_RISK', color: '#7A0916', holeColor: '#594C10', opacity: 0.37 },
];
