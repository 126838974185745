import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateBranchForm } from './CreateBranchForm';
import { useCreateBranch } from '@Modal/hooks/branches/useCreateBranch';

export const CreateBranchModalDialog: React.FC = () => {
  const { showCreateBranchForm, handleCreateCancel } = useCreateBranch();

  return (
    <>
      {showCreateBranchForm && (
        <ClosableModal
          name="CreateBranch"
          title="Create branch"
          open={showCreateBranchForm}
          onClose={handleCreateCancel}
        >
          <CreateBranchForm />
        </ClosableModal>
      )}
    </>
  );
};
