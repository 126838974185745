// System
import React from 'react';
import { Box, TextField, InputAdornment, Button } from '@mui/material';

// Styles
import Styles from './BatteryRange.module.scss';

// Types
type Props = {
  rangeValue: {
    from: number;
    to: number;
  };
  batteryRangeValueApplied?: boolean;
  filteredDataLength?: number;
  handleRangeValueChange: (arg1: number, arg2: string) => void;
  handleFilterByBatteryPercentage: (arg: { from: number; to: number }) => void;
};

const BatteryRange = ({
  rangeValue,
  filteredDataLength,
  handleRangeValueChange,
  batteryRangeValueApplied,
  handleFilterByBatteryPercentage,
}: Props) => {
  return (
    <Box className={Styles.BatteryRange}>
      <Box className={Styles.RangeSlider}>
        <TextField
          label="From"
          value={rangeValue.from}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleRangeValueChange(+event.currentTarget.value, 'from')
          }
        />
        <TextField
          label="To"
          value={rangeValue.to}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleRangeValueChange(+event.currentTarget.value, 'to')
          }
        />
      </Box>
      <Button onClick={() => handleFilterByBatteryPercentage(rangeValue)} variant="contained">
        {batteryRangeValueApplied ? `Apply (Result: ${filteredDataLength})` : 'Apply'}
      </Button>
    </Box>
  );
};

export default BatteryRange;
