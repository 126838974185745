import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useCreateAviatorUser } from '@Modal/hooks/users/useCreateUser';

export const CreateUserCancelModalDialog: React.FC = () => {
  const { handleCancelTakeMeBack, showCancelUserForm } = useCreateAviatorUser();

  return (
    <>
      {showCancelUserForm && (
        <CancelModal open={showCancelUserForm}>
          <ImageCancelDialogLayout
            title="Cancel vehicle changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in user settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
