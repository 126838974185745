import React, { useCallback, useState } from 'react';

type event = React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLLIElement, MouseEvent>;

export const usePopOvers = () => {
  const [open, setOpenPopover] = useState<null | HTMLElement>(null);

  const openPopover = useCallback((event: event) => {
    setOpenPopover(event.currentTarget);
    event.stopPropagation();
  }, []);

  const closePopover = useCallback((event: event) => {
    setOpenPopover(null);
    event.stopPropagation();
  }, []);

  return { open, openPopover, closePopover };
};
