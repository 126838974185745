import { ColorCircleLabel } from '@Components/shared/labels/ColorCircleLabel';
import { ChargingStatus } from '@contracts/domain/keys';

import { useChargingStatusColor, useChargingStatusTitle } from './useChargingStatus';

type Props = { status?: ChargingStatus };

export const ChargingStatusLabel: React.FC<Props> = ({ status }) => {
  const color = useChargingStatusColor()[status ?? 'unknown'];
  const title = useChargingStatusTitle()[status ?? 'unknown'];

  return <ColorCircleLabel color={color} title={title} variant="ghost" />;
};
