// System
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { EnumTableColumnHeader, StringTableColumnHeader } from '@Components/shared/tables/columns';

// Contracts
import { Filterable } from '@contracts/common/traits/Filterable';
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { TransactionItemDto } from '@contracts/api/dto/transaction/TransactionDto';

// Utils
import {
  TransactionListColumns,
  TransactionListFilter,
  transactionListColumns,
} from '@utils/tables/transaction/transactionListFilter';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Redux
import { useDispatch } from 'react-redux';
import { useTransactionTable } from '@Modal/hooks/transaction/useTransactionTable';
import { NumberRangeTableColumnHeader } from '@Components/shared/tables/columns/NumberRangeTableColumnHeader';
import { DateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/DateTableColumnHeader';

type HeaderOptions = {
  filter: Filterable<TransactionListFilter>;
};

type Columns = TableColumnDescriptionArray<
  TransactionItemDto,
  HeaderOptions,
  typeof transactionListColumns
>;

type Column = TableColumnDescription<TransactionItemDto, HeaderOptions, TransactionListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useTransactionTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();
  const dispatch = useDispatch();
  const { handleFilterSelect, setRoundTransactionsTableFilters } = useTransactionTable();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'transaction._id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'id'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?._id && <TruncateText text={row?._id} maxLength={8} textStyles={styles.id} />}
            </Box>
          ),
        },
        {
          name: 'transaction.freebetUsageLogId',
          title: 'Freebet Usage Log Id',
          sx: { minWidth: '230px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'freebetUsageLogId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.freebetUsageLogId && (
                <TruncateText text={row?.freebetUsageLogId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.freebetUsageRecordId',
          title: 'Freebet Usage Record Id',
          sx: { minWidth: '230px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'freebetUsageRecordId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.freebetUsageRecordId && (
                <TruncateText
                  text={row?.freebetUsageRecordId}
                  maxLength={8}
                  textStyles={styles.id}
                />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.playerId',
          title: 'Player Id',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'playerId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.playerId && (
                <TruncateText text={row?.playerId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.roundId',
          title: 'Round Id',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'roundId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.roundId && (
                <TruncateText text={row?.roundId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.betAmount',
          title: 'Bet Amount',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minBetAmount',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minBetAmount',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxBetAmount',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxBetAmount',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'minBetAmount',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'maxBetAmount',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.betAmount}</Box>,
        },
        {
          name: 'transaction.isAutoCashout',
          title: 'Is Autocashout',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName="isAutoCashout"
              options={[
                { key: 'True', value: 'true' },
                { key: 'False', value: 'false' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>{row?.isAutoCashout ? 'True' : 'False'}</Box>
          ),
        },
        {
          name: 'transaction.maxMultiplier',
          title: 'Max Multiplier',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minMaxMultiplier',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minMaxMultiplier',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxMaxMultiplier',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxMaxMultiplier',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'minMaxMultiplier',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'maxMaxMultiplier',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.maxMultiplier}</Box>,
        },
        {
          name: 'transaction.playerRoundBetId',
          title: 'Player Round Bet Id',
          sx: { minWidth: '230px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'playerRoundBetId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.playerRoundBetId && (
                <TruncateText text={row?.playerRoundBetId} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.language',
          title: 'Language',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName="language"
              options={[
                { key: 'Georgian', value: 'ka' },
                { key: 'English', value: 'en' },
                { key: 'Russian', value: 'ru' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.language}</Box>,
        },
        {
          name: 'transaction.currency',
          title: 'Currency',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName="currency"
              options={[
                { key: 'GEL', value: 'GEL' },
                { key: 'USD', value: 'USD' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.currency}</Box>,
        },
        {
          name: 'transaction.win',
          title: 'Win',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minWin',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minWin',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxWin',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxWin',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'minWin',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'maxWin',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.win}</Box>,
        },
        {
          name: 'transaction.cashoutDate',
          title: 'Cashout Date',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minCashoutDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxCashoutDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'minCashoutDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'maxCashoutDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.cashoutDate && (
                <DateLabel date={new Date(parseISO(row?.cashoutDate))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.multiplier',
          title: 'Multiplier',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minMultiplier',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'minMultiplier',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxMultiplier',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'maxMultiplier',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'minMultiplier',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'maxMultiplier',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.multiplier}</Box>,
        },
        {
          name: 'transaction.createdAt',
          title: 'Created At',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'createdStartDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setRoundTransactionsTableFilters({
                        codeName: 'createdEndDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'createdStartDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTransactionsTableFilters({
                    codeName: 'createdEndDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.roundHash',
          title: 'Round Hash',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'roundHash'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.roundHash && (
                <TruncateText text={row?.roundHash} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.serverHash',
          title: 'Server Hash',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'serverHash'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.serverHash && (
                <TruncateText text={row?.serverHash} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
        {
          name: 'transaction.clientHash',
          title: 'Client Hash',
          sx: { minWidth: '180px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'clientHash'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.clientHash && (
                <TruncateText text={row?.clientHash} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
