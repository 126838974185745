import { ButtonProps, alpha, useTheme, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const useButtonHover = ({ variant, color }: ButtonProps): SystemStyleObject<Theme> => {
  const { customShadows, palette } = useTheme();

  if (color === 'inherit' && variant === 'contained') {
    return { boxShadow: customShadows.z8, backgroundColor: palette.grey[400] };
  }

  if (color === 'inherit' && variant === 'outlined') {
    return { borderColor: palette.text.primary, backgroundColor: palette.action.hover };
  }

  if (color === 'inherit' && variant === 'text') {
    return { backgroundColor: palette.action.hover };
  }

  if (color === 'inherit' && variant === 'soft') {
    return { backgroundColor: alpha(palette.grey[500], 0.24) };
  }

  return {};
};
