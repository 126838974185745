import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// GET
export const getSelectedRole = createAsyncThunk(
  'role/getSelectedRole',
  async (roleId: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/roles/${roleId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Delete
export const deleteSelectedRole = createAsyncThunk(
  'role/deleteSelectedRole',
  async (roleId: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .delete(`/roles/${roleId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showSelectedRoleDrawer: false,
  selectedRoleData: null,
  showDeleteRoleModalDialog: false,
};

const rolesDrawerSlice = createSlice({
  name: 'roleDrawer',
  initialState,
  reducers: {
    setShowRoleDrawer: (state, { payload }) => {
      state.showSelectedRoleDrawer = payload;
    },
    setSelectedRole: (state, { payload }) => {
      state.selectedRoleData = payload;
    },
    setShowDeleteRoleModalDialog: (state, { payload }) => {
      state.showDeleteRoleModalDialog = payload;
    },
  },
  extraReducers: {
    [getSelectedRole.fulfilled?.type]: (state, action) => {
      state.selectedRoleData = action.payload;
    },
  },
});

export const { reducer: rolesDrawerReducer } = rolesDrawerSlice;
export const { setShowRoleDrawer, setSelectedRole, setShowDeleteRoleModalDialog } =
  rolesDrawerSlice.actions;
