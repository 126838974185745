import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateCategoryFormContext,
  CreateCategoryFormState,
  createCategoryFormStateDefault,
  createCategorySchemaDefault,
} from '@Forms/vehicles/CreateCategoryFormContext';

export const CreateCategoryFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateCategoryFormState>>>(
    createCategorySchemaDefault
  );

  const methods = useForm({
    defaultValues: createCategoryFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateCategoryFormContext.Provider value={value}>
      {children}
    </CreateCategoryFormContext.Provider>
  );
};
