import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode } from 'react';

type Props = {
  label: string;
  value: ReactNode;
};

export const FieldRowLabel: React.FC<Props> = ({ label, value }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="body2" data-at-readnly-text="Label">
        {label}
      </Typography>
      <Typography variant="body2" color="black" data-at-readnly-text="Value">
        {value}
      </Typography>
    </Stack>
  );
};
