import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object } from 'yup';
import * as yup from 'yup';
import { Roles } from '@Pages/roles.enum';

export type SetupAddUserFormState = {
  firstName: string;
  lastName: string;
  email: string;
  role: Roles | string;
  brands: (string | undefined)[];
  secondLevelBranches: (string | undefined)[];
  thirdLevelBranches: (string | undefined)[];
  password: string;
};

export const setupAddUserFormStateDefault: SetupAddUserFormState = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  brands: [],
  secondLevelBranches: [],
  thirdLevelBranches: [],
  password: '',
};

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;

export const setupAddUserSchemaDefault: ObjectSchema<Partial<SetupAddUserFormState>> =
  object<SetupAddUserFormState>().shape({
    firstName: yup.string().required('First name is required field'),
    lastName: yup.string().required('Last name is required field'),
    email: yup.string().required('Email is required field'),
    role: yup.string().required('Role is required field'),
    brands: yup.array().of(yup.string()).required('Brand is required field'),
    secondLevelBranches: yup.array().of(yup.string()).required('2nd level branch is required'),
    thirdLevelBranches: yup.array().of(yup.string()).required('3rd level branch is required'),
    password: yup
      .string()
      .required('Password is required field')
      .matches(
        passwordRegex,
        'Password must be 8-16 characters long and contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
      ),
    repeatPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
  });

type SetupAddUserFormContextType = {
  schema: ObjectSchema<Partial<SetupAddUserFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupAddUserFormState>>>;
  methods?: UseFormReturn<SetupAddUserFormState>;
};

const createAddUserFormContextDefault = {
  schema: setupAddUserSchemaDefault,
  setSchema: noop,
} satisfies SetupAddUserFormContextType;

export const SetupAddUserFormContext = createContext<SetupAddUserFormContextType>(
  createAddUserFormContextDefault
);

export const useSetupAddUserForm = () => {
  const { methods } = useContext(SetupAddUserFormContext);
  if (!methods) {
    throw new Error('SetupAddUserFormContext is empty');
  }

  return methods;
};

export const useManageSetupAddUserSchema = () => {
  const { setSchema } = useContext(SetupAddUserFormContext);

  return { setSchema };
};
