import { useJsApiLoader } from '@react-google-maps/api';
import { MAP_API } from '@configs/config-global';

const libraries: ['drawing'] = ['drawing'];

export const useGoogleMapsLoader = () => {
  return useJsApiLoader({
    googleMapsApiKey: MAP_API,
    libraries,
  });
};
