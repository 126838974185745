import { TabContext, TabPanel } from '@mui/lab';
import { SystemStyleObject } from '@mui/system';
import { Select, Theme } from '@mui/material';

import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { useSelectorTabs } from '@Components/shared/hooks/useSelectorTabs';

import { ApproachCommands } from './ApproachCommands';
import { DeployCommands } from './DeployCommands';
import { CloseCommands } from './CloseCommands';
import { DashboardCommands } from './DashboardCommands';

const useSx = () => {
  return {
    panel: { padding: 0, height: '100%' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};
export const Commands = () => {
  const styles = useSx();
  const { currentTab, handleSwitchTab } = useSelectorTabs('1');

  return (
    <DrawerTabPanelLayout>
      <DrawerTabSectionLayout>
        <Select
          name="Commands"
          size="small"
          id="outlined-basic"
          variant="outlined"
          value={currentTab}
          onChange={handleSwitchTab}
        >
          <MenuItemEx value="1" name="Dashboard Commands">
            Dashboard Commands
          </MenuItemEx>
          <MenuItemEx value="2" name="Approach Commands">
            Approach Commands
          </MenuItemEx>
          <MenuItemEx value="3" name="Deploy Commands">
            Deploy Commands
          </MenuItemEx>
          <MenuItemEx value="4" name="Close Commands">
            Close Commands
          </MenuItemEx>
        </Select>
        <TabContext value={currentTab}>
          <TabPanel sx={styles.panel} value="1">
            <DashboardCommands />
          </TabPanel>
          <TabPanel sx={styles.panel} value="2">
            <ApproachCommands />
          </TabPanel>
          <TabPanel sx={styles.panel} value="3">
            <DeployCommands />
          </TabPanel>
          <TabPanel sx={styles.panel} value="4">
            <CloseCommands />
          </TabPanel>
        </TabContext>
      </DrawerTabSectionLayout>
    </DrawerTabPanelLayout>
  );
};
