// Components
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { TableColumnHeader } from '@Components/shared/tables/columns';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Utils
import {
  UserListColumns,
  UserListFilter,
  userListColumns,
} from '@utils/tables/users/userListFilter';

// Contracts
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { UserItemDto } from '@contracts/api/dto/users/UserDto';
import { Filterable } from '@contracts/common/traits/Filterable';

type HeaderOptions = {
  filter: Filterable<UserListFilter>;
};

type Columns = TableColumnDescriptionArray<UserItemDto, HeaderOptions, typeof userListColumns>;

type Column = TableColumnDescription<UserItemDto, HeaderOptions, UserListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useUserTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'user.id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>{row?.id && <TruncateText text={row?.id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'user.email',
          title: 'Email',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.email}</Box>,
        },
        {
          name: 'user.password',
          title: 'Password',
          sx: { minWidth: '100px', maxWidth: '240px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.password}</Box>,
        },
        {
          name: 'user.providerId',
          title: 'Provider ID',
          sx: { minWidth: '100px', maxWidth: '200px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>
              {row?.providerId && (
                <TruncateText text={row?.providerId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'user.externalId',
          title: 'External ID',
          sx: { minWidth: '100px', maxWidth: '200px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>
              {row?.externalId && (
                <TruncateText text={row?.externalId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'user.roleIds',
          title: 'Role IDs',
          sx: { minWidth: '250px', maxWidth: '250px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis, display: 'flex', flexDirection: 'column' }}>
              {row?.roleIds?.map((id) => id)}
            </Box>
          ),
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
