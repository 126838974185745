import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// Post
export const signOutCustomerRequest = createAsyncThunk(
  'customer/signOutCustomerRequest',
  async (id: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .post(`/-----API-----/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showSignOutCustomerModalDialog: false,
  forceSignOut: false,
};

const signOutCustomerSlice = createSlice({
  name: 'signOutCustomer',
  initialState,
  reducers: {
    setShowSignOutCustomerModalDialog: (state, { payload }) => {
      state.showSignOutCustomerModalDialog = payload;
    },
    setForceSignOut: (state, { payload }) => {
      state.forceSignOut = payload;
    },
  },
  extraReducers: {
    [signOutCustomerRequest.fulfilled?.type]: (state) => {
      state.showSignOutCustomerModalDialog = false;
    },
  },
});

export const { reducer: signOutCustomerReducer } = signOutCustomerSlice;
export const { setShowSignOutCustomerModalDialog, setForceSignOut } = signOutCustomerSlice.actions;
