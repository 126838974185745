import { Box, Stack, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  title?: JSX.Element | string;
  sort?: JSX.Element;
  search?: JSX.Element;
  sx?: SystemStyleObject<Theme>;
};

export const TableColumnHeaderLayout: React.FC<Props> = ({ title, search, sort, sx }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={sx}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Box data-at-readonly-text="Title">{title}</Box>
        {sort && <Box>{sort}</Box>}
      </Stack>
      {search && <Box>{search}</Box>}
    </Stack>
  );
};
