// System
import { Button, Stack, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';

// Redux
import { useLinkVehicleIot } from '@Modal/hooks/vehicles/vehicles/useLinkVehicleIot';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

export const IoTInformation: React.FC = () => {
  const { handleShowIotDeleteDialog } = useLinkVehicleIot();
  const { selectedVehicleData } = useVehiclesDrawer();
  const { id, imei, iccid, integrationType, firmwareVersion, iotType } = selectedVehicleData.iot;

  return (
    <Stack direction="column" spacing={3} paddingTop={0.5}>
      <AtLocator type="button" name="unlink">
        <Button
          variant="contained"
          onClick={() => handleShowIotDeleteDialog(true)}
          type="submit"
          color="inherit"
          sx={{
            alignSelf: 'flex-start',
          }}
        >
          Unlink
        </Button>
      </AtLocator>
      <Stack gap={2}>
        <AtLocator type="readonly-text" name="IoTInformation">
          <Typography variant="button">IoT information</Typography>
        </AtLocator>
        <AtLocator type="special-field" name="id" box>
          <FieldRowLabel label="Internal ID" value={id ?? 'No results'} />
        </AtLocator>
        <AtLocator type="special-field" name="type" box>
          <FieldRowLabel label="Integration type" value={integrationType ?? 'No results'} />
        </AtLocator>
        <AtLocator type="special-field" name="imei" box>
          <FieldRowLabel label="IMEI" value={imei ?? 'No results'} />
        </AtLocator>
        <AtLocator type="special-field" name="iccid" box>
          <FieldRowLabel label="ICCID" value={iccid ?? 'No results'} />
        </AtLocator>
        <AtLocator type="special-field" name="firmwareVersion" box>
          <FieldRowLabel label="Firmware version" value={firmwareVersion ?? 'No results'} />
        </AtLocator>
        <AtLocator type="special-field" name="iotType" box>
          <FieldRowLabel label="IoT type" value={iotType ?? 'No results'} />
        </AtLocator>
      </Stack>
    </Stack>
  );
};
