import { KeyboardEvent, useCallback } from 'react';

export const usePressEnter = <T extends Element>(handler?: () => void) => {
  return useCallback(
    (event: KeyboardEvent<T>) => {
      if (event.key === 'Enter') {
        handler?.();
      }
    },
    [handler]
  );
};
