// Components
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupRoleDrawerContent } from './SetupRoleDrawerContent';
import { useRolesDrawer } from '@Modal/hooks/roles/useRolesDrawer';
import { useDispatch } from 'react-redux';

// Redux

export const SetupRoleDrawer: React.FC = () => {
  const { showSelectedRoleDrawer, setShowRoleDrawer } = useRolesDrawer();
  const dispatch = useDispatch();

  return (
    <SetupDrawer
      name="SetupRole"
      open={showSelectedRoleDrawer}
      onClose={() => dispatch(setShowRoleDrawer(false))}
    >
      <SetupRoleDrawerContent />
    </SetupDrawer>
  );
};
