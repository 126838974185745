// System
import React from 'react';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Hooks
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Form
import { useCreateVehicleNoteForm } from '@Forms/vehicles/CreateVehicleNoteFormContext';

// Redux
import { setShowCreateOperatorNotes } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Types
import { AppDispatch } from 'src/app/store';
import FormProvider from '@Components/hook-form/FormProvider';
import { RHFTextField } from '@Components/hook-form';

// Styles
import Styles from './SetupOperatorCreateNoteDrawer.module.scss';

export const SetupOperatorCreateNoteDrawer: React.FC = () => {
  const methods = useCreateVehicleNoteForm();

  const { showCreateNotesModal, isCreateNotSubmittable, handleSubmitCreateNoteForm } =
    useOperatorVehicles();

  const dispatch = useDispatch<AppDispatch>();

  return (
    <SetupDrawer
      name="SetupOperatorCreateNoteDrawer"
      open={showCreateNotesModal}
      onClose={() => dispatch(setShowCreateOperatorNotes(false))}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorCreateNoteDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Add Note
          </Typography>
          <Box
            className={Styles.CloseIcon}
            onClick={() => dispatch(setShowCreateOperatorNotes(false))}
          >
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <FormProvider onSubmit={handleSubmitCreateNoteForm} methods={methods}>
          <Box>
            <Box className={Styles.FormContainer}>
              <RHFTextField
                name="note"
                multiline
                minRows={4}
                maxRows={9}
                placeholder="Note description"
                inputProps={{ maxLength: 300 }}
                helperText="Maximum 300 characters"
              />
            </Box>

            <Box className={Styles.Footer}>
              <Button
                disabled={!isCreateNotSubmittable}
                className={isCreateNotSubmittable ? Styles.SubmitCTA_Active : Styles.SubmitCTA}
                variant="contained"
                type="submit"
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </SetupDrawer>
  );
};
