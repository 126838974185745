// System
import { Button, Typography, Stack, Checkbox } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useBlockUnblockCustomer } from '@Modal/hooks/customers/blockCustomer/useBlockUnblockCustomer';

// Styles
import Styles from './blockCustomerModalDialog.module.scss';

export const BlockCustomerModalDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    showBlockCustomerModalDialog,
    handleBlockCustomerModalDialog,
    handleBlockUnblockCustomerRequest,
    submittable,
    forceBlock,
    setForceBlock,
    showWarning,
  } = useBlockUnblockCustomer();
  return (
    <ClosableModal
      name="blockCustomer"
      title="Block Customer"
      open={showBlockCustomerModalDialog}
      onClose={() => {
        handleBlockCustomerModalDialog(false);
        dispatch(setForceBlock(false));
      }}
      className={Styles.blockCustomerModalDialog}
    >
      <>
        <Typography>Do you want to block user?</Typography>
        {showWarning && (
          <Stack className={Styles.WarningContainer}>
            <AssetIcon src="ic_error" className={Styles.WarningIcon} />
            <Typography variant="subtitle2" className={Styles.WarningText}>
              User has existing activity
            </Typography>
          </Stack>
        )}
        <CreateDialogLayout>
          <Stack className={Styles.ForceCheckboxContainer}>
            {showWarning && (
              <>
                <Typography variant="body2">Force (Ignore existing rentals)</Typography>
                <Checkbox
                  checked={forceBlock}
                  onChange={() => dispatch(setForceBlock(!forceBlock))}
                />
              </>
            )}
          </Stack>
          <CreateDialogFooterLayout>
            <AtLocator type="button" name="Cancel">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  handleBlockCustomerModalDialog(false), dispatch(setForceBlock(false));
                }}
              >
                Cancel
              </Button>
            </AtLocator>
            <AtLocator type="button" name="Submit">
              <Button
                type="submit"
                color="error"
                variant="contained"
                disabled={!submittable}
                onClick={handleBlockUnblockCustomerRequest}
              >
                Block
              </Button>
            </AtLocator>
          </CreateDialogFooterLayout>
        </CreateDialogLayout>
      </>
    </ClosableModal>
  );
};
