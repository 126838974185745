// System
import { SystemStyleObject } from '@mui/system';
import { Stack, Theme, Typography } from '@mui/material';

// Components
import { parseISO } from 'date-fns';
import { Box, Tooltip } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';

// Type
import { CustomerBalanceTransactionCardType } from '@Modal/reducers/customers/types';

const useSx = () => {
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      minHeight: '56px',
      padding: 2,
      backgroundColor: 'rgba(244, 246, 248)',
      width: '560px',
      overflow: 'clip',
      ...roundedBorder,
    },
    cardNumber: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '600',
      color: 'rgb(33, 43, 54)',
    },
    expDate: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      color: 'rgb(99, 115, 129)',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const BalanceCard = ({
  id,
  type,
  name,
  time,
  value,
  currency,
  additionalTitle,
  additionalInfo,
}: CustomerBalanceTransactionCardType) => {
  const styles = useSx();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.container}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {type !== 'RENTAL' ? (
          <Tooltip
            title={
              <Stack direction="column" spacing={1} sx={{ padding: '12px' }}>
                <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: '600' }}>
                  {additionalTitle}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    fontWeight: '400',
                    color: 'rgb(145, 158, 171)',
                  }}
                >
                  {additionalInfo}
                </Typography>
              </Stack>
            }
            arrow
            placement="right"
          >
            <Box sx={{ lineHeight: 0, ml: 0 }}>
              <AssetIcon src="info-circle" sx={{ width: '17px', height: '17px', color: 'black' }} />
            </Box>
          </Tooltip>
        ) : (
          ''
        )}
        <Typography variant="body2" sx={{ width: '150px' }}>
          {name
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase())}
        </Typography>
      </Stack>
      <DateLabel date={new Date(parseISO(time))} format="long" />
      <Typography
        variant="body2"
        sx={{ color: Number(value) < 0 ? 'rgb(33, 43, 54)' : 'rgb(27, 128, 106)' }}
      >
        {value} {currency}
      </Typography>
    </Stack>
  );
};
