// System
import { Button, Typography, Stack } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useBlockUnblockCustomer } from '@Modal/hooks/customers/blockCustomer/useBlockUnblockCustomer';

export const UnblockCustomerModalDialog = () => {
  const {
    showUnblockCustomerModalDialog,
    handleUnblockCustomerModalDialog,
    handleBlockUnblockCustomerRequest,
  } = useBlockUnblockCustomer();
  return (
    <ClosableModal
      name="unblockCustomer"
      title="Unblock Customer"
      open={showUnblockCustomerModalDialog}
      onClose={() => {
        handleUnblockCustomerModalDialog(false);
      }}
      sx={{ width: '592px' }}
    >
      <CreateDialogLayout>
        <Typography>Do you want to unblock user?</Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center"></Stack>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                handleUnblockCustomerModalDialog(false);
              }}
            >
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              color="error"
              variant="contained"
              onClick={handleBlockUnblockCustomerRequest}
            >
              Unblock
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
