import { Stack, Theme, Typography, useTheme } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { SystemStyleObject } from '@mui/system';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';
import { ChargingStatusLabel } from '@Features/vehicles/shared/ChargingStatus';
import { ChargingStatus, PowerStatus } from '@contracts/domain/keys';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { usePowerStatusTitle } from '@Features/vehicles/shared/PowerStatus';

import useResponsive from '@Hooks/shared/useResponsive';
import { DetailsItem } from './DetailsItem';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      padding: 2,
      backgroundColor: palette.grey['100'],
      ...roundedBorder,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const AdditionalDetails = () => {
  const styles = useSx();
  const powerStatusTiltes = usePowerStatusTitle();

  const { selectedVehicleData } = useVehiclesDrawer();
  const { chargingStatus, powerStatus, odometer, lastSeenOnline } = selectedVehicleData;

  const isDesktop = useResponsive('up', 'sm');

  return (
    <Stack direction="column" spacing={1}>
      <AtLocator type="readonly-text" name="AdditionalDetails">
        <Typography variant="button">Additional details</Typography>
      </AtLocator>
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        justifyContent="space-between"
        alignItems={isDesktop ? 'center' : 'flex-start'}
        gap={2}
        sx={styles.container}
      >
        <DetailsItem
          icon={<AssetIcon src="power-02" size="xxxl" />}
          title="Power state"
          state={powerStatusTiltes[powerStatus as PowerStatus]}
        />
        <DetailsItem
          icon={<AssetIcon src="battery-charging-02" size="xxxl" />}
          title="Charging state"
          state={<ChargingStatusLabel status={chargingStatus as ChargingStatus} />}
        />
        <DetailsItem
          icon={<AssetIcon src="speedometer-01" size="xxxl" />}
          title="Odometer"
          state={odometer ? `${odometer} km` : 'N/A'}
        />
      </Stack>
      <Stack spacing={3}>
        <AtLocator type="readonly-text" name="lastSeenOnline">
          <FieldRowLabel
            label="Last seen online"
            value={
              lastSeenOnline ? (
                <DateLabel date={new Date(lastSeenOnline)} format="long" timezone={undefined} />
              ) : (
                'N/A'
              )
            }
          />
        </AtLocator>
      </Stack>
    </Stack>
  );
};
