// System
import { Box, IconButton, Stack } from '@mui/material';

// Icons
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Styles
import Styles from './DrawerHeaderLayout.module.scss';

// Types
type Props = {
  children: JSX.Element;
  onClose?: () => void;
  currentSlug?: string;
};

export const DrawerHeaderLayout: React.FC<Props> = ({ children, onClose, currentSlug }) => {
  const activeSlug = currentSlug && window?.location.pathname.includes(currentSlug);
  return (
    <Stack className={Styles.DrawerHeaderLayout}>
      {!activeSlug ? (
        <Box className={Styles.BackArrow} onClick={onClose}>
          <AssetIcon src="chevron-left" size="xxxl" />
        </Box>
      ) : null}

      <Box sx={{ flex: 1 }}>{children}</Box>
      <IconButton
        aria-label="close"
        onClick={onClose}
        data-at-button="Close"
        sx={{
          background: 'transparent',
          visibility: onClose ? 'visible' : 'collapse',
        }}
      >
        <AssetIcon src="x-close" size="xl" />
      </IconButton>
    </Stack>
  );
};
