import { Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SystemStyleObject } from '@mui/system';

import { useTabs } from '@Components/shared/hooks/useTabs';
import { TabEx } from '@Components/shared/selects/TabEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabsDrawerLayout } from '@Components/shared/layouts/TabsDrawerLayout';

import { PrimaryInformation } from './tabs/PrimaryInformation';
import { BusinessArea } from './tabs/BusinessArea';
import { BillingInformation } from './tabs/BillingInformation';
import { useBranches } from '@Modal/hooks/branches/useBranch';

const useSx = () => {
  return {
    tab: { width: '50%' },
    panel: { padding: 0, width: '100%', overflow: 'visible' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupBranchTabs: React.FC = () => {
  const { currentTab, handleSwithTab } = useTabs('1');
  const { selectedBranch } = useBranches();
  const styles = useSx();

  return (
    <TabContext value={currentTab}>
      <TabsDrawerLayout
        header={
          <AtLocator type="tab-list" name="SetupBranch">
            <TabList onChange={handleSwithTab} aria-label="Information tabs">
              <TabEx label="Primary information" value="1" sx={styles.tab} />
              {selectedBranch?.level === 2 && (
                <TabEx label="Billing information" value="2" sx={styles.tab} />
              )}
              {selectedBranch?.level === 3 && (
                <TabEx label="Business area" value="3" sx={styles.tab} />
              )}
            </TabList>
          </AtLocator>
        }
      >
        <TabPanel value="1" sx={styles.panel}>
          <PrimaryInformation />
        </TabPanel>
        <TabPanel value="2" sx={styles.panel}>
          <BillingInformation />
        </TabPanel>
        <TabPanel value="3" sx={styles.panel}>
          <BusinessArea />
        </TabPanel>
      </TabsDrawerLayout>
    </TabContext>
  );
};
