import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { OpenSendCommandDialogName } from '@Features/providers/store/dialogs/useSendCommandDialog';
import { Button } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useSetupVehicleMaxSpeed } from './hooks/useSetupVehicleMaxSpeed';

export const SetupVehicleMaxSpeedDialog = () => {
  const { handleClose, handleConfirm, openDialogName, methods } = useSetupVehicleMaxSpeed();

  return (
    <>
      {openDialogName === OpenSendCommandDialogName.MaxSpeed && (
        <ClosableModal
          open={openDialogName === OpenSendCommandDialogName.MaxSpeed}
          name="setupVehicleRetireDialog"
          title="Set max speed"
          onClose={handleClose}
        >
          <CreateDialogLayout>
            <FormProvider {...methods}>
              <RHFIntegerField
                name="maxSpeed"
                id="setup-vehicle-max-speed"
                label="Enter max speed"
                variant="outlined"
              />
              <CreateDialogFooterLayout>
                <AtLocator type="button" name="Cancel">
                  <Button variant="outlined" color="inherit" onClick={handleClose}>
                    Cancel
                  </Button>
                </AtLocator>
                <AtLocator type="button" name="Submit">
                  <Button type="submit" variant="contained" onClick={handleConfirm}>
                    Confirm
                  </Button>
                </AtLocator>
              </CreateDialogFooterLayout>
            </FormProvider>
          </CreateDialogLayout>
        </ClosableModal>
      )}
    </>
  );
};
