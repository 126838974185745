import { Button, Stack, Typography } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useCreateRoundConfig } from '@Modal/hooks/rounds/useCreateRoundConfig';
import { useDispatch } from 'react-redux';

export const RoundsTitle: React.FC = () => {
  const { setShowCreateRoundConfigForm } = useCreateRoundConfig();
  const dispatch = useDispatch();

  return (
    <AtLocator type="container" name="roundsListTitle">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBottom: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Rounds</Typography>
        </Stack>
        <Button
          size="large"
          variant="contained"
          onClick={() => dispatch(setShowCreateRoundConfigForm(true))}
        >
          Add round config
        </Button>
      </Stack>
    </AtLocator>
  );
};
