// System
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { StringTableColumnHeader, TableColumnHeader } from '@Components/shared/tables/columns';
import { NumberRangeTableColumnHeader } from '@Components/shared/tables/columns/NumberRangeTableColumnHeader';
import { DateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/DateTableColumnHeader';

// Contracts
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { FreebetUsageItemDto } from '@contracts/api/dto/freebetUsage/FreebetUsageDto';
import { Filterable } from '@contracts/common/traits/Filterable';

// Utils
import {
  FreebetUsageListColumns,
  FreebetUsageListFilter,
  freebetUsageListColumns,
} from '@utils/tables/freebetsUsage/freebetsUsageListFilter';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Redux
import { useDispatch } from 'react-redux';
import { useFreebetsUsageTable } from '@Modal/hooks/freebetsUsage/useFreebetsUsage';

type HeaderOptions = {
  filter: Filterable<FreebetUsageListFilter>;
};

type Columns = TableColumnDescriptionArray<
  FreebetUsageItemDto,
  HeaderOptions,
  typeof freebetUsageListColumns
>;

type Column = TableColumnDescription<FreebetUsageItemDto, HeaderOptions, FreebetUsageListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useFreebetUsageTableColumns = () => {
  const styles = useSx();
  const ellipsis = useEllipsis();
  const dispatch = useDispatch();
  const { handleFilterSelect, setFreebetUsageTableFilters } = useFreebetsUsageTable();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'freebetUsage._id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'id'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>{row?._id && <TruncateText text={row?._id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'freebetUsage.campaignId',
          title: 'Campaign ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'campaignId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>
              {row?.campaignId && (
                <TruncateText text={row?.campaignId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebetUsage.usageId',
          title: 'Usage ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'usageId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>
              {row?.usageId && (
                <TruncateText text={row?.usageId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebetUsage.providerId',
          title: 'Provider ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <>
              {row?.providerId && (
                <TruncateText text={row?.providerId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebetUsage.roundId',
          title: 'Round ID',
          sx: { minWidth: '130px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'roundId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>
              {row?.roundId && (
                <TruncateText text={row?.roundId} maxLength={8} textStyles={styles.id} />
              )}
            </>
          ),
        },
        {
          name: 'freebetUsage.gameId',
          title: 'Game ID',
          sx: { minWidth: '100px', maxWidth: '120px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.gameId}</Box>,
        },
        {
          name: 'freebetUsage.win',
          title: 'Win',
          sx: { maxWidth: '100px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setFreebetUsageTableFilters({
                        codeName: 'minWin',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setFreebetUsageTableFilters({
                        codeName: 'minWin',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setFreebetUsageTableFilters({
                        codeName: 'maxWin',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setFreebetUsageTableFilters({
                        codeName: 'maxWin',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setFreebetUsageTableFilters({
                    codeName: 'minWin',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetUsageTableFilters({
                    codeName: 'maxWin',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.win}</Box>,
        },
        {
          name: 'freebetUsage.createdAt',
          title: 'Created At',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setFreebetUsageTableFilters({
                        codeName: 'createdStartDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setFreebetUsageTableFilters({
                        codeName: 'createdEndDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setFreebetUsageTableFilters({
                    codeName: 'createdStartDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setFreebetUsageTableFilters({
                    codeName: 'createdEndDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'freebetUsage.updatedAt',
          title: 'Updated At',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.updatedAt && (
                <DateLabel date={new Date(parseISO(row?.updatedAt))} format="long" />
              )}
            </Box>
          ),
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
