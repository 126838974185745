import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { ConfirmButtonPanel } from '@Components/shared/buttons/ConfirmButtonPanel';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SetupTransactionDrawerHeader } from './SetupTransactionDrawerHeader';
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';
import { TransactionDetails } from './information/TransactionDetails';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';

export const SetupTransactionDrawerContent: React.FC = () => {
  const { handleDrawerCancel, handleTransactionRefundModalDialog } = useTransactionsDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/Transactions" onClose={handleDrawerCancel}>
          <SetupTransactionDrawerHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <>
          <Divider />
          <ConfirmButtonPanel onSaveClick={() => handleTransactionRefundModalDialog(true)} />
        </>
      }
    >
      <Stack>
        <Divider />
        <TransactionDetails />
      </Stack>
    </TabDrawerLayout>
  );
};
