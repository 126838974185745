// System
import { Button, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useCreateAviatorRole } from '@Modal/hooks/aviatorRoles/useCreateAviatorRole';

export const RoleTitle: React.FC = () => {
  const dispatch = useDispatch();
  const { setShowCreateAviatorRoleForm } = useCreateAviatorRole();

  return (
    <AtLocator type="container" name="RoleTitle">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '4px 0' }}
      >
        <AtLocator type="readonly-text" name="Title">
          <Typography variant="h3">Roles</Typography>
        </AtLocator>
        <Button
          variant="contained"
          onClick={() => dispatch(setShowCreateAviatorRoleForm(true))}
          size="large"
          sx={{ paddingY: '2px', height: '45px' }}
        >
          Create Role
        </Button>
      </Stack>
    </AtLocator>
  );
};
