import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';

export const SetupBranchCancelModalDialog: React.FC = () => {
  const { showCancelBranchDrawer, handleCancelTakeMeBack } = useBranchDrawer();

  return (
    <>
      {showCancelBranchDrawer && (
        <CancelModal open={showCancelBranchDrawer}>
          <ImageCancelDialogLayout
            title="Cancel branch changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('confirm')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in branch settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
