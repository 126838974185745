import React from 'react';

import { Stack } from '@mui/material';
import { ButtonGroup } from '@Components/shared/buttons/ButtonGroup/ButtonGroup';
import { TabContext, TabPanel } from '@mui/lab';

import { useTabs } from '@Components/shared/hooks/useTabs';
import { StartRentalRequirements } from './StartRentalRequirements';
import { EndRentalRequirements } from './EndRentalRequirments';

export const RentalRequirementsToggle = () => {
  const { currentTab, handleSwithTab } = useTabs('start');

  const items = [
    {
      value: 'start',
      displayName: 'Start-rental requirements',
    },
    {
      value: 'end',
      displayName: 'End-rental requirements',
    },
  ];

  return (
    <Stack>
      <ButtonGroup items={items} activeItem={currentTab} handleChange={handleSwithTab} />
      <TabContext value={currentTab}>
        <TabPanel value="start" sx={{ padding: '0' }}>
          <StartRentalRequirements />
        </TabPanel>
        <TabPanel value="end" sx={{ padding: '0' }}>
          <EndRentalRequirements />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};
