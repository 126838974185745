import React from 'react';

// System
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Redux
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';
import { setShowReplaceQRModal } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Types
import { AppDispatch } from 'src/app/store';
import FormProvider from '@Components/hook-form/FormProvider';
import { RHFTextField } from '@Components/hook-form';

// Styles
import Styles from './SetupOperatorReplaceQRDrawer.module.scss';

export const SetupOperatorReplaceQRDrawer: React.FC = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const {
    fetchedVehicle,
    showOperatorReplaceQRModal,
    replaceQRMethods,
    handleSubmitReplaceQRForm,
    isReplaceQRSubmittable,
  } = useOperatorVehicles();

  return (
    <SetupDrawer
      name="SetupOperatorLinkIotDrawer"
      open={showOperatorReplaceQRModal}
      onClose={() => dispatch(setShowReplaceQRModal(false))}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorReplaceQRDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Replace QR
          </Typography>
          <Box className={Styles.CloseIcon} onClick={() => dispatch(setShowReplaceQRModal(false))}>
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <FormProvider methods={replaceQRMethods} onSubmit={handleSubmitReplaceQRForm}>
          <Box className={Styles.TextContainer}>
            <RHFTextField
              id="qrCode"
              name="qrCode"
              label="Current QR"
              variant="outlined"
              value={fetchedVehicle?.qrCode}
              disabled
              className={Styles.InputField}
            />
            <RHFTextField
              id="qrCode"
              name="qrCode"
              label="New QR"
              variant="outlined"
              className={Styles.InputField}
            />
            <Button
              className={isReplaceQRSubmittable ? Styles.Btn_Active : Styles.Btn}
              variant="contained"
              type={'submit'}
              disabled={!isReplaceQRSubmittable}
            >
              Confirm
            </Button>
          </Box>
        </FormProvider>
      </Box>
    </SetupDrawer>
  );
};
