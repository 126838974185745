// System
import { Suspense, useMemo } from 'react';
import { Tabs, Tab, Box, CircularProgress, Divider } from '@mui/material';

// Components
import { useTabs } from '@Components/shared/hooks/useTabs';

// Features
import { CampaignsList } from '@Features/campaigns/CampaignsList';
import { CampaignsTitle } from '@Features/campaigns/CampaignsTitle/CampaignsTitle';
import { FreebetsList } from '@Features/freebets/FreebetsList';
import { FreebetsTitle } from '@Features/freebets/FreebetsTitle/FreebetsTitle';
import { FreebetsUsageList } from '@Features/freebetsUsage/FreebetsUsageList';
import { FreebetsUsageTitle } from '@Features/freebetsUsage/FreebetsUsageTitle/FreebetsUsageTitle';

const panels = [CampaignsList, FreebetsList, FreebetsUsageList];
const titles = [CampaignsTitle, FreebetsTitle, FreebetsUsageTitle];

export const CampaignsPage = () => {
  const { currentTab, handleSwithTab } = useTabs(0);
  const Component = useMemo(() => panels[currentTab], [currentTab]);
  const Title = useMemo(() => titles[currentTab], [currentTab]);
  return (
    <>
      <Title />
      <Tabs value={currentTab} onChange={handleSwithTab}>
        <Tab label="Campaigns" />
        <Tab label="Freebets" />
        <Tab label="Usage" />
      </Tabs>
      <Divider />
      <Box sx={{ paddingTop: 3, paddingBottom: 2 }}>
        <Suspense fallback={<CircularProgress />}>
          <Component />
        </Suspense>
      </Box>
    </>
  );
};
