import useResponsive from '@Hooks/shared/useResponsive';
import { Stack, Typography, Button } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useCreateProvider } from '@Modal/hooks/providers/useCreateProvider';
import { useDispatch } from 'react-redux';

export const ProvidersTitle: React.FC = () => {
  const isDesktop = useResponsive('up', 'sm');
  const dispatch = useDispatch();
  const { setShowCreateProviderForm } = useCreateProvider();
  const handleOpenCreateProviderModal = () => dispatch(setShowCreateProviderForm(true));

  return (
    <AtLocator type="container" name="customerListTitle">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBottom: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Providers</Typography>
        </Stack>
        <AtLocator type="button" name="CreateVehicle">
          <Button
            sx={{ width: !isDesktop ? 240 : null }}
            size="large"
            variant="contained"
            onClick={handleOpenCreateProviderModal}
          >
            Add provider
          </Button>
        </AtLocator>
      </Stack>
    </AtLocator>
  );
};
