// System
import { Button } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Form
import { useTransactionRefundForm } from './TransactionRefundFormContext';

// Redux
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';

export const TransactionRefundModalDialog = () => {
  const methods = useTransactionRefundForm();

  const {
    submittable,
    showTransactionRefundModalDialog,
    handleTransactionRefundModalDialog,
    handleConfirmTransactionRefundModalDialog,
  } = useTransactionsDrawer();

  return (
    <>
      <ClosableModal
        name="transactionRefund"
        title="Refund"
        open={showTransactionRefundModalDialog}
        onClose={() => handleTransactionRefundModalDialog(false)}
        sx={{ width: '592px' }}
      >
        <FormProvider methods={methods}>
          <CreateDialogLayout>
            <RHFTextField
              name="refund"
              placeholder="Refund Amount"
              helperText="Specify the exact value you intend to refund for this transaction"
              type="number"
            />
            <CreateDialogFooterLayout>
              <AtLocator type="button" name="Cancel">
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => handleTransactionRefundModalDialog(false)}
                >
                  Cancel
                </Button>
              </AtLocator>
              <AtLocator type="button" name="Submit">
                <Button
                  variant="contained"
                  onClick={() => handleConfirmTransactionRefundModalDialog(true)}
                  disabled={!submittable}
                >
                  Confirm
                </Button>
              </AtLocator>
            </CreateDialogFooterLayout>
          </CreateDialogLayout>
        </FormProvider>
      </ClosableModal>
    </>
  );
};
