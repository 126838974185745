// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';

// Redis
import { useRedis } from '../redis/useRedis';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setShowCommandsModal,
  setShowApproachModal,
  setShowVehicleModal,
  getOperatorVehiclesOnMap,
  getSelectedOperatorVehicle,
  putOperatorVehicleState,
  setShowServiceStateModal,
  putOperatorVehicle,
  setShowReplaceQRModal,
  postOperatorIoTCommand,
  getOperatorVehicleNotes,
  postOperatorVehicleNote,
  deleteOperatorVehicleNote,
  setShowOperatorProfileModal,
} from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';
import { getLiveMapVehicles } from '@Modal/reducers/vehicles/vehicles/vehicleMapSlice';
import { updateVehicleServiceState } from '@Modal/reducers/vehicles/vehicles/vehiclesDrawerSlice';
import { getSelectedVehicleCategoryDashboardCommands } from '@Modal/reducers/vehicles/categories/vehicleCategoryDrawerSlice';

// Mappers
import {
  useManageReplaceQRSchema,
  useSetupReplaceQRForm,
} from '@Forms/operator/SetupReplaceQRFormContext';
import {
  useManageLinkIoTSchema,
  useSetupLinkIoTForm,
} from '@Forms/operator/SetupLinkIoTFormContext';

import { useVehicleCommands } from '@Hooks/shared/useVehicleCommands';
import { useVehiclesDrawer } from '../vehicles/vehicles/useVehicleDrawer';
import { useCreateVehicleNoteForm } from '@Forms/vehicles/CreateVehicleNoteFormContext';
import { useSetupLinkIoTUseCaseSchema } from '@Hooks/operator/useSetupLinkIoTUseCaseSchema';
import { useSetupLinkIoTUseCaseMapper } from '@Hooks/operator/useSetupLinkIoTUseCaseMapper';
import { useSetupReplaceQRUseCaseSchema } from '@Hooks/operator/useSetupReplaceQRUseCaseSchema';
import { useSetupReplaceQRUseCaseMapper } from '@Hooks/operator/useSetupReplaceQRUseCaseMapper';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { VehicleFormStateType, BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';
import { SendOperatorVehicleCommandDTO } from '@Modal/reducers/operator/operatorVehicles/dto';
import useResponsive from '@Hooks/shared/useResponsive';
import Cookies from 'js-cookie';

export const useOperatorVehicles = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const socketId = localStorage.getItem('socketId');
  const isMobile = useResponsive('down', 'sm');

  const {
    vehicleNotes,
    vehiclesOnMap,
    fetchedVehicle,
    selectedNoteId,
    showVehicleModal,
    showCommandsModal,
    approachedVehicle,
    showApproachModal,
    showOperatorIoTModal,
    showCreateNotesModal,
    showServiceStateModal,
    showOperatorProfileModal,
    showOperatorVehicleNotes,
    showOperatorReplaceQRModal,
    showOperatorDeleteNoteModal,
  } = useSelector((state: StoreInterface) => state?.operatorVehicles);

  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { handleSetRedisVehicle } = useRedis();

  // Forms
  const linkIoTSchema = useSetupLinkIoTUseCaseSchema();
  const { setSchema: setLinkIoTSchema } = useManageLinkIoTSchema();
  const linkIoTMapper = useSetupLinkIoTUseCaseMapper(fetchedVehicle?.iotImei);

  const {
    control: linkIotFormControl,
    handleSubmit: handleLinkIoTSubmit,
    reset: resetLinkIoTForm,
  } = useSetupLinkIoTForm();

  const linkIotMethods = useSetupLinkIoTForm();

  const { isDirty: isLinkIoTDirty, errors: linkIoTErrors } = useFormState({
    control: linkIotFormControl,
  });

  const replaceQRSchema = useSetupReplaceQRUseCaseSchema();
  const { setSchema: setReplaceQRSchema } = useManageReplaceQRSchema();
  const replaceQRMapper = useSetupReplaceQRUseCaseMapper(fetchedVehicle?.iotImei);

  const { handleSendCommands } = useVehicleCommands();
  const { handleServiceStateUpdate } = useVehiclesDrawer();

  const {
    control: replaceQRFormControl,
    handleSubmit: handleReplaceQRSubmit,
    reset: resetQRCodeForm,
  } = useSetupReplaceQRForm();

  const replaceQRMethods = useSetupReplaceQRForm();

  const { isDirty: isReplaceQRDirty, errors: replaceQRErrors } = useFormState({
    control: replaceQRFormControl,
  });

  const {
    control: vehicleNoteFormControl,
    handleSubmit: vehicleNoteFormSubmit,
    reset: vehicleNoteFormReset,
  } = useCreateVehicleNoteForm();

  const { isDirty: isVehicleNoteFormDirty, errors: vehicleNoteFormErrors } = useFormState({
    control: vehicleNoteFormControl,
  });

  const setQRCodeMapperHandler = useCallback(async () => {
    setReplaceQRSchema(replaceQRSchema);
    const replaceQRFormState = await replaceQRMapper.toFormState();
    resetQRCodeForm(replaceQRFormState);
  }, [fetchedVehicle]);

  const setLinkIoTMapperHandler = useCallback(async () => {
    setLinkIoTSchema(linkIoTSchema);
    const linkIoTFormState = await linkIoTMapper.toFormState();
    resetLinkIoTForm(linkIoTFormState);
  }, [fetchedVehicle]);

  useEffect(() => {
    if (fetchedVehicle) {
      setLinkIoTMapperHandler();
    }
  }, [fetchedVehicle, setLinkIoTMapperHandler]);

  useEffect(() => {
    if (fetchedVehicle) {
      setQRCodeMapperHandler();
    }
  }, [fetchedVehicle, setQRCodeMapperHandler]);

  const getBackOfficeVehicles = async () => {
    return dispatch(getLiveMapVehicles())
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Fetch All Vehicles For Operators Only
  const handleGetAllOperatorVehicles = (bounds: string) => {
    dispatch(getOperatorVehiclesOnMap({ bounds }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Update operator vehicle service state
  const handleUpdateOperatorVehicleState = (state: string) => {
    dispatch(putOperatorVehicleState({ id: fetchedVehicle?.id, serviceState: state }))
      .unwrap()
      .then(async () => {
        await handleGetApproachedOperatorVehicle(fetchedVehicle?.id);
        dispatch(setShowServiceStateModal(false));
        enqueueSnackbar(`Vehicle state updated to ${state}`, {
          autoHideDuration: 3500,
          variant: 'success',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 5000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Fetch Approached Vehicle
  const handleGetApproachedOperatorVehicle = async (id: string) => {
    // await handleUpdateOperatorVehicleState('APPROACHED');
    dispatch(getSelectedOperatorVehicle(id))
      .unwrap()
      .then((result: BackofficeLiveVehicle) => {
        return dispatch(getSelectedVehicleCategoryDashboardCommands(result?.categoryId));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Fetch Operator Vehicle Notes
  const handleGetOperatorVehicleNotes = async () => {
    return dispatch(getOperatorVehicleNotes(fetchedVehicle?.id))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Update operator vehicle
  const handleUpdateOperatorVehicleQR = async (id: string, vehicleData: VehicleFormStateType) => {
    return dispatch(
      putOperatorVehicle({ vehicleID: fetchedVehicle?.id, vehicleFormState: vehicleData })
    )
      .unwrap()
      .then(async () => {
        await handleGetApproachedOperatorVehicle(fetchedVehicle?.id);
        dispatch(setShowReplaceQRModal(false));
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Send IoT Command
  const sendIoTCommandHandler = async (IoTData: SendOperatorVehicleCommandDTO) => {
    return dispatch(postOperatorIoTCommand(IoTData))
      .unwrap()
      .then(async () => {
        enqueueSnackbar('Command sent', {
          autoHideDuration: 3000,
          variant: 'success',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
        dispatch(setShowCommandsModal(false));
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Submit Replace QR Form
  const handleSubmitReplaceQRForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleReplaceQRSubmit(async (formData) => {
        dispatch(
          putOperatorVehicle({
            vehicleID: fetchedVehicle?.id,
            vehicleFormState: {
              licensePlate: fetchedVehicle?.licensePlate,
              qrCode: formData?.qrCode || fetchedVehicle?.qrCode,
              vin: fetchedVehicle.vin,
              categoryId: fetchedVehicle?.categoryId,
              iotImei: fetchedVehicle?.iotImei,
              name: fetchedVehicle?.name,
            },
          })
        )
          .unwrap()
          .then(() => {
            resetQRCodeForm();
            handleGetApproachedOperatorVehicle(fetchedVehicle?.id);
            enqueueSnackbar('QR code has been replaced', {
              autoHideDuration: 3000,
              variant: 'success',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
          })
          .catch((error: string) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
          });
      })(e);
    },
    [fetchedVehicle]
  );

  // Submit Link IoT Form
  const handleSubmitLinkIoTForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleLinkIoTSubmit(async (formData) => {
        dispatch(
          putOperatorVehicle({
            vehicleID: fetchedVehicle?.id,
            vehicleFormState: {
              licensePlate: fetchedVehicle?.licensePlate,
              qrCode: fetchedVehicle?.qrCode,
              vin: fetchedVehicle?.vin,
              categoryId: fetchedVehicle?.categoryId,
              iotImei: formData.iotImei,
              name: fetchedVehicle?.name,
            },
          })
        )
          .unwrap()
          .then(() => {
            resetLinkIoTForm();
            handleGetApproachedOperatorVehicle(fetchedVehicle?.id);
          })
          .catch((error: string) => {
            resetLinkIoTForm();
            enqueueSnackbar('IoT link has been replaced', {
              autoHideDuration: 3000,
              variant: 'success',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
          });
      })(e);
    },
    [fetchedVehicle]
  );

  // Submit Create Note Form
  const handleSubmitCreateNoteForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return vehicleNoteFormSubmit(async (formData) => {
        dispatch(
          postOperatorVehicleNote({
            id: fetchedVehicle?.id,
            newNote: {
              note: formData?.note,
              authorId: currentUser?.id,
            },
          })
        )
          .unwrap()
          .then(() => {
            vehicleNoteFormReset();
            handleGetOperatorVehicleNotes();
          })
          .catch((error: string) => {
            vehicleNoteFormReset();
            enqueueSnackbar('IoT link has been replaced', {
              autoHideDuration: 3000,
              variant: 'success',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
          });
      })(e);
    },
    [fetchedVehicle, vehicleNotes]
  );

  // Delete Note Handler
  const deleteOperatorNoteHandle = () => {
    if (selectedNoteId) {
      return dispatch(deleteOperatorVehicleNote(selectedNoteId))
        .unwrap()
        .then(() => {
          handleGetOperatorVehicleNotes();
          enqueueSnackbar('Note deleted', {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: isMobile ? 'center' : 'right',
            },
          });
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: isMobile ? 'center' : 'right',
            },
          });
        });
    }
  };

  const handleConsiderServiceState = () => {
    switch (approachedVehicle?.serviceState) {
      case 'FUNCTIONAL':
        return 'OTHER';
      case 'INSPECT':
        return 'OTHER';
      default:
        return false;
    }
  };

  const handleApproachServiceStateUpdate = () => {
    if (handleConsiderServiceState()) {
      return dispatch(
        updateVehicleServiceState({
          vehicleID: approachedVehicle?.id,
          vehicleServiceState: 'OTHER',
        })
      )
        .unwrap()
        .then(() => {
          getBackOfficeVehicles();
          enqueueSnackbar('Service state updated', {
            autoHideDuration: 3000,
            variant: 'success',
            anchorOrigin: {
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: isMobile ? 'center' : 'right',
            },
          });
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: isMobile ? 'top' : 'bottom',
              horizontal: isMobile ? 'center' : 'right',
            },
          });
        });
    }
  };

  // Handle set approached vehicle
  const handleSetApproachedVehicle = (status: boolean, vehicle: BackofficeLiveVehicle | null) => {
    dispatch(setShowApproachModal({ status: status, vehicleInfo: vehicle }));
    if (vehicle) {
      handleGetApproachedOperatorVehicle(vehicle?.id);
      if (vehicle) {
        handleSetRedisVehicle(vehicle);
      }
    }
  };

  // Handle set show service state modal
  const handleSetShowServiceStateDrawer = (status: boolean) => {
    return dispatch(setShowServiceStateModal(status));
  };

  // Handle set show vehicle drawer
  const handleSetShowVehicleDrawer = (status: boolean) => {
    dispatch(setShowApproachModal(false));
    dispatch(setShowVehicleModal(status));
  };

  const handleDrawerCommand = (command: string) => {
    handleSendCommands(socketId, command, [fetchedVehicle?.iotImei]);
  };

  const handleOperatorVehicleDrawerDeploy = () => {
    handleSetShowVehicleDrawer(false);
    handleDrawerCommand('stop');
    handleServiceStateUpdate({
      vehicleServiceState: 'FUNCTIONAL',
      vehicleID: fetchedVehicle?.id,
    });
  };

  return {
    // Store
    vehicleNotes,
    vehiclesOnMap,
    fetchedVehicle,
    showVehicleModal,
    approachedVehicle,
    showApproachModal,
    showCommandsModal,
    showOperatorIoTModal,
    showServiceStateModal,
    sendIoTCommandHandler,
    showOperatorProfileModal,
    showOperatorReplaceQRModal,
    linkIoTErrors,
    linkIotMethods,
    replaceQRErrors,
    replaceQRMethods,
    showCreateNotesModal,
    showOperatorVehicleNotes,
    showOperatorDeleteNoteModal,
    isLinkIoTSubmittable: isLinkIoTDirty && !Object.values(linkIoTErrors)?.length,
    isReplaceQRSubmittable: isReplaceQRDirty && !Object.values(replaceQRErrors)?.length,
    isCreateNotSubmittable: isVehicleNoteFormDirty && !Object.values(vehicleNoteFormErrors)?.length,
    // Actions
    handleDrawerCommand,
    setShowVehicleModal,
    setShowCommandsModal,
    setShowOperatorProfileModal,
    handleSubmitLinkIoTForm,
    deleteOperatorNoteHandle,
    handleSubmitReplaceQRForm,
    handleSetApproachedVehicle,
    handleSetShowVehicleDrawer,
    handleSubmitCreateNoteForm,
    handleGetAllOperatorVehicles,
    handleGetOperatorVehicleNotes,
    handleUpdateOperatorVehicleQR,
    handleSetShowServiceStateDrawer,
    handleUpdateOperatorVehicleState,
    handleApproachServiceStateUpdate,
    handleOperatorVehicleDrawerDeploy,
    handleGetApproachedOperatorVehicle,
  };
};
