import React from 'react';
import { Grid } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RHFSwitch } from '@Components/hook-form';

export const ApproachCommands = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={0} columns={8} justifyContent="flex-start">
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Start">
          <RHFSwitch label="Start" name="commands.approach.start" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Alarm On">
          <RHFSwitch label="Alarm On" name="commands.approach.alarmOn" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Refresh">
          <RHFSwitch label="Refresh" name="commands.approach.refresh" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Set Out of Order">
          <RHFSwitch label="Set Out of Order" name="commands.approach.setOutOfOrder" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Unlock Battery">
          <RHFSwitch label="Unlock Battery" name="commands.approach.unlockBattery" />
        </AtLocator>
      </Grid>
    </Grid>
  );
};
