import { Stack } from '@mui/material';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';
import { useAvTransactionDrawer } from '@Modal/hooks/transaction/useAvTransactionDrawer';

export const AvTransactionDetails = () => {
  const { selectedAvTransactionData } = useAvTransactionDrawer();
  const { _id, playerId, roundId, betAmount, isAutoCashout, maxMultiplier, playerRoundBetId } =
    selectedAvTransactionData;

  return (
    <Stack direction="column" spacing={3} padding="24px">
      <FieldRowLabel label="ID" value={_id ? _id : 'No Result'} />
      <FieldRowLabel label="Player Id" value={playerId ? playerId : 'No Result'} />
      <FieldRowLabel label="Round Id" value={roundId ? roundId : 'No Result'} />
      <FieldRowLabel label="Bet Amount" value={betAmount ? betAmount : 'No Result'} />
      <FieldRowLabel label="Is Auto Cashout" value={isAutoCashout ? 'True' : 'False'} />
      <FieldRowLabel label="Max Multiplier" value={maxMultiplier ? maxMultiplier : 'No Result'} />
      <FieldRowLabel
        label="Player Round Bet Id"
        value={
          playerRoundBetId ? <TruncateText text={playerRoundBetId} maxLength={25} /> : 'No Result'
        }
      />
    </Stack>
  );
};
