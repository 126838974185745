import { BaseSyntheticEvent, useMemo } from 'react';
import { useSnackbar } from 'notistack';
// Types
import { StoreInterface } from 'src/app/types';
// Redux
import { useBranches } from './useBranch';
import { useSelector, useDispatch } from 'react-redux';
import { createNewBranch } from '@Modal/reducers/branches/branchSlice';
import {
  setShowCreateBranchForm,
  setShowCancelBranchForm,
} from '@Modal/reducers/branches/createBranchSlice';
// Form
import { useFormState } from 'react-hook-form';
import { useCreateBranchForm } from '@Forms/branches/CreateBranchFormContext';
import { AppDispatch } from 'src/app/store';
import { useBranchesTable } from './useBranchesTable';

export const useCreateBranch = () => {
  // Redux
  const { showCreateBranchForm, showCancelBranchForm } = useSelector(
    (state: StoreInterface) => state?.createBranchReducer
  );

  const { brands, branches } = useBranches();

  const allBranches = [...brands, ...branches];

  const dispatch = useDispatch<AppDispatch>();

  // Other hooks
  const { enqueueSnackbar } = useSnackbar();
  const { fetchBranchesTableContent } = useBranchesTable();

  // Form Controls
  const { control, handleSubmit, reset } = useCreateBranchForm();
  const { isDirty, errors } = useFormState({ control });
  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelBranchForm(true));
    } else {
      return dispatch(setShowCreateBranchForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      reset();
      dispatch(setShowCancelBranchForm(false));
      dispatch(setShowCreateBranchForm(true));
    } else {
      reset();
      dispatch(setShowCancelBranchForm(false));
      dispatch(setShowCreateBranchForm(false));
    }
  };

  const handleSubmitCreateBranch = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        const newBranchParent = allBranches.find((branch) => branch.id === formData?.parentId);
        if (newBranchParent) {
          dispatch(
            createNewBranch({
              name: formData?.name,
              level: newBranchParent?.level + 1,
              parentId: formData?.parentId,
            })
          )
            .unwrap()
            .then(() => {
              fetchBranchesTableContent();
              handleCancelTakeMeBack('confirm');
              enqueueSnackbar('Branch created succesfully', {
                variant: 'success',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            })
            .catch((error: string) => {
              reset();
              enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            });
        }
      })(e);
    },
    []
  );

  return {
    showCreateBranchForm,
    showCancelBranchForm,
    setShowCreateBranchForm,
    setShowCancelBranchForm,
    handleCreateCancel,
    handleCancelTakeMeBack,
    handleSubmitCreateBranch,
    submittable: isDirty && Object.values(errors).length == 0,
  };
};
