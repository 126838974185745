import React from 'react';

import { Stack, Typography } from '@mui/material';

import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RHFSwitch } from '@Components/hook-form';

// Redux
import { useDispatch } from 'react-redux';
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

export const AutoEndRental = () => {
  // @TODO: Re-do this part using FSD approach
  // const { autoEndRental } = useSetupCategoryUseCaseInfo();
  const { showAutoEndRentalInput, setShowAutoEndRentalInput } = useVehicleCategoryDrawer();
  const dispatch = useDispatch();
  return (
    <Stack spacing={1}>
      <AtLocator type="readonly-text" name="AutoEndRental">
        <Typography variant="subtitle1">Auto-end rental</Typography>
      </AtLocator>
      <Stack direction="row">
        <AtLocator type="readonly-text" name="RentalAutoEndSettings">
          <Typography variant="body2" color="text.secondary">
            Enable this option to specify the number of minutes of inactivity required to end the
            rental.
          </Typography>
        </AtLocator>
        <AtLocator type="special-field" name="RenentalAutoEnd">
          <RHFSwitch
            name="noMovement.autoEndRental"
            label=""
            onClick={() => dispatch(setShowAutoEndRentalInput(!showAutoEndRentalInput))}
          />
        </AtLocator>
      </Stack>
      {showAutoEndRentalInput && (
        <AtLocator type="text-field" name="noMovement.endRentalAfterMinutes">
          <RHFIntegerField
            name="noMovement.endRentalAfterMinutes"
            label="End rental after minutes"
          />
        </AtLocator>
      )}
    </Stack>
  );
};
