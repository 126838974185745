import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { SimpleCreateBranchForm } from './SimpleCreateBranchForm';

export const SimpleCreateBranchModalDialog: React.FC = () => {
  // const { active } = useCreateBranchUseCaseStatus(CreateBranchDialogType.Simple);
  // const { handleCancel } = useCreateBranchUseCase();

  return (
    <>
      {/* {active && (
        <ClosableModal
          name="SimpleCreateBranch"
          title="Create branch"
          open={active}
          onClose={handleCancel}
        >
          <SimpleCreateBranchForm />
        </ClosableModal>
      )} */}
    </>
  );
};
