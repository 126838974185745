/* eslint-disable react/no-unescaped-entities */
import React, { ReactNode } from 'react';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { RHFSlider } from '@Components/hook-form';
import { Stack, Typography, IconButton, Tooltip, InputAdornment } from '@mui/material';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

type LevelProps = {
  name: string;
  label: string;
  children: ReactNode;
};
const Level: React.FC<LevelProps> = ({ label, name, children }) => {
  return (
    <AtLocator type="special-field" name={name}>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <AtLocator type="readonly-text" name="label">
              <Typography variant="subtitle2">{label}</Typography>
            </AtLocator>
            <AtLocator type="tooltip" name="tooltip">
              <Tooltip title={children} arrow>
                <IconButton>
                  <AssetIcon src="info-circle" size="xl" />
                </IconButton>
              </Tooltip>
            </AtLocator>
          </Stack>
          <AtLocator type="text-field" name="text-field">
            <RHFIntegerField
              name={name}
              skipErrorText
              variant="outlined"
              label=""
              size="small"
              sx={{ width: '84px' }}
              placeholder="0"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </AtLocator>
        </Stack>
        <AtLocator type="slider-field" name="slider">
          <RHFSlider name={name} min={0} max={100} />
        </AtLocator>
      </Stack>
    </AtLocator>
  );
};

export const IoTBatteryLevel: React.FC = () => {
  const roundedBorder = useRoundedBorder();
  const { showSpeedSettingsFilters } = useVehicleCategoryDrawer();

  if (!showSpeedSettingsFilters) {
    return null;
  }

  return (
    <Stack direction="column" spacing={1.5}>
      <Stack direction="column" spacing={1.5} sx={{ ...roundedBorder, paddingX: 2, paddingY: 1.5 }}>
        <Level key="medium" name="iotBatteryMedium" label="Medium">
          Upper threshold for Medium IoT battery level
        </Level>
        <Level key="low" name="iotBatteryLow" label="Low">
          Upper threshold for Low IoT battery level
        </Level>
        <Level key="critical" name="iotBatteryCritical" label="Critical">
          Upper threshold for Critical IoT battery level
        </Level>
      </Stack>
    </Stack>
  );
};
