import { createSlice } from '@reduxjs/toolkit';
import { RedisReducerInterface } from './types';

// Type
const initialState: RedisReducerInterface = {
  selectedRedisVehicle: undefined,
};

const redisSlice = createSlice({
  name: 'redis',
  initialState,
  reducers: {
    setRedisVehicle: (state, { payload }) => {
      state.selectedRedisVehicle = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: redisReducer } = redisSlice;
export const { setRedisVehicle } = redisSlice.actions;
