import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number } from 'yup';

export type CreateIotModuleFormState = {
  iotType: string | null;
  host: string;
  port: number | null;
  username?: string;
  password?: string;
  imei: string;
};

export const createIotModuleFormStateDefault: CreateIotModuleFormState = {
  iotType: '',
  host: '',
  port: 0,
  username: '',
  password: '',
  imei: '',
};

export const createIotModuleSchemaDefault: ObjectSchema<Partial<CreateIotModuleFormState>> = object<
  Partial<CreateIotModuleFormState>
>().shape({
  iotType: string().required(),
  host: string().optional(),
  port: number().optional(),
  username: string(),
  password: string(),
  imei: string().required(),
});

type CreateIotModuleFormContextType = {
  schema: ObjectSchema<Partial<CreateIotModuleFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateIotModuleFormState>>>;
  methods?: UseFormReturn<CreateIotModuleFormState>;
};

const createIotModuleFormContextDefault = {
  schema: createIotModuleSchemaDefault,
  setSchema: noop,
} satisfies CreateIotModuleFormContextType;

export const CreateIotModuleFormContext = createContext<CreateIotModuleFormContextType>(
  createIotModuleFormContextDefault
);

export const useCreateIotModuleForm = () => {
  const { methods } = useContext(CreateIotModuleFormContext);
  if (!methods) {
    throw new Error('CreateIotModuleFormContext is empty');
  }

  return methods;
};

export const useManageCreateIotModuleSchema = () => {
  const { setSchema } = useContext(CreateIotModuleFormContext);

  return { setSchema };
};
