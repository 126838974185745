import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelDialogMessageLayout,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';

export const SetupCustomerCancelModalDialog: React.FC = () => {
  const { showCancelCustomerDrawer, handleCancelTakeMeBack } = useCustomersDrawer();

  return (
    <>
      {showCancelCustomerDrawer && (
        <CancelModal open={showCancelCustomerDrawer}>
          <ImageCancelDialogLayout
            title="You are about to cancel changes"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('cancel')}
              />
            }
          >
            <CancelDialogMessageLayout>
              The changes you made will not be saved
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
