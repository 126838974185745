import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const analyticListColumns = [
  'analytic.date',
  'analytic.providerId',
  'analytic.totalBetAmount',
  'analytic.totalWonAmount',
  'analytic.numberOfUsers',
  'analytic.numberOfSpins',
  'analytic.numberOfRounds',
] as const;

export type AnalyticListColumns = (typeof analyticListColumns)[number];

export type AnalyticListFilter = {
  'analytic.date': string | undefined;
  'analytic.providerId': string | undefined;
  'analytic.totalBetAmount': number | undefined;
  'analytic.totalWonAmount': number | undefined;
  'analytic.numberOfUsers': number | undefined;
  'analytic.numberOfSpins': number | undefined;
  'analytic.numberOfRounds': number | undefined;
};

export const defaultAnalyticListFilter: AnalyticListFilter = {
  'analytic.date': undefined,
  'analytic.providerId': undefined,
  'analytic.totalBetAmount': undefined,
  'analytic.totalWonAmount': undefined,
  'analytic.numberOfUsers': undefined,
  'analytic.numberOfSpins': undefined,
  'analytic.numberOfRounds': undefined,
};

export const analyticListColumnVisibilitySettings = {
  'analytic.date': ColumnVisibilityType.Visible,
  'analytic.providerId': ColumnVisibilityType.CanBeHidden,
  'analytic.totalBetAmount': ColumnVisibilityType.CanBeHidden,
  'analytic.totalWonAmount': ColumnVisibilityType.CanBeHidden,
  'analytic.numberOfUsers': ColumnVisibilityType.CanBeHidden,
  'analytic.numberOfSpins': ColumnVisibilityType.CanBeHidden,
  'analytic.numberOfRounds': ColumnVisibilityType.CanBeHidden,
} satisfies ColumnVisibilitySettings<AnalyticListColumns>;

export const defaultAnalyticListColumnVisibility = defaultColumnVisibilityParams(
  analyticListColumnVisibilitySettings
);

export const analyticListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  analyticListColumns,
  analyticListColumnVisibilitySettings
);
