import { ComponentType, MouseEventHandler } from 'react';
import {
  ActionWithPopover,
  useActionWithPopover,
} from '@Components/shared/popovers/ActionWithPopover';
import { FilterColumnDialogContentProps } from '@Components/props/table/FilterColumnDialogContentProps';
import { FilterItemType } from '@Modal/reducers/types';

type FilterProps<T, O extends {} = {}> = {
  dialogComponent: ComponentType<FilterColumnDialogContentProps<T, O>>;
  filterOptions: O;
  title?: string;
  codeName?: string;
  handleSetFilters: (arg: FilterItemType) => void;
};

type TableButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
};

type Props<T, O extends {} = {}> = FilterProps<T, O> & {
  buttonComponent: ComponentType<TableButtonProps>;
  title?: string;
  codeName?: string;
  handleSetFilters: (arg: FilterItemType) => void;
};

const Filter = <T extends unknown, O extends {} = {}>({
  dialogComponent: DialogComponent,
  filterOptions,
  title,
  codeName,
  handleSetFilters,
}: FilterProps<T, O>) => {
  const { open } = useActionWithPopover();
  return (
    <DialogComponent
      {...filterOptions}
      open={open}
      title={title}
      codeName={codeName}
      handleSetFilters={handleSetFilters}
    />
  );
};

export const TableFilterAction = <T, O extends {}>({
  dialogComponent,
  filterOptions,
  buttonComponent: ButtonComponent,
  title,
  codeName,
  handleSetFilters,
}: Props<T, O>) => {
  return (
    <ActionWithPopover
      content={
        <Filter
          title={title}
          codeName={codeName}
          dialogComponent={dialogComponent}
          filterOptions={filterOptions}
          handleSetFilters={handleSetFilters}
        />
      }
      name="table-search"
    >
      {(onClick, id) => <ButtonComponent onClick={onClick} id={id} />}
    </ActionWithPopover>
  );
};
