import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useCreateVehicle } from '@Modal/hooks/vehicles/vehicles/useCreateVehicle';

export const CreateVehicleCancelModalDialog: React.FC = () => {
  const { handleCancelTakeMeBack, showCancelVehicleForm } = useCreateVehicle();

  return (
    <>
      {showCancelVehicleForm && (
        <CancelModal open={showCancelVehicleForm}>
          <ImageCancelDialogLayout
            title="Cancel vehicle changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in vehicle settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
