// System
import { Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';

// Features
import { AdditionalDetails } from '@Features/vehicles/vehicles/SetupVehicle/tabs/Details/AdditionalDetails';

// Forms
import { useSetupVehicleForm } from '@Forms/vehicles/SetupVehicleFormContext';

// Components
import { VehicleCard } from './VehicleCard/VehicleCard';
import { GeneralInformation } from './GeneralInformation';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Redux
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

export const Details: React.FC = () => {
  const methods = useSetupVehicleForm();
  const { selectedVehicleData } = useVehiclesDrawer();

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Box sx={{ marginY: 1 }}>
            <VehicleCard vehicleData={selectedVehicleData} />
          </Box>
          <GeneralInformation />
          <AdditionalDetails />
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
