import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { 
    CreateCustomerNoteFormContext, 
    CreateCustomerNoteFormState,
    CreateCustomerNoteSchemaDefault,
    CreateCustomerNoteFormStateDefault
} from '@Forms/customers/CreateCustomerNoteFormContext';

export const CreateCustomerNoteFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateCustomerNoteFormState>>>(
    CreateCustomerNoteSchemaDefault
  );
  const methods = useForm({
    defaultValues: CreateCustomerNoteFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateCustomerNoteFormContext.Provider value={value}>
      {children}
    </CreateCustomerNoteFormContext.Provider>
  );
};