// Components
import { SetupVehicleTabs } from './SetupVehicleTabs/SetupVehicleTabs';
import { SetupVehicleHeader } from './SetupVehicleHeader/SetupVehicleHeader';
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';

// Redux
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

export const SetupVehicleContent: React.FC = () => {
  const { handleCreateCancel, handleSubmitGeneralForm, submittable } = useVehiclesDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/vehicles" onClose={handleCreateCancel}>
          <SetupVehicleHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={handleSubmitGeneralForm}
          saveDisabled={!submittable}
          onCancelClick={handleCreateCancel}
        />
      }
    >
      <SetupVehicleTabs />
    </TabDrawerLayout>
  );
};
