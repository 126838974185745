import {
  StringTableFilterDialogContent,
  TableFilterAction,
} from '@Components/shared/tables/filters';
import { SearchTableButton } from '@Components/shared/tables/actions';
import { ColumnFilterable } from '@contracts/common/traits/Filterable';

import { TableColumnHeaderLayout } from '../TableColumnHeaderLayout';
import { FilterItemType } from '@Modal/reducers/types';

type Props = {
  title: string;
  codeName?: string;
  handleSetFilters: (arg: FilterItemType) => void;
};

export const StringTableColumnHeader: React.FC<Props> = ({ title, codeName, handleSetFilters }) => {
  return (
    <TableColumnHeaderLayout
      title={title}
      search={
        <TableFilterAction
          title={title}
          codeName={codeName}
          filterOptions={{}}
          buttonComponent={SearchTableButton}
          dialogComponent={StringTableFilterDialogContent}
          handleSetFilters={handleSetFilters}
        />
      }
    />
  );
};
