import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { UpdateVehicleCategoryDto } from '@contracts/api/dto/vehicles/categories/UpdateVehicleCategoryDto';

// Types

// GET
export const getSelectedVehicleCategory = createAsyncThunk(
  'vehicle/getSelectedVehicleCategory',
  async (categoryId: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/categories/${categoryId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const getSelectedVehicleCategoryDashboardCommands = createAsyncThunk(
  'vehicle/getSelectedVehicleCategoryDashboardCommands',
  async (categoryId: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/categories/${categoryId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.commands.dashboard);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT
export const updateSelectedVehicleCategory = createAsyncThunk(
  'vehicle/updateSelectedVehicleCategory',
  async (vehicleData: UpdateRequest<UpdateVehicleCategoryDto>, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/categories/${vehicleData.id}`, {
        ...vehicleData.body,
      })
      .then(() => {
        return thunkApi.fulfillWithValue(vehicleData.body);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  selectedVehicleCategory: null,
  selectedVehicleCategoryDashboardCommands: [],
  showCancelCategoryDrawer: false,
  showSelectedCategoryDrawer: false,
  showSpeedSettingsFilters: null,
  showAutoEndRentalInput: null,
};

const veehicleCategoryDrawerSlice = createSlice({
  name: 'vehicleCategoryDrawer',
  initialState,
  reducers: {
    setShowSelectedCategoryDrawer: (state, { payload }) => {
      state.showSelectedCategoryDrawer = payload;
    },
    setShowCancelVehicleCategoryDrawer: (state, { payload }) => {
      state.showCancelCategoryDrawer = payload;
    },
    setShowSpeedSettingsFilters: (state, { payload }) => {
      state.showSpeedSettingsFilters = payload;
    },
    setShowAutoEndRentalInput: (state, { payload }) => {
      state.showAutoEndRentalInput = payload;
    },
  },
  extraReducers: {
    [getSelectedVehicleCategory.fulfilled?.type]: (state, { payload }) => {
      state.selectedVehicleCategory = payload;
      state.showSelectedCategoryDrawer = true;
      state.showSpeedSettingsFilters = payload.iotBatteryLevelFilters;
      state.showAutoEndRentalInput = payload.noMovement.autoEndRental;
    },
    [getSelectedVehicleCategoryDashboardCommands.fulfilled?.type]: (state, { payload }) => {
      // eslint-disable-next-line prefer-const
      let availableCommands: any = [];
      for (const key in payload) {
        if (payload[key]) {
          availableCommands.push(key);
        }
      }
      state.selectedVehicleCategoryDashboardCommands = availableCommands;
    },
    [updateSelectedVehicleCategory.fulfilled?.type]: (state, { payload }) => {
      state.selectedVehicleCategory = payload;
      state.showSelectedCategoryDrawer = false;
    },
  },
});

export const { reducer: vehicleCategoryDrawerReducer } = veehicleCategoryDrawerSlice;
export const {
  setShowSelectedCategoryDrawer,
  setShowCancelVehicleCategoryDrawer,
  setShowSpeedSettingsFilters,
  setShowAutoEndRentalInput,
} = veehicleCategoryDrawerSlice.actions;
