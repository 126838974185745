import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosPaymentsInstance } from '@utils/axiosInstances';
import { PaymentMethodCardProps } from '../types';

// GET
export const getCustomerPaymentMethods = createAsyncThunk(
  'customer/getCustomerPaymentMethods',
  async (id: string, thunkApi) => {
    const config = {
      headers: {
        'x-private-key': process.env.REACT_APP_SCROLL_MOBILE_PRIVATE,
      },
    };
    try {
      const response = await axiosPaymentsInstance.get(`/payments/cards/${id}`, config);
      return thunkApi.fulfillWithValue(response.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error.message || 'Rejected');
    }
  }
);

// DELETE
export const deleteCustomerPaymentMethod = createAsyncThunk(
  'customer/deleteCustomerPaymentMethod',
  async (id: string, thunkApi) => {
    const config = {
      headers: {
        'x-private-key': process.env.REACT_APP_SCROLL_MOBILE_PRIVATE,
      },
    };

    try {
      await axiosPaymentsInstance.delete(`/payments/project/card/${id}`, config);
      return thunkApi.fulfillWithValue(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error.message || 'Rejected');
    }
  }
);

// Type
const initialState = {
  paymentMethods: [],
  selectedPaymentMethodId: 0,
  showDeleteCustomerPaymentMethod: false,
};

const customerPaymentMethodsSlice = createSlice({
  name: 'customerPaymentMethods',
  initialState,
  reducers: {
    setCustomerPaymentMethods: (state, { payload }) => {
      state.paymentMethods = payload;
    },
    setShowDeleteCustomerPaymentMethod: (state, { payload }) => {
      state.showDeleteCustomerPaymentMethod = payload;
    },
    setSelectedPaymentMethodId: (state, { payload }) => {
      state.selectedPaymentMethodId = payload;
    },
  },
  extraReducers: {
    [getCustomerPaymentMethods.fulfilled?.type]: (state, { payload }) => {
      state.paymentMethods = payload;
    },
    [deleteCustomerPaymentMethod.fulfilled?.type]: (state, { payload }) => {
      const updatedPaymentMethods = state.paymentMethods.filter(
        (paymentMethod: PaymentMethodCardProps) => paymentMethod.id !== payload
      );
      // @ts-ignore
      state.paymentMethods = updatedPaymentMethods;
      state.showDeleteCustomerPaymentMethod = false;
    },
  },
});

export const { reducer: customerPaymentMethodReducer } = customerPaymentMethodsSlice;
export const {
  setCustomerPaymentMethods,
  setShowDeleteCustomerPaymentMethod,
  setSelectedPaymentMethodId,
} = customerPaymentMethodsSlice.actions;
