// System
import { useMemo } from 'react';
import { Button, Theme } from '@mui/material';

import { Box, SystemStyleObject } from '@mui/system';
// Components
import MenuPopover from '@Components/menu-popover';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Component Hooks
import { usePopOvers } from '@Components/shared/hooks/usePopOvers';

// Redux
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { useVehicleCommands } from '@Hooks/shared/useVehicleCommands';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

// Styles
import Styles from './VehicleCommandsLabel.module.scss';
import { useActivity } from '@Modal/hooks/vehicles/activity/useActivity';

// Props
type Props = {
  value: string | null;
  sx?: SystemStyleObject<Theme>;
  id: string;
};

export const VehicleCommandsLabel: React.FC<Props> = ({ value, id, sx }) => {
  const { vehicleServiceStates } = useGeneralStore();

  const { selectedVehicleData } = useVehiclesDrawer();
  const { selectedVehicleCategoryDashboardCommands } = useVehicleCategoryDrawer();
  const { handleGetActivity } = useActivity();

  const { open, openPopover, closePopover } = usePopOvers();
  const socketId = localStorage.getItem('socketId');
  const { handleSendCommands } = useVehicleCommands();

  function commandStringFormatter(inputString: string) {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
    );
  }
  const item = useMemo(() => {
    return vehicleServiceStates?.find(({ key }) => key === value);
  }, [vehicleServiceStates, value]);
  const DropDown = useMemo(
    () =>
      (selectedVehicleCategoryDashboardCommands ?? []).map((command: string) => (
        <MenuItemEx
          key={command}
          value={command}
          name={command}
          onClick={(e) => {
            handleSendCommands(socketId, command, [selectedVehicleData?.iotImei]);
            setTimeout(() => {
              handleGetActivity();
            }, 1000);
            closePopover(e);
          }}
        >
          {commandStringFormatter(command)}
        </MenuItemEx>
      )),
    [selectedVehicleCategoryDashboardCommands]
  );

  if (!item) {
    return null;
  }

  return (
    <Box className={Styles.VehicleCommandsLabel}>
      <AtLocator type="button" name="commandsLabel">
        <Button
          className={Styles.CommandsCTA}
          endIcon={
            open ? (
              <AssetIcon src="chevron-up" size="s" />
            ) : (
              <AssetIcon src="chevron-down" size="s" />
            )
          }
          onClick={openPopover}
        >
          Send command
        </Button>
      </AtLocator>
      <MenuPopover open={open} onClose={closePopover} sx={{ marginY: 1 }} disabledArrow>
        {DropDown}
      </MenuPopover>
    </Box>
  );
};
