// System
import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/system';
import { Typography } from '@mui/material';

// Components
import { SetupPricingHeader } from '../SetupPricingHeader';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Form
import { useSetupPricingForm } from '@Forms/pricing/PricingDrawer/SetupPricingFormContext';

// Redux
import { usePricingDrawer } from '@Modal/hooks/pricing/usePricingDrawer';

// Styles
import Styles from './SetupPricingContent.module.scss';

export const SetupPricingContent = () => {
  const methods = useSetupPricingForm();
  const dispatch = useDispatch();

  const {
    setShowPricingDrawer,
    submittable,
    handleSubmitGeneralForm,
    selectedPricing,
    handleCreateCancel,
  } = usePricingDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout
          currentSlug="pricing"
          onClose={() => dispatch(setShowPricingDrawer(false))}
        >
          <SetupPricingHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={handleSubmitGeneralForm}
          saveDisabled={!submittable}
          onCancelClick={handleCreateCancel}
        />
      }
    >
      <FormProvider methods={methods} onSubmit={() => alert('1')}>
        <DrawerTabPanelLayout>
          <DrawerTabSectionLayout>
            <Box className={Styles.SetupPricingContent}>
              <Box className={Styles.MainContent}>
                <Typography className={Styles.HeaderFont}>Pricing</Typography>
                <Stack className={Styles.InputsContainer}>
                  <RHFTextField
                    id="unlockPrice"
                    name="unlockPrice"
                    label="Unlock Price"
                    variant="outlined"
                    type="number"
                    className={Styles.Input}
                  />
                  <RHFTextField
                    id="minutePrice"
                    name="minutePrice"
                    label="Minute Price"
                    variant="outlined"
                    type="number"
                    className={Styles.Input}
                  />
                  <RHFTextField
                    id="parkingPrice"
                    name="parkingPrice"
                    label="Parking Price"
                    type="number"
                    variant="outlined"
                    className={Styles.Input}
                  />
                </Stack>
              </Box>
              <Box>
                <Typography className={Styles.HeaderFont}>Additional Information</Typography>
                <Box className={Styles.InfoContainer}>
                  <AtLocator type="readonly-text" name="customer">
                    <Stack className={Styles.InfoLine}>
                      <Typography className={Styles.InfoLabel} data-at-readnly-text="Label">
                        Category Name
                      </Typography>
                      <Typography className={Styles.InfoValue}>{selectedPricing?.name}</Typography>
                    </Stack>
                  </AtLocator>
                  <AtLocator type="readonly-text" name="customer">
                    <Stack className={Styles.InfoLine}>
                      <Typography className={Styles.InfoLabel} data-at-readnly-text="Label">
                        Second Level Branch
                      </Typography>
                      <Typography className={Styles.InfoValue} data-at-readnly-text="Value">
                        {selectedPricing?.secondLvlBranch?.name}
                      </Typography>
                    </Stack>
                  </AtLocator>
                  <AtLocator type="readonly-text" name="customer">
                    <Stack className={Styles.InfoLine}>
                      <Typography className={Styles.InfoLabel} data-at-readnly-text="Label">
                        Third Level Branch
                      </Typography>
                      <Typography className={Styles.InfoValue} data-at-readnly-text="Value">
                        {selectedPricing?.thirdLvlBranch?.name}
                      </Typography>
                    </Stack>
                  </AtLocator>
                  <AtLocator type="readonly-text" name="customer">
                    <Stack className={Styles.InfoLine}>
                      <Typography className={Styles.InfoLabel} data-at-readnly-text="Label">
                        Vehicle Type
                      </Typography>
                      <Typography className={Styles.InfoValue} data-at-readnly-text="Value">
                        {selectedPricing?.vehicleType}
                      </Typography>
                    </Stack>
                  </AtLocator>
                  <AtLocator type="readonly-text" name="customer">
                    <Stack className={Styles.InfoLine}>
                      <Typography className={Styles.InfoLabel} data-at-readnly-text="Label">
                        Vehicle Category
                      </Typography>
                      <Typography className={Styles.InfoValue} data-at-readnly-text="Value">
                        {selectedPricing?.category?.name}
                      </Typography>
                    </Stack>
                  </AtLocator>
                </Box>
              </Box>
            </Box>
          </DrawerTabSectionLayout>
        </DrawerTabPanelLayout>
      </FormProvider>
    </TabDrawerLayout>
  );
};
