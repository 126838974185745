// System
import { List, Stack } from '@mui/material';

// Components
import NavList from './NavList';

// Types
import { NavSectionProps } from '../types';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, ...other }: NavSectionProps) {
  return (
    <Stack {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key}>
            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
