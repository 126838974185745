import React, { CSSProperties, useContext, useMemo, useState } from 'react';

// Componens
import {
  GoogleMapsContainer,
  GoogleMapsContainerContext,
  GoogleMapsContainerContextType,
  MapOptions,
} from '@Components/shared/maps/GoogleMapsContainer';
import { MapAddMode } from './modes/MapAddMode';
import { MapCutMode } from './modes/MapCutMode';
import { MapViewMode } from './modes/MapViewMode';
import { MapEditMode } from './modes/MapEditMode';
import { MapDeleteMode } from './modes/MapDeleteMode';
import { GoogleMapsLoader } from '@Components/shared/maps/GoogleMapsLoader';

// System
import { Box } from '@mui/material';

// Redux
import { useDispatch } from 'react-redux';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useBranches } from '@Modal/hooks/branches/useBranch';

const mapContainerStyle: CSSProperties = { height: '100%', width: '100%' };

export const MapZoneViewer: React.FC = () => {
  const dispatch = useDispatch();
  const { mode, defaultZoom, defaultCenter, setChangeMapMode, setSelectedMapZoneIndex } =
    useBranchesMaps();
  const { selectedBranch } = useBranches();

  const centerLocationByBranch = {
    lat: selectedBranch?.geoLocation?.lat,
    lng: selectedBranch?.geoLocation?.lon,
  };
  const useZoom = useState<number | undefined>(defaultZoom);
  const useCenter = useState<google.maps.LatLngLiteral | undefined>(
    centerLocationByBranch ? centerLocationByBranch : defaultCenter
  );

  const mapValue = useMemo<GoogleMapsContainerContextType>(
    () => ({
      center: useCenter,
      zoom: useZoom,
      options: MapOptions,
      mapContainerStyle,
    }),
    [useZoom, useCenter]
  );

  return (
    <GoogleMapsContainerContext.Provider value={mapValue}>
      <Box
        width="100%"
        height="100%"
        sx={{
          '& button[aria-label="Stop drawing"]': {
            visibility: 'collapse',
          },
          '& button[aria-label="Draw a shape"]': {
            visibility: 'collapse',
          },
        }}
      >
        <GoogleMapsContainer onClick={() => dispatch(setSelectedMapZoneIndex(undefined))}>
          {mode === 'view' && <MapViewMode />}
          {mode === 'edit' && <MapEditMode />}

          {mode === 'add' && (
            <MapAddMode
              onFinish={async (i) => {
                // await handleChangeMode('add');
                // setSelectedIndex(i);
              }}
            />
          )}
          {mode === 'cut' && (
            <MapCutMode
              onFinish={async (i) => {
                await dispatch(setChangeMapMode('edit'));
                dispatch(setChangeMapMode(i));
              }}
            />
          )}
          {mode === 'delete' && <MapDeleteMode />}
        </GoogleMapsContainer>
      </Box>
    </GoogleMapsContainerContext.Provider>
  );
};

export const SetupMapZoneContent: React.FC = () => {
  const { activeMapZoneType } = useBranchesMaps();
  // @ts-ignore
  return <GoogleMapsLoader component={MapZoneViewer} props={{ mapZoneType: activeMapZoneType }} />;
};
