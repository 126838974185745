import { useCallback, useMemo } from 'react';

// System
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { usePricing } from './usePricing';
import {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  setColumnVisibility,
  resetSelectedFilters,
} from '@Modal/reducers/pricing/pricingTableSlice';
import { getPricing } from '@Modal/reducers/pricing/pricingSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { FilterItemType } from '@Modal/reducers/types';
import { PricingItemType } from '@Modal/reducers/pricing/types';
import { useBranches } from '../branches/useBranch';
import { Paginable } from '@contracts/common/traits/Paginable';

export const usePricingTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const { rowsPerPage, columnVisibility, columnVisibilityOptions, page, selectedFilters } =
    useSelector((state: StoreInterface) => state?.pricingTable);

  const { activeBrandId } = useBranches();
  const { pricingList, pagination, setPagination, loadingContent } = usePricing();

  const getPricingListValues = (level: keyof PricingItemType) => {
    const pricingSelectedList = pricingList?.map(
      // @ts-ignore
      (pricing: PricingItemType) => pricing?.[level]?.name
    );
    // @ts-ignore
    return [...new Set(pricingSelectedList)];
  };

  function convertArrayToObject(key: keyof PricingItemType) {
    const valueList = getPricingListValues(key);
    return valueList.reduce((acc, currentValue) => {
      acc[currentValue] = currentValue;
      return acc;
    }, {});
  }

  const getPricingListOfValues = useCallback(() => {
    const objectedSecondLvlBranches = convertArrayToObject('secondLvlBranch');
    const objectedThirdLvlBranches = convertArrayToObject('thirdLvlBranch');
    const objectedVehicleCategories = convertArrayToObject('category');

    return {
      secondLevelBranches: objectedSecondLvlBranches,
      thirdLevelBranches: objectedThirdLvlBranches,
      vehicleCategory: objectedVehicleCategories,
    };
  }, [pricingList]);

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setSelectedFilters(filter));
  };

  const fetchPricingTableContent = useCallback(() => {
    dispatch(getPricing({ selectedFilters }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedFilters, activeBrandId]);

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const pricingListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);

    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...pricingListFilters, ...column];

    if (pricingListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = pricingListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const handleTablePageChange = (filter: FilterItemType, pagination: Paginable) => {
    dispatch(setSelectedFilters(filter));
    dispatch(setPagination(pagination));
  };

  const handleResetFilters = () => {
    dispatch(resetSelectedFilters());
  };

  return {
    // State
    page,
    pagination,
    pricingList,
    rowsPerPage,
    loadingContent,
    selectedFilters,
    columnVisibility,
    pricingListFilters,
    columnVisibilityOptions,
    // Actions
    setPage,
    setRowsPerPage,
    setSelectedFilters,
    setColumnVisibility,
    handleFilterSelect,
    handleResetFilters,
    handleTablePageChange,
    visiblityCustomizable,
    getPricingListOfValues,
    fetchPricingTableContent,
  };
};
