import { SetupCategoryFormState } from '@Forms/vehicles/SetupCategoryFormContext';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { UpdateVehicleCategoryDto } from '@contracts/api/dto/vehicles/categories/UpdateVehicleCategoryDto';
import { VehicleCategoryItem } from '@Modal/reducers/vehicles/types';

export const useSetupCategoryUseCaseMapper = (category: VehicleCategoryItem) => {
  return {
    toFormState: async (): Promise<SetupCategoryFormState | undefined> => {
      return {
        id: category?.id,
        name: category?.name,
        vehicleType: category?.vehicleType,
        brandId: category?.brandId,
        displayName: category?.displayName ?? '',
        helmetTracking: category?.helmetTracking ?? false,
        batteryMedium: category?.batteryMedium || 0,
        batteryLow: category?.batteryLow || 0,
        batteryCritical: category?.batteryCritical || 0,
        iotBatteryLevelFilters: category?.iotBatteryLevelFilters ?? false,
        iotBatteryMedium: category?.iotBatteryMedium || 0,
        iotBatteryLow: category?.iotBatteryLow || 0,
        iotBatteryCritical: category?.iotBatteryCritical || 0,
        speedMode: category?.speedMode ?? null,
        maxSpeed: category?.maxSpeed ?? null,
        maxSpeedSlowZone: category?.maxSpeedSlowZone ?? null,
        maxDistanceWithFullBattery: category?.maxDistanceWithFullBattery ?? null,
        reservationDistanceMax: category?.reservationDistanceMax ?? null,
        rentalDistanceMax: category?.rentalDistanceMax ?? null,
        minBalanceWithPaymentSource: category?.minBalanceWithPaymentSource ?? null,
        minBalanceWithoutPaymentSource: category?.minBalanceWithoutPaymentSource ?? null,
        minAge: category?.minAge ?? null,
        signedUp: category?.signedUp || false,
        emailAddressSpecified: category?.emailAddressSpecified || false,
        phoneNumberSpecified: category?.phoneNumberSpecified || false,
        firstNameSpecified: category?.firstNameSpecified || false,
        lastNameSpecified: category?.lastNameSpecified || false,
        dateOfBirthSpecified: category?.dateOfBirthSpecified || false,
        minAgeAccordance: category?.minAgeAccordance || false,
        streetSpecified: category?.streetSpecified || false,
        postcodeSpecified: category?.postcodeSpecified || false,
        citySpecified: category?.citySpecified || false,
        countrySpecified: category?.countrySpecified || false,
        creditCardAttached: category?.creditCardAttached || false,
        paymentMethodSelected: category?.paymentMethodSelected || false,
        paidSignUpFeeViaCreditCard: category?.paidSignUpFeeViaCreditCard || false,
        enableCreditCardPreAuthorization: category?.enableCreditCardPreAuthorization || false,
        settleNegativeBalance: category?.settleNegativeBalance || false,
        vehicleIsInOperationalArea: category?.vehicleIsInOperationalArea || false,
        vehicleIsNotInNoParkingArea: category?.vehicleIsNotInNoParkingArea || false,
        vehicleIsCharging: category?.vehicleIsCharging || false,
        speedIsZero: category?.speedIsZero || false,
        ignitionMustBeTurnedOffAfterEndingRental:
          category?.ignitionMustBeTurnedOffAfterEndingRental || false,
        ignitionMustBeSwitchedOffToEndRental:
          category?.ignitionMustBeSwitchedOffToEndRental || false,
        helmetsDetected: category?.helmetsDetected || false,
        bikeLockIsLocked: category?.bikeLockIsLocked || false,
        commands: {
          dashboard: {
            start: category?.commands?.dashboard?.start,
            stop: category?.commands?.dashboard?.stop,
            unlockBattery: category?.commands?.dashboard?.unlockBattery,
            unlockHelmet: category?.commands?.dashboard?.unlockHelmet,
            alarmOn: category?.commands?.dashboard?.alarmOn,
            setMaxSpeed: category?.commands?.dashboard?.setMaxSpeed,
            reboot: category?.commands?.dashboard?.reboot,
            productionMode: category?.commands?.dashboard?.productionMode,
            demoMode: category?.commands?.dashboard?.demoMode,
            openSaddle: category?.commands?.dashboard?.openSaddle,
            openTailBox: category?.commands?.dashboard?.openTailBox,
          },
          approach: {
            refresh: category?.commands?.approach?.refresh,
            alarmOn: category?.commands?.approach?.alarmOn,
            start: category?.commands?.approach?.start,
            setOutOfOrder: category?.commands?.approach?.setOutOfOrder,
            unlockBattery: category?.commands?.approach?.unlockBattery,
          },
          deploy: {
            refresh: category?.commands?.deploy?.refresh,
            alarmOn: category?.commands?.deploy?.alarmOn,
            stop: category?.commands?.deploy?.stop,
            setOperational: category?.commands?.deploy?.setOperational,
          },
          close: {
            refresh: category?.commands?.close?.refresh,
            alarmOn: category?.commands?.close?.alarmOn,
            stop: category?.commands?.close?.stop,
          },
        },
        noMovement: {
          maxDistance: category?.noMovement?.maxDistance,
          maxTime: category?.noMovement?.maxTime,
          autoEndRental: category?.noMovement?.autoEndRental,
          endRentalAfterMinutes: category?.noMovement?.endRentalAfterMinutes || 0,
        },
      };
    },
    toDto: (formState: SetupCategoryFormState): UpdateRequest<UpdateVehicleCategoryDto> => {
      return {
        id: formState.id,
        body: {
          name: formState.name,
          vehicleType: formState.vehicleType,
          displayName: formState.displayName || null,
          helmetTracking: formState.helmetTracking,
          batteryMedium: formState.batteryMedium || 0,
          batteryLow: formState.batteryLow || 0,
          batteryCritical: formState.batteryCritical || 0,
          iotBatteryLevelFilters: formState.iotBatteryLevelFilters,
          iotBatteryMedium: formState.iotBatteryMedium || 0,
          iotBatteryLow: formState.iotBatteryLow || 0,
          iotBatteryCritical: formState.iotBatteryCritical || 0,
          speedMode: formState.speedMode || null,
          maxSpeed: formState.maxSpeed ?? null,
          maxSpeedSlowZone: formState.maxSpeedSlowZone ?? null,
          maxDistanceWithFullBattery: formState.maxDistanceWithFullBattery ?? null,
          reservationDistanceMax: formState.reservationDistanceMax ?? null,
          rentalDistanceMax: formState.rentalDistanceMax ?? null,
          minBalanceWithPaymentSource: formState.minBalanceWithPaymentSource ?? null,
          minBalanceWithoutPaymentSource: formState.minBalanceWithoutPaymentSource ?? null,
          minAge: formState.minAge ?? null,
          signedUp: formState.signedUp || false,
          emailAddressSpecified: formState.emailAddressSpecified || false,
          phoneNumberSpecified: formState.phoneNumberSpecified || false,
          firstNameSpecified: formState.firstNameSpecified || false,
          lastNameSpecified: formState.lastNameSpecified || false,
          dateOfBirthSpecified: formState.dateOfBirthSpecified || false,
          minAgeAccordance: formState.minAgeAccordance || false,
          streetSpecified: formState.streetSpecified || false,
          postcodeSpecified: formState.postcodeSpecified || false,
          citySpecified: formState.citySpecified || false,
          countrySpecified: formState.countrySpecified || false,
          creditCardAttached: formState.creditCardAttached || false,
          paymentMethodSelected: formState.paymentMethodSelected || false,
          paidSignUpFeeViaCreditCard: formState.paidSignUpFeeViaCreditCard || false,
          enableCreditCardPreAuthorization: formState.enableCreditCardPreAuthorization || false,
          settleNegativeBalance: formState.settleNegativeBalance || false,
          vehicleIsInOperationalArea: formState.vehicleIsInOperationalArea || false,
          vehicleIsNotInNoParkingArea: formState.vehicleIsNotInNoParkingArea || false,
          vehicleIsCharging: formState.vehicleIsCharging || false,
          speedIsZero: formState.speedIsZero || false,
          ignitionMustBeTurnedOffAfterEndingRental:
            formState.ignitionMustBeTurnedOffAfterEndingRental || false,
          ignitionMustBeSwitchedOffToEndRental:
            formState.ignitionMustBeSwitchedOffToEndRental || false,
          helmetsDetected: formState.helmetsDetected || false,
          bikeLockIsLocked: formState.bikeLockIsLocked || false,
          commands: {
            dashboard: {
              start: formState?.commands?.dashboard?.start || false,
              stop: formState?.commands?.dashboard?.stop || false,
              unlockBattery: formState?.commands?.dashboard?.unlockBattery || false,
              unlockHelmet: formState?.commands?.dashboard?.unlockHelmet || false,
              alarmOn: formState?.commands?.dashboard?.alarmOn || false,
              setMaxSpeed: formState?.commands?.dashboard?.setMaxSpeed || false,
              reboot: formState?.commands?.dashboard?.reboot || false,
              productionMode: formState?.commands?.dashboard?.productionMode || false,
              demoMode: formState?.commands?.dashboard?.demoMode || false,
              openSaddle: formState?.commands?.dashboard?.openSaddle || false,
              openTailBox: formState?.commands?.dashboard?.openSaddle || false,
            },
            approach: {
              refresh: formState?.commands?.approach?.refresh || false,
              alarmOn: formState?.commands?.approach?.alarmOn || false,
              start: formState?.commands?.approach?.start || false,
              setOutOfOrder: formState?.commands?.approach?.setOutOfOrder || false,
              unlockBattery: formState?.commands?.approach?.unlockBattery || false,
            },
            deploy: {
              refresh: formState?.commands?.deploy?.refresh || false,
              alarmOn: formState?.commands?.deploy?.alarmOn || false,
              stop: formState?.commands?.deploy?.stop || false,
              setOperational: formState?.commands?.deploy?.setOperational || false,
            },
            close: {
              refresh: formState?.commands?.close?.refresh || false,
              alarmOn: formState?.commands?.close?.alarmOn || false,
              stop: formState?.commands?.close?.stop || false,
            },
          },
          noMovement: {
            maxDistance: formState?.noMovement?.maxDistance || 0,
            maxTime: formState?.noMovement?.maxTime || 0,
            autoEndRental: formState?.noMovement?.autoEndRental ?? false,
            endRentalAfterMinutes: formState?.noMovement?.endRentalAfterMinutes || null,
          },
        },
      };
    },
  };
};
