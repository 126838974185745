// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  updateCustomer,
  getSelectedCustomer,
  setShowCustomerDrawer,
  setShowCancelCustomerDrawer,
} from '@Modal/reducers/customers/customerDrawerSlice';
import {
  useSetupCustomerForm,
  useManageSetupCustomerSchema,
} from '@Forms/customers/SetupCustomerFormContext';

// Redux
import { useCustomers } from './useCustomers';

// Hooks
import { useSetupCustomerUseCaseSchema } from '@Hooks/customers/SetupCustomer/useSetupCustomerUseCaseSchema';
import { useSetupCustomerUseCaseMapper } from '@Hooks/customers/SetupCustomer/useSetupCustomerUseCaseMapper';
import { useCustomerRentals } from './rentals/useCustomerRentals';

export const useCustomersDrawer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { showCancelCustomerDrawer, showCustomerDrawer, selectedCustomerData } = useSelector(
    (state: StoreInterface) => state?.customerDrawer
  );
  const { customers } = useCustomers();

  // Other
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, handleSubmit, reset } = useSetupCustomerForm();
  const { setSchema } = useManageSetupCustomerSchema();

  const mapper = selectedCustomerData ? useSetupCustomerUseCaseMapper(selectedCustomerData) : null;
  const schema = useSetupCustomerUseCaseSchema();

  const { isDirty, errors } = useFormState({ control });

  const handleCustomerRowClick = async (id: string) => {
    return dispatch(getSelectedCustomer(id))
      .unwrap()
      .then(() => dispatch(setShowCustomerDrawer(true)))
      .catch((error: string) => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const setCustomerDrawerMapperHandler = useCallback(async () => {
    if (mapper) {
      setSchema(schema);
      const formState = await mapper.toFormState(selectedCustomerData.id);
      reset(formState);
    }
  }, [selectedCustomerData]);

  useEffect(() => {
    if (selectedCustomerData) {
      setCustomerDrawerMapperHandler();
    }
  }, [selectedCustomerData, setCustomerDrawerMapperHandler]);

  // Form Submit
  const handleSubmitGeneralForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          updateCustomer({
            customerId: selectedCustomerData?.id,
            customerFormState: {
              accountDetails: formData?.accountDetails,
              firstName: formData.accountDetails[0].firstName,
              lastName: formData.accountDetails[0].lastName,
              email: formData.email,
              phoneNumber: formData.phone,
              brandId: formData.customer.brandId,
              branchId: formData.customer.brandId,
              street: formData.customer.brandId,
              zip: formData.customer.brandId,
              // @ts-ignore
              tags: formData.accountDetails[0],
              city: formData.customer.brandId,
              newsletter: formData.customer.newsletter,
            },
          })
        )
          .unwrap()
          .then(() => {
            reset();
            const updatedCustomers = customers.map((customer) => {
              return customer?.id === selectedCustomerData?.id
                ? { ...customer, ...formData }
                : customer;
            });

            // @ts-ignore
            dispatch(updateCustomersList(updatedCustomers));
          })
          .catch((error: string) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelCustomerDrawer(true));
    } else {
      reset();
      return dispatch(setShowCustomerDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelCustomerDrawer(false));
      dispatch(setShowCustomerDrawer(true));
    } else {
      reset();
      dispatch(setShowCancelCustomerDrawer(false));
      dispatch(setShowCustomerDrawer(false));
    }
  };

  return {
    // State
    showCustomerDrawer,
    selectedCustomerData,
    showCancelCustomerDrawer,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    getSelectedCustomer,
    handleCreateCancel,
    setShowCustomerDrawer,
    handleCustomerRowClick,
    handleCancelTakeMeBack,
    handleSubmitGeneralForm,
    setShowCancelCustomerDrawer,
  };
};
