import { ComponentType } from 'react';

import { ComposeProvider } from '@Components/shared/providers/ComposeProvider';

// Branches
import { CreateBrandFormProvider } from '@Features/providers/forms/branches/CreateBrandFormProvider';
import { SetupBranchFormProvider } from '@Features/providers/forms/branches/SetupBranchFormProvider';
import { CreateBranchFormProvider } from '@Features/providers/forms/branches/CreateBranchFormProvider';

// Vehicles
import { SetupVehicleFormProvider } from '@Features/providers/forms/vehichles/SetupVehicleFormProvider';
import { SetupCategoryFormProvider } from '@Features/providers/forms/vehichles/SetupCategoryFormProvider';
import { CreateVehicleFormProvider } from '@Features/providers/forms/vehichles/CreateVehicleFormProvider';
import { CreateCategoryFormProvider } from '@Features/providers/forms/vehichles/CreateCategoryFormProvider';
import { SetupIotModuleFormProvider } from '@Features/providers/forms/vehichles/SetupIotModuleFormProvider';
import { CreateIotModuleFormProvider } from '@Features/providers/forms/vehichles/CreateIotModuleFormProvider';
import { CreateVehicleNoteFormProvider } from '@Features/providers/forms/vehichles/CreateVehicleNoteFormProvider';
import { CreateVehicleIotLinkFormProvider } from '@Features/providers/forms/vehichles/CreateVehicleIotLinkFormProvider';

// Customers
import { SetupCustomerFormProvider } from '@Features/providers/forms/customers/SetupCustomerFormProvider';
import { CreateCustomerNoteFormProvider } from '@Features/providers/forms/customers/CreateCustomerNoteFormProvider';
import { BalanceModalDialogFormProvider } from '@Features/providers/forms/customers/BalanceModalDialogFormProvider';
import { DeleteCustomerFormProvider } from '@Features/providers/forms/customers/DeleteCustomerFormProvider';

// Rentals
import { CreateRentalsNoteFormProvider } from '@Features/providers/forms/rentals/CreateRentalsNoteFormProvider';
import { RentalEndRideFormProvider } from '@Features/providers/forms/rentals/RentalEndRideFormProvider';

// Pricing
import { SetupPricingFormProvider } from '@Features/providers/forms/pricing/SetupPricingFormProvider';
import { CreatePricingFormProvider } from '@Features/providers/forms/pricing/CreatePricingFormProvider';

// Operator
import { LinkIoTFormProvider } from '@Features/providers/forms/operator/SetupLinkIoTFormProvider';

// Others
import { AlertStoreProvider } from '@Features/providers/store/AlertStoreProvider';
import { GoogleMapsLoaderProvider } from '@Components/shared/maps/GoogleMapsLoader';

// Transactions
import { TransactionRefundFormProvider } from '@Features/providers/forms/transactions/TransactionRefundFormProvider';
import { ReplaceQRFormProvider } from '@Features/providers/forms/operator/SetupReplaceQRFormProvider';

// Roles
import { SetupAddUserFormProvider } from '@Features/providers/forms/roles/SetupAddUserFormProvider';
import { SetupRoleFormProvider } from '@Features/providers/forms/roles/SetupRoleFormProvider';
import { ResetRolePasswordFormProvider } from '@Features/providers/forms/roles/ResetRolePasswordFormProvider';

// Providers
import { CreateProviderFormProvider } from '@Features/providers/forms/providers/CreateProviderFormProvider';
import { SetupProviderFormProvider } from '@Features/providers/forms/providers/SetupProviderFormProvider';

// Rounds
import { CreateRoundConfigFormProvider } from '@Features/providers/forms/rounds/CreateRoundConfigFormProvider';

// AviatorUsers
import { CreateAviatorUserFormProvider } from '@Features/providers/forms/roles/CreateAviatorUserFormProvider';

// AviatorRoles
import { CreateAviatorRoleFormProvider } from '@Features/providers/forms/aviatorRoles/CreateAviatorRoleFormProvider';

const providers: ComponentType<{ children: JSX.Element }>[] = [
  AlertStoreProvider,
  CreateBrandFormProvider,
  CreateBranchFormProvider,
  SetupBranchFormProvider,
  CreateCategoryFormProvider,
  SetupCategoryFormProvider,
  CreateVehicleFormProvider,
  CreateVehicleNoteFormProvider,
  SetupVehicleFormProvider,
  CreateIotModuleFormProvider,
  SetupIotModuleFormProvider,
  CreateVehicleIotLinkFormProvider,
  SetupCustomerFormProvider,
  GoogleMapsLoaderProvider,
  CreateCustomerNoteFormProvider,
  DeleteCustomerFormProvider,
  CreateRentalsNoteFormProvider,
  RentalEndRideFormProvider,
  CreatePricingFormProvider,
  SetupPricingFormProvider,
  BalanceModalDialogFormProvider,
  TransactionRefundFormProvider,
  LinkIoTFormProvider,
  ReplaceQRFormProvider,
  SetupAddUserFormProvider,
  SetupRoleFormProvider,
  ResetRolePasswordFormProvider,
  CreateProviderFormProvider,
  SetupProviderFormProvider,
  CreateRoundConfigFormProvider,
  CreateAviatorUserFormProvider,
  CreateAviatorRoleFormProvider,
];

type Props = { children: JSX.Element | JSX.Element[] };

export const StoreContainer: React.FC<Props> = ({ children }) => {
  return (
    <ComposeProvider providers={providers}>
      <>{children}</>
    </ComposeProvider>
  );
};
