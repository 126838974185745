import { useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import { setColumnVisibility } from '@Modal/reducers/providers/table/providerTableSlice';

// Types
import { StoreInterface } from 'src/app/types';

export const useProvidersTable = () => {
  const dispatch = useDispatch();

  const { columnVisibility, columnVisibilityOptions, selectedFilters } = useSelector(
    (state: StoreInterface) => state?.providersTable
  );

  const providersListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);
    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...providersListFilters, ...column];
    if (providersListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = providersListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  return {
    // State
    selectedFilters,
    columnVisibility,
    providersListFilters,
    visiblityCustomizable,
    columnVisibilityOptions,
    // Actions
    handleApplyColumnVisibility,
  };
};
