import { useState } from 'react';
import { Stack, Box, Dialog } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

type MapMediaProps = {
  open: boolean;
  name?: string;
  onClose: (arg: null) => void;
  imgSrc: string;
};

const MapMediaDialog: React.FC<MapMediaProps> = ({ open, name, imgSrc, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      hideBackdrop
      data-at-modal={name}
      sx={{
        marginBottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.32)',
        overflow: 'visible !important',
        '.MuiDialog-paperFullScreen': {
          background: 'rgba(0, 0, 0, 0.78)',
          padding: '20px',
          overflow: 'hidden',
        },
      }}
    >
      <Box
        role="button"
        onClick={() => onClose(null)}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#DFE3E8',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
          borderRadius: '8px',
        }}
        alignSelf={'flex-end'}
      >
        <AssetIcon src="x-close" size="xxxl" />
      </Box>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
        }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <img
          src={imgSrc}
          width={640}
          height={640}
          style={{
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
      </Box>
    </Dialog>
  );
};

export const RentalsMapMedia = () => {
  const [selectedMapMedia, setSelectedMapMedia] = useState<string | null>(null);
  const { selectedRental } = useRentalsDrawer();

  return (
    <Stack
      sx={{
        height: '270px',
        objectFit: 'contain',
        width: '100%',
      }}
    >
      <Box
        onClick={() =>
          setSelectedMapMedia(
            `${process.env.REACT_APP_MEDIA_PROXY_URL}/rental-locations/${selectedRental.id}.png`
          )
        }
        sx={{ borderRadius: '8px', width: '100%', height: '100%', cursor: 'pointer' }}
      >
        <img
          alt="rental map Image"
          width="565px"
          height="280px"
          src={`${process.env.REACT_APP_MEDIA_PROXY_URL}/rental-locations/${selectedRental.id}.png`}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.style.display = 'none';
          }}
        />
      </Box>
      <MapMediaDialog
        name="Selected Rental Media"
        open={!!selectedMapMedia}
        onClose={() => setSelectedMapMedia(null)}
        imgSrc={selectedMapMedia ? selectedMapMedia : ''}
      />
    </Stack>
  );
};
