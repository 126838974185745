import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { TableCell, TableCellProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = DataAttributeProps & {
  children: JSX.Element | JSX.Element[] | number | string | undefined;
  sx?: SystemStyleObject<Theme>;
  align?: TableCellProps['align'];
};
export const TableCellEx: React.FC<Props> = ({ children, sx, align = 'left', ...dataProps }) => {
  return (
    <TableCell sx={[{ borderBottom: 'inherit' }, sx ?? {}]} {...dataProps} align={align ?? 'left'}>
      {children}
    </TableCell>
  );
};
