import { Button, Stack, Typography, useTheme } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useLinkVehicleIot } from '@Modal/hooks/vehicles/vehicles/useLinkVehicleIot';

export const IotNotAssigned: React.FC = () => {
  const { palette } = useTheme();

  const { handleShowIOTForm } = useLinkVehicleIot();

  return (
    <Stack direction="column" alignItems="center" sx={{ paddingTop: 5 }}>
      <AssetIcon
        src="iot-link"
        sx={{
          width: '56px',
          height: '56px',
          marginBottom: 2.5,
          bgcolor: palette.action.disabled,
        }}
      />
      <AtLocator type="readonly-text" name="iotNotLinkedHeader">
        <Typography variant="h6">IoT module is not linked to the vehicle</Typography>
      </AtLocator>
      <AtLocator type="readonly-text" name="iotNotLinkedBody">
        <Typography variant="body2">You haven’t linked any IoT to this current vehicle</Typography>
      </AtLocator>
      <AtLocator type="button" name="iotLink">
        <Button
          variant="contained"
          type="submit"
          sx={{ marginTop: 2 }}
          onClick={() => handleShowIOTForm(true)}
        >
          Link
        </Button>
      </AtLocator>
    </Stack>
  );
};
