import { ObjectSchema, object, string, number, array, boolean } from 'yup';

import { useMemo } from 'react';
import { SetupBranchFormState } from './SetupBranchFormContext';
import { GeoPointDto } from '@contracts/api/dto/common/GeoPointDto';

export const useSetupBranchDrawerFormSchema = (): ObjectSchema<Partial<SetupBranchFormState>> => {
  return useMemo(
    () =>
      object<SetupBranchFormState>().shape({
        id: string().required('Id branch is required.'),
        brandId: string().required('Brand Id is required.'),
        name: string()
          .required('Name is required.')
          .matches(/([A-Z]?)\w+\s?/, 'Field name should not include special symbols'),
        level: number().required('Level is required.'),
        type: string().nullable(),
        status: string().nullable(),
        code: string().nullable(),
        timezone: string().nullable(),
        vat: number()
          .transform((value) => (isNaN(value) ? null : value))
          .nullable()
          .min(0, 'VAT percentage must be a positive number')
          .max(100, 'VAT percentage must be lower than 100')
          .typeError('Must be a number'),
        geoLocation: object<GeoPointDto>()
          .shape({
            lon: number()
              .min(-180)
              .max(180)
              .typeError('Must be a number')
              .required('This field is mandatory'),
            lat: number()
              .min(-90)
              .max(90)
              .typeError('Must be a number')
              .required('This field is mandatory'),
          })
          .nullable()
          .test({
            name: 'Custom GeoPoint validation',
            test: function test(value) {
              if (!value?.lon && value?.lat) {
                return this.createError({
                  message: 'Longitude is required.',
                  path: 'geoLocation.lon',
                });
              }
              if (value?.lon && !value?.lat) {
                return this.createError({
                  message: 'Latitude is required.',
                  path: 'geoLocation.lat',
                });
              }
              return true;
            },
          })
          .nullable(),
        parentId: string().required('Parent branch is required.'),
        mapZoomLevel: number().typeError('Must be a number').nullable(),
        currency: string().nullable(),
        categories: array().of(
          object().shape({
            id: string().required(),
            branchId: string(),
            hidden: boolean().required(),
          })
        ),
        mapZones: array(),
      }),
    []
  );
};
