// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { getActivity, setActivity } from '@Modal/reducers/vehicles/Activity/activitySlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useVehiclesDrawer } from '../vehicles/useVehicleDrawer';
import { useEffect } from 'react';

export const useActivity = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { activity } = useSelector((state: StoreInterface) => state?.activity);
  const { selectedVehicleData } = useVehiclesDrawer();

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleGetActivity = () => {
    if (selectedVehicleData) {
      dispatch(getActivity(selectedVehicleData.id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  useEffect(() => {
    handleGetActivity();
  }, []);

  return {
    // Store
    activity,

    // Actions
    setActivity,
    handleGetActivity,
  };
};
