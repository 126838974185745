import { Dispatch, createContext } from 'react';
import { noop } from '@utils/noop';

import { SetupCategoryUseCaseStatus } from './SetupCategoryUseCaseStatus';

type SetupCategoryUseCaseStatusContextType = {
  status: SetupCategoryUseCaseStatus;
  setStatus: Dispatch<SetupCategoryUseCaseStatus>;
  open: boolean;
  setOpen: Dispatch<boolean>;
};

export type SetupCategoryDialogParams = {
  categoryId: string;
  brandId: string;
};

const setupCategoryUseCaseStatusContextDefault: SetupCategoryUseCaseStatusContextType = {
  status: SetupCategoryUseCaseStatus.Initial,
  setStatus: noop,
  open: false,
  setOpen: noop,
};

export const SetupCategoryUseCaseStatusContext =
  createContext<SetupCategoryUseCaseStatusContextType>(setupCategoryUseCaseStatusContextDefault);
