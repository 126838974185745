import { SetupIotModuleFormState } from '@Forms/vehicles/SetupIotModuleFormContext';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { UpdateIotModuleDto } from '@contracts/api/dto/vehicles/iot/UpdateIotModuleDto';
import { IotItem } from '@Modal/reducers/vehicles/types';

export const useSetupIotModuleUseCaseMapper = (iot: IotItem) => {
  return {
      toFormState: async (): Promise<SetupIotModuleFormState | undefined> => {
      return {
        // @ts-ignore
        id: iot.id,
        iotType: iot.iotType,
        host: iot.host,
        port: iot.port,

        username: iot.username,
        password: iot.password,
      };
    },
    toDto: (formState: SetupIotModuleFormState): UpdateRequest<UpdateIotModuleDto> => {
      return {
        id: formState.id,
        body: {
          iotType: formState.iotType,
          host: formState.host,
          port: formState.port,
          username: formState.username,
          password: formState.password,
        },
      };
    },
  };
};
