// System
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';
import { setShowOperatorDeleteNoteModal } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Types
import { AppDispatch } from 'src/app/store';

export const SetupOperatorDeleteNoteDrawer = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { showOperatorDeleteNoteModal, deleteOperatorNoteHandle } = useOperatorVehicles();

  return (
    <ClosableModal
      name="SetupOperatorDeleteNoteDrawer"
      title="Delete note"
      open={showOperatorDeleteNoteModal}
      onClose={() => dispatch(setShowOperatorDeleteNoteModal(false))}
      sx={{ width: '90%' }}
    >
      <CreateDialogLayout>
        <Typography variant="body1">You are about to delete this note permanently</Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => dispatch(setShowOperatorDeleteNoteModal(false))}
            >
              Cancel
            </Button>
          </AtLocator>
          <Button variant="contained" color="error" onClick={deleteOperatorNoteHandle}>
            Delete
          </Button>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
