import { Stack, Typography } from '@mui/material';

// Redux
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

// Styles
import Styles from './PricingBreakdown.module.scss';

export const PricingBreakdown = () => {
  const { selectedRentalPriceBreakdown } = useRentalsDrawer();
  const { unlockPrice, ridePrice, duration, price, pausePrice, rideDuration, pauseDuration } =
    selectedRentalPriceBreakdown;

  return (
    <Stack className={Styles.PricingBreakdown}>
      {/* Cost Breakdown */}
      <Stack className={Styles.BreakdownContainer}>
        <Typography className={Styles.Header}>Cost Breakdown</Typography>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Unlock</Typography>
          <Typography className={Styles.ValueFont}>{unlockPrice ? unlockPrice : 'N/A'}</Typography>
        </Stack>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Driving</Typography>
          <Typography className={Styles.ValueFont}>{ridePrice ? ridePrice : 'N/A'}</Typography>
        </Stack>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Parking</Typography>
          <Typography className={Styles.ValueFont}>{pausePrice ? pausePrice : 'N/A'}</Typography>
        </Stack>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Total Cost</Typography>
          <Typography className={Styles.ValueFont}>{price ? price : 'N/A'}</Typography>
        </Stack>
      </Stack>

      {/* Time Breakdown */}
      <Stack className={Styles.BreakdownContainer}>
        <Typography className={Styles.Header}>Time Breakdown</Typography>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Driving</Typography>
          <Typography className={Styles.ValueFont}>
            {rideDuration !== undefined && rideDuration !== null ? rideDuration : 'N/A'}
          </Typography>
        </Stack>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Parking</Typography>
          <Typography className={Styles.ValueFont}>
            {pauseDuration !== undefined && pauseDuration !== null ? pauseDuration : 'N/A'}
          </Typography>
        </Stack>
        <Stack className={Styles.LabelValueContainer}>
          <Typography className={Styles.LabelFont}>Total Duration</Typography>
          <Typography className={Styles.ValueFont}>
            {duration !== undefined && duration !== null ? duration : 'N/A'}
          </Typography>
        </Stack>
      </Stack>
      {/* Payment add when backend api will include this info*/}
      {/* <Stack direction="column" spacing={1.7}>
        <Typography variant="subtitle2" color="black">
          Payment
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" data-at-readnly-text="Label" color="#637381">
            Paid amount
          </Typography>
          <Typography variant="body2" data-at-readnly-text="Value" color="#637381">
            10.00 GEL
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" data-at-readnly-text="Label" color="#637381">
            Paid after discount
          </Typography>
          <Typography variant="body2" data-at-readnly-text="Value" color="#637381">
            8.00 GEL
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" data-at-readnly-text="Label" color="#637381">
            Paid via
          </Typography>
          <Typography variant="body2" data-at-readnly-text="Value" color="#637381">
            Bank Card / Wallet
          </Typography>
        </Stack>
      </Stack> */}
    </Stack>
  );
};
