import { delay } from '@utils/delay';
import { useEffect, useRef } from 'react';

export const useAutoFocus = <ElementType extends HTMLElement>(shown: boolean) => {
  const ref = useRef<ElementType>(null);
  useEffect(() => {
    if (!shown) {
      return;
    }
    const action = async () => {
      await delay(10);
      const node = ref.current;

      if (node && typeof node.focus === 'function') {
        node.focus();
      }
    };

    action();
  }, [shown, ref]);
  return ref;
};
