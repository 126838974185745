// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  getCustomerRentals,
  setCustomerRentals,
  setCustomerRentalPagination,
} from '@Modal/reducers/customers/rentals/customerRentalsSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useCustomersDrawer } from '../useCustomerDrawer';

export const useCustomerRentals = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { customerRentals, pagination, customerRentalPagination } = useSelector(
    (state: StoreInterface) => state?.customerRentals
  );

  const { selectedCustomerData } = useCustomersDrawer();

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleGetCustomerRentals = () => {
    if (selectedCustomerData) {
      dispatch(
        getCustomerRentals({ id: selectedCustomerData.id, offset: customerRentalPagination })
      )
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  return {
    // Store
    pagination,
    customerRentals,
    customerRentalPagination,
    // Actions
    setCustomerRentals,
    handleGetCustomerRentals,
    setCustomerRentalPagination,
  };
};
