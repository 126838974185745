import { Button } from '@mui/material';
import FormProvider from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';

import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';

import { CreateAviatorRoleContent } from './CreateAviatorRoleContent';

import { useCreateAviatorRoleForm } from '@Forms/aviatorRoles/CreateAviatorRoleFormContext';
import { useCreateAviatorRole } from '@Modal/hooks/aviatorRoles/useCreateAviatorRole';

export const CreateAviatorRoleForm = () => {
  const methods = useCreateAviatorRoleForm();
  const { handleCreateCancel, submittable, handleSubmitCreateAviatorRole } = useCreateAviatorRole();

  return (
    <FormProvider methods={methods}>
      <CreateDialogLayout>
        <CreateAviatorRoleContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateAviatorRole}
            >
              Confirm
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
