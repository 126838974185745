import { useState } from 'react';
import { Stack, Typography, Box, Dialog } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

type MediaProps = {
  open: boolean;
  name?: string;
  onClose: (arg: null) => void;
  imgSrc: string;
};

const MediaDialog: React.FC<MediaProps> = ({ open, name, imgSrc, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      hideBackdrop
      data-at-modal={name}
      sx={{
        marginBottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.32)',
        overflow: 'visible !important',
        '.MuiDialog-paperFullScreen': {
          background: 'rgba(0, 0, 0, 0.78)',
          padding: '20px',
          overflow: 'hidden',
        },
      }}
    >
      <Box
        role="button"
        onClick={() => onClose(null)}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#DFE3E8',
          width: '40px',
          height: '40px',
          cursor: 'pointer',
          borderRadius: '8px',
        }}
        alignSelf={'flex-end'}
      >
        <AssetIcon src="x-close" size="xxxl" />
      </Box>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
        }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <img
          src={imgSrc}
          width={640}
          height={640}
          style={{
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
      </Box>
    </Dialog>
  );
};

export const RentalsMedia = () => {
  const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
  const { selectedRental } = useRentalsDrawer();

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body2" data-at-readnly-text="Label" color="#637381">
        Media
      </Typography>
      <Stack spacing={1}>
        <Box
          onClick={() =>
            setSelectedMedia(
              `${process.env.REACT_APP_MEDIA_PROXY_URL}/rental-end/${selectedRental.id}.png`
            )
          }
          sx={{ borderRadius: '8px', width: '124px', height: '124px', cursor: 'pointer' }}
        >
          <img
            style={{
              objectFit: 'cover',
              borderRadius: '8px',
            }}
            alt="vehicle Image"
            width="124px"
            height="124px"
            src={`${process.env.REACT_APP_MEDIA_PROXY_URL}/rental-end/${selectedRental.id}.png`}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.style.display = 'none'; // Hide the image element on error
            }}
          />
        </Box>
      </Stack>
      <MediaDialog
        name="Selected Rental Media"
        open={!!selectedMedia}
        onClose={() => setSelectedMedia(null)}
        imgSrc={selectedMedia ? selectedMedia : ''}
      />
    </Stack>
  );
};
