import { Grid } from '@mui/material';

import { RHFSwitch } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const EndRentalRequirements = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={0} columns={8} justifyContent="flex-start">
      <Grid item xs={4}>
        <AtLocator type="special-field" name="VehicleOperationalArea">
          <RHFSwitch label="Vehicle is in Operational area" name="vehicleIsInOperationalArea" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="VehicleNotInParking">
          <RHFSwitch label="Vehicle is not in No Parking area" name="vehicleIsNotInNoParkingArea" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="vehicleIsCharging">
          <RHFSwitch label="Vehicle is charging" name="vehicleIsCharging" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="speedIsZero">
          <RHFSwitch label="Speed is zero" name="speedIsZero" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Ignition">
          <RHFSwitch
            label="Ignition must be turned off after ending rental"
            name="ignitionMustBeTurnedOffAfterEndingRental"
          />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="ignitionMustBeSwitchedOff">
          <RHFSwitch label="Helmet(s) detected" name="helmetsDetected" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="helmetsDetected">
          <RHFSwitch label="Bike lock is locked" name="bikeLockIsLocked" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="ignitionMustBeSwitchedOffToEndRental">
          <RHFSwitch
            label="Ignition must be switched off to end rental"
            name="ignitionMustBeSwitchedOffToEndRental"
          />
        </AtLocator>
      </Grid>
    </Grid>
  );
};
