import React from 'react';
import { Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type props = {
  items: {
    value: string;
    displayName: string;
  }[];
  activeItem: string;
  handleChange: any;
  sx?: SystemStyleObject<Theme>;
};

export const ButtonGroup = ({ activeItem, handleChange, items, sx }: props) => {
  return (
    <ToggleButtonGroup value={activeItem} onChange={handleChange} exclusive>
      {items.map((item) => {
        return (
          <ToggleButton
            value={item.value}
            key={item.value}
            sx={{
              flex: '1',
              ...sx,
            }}
          >
            {item.displayName}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
