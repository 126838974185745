import { TextField, Typography, Box } from '@mui/material';

export const BillingInformation: React.FC = () => {
  return (
    <Box overflow="visible">
      <Box display="flex" padding={2} flexDirection="column">
        <Typography
          variant="button"
          gutterBottom
          sx={{ margin: '0', marginBottom: '15px', marginTop: '15px' }}
        >
          Legal info
        </Typography>
        <TextField
          id="outlined-basic"
          label="Legal entity name"
          variant="outlined"
          sx={{ marginBottom: '11px', height: '' }}
        />
        <TextField
          id="outlined-basic"
          label="VAT number"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField
          id="outlined-basic"
          label="Registration Number"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField id="outlined-basic" label="IBAN" variant="outlined" />
      </Box>

      <Box display="flex" padding={2} flexDirection="column">
        <Typography
          variant="button"
          gutterBottom
          sx={{ margin: '0', marginTop: '25px', marginBottom: '15px' }}
        >
          Billing address
        </Typography>
        <TextField id="outlined-basic" label="Currency symbol" variant="outlined" />
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ marginTop: '4px', marginLeft: '9px', color: '#637381' }}
        >
          Used to configure the currency symbol on the invoice. This won&apos;t change the
          branch&apos;s currency.
        </Typography>
        <TextField
          id="outlined-basic"
          label="Address"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField
          id="outlined-basic"
          label="City"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField
          id="outlined-basic"
          label="Postal code"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField
          id="outlined-basic"
          label="Country"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
      </Box>

      <Box display="flex" padding={2} flexDirection="column">
        <Typography
          variant="button"
          gutterBottom
          sx={{ margin: '0', marginBottom: '15px', marginTop: '15px' }}
        >
          Contact info
        </Typography>
        <TextField
          id="outlined-basic"
          label="Phone number"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField
          id="outlined-basic"
          label="E-mail address"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
        <TextField
          id="outlined-basic"
          label="Website url"
          variant="outlined"
          sx={{ marginBottom: '11px' }}
        />
      </Box>
    </Box>
  );
};
