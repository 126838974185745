import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Types
import { CreateRentalNoteType, RentalNoteType } from './types';

// Utils
import { axiosRentalsInstance } from '@utils/axiosInstances';

// POST Notes

export const createRentalNote = createAsyncThunk(
  'rentals/createRentalsNote',
  async (noteData: CreateRentalNoteType, thunkApi) => {
    return axiosRentalsInstance
      .post(`rentals/${noteData.id}/notes`, {
        note: noteData.note,
        authorId: noteData.authorId,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.response?.data.message);
      });
  }
);

// GET Notes
export const getRentalNotes = createAsyncThunk(
  'rentals/getRentalsNotes',
  async (rentalId: string, thunkApi) => {
    return axiosRentalsInstance
      .get(`rentals/${rentalId}/notes`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.response?.data.message);
      });
  }
);

// DELETE Note
export const deleteRentalsNote = createAsyncThunk(
  'rentals/deleteRentalsNotes',
  async (noteId: string, thunkApi) => {
    return axiosRentalsInstance
      .delete(`rentals/notes/${noteId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.response?.data.message);
      });
  }
);

// Type
const initialState = {
  showCreateRentalNoteForm: false,
  showDeleteRentalNoteForm: false,
  rentalNotes: [],
  selectedRentalNoteId: null,
};

const rentalsNoteSlice = createSlice({
  name: 'rentalsNote',
  initialState,
  reducers: {
    setShowCreateRentalNoteForm: (state, { payload }) => {
      state.showCreateRentalNoteForm = payload;
    },
    setShowDeleteRentalNoteForm: (state, { payload }) => {
      state.showDeleteRentalNoteForm = payload.status;
      state.selectedRentalNoteId = payload.noteId;
    },
  },
  extraReducers: {
    [createRentalNote.fulfilled?.type]: (state, action) => {
      const updatedRentalNotes = [action.payload, ...state.rentalNotes];

      // @ts-ignore
      state.rentalNotes = updatedRentalNotes;
      state.showCreateRentalNoteForm = false;
    },
    [getRentalNotes.fulfilled?.type]: (state, action) => {
      const { items } = action.payload;
      state.rentalNotes = items;
    },
    [deleteRentalsNote.fulfilled?.type]: (state, action) => {
      const { rentalNotes, selectedRentalNoteId } = state;
      const updatedRentalNotes = [...rentalNotes].filter(
        (note: RentalNoteType) => note.id !== selectedRentalNoteId
      );
      state.rentalNotes = updatedRentalNotes;
      state.selectedRentalNoteId = null;
      state.showDeleteRentalNoteForm = false;
    },
  },
});

export const { reducer: rentalsNoteReducer } = rentalsNoteSlice;
export const { setShowCreateRentalNoteForm, setShowDeleteRentalNoteForm } =
  rentalsNoteSlice.actions;
