import {
  defaultAnalyticListFilter,
  analyticListColumnVisibilityOptions,
  defaultAnalyticListColumnVisibility,
} from '@utils/tables/analytics/analyticListFilter';

// System
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Features
import { DateGeneratorForAnalyticsTable } from '@Features/analytics/DateGeneratorForAnalyticsTable/DateGeneratorForAnalyticsTable';
const { startDate, endDate } = DateGeneratorForAnalyticsTable();

// Type
const initialState = {
  selectedFilters: defaultAnalyticListFilter,
  columnVisibility: defaultAnalyticListColumnVisibility,
  columnVisibilityOptions: analyticListColumnVisibilityOptions,
  analyticTableFilters: {},
  totalNumOfSpins: 0,
  totalNumOfUsers: 0,
  totalBetAmount: 0,
  totalWonAmount: 0,
  totalNumOfRounds: 0,
  tableFilters: {
    tableStartDate: startDate,
    tableEndDate: endDate,
  },
  chartValue: 'totalBetAmount',
  chartXAxis: [],
  chartYAxis: [],
};

const analyticTableSlice = createSlice({
  name: 'analyticTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setanalyticTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { analyticTableFilters } = state;

      const newanalyticTableFilters = { ...analyticTableFilters, [codeName]: value };
      state.analyticTableFilters = newanalyticTableFilters;
    },
    setTotalNumOfSpins: (state, { payload }) => {
      state.totalNumOfSpins = payload;
    },
    setTotalNumOfUsers: (state, { payload }) => {
      state.totalNumOfUsers = payload;
    },
    setTotalBetAmount: (state, { payload }) => {
      state.totalBetAmount = payload;
    },
    setTotalWonAmount: (state, { payload }) => {
      state.totalWonAmount = payload;
    },
    setTotalNumOfRounds: (state, { payload }) => {
      state.totalNumOfRounds = payload;
    },
    setTableFilters: (state, { payload }) => {
      const { tableFilters } = state;
      const { codeName, value } = payload;
      const newTableFilters = { ...tableFilters, [codeName]: value };
      state.tableFilters = newTableFilters;
    },
    setChartXAxis: (state, { payload }: PayloadAction<number[]>) => {
      if (
        Array.isArray(payload) &&
        payload.every((date) => typeof date === 'number' && !isNaN(date))
      ) {
        // @ts-ignore
        state.chartXAxis = payload;
      }
    },
    setChartYAxis: (state, { payload }: PayloadAction<number[]>) => {
      if (
        Array.isArray(payload) &&
        payload.every((value) => typeof value === 'number' && !isNaN(value))
      ) {
        // @ts-ignore
        state.chartYAxis = payload;
      } else {
        console.warn('Invalid chartYAxis data:', payload);
      }
    },
    setChartValue: (state, { payload }) => {
      state.chartValue = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: analyticTableReducer } = analyticTableSlice;
export const {
  setChartYAxis,
  setChartXAxis,
  setChartValue,
  setTableFilters,
  setTotalWonAmount,
  setTotalBetAmount,
  setTotalNumOfSpins,
  setTotalNumOfUsers,
  setColumnVisibility,
  setTotalNumOfRounds,
  setanalyticTableFilters,
} = analyticTableSlice.actions;
