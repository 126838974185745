import React from 'react';
import { Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import useResponsive from '@Hooks/shared/useResponsive';

type RenderTruncatedTextProps = {
  text: string;
  maxLength: number;
  textStyles?: React.CSSProperties;
  buttonStyles?: React.CSSProperties;
};

const TruncateText: React.FC<RenderTruncatedTextProps> = ({
  text,
  maxLength,
  textStyles,
  buttonStyles,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive('down', 'sm');

  const truncatedText = text?.length <= maxLength ? text : text?.slice(0, maxLength) + '...';

  const handleCopyClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied to clipboard', {
      autoHideDuration: 1000,
      anchorOrigin: {
        vertical: isMobile ? 'top' : 'bottom',
        horizontal: isMobile ? 'center' : 'right',
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
      <Typography
        variant="body2"
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...textStyles }}
      >
        {truncatedText}
      </Typography>
      {text && (
        <Box
          onClick={handleCopyClick}
          sx={{
            backgroundColor: '#F4F6F8',
            color: '#637381',
            padding: '6px',
            cursor: 'pointer',
            borderRadius: '8px',
            ...buttonStyles,
          }}
        >
          <Typography sx={{ fontSize: '12px', lineHeight: '16px', fontWeight: '600' }}>
            Copy
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TruncateText;
