// System
import React from 'react';
import { useState, useEffect } from 'react';

// Components
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { Box } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { RentalTransactionCard } from './RentalTransactionCard';

// Redux
import { useTransactions } from '@Modal/hooks/transactions/useTransactions';
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';

// Styles
import Styles from './RentalTransactions.module.scss';

export const RentalTransactions: React.FC = () => {
  const { rentalTransactions, handleRentalTransactions } = useTransactions();
  const { handleTransactionRowClick } = useTransactionsDrawer();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    handleRentalTransactions();
    setLoading(false);
  }, []);

  const handleBoxClick = (id: string) => {
    handleTransactionRowClick(id);
  };

  return (
    <>
      <DrawerTabPanelLayout>
        <Box className={Styles.TransactionsContainer}>
          {rentalTransactions.length > 0 ? (
            rentalTransactions?.map((transaction) => (
              <Box
                className={Styles.RentalTransaction}
                onClick={() => handleBoxClick(transaction.externalTransactionId)}
              >
                <RentalTransactionCard
                  id={transaction?.id}
                  currency={transaction?.currency}
                  amount={transaction?.amount}
                  date={transaction?.createdAt}
                  status={transaction?.status}
                />
              </Box>
            ))
          ) : (
            <Box className={Styles.NoResultsContainer}>No Results</Box>
          )}
        </Box>
      </DrawerTabPanelLayout>
      {loading && <AssetIcon src="rental-loading" className={Styles.LoadingIcon} />}
    </>
  );
};
