// System
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Theme } from '@mui/material';
import { Box, SystemStyleObject } from '@mui/system';

// Components
import MenuPopover from '@Components/menu-popover';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';

// Component hooks
import { usePopOvers } from '@Components/shared/hooks/usePopOvers';

// Redux
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

// Features
import { useVehicleServiceStateTitle } from '@Features/vehicles/shared/VehicleServiceState';

// Styles
import Styles from './VehicleServiceLabel.module.scss';
import { useActivity } from '@Modal/hooks/vehicles/activity/useActivity';

// Types
type Props = {
  value: string | null;
  sx?: SystemStyleObject<Theme>;
  id: string;
};

export const VehicleServiceLabel: React.FC<Props> = ({ value, id, sx }) => {
  const dispatch = useDispatch();

  const { vehicleServiceStates } = useGeneralStore();
  const {
    selectedVehicleServiceState,
    setSelectedVehicleServiceState,
    selectedVehicleData,
    handleServiceStateUpdate,
  } = useVehiclesDrawer();
  const { handleGetActivity } = useActivity();

  const { open, openPopover, closePopover } = usePopOvers();

  const item = useMemo(() => {
    return vehicleServiceStates?.find(({ key }) => key === value);
  }, [vehicleServiceStates, value]);
  const DropDown = useMemo(
    () =>
      (vehicleServiceStates ?? []).map(({ key, value: vehicleValue }) => (
        <MenuItemEx
          key={key}
          value={key}
          name={key}
          onClick={(e) => {
            dispatch(setSelectedVehicleServiceState(key));
            handleServiceStateUpdate({
              vehicleID: selectedVehicleData.id,
              vehicleServiceState: key,
            });
            setTimeout(() => {
              handleGetActivity();
            }, 1000);
            closePopover(e);
          }}
          className={Styles.ServiceStateCTA}
        >
          {vehicleValue}
        </MenuItemEx>
      )),
    [vehicleServiceStates, closePopover, id, selectedVehicleServiceState]
  );

  if (!item) {
    return null;
  }

  return (
    <Box className={Styles.VehicleServiceLabel}>
      <AtLocator type="button" name="BranchLabel">
        <Button
          variant="contained"
          endIcon={<AssetIcon src="chevron-down" size="s" />}
          onClick={openPopover}
          className={Styles.ServiceStateCTA}
        >
          {useVehicleServiceStateTitle()[selectedVehicleServiceState ?? 'UNKNOWN']}
        </Button>
      </AtLocator>
      <MenuPopover open={open} onClose={closePopover} disabledArrow sx={{ marginY: 1 }}>
        {DropDown}
      </MenuPopover>
    </Box>
  );
};
