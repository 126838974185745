import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const roleListColumns = [
  'role.id',
  'role.firstName',
  'role.lastName',
  'role.email',
  'role.role',
  'role.brand',
  'role.secondLevelBranch',
  'role.thirdLevelBranch',
] as const;

export type RoleListColumns = (typeof roleListColumns)[number];

export type RoleListFilter = {
  'role.id': string | undefined;
  'role.firstName': string | undefined;
  'role.lastName': string | undefined;
  'role.email': string | undefined;
  'role.role': string | undefined;
  'role.brand': string | undefined;
  'role.secondLevelBranch': string | undefined;
  'role.thirdLevelBranch': string | undefined;
  limit: number;
};

export const defaultRoleListFilter: RoleListFilter = {
  'role.id': undefined,
  'role.firstName': undefined,
  'role.lastName': undefined,
  'role.email': undefined,
  'role.role': undefined,
  'role.brand': undefined,
  'role.secondLevelBranch': undefined,
  'role.thirdLevelBranch': undefined,
  limit: 25,
};

export const roleListColumnVisibilitySettings = {
  'role.id': ColumnVisibilityType.Visible,
  'role.firstName': ColumnVisibilityType.Visible,
  'role.lastName': ColumnVisibilityType.Visible,
  'role.email': ColumnVisibilityType.Visible,
  'role.role': ColumnVisibilityType.Visible,
  'role.brand': ColumnVisibilityType.Visible,
  'role.secondLevelBranch': ColumnVisibilityType.Visible,
  'role.thirdLevelBranch': ColumnVisibilityType.Visible,
} satisfies ColumnVisibilitySettings<RoleListColumns>;

export const defaultRoleListColumnVisibility = defaultColumnVisibilityParams(
  roleListColumnVisibilitySettings
);
export const roleListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  roleListColumns,
  roleListColumnVisibilitySettings
);
