import { SetupBranchFormState } from './SetupBranchFormContext';
import { BranchItemDto } from '@contracts/api/dto/branches/BranchItemDto';
import { UpdateBranchDto } from '@contracts/api/dto/branches/UpdateBranchDto';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { AssignedVehicleCategoryItemDto } from '@contracts/api/dto/vehicles/categories/AssignedVehicleCategoryItemDto';

export const useBranchDrawerMapper = () => {
  return {
    toFormState: (
      branch: BranchItemDto,
      brandId: string,
      assignedCategories: AssignedVehicleCategoryItemDto[]
    ): SetupBranchFormState => {
      return {
        id: branch.id,
        brandId,
        name: branch.name,
        level: branch.level,
        type: branch?.type ?? null,
        status: branch?.status ?? null,
        code: branch?.code ?? null,
        timezone: branch?.timezone ?? '',
        vat: branch?.vat ?? null,
        geoLocation: branch?.geoLocation ?? null,
        parentId: branch?.parentId ?? branch.id,
        mapZoomLevel: branch?.mapZoomLevel ?? null,
        currency: branch?.currency ?? null,
        categories: assignedCategories.map(({ id, hidden }) => ({
          id,
          branchId: branch.id,
          hidden: hidden ?? false,
        })),
        mapZones: [],
      };
    },
    toDto: (formState: SetupBranchFormState): UpdateRequest<UpdateBranchDto> => {
      return {
        id: formState.id,
        body: {
          level: formState.level,
          name: formState.name,
          parentId: formState.parentId,
          code: formState.code ?? null,
          currency: formState.currency || null,
          geoLocation:
            formState.geoLocation?.lat && formState.geoLocation?.lon
              ? {
                  lat: formState.geoLocation?.lat,
                  lon: formState.geoLocation?.lon,
                }
              : null,
          mapZoomLevel: formState.mapZoomLevel ?? null,
          timezone: formState.timezone || null,
          vat: formState.vat ?? null,
          categories: formState?.categories || [],
        },
      };
    },
  };
};
