import { Stack } from '@mui/material';

import FormProvider, { RHFSelect, RHFTextField, RHFMultiSelect } from '@Components/hook-form';

import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useSetupAddUserForm } from '@Forms/roles/SetupAddUserFormContext';
import { useEffect, useState } from 'react';
import { ShowablePasswordInputField } from './ShowablePasswordInputField';
import { RolesForAddUser } from '@Pages/roles.enum';
import { BranchesType } from '@Modal/reducers/branches/types';
import { useAddUser } from '@Modal/hooks/roles/useAddUser';

export const AddUser = () => {
  const methods = useSetupAddUserForm();
  const { handleFetchAllBranches, brands, allBranches } = useBranches();
  const { handleSubmitAddUser } = useAddUser();

  useEffect(() => {
    handleFetchAllBranches();
  }, []);

  const [chosenBrands, setChosenBrands] = useState<string[]>([]);
  const [secondLevelBranches, setSecondLevelBranches] = useState<BranchesType[]>([]);
  const [chosen2ndLvlBranches, setChosen2ndLvlBranches] = useState<string[]>([]);
  const [thirdLevelBranches, setThirdLevelBranches] = useState<BranchesType[]>([]);

  const handleBrandChange = (id: string) => {
    if (chosenBrands.includes(id)) {
      const updatedChosenBrands = chosenBrands.filter((brandId) => brandId !== id);
      setChosenBrands(updatedChosenBrands);

      const updatedSecondLevelBranches = secondLevelBranches.filter(
        (branch) => branch.parentId !== id
      );
      setSecondLevelBranches(updatedSecondLevelBranches);
    } else {
      setChosenBrands([...chosenBrands, id]);

      const filteredBranches: BranchesType[] = allBranches.filter(
        (branch) => branch.parentId === id
      );
      setSecondLevelBranches([...secondLevelBranches, ...filteredBranches]);
    }
  };

  const handleSecondLvlBranchChange = (id: string) => {
    if (chosen2ndLvlBranches.includes(id)) {
      const filteredChosen2ndLvlBranches = chosen2ndLvlBranches.filter(
        (branchId) => branchId !== id
      );
      setChosen2ndLvlBranches(filteredChosen2ndLvlBranches);

      const filtered3rdLvlBranches = thirdLevelBranches.filter((branch) => branch.parentId !== id);
      setThirdLevelBranches(filtered3rdLvlBranches);
    } else {
      setChosen2ndLvlBranches([...chosen2ndLvlBranches, id]);

      const filtered3rdLvlBranches = allBranches.filter((branch) => branch.parentId === id);
      setThirdLevelBranches([...thirdLevelBranches, ...filtered3rdLvlBranches]);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmitAddUser}>
      <Stack direction="column" spacing={2} sx={{ padding: '16px 20px' }}>
        <AtLocator type="text-field" name="firstName" key="firstName">
          <RHFTextField id="firstName" name="firstName" label={'First Name'} variant="outlined" />
        </AtLocator>
        <AtLocator type="text-field" name="lastName" key="lastName">
          <RHFTextField id="lastName" name="lastName" label={'Last Name'} variant="outlined" />
        </AtLocator>
        <AtLocator type="text-field" name="email" key="email">
          <RHFTextField id="email" name="email" label={'Email'} variant="outlined" />
        </AtLocator>
        <AtLocator type="select-field" name="role">
          <RHFSelect name="role" label="Role" variant="outlined">
            {Object.entries(RolesForAddUser)?.map(([val, key]) => (
              <MenuItemEx key={key} value={key} name="role">
                {val}
              </MenuItemEx>
            ))}
          </RHFSelect>
        </AtLocator>
        <AtLocator type="select-field" name="brands">
          <RHFMultiSelect
            name="brands"
            label="brand"
            variant="outlined"
            options={brands?.map((brand) => ({
              label: brand.name,
              value: `${brand.id}`,
              onClick: () => handleBrandChange(brand.id),
            }))}
          ></RHFMultiSelect>
        </AtLocator>
        <AtLocator type="select-field" name="secondLevelBranches">
          <RHFMultiSelect
            name="secondLevelBranches"
            label="2nd level branch"
            variant="outlined"
            options={secondLevelBranches?.map((branch) => ({
              label: branch.name,
              value: `${branch.id}`,
              onClick: () => handleSecondLvlBranchChange(branch.id),
            }))}
          ></RHFMultiSelect>
        </AtLocator>
        <AtLocator type="select-field" name="thirdLevelBranches">
          <RHFMultiSelect
            name="thirdLevelBranches"
            label="3rd level branch"
            variant="outlined"
            options={thirdLevelBranches?.map((branch) => ({
              label: branch.name,
              value: `${branch.id}`,
            }))}
          ></RHFMultiSelect>
        </AtLocator>
        <AtLocator type="text-field" name="password" key="password">
          <ShowablePasswordInputField
            id="password"
            name="password"
            label="Password"
            helperText="Password must have at least 8 character, 1 lower case, 1 upper case, 1 number and 1 special character"
          />
        </AtLocator>
      </Stack>
    </FormProvider>
  );
};
