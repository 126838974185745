// System
import { useDropzone } from 'react-dropzone';
import { Stack, Button } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Components
import {
  SingleFileUploadDropzoneLayout,
  SingleFileUploadEmptyPlaceholder,
  SingleFileUploadErrorPlaceholder,
  SingleFileUploadFilledPlaceholder,
} from '@Components/shared/uploads/SingleFileUpload';

// Redux
import { useCSV } from '@Modal/hooks/vehicles/csv/useCSV';

// Styles
import Styles from './UploadIotModulesCSVDialogForm.module.scss';

export const UploadIotModulesCSVDialogForm: React.FC = () => {
  const { handleIoTCSVModal, handleSubmitCSV, csvFile, csvFileErrors, handleSetCSVFile } = useCSV();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    multiple: false,
    disabled: false,
    noClick: true,
    accept: { 'text/csv': ['.csv'] },
    onDrop: (file) => handleSetCSVFile(file[0]),
  });

  return (
    <Stack className={Styles.UploadIotModulesCSVDialogForm}>
      <AtLocator type="container" name="SingleFileUpload">
        <div {...getRootProps()}>
          <SingleFileUploadDropzoneLayout isDragActive={isDragActive} isError={!!csvFileErrors}>
            <input {...getInputProps()} />
            {csvFile === undefined && <SingleFileUploadEmptyPlaceholder onBrowse={open} />}
            {csvFile !== undefined && csvFileErrors === undefined && (
              <SingleFileUploadFilledPlaceholder
                file={csvFile}
                onDelete={() => handleSetCSVFile(undefined)}
              />
            )}
            {csvFile !== undefined && csvFileErrors !== undefined && (
              <SingleFileUploadErrorPlaceholder
                file={csvFile}
                error={csvFileErrors}
                onUpload={open}
              />
            )}
          </SingleFileUploadDropzoneLayout>
        </div>
      </AtLocator>
      <Stack className={Styles.FooterContainer}>
        <Stack className={Styles.BtnsContainer}>
          <AtLocator key="Cancel" type="button" name="Cancel">
            <Button
              variant="outlined"
              color="inherit"
              className={Styles.CancelBtn}
              onClick={() => handleIoTCSVModal(false)}
            >
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="UpdateTable">
            <Button
              type="submit"
              variant="contained"
              disabled={!csvFile}
              onClick={() => handleSubmitCSV('IOT')}
            >
              Update table
            </Button>
          </AtLocator>
        </Stack>
      </Stack>
    </Stack>
  );
};
