import React from 'react';

export const HealthCheck = () => {
  console.log('ENVS', process.env);
  return (
    <div>
      <h1>I am alive!!!!</h1>
    </div>
  );
};
