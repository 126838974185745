import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const useRoundedBorder = (radius: number = 1): SystemStyleObject<Theme> => {
  return {
    borderColor: 'divider',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: radius,
  };
};
