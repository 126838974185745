import React, { useEffect } from 'react';

// System
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { Divider, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

// Hooks
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Components
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// API
import { getVehicleServiceStates } from '@Modal/reducers/generalSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';

// Styles
import Styles from './SetupOperatorServiceStateDrawer.module.scss';

export const SetupOperatorServiceStateDrawer: React.FC = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { vehicleServiceStates } = useGeneralStore();
  const {
    showServiceStateModal,
    handleSetShowServiceStateDrawer,
    handleUpdateOperatorVehicleState,
  } = useOperatorVehicles();

  const { showOperatorScanModal, handleUpdateOperatorMultipleVehiclesState } = useOperatorScan();

  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getVehicleServiceStates())
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 5000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, []);

  return (
    <SetupDrawer
      name="SetupOperatorApproach"
      open={showServiceStateModal}
      onClose={() => handleSetShowServiceStateDrawer(false)}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorServiceStateDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Service State
          </Typography>
          <Box className={Styles.CloseIcon} onClick={() => handleSetShowServiceStateDrawer(false)}>
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <Box className={Styles.ServiceStateCardsContainer}>
          {/* {vehicleServiceStates?.map((serviceState) => {
            if (serviceState?.key !== 'FUNCTIONAL' && serviceState?.key !== 'INSPECT') {
              return (
                <Box
                  className={Styles.ServiceStateCard}
                  key={serviceState?.key}
                  onClick={() =>
                    showOperatorScanModal
                      ? handleUpdateOperatorMultipleVehiclesState(serviceState?.key)
                      : handleUpdateOperatorVehicleState(serviceState?.key)
                  }
                >
                  <Typography>{serviceState?.value}</Typography>
                </Box>
              );
            }
          })} */}
        </Box>
      </Box>
    </SetupDrawer>
  );
};
