import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  SetupProviderFormContext,
  SetupProviderFormState,
  setupProviderFormStateDefault,
  setupProviderSchemaDefault,
} from '@Forms/providers/SetupProviderFormContext';

export const SetupProviderFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<SetupProviderFormState>>>(
    setupProviderSchemaDefault
  );
  const methods = useForm({
    defaultValues: setupProviderFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupProviderFormContext.Provider value={value}>{children}</SetupProviderFormContext.Provider>
  );
};
