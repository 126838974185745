import { Typography } from '@mui/material';
import { useRolesDrawer } from '@Modal/hooks/roles/useRolesDrawer';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';
import { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material';

const useSx = () => {
  return {
    id: { fontSize: '18px', lineHeight: '28px', fontWeight: '800' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupRoleDrawerHeader = () => {
  const { selectedRoleData } = useRolesDrawer();
  const styles = useSx();

  return (
    <Typography>
      {selectedRoleData && (
        <TruncateText
          text={selectedRoleData?.account?.accountDetails[0]?.id}
          maxLength={8}
          textStyles={styles.id}
        />
      )}
    </Typography>
  );
};
