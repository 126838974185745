import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { Button, Typography } from '@mui/material';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useRentalsNote } from '@Modal/hooks/rentals/useRentalsNote';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useSnackbar } from 'notistack';

export const RemoveRentalsNoteModalDialog = () => {
  const {
    showDeleteRentalNoteForm,
    deleteRentalsNote,
    selectedRentalNoteId,
    handleRemoveNoteCancel,
  } = useRentalsNote();

  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteRentalNote = () => {
    dispatch(deleteRentalsNote(selectedRentalNoteId))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error?.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return (
    <ClosableModal
      name="DeleteNote"
      title="Delete note"
      open={showDeleteRentalNoteForm}
      onClose={() => handleRemoveNoteCancel()}
      sx={{ width: '592px' }}
    >
      <CreateDialogLayout>
        <Typography variant="body1">You are about to delete this note permanently</Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={() => handleRemoveNoteCancel()}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRentalNote();
              }}
            >
              Delete
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
