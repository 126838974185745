import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, number } from 'yup';

export type TransactionRefundFormState = {
  refund: number;
};

export const TransactionRefundFormStateDefault: TransactionRefundFormState = {
  refund: 0,
};

export const TransactionRefundSchemaDefault: ObjectSchema<Partial<TransactionRefundFormState>> =
  object<Partial<TransactionRefundFormState>>().shape({
    refund: number()
      .required('Note is required')
      .positive('Refund must be a positive number')
      .moreThan(0, 'Refund must be greater than 0'),
  });

type TransactionRefundContextType = {
  schema: ObjectSchema<Partial<TransactionRefundFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<TransactionRefundFormState>>>;
  methods?: UseFormReturn<TransactionRefundFormState>;
};

const TransactionNoteFormContextDefault = {
  schema: TransactionRefundSchemaDefault,
  setSchema: noop,
} satisfies TransactionRefundContextType;

export const TransactionRefundFormContext = createContext<TransactionRefundContextType>(
  TransactionNoteFormContextDefault
);

export const useTransactionRefundForm = () => {
  const { methods } = useContext(TransactionRefundFormContext);
  if (!methods) {
    throw new Error('TransactionRefundFormContext is empty');
  }

  return methods;
};

export const useManageTransactionRefund = () => {
  const { setSchema } = useContext(TransactionRefundFormContext);

  return { setSchema };
};
