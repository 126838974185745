import {
  providerTransactionListColumnVisibilityOptions,
  defaultProviderTransactionListFilter,
  defaultProviderTransactionListColumnVisibility,
} from '@utils/tables/providerTransaction/providerTransactionListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  limit: 25,
  page: 1,
  total: 0,
  selectedFilters: defaultProviderTransactionListFilter,
  columnVisibility: defaultProviderTransactionListColumnVisibility,
  columnVisibilityOptions: providerTransactionListColumnVisibilityOptions,
  providerTransactionTableFilters: {},
};

const providerTransactionTableSlice = createSlice({
  name: 'providerTransactionTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setProviderTransactionTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { providerTransactionTableFilters } = state;

      const convertedValue = typeof value !== 'object' || value === null ? value : value[0];
      const newProviderTransactionTableFilters = {
        ...providerTransactionTableFilters,
        [codeName]:
          convertedValue === 'true' ? true : convertedValue === 'false' ? false : convertedValue,
      };

      state.providerTransactionTableFilters = newProviderTransactionTableFilters;
    },
  },
  extraReducers: {},
});

export const { reducer: providerTransactionTableReducer } = providerTransactionTableSlice;
export const {
  setColumnVisibility,
  setLimit,
  setPage,
  setTotal,
  setProviderTransactionTableFilters,
} = providerTransactionTableSlice.actions;
