import { Stack, Typography } from '@mui/material';
import { RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

type Props = {
  status: boolean;
};

export const Contact: React.FC<Props> = ({ status }) => {
  const { selectedCustomerData } = useCustomersDrawer();
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <AtLocator type="readonly-text" name="Contact">
          <Typography variant="button">Contact</Typography>
        </AtLocator>
      </Stack>
      <Stack direction="column" spacing={1.5}>
        <AtLocator type="text-field" name="email">
          <RHFTextField
            disabled={selectedCustomerData?.customer?.status === 'blocked'}
            name="email"
            id="setup-customer-email"
            label={selectedCustomerData?.email !== null ? 'Verified Email' : 'Email'}
            variant="outlined"
            defaultValue={
              selectedCustomerData?.email !== null
                ? selectedCustomerData?.email
                : selectedCustomerData?.accountDetails[0]?.secondaryEmail
            }
          />
        </AtLocator>
        <AtLocator type="text-field" name="phoneNumber">
          <RHFTextField
            disabled={selectedCustomerData?.customer?.status === 'blocked'}
            name="phoneNumber"
            id="setup-customer-number"
            label={selectedCustomerData?.phone !== null ? 'Verified Phone Number' : 'Phone Number'}
            variant="outlined"
            defaultValue={
              selectedCustomerData?.phone !== null
                ? selectedCustomerData?.phone
                : selectedCustomerData?.accountDetails[0]?.secondaryPhone
            }
          />
        </AtLocator>
      </Stack>
    </Stack>
  );
};
