import { ComponentProps, ReactElement, cloneElement } from 'react';
import { Button } from '@mui/material';

import { IconicButton } from '@Components/shared/buttons/IconicButton';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Button> | ComponentProps<typeof IconicButton>;

export type AtButtonLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtButtonLocator: React.FC<AtButtonLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-button': name } as DataAttributeProps);
};
