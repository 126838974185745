import { useMemo } from 'react';
import { Box } from '@mui/material';

import {
  SelectBox,
  SelectBoxDropDownLayout,
  SelectBoxProvider,
  useManageSelectBox,
} from '@Components/shared/selects/SelectBox';
import { KeyValue } from '@contracts/view/KeyValue';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { CheckmarkSelectDropDownContent } from '@Components/shared/selects/CheckmarkSelect';

import { ColumnVisibilityTableButton } from '../../actions/ColumnVisibilityTableButton';

type Props<T extends string> = {
  options: KeyValue<T, string>[];
  checkedValues: T[];
  onChange?: (values: T[]) => void;
};

const DropDown = <T extends string>({ options, checkedValues, onChange }: Props<T>) => {
  const ellipsis = useEllipsis();
  const optionViews: KeyValue<T, string | JSX.Element>[] = useMemo(
    () =>
      options.map(({ key, value }) => ({
        key,
        value: <Box sx={{ ...ellipsis, maxWidth: '150px' }}>{value}</Box>,
      })),
    [ellipsis, options]
  );
  return (
    <CheckmarkSelectDropDownContent
      options={optionViews}
      checkedValues={checkedValues}
      // TODO:Refactor when we update filters
      // @ts-ignore
      onChange={onChange}
    />
  );
};

const CheckmarkSelectBox = <T extends string>(props: Props<T>) => {
  const { handleOpen } = useManageSelectBox();
  return (
    <SelectBox
      name="TableColumn"
      input={<ColumnVisibilityTableButton onClick={handleOpen} />}
      dropDown={
        <SelectBoxDropDownLayout sx={{ maxHeight: '500px', width: '200px' }}>
          <DropDown {...props} />
        </SelectBoxDropDownLayout>
      }
    />
  );
};

export const ColumnVisibilityAction = <T extends string>(props: Props<T>) => {
  return (
    <SelectBoxProvider>
      <CheckmarkSelectBox {...props} />
    </SelectBoxProvider>
  );
};
