// System
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';

// Hooks
import useResponsive from '@Hooks/shared/useResponsive';

// Components
import Logo from '@Components/logo';
import Scrollbar from '@Components/scrollbar';
import { NavSectionVertical } from '@Components/nav-section';

// Config
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';

// Styles
import Styles from './NavMini.module.scss';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContentDesktop = (
    <Scrollbar className={Styles.NavMini}>
      <Logo />

      <NavSectionVertical data={navConfig} />
    </Scrollbar>
  );

  const renderContentMobile = (
    <Scrollbar className={Styles.NavVertical}>
      <Logo />

      <NavSectionVertical data={navConfig} />
    </Scrollbar>
  );

  return (
    <Box className={Styles.Container} component="nav">
      <NavToggleButton />

      {isDesktop ? (
        <Drawer open variant="permanent">
          {renderContentDesktop}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {renderContentMobile}
        </Drawer>
      )}
    </Box>
  );
}
