// System
import { Stack, Typography, Box } from '@mui/material';

// Components
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

// Redux
import { usePricingDrawer } from '@Modal/hooks/pricing/usePricingDrawer';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Types
type Props = {
  onClose?: () => void;
};

export const SetupPricingHeader: React.FC<Props> = ({ onClose }: Props) => {
  const ellipsis = useEllipsis();

  const { selectedPricing } = usePricingDrawer();
  const { name } = selectedPricing;

  return (
    <Box
      sx={{
        maxWidth: '100%',
        ...ellipsis,
        display: 'flex',
        width: '100%',
        gap: '12px',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">{name}</Typography>
    </Box>
  );
};
