// System
import React from 'react';
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { useOperatorFilters } from '@Modal/hooks/operator/useOperatorFilters';

// Features
import { OperatorBranches } from '@Features/operator/OperatorBranches';
import { OperatorVehicleServiceState } from '../../OperatorVehicleServiceState';
import { OperatorSimpleFilterTemplate } from '../../OperatorSimpleFilterTemplate';
import { OperatorBatteryLevel } from '@Features/operator/OperatorBatteryLevel';

// Types
import { MapZoneItemType } from '@Modal/reducers/branches/types';
import { BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';
import { useLiveMapFilters } from '@Modal/hooks/vehicles/useLiveMapFilters';
import { HashTable } from '@utils/services/liveMapSocket';

// Styles
import Styles from './SetupOperatorFiltersDrawer.module.scss';

type Props = {
  mapZonesOnMap: MapZoneItemType[];
  originalVehicles: HashTable;
  vehiclesOnMap: HashTable;
  vehiclesLength: number;
  setVehiclesOnMap: (arg: HashTable) => void;
  setFilteredMapZoneList: (arg: MapZoneItemType[]) => void;
};

export const SetupOperatorFiltersDrawer: React.FC<Props> = ({
  mapZonesOnMap,
  originalVehicles,
  vehiclesOnMap,
  vehiclesLength,
  setVehiclesOnMap,
  setFilteredMapZoneList,
}) => {
  const {
    // Actions
    handleFilterByAreaType,
    handleFilterByIoTStatus,
    handleRangeValueChange,
    handleClearLiveMapFilters,
    handleFilterByVehicleType,
    handleFilterByPowerStatus,
    handleFilterByBatteryLevel,
    handleFilterByRentalStatus,
    handleFilterVehiclesByBrand,
    handleFilterVehiclesByBranch,
    handleFilterVehiclesByService,
    handleFilterByBatteryPercentage,
    // Data
    liveMapFilters,
    outOfOrderState,
    operationalStates,
    batteryRangeValue,
  } = useLiveMapFilters(
    mapZonesOnMap,
    originalVehicles,
    vehiclesOnMap,
    setVehiclesOnMap,
    setFilteredMapZoneList
  );
  const { showOperatorFiltersDrawer, handleOperatorFiltersDrawerCancel } = useOperatorFilters();

  const rentalStatusFilterData = liveMapFilters?.rentalStatus.map((rental) => {
    return { ...rental, title: rental.rentalStatusTitle };
  });

  const powerStatusFilterData = liveMapFilters?.powerStatusData.map((pwr) => {
    return { ...pwr, title: pwr.powerStatusTitle };
  });

  const areaTypeFilterData = liveMapFilters?.areaType.map((area) => {
    return { ...area, title: area.title };
  });

  const vehicleTypeData = liveMapFilters?.vehicleType.map((type) => {
    return { ...type, title: type.vehicleTypeTitle };
  });

  const iotStatusFilterData = liveMapFilters?.iotStatus.map((iot) => {
    return { ...iot, title: iot.iotStatusTitle };
  });

  return (
    <SetupDrawer
      name="SetupOperatorFilters"
      open={showOperatorFiltersDrawer}
      onClose={handleOperatorFiltersDrawerCancel}
    >
      <Box className={Styles.SetupOperatorFilters}>
        <Box className={Styles.HeaderContainer}>
          <Box className={Styles.Header}>
            <Typography fontSize={18} fontWeight={900}>
              Filters
            </Typography>
            <Box className={Styles.CloseIcon} onClick={handleOperatorFiltersDrawerCancel}>
              <AssetIcon src="x-close" size="xxxxl" />
            </Box>
          </Box>
          <Divider />
        </Box>
        {/* Battery Level Filter */}
        <Box className={Styles.MainContent}>
          <OperatorBatteryLevel
            handleFilterByBatteryLevel={handleFilterByBatteryLevel}
            handleFilterByBatteryPercentage={handleFilterByBatteryPercentage}
            batteryLevelFilter={liveMapFilters?.batteryLevel}
            batteryPercentage={batteryRangeValue}
            handleRangeValueChange={handleRangeValueChange}
          />
          {/* IoT Level Filter */}
          {/* <OperatorIotBatteryLevel /> */}
          {/* Branches Filter */}
          <OperatorBranches
            handleFilterVehiclesByBrand={handleFilterVehiclesByBrand}
            handleFilterVehiclesByBranch={handleFilterVehiclesByBranch}
            branches={liveMapFilters?.branch}
          />
          {/* Service State Filter */}
          <OperatorVehicleServiceState
            serviceState={operationalStates}
            outOfOrderState={outOfOrderState}
            handleFilterVehiclesByService={handleFilterVehiclesByService}
          />
          {/* IoT Status Filter */}
          <OperatorSimpleFilterTemplate
            sectionName="IoT status"
            filtersData={iotStatusFilterData}
            onClick={handleFilterByIoTStatus}
          />
          {/* Rental Status Filter */}
          <OperatorSimpleFilterTemplate
            onClick={handleFilterByRentalStatus}
            sectionName="Rental status"
            filtersData={rentalStatusFilterData}
          />
          {/* Power Status Filter */}
          <OperatorSimpleFilterTemplate
            onClick={handleFilterByPowerStatus}
            sectionName="Power status"
            filtersData={powerStatusFilterData}
          />
          {/* Area Type Filter */}
          <OperatorSimpleFilterTemplate
            onClick={handleFilterByAreaType}
            sectionName="Area type"
            filtersData={areaTypeFilterData}
          />
          {/* Vehicle Type Filter */}
          <OperatorSimpleFilterTemplate
            onClick={handleFilterByVehicleType}
            sectionName="Vehicle Type"
            filtersData={vehicleTypeData}
          />
          {/* Last-Ended Rental Filter */}
          {/* <OperatorSliderFilterTemplate sectionName="Last-ended rentals" /> */}
          {/* Last Seen Online Filter */}
          {/* <OperatorSliderFilterTemplate sectionName="Last seen online" /> */}
        </Box>
        <Box className={Styles.OperatorFiltersFooter}>
          <Button
            onClick={() => {
              handleClearLiveMapFilters();
              handleOperatorFiltersDrawerCancel();
            }}
            className={Styles.Button_Cancel}
            variant="outlined"
          >
            Clear
          </Button>
          <Button
            className={Styles.Button_Apply}
            onClick={handleOperatorFiltersDrawerCancel}
            variant="contained"
          >
            Apply filters ({vehiclesLength})
          </Button>
        </Box>
      </Box>
    </SetupDrawer>
  );
};
