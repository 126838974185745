// System
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { useBranches } from '@Modal/hooks/branches/useBranch';

// Actions
import {
  postIoTCSVFile,
  postVehicleCSVFile,
  setCSVFile,
  setShowIoTCSVModal,
  setShowVehicleCSVModal,
} from '@Modal/reducers/vehicles/csv/csvSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { SubmitCSVDTO } from '@Modal/reducers/vehicles/csv/csvSlice.dto';

export const useCSV = () => {
  // State
  const [formData, setFormData] = useState(new FormData());

  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { activeBrandId } = useBranches();
  const { showIoTCSVModal, showVehicleCsvModal, csvFile, csvFileErrors } = useSelector(
    (state: StoreInterface) => state?.csv
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handlers
  const handleIoTCSVModal = (status: boolean) => {
    dispatch(setShowIoTCSVModal(status));
    if (!status) {
      dispatch(setCSVFile(undefined));
    }
  };

  const handleVehicleCSVModal = (status: boolean) => {
    dispatch(setShowVehicleCSVModal(status));
    if (!status) {
      dispatch(setCSVFile(undefined));
    }
  };

  const handleSetCSVFile = (file: File | undefined) => {
    if (file) {
      const reader = new FileReader();

      const newFormData = new FormData();
      newFormData.append('csvFile', file);

      setFormData(newFormData);

      reader.onloadend = () => {
        const fileDataUrl = reader.result;

        // Update your state with the file data URL
        dispatch(setCSVFile({ csvFileDataUrl: fileDataUrl, name: file.name }));
      };
      reader.readAsDataURL(file);
    } else {
      dispatch(setCSVFile(undefined));
    }
  };

  const handleSubmitCSV = (page: 'IOT' | 'VEHICLES') => {
    if (page === 'IOT') {
      return dispatch(
        postIoTCSVFile({
          // @ts-ignore
          csvFile: formData,
          brandId: activeBrandId,
        })
      )
        .unwrap()
        .catch((error) => {
          return enqueueSnackbar(error, {
            autoHideDuration: 3500,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }

    if (page === 'VEHICLES') {
      return dispatch(
        postVehicleCSVFile({
          // @ts-ignore
          csvFile: formData,
          brandId: activeBrandId,
        })
      )
        .unwrap()
        .catch((error) => {
          return enqueueSnackbar(error, {
            autoHideDuration: 3500,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  return {
    // Store
    csvFile,
    csvFileErrors,
    showIoTCSVModal,
    showVehicleCsvModal,
    // Actions
    handleIoTCSVModal,
    handleSubmitCSV,
    handleSetCSVFile,
    handleVehicleCSVModal,
  };
};
