import { useMemo } from 'react';
import { ObjectSchema, object, string, number, boolean, date, array } from 'yup';
import { SetupCustomerFormState } from '@Forms/customers/SetupCustomerFormContext';

export const useSetupCustomerUseCaseSchema = (): ObjectSchema<Partial<SetupCustomerFormState>> => {
  // @ts-ignore
  return useMemo(
    () =>
      object<SetupCustomerFormState>().shape({
        accountDetails: array().of(
          object().shape({
            id: string().required('Account ID is required'),
            createdAt: string().nullable().optional(),
            updatedAt: string().nullable().optional(),
            attributes: object().shape({
              secondaryEmail: string().nullable().optional(),
              secondaryPhone: string().nullable().optional(),
              address: string().nullable().optional(),
              birthday: string().nullable().optional(),
              city: string().nullable().optional(),
              country: string().nullable().optional(),
              gender: string().nullable().optional(),
              postalCode: string().nullable().optional(),
            }),
          })
        ),
        id: string().required('Customer ID is required'),
        brandId: string().optional().nullable(),
        firstName: string()
          .min(2, 'First name should have a minimum of 2 characters')
          .max(30, 'First name should have a maximum of 30 characters')
          .optional()
          .nullable()
          .matches(/^[a-zA-Z0-9_\-']+$/, 'First name includes an invalid value'),
        lastName: string()
          .min(2, 'Last name should have a minimum of 2 characters')
          .max(30, 'Last name should have a maximum of 30 characters')
          .optional()
          .nullable()
          .matches(/^[a-zA-Z0-9_\-']+$/, 'Last name includes an invalid value'),
        dob: date()
          .typeError('Invalid date')
          .optional()
          .nullable()
          .max(new Date(), 'Date of birth cannot be in the future'),
        email: string()
          .optional()
          .nullable()
          .email()
          .min(3, 'Email should have a minimum of 3 characters')
          .max(320, 'Email should have a maximum of 320 characters'),
        phoneNumber: string().optional().nullable(),
        lastKnownDevice: string().optional().nullable(),
        userAppLanguage: string().optional().nullable(),
        userAppVersion: string().optional().nullable(),
        branchId: string().optional().nullable(),
        street: string()
          .optional()
          .nullable()
          .min(2, 'Street should have a minimum of 2 characters')
          .max(100, 'Street should have a maximum of 100 characters'),
        zip: string()
          .optional()
          .nullable()
          .min(2, 'ZIP code should have a minimum of 2 digits')
          .max(32, 'ZIP code should have a maximum of 32 digits')
          .matches(/^[0-9]+$/, 'ZIP code should contain only digits'),
        tags: array().of(string()).optional(),
        city: string()
          .optional()
          .nullable()
          .min(2, 'City should have a minimum of 2 characters')
          .max(100, 'City should have a maximum of 100 characters'),
        newsletter: boolean().defined().nullable(),
        termsAndConditions: boolean().optional().nullable(),
        status: string().optional().nullable(),
        sourceProjectId: string().optional().nullable(),
      }),
    []
  );
};
