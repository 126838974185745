export enum AlertType {
  BranchWasCreated,
  BranchWasUpdated,
  VehicleCVSUploaded,
  VehicleCategoryUpdated,
  VehicleWasCreated,
  VehicleCategoryCreated,
  VehicleWasUpdated,
  IotModulesCVSUploaded,
  IotModuleWasCreated,
  IotModuleWasUpdated,
  CustomerWasUpdated,
}

type AlertMapSpec = {
  [AlertType.BranchWasCreated]: { branchId: string; brandId: string };
  [AlertType.BranchWasUpdated]: {};
  [AlertType.VehicleCVSUploaded]: {};
  [AlertType.VehicleCategoryUpdated]: {};
  [AlertType.VehicleWasCreated]: { vehicleId: string; brandId: string };
  [AlertType.VehicleCategoryCreated]: { categoryId: string; brandId: string };
  [AlertType.VehicleWasUpdated]: {};
  [AlertType.IotModulesCVSUploaded]: {};
  [AlertType.IotModuleWasCreated]: { iotModuleId: string; brandId: string };
  [AlertType.IotModuleWasUpdated]: {};
  [AlertType.CustomerWasUpdated]: {};
};

type BuildAlertMap<T extends AlertType, D extends Record<T, any>> = {
  [K in T]: { type: K } & D[K];
};

export type AlertMap = BuildAlertMap<AlertType, AlertMapSpec>;
export type Alert = AlertMap[AlertType];
