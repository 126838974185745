import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// Types
import { GetCustomerParams } from './types';

// GET
export const getCustomersList = createAsyncThunk(
  'customers/getCustomersList',
  async (params: GetCustomerParams, thunkApi) => {
    return axiosCustomerManagementInstance
      .get('/customers', {
        params: { ...params.selectedFilters, brandId: params.brandId },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

export const getCustomersDeleteRequestsCount = createAsyncThunk(
  'customers/getCustomersDeleteRequestsCount',
  async (_, thunkApi) => {
    return axiosCustomerManagementInstance
      .get('/customers/delete-requests-count', {})
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

// Type
const initialState = {
  customers: [],
  customersDeleteRequestsCount: 0,
  loadingContent: true,
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomersList: (state, { payload }) => {
      state.customers = payload;
    },
    setCustomersDeleteRequestsCount: (state, { payload }) => {
      state.customersDeleteRequestsCount = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
  },
  extraReducers: {
    [getCustomersList.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;

      state.customers = items;
      state.loadingContent = false;

      state.pagination = {
        ...state.pagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
    [getCustomersDeleteRequestsCount.fulfilled?.type]: (state, action) => {
      state.customersDeleteRequestsCount = action.payload;
    },
    [getCustomersList.rejected?.type]: (state) => {
      state.customers = [];
      state.loadingContent = false;
    },
    [getCustomersDeleteRequestsCount.rejected?.type]: (state) => {
      state.customersDeleteRequestsCount = 0;
    },
  },
});

export const { reducer: customersReducer } = customersSlice;
export const { setCustomersList, setCustomersDeleteRequestsCount, setPagination } =
  customersSlice.actions;
