import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelDialogMessageLayout,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';
import { usePricingDrawer } from '@Modal/hooks/pricing/usePricingDrawer';

export const SetupPricingCancelModalDialog: React.FC = () => {
  const { showCancelPricing, handleCancelTakeMeBack } = usePricingDrawer();
  return (
    <>
      {showCancelPricing && (
        <CancelModal open={showCancelPricing}>
          <ImageCancelDialogLayout
            title="Cancel pricing changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('cancel')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in pricing settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
