// System
import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Styles
const ScannerManualCommands = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'transparent',
  padding: '16px',
  position: 'relative',
  top: '-68%',
  width: '100%',
};

const ManualCommandCardStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  borderRadius: '8px',
  padding: '8px',
  border: '1px solid transparent',
  boxShadow: '0px 1px 2px 0px rgba(145, 158, 171, 0.16)',
  backgroundColor: '#FFF',
  minWidth: '42%',
};

export const OperatorScannerManuals: React.FC = () => {
  const { handleOpenQRScanModal, handleOpenServiceStateModal } = useOperatorScan();

  return (
    <Box sx={ScannerManualCommands}>
      <Box
        sx={{
          ...ManualCommandCardStyle,
          minWidth: '40px',
          maxWidth: '40px',
          height: '40px',
          bordeRadius: '50%',
        }}
      >
        <AssetIcon src="icons/operator/ic_torch" sx={{ width: '28px', height: '28px' }} />
      </Box>
      <Box sx={ManualCommandCardStyle} onClick={() => handleOpenQRScanModal(true)}>
        <Typography fontWeight={600} fontSize={'14px'}>
          Enter Manually
        </Typography>
      </Box>
      <Box onClick={handleOpenServiceStateModal} sx={ManualCommandCardStyle}>
        <Typography fontWeight={600} fontSize={'14px'}>
          Service State
        </Typography>
      </Box>
    </Box>
  );
};
