type Options = {
  group?: string;
  numerals: string[];
};

const defaultOptions: Options = {
  numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

const toRegExpChar = (char?: string): string => {
  return /\s/.test(char ?? '') ? '\\s' : char || '';
};

export const normalizeIntegerString = ({ group, numerals }: Options = defaultOptions) => {
  const groupRegExp = group ? new RegExp(`[${toRegExpChar(group)}]`, 'g') : undefined;
  const numeralRegExp = new RegExp(`[${numerals.join('')}]`, 'g');

  const indexMap = new Map(numerals.map((d, i) => [d, String(i)]));
  const getIndex = (d: string): string => indexMap.get(d) ?? '';

  return (text: string) => {
    const trimmed = text.trim();
    const noGroup = groupRegExp ? trimmed.replace(groupRegExp, '') : trimmed;
    const parsed = noGroup.replace(numeralRegExp, getIndex);
    return parsed;
  };
};
