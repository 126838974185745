import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';

export const userListColumns = [
  'user.id',
  'user.email',
  'user.password',
  'user.providerId',
  'user.externalId',
  'user.roleIds',
] as const;

export type UserListColumns = (typeof userListColumns)[number];

export type UserListFilter = {
  'user.id': string | undefined;
  'user.email': string | undefined;
  'user.password': string | undefined;
  'user.providerId': string | undefined;
  'user.externalId': string | undefined;
  'user.roleIds': string[] | undefined;
};

export const defaultUserListFilter: UserListFilter = {
  'user.id': undefined,
  'user.email': undefined,
  'user.password': undefined,
  'user.providerId': undefined,
  'user.externalId': undefined,
  'user.roleIds': undefined,
};

export const userListColumnVisibilitySettings = {
  'user.id': ColumnVisibilityType.Visible,
  'user.email': ColumnVisibilityType.CanBeHidden,
  'user.password': ColumnVisibilityType.CanBeHidden,
  'user.providerId': ColumnVisibilityType.CanBeHidden,
  'user.externalId': ColumnVisibilityType.CanBeHidden,
  'user.roleIds': ColumnVisibilityType.CanBeHidden,
} satisfies ColumnVisibilitySettings<UserListColumns>;

export const defaultUserListColumnVisibility = defaultColumnVisibilityParams(
  userListColumnVisibilitySettings
);

export const userListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  userListColumns,
  userListColumnVisibilitySettings
);
