import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useCreateAviatorRole } from '@Modal/hooks/aviatorRoles/useCreateAviatorRole';

export const CancelCreateAviatorRoleModalDialog: React.FC = () => {
  const { handleCancelTakeMeBack, showCancelCreateAviatorRoleForm } = useCreateAviatorRole();

  return (
    <>
      {showCancelCreateAviatorRoleForm && (
        <CancelModal open={showCancelCreateAviatorRoleForm}>
          <ImageCancelDialogLayout
            title="Cancel vehicle changes?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in role settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
