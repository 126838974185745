import React from 'react';
import { Button, Stack } from '@mui/material';

// Styles
import Styles from './SetupAddUserDrawerFooter.module.scss';

// Types
type Props = {
  saveDisabled?: boolean;
  onSaveClick?: () => void;
  cancelDisabled?: boolean;
  onCancelClick?: () => void;
};

export const SetupAddUserDrawerFooter: React.FC<Props> = ({
  cancelDisabled = false,
  onCancelClick,
  saveDisabled = false,
  onSaveClick,
}) => {
  // TODO Update btn disability
  return (
    <Stack className={Styles.SaveSettingsButtonPanel}>
      <Button
        className={Styles.SaveCTA}
        type="button"
        disabled={saveDisabled}
        onClick={onSaveClick}
      >
        Add User
      </Button>
      <Button
        className={Styles.CancelCTA}
        variant="outlined"
        disabled={cancelDisabled}
        onClick={onCancelClick}
      >
        Cancel
      </Button>
    </Stack>
  );
};
