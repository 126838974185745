// System
import { Stack, Typography, Box } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Features
import { VehicleServiceLabel } from '@Features/vehicles/vehicles/VehicleServiceLabel';
import { VehicleCommandsLabel } from '../../VehicleCommandsLabel';

// Redux
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

// Styles
import Styles from './SetupVehicleHeader.module.scss';

export const SetupVehicleHeader: React.FC = () => {
  // Data
  const { selectedVehicleData } = useVehiclesDrawer();
  const { id, serviceState, qrCode } = selectedVehicleData;

  return (
    <Stack className={Styles.SetupVehicleHeader}>
      <Box className={Styles.QrCode}>
        <Typography variant="h6">{qrCode}</Typography>
      </Box>
      <Stack className={Styles.CTAContainer}>
        <AtLocator type="button" name="VehicleStatus">
          <VehicleServiceLabel value={serviceState} id={id} />
        </AtLocator>
        <AtLocator type="button" name="VehicleStatus">
          <VehicleCommandsLabel value={serviceState} id={id} />
        </AtLocator>
      </Stack>
    </Stack>
  );
};
