import { MouseEventHandler } from 'react';
import { useTheme } from '@mui/material';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { CustomTableButton } from '../CustomTableButton';

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
};

export const FilterTableButton: React.FC<Props> = ({ id, onClick }) => {
  const { palette } = useTheme();
  return (
    <CustomTableButton id={id} onClick={onClick} data-at-button="Filter">
      <AssetIcon src="filter-funnel-01" size="l" sx={{ bgcolor: palette.primary.main }} />
    </CustomTableButton>
  );
};
