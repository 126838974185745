import { MapZoneType } from '@contracts/domain/keys';
import { SemiGeoJSONMultiPoligon } from '@contracts/common/map/SemiGeoJSON';
import { EditStatus } from '@contracts/common/EditStatus';
import { fromGoogleMapsMultiPoligon, getPathType } from '@utils/map/geoJSON';
import { GoogleMapsPath } from '@contracts/common/map/GoogleMaps';

import { MapPath } from './MapEditorMode';

export const toMapZonePath =
  (mapZoneType: MapZoneType, mapZoneId: string) =>
  (path: GoogleMapsPath): MapPath => {
    return {
      path,
      editStatus: EditStatus.Pristine,
      mapZoneType,
      type: getPathType(path),
      id: mapZoneId,
    };
  };

export const toGeoJSONPath = (
  paths: MapPath[]
): {
  mapZoneType: MapZoneType;
  geometry: SemiGeoJSONMultiPoligon;
  pristine: boolean;
}[] => {
  const mapZoneTypes = Array.from(
    new Set(
      paths
        .filter(({ editStatus }) => editStatus !== EditStatus.Deleted)
        .map(({ mapZoneType }) => mapZoneType)
    )
  );

  return mapZoneTypes.map((zoneType) => ({
    pristine:
      paths.filter(
        ({ editStatus, mapZoneType }) =>
          mapZoneType === zoneType && editStatus !== EditStatus.Pristine
      ).length === 0,
    mapZoneType: zoneType,
    geometry: fromGoogleMapsMultiPoligon(
      paths
        .filter(
          ({ editStatus, mapZoneType }) =>
            mapZoneType === zoneType && editStatus !== EditStatus.Deleted
        )
        .map(({ path }) => path)
    ),
  }));
};
