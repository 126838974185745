import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export type CreateCustomerNoteFormState = {
  note: string;
};

export const CreateCustomerNoteFormStateDefault: CreateCustomerNoteFormState = {
  note: '',
};

export const CreateCustomerNoteSchemaDefault: ObjectSchema<Partial<CreateCustomerNoteFormState>> =
  object<Partial<CreateCustomerNoteFormState>>().shape({
    note: string()
      .required('Note is required')
      .test('min-characters', 'Note must be at least 2 non-space characters long', (value) => {
        const nonSpaceChars = value.replace(/\s/g, '').length;
        return nonSpaceChars >= 2;
      }),
  });

type CreateCustomerNoteFormContextType = {
  schema: ObjectSchema<Partial<CreateCustomerNoteFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateCustomerNoteFormState>>>;
  methods?: UseFormReturn<CreateCustomerNoteFormState>;
};

const createCustomerNoteFormContextDefault = {
  schema: CreateCustomerNoteSchemaDefault,
  setSchema: noop,
} satisfies CreateCustomerNoteFormContextType;

export const CreateCustomerNoteFormContext = createContext<CreateCustomerNoteFormContextType>(
  createCustomerNoteFormContextDefault
);

export const useCreateCustomerNoteForm = () => {
  const { methods } = useContext(CreateCustomerNoteFormContext);
  if (!methods) {
    throw new Error('CreateCustomerNoteFormContext is empty');
  }

  return methods;
};

export const useManageCreateCustomerSchema = () => {
  const { setSchema } = useContext(CreateCustomerNoteFormContext);

  return { setSchema };
};
