import {
  defaultRoundListFilter,
  roundListColumnVisibilityOptions,
  defaultRoundListColumnVisibility,
} from '@utils/tables/rounds/roundListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  limit: 25,
  page: 1,
  total: 0,
  selectedFilters: defaultRoundListFilter,
  columnVisibility: defaultRoundListColumnVisibility,
  columnVisibilityOptions: roundListColumnVisibilityOptions,
  roundTableFilters: {},
};

const roundTableSlice = createSlice({
  name: 'roundTable',
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setRoundTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { roundTableFilters } = state;

      const newRoundTableFilters = { ...roundTableFilters, [codeName]: value };
      state.roundTableFilters = newRoundTableFilters;
    },
  },
  extraReducers: {},
});

export const { reducer: roundTableReducer } = roundTableSlice;
export const { setColumnVisibility, setLimit, setTotal, setPage, setRoundTableFilters } =
  roundTableSlice.actions;
