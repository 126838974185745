import { CreateAviatorRoleForm } from './CreateAviatorRoleForm';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { useCreateAviatorRole } from '@Modal/hooks/aviatorRoles/useCreateAviatorRole';

export const CreateAviatorRoleModalDialog = () => {
  const { showCreateAviatorRoleForm, handleCreateCancel } = useCreateAviatorRole();

  return (
    <>
      {showCreateAviatorRoleForm && (
        <ClosableModal
          name="CreateRole"
          title="Create Role"
          open={showCreateAviatorRoleForm}
          onClose={handleCreateCancel}
        >
          <CreateAviatorRoleForm />
        </ClosableModal>
      )}
    </>
  );
};
