import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import { SubmitCSVDTO } from './csvSlice.dto';

// POST
export const postVehicleCSVFile = createAsyncThunk(
  'csv/postVehicleCSVFile',
  async (csvData: SubmitCSVDTO, thunkApi) => {
    const formData = new FormData();
    formData.append('file', csvData?.csvFile);

    return axiosBranchesVehiclesInstance
      .post('/v1/vehicles/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          brandId: csvData?.brandId,
        },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const postIoTCSVFile = createAsyncThunk(
  'csv/postIoTCSVFile',
  async (csvData: SubmitCSVDTO, thunkApi) => {
    const formData = new FormData();
    formData.append('file', csvData?.csvFile);

    return axiosBranchesVehiclesInstance
      .post(
        `/v1/iot/upload/${csvData?.brandId}`,
        {
          file: formData,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  csvFile: undefined,
  csvFileErrors: undefined,
  showIoTCSVModal: false,
  showVehicleCsvModal: false,
};

const csvSlice = createSlice({
  name: 'csvSlice',
  initialState,
  reducers: {
    setShowIoTCSVModal: (state, { payload }) => {
      state.showIoTCSVModal = payload;
    },
    setShowVehicleCSVModal: (state, { payload }) => {
      state.showVehicleCsvModal = payload;
    },
    setCSVFile: (state, { payload }) => {
      state.csvFile = payload;
    },
  },
  extraReducers: {
    [postVehicleCSVFile.fulfilled?.type]: (state) => {
      state.csvFileErrors = undefined;
    },
    [postVehicleCSVFile.rejected?.type]: (state, { payload }) => {
      state.csvFileErrors = payload;
    },
    [postIoTCSVFile.fulfilled?.type]: (state) => {
      state.csvFileErrors = undefined;
    },
    [postIoTCSVFile.rejected?.type]: (state, { payload }) => {
      state.csvFileErrors = payload;
    },
  },
});

export const { reducer: csvSliceReducer } = csvSlice;
export const { setShowIoTCSVModal, setShowVehicleCSVModal, setCSVFile } = csvSlice.actions;
