import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';
// Styles
import Styles from './Logo.module.scss';
// Logo
import AviatorLogo from '../../../shared/ui/layouts/dashboard/nav/AviatorLogo.svg';
// ----------------------------------------------------------------------
export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}
const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false }, ref) => {
  const logo = (
    <Box ref={ref} component="div" className={Styles.LogoContainer}>
      <img className={Styles.Logo} src={`${AviatorLogo}`} />
    </Box>
  );
  if (disabledLink) {
    return logo;
  }
  return (
    <Link component={RouterLink} to="/providers" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});
export default Logo;
