import React from 'react';

import { Stack, Typography } from '@mui/material';

import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const Settings = () => {
  return (
    <Stack spacing={2}>
      <AtLocator type="readonly-text" name="NoMovementSettings">
        <Typography variant="subtitle1">No movement settings</Typography>
      </AtLocator>
      <AtLocator type="readonly-text" name="MovementRulesDescription">
        <Typography variant="body2" color="text.secondary">
          Configure the no movement rule so that the rental won’t be charged <br />
          to the user under certain conditions.
        </Typography>
      </AtLocator>
      <AtLocator type="text-field" name="noMovement.maxDistance">
        <RHFIntegerField name="noMovement.maxDistance" label="Maximum distance in meters" />
      </AtLocator>
      <AtLocator type="text-field" name="noMovement.maxTime">
        <RHFIntegerField name="noMovement.maxTime" label="Maximum time in minutes" />
      </AtLocator>
      <AtLocator type="text-field" name="noMovement.autoEndMaxCost">
        <RHFIntegerField name="noMovement.autoEndMaxTime" label="Auto-End Maximum Cost" />
      </AtLocator>
    </Stack>
  );
};
