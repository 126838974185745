import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import { CreatePricingFormStateDTO } from '@Forms/pricing/CreatePricing/CreatePricingFormContext';

// POST
export const createPricing = createAsyncThunk(
  'pricing/createNewPricing',
  async (pricingData: CreatePricingFormStateDTO, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post('/v1/pricing', {
        ...pricingData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  showCreatePricingForm: false,
  showCancelPricingForm: false,
};

const createPricingSlice = createSlice({
  name: 'createPricing',
  initialState,
  reducers: {
    setShowCreatePricingForm: (state, { payload }) => {
      state.showCreatePricingForm = payload;
    },
    setShowCancelPricingForm: (state, { payload }) => {
      state.showCancelPricingForm = payload;
    },
  },
  extraReducers: {
    [createPricing.fulfilled?.type]: (state) => {
      state.showCreatePricingForm = false;
    },
  },
});

export const { reducer: createPricingReducer } = createPricingSlice;
export const { setShowCreatePricingForm, setShowCancelPricingForm } = createPricingSlice.actions;
