import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// Types
import { AddUserType } from './types';

// POST
export const addUser = createAsyncThunk(
  'roles/addUser',
  async (pricingData: AddUserType, thunkApi) => {
    return axiosCustomerManagementInstance
      .post('/roles', {
        ...pricingData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  showAddUserModalDialog: false,
  showCancelAddUserModalDialog: false,
};

const addUserSlice = createSlice({
  name: 'addUserSlice',
  initialState,
  reducers: {
    setShowAddUserModalDialog: (state, { payload }) => {
      state.showAddUserModalDialog = payload;
    },
    setShowCancelAddUserModalDialog: (state, { payload }) => {
      state.showCancelAddUserModalDialog = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: addUserReducer } = addUserSlice;
export const { setShowAddUserModalDialog, setShowCancelAddUserModalDialog } = addUserSlice.actions;
