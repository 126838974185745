import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import {
  SelectBox,
  SelectBoxDropDownLayout,
  SelectBoxProvider,
  useManageSelectBox,
} from '@Components/shared/selects/SelectBox';
import { Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

type DropDownProps = {
  hidden: boolean;
  onChangeHidden?: (value: boolean) => void;
  onDelete?: () => void;
};

type ActionProps = {
  value: string;
  text: string;
  icon: JSX.Element;
  onClick?: () => void;
};

type Props = DropDownProps & {
  name: string;
  text: string;
};

const Action: React.FC<ActionProps> = ({ value, text, icon, onClick }) => {
  const { handleClose } = useManageSelectBox();
  return (
    <MenuItemEx name="Action" key="hide" onClick={handleClose(onClick)}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Typography variant="body2">{text}</Typography>
        {icon}
      </Stack>
    </MenuItemEx>
  );
};

const DropDown: React.FC<DropDownProps> = ({ hidden, onDelete, onChangeHidden }) => {
  const handleChangeHidden = useCallback(
    (value: boolean) => (): void => onChangeHidden?.(value),
    [onChangeHidden]
  );

  return (
    <>
      {!hidden && (
        <Action
          key="hide"
          value="hide"
          text="Hide"
          icon={<AssetIcon size="xl" src="eye-off" />}
          onClick={handleChangeHidden(true)}
        />
      )}
      {hidden && (
        <Action
          key="unhide"
          value="unhide"
          text="Unhide"
          icon={<AssetIcon size="xl" src="eye" />}
          onClick={handleChangeHidden(false)}
        />
      )}
      <Action
        key="delete"
        value="delete"
        text="Delete"
        icon={<AssetIcon size="xl" src="trash-02" />}
        onClick={onDelete}
      />
    </>
  );
};

const SelectContent: React.FC<Props> = ({ name, text, hidden, ...rest }) => {
  const { handleOpen } = useManageSelectBox();

  return (
    <SelectBox
      name={name}
      input={
        <Button
          variant="contained"
          color="inherit"
          size="small"
          startIcon={<AssetIcon src={hidden ? 'eye-off' : 'eye'} size="xl" />}
          endIcon={<AssetIcon src="chevron-down" size="s" />}
          onClick={handleOpen}
        >
          {text}
        </Button>
      }
      dropDown={
        <SelectBoxDropDownLayout sx={{ maxHeight: '500px', width: '211px' }}>
          <DropDown hidden={hidden} {...rest} />
        </SelectBoxDropDownLayout>
      }
    />
  );
};

export const HideDeleteActionSelect: React.FC<Props> = (props) => {
  return (
    <SelectBoxProvider>
      <SelectContent {...props} />
    </SelectBoxProvider>
  );
};
