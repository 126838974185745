import {
  defaultRoleListFilter,
  defaultRoleListColumnVisibility,
  roleListColumnVisibilityOptions,
} from '@utils/tables/roles/rolesListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultRoleListFilter,
  rowsPerPage: 10,
  page: 1,
  columnVisibility: defaultRoleListColumnVisibility,
  columnVisibilityOptions: roleListColumnVisibilityOptions,
};

const rolesTableSlice = createSlice({
  name: 'roles/table',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultRoleListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: rolesTableReducer } = rolesTableSlice;
export const {
  setColumnVisibility,
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
} = rolesTableSlice.actions;
