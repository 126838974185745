import { DefaultLocale } from '@contracts/common/DefaultLocale';

export const localeNumberFormat =
  (locale: string = DefaultLocale) =>
  (value: number, formatOptions?: Intl.NumberFormatOptions) => {
    const fullOptions: Intl.NumberFormatOptions = {
      useGrouping: false,
      ...formatOptions,
    };

    const formatter = new Intl.NumberFormat(locale, fullOptions);

    return formatter.format(value);
  };
