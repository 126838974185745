import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateRoundConfigFormContext,
  createRoundConfigFormStateDefault,
  createRoundConfigSchemaDefault,
  CreateRoundConfigFormState,
} from '@Forms/rounds/CreateRoundConfigFormContext';

export const CreateRoundConfigFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateRoundConfigFormState>>>(
    createRoundConfigSchemaDefault
  );
  const methods = useForm({
    defaultValues: createRoundConfigFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateRoundConfigFormContext.Provider value={value}>
      {children}
    </CreateRoundConfigFormContext.Provider>
  );
};
