// System
import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

// Types
type Props = {
  field: string;
  value: string;
};

// Styles
const TemplateContainer = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
};

export const OperatorVehicleDetailsTemplate: React.FC<Props> = ({ field, value }) => {
  return (
    <Box sx={TemplateContainer}>
      <Typography fontSize={14} color="#637381">
        {field}
      </Typography>
      <Typography fontSize={14} color="#000000">
        {value}
      </Typography>
    </Box>
  );
};
