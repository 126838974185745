import { Grid } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RHFSwitch } from '@Components/hook-form';

export const DashboardCommands = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={0} columns={8} justifyContent="flex-start">
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Start">
          <RHFSwitch label="Start " name="commands.dashboard.start" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Stop">
          <RHFSwitch label="Stop " name="commands.dashboard.stop" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="UnlockBattery">
          <RHFSwitch label="Unlock Battery" name="commands.dashboard.unlockBattery" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="UnlockHelmet">
          <RHFSwitch label="Unlock Helmet" name="commands.dashboard.unlockHelmet" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Alarm">
          <RHFSwitch label="Alarm" name="commands.dashboard.alarmOn" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="MaxSpeed">
          <RHFSwitch label="Set Max Speed" name="commands.dashboard.setMaxSpeed" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Reboot">
          <RHFSwitch label="Reboot" name="commands.dashboard.reboot" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Production Mode">
          <RHFSwitch label="Production Mode" name="commands.dashboard.productionMode" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="DemoMode">
          <RHFSwitch label="Demo Mode" name="commands.dashboard.demoMode" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="OpenSaddle">
          <RHFSwitch label="Open Saddle" name="commands.dashboard.openSaddle" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="OpenTailBox">
          <RHFSwitch label="Open TailBox" name="commands.dashboard.openTailBox" />
        </AtLocator>
      </Grid>
    </Grid>
  );
};
