import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import {
  GetVehiclesParamsType,
  VehicleCategoryType,
  VehicleItemType,
  GetVehicleParams,
  GetIotParams,
} from './types';

// GET
export const getVehiclesList = createAsyncThunk(
  'vehicles/getVehiclesList',
  async (params: GetVehicleParams, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get('/v1/vehicles', {
        params:
          params?.brandId !== 'undefined' && params?.brandId
            ? { ...params.selectedFilters, brandId: params.brandId }
            : { ...params.selectedFilters },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

export const getVehicleCategoriesList = createAsyncThunk(
  'vehicles/getVehicleCategoriesList',
  async (params: GetVehiclesParamsType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get('/v1/categories', {
        params: { ...params },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// export const getVehicleIotModulesList = createAsyncThunk(
//   'vehicles/getVehicleIotModulesList',
//   async (params: GetIotParams, thunkApi) => {
//     return axiosBranchesVehiclesInstance
//       .get('/v1/iot', {
//         params:
//           params?.brandId !== 'undefined' && params?.brandId
//             ? { ...params.selectedFilters, brandId: params.brandId }
//             : { ...params.selectedFilters },
//       })
//       .then((res) => {
//         return thunkApi.fulfillWithValue(res.data);
//       })
//       .catch((error) => {
//         return thunkApi.rejectWithValue(error || 'Rejected');
//       });
//   }
// );

// Type
const initialState = {
  vehicles: [],
  loadingVehiclesContent: true,
  vehicleCategories: [],
  loadingCategoriesContent: true,
  vehiclesIOTModules: [],
  loadingIotContent: true,
  vehiclePagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
  categoryPagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
  // iotPagination: {
  //   page: 0,
  //   count: 0,
  //   rowsPerPage: 25,
  // },
};

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    // @TODO: Improve these 3 handlers after sprint
    setVehiclePagination: (state, { payload }) => {
      state.vehiclePagination = payload;
    },
    setCategoryPagination: (state, { payload }) => {
      state.categoryPagination = payload;
    },
    setVehiclesList: (state, { payload }) => {
      state.vehicles = payload;
    },
    setVehicleCategoriesList: (state, action: PayloadAction<VehicleCategoryType>) => {
      // @ts-ignore
      state.vehicleCategories = [action.payload, ...state.vehicleCategories];
    },
    updateVehicleCategoriesList: (state, action: PayloadAction<VehicleItemType[]>) => {
      // @ts-ignore
      state.vehicleCategories = action.payload;
    },
    updateIotList: (state, action: PayloadAction<VehicleItemType[]>) => {
      // @ts-ignore
      state.vehicleCategories = action.payload;
    },
  },
  extraReducers: {
    [getVehiclesList.fulfilled?.type]: (state, { payload }) => {
      const { items, pageInfo } = payload;
      const { page } = pageInfo;

      state.vehicles = items;
      state.loadingVehiclesContent = false;
      state.vehiclePagination = {
        ...state.vehiclePagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
    [getVehicleCategoriesList.fulfilled?.type]: (state, { payload }) => {
      const { items, pageInfo } = payload;
      const { page } = pageInfo;

      state.vehicleCategories = items;
      state.loadingCategoriesContent = false;

      state.categoryPagination = {
        ...state.categoryPagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
    [getVehiclesList.rejected?.type]: (state) => {
      state.vehicles = [];
      state.loadingVehiclesContent = false;
    },
    [getVehicleCategoriesList.rejected?.type]: (state) => {
      state.vehicleCategories = [];
      state.loadingCategoriesContent = false;
    },
  },
});

export const { reducer: vehiclesReducer } = vehiclesSlice;
export const {
  updateIotList,
  setVehiclesList,
  setVehiclePagination,
  setCategoryPagination,
  setVehicleCategoriesList,
  updateVehicleCategoriesList,
} = vehiclesSlice.actions;
