import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import * as Yup from 'yup';

export type ResetRolePasswordFormState = {
  password: string | null;
  confirmPassword: string | null;
};

export const ResetRolePasswordFormStateDefault: ResetRolePasswordFormState = {
  password: '',
  confirmPassword: '',
};

export const ResetRolePasswordSchemaDefault = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

type ResetRolePasswordFormContextType = {
  schema: ObjectSchema<Partial<ResetRolePasswordFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<ResetRolePasswordFormState>>>;
  methods?: UseFormReturn<ResetRolePasswordFormState>;
};

const createResetRolePasswordContextDefault = {
  schema: ResetRolePasswordSchemaDefault,
  setSchema: noop,
} satisfies ResetRolePasswordFormContextType;

export const ResetRolePasswordFormContext = createContext<ResetRolePasswordFormContextType>(
  createResetRolePasswordContextDefault
);

export const useResetRolePasswordForm = () => {
  const { methods } = useContext(ResetRolePasswordFormContext);
  if (!methods) {
    throw new Error('SetupRoleFormContext is empty');
  }

  return methods;
};

export const useManageSetupRoleSchema = () => {
  const { setSchema } = useContext(ResetRolePasswordFormContext);

  return { setSchema };
};
