import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { IconButton } from '@mui/material';
import { MouseEventHandler } from 'react';

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const ColumnVisibilityTableButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton
      aria-label="expand"
      onClick={onClick}
      data-at-button="column-visibility"
      sx={{ background: 'transparent' }}
    >
      <AssetIcon src="dots-vertical" size="xxxl" sx={{ color: 'black' }} />
    </IconButton>
  );
};
