import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useProviderDrawer } from '@Modal/hooks/providers/useProviderDrawer';
import { useDispatch } from 'react-redux';

export const SetupProviderDrawerCancelDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { showCancelProviderDrawer, setShowCancelProviderDrawer, handleCancelChanges } =
    useProviderDrawer();

  return (
    <>
      {showCancelProviderDrawer && (
        <CancelModal open={showCancelProviderDrawer}>
          <ImageCancelDialogLayout
            title="Cancel provider changes?"
            actions={
              <CancelDialogActions
                onReject={() => dispatch(setShowCancelProviderDrawer(false))}
                onConfirm={handleCancelChanges}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in provider settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
