import { ColumnVisibilitySettings } from '../ColumnVisibilitySettings';
import { ColumnVisibilityType } from '../ColumnVisibilityType';

export type ColumnVisibilityParams<T extends string> = T[];

export const defaultColumnVisibilityParams = <T extends string>(
  options: ColumnVisibilitySettings<T>
) => {
  const keys = Object.keys(options) as T[];
  return keys.filter((key) => options[key] !== ColumnVisibilityType.CanBeVisible);
};

export const defaultColumnVisibilityOptions = <T extends string>(
  columns: T[] | readonly T[],
  options: ColumnVisibilitySettings<T>
) => {
  return columns.filter((key) => options[key] !== ColumnVisibilityType.Visible);
};
