import { useSnackbar } from 'notistack';
// Types
import { StoreInterface } from 'src/app/types';
// Redux
import { useSelector, useDispatch } from 'react-redux';

// Form
import { useFormState } from 'react-hook-form';
import { useCreateRentalsNoteForm } from '@Forms/rentals/Notes/CreateRentalsNoteFormContext';
import {
  createRentalNote,
  getRentalNotes,
  deleteRentalsNote,
  setShowCreateRentalNoteForm,
  setShowDeleteRentalNoteForm,
} from '@Modal/reducers/rentals/rentalsNoteSlice';
import { BaseSyntheticEvent } from 'react';
import { useRentalsDrawer } from './useRentalsDrawer';
import { AppDispatch } from 'src/app/store';
import Cookies from 'js-cookie';

export const useRentalsNote = () => {
  // Redux
  const { showCreateRentalNoteForm, showDeleteRentalNoteForm, rentalNotes, selectedRentalNoteId } =
    useSelector((state: StoreInterface) => state?.rentalsNote);

  const { selectedRental } = useRentalsDrawer();

  const dispatch = useDispatch<AppDispatch>();

  // Other hooks
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, handleSubmit, reset } = useCreateRentalsNoteForm();
  const { isDirty } = useFormState({ control });

  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  const handleCreateNote = (e?: BaseSyntheticEvent) => {
    return handleSubmit(async (formData) => {
      const noteData = {
        id: selectedRental?.id,
        note: formData.note,
        authorId: currentUser?.id,
      };
      dispatch(createRentalNote(noteData))
        .unwrap()
        .then((_) => {
          reset();
          enqueueSnackbar('Note was successfully created', {
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        })
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    })(e);
  };

  const handleCreateNoteCancel = () => {
    reset();
    return dispatch(setShowCreateRentalNoteForm(false));
  };

  const handleRemoveNote = () => {
    reset();
    return dispatch(setShowDeleteRentalNoteForm(true));
  };

  const handleRemoveNoteCancel = () => {
    return dispatch(setShowDeleteRentalNoteForm(false));
  };

  return {
    showCreateRentalNoteForm,
    showDeleteRentalNoteForm,
    setShowCreateRentalNoteForm,
    setShowDeleteRentalNoteForm,
    selectedRentalNoteId,
    handleCreateNote,
    handleCreateNoteCancel,
    handleRemoveNote,
    handleRemoveNoteCancel,
    createRentalNote,
    getRentalNotes,
    deleteRentalsNote,
    rentalNotes,
  };
};
