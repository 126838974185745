import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';
import { useCreateBrand } from '@Modal/hooks/branches/useCreateBrand';


export const CreateBrandCancelModalDialog: React.FC = () => {
  const {showCancelBrandForm,handleCancelTakeMeBack} = useCreateBrand();
  return (
    <>
      {showCancelBrandForm && (
        <CancelModal open={showCancelBrandForm}>
          <ImageCancelDialogLayout
            title="Cancel branch changes?"
            actions={
              <CancelDialogActions onReject={() => handleCancelTakeMeBack('back')} onConfirm={() => handleCancelTakeMeBack('confirm')} />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in branch settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
