import { useCallback } from 'react';

// System
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Redux
import {
  getCustomersDeleteRequestsCount,
  setPagination,
} from '@Modal/reducers/customers/customersSlice';

export const useCustomers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const { customers, customersDeleteRequestsCount, pagination, loadingContent } = useSelector(
    (state: StoreInterface) => state?.customers
  );

  const fetchCustomersDeleteRequestsCount = useCallback(() => {
    dispatch(getCustomersDeleteRequestsCount())
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, []);

  return {
    // Store
    customers,
    pagination,
    loadingContent,
    customersDeleteRequestsCount,
    // Actions
    setPagination,
    fetchCustomersDeleteRequestsCount,
  };
};
