import { useCallback, useMemo } from 'react';

import { VisiblityCustomizable } from '@contracts/common/traits/VisiblityCustomizable';
import { TableColumnDescription } from '@contracts/view/TableColumnDescription';
import { KeyValue } from '@contracts/view/KeyValue';

import { TableHeadCellEx } from '../../components';
import { ColumnVisibilityAction } from '../../columnVisibility/ColumnVisibilityAction';

type Props<T extends Record<string, unknown>, H extends {}, K extends string> = {
  content: VisiblityCustomizable<K>;
  columns: TableColumnDescription<T, H, K>[];
};

export const ColumnVisibilityPresenter = <
  T extends Record<string, unknown>,
  H extends {},
  K extends string
>({
  content,
  columns,
}: Props<T, H, K>) => {
  const options = useMemo<KeyValue<K, string>[]>(() => {
    return content.options
      .map((key) => ({ key, value: columns?.find((column) => column.name === key) }))
      .filter(({ value }) => value !== undefined)
      .map(({ key, value }) => ({ key, value: value?.title ?? '' }));
  }, [content.options, columns]);

  const checkedValues = useMemo<K[]>(() => {
    return content.value.filter((key) => options.find(({ key: k }) => k === key));
  }, [content.value, options]);

  const handleChange = useCallback(
    (values: K[]) => {
      const newValues = content.options.filter((key) => values.includes(key));
      content.apply(newValues);
    },
    [content]
  );

  return (
    <TableHeadCellEx
      data-at-table-column="ColumnVisibility"
      sx={{
        position: 'sticky',
        paddingY: 1.75,
        paddingX: 0.5,
        right: '0',
      }}
    >
      <ColumnVisibilityAction
        options={options}
        checkedValues={checkedValues}
        onChange={handleChange}
      />
    </TableHeadCellEx>
  );
};
