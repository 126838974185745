import { noop } from '@utils/noop';
import { Dispatch, RefObject, createContext } from 'react';

type SelectBoxContextType = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  anchorRef: RefObject<HTMLButtonElement>;
};

const defaultSelectBoxContext: SelectBoxContextType = {
  open: false,
  setOpen: noop,
  anchorRef: { current: null },
};

export const SelectBoxContext = createContext(defaultSelectBoxContext);
