import { ComponentProps, ReactElement, cloneElement } from 'react';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { RHFSwitch } from '@Components/hook-form';

type ChildrenProps = ComponentProps<typeof RHFSwitch>;

export type AtSwitchFieldLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtSwitchFieldLocator: React.FC<AtSwitchFieldLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-switch-field': name } as DataAttributeProps);
};
