import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

export const getTimeZones = createAsyncThunk('general/getTimeZones', async (_, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get(`v1/timezones`)
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error?.message);
    });
});

export const getMapZoneTypes = createAsyncThunk('general/getMapZoneTypes', async (_, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get(`v1/map-zone-types`)
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error?.message);
    });
});

export const getCurrencies = createAsyncThunk('general/getCurrencies', async (_, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get(`v1/currencies`)
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error?.message);
    });
});

export const getVehicleServiceStates = createAsyncThunk(
  'general/getVehicleServiceStates',
  async (_, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`v1/vehicle-service-state`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const getVehicleTypes = createAsyncThunk('general/getVehicleTypes', async (_, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get(`v1/vehicle-types`)
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error?.message);
    });
});

export const getSpeedModes = createAsyncThunk('general/getSpeedModes', async (_, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get('v1/speed-modes')
    .then((res) => thunkApi.fulfillWithValue(res.data))
    .catch((error) => thunkApi.rejectWithValue(error?.message));
});

export const getVehicleDisplayNames = createAsyncThunk(
  'general/vehicleDisplayNames',
  async (_, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get('v1/vehicle-display-names')
      .then((res) => thunkApi.fulfillWithValue(res.data))
      .catch((error) => thunkApi.rejectWithValue(error?.message));
  }
);

// Type
const initialState = {
  speedModes: [],
  timezones: [],
  currencies: [],
  vehicleTypes: [],
  mapZoneTypes: [],
  vehicleDisplayNames: [],
  vehicleServiceStates: [],
};

const generalSlice = createSlice({
  name: 'generalSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // [getTimeZones.fulfilled?.type]: (state, action) => {
    //   state.timezones = action.payload;
    // },
    // [getMapZoneTypes.fulfilled?.type]: (state, action) => {
    //   state.mapZoneTypes = action.payload;
    // },
    // [getCurrencies.fulfilled?.type]: (state, action) => {
    //   state.currencies = action.payload;
    // },
    // [getVehicleServiceStates.fulfilled?.type]: (state, action) => {
    //   state.vehicleServiceStates = action.payload;
    // },
    // [getVehicleTypes.fulfilled?.type]: (state, action) => {
    //   state.vehicleTypes = action.payload;
    // },
    // [getSpeedModes.fulfilled?.type]: (state, action) => {
    //   state.speedModes = action.payload;
    // },
    // [getVehicleDisplayNames.fulfilled?.type]: (state, action) => {
    //   state.vehicleDisplayNames = action.payload;
    // },
  },
});

export const { reducer: generalReducer } = generalSlice;
