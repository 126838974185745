// System
import React, { useState } from 'react';
import { SystemStyleObject } from '@mui/system';
import { Box, Divider, Theme, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';

interface ToggleableContentProps {
  name: string;
  children: React.ReactNode;
  icon?: string;
  helperText?: string;
}

// Styles
import Styles from './ToggleableContentOperator.module.scss';

export const ToggleableContentMobile: React.FC<ToggleableContentProps> = ({
  name,
  children,
  icon,
  helperText,
}) => {
  const [isContentVisible, setContentVisible] = useState(true);
  const [isIconHovered, setIconHovered] = useState(false);

  const toggleContentVisibility = () => {
    setContentVisible((prevVisible) => !prevVisible);
  };

  const handleIconMouseEnter = () => {
    setIconHovered(true);
  };

  const handleIconMouseLeave = () => {
    setIconHovered(false);
  };

  return (
    <Box className={Styles.ToggleableContentMobile}>
      <Box className={Styles.HeaderContainer}>
        <Box className={Styles.AlignContainer}>
          <Typography fontSize={18} fontWeight={700}>
            {name}
          </Typography>
          <Box
            className={Styles.AlignContainer}
            onMouseEnter={handleIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
          >
            <AssetIcon src={`${icon}`} size="xxxl" sx={{ marginLeft: '5px' }} />
          </Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={toggleContentVisibility}>
          <AssetIcon
            src="chevron-down"
            size="l"
            className={Styles.IconTransition}
            sx={{
              rotate: `${isContentVisible ? '0deg' : '180deg'}`,
              color: '#000000',
            }}
          />
        </Box>
      </Box>
      {isContentVisible && <Box>{children}</Box>}
      <Box sx={{ marginTop: isContentVisible ? '20px' : '0px' }}>
        <Divider />
      </Box>
    </Box>
  );
};
