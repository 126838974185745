import { Drawer } from '@mui/material';
import { DrawerAnchorType } from '@contracts/domain/keys';

type Props = {
  name: string;
  open: boolean;
  onClose?: () => void;
  children: JSX.Element | JSX.Element[];
  anchor?: DrawerAnchorType;
};

export const SetupDrawer: React.FC<Props> = ({
  name,
  open,
  onClose,
  children,
  anchor = 'right',
}) => {
  return (
    <Drawer anchor={anchor} open={open} onClose={onClose} data-at-modal={name}>
      {children}
    </Drawer>
  );
};
