import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// GET
export const getActivity = createAsyncThunk('vehicle/getActivity', async (id: string, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get(`/v1/activity/${id}`)
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error.message || 'Rejected');
    });
});

// Type
const initialState = {
  activity: [],
};

const activitySlice = createSlice({
  name: 'activitySlice',
  initialState,
  reducers: {
    setActivity: (state, { payload }) => {
      state.activity = payload;
    },
  },
  extraReducers: {
    [getActivity.fulfilled?.type]: (state, { payload }) => {
      state.activity = payload.slice().reverse();
    },
  },
});

export const { reducer: activityReducer } = activitySlice;
export const { setActivity } = activitySlice.actions;
