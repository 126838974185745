import React from 'react';

// System
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Redux
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';
import { setShowIoTLinkModal } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Types
import { AppDispatch } from 'src/app/store';
import FormProvider from '@Components/hook-form/FormProvider';
import { RHFTextField } from '@Components/hook-form';

// Styles
import Styles from './SetupOperatorLinkIotDrawer.module.scss';

export const SetupOperatorLinkIotDrawer: React.FC = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const {
    fetchedVehicle,
    showOperatorIoTModal,
    handleSubmitLinkIoTForm,
    linkIotMethods,
    isLinkIoTSubmittable,
  } = useOperatorVehicles();

  return (
    <SetupDrawer
      name="SetupOperatorLinkIotDrawer"
      open={showOperatorIoTModal}
      onClose={() => dispatch(setShowIoTLinkModal(false))}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorLinkIotDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Link IoT
          </Typography>
          <Box className={Styles.CloseIcon} onClick={() => dispatch(setShowIoTLinkModal(false))}>
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <FormProvider methods={linkIotMethods} onSubmit={handleSubmitLinkIoTForm}>
          <Box className={Styles.TextContainer}>
            <RHFTextField
              id="iotImei"
              name="iotImei"
              label="Current IoT"
              variant="outlined"
              value={fetchedVehicle?.iotImei}
              disabled
              className={Styles.InputField}
            />
            <RHFTextField
              id="iotImei"
              name="iotImei"
              label="New IoT"
              variant="outlined"
              className={Styles.InputField}
            />
            <Button
              className={isLinkIoTSubmittable ? Styles.ConfirmCTA_Active : Styles.ConfirmCTA}
              variant="contained"
              type="submit"
              disabled={!isLinkIoTSubmittable}
            >
              Confirm
            </Button>
          </Box>
        </FormProvider>
      </Box>
    </SetupDrawer>
  );
};
