import React, { useCallback, useState } from 'react';

// System
import { useTheme } from '@mui/material/styles';
import { Button, MenuItem, Stack, Typography } from '@mui/material';

// Form
import FormProvider, { RHFSelect, RHFTextField } from '@Components/hook-form';
import { useCreatePricingForm } from '@Forms/pricing/CreatePricing/CreatePricingFormContext';

// Redux
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useCreatePricing } from '@Modal/hooks/pricing/useCreatePricing';
import { useVehicleCategoryTable } from '@Modal/hooks/vehicles/category/useVehicleCategoryTable';

// Types
import { BranchesType } from '@Modal/reducers/branches/types';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';

export const CreatePricingForm = () => {
  const { palette } = useTheme();

  const { vehicleTypes } = useGeneralStore();
  const { branches, brands } = useBranches();
  const { vehicleCategories, handleGetVehicleCategoriesList } = useVehicleCategoryTable();
  const { submittable, handleSubmitCreatePricing, handleCreateCancel } = useCreatePricing();

  const { setValue } = useCreatePricingForm();

  const secondLvlBranches = branches?.filter((branch) => branch.level === 2);

  const methods = useCreatePricingForm();

  const [thidLevelBranches, setThirdLevelBranches] = useState<BranchesType[]>([]);

  const handleThirdLvlBranchChange = useCallback(
    (selectedBranch: BranchesType) => {
      setValue('thirdLvlBranchId', '');
      setValue('categoryId', '');

      const filteredBranches: BranchesType[] = branches.filter(
        (branch) => branch.parentId == selectedBranch.id
      );

      const secondLevelBranchParent: BranchesType | undefined = brands.find(
        (brand) => selectedBranch.parentId === brand.id
      );

      handleGetVehicleCategoriesList(
        {
          brandId: secondLevelBranchParent?.id || '',
          offset: 0,
          limit: 25,
        },
        secondLevelBranchParent?.id || ''
      );
      setThirdLevelBranches(filteredBranches);
    },
    [methods, branches]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmitCreatePricing}>
      <RHFTextField
        id="name"
        name="name"
        label="Pricing name"
        variant="outlined"
        sx={{ width: '100%', marginTop: '30px', marginBottom: '15px' }}
      />
      <RHFSelect
        label="2nd level branch"
        name="secondLvlBranchId"
        variant="outlined"
        sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
      >
        {secondLvlBranches?.map((branch) => {
          return (
            <MenuItem onClick={() => handleThirdLvlBranchChange(branch)} value={`${branch.id}`}>
              {branch.name}
            </MenuItem>
          );
        })}
      </RHFSelect>
      <RHFSelect
        label="3rd level branch"
        name="thirdLvlBranchId"
        variant="outlined"
        // disabled={!(formValues?.thirdLvlBranchId && formValues?.thirdLvlBranchId > 0)}
        sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
      >
        {thidLevelBranches.map((branch) => {
          return <MenuItem value={branch.id}>{branch.name}</MenuItem>;
        })}
      </RHFSelect>
      <RHFSelect
        label="Vehicle Type"
        name="vehicleType"
        variant="outlined"
        sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
      >
        {vehicleTypes?.map((veh) => {
          return (
            <MenuItem key={veh.value} value={veh.value}>
              {veh.key}
            </MenuItem>
          );
        })}
      </RHFSelect>
      <RHFSelect
        label="Vehicle Category"
        name="categoryId"
        variant="outlined"
        sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
      >
        {vehicleCategories.map((cat) => {
          return <MenuItem value={cat.id}>{cat.name}</MenuItem>;
        })}
      </RHFSelect>
      <AtLocator type="readonly-text" name="Title">
        <Typography sx={{ marginBottom: '15px', fontSize: '14px !important' }} variant="h1">
          Pricing
        </Typography>
      </AtLocator>
      <Stack direction="row" display="flex" gap={2} alignItems="center">
        <RHFTextField
          id="unlockPrice"
          name="unlockPrice"
          label="Unlock Price"
          variant="outlined"
          type="number"
          sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
        />
        <RHFTextField
          id="minutePrice"
          name="minutePrice"
          label="Minute Price"
          variant="outlined"
          type="number"
          sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
        />
        <RHFTextField
          id="parkingPrice"
          name="parkingPrice"
          label="Parking Price"
          variant="outlined"
          type="number"
          sx={{ width: '100%', marginTop: '6px', marginBottom: '15px' }}
        />
      </Stack>
      <Stack direction="row" display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          variant="outlined"
          sx={{ marginRight: '8px', color: palette.grey[800], borderColor: palette.grey[400] }}
          onClick={handleCreateCancel}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained" disabled={!submittable}>
          Confirm
        </Button>
      </Stack>
    </FormProvider>
  );
};
