import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';
import { TransactionRefundType } from './types';
import { axiosPaymentsInstance } from '@utils/axiosInstances';
import axios from 'axios';

// GET
export const getSelectedTransaction = createAsyncThunk(
  'customer/getSelectedTransaction',
  async (transactionId: string, thunkApi) => {
    return (
      axiosCustomerManagementInstance
        //   TODO update API
        .get(`/transactions/${transactionId}`)
        .then((res) => {
          return thunkApi.fulfillWithValue(res.data);
        })
        .catch((error) => {
          return thunkApi.rejectWithValue(error.message || 'Rejected');
        })
    );
  }
);

// POST Transaction Refund
export const createTransactionRefund = createAsyncThunk(
  'customer/createTransactionRefund',
  async (transactionData: TransactionRefundType, thunkApi) => {
    const config = {
      headers: {
        'x-private-key': process.env.REACT_APP_SCROLL_MOBILE_PRIVATE,
      },
    };
    const requestBody = {
      amount: transactionData.amount,
    };
    return axiosPaymentsInstance
      .post(`/payments/orders/refund/${transactionData.id}`, requestBody, config)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showTransactionDrawer: false,
  selectedTransactionData: {},
  showTransactionRefundModalDialog: false,
  showConfirmTransactionRefundModalDialog: false,
};

const transactionsDrawerSlice = createSlice({
  name: 'transactionDrawer',
  initialState,
  reducers: {
    setShowTransactionDrawer: (state, { payload }) => {
      state.showTransactionDrawer = payload;
    },
    setSelectedTransaction: (state, { payload }) => {
      state.selectedTransactionData = payload;
    },
    setShowTransactionRefundModalDialog: (state, { payload }) => {
      state.showTransactionRefundModalDialog = payload;
    },
    setShowConfirmTransactionRefundModalDialog: (state, { payload }) => {
      state.showConfirmTransactionRefundModalDialog = payload;
    },
  },
  extraReducers: {
    [getSelectedTransaction.fulfilled?.type]: (state, action) => {
      state.showTransactionDrawer = true;
      state.selectedTransactionData = action.payload;
    },
  },
});

export const { reducer: transactionsDrawerReducer } = transactionsDrawerSlice;
export const {
  setShowTransactionDrawer,
  setShowTransactionRefundModalDialog,
  setShowConfirmTransactionRefundModalDialog,
} = transactionsDrawerSlice.actions;
