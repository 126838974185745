import {
  defaultUserListFilter,
  userListColumnVisibilityOptions,
  defaultUserListColumnVisibility,
} from '@utils/tables/users/userListFilter';
// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultUserListFilter,
  columnVisibility: defaultUserListColumnVisibility,
  columnVisibilityOptions: userListColumnVisibilityOptions,
};

const userTableSlice = createSlice({
  name: 'userTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: userTableReducer } = userTableSlice;
export const { setColumnVisibility } = userTableSlice.actions;
