import React from 'react';

import { Button } from '@mui/material';
import FormProvider from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';

import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { useCreateVehicleForm } from '@Forms/vehicles/CreateVehicleFormContext';

import { CreateVehicleContent } from './CreateVehicleContent';
import { useCreateVehicle } from '@Modal/hooks/vehicles/vehicles/useCreateVehicle';

export const CreateVehicleForm = () => {
  const methods = useCreateVehicleForm();
  const { handleCreateCancel, submittable, handleSubmitCreateVehicle } = useCreateVehicle();

  return (
    <FormProvider methods={methods}>
      <CreateDialogLayout>
        <CreateVehicleContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateVehicle}
            >
              Confirm
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
