import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateBranchFormContext,
  CreateBranchFormState,
  createBranchFormStateDefault,
  createBranchSchemaDefault,
} from '@Forms/branches/CreateBranchFormContext';

export const CreateBranchFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<CreateBranchFormState>>>(createBranchSchemaDefault);
  const methods = useForm({
    defaultValues: createBranchFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateBranchFormContext.Provider value={value}>{children}</CreateBranchFormContext.Provider>
  );
};
