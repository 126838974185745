import { Button } from '@mui/material';
import FormProvider from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';

import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';

import { CreateUserContent } from './CreateUserContent';
import { useCreateAviatorUserForm } from '@Forms/roles/CreateAviatorUserFormContext';
import { useCreateAviatorUser } from '@Modal/hooks/users/useCreateUser';

export const CreateUserForm = () => {
  const methods = useCreateAviatorUserForm();
  const { handleCreateCancel, submittable, handleSubmitCreateAviatorUser } = useCreateAviatorUser();

  return (
    <FormProvider methods={methods}>
      <CreateDialogLayout>
        <CreateUserContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateAviatorUser}
            >
              Confirm
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
