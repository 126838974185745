import Scrollbar from '@Components/scrollbar/Scrollbar';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const DrawerTabPanelLayout: React.FC<Props> = ({ children }) => {
  return (
    <Scrollbar autoHide={false} forceVisible="y">
      {children}
    </Scrollbar>
  );
};
