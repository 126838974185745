/* eslint-disable object-shorthand */
import { ObjectSchema, object, string, number, mixed } from 'yup';

import { useMemo } from 'react';
import { SetupVehicleFormState } from '@Forms/vehicles/SetupVehicleFormContext';
import { VehicleCategoryDto } from '@contracts/api/dto/vehicles/vehicles/VehicleCategoryDto';
import { VehicleType } from '@contracts/domain/keys';
import { VehicleBranchDto } from '@contracts/api/dto/vehicles/vehicles/VehicleBranchDto';

export const useSetupVehicleUseCaseSchema = (): ObjectSchema<Partial<SetupVehicleFormState>> => {
  return useMemo(
    () =>
      object<SetupVehicleFormState>().shape({
        id: string().required('ID vehicle is required'),
        brandId: string().required('Brand ID branch is required'),
        name: string()
          .nullable()
          .matches(/([A-Z]?)\w+\s?/, 'Name should not include special symbols'),
        licensePlate: string().matches(
          /([A-Z]?)\w+\s?/,
          'License Plate should not include special symbols'
        ),
        vin: string().matches(/([A-Z]?)\w+\s?/, 'VIN should not include special symbols'),
        qrCode: string()
          .required('QR Code is required')
          .matches(/([A-Z]?)\w+\s?/, 'QR Code should not include special symbols'),
        categoryId: string()?.nullable(),
        serviceState: string().nullable(),
        iotStatus: string().nullable(),
        batteryLevel: string().nullable(),
        powerStatus: string().nullable(),
        chargingStatus: string().nullable(),
        stateOfCharge: number().nullable(),
        odometer: number().nullable(),
        lastSeenOnline: string(),
        lastMovement: string(),
        iotImei: string().nullable(),
        rentalStatus: string().nullable(),
        category: object<Partial<VehicleCategoryDto>>()
          .shape({
            name: string().nullable().defined(),
            vehicleType: mixed<VehicleType>()
              .oneOf(['SCOOTER', 'BIKE', 'MOPED'] as const)
              .nullable()
              .defined(),
            id: string(),
            branch: object<VehicleBranchDto>().shape({
              timezone: string().nullable().defined(),
            }),
          })
          .nullable(),
      }),
    []
  );
};
