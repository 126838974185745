import { useCallback, useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import { setColumnVisibility } from '@Modal/reducers/providers/table/providerTableSlice';
import { getUsersList, setPagination } from '@Modal/reducers/users/table/usersSlice';
import { useSnackbar } from 'notistack';

// Types
import { StoreInterface } from 'src/app/types';

export const useUsersTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { columnVisibility, columnVisibilityOptions, selectedFilters } = useSelector(
    (state: StoreInterface) => state?.usersTable
  );

  const providersListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);
    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...providersListFilters, ...column];
    if (providersListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = providersListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const fetchUsersTableContent = useCallback(() => {
    // @ts-ignore
    dispatch(getUsersList())
      .unwrap()
      .catch((error: any) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedFilters]);

  return {
    // State
    selectedFilters,
    columnVisibility,
    providersListFilters,
    visiblityCustomizable,
    columnVisibilityOptions,
    // Actions
    fetchUsersTableContent,
    handleApplyColumnVisibility,
  };
};
