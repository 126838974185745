import Label from '@Components/label/Label';
import { useTheme } from '@mui/material';
import { CustomerStatus } from '@contracts/domain/keys';
import { useCustomerStatusColor, useCustomerStatusTitle } from './useCustomerStatus';

type Props = { status: CustomerStatus };

export const CustomerStatusLabel: React.FC<Props> = ({ status }) => {
  const { palette } = useTheme();
  const color = useCustomerStatusColor()[status];
  const title = useCustomerStatusTitle()[status];

  return (
    <>
      {status && (
        <Label
          variant="filled"
          sx={{ backgroundColor: color, color: palette.success.contrastText }}
        >
          {title}
        </Label>
      )}
    </>
  );
};
