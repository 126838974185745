// System
import React from 'react';
import { useState, useEffect } from 'react';

// Components
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { Box } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { CustomerTransactionCard } from './CustomerTransactionCard';
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';

// Redux
import { useTransactions } from '@Modal/hooks/transactions/useTransactions';

export const CustomerTransactions: React.FC = () => {
  const { customerTransactions, handleCustomerTransactions } = useTransactions();
  const [loading, setLoading] = useState(false);
  const { handleTransactionRowClick } = useTransactionsDrawer();

  useEffect(() => {
    setLoading(true);
    handleCustomerTransactions();
    setLoading(false);
  }, []);

  return (
    <>
      <DrawerTabPanelLayout>
        {customerTransactions.length > 0 ? (
          customerTransactions?.map((transaction) => (
            <Box
              sx={{ marginY: 1, display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
              onClick={() => handleTransactionRowClick(transaction.externalTransactionId)}
            >
              <CustomerTransactionCard
                currency={transaction.currency}
                amount={transaction.amount}
                date={transaction.createdAt}
                type={transaction.transactionType}
                id={transaction.transactionId}
                status={transaction.status}
              />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50%',
              fontWeight: '600',
            }}
          >
            No Results
          </Box>
        )}
      </DrawerTabPanelLayout>
      {loading && (
        <AssetIcon
          src="rental-loading"
          sx={{ position: 'absolute', top: '90%', left: '286px', width: '20px', height: '20px' }}
        />
      )}
    </>
  );
};
