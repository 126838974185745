import {
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogRejectAction,
  CancelDialogConfirmAction,
} from '@Components/shared/dialogs/CancelDialog';

export const CreateIotModuleCancelModalDialog: React.FC = () => {
  // @TODO: Re-do this part using FSD approach
  // const { canceling } = useCreateIotModuleUseCaseStatus();
  // const { handleConfirmCancel, handleRejectCancel } = useCreateIotModuleUseCase();
  return (
    <>
      {/* {canceling && (
        <CancelModal open={canceling}>
          <ImageCancelDialogLayout
            title="Are you sure you want to cancel?"
            actions={[
              <CancelDialogRejectAction onClick={handleRejectCancel}>No</CancelDialogRejectAction>,
              <CancelDialogConfirmAction onClick={handleConfirmCancel}>
                Yes
              </CancelDialogConfirmAction>,
            ]}
          />
        </CancelModal>
      )} */}
    </>
  );
};
