import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  RentalEndRideFormContext,
  RentalEndRideFormState,
  RentalEndRideSchemaDefault,
  RentalEndRideFormStateDefault,
} from '@Forms/rentals/EndRide/RentalEndRideFormContext';

export const RentalEndRideFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<RentalEndRideFormState>>>(
    RentalEndRideSchemaDefault
  );
  const methods = useForm({
    defaultValues: RentalEndRideFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <RentalEndRideFormContext.Provider value={value}>{children}</RentalEndRideFormContext.Provider>
  );
};
