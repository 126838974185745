import { useCallback, useMemo, useState } from 'react';

// System
import { useSnackbar } from 'notistack';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
  setColumnVisibility,
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
} from '@Modal/reducers/rentals/rentalsTableSlice';
import { useRentals } from './useRentals';
import { useBranches } from '../branches/useBranch';
import { getRentalsList, setPagination } from '@Modal/reducers/rentals/rentalsSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { Paginable } from '@contracts/common/traits/Paginable';
import { FilterItemType } from '@Modal/reducers/types';

export const useRentalsTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const { rowsPerPage, columnVisibility, columnVisibilityOptions, page, selectedFilters } =
    useSelector((state: StoreInterface) => state?.rentalsTable);

  const { activeBrandId } = useBranches();
  const { rentals, pagination, loadingContent } = useRentals();

  const fetchRentalsTableContent = useCallback(() => {
    dispatch(getRentalsList({ selectedFilters, brandId: activeBrandId, sortingFilter: 'DESC' }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedFilters, activeBrandId]);

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const rentalsListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);

    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setSelectedFilters(filter));
  };

  const handleResetFilters = () => {
    dispatch(resetSelectedFilters());
  };

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...rentalsListFilters, ...column];

    if (rentalsListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = rentalsListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const handleTablePageChange = (filter: FilterItemType, pagination: Paginable) => {
    dispatch(setSelectedFilters(filter));
    dispatch(setPagination(pagination));
  };

  return {
    // State
    page,
    rentals,
    pagination,
    rowsPerPage,
    loadingContent,
    selectedFilters,
    columnVisibility,
    rentalsListFilters,
    columnVisibilityOptions,
    // Actions
    setPage,
    setRowsPerPage,
    setSelectedFilters,
    handleFilterSelect,
    handleResetFilters,
    handleTablePageChange,
    visiblityCustomizable,
    fetchRentalsTableContent,
    handleApplyColumnVisibility,
  };
};
