import { Stack, Typography } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { FieldRowLabel } from '@Components/shared/labels/FieldRowLabel';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

export const DeviceInfo = () => {
  const { selectedCustomerData } = useCustomersDrawer();
  const { lastKnownDevice, userAppLanguage, userAppVersion } = selectedCustomerData;
  return (
    <Stack direction="column" spacing={2}>
      <AtLocator type="readonly-text" name="DeviceInfo">
        <Typography variant="button">Device Info</Typography>
      </AtLocator>
      <Stack spacing={1.5}>
        <AtLocator type="readonly-text" name="LastKnownDevice">
          <FieldRowLabel label="Last known device" value={lastKnownDevice || 'N/A'} />
        </AtLocator>
        <AtLocator type="readonly-text" name="UserAppLanguage">
          <FieldRowLabel label="User App Language" value={userAppLanguage || 'N/A'} />
        </AtLocator>
        <AtLocator type="readonly-text" name="UserAppVersion">
          <FieldRowLabel label="User App Version" value={userAppVersion || 'N/A'} />
        </AtLocator>
      </Stack>
    </Stack>
  );
};
