import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreatePricingFormContext,
  CreatePricingFormStateDTO,
  createPricingFormStateDefault,
  createPricingSchemaDefault,
} from '@Forms/pricing/CreatePricing/CreatePricingFormContext';

export const CreatePricingFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreatePricingFormStateDTO>>>(
    createPricingSchemaDefault
  );
  const methods = useForm({
    defaultValues: createPricingFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreatePricingFormContext.Provider value={value}>{children}</CreatePricingFormContext.Provider>
  );
};
