import React, { useMemo } from 'react';
import { MarkerClusterer } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

import { generateIconByPriority } from '@Components/shared/maps/CustomMarkerIcons';

import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

import { HashTable } from '@utils/services/liveMapSocket';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

type Props = {
  markers: HashTable;
  clustering?: boolean;
};

const GoogleMarkersOperator = React.memo(({ markers, clustering }: Props) => {
  const { operationalState, outOfOrderState } = useBranchesMaps();

  const { handleSetApproachedVehicle, approachedVehicle } = useOperatorVehicles();

  const clusteredMarkers = useMemo(() => {
    return Object.keys(markers)
      .filter((marker) => marker !== 'undefined' && marker !== 'null')
      .map((marker) => ({
        id: marker,
        // @ts-ignore
        vehicle: markers[marker],
      }));
  }, [markers]);

  return (
    <div>
      {' '}
      {/* Wrap the content in a single parent element */}
      {clustering ? (
        <MarkerClusterer
          options={{
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            minimumClusterSize: 5,
          }}
        >
          {(clusterer) =>
            // @ts-ignore
            clusteredMarkers.map(({ id, vehicle }) => (
              <Marker
                key={id}
                icon={{
                  // @ts-ignore
                  url: generateIconByPriority(vehicle, {
                    operational: operationalState,
                    outOfOrder: outOfOrderState,
                  }),
                  scaledSize:
                    approachedVehicle?.id === vehicle?.id
                      ? new google.maps.Size(90, 90)
                      : new google.maps.Size(60, 60),
                }}
                position={{
                  lat: vehicle?.deviceInfo?.location?.latitude,
                  lng: vehicle?.deviceInfo?.location?.longitude,
                }}
                onClick={() => handleSetApproachedVehicle(true, vehicle)}
                clusterer={clusterer as any}
              />
            ))
          }
        </MarkerClusterer>
      ) : (
        clusteredMarkers.map(({ id, vehicle }) => (
          <Marker
            key={id}
            icon={{
              // @ts-ignore
              url: generateIconByPriority(vehicle, {
                operational: operationalState,
                outOfOrder: outOfOrderState,
              }),
              scaledSize:
                approachedVehicle?.id === vehicle?.id
                  ? new google.maps.Size(90, 90)
                  : new google.maps.Size(40, 40),
            }}
            position={{
              lat: vehicle?.deviceInfo?.location?.latitude,
              lng: vehicle?.deviceInfo?.location?.longitude,
            }}
            onClick={() => handleSetApproachedVehicle(true, vehicle)}
          />
        ))
      )}
    </div>
  );
});

export default GoogleMarkersOperator;
