// System
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Components
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { ActivityCard } from './ActivityCard';

// Redux
import { useActivity } from '@Modal/hooks/vehicles/activity/useActivity';

// Styles
import Styles from './Activity.module.scss';

export const Activity: React.FC = () => {
  const { activity, handleGetActivity } = useActivity();

  useEffect(() => {
    handleGetActivity();
  }, []);

  return (
    <DrawerTabPanelLayout>
      <DrawerTabSectionLayout>
        <Box className={Styles.ActivityCardsContainer}>
          {activity.map((card, index) => (
            <Box className={Styles.CardContainer}>
              <ActivityCard
                firstName={card?.account?.accountDetails[0]?.firstName}
                lastName={card?.account?.accountDetails[0]?.lastName}
                action={card?.action}
                createdAt={card?.createdAt}
              />
              {index < activity.length - 1 && (
                <Box className={Styles.DividerContainer}>
                  <AssetIcon src="activityDivider" size="xxxl" />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </DrawerTabSectionLayout>
    </DrawerTabPanelLayout>
  );
};
