import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ColorCircleLabel } from '@Components/shared/labels/ColorCircleLabel';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

export const AdditionalInfo = () => {
  const { selectedCustomerData } = useCustomersDrawer();
  const { termsAndConditions } = selectedCustomerData;
  const { palette } = useTheme();
  return (
    <Stack direction="column" spacing={2}>
      <AtLocator type="readonly-text" name="AdditionalInfo">
        <Typography variant="button">Additional Info</Typography>
      </AtLocator>
      <Stack spacing={1.5}>
        {/* <AtLocator type="readonly-text" name="branchId">
          <FieldRowLabel label="Home branch" value={branchId || 'N/A'} />
        </AtLocator> */}
        <Divider />
        <AtLocator type="special-field" name="newsletter">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2">Newsletter</Typography>
            <ColorCircleLabel
              color={
                selectedCustomerData?.customer?.newsletter
                  ? palette.success.main
                  : palette.error.main
              }
              title={selectedCustomerData?.customer?.newsletter ? 'Accepted' : 'Pending'}
              variant="ghost"
            />
          </Stack>
        </AtLocator>
        <AtLocator type="special-field" name="termsAndConditions">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2">Terms & Conditions</Typography>
            <ColorCircleLabel
              color={termsAndConditions ? palette.success.main : palette.error.main}
              title={termsAndConditions ? 'Accepted' : 'Pending'}
              variant="ghost"
            />
          </Stack>
        </AtLocator>
      </Stack>
    </Stack>
  );
};
