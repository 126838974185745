import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SetupAvTransactionDrawerHeader } from './SetupAvTransactionDrawerHeader';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { useAvTransactionDrawer } from '@Modal/hooks/transaction/useAvTransactionDrawer';
import { useDispatch } from 'react-redux';
import { AvTransactionDetails } from './AvTransactionDetails';

export const SetupAvTransactionDrawerContent: React.FC = () => {
  const dispatch = useDispatch();
  const { setShowAvTransactionDrawer } = useAvTransactionDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout
          currentSlug="/transactions"
          onClose={() => dispatch(setShowAvTransactionDrawer(false))}
        >
          <SetupAvTransactionDrawerHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          saveDisabled={true}
          onCancelClick={() => dispatch(setShowAvTransactionDrawer(false))}
        />
      }
    >
      <Stack>
        <Divider />
        <AvTransactionDetails />
      </Stack>
    </TabDrawerLayout>
  );
};
