// System
import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { Box } from '@mui/material';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { RoundItemDto } from '@contracts/api/dto/rounds/RoundsDto';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { useRoundsTable } from '@Modal/hooks/rounds/useRoundsTable';
import { StringTableColumnHeader } from '@Components/shared/tables/columns';
import { NumberRangeTableColumnHeader } from '@Components/shared/tables/columns/NumberRangeTableColumnHeader';
import { DateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/DateTableColumnHeader';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Contracts
import { Filterable } from '@contracts/common/traits/Filterable';
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';

// Utils
import {
  RoundListColumns,
  RoundListFilter,
  roundListColumns,
} from '@utils/tables/rounds/roundListFilter';
import { useDispatch } from 'react-redux';

type HeaderOptions = {
  filter: Filterable<RoundListFilter>;
};

type Columns = TableColumnDescriptionArray<RoundItemDto, HeaderOptions, typeof roundListColumns>;

type Column = TableColumnDescription<RoundItemDto, HeaderOptions, RoundListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useRoundTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();
  const dispatch = useDispatch();
  const { handleFilterSelect, setRoundTableFilters } = useRoundsTable();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'round._id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'id'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>{row?._id && <TruncateText text={row?._id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'round.multiplierCrash',
          title: 'Multiplier Crash',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <NumberRangeTableColumnHeader
              title={title}
              onApply={(startValue, endValue) => {
                startValue !== undefined
                  ? dispatch(
                      setRoundTableFilters({
                        codeName: 'minMultiplierCrash',
                        value: startValue,
                      })
                    )
                  : dispatch(
                      setRoundTableFilters({
                        codeName: 'minMultiplierCrash',
                        value: undefined,
                      })
                    );
                endValue !== undefined
                  ? dispatch(
                      setRoundTableFilters({
                        codeName: 'maxMultiplierCrash',
                        value: endValue,
                      })
                    )
                  : dispatch(
                      setRoundTableFilters({
                        codeName: 'maxMultiplierCrash',
                        value: undefined,
                      })
                    );
              }}
              onReset={() => {
                dispatch(
                  setRoundTableFilters({
                    codeName: 'minMultiplierCrash',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTableFilters({
                    codeName: 'maxMultiplierCrash',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.multiplierCrash}</Box>,
        },
        {
          name: 'round.createdAt',
          title: 'Created At',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                startDate?.length
                  ? dispatch(
                      setRoundTableFilters({
                        codeName: 'createdStartDate',
                        value: startDate,
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setRoundTableFilters({
                        codeName: 'createdEndDate',
                        value: endDate,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setRoundTableFilters({
                    codeName: 'createdStartDate',
                    value: undefined,
                  })
                );
                dispatch(
                  setRoundTableFilters({
                    codeName: 'createdEndDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'round.roundHash',
          title: 'Round Hash',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'roundHash'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {
                <>
                  {row?.roundHash && (
                    <TruncateText text={row?.roundHash} maxLength={8} textStyles={styles.id} />
                  )}
                </>
              }
            </Box>
          ),
        },
        {
          name: 'round.clientHash',
          title: 'Client Hash',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'clientHash'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {
                <>
                  {row?.clientHash && (
                    <TruncateText text={row?.clientHash} maxLength={8} textStyles={styles.id} />
                  )}
                </>
              }
            </Box>
          ),
        },
        {
          name: 'round.serverHash',
          title: 'Server Hash',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'serverHash'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.serverHash && (
                <TruncateText text={row?.serverHash} maxLength={8} textStyles={styles.id} />
              )}
            </Box>
          ),
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
