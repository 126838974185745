// System
import React from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Box, IconButton, Typography } from '@mui/material';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Styles
const NoteCardStyle = {
  width: '95%',
  padding: '16px',
  display: 'flex',
  gap: '12px',
  backgroundColor: '#F9FAFB',
  borderRadius: '8px',
  justifyContent: 'space-between',
};

const NoteCardAuthorInfoStyle = {
  display: 'flex',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
};

const DotStyle = {
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: '#637381',
};

// Types
import { AppDispatch } from 'src/app/store';
import { OperatorVehicleNoteType } from '@Modal/reducers/operator/operatorVehicles/types';
import { setShowOperatorDeleteNoteModal } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

type Prop = {
  note: OperatorVehicleNoteType;
};

export const OperatorNoteCard: React.FC<Prop> = ({ note }) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Box sx={NoteCardStyle} key={note.id}>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Avatar>NG</Avatar>
        <Box>
          <Typography fontSize={'14px'} fontWeight={600} lineHeight={'20px'}>
            {note?.note}
          </Typography>
          <Box sx={NoteCardAuthorInfoStyle}>
            <Typography fontSize={'12px'} color={'#637381'} fontWeight={400}>
              Nika Guladishvili
            </Typography>
            <Box sx={DotStyle} />
            <Typography fontSize={'12px'} color={'#637381'} fontWeight={400}>
              <DateLabel date={new Date(note?.createdAt)} timezone={undefined} format="long" />
            </Typography>
          </Box>
        </Box>
      </Box>
      <IconButton onClick={() => dispatch(setShowOperatorDeleteNoteModal({status:true,noteId:note?.id}))}>
        <AssetIcon src="trash-02" size="xxxl" />
      </IconButton>
    </Box>
  );
};
