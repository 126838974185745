import { useMemo } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import {
  setPage,
  setLimit,
  setTotal,
  setColumnVisibility,
  setCampaignTableFilters,
} from '@Modal/reducers/campaigns/table/campaignTableSlice';

// Types
import { StoreInterface } from 'src/app/types';
import { FilterItemType } from '@Modal/reducers/types';

export const useCampaignsTable = () => {
  const dispatch = useDispatch();

  const {
    page,
    limit,
    total,
    selectedFilters,
    columnVisibility,
    campaignTableFilters,
    columnVisibilityOptions,
  } = useSelector((state: StoreInterface) => state?.campaignsTable);

  const campaignsListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);
    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...campaignsListFilters, ...column];
    if (campaignsListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = campaignsListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setCampaignTableFilters(filter));
  };

  return {
    // State
    limit,
    page,
    total,
    selectedFilters,
    columnVisibility,
    campaignsListFilters,
    campaignTableFilters,
    visiblityCustomizable,
    columnVisibilityOptions,
    // Actions
    setLimit,
    setPage,
    setTotal,
    setCampaignTableFilters,
    handleFilterSelect,
    handleApplyColumnVisibility,
  };
};
