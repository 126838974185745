import { Button } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { useCreateBranchForm } from '@Forms/branches/CreateBranchFormContext';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';

import { CreateBranchContent } from './CreateBranchContent';
import { useCreateBranch } from '@Modal/hooks/branches/useCreateBranch';

export const CreateBranchForm: React.FC = () => {
  const methods = useCreateBranchForm();
  const { handleCreateCancel, handleSubmitCreateBranch, submittable } = useCreateBranch();

  return (
    <FormProvider {...methods}>
      <CreateDialogLayout>
        <CreateBranchContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateBranch}
            >
              Create branch
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
