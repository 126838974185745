// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  getSelectedIot,
  updateSelectedIot,
  setShowSelectedIotDrawer,
  setShowCancelIotDrawer,
} from '@Modal/reducers/vehicles/iot/iotDrawerSlice';

// Redux
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { updateIotList } from '@Modal/reducers/vehicles/vehiclesSlice';
// import { getVehicleDisplayNames, getVehicleTypes } from '@Modal/reducers/generalSlice';

// Hooks
import {
  useManageSetupIotModuleSchema,
  useSetupIotModuleForm,
} from '@Forms/vehicles/SetupIotModuleFormContext';
import {
  useSetupIotModuleUseCaseMapper,
  useSetupIotModuleUseCaseSchema,
} from '@Hooks/vehicles/SetupIotModule';

import { useIotTable } from './useIotsTable';

export const useIotDrawer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { vehicleDisplayNames, vehicleTypes } = useGeneralStore();
  const { vehiclesIOTModules } = useIotTable();

  const { showCancelIotDrawer, showSelectedIotDrawer, selectedIot } = useSelector(
    (state: StoreInterface) => state?.iotDrawer
  );

  // Other
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (!(vehicleTypes.length > 0)) dispatch(getVehicleTypes());
  //   if (!(vehicleDisplayNames.length > 0)) dispatch(getVehicleDisplayNames());
  // }, []);

  // Form Controls
  const { control, reset, handleSubmit } = useSetupIotModuleForm();

  const { setSchema } = useManageSetupIotModuleSchema();

  const mapper = useSetupIotModuleUseCaseMapper(selectedIot);

  const schema = useSetupIotModuleUseCaseSchema();

  const { isDirty, errors } = useFormState({ control });

  const handleIotClick = async (id: string) => {
    return dispatch(getSelectedIot(id))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error?.message, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const setIotDrawerMapperHandler = useCallback(async () => {
    setSchema(schema);
    const formState = await mapper.toFormState();
    reset(formState);
  }, [selectedIot]);

  useEffect(() => {
    if (selectedIot) {
      setIotDrawerMapperHandler();
    }
  }, [selectedIot, setIotDrawerMapperHandler]);

  // Form Submit
  const handleSubmitIotForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        const dto = mapper.toDto(formData);

        dispatch(updateSelectedIot(dto))
          .unwrap()
          .then(() => {
            const updatedIots = vehiclesIOTModules.map((iot: any) => {
              return iot?.id === dto?.id ? { ...iot, ...dto.body } : iot;
            });

            // @ts-ignore
            dispatch(updateIotList(updatedIots));
            reset();
          })
          .catch((error: string) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelIotDrawer(true));
    } else {
      reset();
      return dispatch(setShowSelectedIotDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelIotDrawer(false));
      dispatch(setShowSelectedIotDrawer(true));
    } else {
      reset();
      dispatch(setShowCancelIotDrawer(false));
      dispatch(setShowSelectedIotDrawer(false));
    }
  };

  return {
    // State
    showCancelIotDrawer,
    showSelectedIotDrawer,
    selectedIot,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    handleIotClick,
    setShowSelectedIotDrawer,
    handleSubmitIotForm,
    setShowCancelIotDrawer,
  };
};
