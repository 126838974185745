import { Stack, Typography, Box } from '@mui/material';
// System
import { format } from 'date-fns';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Redux
import { useDispatch } from 'react-redux';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

// Styles
import Styles from './GeneralInformation.module.scss';

export const GeneralInformation = () => {
  const { selectedRental } = useRentalsDrawer();
  const dispatch = useDispatch();

  const { qrCode, price, startTime, endTime, startLocation, endLocation } = selectedRental;

  const { handleVehicleRowClick } = useVehiclesDrawer();
  const { selectedCustomerData, setShowCustomerDrawer } = useCustomersDrawer();

  const handleCustomerClick = () => {
    dispatch(setShowCustomerDrawer(true));
  };

  return (
    <Stack className={Styles.GeneralInformation}>
      {/* vehicle */}
      <AtLocator type="readonly-text" name="vehicle">
        <Stack
          onClick={() => handleVehicleRowClick(selectedRental.vehicleId)}
          className={Styles.InfoContainer}
        >
          <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
            Vehicle
          </Typography>
          <Typography variant="subtitle2" data-at-readnly-text="value" className={Styles.InfoValue}>
            {qrCode ? `${qrCode}` : 'N/A'}
          </Typography>
        </Stack>
      </AtLocator>
      {/* customer */}
      <AtLocator type="readonly-text" name="customer">
        <Stack className={Styles.InfoContainer}>
          <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
            Customer
          </Typography>
          <Box>
            <Typography
              variant="subtitle2"
              data-at-readnly-text="Value"
              className={Styles.InfoValue}
              onClick={handleCustomerClick}
            >
              {selectedCustomerData?.id
                ? `${selectedCustomerData?.accountDetails[0]?.firstName} ${selectedCustomerData?.accountDetails[0]?.lastName}`
                : 'N/A'}
            </Typography>
          </Box>
        </Stack>
      </AtLocator>
      {/* value drive */}
      <Stack className={Styles.ContainerWithHeader}>
        <Typography variant="subtitle2" className={Styles.Header}>
          Value Drive
        </Typography>
        <AtLocator type="readonly-text" name="price">
          <Stack className={Styles.InfoContainer}>
            <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
              Price
            </Typography>
            <Typography variant="subtitle2" data-at-readnly-text="Value">
              {price ? `${price}` : 'N/A'}
            </Typography>
          </Stack>
        </AtLocator>
      </Stack>
      {/* location */}
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle2" className={Styles.ContainerWithHeader}>
          Location
        </Typography>
        <AtLocator type="readonly-text" name="start location">
          <Stack className={Styles.InfoContainer}>
            <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
              Start location
            </Typography>
            <Typography variant="subtitle2" data-at-readnly-text="Value">
              {startLocation ? startLocation : 'N/A'}
            </Typography>
          </Stack>
        </AtLocator>
        <AtLocator type="readonly-text" name="end location">
          <Stack className={Styles.InfoContainer}>
            <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
              End location
            </Typography>
            <Typography variant="subtitle2" data-at-readnly-text="Value">
              {endLocation ? endLocation : 'N/A'}
            </Typography>
          </Stack>
        </AtLocator>
      </Stack>
      {/* date time */}
      <Stack className={Styles.ContainerWithHeader}>
        <Typography variant="subtitle2" className={Styles.Header}>
          Date Time
        </Typography>
        <AtLocator type="readonly-text" name="start date">
          <Stack className={Styles.InfoContainer}>
            <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
              Start date
            </Typography>
            <Typography variant="subtitle2" data-at-readnly-text="Value">
              {startTime ? `${format(new Date(startTime), 'dd MMM, yyyy-HH:mm')}` : 'N/A'}
            </Typography>
          </Stack>
        </AtLocator>
        <AtLocator type="readonly-text" name="end date">
          <Stack className={Styles.InfoContainer}>
            <Typography variant="body2" data-at-readnly-text="Label" className={Styles.InfoLabel}>
              End date
            </Typography>
            <Typography variant="subtitle2" data-at-readnly-text="Value">
              {endTime ? `${format(new Date(endTime), 'dd MMM, yyyy-HH:mm')}` : 'N/A'}
            </Typography>
          </Stack>
        </AtLocator>
      </Stack>
    </Stack>
  );
};
