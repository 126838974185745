// System
import { BaseSyntheticEvent, useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  createVehicleIOTModule,
  setShowCancelVehicleIOTModuleForm,
  setShowCreateVehicleIOTModuleForm,
} from '@Modal/reducers/vehicles/iot/createVehicleIOTSlice';

// Hooks
import { useSnackbar } from 'notistack';
import { useBranches } from '../../branches/useBranch';

// Form
import { useFormState } from 'react-hook-form';
import { useCreateIotModuleForm } from '@Forms/vehicles/CreateIotModuleFormContext';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useIotTable } from './useIotsTable';

export const useCreateVehicleIOTModule = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { activeBrandId } = useBranches();

  // const { setVehicleIOTModulesList } = useVehicles();
  const { setVehicleIOTModulesList } = useIotTable();
  const { showCancelVehicleIOTModuleForm, showCreateVehicleIOTModuleForm } = useSelector(
    (state: StoreInterface) => state?.createVehicleIOTModule
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    dispatch(setShowCreateVehicleIOTModuleForm(false));
    reset();
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      reset();
      dispatch(setShowCancelVehicleIOTModuleForm(false));
      dispatch(setShowCreateVehicleIOTModuleForm(true));
    } else {
      reset();
      dispatch(setShowCancelVehicleIOTModuleForm(false));
      dispatch(setShowCreateVehicleIOTModuleForm(false));
    }
  };

  // Form Controls
  const { control, handleSubmit, reset } = useCreateIotModuleForm();
  const { isDirty, errors } = useFormState({ control });

  // Form Handlers
  const handleSubmitCreateVehicleIOTModule = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        if (activeBrandId) {
          dispatch(createVehicleIOTModule({ ...formData, brandId: activeBrandId }))
            .unwrap()
            .then((result) => {
              dispatch(setVehicleIOTModulesList(result));
              reset();
            })
            .catch((error) => {
              enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            });
        } else {
          enqueueSnackbar('Brand id is empty', {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    []
  );

  return {
    // Store
    showCancelVehicleIOTModuleForm,
    showCreateVehicleIOTModuleForm,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    setShowCreateVehicleIOTModuleForm,
    handleSubmitCreateVehicleIOTModule,
  };
};
