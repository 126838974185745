import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { SetupAddUserDrawerHeader } from './SetupAddUserDrawerHeader';
import { AddUser } from './AddUser';
import { SetupAddUserDrawerFooter } from './SetupAddUserDrawerFooter';
import { useAddUser } from '@Modal/hooks/roles/useAddUser';

export const SetupAddUserDrawerContent: React.FC = () => {
  const { handleSubmitAddUser, handleCreateCancel, submittable } = useAddUser();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/roles" onClose={() => handleCreateCancel()}>
          <SetupAddUserDrawerHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SetupAddUserDrawerFooter
          onSaveClick={handleSubmitAddUser}
          saveDisabled={!submittable}
          onCancelClick={() => handleCreateCancel()}
        />
      }
    >
      <Stack>
        <Divider />
        <AddUser />
      </Stack>
    </TabDrawerLayout>
  );
};
