import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Update instance
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';
import { GetRolesParams } from './types';

export const getRolesList = createAsyncThunk(
  'roles/getRoles',
  async (params: GetRolesParams, thunkApi) => {
    // Update after creating api
    return axiosCustomerManagementInstance
      .get('/roles', {
        params: { ...params.selectedFilters, brandId: params.brandId },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error);
      });
  }
);

// Type
const initialState = {
  roles: [],
  loadingContent: false,
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRolesList: (state, { payload }) => {
      state.roles = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
  },
  extraReducers: {
    [getRolesList.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;
      state.roles = action.payload;
      state.loadingContent = false;
      state.pagination = {
        ...state.pagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
  },
});

export const { reducer: rolesReducer } = rolesSlice;
export const { setRolesList, setPagination } = rolesSlice.actions;
