import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { FormProvider } from 'react-hook-form';
import { useSetupIotModuleForm } from '@Forms/vehicles/SetupIotModuleFormContext';
import { Stack } from '@mui/system';
import { GeneralInfo } from './Information/GeneralInfo';
import { AdditionalInfo } from './Information/AdditionalInfo';

export const SetupIotModuleDetails = () => {
  const methods = useSetupIotModuleForm();

  return (
    <FormProvider {...methods}>
      <DrawerTabSectionLayout>
        <Stack direction="column" spacing={8}>
          {/* <GeneralInfo /> */}
          <AdditionalInfo />
        </Stack>
      </DrawerTabSectionLayout>
    </FormProvider>
  );
};
