import React from 'react';

import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { CreateVehicleCategoryForm } from './CreateVehicleCategoryForm';
import { useCreateVehicleCategory } from '@Modal/hooks/vehicles/category/useCreateVehicleCategory';

export const CreateVehicleCategoryModalDialog = () => {
  const { showCreateVehicleCategoryForm, handleCreateCancel } = useCreateVehicleCategory();

  return (
    <>
      {showCreateVehicleCategoryForm && (
        <ClosableModal
          name="CategoryCreation"
          title="Add category"
          open={showCreateVehicleCategoryForm}
          onClose={handleCreateCancel}
        >
          <CreateVehicleCategoryForm />
        </ClosableModal>
      )}
    </>
  );
};
