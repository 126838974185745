// System
import { Suspense, useMemo } from 'react';
import { Tabs, Tab, Box, CircularProgress, Divider } from '@mui/material';

// Components
import { useTabs } from '@Components/shared/hooks/useTabs';

// Features
import { UsersList } from '@Features/users/UsersList';
import { UserTitle } from '@Features/users/UserTitle/UserTitle';
import { AviatorRolesList } from '@Features/users/AviatorRolesList';
import { AviatorRoleTitle } from '@Features/users/AviatorRoleTitle/AviatorRoleTitle';

const panels = [UsersList, AviatorRolesList];
const titles = [UserTitle, AviatorRoleTitle];

export const UsersPage = () => {
  const { currentTab, handleSwithTab } = useTabs(0);
  const Component = useMemo(() => panels[currentTab], [currentTab]);
  const Title = useMemo(() => titles[currentTab], [currentTab]);
  return (
    <>
      <Title />
      <Tabs value={currentTab} onChange={handleSwithTab}>
        <Tab label="Users" />
        <Tab label="Roles" />
      </Tabs>
      <Divider />
      <Box sx={{ paddingTop: 3, paddingBottom: 2 }}>
        <Suspense fallback={<CircularProgress />}>
          <Component />
        </Suspense>
      </Box>
    </>
  );
};
