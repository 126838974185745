import { number, object, ObjectSchema, string } from 'yup';

import { createContext, Dispatch, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { noop } from '@utils/noop';

export type CreateCategoryFormState = {
  name: string;
  vehicleType: string;
};

export const createCategoryFormStateDefault: CreateCategoryFormState = {
  name: '',
  vehicleType: '',
};

export const createCategorySchemaDefault: ObjectSchema<Partial<CreateCategoryFormState>> = object<
  Partial<CreateCategoryFormState>
>().shape({
  name: string().required(),
  vehicleType: string().required(),
});

type CreateCategoryFormContextType = {
  schema: ObjectSchema<Partial<CreateCategoryFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateCategoryFormState>>>;
  methods?: UseFormReturn<CreateCategoryFormState>;
};

const createCategoryFormContextDefault = {
  schema: createCategorySchemaDefault,
  setSchema: noop,
} satisfies CreateCategoryFormContextType;

export const CreateCategoryFormContext = createContext<CreateCategoryFormContextType>(
  createCategoryFormContextDefault
);

export const useCreateCategoryForm = () => {
  const { methods } = useContext(CreateCategoryFormContext);
  if (!methods) {
    throw new Error('CreateCategoryFormContext is empty');
  }
  return methods;
};

export const useManageCreateCategorySchema = () => {
  const { setSchema } = useContext(CreateCategoryFormContext);

  return { setSchema };
};
