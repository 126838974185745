import { useMemo } from 'react';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { RHFSelect, RHFTextField } from '@Components/hook-form';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';

const useDropDown = () => {
  const { currencies } = useGeneralStore();
  const currencyDropDown = useMemo(
    () =>
      currencies?.map((name) => (
        <MenuItemEx key={name} value={name} name="Currency">
          {name}
        </MenuItemEx>
      )),
    []
  );
  return {
    currencies: currencyDropDown,
  };
};

export const BillingInfo = () => {
  const dropDown = useDropDown();
  return (
    <Stack direction="column" spacing={2}>
      <AtLocator type="readonly-text" name="BillingInfo">
        <Typography variant="button">Billing info</Typography>
      </AtLocator>
      <Stack direction="column" spacing={1.5}>
        <AtLocator type="select-field" name="currency">
          <RHFSelect
            label="Currency"
            name="currency"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssetIcon src="globe-01" size="xxxl" sx={{ color: 'text.primary' }} />
                </InputAdornment>
              ),
            }}
          >
            {dropDown.currencies}
          </RHFSelect>
        </AtLocator>
        <AtLocator type="text-field" name="vat">
          <RHFTextField
            id="setup-branch-vat"
            label="VAT Percentage"
            variant="outlined"
            name="vat"
            type="number"
            onKeyPress={(event) => {
              const allowedChars = /^[0-9.]+$/
              if (!event.key.match(allowedChars)) {
                event.preventDefault();
              }
            }}
          />
        </AtLocator>
        <Stack direction="column" spacing={1}>
          <AtLocator type="text-field" name="code">
            <RHFTextField
              id="setup-branch-code"
              label="Invoice code"
              variant="outlined"
              name="code"
              type="number"
            />
          </AtLocator>
          <Box sx={{ paddingLeft: 1.5 }}>
            <AtLocator type="readonly-text" name="CodeDescription">
              <Typography variant="caption" display="block" color="text.secondary">
                Shown in invoices
              </Typography>
            </AtLocator>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
