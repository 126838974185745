import { BaseSyntheticEvent, useMemo } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowCreateProviderForm,
  setShowCancelProviderForm,
} from '@Modal/reducers/providers/createProvider/CreateProviderSlice';

// Form
import { useFormState } from 'react-hook-form';
import { useCreateProviderForm } from '@Forms/providers/CreateProviderFormContext';

// System
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useCreateProviderMutation, useGetProvidersQuery } from '@Src/generated.graphql';

export const useCreateProvider = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const [createProvider] = useCreateProviderMutation();
  const { refetch: refetchProviders } = useGetProvidersQuery();

  const { showCancelProviderForm, showCreateProviderForm } = useSelector(
    (state: StoreInterface) => state?.createProvider
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelProviderForm(true));
    } else {
      return dispatch(setShowCreateProviderForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelProviderForm(false));
      dispatch(setShowCreateProviderForm(true));
    } else {
      reset();
      dispatch(setShowCancelProviderForm(false));
      dispatch(setShowCreateProviderForm(false));
    }
  };

  // Form Controls
  const { control, handleSubmit, reset } = useCreateProviderForm();
  const { isDirty, errors } = useFormState({ control });

  const handleSubmitCreateProvider = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        let betSuggestions = formData.betSuggestions;

        if (typeof betSuggestions === 'string') {
          // @ts-ignore
          betSuggestions = betSuggestions.split(',').map((num) => Number(num.trim()));
        }
        const data = {
          ...formData,
          betSuggestions,
        };
        try {
          await createProvider({
            // @ts-ignore
            variables: { input: data },
          });

          enqueueSnackbar('Provider was successfully created', {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });

          // Refetch providers
          await refetchProviders();

          reset();
          dispatch(setShowCreateProviderForm(false));
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    [createProvider]
  );

  return {
    // Store
    showCancelProviderForm,
    showCreateProviderForm,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    setShowCreateProviderForm,
    handleSubmitCreateProvider,
  };
};
