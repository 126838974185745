import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  setCustomerPaymentMethods,
  getCustomerPaymentMethods,
  setSelectedPaymentMethodId,
  deleteCustomerPaymentMethod,
  setShowDeleteCustomerPaymentMethod,
} from '@Modal/reducers/customers/paymentMethods/paymentMethodSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

export const useCustomerPaymentMethods = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { paymentMethods, showDeleteCustomerPaymentMethod, selectedPaymentMethodId } = useSelector(
    (state: StoreInterface) => state?.paymentMethods
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Get customer payment methods
  const handleGetCustomerPaymentMethods = (id: string) => {
    if (id) {
      dispatch(getCustomerPaymentMethods(id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 3000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  // Payment method dialog Handler
  const handleDeletePaymentMethodDialog = () => {
    return dispatch(setShowDeleteCustomerPaymentMethod(true));
  };

  // Customer payment method delete handler
  const deleteCustomerPaymentMethodHandler = () => {
    return dispatch(deleteCustomerPaymentMethod(selectedPaymentMethodId))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Payment method has been deleted.', {
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 3000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  // Handle dialog cancel
  const handleDeleteCancel = () => {
    return dispatch(setShowDeleteCustomerPaymentMethod(false));
  };

  // Handle select paymentMethodID
  const handleDeletePaymentMethodClick = (id: number | undefined) => {
    dispatch(setShowDeleteCustomerPaymentMethod(true));
    dispatch(setSelectedPaymentMethodId(id));
  };

  return {
    // Store
    paymentMethods,
    showDeleteCustomerPaymentMethod,
    // Actions
    handleDeleteCancel,
    setCustomerPaymentMethods,
    handleDeletePaymentMethodClick,
    handleDeletePaymentMethodDialog,
    handleGetCustomerPaymentMethods,
    deleteCustomerPaymentMethodHandler,
  };
};
