import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const ResumeEventIcon = ({ width, height }: CardProps) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill="#D8FBDE" />
      <g clipPath="url(#clip0_24113_10450)">
        <path
          d="M18 26.3334C22.6023 26.3334 26.3333 22.6024 26.3333 18C26.3333 13.3976 22.6023 9.66669 18 9.66669C13.3976 9.66669 9.66663 13.3976 9.66663 18C9.66663 22.6024 13.3976 26.3334 18 26.3334Z"
          stroke="#36B37E"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9166 15.4711C15.9166 15.0734 15.9166 14.8745 15.9997 14.7635C16.0722 14.6667 16.1831 14.6062 16.3036 14.5976C16.4419 14.5877 16.6092 14.6953 16.9438 14.9103L20.8776 17.4392C21.1679 17.6259 21.3131 17.7192 21.3632 17.8378C21.4071 17.9415 21.4071 18.0585 21.3632 18.1622C21.3131 18.2809 21.1679 18.3742 20.8776 18.5608L16.9438 21.0897C16.6092 21.3048 16.4419 21.4123 16.3036 21.4024C16.1831 21.3938 16.0722 21.3333 15.9997 21.2365C15.9166 21.1255 15.9166 20.9266 15.9166 20.5289V15.4711Z"
          stroke="#36B37E"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_24113_10450">
          <rect width="20" height="20" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  </svg>
);
