// Types
import { StoreInterface } from 'src/app/types';

// Redux
import { useSelector } from 'react-redux';

export const useGeneralStore = () => {
  // Redux
  const {
    timezones,
    mapZoneTypes,
    currencies,
    vehicleServiceStates,
    speedModes,
    vehicleTypes,
    vehicleDisplayNames,
  } = useSelector((state: StoreInterface) => state?.general);

  return {
    timezones,
    speedModes,
    currencies,
    mapZoneTypes,
    vehicleTypes,
    vehicleDisplayNames,
    vehicleServiceStates,
  };
};
