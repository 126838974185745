import { Drawer } from '@mui/material';

// Form
import { SetupTransactionDrawerContent } from './SetupTransactionDrawerContent';

// Redux
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';

export const SetupTransactionDrawer: React.FC = () => {
  const { showTransactionDrawer, handleDrawerCancel } = useTransactionsDrawer();

  return (
    <Drawer anchor="right" open={showTransactionDrawer} onClose={handleDrawerCancel}>
      <SetupTransactionDrawerContent />
    </Drawer>
  );
};
