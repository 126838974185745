import { FC, ComponentType, memo } from 'react';

type Props = {
  providers: ComponentType<{ children: JSX.Element }>[];
  children: JSX.Element;
};

export const ComposeProvider: FC<Props> = memo(({ providers, children }) => {
  return (
    <>
      {providers.reverse().reduce((accumulator, current) => {
        const Provider = current;
        return <Provider>{accumulator}</Provider>;
      }, children)}
    </>
  );
});
