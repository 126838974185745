import {  createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  showCreateBranchForm: false,
  showCancelBranchForm:false,
};

const createBranchSlice = createSlice({
  name: 'createBranch',
  initialState,
  reducers: {
    setShowCreateBranchForm: (state, { payload }) => {
      state.showCreateBranchForm = payload;
    },
    setShowCancelBranchForm: (state, { payload }) => {
      state.showCancelBranchForm = payload;
    },
  }
});

export const { reducer: createBranchReducer } = createBranchSlice;
export const {
  setShowCreateBranchForm,
  setShowCancelBranchForm
} = createBranchSlice.actions;
