// System
import { Box } from '@mui/system';
import { FormProvider } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Form
import { useSetupVehicleForm } from '@Forms/vehicles/SetupVehicleFormContext';

// Components
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Features
import { RentalsMedia } from '@Features/rentals/details/Media';
import { RentalsMapMedia } from '@Features/rentals/details/MapMediaDialog';
import { RentalInfo } from '@Features/rentals/details/RentalInfo/RentalInfo';
import { AdditionalDetails } from '@Features/rentals/details/AdditionalDetails';
import { GeneralInformation } from '@Features/rentals/details/GeneralInformation/GeneralInformation';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';
import { RentalEndRideButton } from '@Features/rentals/details/RentalEndRideButton/RentalEndRideButton';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

export const Details: React.FC = () => {
  const methods = useSetupVehicleForm();
  const { selectedRental } = useRentalsDrawer();
  const { getSelectedCustomer } = useCustomersDrawer();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRental?.customerId) {
      // @ts-ignore
      dispatch(getSelectedCustomer(selectedRental?.customerId));
    }
  }, [selectedRental]);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Box display={'flex'} flexDirection={'column'} gap={'25px'}>
            <RentalsMapMedia />
            <RentalEndRideButton />
            <RentalInfo />
            <GeneralInformation />
            <AdditionalDetails />
            <RentalsMedia />
          </Box>
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
