// System
import { Box, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

// Redux
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

export const SetupVehicleCategoryHeader: React.FC = () => {
  const ellipsis = useEllipsis();
  const { selectedVehicleCategory } = useVehicleCategoryDrawer();

  return (
    <Typography variant="h6" gutterBottom sx={{ margin: '0' }}>
      <AtLocator type="readonly-text" name="header">
        <Box sx={{ ...ellipsis, maxWidth: '300px' }}>{selectedVehicleCategory.name}</Box>
      </AtLocator>
    </Typography>
  );
};
