// Components
import {
  ImageCancelDialogLayout,
  CancelDialogConfirmAction,
  CancelDialogRejectAction,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';

// Redux
import { useLinkVehicleIot } from '@Modal/hooks/vehicles/vehicles/useLinkVehicleIot';

export const CreateVehicleIotLinkCancelDialog: React.FC = () => {
  const { handleCancelTakeMeBack, iotCancelShown } = useLinkVehicleIot();

  return (
    <>
      {iotCancelShown && (
        <CancelModal open={iotCancelShown}>
          <ImageCancelDialogLayout
            title="Are you sure you want to cancel?"
            actions={[
              <CancelDialogRejectAction onClick={() => handleCancelTakeMeBack('back')}>
                No
              </CancelDialogRejectAction>,
              <CancelDialogConfirmAction onClick={() => handleCancelTakeMeBack('cofirm')}>
                Yes
              </CancelDialogConfirmAction>,
            ]}
          />
        </CancelModal>
      )}
    </>
  );
};
