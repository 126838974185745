import { Stack, Typography, Button, Box } from '@mui/material';

import { AssetIcon } from '@Components/shared/images/AssetIcon';

type Props = {
  onBrowse?: () => void;
};
export const SingleFileUploadEmptyPlaceholder: React.FC<Props> = ({ onBrowse }) => {
  return (
    <Stack
      onClick={onBrowse}
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={0}
      padding={1.5}
    >
      <AssetIcon
        src="icons/maps/ic_cloud_upload"
        sx={{ width: '60px', height: '60px', color: 'text.disabled' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" color="text.disabled">
          Drop CSV here or
        </Typography>
        <Button
          variant="text"
          component="span"
          color="inherit"
          sx={{
            textDecoration: 'underline',
            fontWeight: 'normal',
            marginLeft: '-3px',
            marginRight: '-3px',
          }}
        >
          Browse
        </Button>
        <Typography variant="body2" sx={{ color: 'text.disabled' }}>
          from files
        </Typography>
      </Box>
    </Stack>
  );
};
