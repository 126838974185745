// System
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Hooks
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Features
import { OperatorReportsOption } from '../../OperatorReportsOption';
import { OperatorVehicleOptions } from '../../OperatorVehicleOptions';
import { OperatorVehicleCard } from '../../OperatorVehicleCard/OperatorVehicleCard';
import { OperatorVehicleDetailsTemplate } from '../../OperatorVehicleDetailsTemplate';

// Redux
import { setShowOperatorVehicleNotes } from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Types
import { AppDispatch } from 'src/app/store';

// Styles
import Styles from './SetupOperatorVehicleDrawer.module.scss';

// Redis
import { useRedis } from '@Modal/hooks/redis/useRedis';
import { getAddressFromLatLng } from '@utils/map/addressDecodes';

export const SetupOperatorVehicleDrawer = () => {
  const {
    vehicleNotes,
    fetchedVehicle,
    showVehicleModal,
    handleDrawerCommand,
    handleSetShowVehicleDrawer,
    handleGetOperatorVehicleNotes,
    handleOperatorVehicleDrawerDeploy,
  } = useOperatorVehicles();

  const { selectedRedisVehicle } = useRedis();

  const [lastLocation, setLastLocation] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();

  // Handlers
  const generateVehicleLastLocation = () => {
    if (selectedRedisVehicle) {
      return getAddressFromLatLng({
        lat: selectedRedisVehicle?.deviceInfo?.location?.latitude,
        lng: selectedRedisVehicle?.deviceInfo?.location?.longitude,
      }).then((result) => setLastLocation(result));
    } else {
      setLastLocation('N/A');
    }
  };

  useEffect(() => {
    generateVehicleLastLocation();
  }, [selectedRedisVehicle]);

  // Data
  const vehicleDetails = [
    {
      field: 'VIN',
      value: fetchedVehicle?.vin,
    },
    {
      field: 'License plate',
      value: fetchedVehicle?.licensePlate,
    },
    {
      field: 'Last location',
      value: lastLocation,
    },
  ];

  useEffect(() => {
    if (fetchedVehicle?.id) {
      handleGetOperatorVehicleNotes();
    }
  }, [fetchedVehicle]);

  return (
    <SetupDrawer
      name="SetupOperatorVehicleDrawer"
      open={showVehicleModal}
      onClose={() => handleSetShowVehicleDrawer(false)}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorVehicleDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Vehicle Details
          </Typography>
          <Box className={Styles.CloseIcon} onClick={() => handleSetShowVehicleDrawer(false)}>
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        {fetchedVehicle && (
          <Box className={Styles.VehicleCardContainer}>
            <OperatorVehicleCard vehicleData={fetchedVehicle} />
          </Box>
        )}
        <Box className={Styles.MainContentContainer}>
          {/* Operator vehicle action options */}
          <OperatorVehicleOptions />
          {/* Notes & Damages */}
          <Box className={Styles.ReportsContainer}>
            <OperatorReportsOption
              amount={vehicleNotes?.length}
              onClick={() => dispatch(setShowOperatorVehicleNotes(true))}
              title={'Notes'}
            />
            {/* <OperatorReportsOption amount={2} onClick={() => console.log('1')} title={'Damages'} /> */}
          </Box>
          <Box className={Styles.DetailsContainer}>
            {vehicleDetails.map((details) => {
              return (
                <OperatorVehicleDetailsTemplate
                  key={details.field}
                  field={details.field}
                  value={details.value}
                />
              );
            })}
          </Box>
        </Box>
        <Divider />
        <Box className={Styles.Footer}>
          <Button
            className={Styles.CloseBtn}
            color="inherit"
            variant="outlined"
            onClick={() => handleSetShowVehicleDrawer(false)}
          >
            Close
          </Button>
          <Button
            onClick={handleOperatorVehicleDrawerDeploy}
            className={Styles.DeployBtn}
            variant="contained"
          >
            Deploy
          </Button>
        </Box>
      </Box>
    </SetupDrawer>
  );
};
