import { useMemo } from 'react';

import { BranchForParent } from '@contracts/domain/BranchForParent';

import { Comparer } from '@contracts/common/Comparer';
import {
  fieldComparer,
  numberComparer,
  queueComparer,
  stringComparer,
} from '@utils/data/comparers';
import { useBranches } from '@Modal/hooks/branches/useBranch';

export const useBranchForParentList = (): BranchForParent[] | undefined => {
  const { branches, brands, activeBrandId } = useBranches();
  const comparer = useMemo(
    (): Comparer<BranchForParent> =>
      queueComparer(
        fieldComparer<BranchForParent, number>((r) => r.level, numberComparer),
        fieldComparer<BranchForParent, string>((r) => r.name, stringComparer())
      ),
    []
  );
  const list = useMemo(() => {
    const items = [...branches, ...brands]
      ?.filter(
        ({ id, parentId, level }) =>
          (level === 1 && id === activeBrandId) || (level === 2 && parentId === activeBrandId)
      )
      ?.map(({ id, name, parentName, level }) => ({
        id,
        name: level === 1 ? name : `${parentName} - ${name}`,
        level,
      }));
    // @ts-ignore
    items?.sort(comparer);
    return items;
  }, [branches, activeBrandId, comparer]);
  // @ts-ignore
  return list;
};
