import { Stack } from '@mui/material';
import React from 'react';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const CreateDialogFooterLayout: React.FC<Props> = ({ children }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
      {children}
    </Stack>
  );
};
