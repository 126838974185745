import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { EnumFilterValue } from '@contracts/common/EnumFilterValue';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { SortParams } from '@contracts/common/params/SortParams';

import { TransactionType, TransactionStatus, TransactionInitiator } from '@contracts/domain/keys';

export const transactionListColumns = [
  'transaction.id',
  // 'transaction.type',
  'transaction.paymentId',
  'transaction.customer',
  'transaction.amount',
  'transaction.date',
  'transaction.status',
  // 'transaction.paymentMethod',
  // 'transaction.initiator',
  // 'transaction.invoice',
] as const;

export type TransactionListColumns = (typeof transactionListColumns)[number];

export type transactionListFilter = {
  'transaction.id': string | undefined;
  // 'transaction.type': EnumFilterValue<TransactionType>;
  'transaction.paymentId': string | number | undefined;
  'transaction.customer': string | undefined;
  'transaction.amount': number | undefined;
  'transaction.date': string | undefined;
  'transaction.status': EnumFilterValue<TransactionStatus>;
  // 'transaction.paymentMethod': string | undefined;
  // 'transaction.initiator': EnumFilterValue<TransactionInitiator>;
  limit: number;
};

export const defaultTransactionListFilter: transactionListFilter = {
  'transaction.id': undefined,
  // 'transaction.type': {},
  'transaction.paymentId': undefined,
  'transaction.customer': undefined,
  'transaction.amount': undefined,
  'transaction.date': undefined,
  'transaction.status': {},
  // 'transaction.paymentMethod': undefined,
  // 'transaction.initiator': {},
  limit: 25,
};

export type TransactionListSortColumns =
  | 'transaction.id'
  | 'transaction.paymentId'
  | 'transaction.amount';

export type TransactionListSort = SortParams<TransactionListSortColumns> | undefined;

export const defaultTransactionListSort: TransactionListSort = undefined;

export const transactionListColumnVisibilitySettings = {
  'transaction.id': ColumnVisibilityType.Visible,
  // 'transaction.type': ColumnVisibilityType.Visible,
  'transaction.paymentId': ColumnVisibilityType.Visible,
  'transaction.customer': ColumnVisibilityType.Visible,
  'transaction.amount': ColumnVisibilityType.Visible,
  'transaction.date': ColumnVisibilityType.Visible,
  'transaction.status': ColumnVisibilityType.Visible,
  // 'transaction.paymentMethod': ColumnVisibilityType.Visible,
  // 'transaction.initiator': ColumnVisibilityType.Visible,
  // 'transaction.invoice': ColumnVisibilityType.Visible,
} satisfies ColumnVisibilitySettings<TransactionListColumns>;

export const defaultTransactionListColumnVisibility = defaultColumnVisibilityParams(
  transactionListColumnVisibilitySettings
);

export const transactionListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  transactionListColumns,
  transactionListColumnVisibilitySettings
);
