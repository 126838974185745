import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import { SetupRoleDrawerHeader } from './SetupRoleDrawerHeader';
import { SetupRoleDrawerFooter } from './SetupRoleDrawerFooter';
import { Role } from './Role';
import { useDispatch } from 'react-redux';
import { useRolesDrawer } from '@Modal/hooks/roles/useRolesDrawer';

export const SetupRoleDrawerContent: React.FC = () => {
  const dispatch = useDispatch();
  const { setShowRoleDrawer, setShowDeleteRoleModalDialog } = useRolesDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/roles" onClose={() => dispatch(setShowRoleDrawer(false))}>
          <SetupRoleDrawerHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SetupRoleDrawerFooter
          onCancelClick={() => dispatch(setShowRoleDrawer(false))}
          onDeleteClick={() => dispatch(setShowDeleteRoleModalDialog(true))}
        />
      }
    >
      <Stack>
        <Divider />
        <Role />
      </Stack>
    </TabDrawerLayout>
  );
};
