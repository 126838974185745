import { SyntheticEvent, useCallback, useState } from 'react';

export const useTabs = <T>(defaultTab: T) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);

  const handleSwithTab = useCallback((_: SyntheticEvent<Element, Event>, value: T) => {
    setCurrentTab(value);
  }, []);

  return { currentTab, setCurrentTab, handleSwithTab };
};
