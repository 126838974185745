// Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosAuthorizationInstance } from '@utils/axiosInstances';
import { CreateUserType } from '../types';

export const createUserReq = createAsyncThunk(
  'authorization/createRole',
  async (user: CreateUserType, thunkApi) => {
    const config = {
      headers: {
        'x-private-key': process.env.REACT_APP_BACKOFFICE_PRIVATE_KEY,
      },
    };

    return axiosAuthorizationInstance
      .post('/api/v1/accounts', user, config)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  showCreateUserForm: false,
  showCancelUserForm: false,
};

const createUserSlice = createSlice({
  name: 'createUser',
  initialState,
  reducers: {
    setShowCreateUserForm: (state, { payload }) => {
      state.showCreateUserForm = payload;
    },
    setShowCancelUserForm: (state, { payload }) => {
      state.showCancelUserForm = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: createUserReducer } = createUserSlice;
export const { setShowCreateUserForm, setShowCancelUserForm } = createUserSlice.actions;
