import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosRentalsInstance } from '@utils/axiosInstances';

// GET
export const getVehicleRentals = createAsyncThunk(
  'vehicle/getVehicleRentals',
  async (id: string, thunkApi) => {
    return axiosRentalsInstance
      .get(`/rentals/vehicle/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  vehicleRentals: [],
};

const vehicleRentalsSlice = createSlice({
  name: 'createCategory',
  initialState,
  reducers: {
    setVehicleRentals: (state, { payload }) => {
      state.vehicleRentals = payload;
    },
  },
  extraReducers: {
    [getVehicleRentals.fulfilled?.type]: (state, { payload }) => {
      state.vehicleRentals = payload;
    },
  },
});

export const { reducer: vehicleRentalsReducer } = vehicleRentalsSlice;
export const { setVehicleRentals } = vehicleRentalsSlice.actions;
