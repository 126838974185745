/* eslint-disable object-shorthand */
import { ObjectSchema, object, string } from 'yup';

import { useMemo } from 'react';
import { SetupLinkIoTFormStateDTO } from '@Forms/operator/SetupLinkIoTFormContext';

export const useSetupLinkIoTUseCaseSchema = (): ObjectSchema<Partial<SetupLinkIoTFormStateDTO>> => {
  return useMemo(
    () =>
      object<SetupLinkIoTFormStateDTO>().shape({
        iotImei: string().required('IoT  is required'),
      }),
    []
  );
};
