import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const CancelEventIcon = ({ width, height }: CardProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#FFE9D5" />
    <path
      d="M16.75 12.2849C17.1591 12.2068 17.5775 12.1667 18 12.1667C19.7681 12.1667 21.4638 12.869 22.714 14.1193C23.9642 15.3695 24.6666 17.0652 24.6666 18.8333C24.6666 19.2558 24.6265 19.6742 24.5484 20.0833M23.1129 23.1115C22.987 23.262 22.8539 23.4075 22.714 23.5474C21.4638 24.7976 19.7681 25.5 18 25.5C16.2318 25.5 14.5362 24.7976 13.2859 23.5474C12.0357 22.2971 11.3333 20.6014 11.3333 18.8333C11.3333 17.0652 12.0357 15.3695 13.2859 14.1193C13.4245 13.9807 13.5686 13.8488 13.7177 13.7239M11.3333 11.3333L9.66663 13M26.3333 13L23.8333 10.5M13 23.8333L11.3333 25.5M25.5 25.5L10.5 10.5"
      stroke="#FF5630"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
