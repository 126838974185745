import Scrollbar from '@Components/scrollbar/Scrollbar';
import { Box, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  sx?: SystemStyleObject<Theme>;
  children: JSX.Element | JSX.Element[];
};

export const SelectBoxDropDownLayout: React.FC<Props> = ({ children, sx }) => {
  return (
    <Box>
      <Scrollbar
        autoHide={false}
        forceVisible="y"
        sx={[{ maxHeight: '400px', padding: 1, width: '120px' }, sx ?? {}]}
      >
        <Box>{children}</Box>
      </Scrollbar>
    </Box>
  );
};
