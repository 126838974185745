type Options = {
  group?: string;
  numerals: string[];
};

const defaultOptions: Options = {
  numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

type ParseOptions = {
  sign?: boolean;
  group?: boolean;
};

const defaulParseOptions: ParseOptions = {
  sign: true,
};

const toRegExpChar = (char?: string): string => {
  return /\s/.test(char ?? '') ? '\\s' : char || '';
};

export const validateIntegerString = (
  parseOptions: ParseOptions = defaulParseOptions,
  options: Options = defaultOptions
) => {
  const signPart = parseOptions.sign ? '[+-]?' : '';
  const d = `[${options.numerals.join('')}]`;
  const integerPart = parseOptions.group
    ? `${d}{1,3}([${toRegExpChar(options.group)}]${d}{3})*`
    : `${d}+`;

  const validateRegExp = new RegExp(`^${signPart}${integerPart}$`);

  return (text: string) => validateRegExp.test(text);
};
