// System
import { Stack, Typography, Box } from '@mui/material';

// Styles
import Styles from './SetupProviderHeader.module.scss';
import { useProviderDrawer } from '@Modal/hooks/providers/useProviderDrawer';

export const SetupProviderHeader: React.FC = () => {
  const { selectedProviderData } = useProviderDrawer();
  return (
    <Stack className={Styles.SetupProviderHeader}>
      <Box className={Styles.QrCode}>
        <Typography variant="h6">{selectedProviderData.id}</Typography>
      </Box>
    </Stack>
  );
};
