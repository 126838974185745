/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
import { memo } from 'react';

import { assertUnreachable } from '@utils/assert';
import { AtPageLocator, AtPageLocatorProps } from './AtPageLocator';
import { AtContainerLocator, AtContainerLocatorProps } from './AtContainerLocator';
import { AtButtonLocator, AtButtonLocatorProps } from './AtButtonLocator';
import { AtPopoverLocator, AtPopoverLocatorProps } from './AtPopoverLocator';
import { AtSelectFieldLocator, AtSelectFieldLocatorProps } from './AtSelectFieldLocator';
import { AtAlertLocator, AtAlertLocatorProps } from './AtAlertLocator';
import { AtReadonlyTextLocator, AtReadonlyTextLocatorProps } from './AtReadonlyTextLocator';
import { AtTextFieldLocator, AtTextFieldLocatorProps } from './AtTextFieldLocator';
import { AtModalLocator, AtModalLocatorProps } from './AtModalAlertLocator';
import { AtSpecialFieldLocator, AtSpecialFieldLocatorProps } from './AtSpecialFieldLocator';
import { AtTabListLocator, AtTabListLocatorProps } from './AtTabListLocator';
import { AtSliderLocatorProps, AtSliderFieldLocator } from './AtSliderFieldLocator';
import { AtSwitchFieldLocator, AtSwitchFieldLocatorProps } from './AtSwitchFieldLocator';
import { AtTooltipLocator, AtTooltipLocatorProps } from './AtTooltipLocator';

type Props =
  | ({ type: 'page' } & AtPageLocatorProps)
  | ({ type: 'container' } & AtContainerLocatorProps)
  | ({ type: 'button' } & AtButtonLocatorProps)
  | ({ type: 'popover' } & AtPopoverLocatorProps)
  | ({ type: 'select-field' } & AtSelectFieldLocatorProps)
  | ({ type: 'text-field' } & AtTextFieldLocatorProps)
  | ({ type: 'special-field' } & AtSpecialFieldLocatorProps)
  | ({ type: 'alert' } & AtAlertLocatorProps)
  | ({ type: 'readonly-text' } & AtReadonlyTextLocatorProps)
  | ({ type: 'tab-list' } & AtTabListLocatorProps)
  | ({ type: 'modal' } & AtModalLocatorProps)
  | ({ type: 'slider-field' } & AtSliderLocatorProps)
  | ({ type: 'switch-field' } & AtSwitchFieldLocatorProps)
  | ({ type: 'tooltip' } & AtTooltipLocatorProps);

export const AtLocator: React.FC<Props> = (props: Props) => {
  switch (props.type) {
    case 'page': {
      const { type, name, ...rest } = props;
      return <AtPageLocator name={name} {...rest} />;
    }
    case 'button': {
      const { type, name, ...rest } = props;
      return <AtButtonLocator name={name} {...rest} />;
    }
    case 'container': {
      const { type, name, ...rest } = props;
      return <AtContainerLocator name={name} {...rest} />;
    }
    case 'popover': {
      const { type, name, ...rest } = props;
      return <AtPopoverLocator name={name} {...rest} />;
    }
    case 'select-field': {
      const { type, name, ...rest } = props;
      return <AtSelectFieldLocator name={name} {...rest} />;
    }
    case 'text-field': {
      const { type, name, ...rest } = props;
      return <AtTextFieldLocator name={name} {...rest} />;
    }
    case 'special-field': {
      const { type, name, ...rest } = props;
      return <AtSpecialFieldLocator name={name} {...rest} />;
    }
    case 'alert': {
      const { type, name, ...rest } = props;
      return <AtAlertLocator name={name} {...rest} />;
    }
    case 'readonly-text': {
      const { type, name, ...rest } = props;
      return <AtReadonlyTextLocator name={name} {...rest} />;
    }
    case 'tab-list': {
      const { type, name, ...rest } = props;
      return <AtTabListLocator name={name} {...rest} />;
    }
    case 'modal': {
      const { type, name, ...rest } = props;
      return <AtModalLocator name={name} {...rest} />;
    }
    case 'tooltip': {
      const { type, name, ...rest } = props;
      return <AtTooltipLocator name={name} {...rest} />;
    }
    case 'switch-field': {
      const { type, name, ...rest } = props;
      return <AtSwitchFieldLocator name={name} {...rest} />;
    }
    case 'slider-field': {
      const { type, name, ...rest } = props;
      return <AtSliderFieldLocator name={name} {...rest} />;
    }
    default: {
      assertUnreachable(props);
    }
  }
};
