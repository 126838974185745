import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const SlowModeEventIcon = ({ width, height }: CardProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#318688" fillOpacity="0.12" />
    <g clipPath="url(#clip0_24395_11792)">
      <path
        d="M13.2123 21.3333C12.5532 20.3884 12.1667 19.2393 12.1667 18C12.1667 17.5709 12.213 17.1527 12.301 16.75M22.7878 21.3333C23.4469 20.3885 23.8334 19.2394 23.8334 18C23.8334 14.7783 21.2217 12.1667 18 12.1667C17.6483 12.1667 17.3038 12.1978 16.9691 12.2575M14.2501 14.25L18.0001 18M9.66671 18C9.66671 22.6024 13.3977 26.3333 18 26.3333C22.6024 26.3333 26.3334 22.6024 26.3334 18C26.3334 13.3976 22.6024 9.66666 18 9.66666C13.3977 9.66666 9.66671 13.3976 9.66671 18ZM17.1667 18C17.1667 18.4602 17.5398 18.8333 18 18.8333C18.4603 18.8333 18.8334 18.4602 18.8334 18C18.8334 17.5398 18.4603 17.1667 18 17.1667C17.5398 17.1667 17.1667 17.5398 17.1667 18Z"
        stroke="#232625"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_24395_11792">
        <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 28 8)" />
      </clipPath>
    </defs>
  </svg>
);
