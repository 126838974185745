import { useCallback } from 'react';

// System
import { useSnackbar } from 'notistack';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
  setIotPagination,
  setVehicleIOTModulesList,
} from '@Modal/reducers/vehicles/iot/iotTableSlice';
import { getVehicleIotModulesList } from '@Modal/reducers/vehicles/iot/iotTableSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { FilterItemType } from '@Modal/reducers/types';
import { Paginable } from '@contracts/common/traits/Paginable';

export const useIotTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const { rowsPerPage, page, selectedFilters, iotPagination, vehiclesIOTModules, loadingContent } =
    useSelector((state: StoreInterface) => state?.iotsTable);

  const { activeBrandId } = useBranches();

  const fetchIotTableContent = useCallback(() => {
    dispatch(getVehicleIotModulesList({ selectedFilters, brandId: activeBrandId }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedFilters, activeBrandId]);

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setSelectedFilters(filter));
  };

  const handleResetFilters = () => dispatch(resetSelectedFilters());

  const handleIotTablePageChange = (filter: FilterItemType, pagination: Paginable) => {
    dispatch(setSelectedFilters(filter));
    dispatch(setIotPagination(pagination));
  };

  return {
    // State
    page,
    rowsPerPage,
    iotPagination,
    selectedFilters,
    vehiclesIOTModules,
    loadingContent,
    // Actions
    setPage,
    setRowsPerPage,
    setSelectedFilters,
    setVehicleIOTModulesList,
    handleResetFilters,
    handleFilterSelect,
    fetchIotTableContent,
    handleIotTablePageChange,
  };
};
