import { BaseSyntheticEvent, useMemo } from 'react';
// Types
import { StoreInterface } from 'src/app/types';
// Redux
import { useBranches } from './useBranch';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShowCancelBrandForm,
  setShowCreateBrandForm,
} from '@Modal/reducers/branches/createBrandSlice';

// Form
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { createNewBranch } from '@Modal/reducers/branches/branchSlice';

import { useCreateBrandForm } from '@Forms/branches/CreateBrandFormContext';
import { AppDispatch } from 'src/app/store';

export const useCreateBrand = () => {
  // Redux
  const { showCreateBrandForm, showCancelBrandForm } = useSelector(
    (state: StoreInterface) => state?.createBrandReducer
  );

  const { handleFetchBrands } = useBranches();

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, handleSubmit, reset } = useCreateBrandForm();
  const { isDirty } = useFormState({ control });

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelBrandForm(true));
    } else {
      return dispatch(setShowCreateBrandForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      reset();
      dispatch(setShowCancelBrandForm(false));
      dispatch(setShowCreateBrandForm(true));
    } else {
      reset();
      dispatch(setShowCancelBrandForm(false));
      dispatch(setShowCreateBrandForm(false));
    }
  };

  const handleSubmitCreateBrand = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          createNewBranch({
            name: formData?.name,
            level: 1,
            parentId: '',
          })
        )
          .unwrap()
          .then(() => {
            handleFetchBrands();
            handleCancelTakeMeBack('confirm');
            enqueueSnackbar('Brand is successfully created', {
              variant: 'success',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          })
          .catch((error: string) => {
            reset();
            enqueueSnackbar(error, {
              variant: 'error',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  return {
    submittable: isDirty,
    showCreateBrandForm,
    showCancelBrandForm,
    setShowCreateBrandForm,
    setShowCancelBrandForm,
    handleCreateCancel,
    handleCancelTakeMeBack,
    handleSubmitCreateBrand,
  };
};
