// System
import { Box } from '@mui/system';

// Features
import { RoundsTitle } from '@Features/rounds/RoundTitle/RoundsTitle';
import { RoundsList } from '@Features/rounds/RoundsList';
import { Divider } from '@mui/material';

export const RoundsPage = () => {
  return (
    <Box>
      <Box>
        <RoundsTitle />
        <Divider />
        <RoundsList />
      </Box>
    </Box>
  );
};
