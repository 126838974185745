import { create } from 'zustand';

export enum OpenSendCommandDialogName {
  Retire,
  MaxSpeed,
  None,
}

type Store = {
  openDialogName: OpenSendCommandDialogName;
  openRetireDialog: () => void;
  openMaxSpeedDialog: () => void;
  closeDialog: () => void;
};

export const useSendCommandDialog = create<Store>((set) => ({
  openDialogName: OpenSendCommandDialogName.None,
  openRetireDialog: () => set({ openDialogName: OpenSendCommandDialogName.Retire }),
  openMaxSpeedDialog: () => set({ openDialogName: OpenSendCommandDialogName.MaxSpeed }),
  closeDialog: () => set({ openDialogName: OpenSendCommandDialogName.None }),
}));
