import Cookies from 'js-cookie';

const AUTH_TOKEN_COOKIE = 'AuthToken';
const REFRESH_TOKEN_COOKIE = 'RefreshToken';

export const getCookieAuthToken = () => {
  const storedToken = Cookies.get(AUTH_TOKEN_COOKIE);
  return storedToken || '';
};

export const getCookieRefreshToken = () => {
  const storedToken = Cookies.get(REFRESH_TOKEN_COOKIE);
  return storedToken || '';
};
