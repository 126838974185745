// System
import { Box, Stack, Typography } from '@mui/material';

// Components
import Image from '@Components/image/Image';

// Features
import { IotStatusLabel } from '@Features/vehicles/shared/IotStatus';
import { BatteryLevelIconLabel } from '@Features/vehicles/shared/BatteryLevel';
import { VehicleServiceStateLabel } from '@Features/vehicles/shared/VehicleServiceState';

// Keys
import { BatteryLevel, IotStatus, VehicleServiceState } from '@contracts/domain/keys';

// Redux
import { BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Redis
import { useRedis } from '@Modal/hooks/redis/useRedis';

// Styles
import Styles from './OperatorVehicleCard.module.scss';

type Props = {
  vehicleData: BackofficeLiveVehicle;
};

export const OperatorVehicleCard: React.FC<Props> = ({ vehicleData }) => {
  const { approachedVehicle } = useOperatorVehicles();

  // Redis
  const { selectedRedisVehicle } = useRedis();

  // Redux
  const { category, qrCode } = vehicleData;

  return (
    <Box className={Styles.OperatorVehicleCard}>
      <Stack className={Styles.ImgBox} alignItems="center" justifyContent="center">
        <Image
          alt="empty_content"
          src={
            category?.vehicleType === 'BIKE'
              ? '/assets/illustrations/Bike_category.png'
              : category?.vehicleType === 'SCOOTER'
              ? '/assets/illustrations/scooter_category.png'
              : '/assets/illustrations/moped_category.jpg'
          }
          className={Styles.Img}
        />
      </Stack>
      <Box className={Styles.InformationContainer}>
        <Box className={Styles.Align}>
          <Typography fontWeight={900} fontSize={18}>
            {qrCode}
          </Typography>
          <Box className={Styles.IoTContainer}>
            <Typography fontWeight={600} fontSize={12}>
              IoT:
            </Typography>
            <IotStatusLabel status={selectedRedisVehicle?.iotStatus as IotStatus} />
          </Box>
        </Box>
        <Box className={Styles.BatteryAndServiceStateContainer}>
          <BatteryLevelIconLabel
            level={selectedRedisVehicle?.deviceInfo?.batteryLevel as BatteryLevel}
            batteryPercentage={
              selectedRedisVehicle?.deviceInfo?.batteryStage !== undefined
                ? +selectedRedisVehicle?.deviceInfo?.batteryStage
                : +approachedVehicle?.deviceInfo?.batteryStage
            }
          />
          <Box className={Styles.ServiceStateContainer}>
            <VehicleServiceStateLabel
              variant="ghost"
              status={selectedRedisVehicle?.serviceState as VehicleServiceState}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
