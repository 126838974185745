// System
import { Stack, Typography } from '@mui/material';

// Redux
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

export const AviatorRoleDetails = () => {
  const { selectedAviatorRoleData } = useAviatorRoleDrawer();
  const { permissions } = selectedAviatorRoleData;

  return (
    <Stack direction="column" spacing={3} padding="24px">
      <Typography variant="h5">Permissions</Typography>
      {permissions?.map((permission) => (
        <Typography key={permission}>
          {permission
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/^\w/, (c) => c.toUpperCase())}
        </Typography>
      ))}
    </Stack>
  );
};
