// System
import { parseISO } from 'date-fns';
import { Stack, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { DateLabel } from '@Components/shared/labels/DateLabel';

// Styles
import Styles from './ActivityCard.module.scss';

// Types
import { VehicleServiceStateEnum } from '@Features/maps/Filters/filters.enum';

type Props = {
  action: string;
  createdAt: string;
  firstName: string | null;
  lastName: string | null;
};

export const ActivityCard: React.FC<Props> = ({ action, createdAt, firstName, lastName }) => {
  const isActionServiceStateChange = Object.values(VehicleServiceStateEnum).includes(
    action as VehicleServiceStateEnum
  );

  return (
    <Stack className={Styles.ActivityCardContainer}>
      <AssetIcon src="activityIcon" size="xxxxxl" />
      <Stack className={Styles.TextContainer}>
        <Stack className={Styles.CommandText}>
          <Typography className={Styles.FontBold}>{`${firstName} ${lastName}`}</Typography>
          <Typography className={Styles.FontLight}>
            {isActionServiceStateChange ? 'update service state to' : 'send command'}
          </Typography>
          <Typography className={Styles.FontBold}>{`'${action}'`}</Typography>
        </Stack>
        <Typography className={Styles.DateFont}>
          {<DateLabel date={new Date(parseISO(createdAt))} format="long" />}
        </Typography>
      </Stack>
    </Stack>
  );
};
