import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { SystemStyleObject } from '@mui/system';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { AnyAction } from 'redux';
import { VehicleRentalsLabel } from './VehicleRentalsLabel';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: '66px',
      width: '544px',
      padding: 2,
      backgroundColor: palette.grey['100'],
      cursor: 'pointer',
      ...roundedBorder,
    },
    priceFont: {
      fontFamily: 'Public Sans',
      color: '#318688',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    dateFont: {
      fontFamily: 'Public Sans',
      color: '#637381',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    nameFont: {
      fontFamily: 'Public Sans',
      color: '#212B36',
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
    button: {
      width: '53px',
      height: '24px',
      paddingY: '2px',
      paddingX: '8px',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

interface RentalsCardProps {
  price: number | string;
  name: string;
  customerId: string;
  createdAt: string;
  state: string;
}

export const VehicleRentalsCard: React.FC<RentalsCardProps> = ({
  price,
  name,
  customerId,
  createdAt,
  state,
}) => {
  const styles = useSx();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={styles.container}
      spacing={2}
    >
      <Box
        sx={{
          display: 'flex',
          width: '160px',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle2" sx={styles.priceFont}>
          GEL {price}
        </Typography>
        <Typography variant="caption" sx={styles.dateFont}>
          <DateLabel date={new Date(createdAt)} format="long" timezone={undefined} />
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minWidth: '130px',
          margin: '0px',
        }}
      >
        <Typography variant="subtitle2" sx={styles.nameFont}>
          {name}
        </Typography>
        <Typography variant="caption" sx={styles.dateFont}>
          ID {customerId}
        </Typography>
      </Box>
      <VehicleRentalsLabel label={state} />
    </Stack>
  );
};
