// System
import { useSelector } from 'react-redux';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useMemo } from 'react';

// Form
import {
  useCreateVehicleIotLinkForm,
  useManageCreateVehicleIotLinkSchema,
} from '@Forms/vehicles/CreateVehicleIotLinkFormContext';

// Actions
import {
  setShowIOTCancel,
  setShowIOTDelete,
} from '@Modal/reducers/vehicles/vehicles/vehiclesDrawerSlice';

// Hooks
import { useCreateVehicleIotLinkUseCasesSchema } from '@Hooks/vehicles/CreateVehicleIotLink/useCreateVehicleIotLinkUseCasesSchema';

// Types
import { StoreInterface } from 'src/app/types';
import { useDispatch } from 'react-redux';
import {
  manageVehicleIot,
  setShowIOTForm,
} from '@Modal/reducers/vehicles/vehicles/vehiclesDrawerSlice';
import { useVehiclesDrawer } from './useVehicleDrawer';
import { AppDispatch } from 'src/app/store';
import { useSnackbar } from 'notistack';

export const useLinkVehicleIot = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { selectedVehicleData } = useVehiclesDrawer();
  const { iotFormShown, iotDeleteFormShown, iotCancelShown } = useSelector(
    (state: StoreInterface) => state?.vehicleDrawer
  );

  // Form
  const { handleSubmit, reset, control } = useCreateVehicleIotLinkForm();

  const { setSchema } = useManageCreateVehicleIotLinkSchema();
  const iotLinkSchema = useCreateVehicleIotLinkUseCasesSchema();

  const { isDirty, errors } = useFormState({ control });

  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleSetIotLinkSchema = () => {
    reset();
    setSchema(iotLinkSchema);
  };

  const handleShowIOTForm = (status: boolean) => {
    dispatch(setShowIOTForm(status));
    reset();
  };

  const handleShowIotDeleteDialog = (status: boolean) => {
    dispatch(setShowIOTDelete(status));
    reset();
  };

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowIOTCancel(true));
    } else {
      reset();
      return dispatch(setShowIOTForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowIOTCancel(false));
      dispatch(setShowIOTForm(true));
    } else {
      reset();
      dispatch(setShowIOTCancel(false));
      dispatch(setShowIOTForm(false));
    }
  };

  const handleSubmitLinkIOT = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          manageVehicleIot({
            vehicleID: selectedVehicleData.id,
            vehicleFormState: formData,
          })
        )
          .unwrap()
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  const handleUnlinkVehicleIot = () => {
    dispatch(
      manageVehicleIot({
        vehicleID: selectedVehicleData.id,
        vehicleFormState: {
          imei: selectedVehicleData.iotImei,
          operationType: 'unlink',
        },
      })
    )
      .unwrap()
      .then((_) => reset())
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return {
    // State
    iotFormShown,
    iotCancelShown,
    iotDeleteFormShown,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleShowIOTForm,
    handleCreateCancel,
    handleSubmitLinkIOT,
    handleUnlinkVehicleIot,
    handleCancelTakeMeBack,
    handleSetIotLinkSchema,
    handleShowIotDeleteDialog,
  };
};
