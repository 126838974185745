import React from 'react';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { Button, Typography } from '@mui/material';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useDispatch } from 'react-redux';
import { updateBranchStatus } from '@Modal/reducers/branches/branchSlice';
import { useSnackbar } from 'notistack';
import { AppDispatch } from 'src/app/store';

export const BranchStatusModalDialog = () => {
  const { showUpdateStatusModal, setCloseUpdateStatusModal, selectedBranch, selectedStatus } =
    useBranches();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch<AppDispatch>();

  const handleUpdateStatusSubmit = () => {
    dispatch(
      updateBranchStatus({
        id: selectedBranch.id,
        status: selectedStatus,
      })
    )
      .unwrap()
      .then()
      .catch((error: string) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return (
    <>
      {showUpdateStatusModal && (
        <ClosableModal
          name="BranchOperationStatus"
          title="Change branch status"
          open={showUpdateStatusModal}
          onClose={() => dispatch(setCloseUpdateStatusModal(false))}
          sx={{
            width: '592px',
          }}
        >
          <CreateDialogLayout>
            <Typography
              variant="body1"
              sx={{
                marginY: 2,
              }}
            >
              Are you sure you want to change branch status?
            </Typography>
            <CreateDialogFooterLayout>
              <AtLocator type="button" name="Cancel">
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => dispatch(setCloseUpdateStatusModal(false))}
                >
                  Cancel
                </Button>
              </AtLocator>
              <AtLocator type="button" name="Submit">
                <Button type="submit" variant="contained" onClick={handleUpdateStatusSubmit}>
                  Confirm
                </Button>
              </AtLocator>
            </CreateDialogFooterLayout>
          </CreateDialogLayout>
        </ClosableModal>
      )}
    </>
  );
};
