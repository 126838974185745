import { boolean, number, object, ObjectSchema, string } from 'yup';

import { createContext, Dispatch, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { noop } from '@utils/noop';

import {
  ApproachCommandsFormData,
  CloseCommandsFormData,
  CommandsFormData,
  DashboardCommandsFormData,
  DeployCommandsFormData,
} from '@contracts/formData/vehicles/commands/CommandsFormData';
import { NoMovementFormData } from '@contracts/formData/vehicles/NoMovementFormData';

export type SetupCategoryFormState = {
  id: string;
  brandId: string;
  name: string;
  displayName: string;
  vehicleType: string;
  helmetTracking: boolean;
  batteryMedium: number;
  batteryLow: number;
  batteryCritical: number;
  iotBatteryLevelFilters: boolean;
  iotBatteryMedium: number;
  iotBatteryLow: number;
  iotBatteryCritical: number;
  speedMode: string | null;
  maxSpeed: number | null;
  maxSpeedSlowZone: number | null;
  maxDistanceWithFullBattery: number | null;
  reservationDistanceMax: number | null;
  rentalDistanceMax: number | null;
  minBalanceWithPaymentSource: number | null;
  minBalanceWithoutPaymentSource: number | null;
  minAge: number | null;
  signedUp: boolean | null;
  emailAddressSpecified: boolean | null;
  phoneNumberSpecified: boolean | null;
  firstNameSpecified: boolean | null;
  lastNameSpecified: boolean | null;
  dateOfBirthSpecified: boolean | null;
  minAgeAccordance: boolean | null;
  streetSpecified: boolean | null;
  postcodeSpecified: boolean | null;
  citySpecified: boolean | null;
  countrySpecified: boolean | null;
  creditCardAttached: boolean | null;
  paymentMethodSelected: boolean | null;
  paidSignUpFeeViaCreditCard: boolean | null;
  enableCreditCardPreAuthorization: boolean | null;
  settleNegativeBalance: boolean | null;
  vehicleIsInOperationalArea: boolean | null;
  vehicleIsNotInNoParkingArea: boolean | null;
  vehicleIsCharging: boolean | null;
  speedIsZero: boolean | null;
  ignitionMustBeTurnedOffAfterEndingRental: boolean | null;
  ignitionMustBeSwitchedOffToEndRental: boolean | null;
  helmetsDetected: boolean | null;
  bikeLockIsLocked: boolean | null;
  commands: {
    dashboard: {
      start?: boolean | null;
      stop?: boolean | null;
      unlockBattery?: boolean | null;
      unlockHelmet?: boolean | null;
      alarmOn?: boolean | null;
      setMaxSpeed?: boolean | null;
      reboot?: boolean | null;
      productionMode?: boolean | null;
      demoMode?: boolean | null;
      openSaddle?: boolean | null;
      openTailBox?: boolean | null;
    } | null;
    approach?: {
      refresh?: boolean | null;
      alarmOn?: boolean | null;
      start?: boolean | null;
      setOutOfOrder?: boolean | null;
      unlockBattery?: boolean | null;
    } | null;
    deploy?: {
      refresh?: boolean | null;
      alarmOn?: boolean | null;
      stop?: boolean | null;
      setOperational?: boolean | null;
    } | null;
    close?: {
      refresh?: boolean | null;
      alarmOn?: boolean | null;
      stop?: boolean | null;
    } | null;
  } | null;
  noMovement?: {
    maxDistance?: number | null;
    maxTime?: number | null;
    autoEndRental?: boolean | null;
    endRentalAfterMinutes?: number | null;
    autoEndMaxCost?: number | null;
  } | null;
};

export const setupCategoryFormStateDefault: SetupCategoryFormState = {
  id: '',
  name: '',
  vehicleType: '',
  brandId: '',
  displayName: '',
  helmetTracking: false,
  batteryMedium: 0,
  batteryLow: 0,
  batteryCritical: 0,
  iotBatteryLevelFilters: false,
  iotBatteryMedium: 0,
  iotBatteryLow: 0,
  iotBatteryCritical: 0,
  speedMode: null,
  maxSpeed: null,
  maxSpeedSlowZone: null,
  maxDistanceWithFullBattery: null,
  reservationDistanceMax: null,
  rentalDistanceMax: null,
  minBalanceWithPaymentSource: null,
  minBalanceWithoutPaymentSource: null,
  minAge: null,
  signedUp: false,
  emailAddressSpecified: false,
  phoneNumberSpecified: false,
  firstNameSpecified: false,
  lastNameSpecified: false,
  dateOfBirthSpecified: false,
  minAgeAccordance: false,
  streetSpecified: false,
  postcodeSpecified: false,
  citySpecified: false,
  countrySpecified: false,
  creditCardAttached: false,
  paymentMethodSelected: false,
  paidSignUpFeeViaCreditCard: false,
  enableCreditCardPreAuthorization: false,
  settleNegativeBalance: false,
  vehicleIsInOperationalArea: false,
  vehicleIsNotInNoParkingArea: false,
  vehicleIsCharging: false,
  speedIsZero: false,
  ignitionMustBeTurnedOffAfterEndingRental: false,
  ignitionMustBeSwitchedOffToEndRental: false,
  helmetsDetected: false,
  bikeLockIsLocked: false,
  commands: null,
  noMovement: null,
};

export const setupCategorySchemaDefault: ObjectSchema<Partial<SetupCategoryFormState>> = object<
  Partial<SetupCategoryFormState>
>().shape({
  id: string().required(),
  name: string().required(),
  vehicleType: string().required(),
  brandId: string().required(),
  displayName: string().required(),
  helmetTracking: boolean(),
  batteryMedium: number(),
  batteryLow: number(),
  batteryCritical: number(),
  iotBatteryLevelFilters: boolean(),
  iotBatteryMedium: number(),
  iotBatteryLow: number(),
  iotBatteryCritical: number(),
  speedMode: string(),
  maxSpeed: number(),
  maxSpeedSlowZone: number(),
  maxDistanceWithFullBattery: number(),
  reservationDistanceMax: number(),
  rentalDistanceMax: number(),
  minBalanceWithPaymentSource: number(),
  minBalanceWithoutPaymentSource: number(),
  minAge: number(),
  signedUp: boolean(),
  emailAddressSpecified: boolean(),
  phoneNumberSpecified: boolean(),
  firstNameSpecified: boolean(),
  lastNameSpecified: boolean(),
  dateOfBirthSpecified: boolean(),
  minAgeAccordance: boolean(),
  streetSpecified: boolean(),
  postcodeSpecified: boolean(),
  citySpecified: boolean(),
  countrySpecified: boolean(),
  creditCardAttached: boolean(),
  paymentMethodSelected: boolean(),
  paidSignUpFeeViaCreditCard: boolean(),
  enableCreditCardPreAuthorization: boolean(),
  settleNegativeBalance: boolean(),
  vehicleIsInOperationalArea: boolean(),
  vehicleIsNotInNoParkingArea: boolean(),
  vehicleIsCharging: boolean(),
  speedIsZero: boolean(),
  ignitionMustBeTurnedOffAfterEndingRental: boolean(),
  ignitionMustBeSwitchedOffToEndRental: boolean(),
  helmetsDetected: boolean(),
  bikeLockIsLocked: boolean(),
  commands: object<Partial<CommandsFormData>>().shape({
    dashboard: object<Partial<DashboardCommandsFormData>>().shape({
      start: boolean(),
      stop: boolean(),
      unlockBattery: boolean(),
      unlockHelmet: boolean(),
      alarmOn: boolean(),
      setMaxSpeed: boolean(),
      reboot: boolean(),
      productionMode: boolean(),
      demoMode: boolean(),
      openSaddle: boolean(),
      openTailBox: boolean(),
    }),
    approach: object<Partial<ApproachCommandsFormData>>().shape({
      refresh: boolean(),
      alarmOn: boolean(),
      start: boolean(),
      setOutOfOrder: boolean(),
      unlockBattery: boolean(),
    }),
    deploy: object<Partial<DeployCommandsFormData>>().shape({
      refresh: boolean(),
      alarmOn: boolean(),
      stop: boolean(),
      setOperational: boolean(),
    }),
    close: object<Partial<CloseCommandsFormData>>().shape({
      refresh: boolean(),
      alarmOn: boolean(),
      stop: boolean(),
    }),
  }),
  noMovement: object<Partial<NoMovementFormData>>().shape({
    maxDistance: number(),
    maxTime: number(),
    autoEndRental: boolean(),
    endRentalAfterMinutes: number(),
    autoEndMaxCost: number(),
  }),
});

type SetupCategoryFormContextType = {
  schema: ObjectSchema<Partial<SetupCategoryFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupCategoryFormState>>>;
  methods?: UseFormReturn<SetupCategoryFormState>;
};

const setupCategoryFormContextDefault = {
  schema: setupCategorySchemaDefault,
  setSchema: noop,
} satisfies SetupCategoryFormContextType;

export const SetupCategoryFormContext = createContext<SetupCategoryFormContextType>(
  setupCategoryFormContextDefault
);

export const useSetupCategoryForm = () => {
  const { methods } = useContext(SetupCategoryFormContext);
  if (!methods) {
    throw new Error('SetupCategoryFormContext is empty');
  }
  return methods;
};

export const useManageSetupCategorySchema = () => {
  const { setSchema } = useContext(SetupCategoryFormContext);

  return { setSchema };
};
