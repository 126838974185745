import { BaseSyntheticEvent, useMemo } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowCreateAviatorRoleForm,
  setShowCancelCreateAviatorRoleForm,
} from '@Modal/reducers/aviatorRoles/createAviatorRoleSlice';

// Form
import { useFormState } from 'react-hook-form';
import { useCreateAviatorRoleForm } from '@Forms/aviatorRoles/CreateAviatorRoleFormContext';

// System
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useCreateRoleMutation, useGetRolesQuery } from '@Src/generated.graphql';
export const useCreateAviatorRole = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const [createAviatorRole] = useCreateRoleMutation();
  const { refetch: refetchAviatorRoles } = useGetRolesQuery();

  const { showCreateAviatorRoleForm, showCancelCreateAviatorRoleForm } = useSelector(
    (state: StoreInterface) => state?.createAviatorRole
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelCreateAviatorRoleForm(true));
    } else {
      return dispatch(setShowCreateAviatorRoleForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelCreateAviatorRoleForm(false));
      dispatch(setShowCreateAviatorRoleForm(true));
    } else {
      reset();
      dispatch(setShowCancelCreateAviatorRoleForm(false));
      dispatch(setShowCreateAviatorRoleForm(false));
    }
  };

  // Form Controls
  const { control, handleSubmit, reset } = useCreateAviatorRoleForm();
  const { isDirty, errors } = useFormState({ control });
  const handleSubmitCreateAviatorRole = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        const isEditable = formData?.isEditable;
        const booleanIsEditable = isEditable === 'true' ? true : false;
        const newFormData = {
          ...formData,
          isEditable: booleanIsEditable,
        };
        try {
          await createAviatorRole({
            // @ts-ignore
            variables: { input: newFormData },
          });

          enqueueSnackbar('Role was successfully created', {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });

          await refetchAviatorRoles();

          reset();
          dispatch(setShowCreateAviatorRoleForm(false));
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    [createAviatorRole, reset, dispatch]
  );

  return {
    // Store
    showCreateAviatorRoleForm,
    showCancelCreateAviatorRoleForm,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    setShowCreateAviatorRoleForm,
    handleSubmitCreateAviatorRole,
  };
};
