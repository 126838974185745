import { TableColumnDescription } from '@contracts/view/TableColumnDescription';

export const applyColumnVisibility = <
  T extends Record<string, unknown>,
  H extends {},
  K extends string
>(
  columns: TableColumnDescription<T, H, K>[],
  values: K[]
): TableColumnDescription<T, H, K>[] => {
  return values
    .map((key) => columns?.find((column) => column.name === key))
    .filter((column) => column !== undefined) as TableColumnDescription<T, H, K>[];
};
