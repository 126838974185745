import { normalizeIntegerString } from '../normalizeIntegerString';

type Options = {
  group?: string;
  numerals: string[];
};

const defaultOptions: Options = {
  numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

export const parseIntegerString = (options: Options = defaultOptions) => {
  const normalize = normalizeIntegerString(options);
  return (text: string) => parseInt(normalize(text), 10);
};
