// System
import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { AnalyticsItemDto } from '@contracts/api/dto/analytics/AnalyticsDto';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { DateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/DateTableColumnHeader';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Contracts
import { Filterable } from '@contracts/common/traits/Filterable';
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';

// Utils
import {
  AnalyticListColumns,
  AnalyticListFilter,
  analyticListColumns,
} from '@utils/tables/analytics/analyticListFilter';
import { useDispatch } from 'react-redux';
import { useAnalyticsTable } from '@Modal/hooks/analytics/useAnalyticsTable';
import { DateGeneratorForAnalyticsTable } from '../DateGeneratorForAnalyticsTable/DateGeneratorForAnalyticsTable';

type HeaderOptions = {
  filter: Filterable<AnalyticListFilter>;
};

type Columns = TableColumnDescriptionArray<
  AnalyticsItemDto,
  HeaderOptions,
  typeof analyticListColumns
>;

type Column = TableColumnDescription<AnalyticsItemDto, HeaderOptions, AnalyticListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const numberFormatter = (num: number) => {
  const formattedNumber = (Math.round((num + Number.EPSILON) * 100) / 100).toLocaleString();
  return formattedNumber;
};

export const useAnalyticTableColumns = () => {
  const styles = useSx();
  const ellipsis = useEllipsis();
  const dispatch = useDispatch();
  const { startDate, endDate } = DateGeneratorForAnalyticsTable();
  const { setTableFilters } = useAnalyticsTable();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'analytic.date',
          title: 'Date',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <DateTableColumnHeader
              title={title}
              onApply={(startDate, endDate) => {
                const modifiedStartDate = startDate ? new Date(startDate) : null;
                const modifiedEndDate = endDate ? new Date(endDate) : null;
                modifiedEndDate ? modifiedEndDate.setHours(23, 59, 0, 0) : null;
                startDate?.length
                  ? dispatch(
                      setTableFilters({
                        codeName: 'tableStartDate',
                        value: modifiedStartDate?.toISOString(),
                      })
                    )
                  : null;
                endDate?.length
                  ? dispatch(
                      setTableFilters({
                        codeName: 'tableEndDate',
                        value: modifiedEndDate?.toISOString(),
                      })
                    )
                  : null;
              }}
              onReset={() => {
                startDate?.length
                  ? dispatch(setTableFilters({ codeName: 'tableStartDate', value: startDate }))
                  : null;
                endDate?.length
                  ? dispatch(setTableFilters({ codeName: 'tableEndDate', value: endDate }))
                  : null;
              }}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row.date}</Box>,
        },
        {
          name: 'analytic.providerId',
          title: 'Provider ID',
          sx: { minWidth: '170px', maxWidth: '180px' },
          header: ({ title }) => (
            <Typography
              sx={{ color: '#637381', fontSize: '14px', lineHeight: '24px', fontWeight: '600' }}
            >
              {title}
            </Typography>
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {
                <>
                  {row?.providerId && (
                    <TruncateText text={row?.providerId} maxLength={8} textStyles={styles.id} />
                  )}
                </>
              }
            </Box>
          ),
        },
        {
          name: 'analytic.totalBetAmount',
          title: 'Total Bet Amount',
          sx: { minWidth: '150px', maxWidth: '220px' },
          header: ({ title }) => (
            <Typography
              sx={{ color: '#637381', fontSize: '14px', lineHeight: '24px', fontWeight: '600' }}
            >
              {title}
            </Typography>
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{numberFormatter(row?.totalBetAmount)}</Box>,
        },
        {
          name: 'analytic.totalWonAmount',
          title: 'Total Won Amount',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <Typography
              sx={{ color: '#637381', fontSize: '14px', lineHeight: '24px', fontWeight: '600' }}
            >
              {title}
            </Typography>
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{numberFormatter(row?.totalWonAmount)}</Box>,
        },
        {
          name: 'analytic.numberOfUsers',
          title: 'Number Of Users',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <Typography
              sx={{ color: '#637381', fontSize: '14px', lineHeight: '24px', fontWeight: '600' }}
            >
              {title}
            </Typography>
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.numberOfUsers}</Box>,
        },
        {
          name: 'analytic.numberOfSpins',
          title: 'Number Of Bets',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <Typography
              sx={{ color: '#637381', fontSize: '14px', lineHeight: '24px', fontWeight: '600' }}
            >
              {title}
            </Typography>
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>{row?.totalLosingBets + row?.totalWinningBets}</Box>
          ),
        },
        {
          name: 'analytic.numberOfRounds',
          title: 'Played Rounds',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <Typography
              sx={{ color: '#637381', fontSize: '14px', lineHeight: '24px', fontWeight: '600' }}
            >
              {title}
            </Typography>
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.numberOfRounds}</Box>,
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
