import { SystemStyleObject } from '@mui/system';
import { Box, IconButton, Modal, Typography, useTheme, Theme, Stack } from '@mui/material';

import { AssetIcon } from '@Components/shared/images/AssetIcon';

type Props = {
  sx?: SystemStyleObject<Theme>;
  name: string;
  title: string;
  open: boolean;
  onClose?: () => void;
  children: JSX.Element;
  className?: string;
};

// Styles
import Styles from './ClosableModal.module.scss';

export const ClosableModal = ({ name, title, open, onClose, children, sx }: Props) => {
  const { palette } = useTheme();

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-at-modal={name}
      className={Styles.ClosableModal}
    >
      <Stack padding={3} spacing={1} className={Styles.MainContainer} sx={sx}>
        <Box className={Styles.HeaderContainer}>
          <Typography variant="h6" gutterBottom data-at-readonly-text="Title">
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            color="inherit"
            data-at-button="Close"
            sx={{
              background: 'transparent',
              visibility: onClose ? 'visible' : 'collapse',
              color: palette.grey[500],
              marginTop: -1.5,
            }}
          >
            <AssetIcon src="x-close" size="xxl" />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </Modal>
  );
};
