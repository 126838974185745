import React from 'react';
import { Grid } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RHFSwitch } from '@Components/hook-form';

export const DeployCommands = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={0} columns={8} justifyContent="flex-start">
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Stop">
          <RHFSwitch label="Stop" name="commands.deploy.stop" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="AlrmOn">
          <RHFSwitch label="Alarm On" name="commands.deploy.alarmOn" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Refresh">
          <RHFSwitch label="Refresh" name="commands.deploy.refresh" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="SetOperational">
          <RHFSwitch label="Set Operational" name="commands.deploy.setOperational" />
        </AtLocator>
      </Grid>
    </Grid>
  );
};
