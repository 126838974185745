import { Drawer } from '@mui/material';

// Form
import { SetupAvTransactionDrawerContent } from './SetupAvTransactionDrawerContent';

// Redux
import { useDispatch } from 'react-redux';
import { useAvTransactionDrawer } from '@Modal/hooks/transaction/useAvTransactionDrawer';

export const SetupAvTransactionDrawer: React.FC = () => {
  const { showAvTransactionDrawer, setShowAvTransactionDrawer } = useAvTransactionDrawer();
  const dispatch = useDispatch();

  return (
    <Drawer
      anchor="right"
      open={showAvTransactionDrawer}
      onClose={() => dispatch(setShowAvTransactionDrawer(false))}
    >
      <SetupAvTransactionDrawerContent />
    </Drawer>
  );
};
