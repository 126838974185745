import SvgColor from '@Components/svg-color/SvgColor';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useMemo } from 'react';

type Sizes = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl' | 'xxxxxl';
type Props = {
  src: string;
  size?: Sizes;
  sx?: SystemStyleObject<Theme>;
  format?: string;
  className?: string;
};
const useSx = () => {
  return useMemo(
    () =>
      ({
        xs: { width: '8px', height: '8px' },
        s: { width: '10px', height: '10px' },
        m: { width: '12px', height: '12px' },
        l: { width: '14px', height: '14px' },
        xl: { width: '16px', height: '16px' },
        xxl: { width: '18px', height: '18px' },
        xxxl: { width: '20px', height: '20px' },
        xxxxl: { width: '25px', height: '25px' },
        xxxxxl: { width: '36px', height: '36px' },
      } satisfies Record<Sizes, SystemStyleObject<Theme>>),
    []
  );
};

export const AssetIcon: React.FC<Props> = ({ src, size = 'm', sx, className }) => {
  const sizeSx = useSx();
  return <SvgColor className={className} src={`/assets/${src}.svg`} sx={{ ...sizeSx[size] }} />;
};

export const AssetImage: React.FC<Props> = ({ src, size = 'm', sx, format, className }) => {
  const sizeSx = useSx();
  return (
    <SvgColor
      className={className}
      src={`/assets/${src}.${format}`}
      sx={{ ...sizeSx[size], ...sx }}
    />
  );
};
