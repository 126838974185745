import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
// Types

import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { BranchesType, CategoriesType } from '@Modal/reducers/branches/types';

// Redux

import { useSelector, useDispatch } from 'react-redux';
import { useBranches } from './useBranch';
import {
  getBranchCategories,
  getBranchUnassignedCategories,
  setShowBranchDrawer,
  setShowCancelBranchDrawer,
  setCheckedCategories,
  setSelectedCategory,
  setCategories,
  clearCategories,
} from '@Modal/reducers/branches/branchDrawerSlice';
import {
  getSingleBranch,
  setBranchesList,
  updateBranch,
} from '@Modal/reducers/branches/branchSlice';
import { getMapZonesList } from '@Modal/reducers/branches/mapZoneSlice';
// Form

import { useFormState } from 'react-hook-form';
import { useBranchDrawerMapper } from '@Forms/branches/useBranchDrawerMapper';
import { useSetupBranchDrawerFormSchema } from '@Forms/branches/useBranchDrawerFormSchema';
import {
  useManageSetupBranchSchema,
  useSetupBranchForm,
} from '@Forms/branches/SetupBranchFormContext';

export const useBranchDrawer = () => {
  // Redux
  const { showBranchDrawer, showCancelBranchDrawer, selectedCategory, categories } = useSelector(
    (state: StoreInterface) => state?.branchDrawerReducer
  );

  const { branches, activeBrandId, selectedBranch } = useBranches();
  const dispatch = useDispatch<AppDispatch>();

  // Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, reset, handleSubmit, setValue } = useSetupBranchForm();
  const { isDirty, errors } = useFormState({ control });
  const { setSchema } = useManageSetupBranchSchema();
  const schema = useSetupBranchDrawerFormSchema();
  const mapper = useBranchDrawerMapper();

  const getSelectedCategoryMapZones = (category: CategoriesType | undefined) => {
    dispatch(
      getMapZonesList({
        brandId: activeBrandId,
        branchId: selectedBranch?.id,
        categoryId: category?.id,
      })
    );
  };

  const handleBranchRowClick = (branchId: string, brandId: string) => {
    dispatch(clearCategories());
    dispatch(getSingleBranch(branchId))
      .unwrap()
      .then((clickedBranch: BranchesType) => {
        if (clickedBranch === undefined) {
          return;
        }
        setSchema(schema);
        if (clickedBranch.level === 3) {
          const formState = mapper.toFormState(clickedBranch, brandId, []);
          dispatch(setShowBranchDrawer(true));
          reset(formState);
        } else {
          const formState = mapper.toFormState(clickedBranch, brandId, []);
          dispatch(setShowBranchDrawer(true));
          reset(formState);
        }
      });
  };

  const getBranchUnassignedCategoriesHandler = () => {
    dispatch(getBranchUnassignedCategories(activeBrandId));
  };

  const getBusinessAreaDataHandler = () => {
    dispatch(getBranchUnassignedCategories(activeBrandId));
    dispatch(getBranchCategories(selectedBranch?.id));
    getSelectedCategoryMapZones(selectedCategory);
  };

  const handleSubmitBranchDrawer = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        const cleanedUpCategories = formData.categories.filter((cat) => cat.branchId);
        const cleanedFormData = { ...formData, categories: cleanedUpCategories };

        // @ts-ignore
        dispatch(updateBranch(cleanedFormData))
          .unwrap()
          .then(() => {
            // @ts-ignore
            const updatedBranches = branches.map((branch: BranchesType) => {
              return branch.id === formData?.id ? { ...branch, ...formData } : branch;
            });

            reset();

            dispatch(setShowBranchDrawer(false));
            dispatch(setBranchesList(updatedBranches));
            enqueueSnackbar('Branch was successfully updated', {
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    [categories]
  );

  const handleCategoryDelete = useCallback(
    (key: string) => () => {
      return setValue(
        'categories',
        categories.filter((category) =>
          category.id === key ? { ...category, branchId: selectedBranch.id } : category
        ),
        { shouldDirty: true }
      );
    },
    [categories, setValue]
  );

  const handleHiddenCategoryChange = useCallback(
    (key: string) => (value: boolean) => {
      setValue(
        'categories',
        categories.map((item) => (item.id === key ? { ...item, hidden: value } : item)),
        { shouldDirty: true }
      );
    },
    [categories, setValue]
  );

  // Handle Form Cancels
  const handleDrawerCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelBranchDrawer(true));
    } else {
      return dispatch(setShowBranchDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      reset();
      dispatch(setShowCancelBranchDrawer(false));
    } else {
      reset();
      dispatch(setShowCancelBranchDrawer(false));
      dispatch(setShowBranchDrawer(false));
    }
  };

  return {
    showBranchDrawer,
    setShowBranchDrawer,
    submittable: isDirty && Object.values(errors).length == 0,
    selectedCategory,
    categories,
    showCancelBranchDrawer,
    setShowCancelBranchDrawer,
    handleDrawerCancel,
    handleBranchRowClick,
    handleSubmitBranchDrawer,
    handleCancelTakeMeBack,
    setCheckedCategories,
    setSelectedCategory,
    getSelectedCategoryMapZones,
    handleCategoryDelete,
    handleHiddenCategoryChange,
    setCategories,
    getBranchUnassignedCategoriesHandler,
    getBusinessAreaDataHandler,
  };
};
