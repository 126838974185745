// Constant filter data
const batteryFiltersData = [
  {
    batteryLevel: 'Critical',
    iconName: 'critical',
    value: 'critical',
    isChecked: false,
    nestedKey: 'batteryLevel',
    key: 'deviceInfo',
  },
  {
    batteryLevel: 'Low',
    iconName: 'low',
    value: 'low',
    isChecked: false,
    nestedKey: 'batteryLevel',
    key: 'deviceInfo',
  },
  {
    batteryLevel: 'Medium',
    iconName: 'medium',
    value: 'medium',
    isChecked: false,
    nestedKey: 'batteryLevel',
    key: 'deviceInfo',
  },
  {
    batteryLevel: 'High',
    iconName: 'high',
    value: 'high',
    isChecked: false,
    nestedKey: 'batteryLevel',
    key: 'deviceInfo',
  },
];

const iotStatusData = [
  {
    iotStatusTitle: 'Online',
    isChecked: false,
    value: 'online',
    key: 'iotStatus',
  },
  {
    iotStatusTitle: 'Offline',
    isChecked: false,
    key: 'iotStatus',
    value: 'offline',
  },
  {
    iotStatusTitle: 'Unknown',
    isChecked: false,
    key: 'iotStatus',
    value: 'unknown',
  },
];

const rentalStatusData = [
  {
    rentalStatusTitle: 'Ride ready',
    isChecked: false,
    value: 'RIDE_READY',
    key: 'rentalStatus',
  },
  {
    rentalStatusTitle: 'Driving',
    isChecked: false,
    value: 'DRIVING',
    key: 'rentalStatus',
  },
  {
    rentalStatusTitle: 'Parking',
    isChecked: false,
    value: 'PARKING',
    key: 'rentalStatus',
  },
];

const vehicleTypeData = [
  {
    vehicleTypeTitle: 'SCOOTER',
    isChecked: false,
    value: 'SCOOTER',
    key: 'category',
    nestedKey: 'vehicleType',
  },
  {
    vehicleTypeTitle: 'MOPED',
    isChecked: false,
    value: 'MOPED',
    key: 'category',
    nestedKey: 'vehicleType',
  },
];

const powerStatusData = [
  {
    powerStatusTitle: 'OFF',
    isChecked: false,
    value: '1',
    nestedKey: 'lockStatus',
    key: 'deviceInfo',
  },
  {
    powerStatusTitle: 'ON',
    isChecked: false,
    value: '0',
    nestedKey: 'lockStatus',
    key: 'deviceInfo',
  },
  {
    powerStatusTitle: 'Unknown',
    isChecked: false,
    value: undefined,
    nestedKey: 'lockStatus',
    key: 'deviceInfo',
  },
];

export const dashboardMapFilters = {
  powerStatus: powerStatusData,
  rentalStatus: rentalStatusData,
  vehicleType: vehicleTypeData,
  iotStatus: iotStatusData,
  batteryLevel: batteryFiltersData,
};
