import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupBranchFormContext,
  SetupBranchFormState,
  setupBranchFormStateDefault,
  setupBranchSchemaDefault,
} from '@Forms/branches/SetupBranchFormContext';

export const SetupBranchFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<SetupBranchFormState>>>(setupBranchSchemaDefault);
  const methods = useForm({
    defaultValues: setupBranchFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupBranchFormContext.Provider value={value}>{children}</SetupBranchFormContext.Provider>
  );
};
