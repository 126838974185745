import { Modal, SxProps, Theme } from '@mui/material';

type Props = {
  open: boolean;
  children: JSX.Element;
  sx?: SxProps<Theme>;
};
export const CancelModal: React.FC<Props> = ({ open, children, sx }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0',
        margin:'0 auto',
        width:'95%',
        ...sx,
      }}
    >
      {children}
    </Modal>
  );
};
