import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  showCreateRoundConfigForm: false,
  showCancelRoundConfigForm: false,
};

const createRoundConfigSlice = createSlice({
  name: 'createRoundConfig',
  initialState,
  reducers: {
    setShowCreateRoundConfigForm: (state, { payload }) => {
      state.showCreateRoundConfigForm = payload;
    },
    setShowCancelRoundConfigForm: (state, { payload }) => {
      state.showCancelRoundConfigForm = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: createRoundConfigReducer } = createRoundConfigSlice;
export const { setShowCreateRoundConfigForm, setShowCancelRoundConfigForm } =
  createRoundConfigSlice.actions;
