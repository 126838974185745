import { GeoPointDto } from '@contracts/api/dto/common/GeoPointDto';
import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number, array, boolean } from 'yup';

export type SetupBranchFormState = {
  id: string;
  brandId: string | undefined;
  name: string;
  level: number;
  type: string | null;
  status: string | null;
  code: string | null;
  timezone: string | null;
  vat: number | null;
  geoLocation: {
    lat?: number | null;
    lon?: number | null;
  } | null;
  parentId: string;
  mapZoomLevel: number | null;
  currency: string | null;
  mapZones: any[];
  categories: {
    id: string;
    branchId?: string | null;
    hidden: boolean;
  }[];
};

export const setupBranchFormStateDefault: SetupBranchFormState = {
  brandId: '',
  parentId: '',
  name: '',
  level: 0,
  id: '',
  code: null,
  currency: null,
  geoLocation: null,
  mapZoomLevel: null,
  status: null,
  timezone: null,
  type: null,
  vat: null,
  categories: [],
  mapZones: [],
};

export const setupBranchSchemaDefault: ObjectSchema<Partial<SetupBranchFormState>> =
  object<SetupBranchFormState>().shape({
    id: string().required(),
    brandId: string().required(),
    name: string().required(),
    level: number().required(),
    type: string().nullable(),
    status: string().nullable(),
    code: string().nullable(),
    timezone: string().nullable(),
    vat: number().nullable(),
    geoLocation: object<GeoPointDto>()
      .shape({
        lon: number().nullable(),
        lat: number().nullable(),
      })
      .nullable(),
    parentId: string().required(),
    mapZoomLevel: number().nullable(),
    currency: string().nullable(),
    categories: array().of(
      object().shape({
        id: string().required(),
        branchId: string().nullable(),
        hidden: boolean().required(),
      })
    ),
    mapZones: array(),
  });

type SetupBranchFormContextType = {
  schema: ObjectSchema<Partial<SetupBranchFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupBranchFormState>>>;
  methods?: UseFormReturn<SetupBranchFormState>;
};

const createBranchFormContextDefault = {
  schema: setupBranchSchemaDefault,
  setSchema: noop,
} satisfies SetupBranchFormContextType;

export const SetupBranchFormContext = createContext<SetupBranchFormContextType>(
  createBranchFormContextDefault
);

export const useSetupBranchForm = () => {
  const { methods } = useContext(SetupBranchFormContext);
  if (!methods) {
    throw new Error('SetupBranchFormContext is empty');
  }

  return methods;
};

export const useManageSetupBranchSchema = () => {
  const { setSchema } = useContext(SetupBranchFormContext);

  return { setSchema };
};
