// System
import React from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';

// Hooks
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Types
type Props = {
  handleCenterMap: () => void;
};

// Redux
import { useDispatch } from 'react-redux';

// Styles
import Styles from './ScanOperator.module.scss';

export const ScanOperator: React.FC<Props> = ({ handleCenterMap }) => {
  const dispatch = useDispatch();
  const { handleOpenScanModal } = useOperatorScan();
  const { setShowOperatorProfileModal } = useOperatorVehicles();

  return (
    <Box className={Styles.ScanOperator}>
      <Box className={Styles.MapControls}>
        <Box
          className={Styles.ControllerBox}
          onClick={() => dispatch(setShowOperatorProfileModal(true))}
        >
          <AssetIcon src="icons/operator/ic_operator_user" className={Styles.AssetIcon} />
        </Box>
        <Box onClick={handleCenterMap} className={Styles.ControllerBox}>
          <AssetIcon src="icons/operator/ic_map_center" className={Styles.AssetIcon} />
        </Box>
      </Box>
      <Button
        onClick={() => handleOpenScanModal(true)}
        className={Styles.ScanCTA}
        fullWidth
        variant="contained"
      >
        <AssetIcon src="icons/operator/ic_scan" className={Styles.AssetIcon} />
        <Typography fontSize={16} fontWeight={600}>
          Scan
        </Typography>
      </Button>
    </Box>
  );
};
