//  System
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

export const ClearMapZonesModalDialog = () => {
  const { showClearMapZoneDialog, handleCancelClearMapZoneDialog, ClearMapZoneHandler } =
    useBranchesMaps();

  return (
    <ClosableModal
      name="DeleteNote"
      title="Clear map zones"
      open={showClearMapZoneDialog}
      onClose={handleCancelClearMapZoneDialog}
      sx={{ width: '592px' }}
    >
      <CreateDialogLayout>
        <Typography variant="body1">Are you sure you want to delete all areas?</Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleCancelClearMapZoneDialog()}
            >
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              color="error"
              onClick={() => {
                ClearMapZoneHandler();
              }}
            >
              Delete
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
