// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getMapZonesList } from '@Modal/reducers/branches/mapZoneSlice';
import { getVehiclesOnMap } from '@Modal/reducers/vehicles/vehicles/vehicleMapSlice';

// Hooks
import { useSnackbar } from 'notistack';
import { useBranches } from '@Modal/hooks/branches/useBranch';

// Types
import { StoreInterface } from 'src/app/types';
import { AppDispatch } from 'src/app/store';

export const useVehicleMap = () => {
  // Hooks
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  // Selectors
  const { activeBrandId } = useBranches();
  const { vehiclesOnMap, areFiltersChecked } = useSelector(
    (state: StoreInterface) => state.vehiclesMap
  );

  const getSelectedBrandMapZones = () => {
    dispatch(
      getMapZonesList({
        brandId: activeBrandId,
        referrer: 'backoffice',
      })
    );
  };

  const getAllMapZones = () => {
    dispatch(
      getMapZonesList({
        referrer: 'backoffice',
      })
    );
  };

  const handleGetVehiclesOnMap = (bounds: string) => {
    dispatch(
      getVehiclesOnMap({
        brandId: activeBrandId,
        bounds,
      })
    )
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  return {
    // States
    vehiclesOnMap,
    getAllMapZones,
    areFiltersChecked,
    // Actions
    handleGetVehiclesOnMap,
    getSelectedBrandMapZones,
  };
};
