import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateRoundConfigForm } from './CreateRoundConfigForm';

// Styles
import Styles from './CreateRoundConfigModalDialog.module.scss';
import { useCreateRoundConfig } from '@Modal/hooks/rounds/useCreateRoundConfig';

export const CreateRoundConfigModalDialog = () => {
  const { showCreateRoundConfigForm, handleCreateCancel } = useCreateRoundConfig();

  return (
    <>
      {showCreateRoundConfigForm && (
        <ClosableModal
          name="CreateProvider"
          title="Add Round Config"
          open={showCreateRoundConfigForm}
          onClose={handleCreateCancel}
          className={Styles.CreateRoundConfigModalDialog}
        >
          <CreateRoundConfigForm />
        </ClosableModal>
      )}
    </>
  );
};
