import { useScrollbar } from '@Components/shared/styles/useScrollbar';
import { Paper, TableContainer, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useEffect, useRef } from 'react';

type Props = {
  children: JSX.Element;
  sx?: SystemStyleObject<Theme>;
  pageNumber?: number;
};
export const TableContainerEx: React.FC<Props> = ({ children, sx, pageNumber }) => {
  const scrollbar = useScrollbar();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [pageNumber]);

  return (
    <TableContainer
      ref={containerRef}
      component={Paper}
      sx={[
        {
          maxHeight: '80vh',
          borderRadius: '0',
          ...scrollbar,
        },
        sx ?? {},
      ]}
      aria-label="caption table"
    >
      {children}
    </TableContainer>
  );
};
