// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useCustomersDrawer } from '../useCustomerDrawer';

// Actions
import {
  setCustomerNotes,
  getCustomerNotes,
  setSelectedNoteId,
  createCustomerNote,
  deleteCustomerNote,
  setShowCreateCustomerNote,
  setShowDeleteCustomerNote,
} from '@Modal/reducers/customers/notes/customerNotesSlice';

// Forms
import { useCreateCustomerNoteForm } from '@Forms/customers/CreateCustomerNoteFormContext';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import Cookies from 'js-cookie';

export const useCustomerNotes = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { customerNotes, showCreateCustomerNote, showDeleteCustomerNote, selectedNoteId } =
    useSelector((state: StoreInterface) => state?.customerNotes);

  const { selectedCustomerData } = useCustomersDrawer();

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Form
  const { control, handleSubmit, reset } = useCreateCustomerNoteForm();
  const { isDirty, errors } = useFormState({ control });

  const handleGetCustomerNotes = () => {
    if (selectedCustomerData) {
      dispatch(getCustomerNotes(selectedCustomerData.id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  // Note Form Handlers
  const handleCreateNoteForm = (status: boolean) => dispatch(setShowCreateCustomerNote(status));
  const handleDeleteNoteForm = (status: boolean) => dispatch(setShowDeleteCustomerNote(status));
  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  const handleSubmitCustomerNote = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          createCustomerNote({
            id: selectedCustomerData?.id,
            newNote: { ...formData, authorId: currentUser?.id },
          })
        )
          .unwrap()
          .then(() => {
            reset();
            enqueueSnackbar('Note was successfully created', {
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    [selectedCustomerData]
  );

  const deleteCustomerNoteHandler = () => {
    return dispatch(deleteCustomerNote(selectedNoteId))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  // Handle Form Cancels
  const handleCreateCancel = () => {
    reset();
    return dispatch(setShowCreateCustomerNote(false));
  };

  const handleDeleteCancel = () => {
    return dispatch(setShowDeleteCustomerNote(false));
  };

  // Handle Select NoteID
  const handleCustomerNoteClick = (id: string) => {
    dispatch(setShowCreateCustomerNote(true));
    dispatch(setSelectedNoteId(id));
  };

  const handleDeleteNoteClick = (id: string | undefined) => {
    dispatch(setShowDeleteCustomerNote(true));
    dispatch(setSelectedNoteId(id));
  };

  return {
    // Store
    customerNotes,
    showCreateCustomerNote,
    showDeleteCustomerNote,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    setCustomerNotes,
    handleCreateCancel,
    handleCreateNoteForm,
    handleDeleteNoteForm,
    handleDeleteCancel,
    handleDeleteNoteClick,
    handleGetCustomerNotes,
    handleCustomerNoteClick,
    handleSubmitCustomerNote,
    deleteCustomerNoteHandler,
  };
};
