// @mui
import { Stack, Box } from '@mui/material';
// components
import Logo from '@Components/logo';
import { NavSectionMini } from '@Components/nav-section';

// Config
import navConfig from './config-navigation';

// Styles
import Styles from './NavMini.module.scss';

// ----------------------------------------------------------------------

export default function NavMini() {
  return (
    <Box component="nav">
      <Stack className={Styles.NavMini}>
        <Logo />

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
