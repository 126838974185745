import { MouseEventHandler, ReactNode } from 'react';
import { ActionWithPopoverProvider } from './ActionWithPopoverProvider';
import { ActionWithPopoverContent } from './ActionWithPopoverContent';

type Props = {
  name: string;
  content: JSX.Element;
  children: (onClick?: MouseEventHandler<HTMLButtonElement>, id?: string) => ReactNode;
};

export const ActionWithPopover: React.FC<Props> = ({ name, content, children }) => {
  return (
    <ActionWithPopoverProvider>
      <ActionWithPopoverContent name={name} content={content}>
        {children}
      </ActionWithPopoverContent>
    </ActionWithPopoverProvider>
  );
};
