// System
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';

// Components
import { SelectBusinessZones } from './SelectBusinessZones';
import { AddVehicleCategories } from './AddVehicleCategories';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Hooks
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';

// Form
import { useSetupBranchForm } from '@Forms/branches/SetupBranchFormContext';

export const BusinessArea: React.FC = () => {
  const methods = useSetupBranchForm();
  const { getBusinessAreaDataHandler } = useBranchDrawer();

  useEffect(() => {
    getBusinessAreaDataHandler();
  }, []);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Box sx={{ paddingBottom: 2 }}>
            <AddVehicleCategories />
          </Box>
          <SelectBusinessZones />
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
