import { Box } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

type Props = {
  isDragActive: boolean;
  isError: boolean;
  children: JSX.Element | boolean | undefined | (JSX.Element | boolean | undefined)[];
};
export const SingleFileUploadDropzoneLayout: React.FC<Props> = ({
  children,
  isDragActive,
  isError,
}) => {
  const { palette, transitions } = useTheme();
  return (
    <Box
      sx={{
        outline: 'none',
        borderRadius: 1.5,
        padding: 2,
        transition: transitions.create('padding'),
        backgroundColor: palette.action.hover,
        border: `1px dashed ${alpha(palette.grey[500], 0.32)}`,
        '&:hover': {
          opacity: 0.72,
        },
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError && {
          borderColor: palette.error.dark,
          borderStyle: 'solid',
        }),
      }}
    >
      {children}
    </Box>
  );
};
