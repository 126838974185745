import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const roundListColumns = [
  'round._id',
  'round.multiplierCrash',
  'round.createdAt',
  'round.roundHash',
  'round.clientHash',
  'round.serverHash',
] as const;

export type RoundListColumns = (typeof roundListColumns)[number];

export type RoundListFilter = {
  'round._id': string | undefined;
  'round.multiplierCrash': string | undefined;
  'round.createdAt': string | undefined;
  'round.roundHash': string | undefined;
  'round.clientHash': string | undefined;
  'round.serverHash': string | undefined;
};

export const defaultRoundListFilter: RoundListFilter = {
  'round._id': undefined,
  'round.multiplierCrash': undefined,
  'round.createdAt': undefined,
  'round.roundHash': undefined,
  'round.clientHash': undefined,
  'round.serverHash': undefined,
};

export const roundListColumnVisibilitySettings = {
  'round._id': ColumnVisibilityType.Visible,
  'round.multiplierCrash': ColumnVisibilityType.CanBeHidden,
  'round.createdAt': ColumnVisibilityType.CanBeHidden,
  'round.roundHash': ColumnVisibilityType.CanBeHidden,
  'round.clientHash': ColumnVisibilityType.CanBeHidden,
  'round.serverHash': ColumnVisibilityType.CanBeHidden,
} satisfies ColumnVisibilitySettings<RoundListColumns>;

export const defaultRoundListColumnVisibility = defaultColumnVisibilityParams(
  roundListColumnVisibilitySettings
);

export const roundListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  roundListColumns,
  roundListColumnVisibilitySettings
);
