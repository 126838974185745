import React from 'react';

import { Stack } from '@mui/material';

import { RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Styles
import Styles from './CreateVehicleContent.module.scss';

export const CreateVehicleContent = () => {
  return (
    <Stack className={Styles.CreateVehicleContent}>
      <AtLocator type="text-field" name="name" key="name">
        <RHFTextField name="name" label="Name" />
      </AtLocator>
      <AtLocator type="text-field" name="licensePlate" key="licensePlate">
        <RHFTextField name="licensePlate" label="License Plate" />
      </AtLocator>
      <AtLocator type="text-field" name="qrCode" key="qrCode">
        <RHFTextField name="qrCode" label="QR Code" />
      </AtLocator>
      <AtLocator type="text-field" name="vin" key="vin">
        <RHFTextField name="vin" label="VIN" />
      </AtLocator>
    </Stack>
  );
};
