import { Drawer } from '@mui/material';

// Form
import { SetupIotModuleContent } from './SetupIotModuleContent';

// Redux
import { useIotDrawer } from '@Modal/hooks/vehicles/iot/useIotDrawer';

export const SetupIotDrawer: React.FC = () => {
  const { showSelectedIotDrawer, handleCreateCancel } = useIotDrawer();

  return (
    <Drawer anchor="right" open={showSelectedIotDrawer} onClose={handleCreateCancel}>
      <SetupIotModuleContent />
    </Drawer>
  );
};
