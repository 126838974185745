import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, array } from 'yup';

export type SetupRoleFormState = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  role: string | null;
  brand: string[] | null;
  secondLevelBranch: string[] | null;
  thirdLevelBranch: string[] | null;
};

export const setupRoleFormStateDefault: SetupRoleFormState = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  brand: [],
  secondLevelBranch: [],
  thirdLevelBranch: [],
};

export const setupRoleSchemaDefault: ObjectSchema<Partial<SetupRoleFormState>> =
  object<SetupRoleFormState>().shape({
    firstName: string().required('First name is required field'),
    lastName: string().required('Last name is required field'),
    email: string().required('Email is required field'),
    role: string().required('Role is required field'),
    brand: array().required('Brand is required field'),
    secondLevelBranch: array().required('2nd level branch is required'),
    thirdLevelBranch: array().required('3rd level branch is required'),
  });

type SetupRoleFormContextType = {
  schema: ObjectSchema<Partial<SetupRoleFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupRoleFormState>>>;
  methods?: UseFormReturn<SetupRoleFormState>;
};

const createRoleFormContextDefault = {
  schema: setupRoleSchemaDefault,
  setSchema: noop,
} satisfies SetupRoleFormContextType;

export const SetupRoleFormContext = createContext<SetupRoleFormContextType>(
  createRoleFormContextDefault
);

export const useSetupRoleForm = () => {
  const { methods } = useContext(SetupRoleFormContext);
  if (!methods) {
    throw new Error('SetupRoleFormContext is empty');
  }

  return methods;
};

export const useManageSetupRoleSchema = () => {
  const { setSchema } = useContext(SetupRoleFormContext);

  return { setSchema };
};
