import { useCallback, useEffect, useMemo } from 'react';

// Components
import { RHFSelect, RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';

// Features
import { useCreateBranchForm } from '@Forms/branches/CreateBranchFormContext';

// Hooks
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useDispatch } from 'react-redux';
import { Controller } from 'react-hook-form';

// System
import { Box } from '@mui/system';
import { Stack } from '@mui/material';

// Other
import { BranchesType } from '@Modal/reducers/branches/types';
import { setSelectedBranch } from '@Modal/reducers/branches/branchSlice';
import { AvatarBrandSelect } from '../shared/BrandSelect';
import { useCreateBrand } from '@Modal/hooks/branches/useCreateBrand';

// Styles
import Styles from './CreateBranchContent.module.scss';

const useDropDown = () => {
  const { branches, activeBrandId, selectedBrand, selectedBranch } = useBranches();
  const branchesForParent = branches?.length
    ? branches.filter((va) => va?.parentId === activeBrandId)
    : [];

  const { reset } = useCreateBranchForm();

  const dispatch = useDispatch();

  const handleBranchSelect = (branch: BranchesType) => {
    dispatch(setSelectedBranch(branch));
  };

  useEffect(() => {
    if (!selectedBranch) {
      dispatch(setSelectedBranch(selectedBrand));
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (activeBrandId) {
      reset({ parentId: activeBrandId, brandId: activeBrandId, level: 1 });
    }
  }, [activeBrandId]);

  const parentDropDown = useMemo(
    () =>
      [selectedBrand, ...branchesForParent]?.map((branch) => (
        <MenuItemEx
          selected={branch?.id === selectedBranch?.id}
          onClick={() => handleBranchSelect(branch)}
          key={branch.id}
          value={branch.id}
          name="Branch"
        >
          {`${selectedBrand?.name} - ${branch.name}`}
        </MenuItemEx>
      )),
    [branchesForParent]
  );

  return { parent: parentDropDown };
};

export const CreateBranchContent: React.FC = () => {
  const { control } = useCreateBranchForm();

  const { setShowCreateBrandForm } = useCreateBrand();

  const dispatch = useDispatch();

  const { brands, selectedBranch, branchChanged, selectedBrand } = useBranches();
  const dropDown = useDropDown();

  const handleCreateBrand = useCallback(async () => {
    dispatch(setShowCreateBrandForm(true));
  }, []);

  return (
    <Stack className={Styles.CreateBranchContent}>
      <Controller
        name="brandId"
        control={control}
        render={({ field }) => (
          <Box>
            <AvatarBrandSelect
              options={brands ?? []}
              value={field.value}
              onChange={field.onChange}
              onCreateClick={handleCreateBrand}
            />
          </Box>
        )}
      />
      <AtLocator type="select-field" name="parentId" key="parentId">
        <RHFSelect
          label="Parent"
          name="parentId"
          variant="outlined"
          id="parentId"
          value={`${branchChanged ? selectedBranch : selectedBrand}`}
          defaultValue={`${branchChanged ? selectedBranch?.name : selectedBrand?.name}`}
        >
          {dropDown.parent}
        </RHFSelect>
      </AtLocator>
      <AtLocator type="text-field" name="name" key="name">
        <RHFTextField
          id="name"
          name="name"
          label={selectedBranch?.level === 2 ? 'City name' : 'Country name'}
          variant="outlined"
        />
      </AtLocator>
    </Stack>
  );
};
