import { useContext, useEffect } from 'react';
import { delay } from '@utils/delay';

import { Alert } from '@contracts/domain/Alert';
import { AlertStoreContext } from './AlertStoreContext';

export const useAutoHideAlert = (alert: Alert) => {
  const { setAlert } = useContext(AlertStoreContext);
  useEffect(() => {
    const effect = async () => {
      await delay(3000);
      setAlert((state) => (state === alert ? undefined : state));
    };
    effect();
  }, [setAlert, alert]);
};
