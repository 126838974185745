import { FormProvider } from 'react-hook-form';
import { Button } from '@mui/material';

import { useCreateBrandForm } from '@Forms/branches/CreateBrandFormContext';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateBrandContent } from './CreateBrandContent';
import { useCreateBrand } from '@Modal/hooks/branches/useCreateBrand';

export const CreateBrandForm: React.FC = () => {
  const methods = useCreateBrandForm();
  const { handleCreateCancel,handleSubmitCreateBrand,submittable } = useCreateBrand();

  return (
    <FormProvider {...methods}>
      <CreateDialogLayout>
        <CreateBrandContent />
        <CreateDialogFooterLayout>
          <AtLocator key="Cancel" type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator key="Submit" type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateBrand}
            >
              Create
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
