import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, number, object } from 'yup';

export type RentalEndRideFormState = {
  duration: number;
};

export const RentalEndRideFormStateDefault: RentalEndRideFormState = {
  duration: 0,
};

export const RentalEndRideSchemaDefault: ObjectSchema<Partial<RentalEndRideFormState>> = object<
  Partial<RentalEndRideFormState>
>().shape({
  duration: number().required('Duration is required').min(1, 'Duration must be more than 0'),
});

type RentalEndRideFormContextType = {
  schema: ObjectSchema<Partial<RentalEndRideFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<RentalEndRideFormState>>>;
  methods?: UseFormReturn<RentalEndRideFormState>;
};

const RentalEndRideFormContextDefault = {
  schema: RentalEndRideSchemaDefault,
  setSchema: noop,
} satisfies RentalEndRideFormContextType;

export const RentalEndRideFormContext = createContext<RentalEndRideFormContextType>(
  RentalEndRideFormContextDefault
);

export const useRentalEndRideForm = () => {
  const { methods } = useContext(RentalEndRideFormContext);
  if (!methods) {
    throw new Error('RentalEndRideFormContext is empty');
  }

  return methods;
};

export const useManageRentalEndRideSchema = () => {
  const { setSchema } = useContext(RentalEndRideFormContext);

  return { setSchema };
};
