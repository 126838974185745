import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { useProviderDrawer } from '@Modal/hooks/providers/useProviderDrawer';
import { SetupProviderContent } from './SetupProviderContent';

export const SetupProviderDrawer: React.FC = () => {
  const { showProviderDrawer, handleCreateCancel } = useProviderDrawer();
  return (
    <SetupDrawer name="SetupProvider" open={showProviderDrawer} onClose={handleCreateCancel}>
      <SetupProviderContent />
    </SetupDrawer>
  );
};
