import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { TableRow, Theme, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = DataAttributeProps & {
  onClick?: () => void;
  children: JSX.Element | JSX.Element[];
  sx?: SystemStyleObject<Theme>;
};
export const TableRowEx: React.FC<Props> = ({ children, sx, onClick, ...dataProps }) => {
  const { palette } = useTheme();
  return (
    <TableRow
      {...dataProps}
      sx={[{ border: `${palette.divider} 1px solid`, cursor: 'pointer' }, sx ?? {}]}
      hover
      onClick={onClick}
    >
      {children}
    </TableRow>
  );
};
