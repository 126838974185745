// System
import { Polygon } from '@react-google-maps/api';

// Contracts
import { MapZoneType } from '@contracts/domain/keys';
import { EditStatus } from '@contracts/common/EditStatus';
import { AllMapZoneType } from '@contracts/domain/extendedKeys';

// Types
import { mapZoneTypeColors } from '../../shared/MapZoneType/useMapZoneType';
import { MapPath } from '../MapEditorMode';

// Hooks
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  mapPaths: MapPath[];
  mapZoneType: AllMapZoneType;
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  fillOpacity: number;
};
const PolygonView: React.FC<PolygonProps> = ({
  mapPaths,
  mapZoneType,
  targetMapZoneType,
  strokeColor,
  fillColor,
  fillOpacity,
}) => {
  const filteredMapPathes = mapPaths.filter(
    (zone) => zone.mapZoneType === targetMapZoneType && zone.editStatus !== EditStatus.Deleted
  );
  if (filteredMapPathes.length === 0) {
    return null;
  }

  if (mapZoneType !== 'ALL' && mapZoneType !== targetMapZoneType) {
    return null;
  }

  const paths = filteredMapPathes.map((mapPath) => mapPath.path);

  return (
    <Polygon paths={paths} options={{ ...polygonOptions, fillColor, strokeColor, fillOpacity }} />
  );
};

export const MapViewMode: React.FC = () => {
  const { activeMapZoneType, mapPaths } = useBranchesMaps();
  return (
    <>
      {mapZoneTypeColors.map((zoneType) => (
        <PolygonView
          key={zoneType.type}
          targetMapZoneType={zoneType.type}
          mapZoneType={activeMapZoneType}
          mapPaths={mapPaths}
          strokeColor={zoneType.color}
          fillColor={zoneType.fillColor ?? zoneType.color}
          fillOpacity={zoneType.opacity}
        />
      ))}
    </>
  );
};
