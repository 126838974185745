import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupRoleFormContext,
  SetupRoleFormState,
  setupRoleFormStateDefault,
  setupRoleSchemaDefault,
} from '@Forms/roles/SetupRoleFormContext';

export const SetupRoleFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<SetupRoleFormState>>>(setupRoleSchemaDefault);
  const methods = useForm({
    defaultValues: setupRoleFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return <SetupRoleFormContext.Provider value={value}>{children}</SetupRoleFormContext.Provider>;
};
