import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// @TODO: Define Type
export const getPricing = createAsyncThunk('pricing/getPricing', async (params: any, thunkApi) => {
  return axiosBranchesVehiclesInstance
    .get(`v1/pricing`, {
      params: {
        ...params.selectedFilters,
      },
    })
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error?.message);
    });
});

// Type
const initialState = {
  pricingList: [],
  loadingContent: true,
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const pricingSlice = createSlice({
  name: 'pricingSlice',
  initialState,
  reducers: {
    setPricingList: (state, { payload }) => {
      state.pricingList = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
  },
  extraReducers: {
    [getPricing.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;

      state.pricingList = items;
      state.loadingContent = false;

      state.pagination = {
        ...state.pagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit < 10 ? 10 : pageInfo.limit,
      };
    },
    [getPricing.rejected?.type]: (state) => {
      state.pricingList = [];
      state.loadingContent = false;
    },
  },
});

export const { reducer: pricingReducer } = pricingSlice;
export const { setPricingList, setPagination } = pricingSlice.actions;
