// Branches
import { BranchStatusModalDialog } from '@Features/branches/BranchStatus';
import { SetupMapZoneModalDialog } from '@Features/branches/SetupMapZone';
import { SetupBranchCancelModalDialog, SetupBranchDrawer } from '@Features/branches/SetupBranch';
import {
  CreateBrandModalDialog,
  CreateBrandCancelModalDialog,
} from '@Features/branches/CreateBrand';
import {
  SimpleCreateBranchModalDialog,
  SimpleCreateBranchCancelModalDialog,
} from '@Features/branches/SimpleCreateBranch';
import {
  CreateBranchModalDialog,
  CreateBranchCancelModalDialog,
} from '@Features/branches/CreateBranch';
import { ClearMapZonesModalDialog } from '@Forms/branches/ClearMapZonesModalDialog';

// Vehicles
import { SetupIotDrawer, SetupIotModuleCancelDialog } from '@Features/vehicles/iot/SetupIotModule';
import {
  CreateIotModuleModalDialog,
  CreateIotModuleCancelModalDialog,
} from '@Features/vehicles/iot/CreateIotModule';
import {
  SetupVehicleCancelModalDialog,
  SetupVehicleDrawer,
  SetupVehicleRetireDialog,
} from '@Features/vehicles/vehicles/SetupVehicle';
import {
  SetupVehicleCategoryDrawer,
  SetupVehicleCategoryCancelModalDialog,
} from '@Features/vehicles/categories/SetupVehicleCategory';
import {
  CreateVehicleModalDialog,
  CreateVehicleCancelModalDialog,
} from '@Features/vehicles/vehicles/CreateVehicle';
import { UploadVehicleCSVModalDialog } from '@Features/vehicles/vehicles/UploadVehicleCSV';
import { UploadIotModulesCSVModalDialog } from '@Features/vehicles/iot/UploadIotModulesCSV';
import { CreateVehicleCategoryModalDialog } from '@Features/vehicles/categories/CreateVehicleCategory';

import { SetupVehicleMaxSpeedDialog } from '@Features/vehicles/vehicles/SetupVehicle/SetupVehicleMaxSpeedDialog';
import { CreateVehicleIotLinkCancelDialog } from '@Features/vehicles/vehicles/SetupVehicle/tabs/IoTModule/CreateVehicleIotLinkCancelDialog';
import { CreateVehicleIotUnlinkConfirmDialog } from '@Features/vehicles/vehicles/SetupVehicle/tabs/IoTModule/CreateVehicleIotUnlinkConfirmDialog';

import { AddVehicleNoteModalDialog } from '@Features/vehicles/notes/AddVehicleNoteModalDialog';

// Customers
import {
  SetupCustomerCancelModalDialog,
  SetupCustomerDrawer,
} from '@Features/customers/SetupCustomer';
import { RemoveVehicleNoteModalDialog } from '@Features/vehicles/notes/removeNoteModalDialog';
import { DeleteCustomerModalDialog } from '@Forms/customers/deleteCustomer/DeleteCustomerModalDialog';
import { SignOutCustomerModalDialog } from '@Forms/customers/signOutCustomer/SignOutCustomerModalDialog';
import { BlockCustomerModalDialog } from '@Forms/customers/blockUnblockCustomer/blockCustomerModalDialog';
import { UnblockCustomerModalDialog } from '@Forms/customers/blockUnblockCustomer/unblockCustomerModalDialog';

// @TODO: Re-do this part using FSD architecture
// import { DeleteCustomerModalDialog } from '@Features/providers/useCases/customers/DeleteCustomer';
// import { CancelDeletionModalDialog } from '@Features/providers/useCases/customers/DeleteCustomer/CancelDeletionModalDialog';
import { BalanceModalDialog } from '@Forms/customers/balance/BalanceModalDialog';
import { CancelBalanceModalDialog } from '@Forms/customers/balance/CancelBalanceModalDialog';
import { AddCustomerNoteModalDialog } from '@Forms/customers/notes/addCustomerNoteModalDialog';
import { RemoveCustomerNoteModalDialog } from '@Forms/customers/notes/RemoveCustomerNoteModalDialog';
import { DeletePaymentModalDialog } from '@Forms/customers/paymentMethods/DeletePaymentMethodModalDialog';

// Rentals
import { SetupRentalDrawer } from '@Features/rentals/shared/SetupRental';
import { AddRentalsNoteModalDialog, RemoveRentalsNoteModalDialog } from '@Forms/rentals/Notes';
import { RentalEndRideModalDialog } from '@Forms/rentals/EndRide/RentalEndRideModalDialog';

// Pricing
import { CreatePricingModalDialog } from '@Features/pricing/CreatePricingModal';
import { SetupPricingDrawer } from '@Features/pricing/SetupPricing/SetupPricingDrawer';
import { SetupPricingCancelModalDialog } from '@Features/pricing/SetupPricing/SetupPricingCancelModalDialog';
import { CreatePricingCancelModalDialog } from '@Features/pricing/CreatePricingModal/CreatePricingCancelModalDialog';
import { SetupMapZoneCancelModalDialog } from '@Features/branches/SetupMapZone/SetupMapZoneCancelModalDialog';

// Transactions
import { SetupTransactionDrawer } from '@Features/transactions/SetupTransaction/SetupTransactionDrawer';
import { TransactionRefundModalDialog } from '@Forms/transactions/TransactionRefundModalDialog';
import { ConfirmTransactionRefundModalDialog } from '@Forms/transactions/ConfirmTransactionRefundModalDialog';

// Operator
import {
  SetupOperatorScanDrawer,
  SetupOperatorNotesDrawer,
  SetupOperatorFiltersDrawer,
  SetupOperatorLinkIotDrawer,
  SetupOperatorVehicleDrawer,
  SetupOperatorApproachDrawer,
  SetupOperatorCommandsDrawer,
  SetupOperatorManualQRDrawer,
  SetupOperatorReplaceQRDrawer,
  SetupOperatorCreateNoteDrawer,
  SetupOperatorDeleteNoteDrawer,
  SetupOperatorServiceStateDrawer,
  SetupOperatorProfileDrawer,
} from '@Features/operator/OperatorDrawers';
import { SetupOperatorScanCancelModal } from '@Features/operator/OperatorDrawers/SetupOperatorScanCancelModal';
// Role
import { SetupAddUserDrawer } from '@Features/roles/SetupRoleDrawer';
import { SetupRoleDrawer } from '@Features/roles/SetupRoleDrawer/SetupRoleDrawer';
import { CreateAddUserCancelModalDialog } from '@Features/roles/SetupRoleDrawer/AddUserCancelModalDialog';
import { DeleteRoleModalDialog } from '@Forms/roles/DeleteRoleModalDialog';
// Provider
import { CreateProviderModalDialog } from '@Features/provider/createProvider/CreateProviderModalDialog';
import { CreateProviderCancelModalDialog } from '@Features/provider/createProvider/CreateProviderCancelModalDialog';
import { SetupProviderDrawer } from '@Features/providers/drawer/SetupProviderDrawer';
import { SetupProviderDrawerCancelDialog } from '@Features/providers/drawer/SetupProviderDrawerCancelDialog';
import { DeleteProviderModalDialog } from '@Features/provider/deleteProvider/DeleteProviderModalDialog';
// Round
import { SetupRoundDrawer } from '@Features/rounds/SetupRound/SetupRoundDrawer';
import { CreateRoundConfigModalDialog } from '@Features/rounds/CreateRoundConfig/CreateRoundConfigModalDialog';
import { CreateRoundConfigCancelModalDialog } from '@Features/rounds/CreateRoundConfig/CreateRoundConfigCancelModalDialog';
import { SetupAvTransactionDrawer } from '@Features/transaction/SetupAvTransactionDrawer/SetupAvTransactionDrawer';
// Users
import { CreateUserModalDialog } from '@Features/users/CreateUser/CreateUserModalDialog';
import { CreateUserCancelModalDialog } from '@Features/users/CreateUser/CreateUserCancelModalDialog';
import { SetupAviatorRoleDrawer } from '@Features/users/SetupAviatorRoleDrawer/SetupAviatorRoleDrawer';
import { CreateAviatorRoleModalDialog } from '@Features/users/CreateAviatorRole/CreateAviatorRoleModalDialog';
import { CancelCreateAviatorRoleModalDialog } from '@Features/users/CreateAviatorRole/CancelCreateAviatorRoleModalDialog';
import { SetupAviatorRoleDeleteModalDialog } from '@Features/users/SetupAviatorRoleDrawer/SetupAviatorRoleDeleteModalDialog';

export const dialogs = (
  <>
    {/* Branch Drawers */}
    <SetupBranchDrawer />
    <CreateBrandModalDialog />
    <CreateBranchModalDialog />
    <BranchStatusModalDialog />
    <SetupMapZoneModalDialog />
    <ClearMapZonesModalDialog />
    <SetupBranchCancelModalDialog />
    <CreateBrandCancelModalDialog />
    <SimpleCreateBranchModalDialog />
    <CreateBranchCancelModalDialog />
    <SetupMapZoneCancelModalDialog />
    <SimpleCreateBranchCancelModalDialog />
    {/* Vehicle Drawers */}
    <SetupIotDrawer />
    <SetupVehicleDrawer />
    <CreateVehicleModalDialog />
    <SetupVehicleRetireDialog />
    <AddVehicleNoteModalDialog />
    <CreateIotModuleModalDialog />
    <SetupIotModuleCancelDialog />
    <SetupVehicleCategoryDrawer />
    <SetupVehicleMaxSpeedDialog />
    <UploadVehicleCSVModalDialog />
    <RemoveVehicleNoteModalDialog />
    <SetupVehicleCancelModalDialog />
    <UploadIotModulesCSVModalDialog />
    <CreateVehicleCancelModalDialog />
    <CreateVehicleCategoryModalDialog />
    <CreateIotModuleCancelModalDialog />
    <CreateVehicleIotLinkCancelDialog />
    <CreateVehicleIotUnlinkConfirmDialog />
    <SetupVehicleCategoryCancelModalDialog />
    {/* Customer Drawers */}
    <SetupCustomerDrawer />
    <AddCustomerNoteModalDialog />
    <RemoveCustomerNoteModalDialog />
    <SetupCustomerCancelModalDialog />
    <DeleteCustomerModalDialog />
    <SignOutCustomerModalDialog />
    <BlockCustomerModalDialog />
    <UnblockCustomerModalDialog />
    {/* <DeleteCustomerModalDialog /> */}
    {/* <CancelDeletionModalDialog /> */}
    {/* Rental Drawers */}
    <SetupRentalDrawer />
    <AddRentalsNoteModalDialog />
    <RemoveRentalsNoteModalDialog />
    <RentalEndRideModalDialog />
    {/* Pricing Drawers */}
    <SetupPricingDrawer />
    <BalanceModalDialog />
    <SetupTransactionDrawer />
    <CreatePricingModalDialog />
    <DeletePaymentModalDialog />
    <CancelBalanceModalDialog />
    <SetupPricingCancelModalDialog />
    <CreatePricingCancelModalDialog />
    {/* Transaction Drawers */}
    <TransactionRefundModalDialog />
    <ConfirmTransactionRefundModalDialog />
    {/* Operator Drawers */}
    <SetupOperatorScanDrawer />
    <SetupOperatorNotesDrawer />
    <SetupOperatorLinkIotDrawer />
    {/* <SetupOperatorFiltersDrawer /> */}
    <SetupOperatorVehicleDrawer />
    <SetupOperatorApproachDrawer />
    <SetupOperatorCommandsDrawer />
    <SetupOperatorManualQRDrawer />
    <SetupOperatorScanCancelModal />
    <SetupOperatorReplaceQRDrawer />
    <SetupOperatorDeleteNoteDrawer />
    <SetupOperatorCreateNoteDrawer />
    <SetupOperatorServiceStateDrawer />
    <SetupOperatorProfileDrawer />
    <SetupAddUserDrawer />
    <SetupRoleDrawer />
    <CreateAddUserCancelModalDialog />
    <DeleteRoleModalDialog />
    <CreateProviderModalDialog />
    <CreateProviderCancelModalDialog />
    <SetupProviderDrawer />
    <SetupProviderDrawerCancelDialog />
    <DeleteProviderModalDialog />
    <SetupRoundDrawer />
    <CreateRoundConfigModalDialog />
    <CreateRoundConfigCancelModalDialog />
    <SetupAvTransactionDrawer />
    <CreateUserModalDialog />
    <CreateUserCancelModalDialog />
    <SetupAviatorRoleDrawer />
    <CreateAviatorRoleModalDialog />
    <CancelCreateAviatorRoleModalDialog />
    <SetupAviatorRoleDeleteModalDialog />
  </>
);
