import { InputSelectIcon } from '@Components/theme/overrides/CustomIcons';
import { InputAdornment, TextField, TextFieldProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = Omit<TextFieldProps, 'sx'> & {
  sx?: SystemStyleObject<Theme>;
};
export const SelectBoxTextFieldInput: React.FC<Props> = ({ InputProps, sx, ...rest }) => {
  return (
    <TextField
      sx={[
        {
          cursor: 'pointer',
          '.MuiInputBase-root': { cursor: 'pointer' },
          input: { cursor: 'pointer' },
        },
        sx ?? {},
      ]}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
            <InputSelectIcon sx={{ '&:not(p)': { position: 'static' } }} />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...rest}
    />
  );
};
