import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { CustomerRentalStatusLabel } from './CustomerRentalStatusLabel';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: '66px',
      width: '544px',
      padding: 2,
      backgroundColor: palette.grey['100'],
      cursor: 'pointer',
      marginTop: '19px',
      marginX: 'auto',
      ...roundedBorder,
    },
    priceFont: {
      fontFamily: 'Public Sans',
      color: '#318688',
      fontWeight: '600',
      fontStyle: 'normal',
    },
    dateFont: {
      fontFamily: 'Public Sans',
      color: '#637381',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    nameFont: {
      fontFamily: 'Public Sans',
      color: '#212B36',
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
    button: {
      width: '53px',
      height: '24px',
      paddingY: '2px',
      paddingX: '8px',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

interface RentalsCardProps {
  price: number;
  date: string;
  licensePlate: string;
  rentalId: string;
  status: string;
}

export const CustomerRentalCard: React.FC<RentalsCardProps> = ({
  price,
  date,
  licensePlate,
  rentalId,
  status,
}) => {
  const styles = useSx();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={styles.container}
      spacing={2}
    >
      <Box
        sx={{
          display: 'flex',
          width: '160px',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle2" sx={styles.priceFont}>
          GEL {price}
        </Typography>
        <Typography variant="caption" sx={styles.dateFont}>
          <DateLabel date={new Date(date)} format="long" />
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minWidth: '130px',
          // width: '200px',
          margin: '0px',
        }}
      >
        <Typography variant="subtitle2" sx={styles.nameFont}>
          {licensePlate}
        </Typography>
        <Typography variant="caption" sx={styles.dateFont}>
          ID {rentalId}
        </Typography>
      </Box>
      <CustomerRentalStatusLabel label={status} />
    </Stack>
  );
};
