import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';

import { useAutoFocus } from '@Components/shared/hooks/useAutoFocus';
import { usePressEnter } from '@Components/shared/hooks/usePressEnter';
import { FilterColumnDialogContentProps } from '@Components/props/table/FilterColumnDialogContentProps';

import { TableFilterDialogLayout } from '../TableFilterDialogLayout';

type Props = FilterColumnDialogContentProps<string | undefined>;

export const StringTableFilterDialogContent: React.FC<Props> = ({
  open,
  codeName,
  handleSetFilters,
}) => {
  const [text, setText] = useState<string | null>('');

  useEffect(() => {
    setText('');
  }, []);

  const ref = useAutoFocus<HTMLDivElement>(open);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setText(event.target.value || '');
    },
    [setText]
  );

  const handleApplyClick = useCallback(() => {
    if (text) {
      handleSetFilters && handleSetFilters({ codeName: codeName, value: text });
    } else {
      handleSetFilters && handleSetFilters({ codeName: codeName, value: undefined });
    }
  }, [text]);

  const handleResetClick = useCallback(() => {
    handleSetFilters && handleSetFilters({ codeName: codeName, value: undefined });
  }, []);

  const handleKeyUp = usePressEnter<HTMLDivElement>(handleApplyClick);

  return (
    <TableFilterDialogLayout
      onApplyClick={handleApplyClick}
      onResetClick={handleResetClick}
      sx={{ width: '193px' }}
    >
      <TextField
        size="small"
        label="Search by"
        data-at-text-field="StringFilter"
        value={text}
        onChange={handleChange}
        inputRef={ref}
        onKeyUp={handleKeyUp}
      />
    </TableFilterDialogLayout>
  );
};
