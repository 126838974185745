// System
import { Box, Button, Stack, Typography } from '@mui/material';

// Types
import { CSVFileType } from '@Modal/reducers/vehicles/types';

// Hooks
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

type Props = {
  file: CSVFileType;
  onDelete?: () => void;
};

export const SingleFileUploadFilledPlaceholder: React.FC<Props> = ({ file, onDelete }) => {
  const ellipsis = useEllipsis();
  const name = typeof file === 'string' ? file : file?.name;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Typography color="secondary" variant="body2" sx={{ marginLeft: 1 }} fontWeight="bold">
        <Box sx={{ ...ellipsis, maxWidth: '400px' }}>{name}</Box>
      </Typography>
      <Button variant="text" size="small" color="inherit" onClick={onDelete}>
        Delete
      </Button>
    </Stack>
  );
};
