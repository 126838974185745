import { Box } from '@mui/material';
import Styles from './PlayerIdContainer.module.scss';
import { useRoundDrawer } from '@Modal/hooks/rounds/useRoundDrawer';

export const PlayerIds = () => {
  const { selectedRoundData } = useRoundDrawer();
  const { playerIds } = selectedRoundData;
  return (
    <Box className={Styles.PlayerIds}>
      {playerIds && playerIds?.length > 0
        ? playerIds?.map((id) => <Box className={Styles.PlayerIdContainer}>{id}</Box>)
        : 'No Player IDs'}
    </Box>
  );
};
