import { Avatar, IconButton, Stack, Theme, Typography, useTheme } from '@mui/material';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { SystemStyleObject } from '@mui/system';
import Iconify from '@Components/iconify/Iconify';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useRentalsNote } from '@Modal/hooks/rentals/useRentalsNote';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      minHeight: '66px',
      padding: 2,
      backgroundColor: palette.grey['100'],
      width: '560px',
      overflow: 'clip',
      ...roundedBorder,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

type props = {
  text?: string;
  id?: string;
  createdAt: string;
  customersId?: string;
};

export const RentalsNoteCard = ({ text, id, createdAt }: props) => {
  const styles = useSx();
  const { palette } = useTheme();
  const { setShowDeleteRentalNoteForm } = useRentalsNote();
  const { selectedRental } = useRentalsDrawer();
  const firstName = selectedRental.firstName;
  const lastName = selectedRental.lastName;

  const dispatch = useDispatch<AppDispatch>();

  function getFirstLetters(str1: string | null, str2: string | null) {
    const firstLetter1 = str1?.charAt(0);
    const firstLetter2 = str2?.charAt(0);
    return `${firstLetter1}${firstLetter2}`;
  }

  const initials = getFirstLetters(firstName, lastName);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.container}
      spacing={2}
    >
      <Stack direction="row" spacing={1}>
        <Avatar>{initials}</Avatar>
        <Stack sx={{ width: '430px', overflow: 'auto' }}>
          <Typography variant="subtitle2">{text}</Typography>
          <Stack direction="row">
            <Typography variant="caption" color={palette.grey.A700}>
              {firstName} {lastName}
            </Typography>
            <Iconify icon="map:circle" color={palette.grey.A700} padding={1} />
            <Typography variant="caption" color={palette.grey.A700}>
              <DateLabel date={new Date(createdAt)} format="long" />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <IconButton
        onClick={() =>
          dispatch(
            setShowDeleteRentalNoteForm({
              status: true,
              noteId: id,
            })
          )
        }
      >
        <AssetIcon src="trash-02" size="xxxl" />
      </IconButton>
    </Stack>
  );
};
