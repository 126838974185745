// System
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Redux
import { useCustomersDrawer } from '../customers/useCustomerDrawer';
import { useRentalsDrawer } from '../rentals/useRentalsDrawer';
import { getCustomerTransactionsList } from '@Modal/reducers/transactions/transactionsSlice';
import { getRentalTransactionsList } from '@Modal/reducers/transactions/transactionsSlice';
import { setTransactionPagination } from '@Modal/reducers/transactions/transactionsSlice';

export const useTransactions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCustomerData } = useCustomersDrawer();
  const { selectedRental } = useRentalsDrawer();

  const { transactions, customerTransactions, rentalTransactions, loadingContent, pagination } =
    useSelector((state: StoreInterface) => state?.transactions);

  const handleCustomerTransactions = () => {
    if (selectedCustomerData) {
      dispatch(getCustomerTransactionsList(selectedCustomerData.id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  const handleRentalTransactions = () => {
    if (selectedRental) {
      dispatch(getRentalTransactionsList(selectedRental.id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  return {
    // Store
    pagination,
    transactions,
    loadingContent,
    rentalTransactions,
    customerTransactions,
    setTransactionPagination,
    // Actions
    handleRentalTransactions,
    handleCustomerTransactions,
  };
};
