import { useMemo } from 'react';
import { Button, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import MenuPopover from '@Components/menu-popover';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';

import { usePopOvers } from '@Components/shared/hooks/usePopOvers';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useDispatch } from 'react-redux';

type Props = {
  value?: string | null;
  sx?: SystemStyleObject<Theme>;
  id?: string;
};

export const BranchStatusLabel: React.FC<Props> = ({ value, id, sx }) => {
  const { branchStatuses, setShowUpdateStatusModal, branches } = useBranches();
  const { open, openPopover, closePopover } = usePopOvers();
  const dispatch = useDispatch();
  const BranchStatusDropDown = useMemo(
    () =>
      branchStatuses?.map(({ key, value: branchValue }) => (
        <MenuItemEx
          key={key}
          value={key}
          onClick={(e) => {
            const selectedBranch = branches.find((va) => va.id === id);
            closePopover(e);
            dispatch(
              setShowUpdateStatusModal({
                modalStatus: true,
                status: key,
                selectedBranch: selectedBranch,
              })
            );
          }}
          name={branchValue}
        >
          {branchValue}
        </MenuItemEx>
      )),
    [branchStatuses, closePopover, id]
  );

  const text = branchStatuses?.find(({ key }) => key === value)?.value;

  if (!text) {
    return null;
  }

  return (
    <>
      <AtLocator type="button" name="BranchLabel">
        <Button
          variant="contained"
          color={text === 'Operational' ? 'success' : 'inherit'}
          sx={{ minWidth: '145px', ...sx }}
          size="small"
          endIcon={<AssetIcon src="chevron-down" size="s" />}
          onClick={openPopover}
        >
          {text}
        </Button>
      </AtLocator>
      <MenuPopover open={open} onClose={closePopover} disabledArrow sx={{ marginY: 1 }}>
        {BranchStatusDropDown}
      </MenuPopover>
    </>
  );
};
