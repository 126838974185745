import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupCustomerFormState,
  setupCustomerFormStateDefault,
  setupCustomerSchemaDefault,
  SetupCustomerFormContext,
} from '@Forms/customers/SetupCustomerFormContext';

export const SetupCustomerFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<SetupCustomerFormState>>>(
    setupCustomerSchemaDefault
  );
  const methods = useForm({
    defaultValues: setupCustomerFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupCustomerFormContext.Provider value={value}>{children}</SetupCustomerFormContext.Provider>
  );
};
