import Cookies from 'js-cookie';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosAuthorizationInstance } from '@utils/axiosInstances';

// Types
import { LogInParams } from './types';

// Get
export const decodeAccessToken = createAsyncThunk(
  'authorization/decodeAccessToken',
  async (accesToken: string, thunkApi) => {
    const config = {
      headers: {
        'x-public-key': process.env.REACT_APP_BACKOFFICE_PUBLIC_KEY,
        Authorization: `Bearer ${accesToken}`,
      },
    };
    return axiosAuthorizationInstance
      .get('/v1/auth/me', config)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Post
export const refreshAccessToken = createAsyncThunk(
  'authorization/refreshAccessToken',
  async (refreshToken: string, thunkApi) => {
    const config = {
      headers: {
        'x-public-key': process.env.REACT_APP_BACKOFFICE_PUBLIC_KEY,
      },
    };

    const requestData = {
      refresh_token: refreshToken,
    };

    return axiosAuthorizationInstance
      .post('/v1/auth/token/refresh', requestData, config)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Post
export const logInRequest = createAsyncThunk(
  'authorization/logIn',
  async (userData: LogInParams, thunkApi) => {
    const requestData = {
      username: userData.username,
      password: userData.password,
      save: userData.save,
    };

    const config = {
      headers: { 'x-public-key': process.env.REACT_APP_BACKOFFICE_PUBLIC_KEY },
    };

    return axiosAuthorizationInstance
      .post('/v1/auth/login', requestData, config)
      .then(async (res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

// Type
const initialState = {
  accessToken: null,
  currentUser: null,
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
  },
  extraReducers: {
    [logInRequest.fulfilled?.type]: (state, { payload }) => {
      state.accessToken = payload.access_token;

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      Cookies.set('AuthToken', payload?.access_token, {
        expires: tomorrow,
      });

      Cookies.set('RefreshToken', payload?.refresh_token);
    },
    [refreshAccessToken.fulfilled?.type]: (state, { payload }) => {
      state.accessToken = payload.access_token;

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      Cookies.set('AuthToken', payload?.access_token, {
        expires: tomorrow,
      });
    },
    [decodeAccessToken.fulfilled?.type]: (state, { payload }) => {
      state.currentUser = payload;

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const payloadString = JSON.stringify(payload);
      Cookies.set('currentUser', payloadString, {
        expires: tomorrow,
      });
    },
    [decodeAccessToken.fulfilled?.type]: (state, { payload }) => {
      state.currentUser = payload;
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const payloadString = JSON.stringify(payload);
      Cookies.set('currentUser', payloadString, {
        expires: tomorrow,
      });
    },
  },
});

export const { reducer: authorizationReducer } = authorizationSlice;
export const { setAccessToken } = authorizationSlice.actions;
