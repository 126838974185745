import { Stack, Typography } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const ProviderTransactionsTitle: React.FC = () => {
  return (
    <AtLocator type="container" name="ProviderTransactionsListTitle">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBottom: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Provider Transactions</Typography>
        </Stack>
      </Stack>
    </AtLocator>
  );
};
