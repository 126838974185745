export enum EditStatus {
  Pristine,
  Added,
  Modified,
  Deleted,
}

export type Editable<T> = {
  value: T;
  status: EditStatus;
};

export const asEditable = <T>(value: T): Editable<T> => ({
  value,
  status: EditStatus.Pristine,
});
