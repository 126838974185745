// System
import React from 'react';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Divider, Typography } from '@mui/material';

// Hooks
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Types
import { AppDispatch } from 'src/app/store';
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';
import { useVehicleCommands } from '@Hooks/shared/useVehicleCommands';

// Styles
import Styles from './SetupOperatorCommandsDrawer.module.scss';

export const SetupOperatorCommandsDrawer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const socketId = localStorage.getItem('socketId');
  const { selectedVehicleCategoryDashboardCommands } = useVehicleCategoryDrawer();
  const { showCommandsModal, setShowCommandsModal, fetchedVehicle } = useOperatorVehicles();

  const { handleSendCommands } = useVehicleCommands();

  function commandStringFormatter(inputString: string) {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
    );
  }

  // Data
  const commands = selectedVehicleCategoryDashboardCommands.map((command: string) => {
    return {
      ttl: commandStringFormatter(command),
      icon: `icons/operator/ic_${command}`,
      onClick: () => handleSendCommands(socketId, command, [fetchedVehicle?.iotImei]),
    };
  });

  return (
    <SetupDrawer
      name="SetupOperatorCommandsDrawer"
      open={showCommandsModal}
      onClose={() => dispatch(setShowCommandsModal(false))}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorCommandsDrawer}>
        <Box className={Styles.TitleContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Send Command
          </Typography>
          <Box className={Styles.CloseIcon} onClick={() => dispatch(setShowCommandsModal(false))}>
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <Box className={Styles.CommandCardContainer}>
          {commands.map((command: any) => {
            return (
              <Box onClick={command?.onClick} key={command?.ttl} className={Styles.CommandCard}>
                <AssetIcon sx={{ width: '24px', height: '24px' }} src={command.icon} />
                <Typography fontSize={'14px'} fontWeight={400}>
                  {command.ttl}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </SetupDrawer>
  );
};
