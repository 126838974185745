// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import {
  getVehicleRentals,
  setVehicleRentals,
} from '@Modal/reducers/vehicles/rentals/vehicleRentalsSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useVehiclesDrawer } from '../vehicles/useVehicleDrawer';

export const useVehicleRentals = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { vehicleRentals } = useSelector((state: StoreInterface) => state?.vehicleRentals);

  const { selectedVehicleData } = useVehiclesDrawer();

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleGetVehicleRentals = () => {
    if (selectedVehicleData) {
      dispatch(getVehicleRentals(selectedVehicleData.id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  return {
    // Store
    vehicleRentals,
    // Actions
    setVehicleRentals,
    handleGetVehicleRentals,
  };
};
