// System
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

// Components
import { generateBatteryteIcon } from '@Components/shared/maps/CustomMarkerIcons';

// Types
type Props = {
  battery: {
    batteryLevel: string;
    iconName: string;
    isChecked: boolean;
  };
  onClick: (arg: { batteryLevel: string; iconName: string; isChecked: boolean }) => void;
};

// Styles
import Styles from './OperatorBatteryCard.module.scss';

export const OperatorBatteryCard: React.FC<Props> = ({ battery, onClick }) => {
  return (
    <Box onClick={() => onClick(battery)} className={Styles.OperatorBatteryCard}>
      <Box className={battery.isChecked ? Styles.IconContainer_Selected : Styles.IconContainer}>
        <img
          src={`${generateBatteryteIcon(battery?.iconName)}`}
          width={20}
          height={20}
          alt="battery"
        />
      </Box>
      <Typography fontSize={'12px'}>{battery?.batteryLevel}</Typography>
    </Box>
  );
};
