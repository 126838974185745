import { Button } from '@mui/material';
import FormProvider from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { useCreateIotModuleForm } from '@Forms/vehicles/CreateIotModuleFormContext';

import { CreateIotModuleContent } from './CreateIotModuleContent';
import { useCreateVehicleIOTModule } from '@Modal/hooks/vehicles/iot/useCreateVehicleIotModule';

export const CreateIotModuleForm = () => {
  const methods = useCreateIotModuleForm();
  const { submittable, handleCreateCancel, handleSubmitCreateVehicleIOTModule } =
    useCreateVehicleIOTModule();

  return (
    <FormProvider methods={methods}>
      <CreateDialogLayout>
        <CreateIotModuleContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateVehicleIOTModule}
            >
              Confirm
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
