import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateProviderFormContext,
  CreateProviderFormState,
  createProviderFormStateDefault,
  createProviderSchemaDefault,
} from '@Forms/providers/CreateProviderFormContext';

export const CreateProviderFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateProviderFormState>>>(
    createProviderSchemaDefault
  );
  const methods = useForm({
    defaultValues: createProviderFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateProviderFormContext.Provider value={value}>
      {children}
    </CreateProviderFormContext.Provider>
  );
};
