// System
import { useState } from 'react';

import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setShowOperatorFiltersDrawer } from '@Modal/reducers/operator/operatorFilters/operatorFiltersSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { fetchBranches } from '@Modal/reducers/branches/branchSlice';
import { useBranchesTable } from '../branches/useBranchesTable';

export const useOperatorFilters = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { selectedFilters: branchFilters } = useBranchesTable();
  const { showOperatorFiltersDrawer, selectedFilters } = useSelector(
    (state: StoreInterface) => state?.operatorFilters
  );

  // Fetch All Branches For Operator Only
  const handleGetAllBranchesForOperator = () => {
    dispatch(fetchBranches({ selectedFilters: branchFilters }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleOperatorFilterCTAClick = () => dispatch(setShowOperatorFiltersDrawer(true));
  const handleOperatorFiltersDrawerCancel = () => dispatch(setShowOperatorFiltersDrawer(false));

  return {
    // Store
    selectedFilters,
    showOperatorFiltersDrawer,
    // Actions
    handleOperatorFilterCTAClick,
    handleGetAllBranchesForOperator,
    handleOperatorFiltersDrawerCancel,
  };
};
