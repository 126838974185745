import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupVehicleContent } from './SetupVehicleContent';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

export const SetupVehicleDrawer: React.FC = () => {
  const { showVehicleDrawer, handleCreateCancel } = useVehiclesDrawer();
  return (
    <SetupDrawer name="SetupVehicle" open={showVehicleDrawer} onClose={handleCreateCancel}>
      <SetupVehicleContent />
    </SetupDrawer>
  );
};
