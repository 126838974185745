// System
import React from 'react';
import { Box, fontWeight } from '@mui/system';
import { Typography } from '@mui/material';

// Types
import { AppDispatch } from 'src/app/store';
import { BranchesType } from '@Modal/reducers/branches/types';

// Styles
import Styles from './OperatorFilterTag.module.scss';

// Types
type Props = {
  name: string;
  onClick?: () => void;
  isChecked?: boolean;
};

export const OperatorFilterTag: React.FC<Props> = ({ name, onClick, isChecked }) => {
  return (
    <Box
      onClick={onClick}
      className={isChecked ? Styles.OperatorFilterTag_Selected : Styles.OperatorFilterTag}
    >
      <Typography fontSize={'13px'} fontWeight={isChecked ? 600 : 400}>
        {name}
      </Typography>
    </Box>
  );
};
