import { MenuItem, Stack } from '@mui/material';

import { RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { RHFSelect } from '@Components/hook-form';
import { useMemo } from 'react';

// Styles
import Styles from './CreateIotModuleContent.module.scss';

const useDropDown = () => {
  const iotTypes = [
    { key: 'OKAI', value: 'OKAI' },
    { key: 'OMNI', value: 'OMNI' },
    { key: 'NIU', value: 'NIU' },
    { key: 'DUNASYS', value: 'DUNASYS' },
  ];

  const iotTypeseDropDown = useMemo(
    () =>
      iotTypes?.map(({ key, value }) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      )),
    [iotTypes]
  );

  return {
    iotTypes: iotTypeseDropDown,
  };
};

export const CreateIotModuleContent = () => {
  const dropDown = useDropDown();

  return (
    <Stack className={Styles.CreateIotModuleContent}>
      <AtLocator type="select-field" name="iotType" key="iotType">
        <RHFSelect
          name="iotType"
          size="small"
          id="outlined-basic"
          label="IoT Type"
          variant="outlined"
        >
          {dropDown.iotTypes}
        </RHFSelect>
      </AtLocator>
      {/* <AtLocator type="text-field" name="host" key="host">
        <RHFTextField name="host" label="Host" />
      </AtLocator>
      <AtLocator type="text-field" name="port" key="port">
        <RHFIntegerField name="port" label="Port" />
      </AtLocator>
      <Stack className={Styles.CreateIotModuleContent}>
        <AtLocator type="text-field" name="qrCode" key="username">
          <RHFTextField name="username" label="User" helperText="Optional" />
        </AtLocator>
        <AtLocator type="text-field" name="qrCode" key="password">
          <RHFTextField name="password" label="Password" helperText="Optional" />
        </AtLocator>
      </Stack> */}
      <AtLocator type="text-field" name="vin" key="imei">
        <RHFTextField name="imei" label="IMEI" />
      </AtLocator>
    </Stack>
  );
};
