import { useMemo } from 'react';

// System
import { Controller } from 'react-hook-form';
import { Stack, Typography, Box } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import {
  CheckmarkSelect,
  CheckmarkSelectDropDownContent,
} from '@Components/shared/selects/CheckmarkSelect';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { SelectBoxDropDownLayout } from '@Components/shared/selects/SelectBox';

// Contracts
import { KeyValue } from '@contracts/view/KeyValue';

// Redux
import { useDispatch } from 'react-redux';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';
import { setSelectedCategory } from '@Modal/reducers/branches/branchDrawerSlice';

// Forms & Features
import { useSetupBranchForm } from '@Forms/branches/SetupBranchFormContext';
import { HideDeleteActionSelect } from '@Features/branches/shared/HideDeleteActionSelect';

const useOptions = () => {
  const { categories } = useBranchDrawer();
  const ellipsis = useEllipsis();
  const allCategoriesView: KeyValue<string, JSX.Element>[] = useMemo(
    () =>
      categories.map(({ id, name }) => ({
        key: id,
        value: <Box sx={{ ...ellipsis, maxWidth: '200px' }}>{name}</Box>,
      })),
    [ellipsis, categories]
  );
  return { allCategories: allCategoriesView };
};

const CheckmarkDropDown: React.FC = () => {
  const { allCategories } = useOptions();
  const { selectedBranch } = useBranches();
  const { handleCheckmarkCategoryChange } = useBranchesMaps();
  const { categories, setCategories, getSelectedCategoryMapZones } = useBranchDrawer();

  const dispatch = useDispatch();

  const modifyAssignedCategories = () =>
    categories.length ? categories.filter((categ) => categ.selected).map((categ) => categ.id) : [];

  const handleCheckbox = (id: string) => {
    const selectedCategories = categories.filter((categ) => categ.id === id);
    const selectedCategoryIDs = selectedCategories.map((categ) => categ.id);
    handleCheckmarkCategoryChange(selectedCategoryIDs);

    dispatch(setSelectedCategory(selectedCategories[0]));
    getSelectedCategoryMapZones(selectedCategories[0]);

    return selectedBranch.id
      ? dispatch(setCategories({ categoryId: id, branchId: selectedBranch.id }))
      : null;
  };

  if (allCategories.length === 0) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="body2">There are no available categories</Typography>
      </Box>
    );
  }

  return (
    <SelectBoxDropDownLayout sx={{ maxHeight: '400px', width: '240px' }}>
      <CheckmarkSelectDropDownContent
        options={allCategories}
        checkedValues={modifyAssignedCategories()}
        onChange={handleCheckbox}
      />
    </SelectBoxDropDownLayout>
  );
};

const CheckedCategories: React.FC = () => {
  const { categories, handleCategoryDelete, handleHiddenCategoryChange } = useBranchDrawer();

  const modifyHiddenCategories = () =>
    categories ? categories.filter((cat) => cat.hidden).map((categ) => categ.id) : [];

  const selectedCategories = categories.filter((cat) => cat.selected);

  return (
    <AtLocator type="container" name="CheckedCategories">
      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
        {selectedCategories.length > 0
          ? selectedCategories.map(({ id, name }) => {
              return (
                <HideDeleteActionSelect
                  name="CategoryBranchAction"
                  text={name}
                  key={id}
                  hidden={modifyHiddenCategories().includes(id)}
                  onDelete={handleCategoryDelete(id)}
                  onChangeHidden={handleHiddenCategoryChange(id)}
                />
              );
            })
          : null}
      </Stack>
    </AtLocator>
  );
};

export const AddVehicleCategories: React.FC = () => {
  const { control } = useSetupBranchForm();
  return (
    <Stack direction="column" spacing={2}>
      <AtLocator type="readonly-text" name="AddVehicleCategories">
        <Typography variant="subtitle2">Add vehicle categories</Typography>
      </AtLocator>
      <Controller
        name="categories"
        control={control}
        render={({ fieldState: { error } }) => {
          return (
            <AtLocator type="special-field" name="categories" box>
              <CheckmarkSelect
                placeholder="Select vehicle categories"
                name="CategorySelector"
                error={!!error}
                helperText={error ? 'Incorrect selected categories' : undefined}
              >
                <CheckmarkDropDown />
              </CheckmarkSelect>
            </AtLocator>
          );
        }}
      />

      <CheckedCategories />
    </Stack>
  );
};
