/* eslint-disable react/no-unescaped-entities */
import React, { ReactNode } from 'react';
import { Stack, Typography, IconButton, Tooltip, InputAdornment } from '@mui/material';

import { RHFSwitch } from '@Components/hook-form';

import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Redux
import { useDispatch } from 'react-redux';
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

type LevelProps = {
  name: string;
  label: string;
  children: ReactNode;
};
const Level: React.FC<LevelProps> = ({ label, name, children }) => {
  return (
    <AtLocator type="special-field" name={name}>
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        sx={{ flex: 1 }}
      >
        <Stack direction="row" alignItems="center">
          <AtLocator type="readonly-text" name="label">
            <Typography variant="subtitle2">{label}</Typography>
          </AtLocator>
          <AtLocator type="tooltip" name="tooltip">
            <Tooltip title={children} arrow>
              <IconButton>
                <AssetIcon src="info-circle" size="xl" />
              </IconButton>
            </Tooltip>
          </AtLocator>
        </Stack>
        <AtLocator type="text-field" name="text-field">
          <RHFIntegerField
            name={name}
            variant="outlined"
            label=""
            size="small"
            placeholder="0"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            InputLabelProps={{ shrink: true }}
          />
        </AtLocator>
      </Stack>
    </AtLocator>
  );
};

export const IoTBatteryLevelSwitcher: React.FC = () => {
  const { showSpeedSettingsFilters, setShowSpeedSettingsFilters } = useVehicleCategoryDrawer();
  const roundedBorder = useRoundedBorder();
  const dispatch = useDispatch();

  return (
    <Stack direction="column" spacing={1.5}>
      <AtLocator type="readonly-text" name="IoTBatteryLevel">
        <Typography variant="subtitle1">IoT battery level</Typography>
      </AtLocator>
      <AtLocator type="special-field" name="iotBatteryLevelFilters">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <AtLocator type="readonly-text" name="label">
            <Typography variant="body2">Use IoT Battery level filters</Typography>
          </AtLocator>
          <AtLocator type="slider-field" name="slider">
            <RHFSwitch
              name="iotBatteryLevelFilters"
              label=""
              labelPlacement="start"
              onClick={() => dispatch(setShowSpeedSettingsFilters(!showSpeedSettingsFilters))}
            />
          </AtLocator>
        </Stack>
      </AtLocator>
    </Stack>
  );
};
