import { CSSProperties } from 'react';

import Iconify from '@Components/iconify/Iconify';
import Label from '@Components/label/Label';
import { LabelVariant } from '@Components/label';

type Props = {
  title: string;
  color: CSSProperties['color'];
  variant: LabelVariant | 'ghost';
};

export const ColorCircleLabel: React.FC<Props> = ({ title, color, variant }) => {
  return (
    <Label
      data-at-container="label"
      startIcon={<Iconify icon="map:circle" color={color} padding={0.5} />}
      variant={variant === 'ghost' ? 'outlined' : variant}
      sx={{
        textTransform: 'none',
        cursor: 'inherit',
        ...(variant === 'ghost' ? { borderStyle: 'none' } : {}),
      }}
    >
      {title}
    </Label>
  );
};
