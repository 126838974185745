// System
import React, { useEffect, useMemo } from 'react';

// Components
import { TablePagination } from '@mui/material';
import { TableBodyEx, TableContainerEx, TableEx } from '@Components/shared/tables/components';
import {
  ColumnVisibilityPresenter,
  TableHeadColumnsPresenter,
  TableHeadPresenter,
  TableRowPresenter,
} from '@Components/shared/tables/presenters';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

// Utils
import { applyColumnVisibility } from '@utils/data/applyColumnVisibility';

//  Table
import { useCampaignTableColumns } from './useCampaignTableColumns';

// Redux
import { useDispatch } from 'react-redux';
import { CampaignItemDto } from '@contracts/api/dto/campaign/CampaignDto';
import { usePaginatedFreeBetsCampaignsQuery } from '@Src/generated.graphql';
import { useCampaignsTable } from '@Modal/hooks/campaigns/useCampaignsTable';

export const CampaignsTable: React.FC = () => {
  const dispatch = useDispatch();
  const {
    page,
    limit,
    total,
    setLimit,
    setPage,
    setTotal,
    columnVisibility,
    campaignsListFilters,
    visiblityCustomizable,
    campaignTableFilters,
  } = useCampaignsTable();
  const columns = useCampaignTableColumns();
  const { data, loading, refetch } = usePaginatedFreeBetsCampaignsQuery({
    variables: {
      limit,
      page,
      search: '',
      // @ts-ignore
      filter: campaignTableFilters,
    },
  });

  const visibleColumns = useMemo(
    () => applyColumnVisibility(columns, campaignsListFilters),
    [columns, columnVisibility]
  );

  useEffect(() => {
    if (data?.paginatedFreeBetsCampaigns) {
      const { total, limit, page } = data.paginatedFreeBetsCampaigns;
      dispatch(setTotal(total));
      dispatch(setLimit(limit));
      dispatch(setPage(page));
    }
  }, [data, dispatch]);

  const header = useMemo(
    () => (
      <TableHeadPresenter>
        <TableHeadColumnsPresenter columns={visibleColumns} />
        {/* @ts-ignore */}
        <ColumnVisibilityPresenter columns={columns} content={visiblityCustomizable} />
      </TableHeadPresenter>
    ),
    [columns]
  );
  if (loading) return <LoadingScreen />;

  const handlePageChange = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage + 1));
    refetch();
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLimit(parseInt(event.target.value, 10)));
    dispatch(setPage(1));
    refetch();
  };

  return (
    <>
      <TableContainerEx sx={{ height: '70vh' }}>
        <TableEx name="CampaignsList">
          {header}
          <TableBodyEx>
            {/* @ts-ignore */}
            {data?.paginatedFreeBetsCampaigns?.docs?.map((row: CampaignItemDto) => (
              <TableRowPresenter
                id={row._id}
                key={row._id}
                columns={visibleColumns}
                row={row}
                onClick={() => {}}
              />
            ))}
          </TableBodyEx>
        </TableEx>
      </TableContainerEx>
      <TableEx name="CustomerListFooter">
        <TablePagination
          component="div"
          count={total}
          page={page - 1}
          rowsPerPage={limit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableEx>
    </>
  );
};
