// System
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import {
  EnumTableColumnHeader,
  StringTableColumnHeader,
  TableColumnHeader,
} from '@Components/shared/tables/columns';
import { SingleDateTableColumnHeader } from '@Components/shared/tables/columns/DateTableColumnHeader/SingleDateTableColumnHeader';

// Contracts
import {
  TableColumnDescription,
  TableColumnDescriptionArray,
} from '@contracts/view/TableColumnDescription';
import { Filterable } from '@contracts/common/traits/Filterable';
import { CampaignItemDto } from '@contracts/api/dto/campaign/CampaignDto';

// Utils
import {
  CampaignListColumns,
  CampaignListFilter,
  campaignListColumns,
} from '@utils/tables/campaigns/campaignListFilter';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Redux
import { useDispatch } from 'react-redux';
import { useCampaignsTable } from '@Modal/hooks/campaigns/useCampaignsTable';

type HeaderOptions = {
  filter: Filterable<CampaignListFilter>;
};

type Columns = TableColumnDescriptionArray<
  CampaignItemDto,
  HeaderOptions,
  typeof campaignListColumns
>;

type Column = TableColumnDescription<CampaignItemDto, HeaderOptions, CampaignListColumns>;

const useSx = () => {
  return {
    id: { width: '80px' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const useCampaignTableColumns = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();
  const { handleFilterSelect, setCampaignTableFilters } = useCampaignsTable();
  const dispatch = useDispatch();

  return useMemo(
    (): Column[] =>
      [
        {
          name: 'campaign._id',
          title: 'ID',
          sx: { minWidth: '100px', maxWidth: '180px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'id'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <>{row?._id && <TruncateText text={row?._id} maxLength={8} textStyles={styles.id} />}</>
          ),
        },
        {
          name: 'campaign.description',
          title: 'Description',
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.description}</Box>,
        },
        {
          name: 'campaign.providerId',
          title: 'Provider ID',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.providerId && <TruncateText text={row?.providerId} maxLength={8} />}
            </Box>
          ),
        },
        {
          name: 'campaign.startDate',
          title: 'Start Date',
          sx: { minWidth: '170px', maxWidth: '240px' },
          header: ({ title }) => (
            <SingleDateTableColumnHeader
              title={title}
              onApply={(date) => {
                date?.length
                  ? dispatch(
                      setCampaignTableFilters({
                        codeName: 'startDate',
                        value: date,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setCampaignTableFilters({
                    codeName: 'startDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.startDate && (
                <DateLabel date={new Date(parseISO(row?.startDate))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'campaign.endDate',
          title: 'End Date',
          sx: { minWidth: '180px', maxWidth: '200px' },
          header: ({ title }) => (
            <SingleDateTableColumnHeader
              title={title}
              onApply={(date) => {
                date?.length
                  ? dispatch(
                      setCampaignTableFilters({
                        codeName: 'endDate',
                        value: date,
                      })
                    )
                  : null;
              }}
              onReset={() => {
                dispatch(
                  setCampaignTableFilters({
                    codeName: 'endDate',
                    value: undefined,
                  })
                );
              }}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.endDate && <DateLabel date={new Date(parseISO(row?.endDate))} format="long" />}
            </Box>
          ),
        },
        {
          name: 'campaign.campaignUniqueId',
          title: 'Campaign Unique ID',
          sx: { minWidth: '200px', maxWidth: '250px' },
          header: ({ title }) => (
            <StringTableColumnHeader
              title={title}
              codeName={'campaignUniqueId'}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.campaignUniqueId && <TruncateText text={row?.campaignUniqueId} maxLength={8} />}
            </Box>
          ),
        },

        {
          name: 'campaign.status',
          title: 'Status',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => (
            <EnumTableColumnHeader
              title={title}
              codeName={'status'}
              options={[
                { key: 'Active', value: 'ACTIVE' },
                { key: 'Revoked', value: 'REVOKED' },
              ]}
              handleSetFilters={handleFilterSelect}
            />
          ),
          body: ({ row }) => <Box sx={{ ...ellipsis }}>{row?.status}</Box>,
        },
        {
          name: 'campaign.createdAt',
          title: 'Created At',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.createdAt && (
                <DateLabel date={new Date(parseISO(row?.createdAt))} format="long" />
              )}
            </Box>
          ),
        },
        {
          name: 'campaign.updatedAt',
          title: 'Updated At',
          sx: { minWidth: '150px', maxWidth: '180px' },
          header: ({ title }) => <TableColumnHeader title={title} />,
          body: ({ row }) => (
            <Box sx={{ ...ellipsis }}>
              {row?.updatedAt && (
                <DateLabel date={new Date(parseISO(row?.updatedAt))} format="long" />
              )}
            </Box>
          ),
        },
      ] satisfies Columns,
    [ellipsis]
  );
};
