// Redux
import { useSelector } from 'react-redux';
import { setPagination } from '@Modal/reducers/pricing/pricingSlice';
// Types
import { StoreInterface } from 'src/app/types';

export const usePricing = () => {
  const { pricingList, pagination, loadingContent } = useSelector(
    (state: StoreInterface) => state?.pricing
  );

  return {
    // Store
    pagination,
    pricingList,
    loadingContent,
    // Actions
    setPagination,
  };
};
