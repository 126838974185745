import { BackofficeLiveVehicle, DeviceInfo, RentalInfo } from '@Modal/reducers/vehicles/types';
import { Socket, io } from 'socket.io-client';

export type HashTable = {
  [key: number]: BackofficeLiveVehicle;
};

// export const socket: Socket = io(`${process.env.REACT_APP_VEHICLES_SOCKET_PROXY}/backoffice`, {
//   transports: ['websocket'],
//   reconnection: false,
//   reconnectionDelay: 50000,
// });

// Create a new array with the updated objects
export const updateLiveMHashTableDeeperLevel = (
  hashTable: HashTable,
  keyToUpdate: keyof BackofficeLiveVehicle,
  updatedData: DeviceInfo
): HashTable => {
  // Find the vehicle with the matching IMEI in the hash table
  const vehicleToUpdate = hashTable[+updatedData.imei];

  if (vehicleToUpdate) {
    // Clone the object to avoid mutating the original
    const updatedObj = { ...vehicleToUpdate };

    // Update the specified key with the updatedData
    // @ts-ignore
    updatedObj[keyToUpdate] = { ...updatedObj[keyToUpdate], ...updatedData };

    // Update the vehicle in the hash table
    // @ts-ignore
    hashTable[updatedData.imei] = updatedObj;
  }

  return hashTable;
};

export const updateLiveMapHashTableUpperLevel = (
  hashTable: HashTable,
  keyToUpdate: keyof BackofficeLiveVehicle,
  updatedData: RentalInfo
): HashTable => {
  // Find the vehicle with the matching IMEI in the hash table
  const vehicleToUpdate = hashTable[+updatedData.iotImei];

  if (vehicleToUpdate) {
    // Clone the object to avoid mutating the original
    const updatedObj = { ...vehicleToUpdate };

    // Update the specified key with the updatedData
    // @ts-ignore
    updatedObj[keyToUpdate] = updatedData[keyToUpdate];

    // Update the vehicle in the hash table
    // @ts-ignore
    hashTable[+updatedData.iotImei] = updatedObj;
  }

  return hashTable;
};
