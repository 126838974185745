import React from 'react';
import { Button, Stack } from '@mui/material';

// Styles
import Styles from './SaveSettingsButtonPanel.module.scss';

// Types
type Props = {
  saveDisabled?: boolean;
  onSaveClick?: () => void;
  cancelDisabled?: boolean;
  onCancelClick?: () => void;
};

export const SaveSettingsButtonPanel: React.FC<Props> = ({
  cancelDisabled = false,
  onCancelClick,
  saveDisabled = false,
  onSaveClick,
}) => {
  return (
    <Stack className={Styles.SaveSettingsButtonPanel}>
      <Button
        className={Styles.SaveCTA}
        type="submit"
        disabled={saveDisabled}
        onClick={onSaveClick}
      >
        Save settings
      </Button>
      <Button
        className={Styles.CancelCTA}
        variant="outlined"
        disabled={cancelDisabled}
        onClick={onCancelClick}
      >
        Cancel
      </Button>
    </Stack>
  );
};
