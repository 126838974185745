import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateVehicleFormContext,
  CreateVehicleFormState,
  createVehicleFormStateDefault,
  createVehicleSchemaDefault,
} from '@Forms/vehicles/CreateVehicleFormContext';

export const CreateVehicleFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateVehicleFormState>>>(
    createVehicleSchemaDefault
  );
  const methods = useForm({
    defaultValues: createVehicleFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateVehicleFormContext.Provider value={value}>{children}</CreateVehicleFormContext.Provider>
  );
};
