import { ComponentType, useMemo, useState } from 'react';

import { AlertStoreContext } from '@Features/providers/store/AlertStore';
import { Alert } from '@contracts/domain/Alert';
import { AlertPresenterProps } from '@Components/props/alerts/AlertPresenterProps';

import { AlertPresenterMap } from './AlertPresenterMap';

export const AlertPresenter: React.FC<AlertPresenterProps> = ({ value, onClose }) => {
  const AlertComponent = AlertPresenterMap[value.type] as ComponentType<AlertPresenterProps>;
  return <AlertComponent value={value} onClose={onClose} />;
};

export const AlertStoreProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const value = useMemo(
    () => ({
      alert,
      setAlert,
      AlertPresenter,
    }),
    [alert, setAlert]
  );

  return <AlertStoreContext.Provider value={value}>{children}</AlertStoreContext.Provider>;
};
