import React, { useEffect, useState } from 'react';

// System
import { Box } from '@mui/material';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';
import { useVehicleRentals } from '@Modal/hooks/vehicles/rentals/useVehicleRentals';

// Components
import { useSnackbar } from 'notistack';
import { VehicleRentalsCard } from '../VehicleRentalsCard';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';

// Styles
import Styles from './VehicleRentals.module.scss';

export const VehicleRentals: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { getSingleRental, getSelectedRentalPriceBreakdown } = useRentalsDrawer();
  const { handleGetVehicleRentals, vehicleRentals } = useVehicleRentals();
  const [loading, setLoading] = useState(false);

  const sortedVehicleRentals = vehicleRentals
    .slice()
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  const { enqueueSnackbar } = useSnackbar();

  const handleBoxClick = (id: string) => {
    dispatch(getSingleRental(id))
      .unwrap()
      .then(() => dispatch(getSelectedRentalPriceBreakdown(id)))
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    handleGetVehicleRentals();
    setLoading(false);
  }, []);

  return (
    <DrawerTabPanelLayout>
      <Box className={Styles.VehicleRentals}>
        <Box className={Styles.RentalsContainer}>
          {vehicleRentals.length === 0 ? (
            <Box className={Styles.VehicleRentals}>No Results</Box>
          ) : (
            sortedVehicleRentals?.map((rental) => (
              <Box onClick={() => handleBoxClick(rental.id)}>
                <VehicleRentalsCard
                  name={rental.name}
                  customerId={rental.id}
                  price={rental.price}
                  createdAt={rental.createdAt}
                  state={rental.state}
                />
              </Box>
            ))
          )}
        </Box>
      </Box>
    </DrawerTabPanelLayout>
  );
};
