// System
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';

// Styles
import Styles from './DeleteProviderModalDialog.module.scss';
import { useProviderDrawer } from '@Modal/hooks/providers/useProviderDrawer';

export const DeleteProviderModalDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    showDeleteProviderModal,
    setShowDeleteProviderModal,
    selectedProviderData,
    handleDeleteConfirmation,
  } = useProviderDrawer();
  return (
    <ClosableModal
      name="deleteProviderModalDialog"
      title="Confirmation"
      open={showDeleteProviderModal}
      onClose={() => dispatch(setShowDeleteProviderModal(false))}
      className={Styles.DeleteProviderModalDialog}
    >
      <>
        <CreateDialogLayout>
          <Typography variant="body2">
            Are you sure you want delete {selectedProviderData && `${selectedProviderData?.name}`}
          </Typography>
          <CreateDialogFooterLayout>
            <AtLocator type="button" name="Cancel">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => dispatch(setShowDeleteProviderModal(false))}
              >
                Cancel
              </Button>
            </AtLocator>
            <AtLocator type="button" name="Submit">
              <Button
                type="submit"
                color="error"
                variant="contained"
                onClick={handleDeleteConfirmation}
              >
                Delete
              </Button>
            </AtLocator>
          </CreateDialogFooterLayout>
        </CreateDialogLayout>
      </>
    </ClosableModal>
  );
};
