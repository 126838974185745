import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const useScrollbar = (): SystemStyleObject<Theme> => {
  return {
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      transition: 'opacity 0.25s linear',
      borderRadius: 0.5,
      opacity: 1,
    },
  };
};
