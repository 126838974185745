// Components
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SetupProviderHeader } from './SetupProviderHeader';
import { FormProvider } from 'react-hook-form';
import { SetupProviderDetails } from './SetupProviderDetails';

// Redux
import { useProviderDrawer } from '@Modal/hooks/providers/useProviderDrawer';
import { SetupProviderDrawerFooter } from './SetupProviderDrawerFooter';
import { useSetupProviderForm } from '@Forms/providers/SetupProviderFormContext';
import { Divider, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';

export const SetupProviderContent: React.FC = () => {
  const { handleCreateCancel, submittable, setShowDeleteProviderModal, handleSubmitGeneralForm } =
    useProviderDrawer();
  const methods = useSetupProviderForm();
  const dispatch = useDispatch();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/providers" onClose={handleCreateCancel}>
          <SetupProviderHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SetupProviderDrawerFooter
          onDeleteClick={() => dispatch(setShowDeleteProviderModal(true))}
          onSaveClick={handleSubmitGeneralForm}
          saveDisabled={!submittable}
          onCancelClick={handleCreateCancel}
        />
      }
    >
      <FormProvider {...methods}>
        <Stack>
          <Divider />
          <SetupProviderDetails />
        </Stack>
      </FormProvider>
    </TabDrawerLayout>
  );
};
