// System
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

// Redux
import { useDispatch } from 'react-redux';
import { useVehicles } from '../useVehicles';
import { getVehicleCategoriesList } from '@Modal/reducers/vehicles/vehiclesSlice';

import {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
} from '@Modal/reducers/vehicles/vehicles/vehicleTableSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { FilterItemType } from '@Modal/reducers/types';
import { Paginable } from '@contracts/common/traits/Paginable';
import { GetVehiclesParamsType } from '@Modal/reducers/vehicles/types';

export const useVehicleCategoryTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    vehicles,
    categoryPagination,
    setCategoryPagination,
    loadingCategoriesContent,
    vehicleCategories,
  } = useVehicles();

  const handleCategoryTablePageChange = (filter: FilterItemType, pagination: Paginable) => {
    dispatch(setSelectedFilters(filter));
    dispatch(setCategoryPagination(pagination));
  };

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleGetVehicleCategoriesList = useCallback(
    (params: GetVehiclesParamsType, brandId: string) => {
      if (brandId) {
        return dispatch(
          getVehicleCategoriesList({
            page: categoryPagination.page,
            rowsPerPage: categoryPagination.rowsPerPage,
            brandId: brandId,
          })
        )
          .unwrap()
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      }
    },
    [categoryPagination.page, categoryPagination.rowsPerPage]
  );

  return {
    // State
    vehicles,
    vehicleCategories,
    categoryPagination,
    loadingCategoriesContent,
    // Actions
    setPage,
    setRowsPerPage,
    setSelectedFilters,
    handleCategoryTablePageChange,
    handleGetVehicleCategoriesList,
  };
};
