// System
import { useMemo } from 'react';
import { Stack } from '@mui/material';

// Components
import { RHFMultiSelect, RHFSelect, RHFTextField } from '@Components/hook-form';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';

// Styles
import Styles from './CreateRoleContent.module.scss';

// GraphQL
import { useGetPermissionsQuery } from '@Src/generated.graphql';

const useDropDown = () => {
  const isEditable = [
    {
      key: 'true',
      value: 'true',
    },
    {
      key: 'false',
      value: 'false',
    },
  ];
  const isEditableDropdown = useMemo(
    () =>
      isEditable?.map(({ key, value }) => (
        <MenuItemEx key={key} value={value} name="Is Editable">
          {key}
        </MenuItemEx>
      )),
    []
  );
  return {
    isEditable: isEditableDropdown,
  };
};

export const CreateAviatorRoleContent = () => {
  const { data } = useGetPermissionsQuery();
  const dropdown = useDropDown();

  return (
    <Stack className={Styles.CreateRoleContent}>
      <RHFTextField name="name" label="Name" />
      <RHFTextField name="description" label="Description" />
      <RHFMultiSelect
        name="permissions"
        label="permissions"
        variant="outlined"
        chip
        options={
          data?.permissions?.map((permission) => ({
            label: permission
              .replace(/_/g, ' ')
              .toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase()),
            value: permission,
          })) || []
        }
      ></RHFMultiSelect>
      <RHFSelect label="Is Editable" name="isEditable" variant="outlined">
        {dropdown.isEditable}
      </RHFSelect>
    </Stack>
  );
};
