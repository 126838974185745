import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string, number, mixed } from 'yup';
import { VehicleCategoryDto } from '@contracts/api/dto/vehicles/vehicles/VehicleCategoryDto';
import { VehicleType } from '@contracts/domain/keys';
import { VehicleBranchDto } from '@contracts/api/dto/vehicles/vehicles/VehicleBranchDto';

export type SetupVehicleFormState = {
  id: string;
  brandId: string;
  categoryId: string | null;
  name: string | null;
  licensePlate: string;
  qrCode: string;
  vin: string;
  serviceState: string | null;
  iotStatus: string | null;
  batteryLevel: string | null;
  powerStatus: string | null;
  chargingStatus: string | null;
  stateOfCharge: number | null;
  odometer: number | null;
  lastSeenOnline: string;
  lastMovement: string;
  iotImei: string | null;
  rentalStatus: string | null;
  category: VehicleCategoryDto | null;
};

export const setupVehicleFormStateDefault: SetupVehicleFormState = {
  id: '',
  brandId: '',
  categoryId: '',
  licensePlate: '',
  name: '',
  qrCode: '',
  vin: '',
  serviceState: '',
  iotStatus: '',
  batteryLevel: '',
  powerStatus: '',
  chargingStatus: '',
  stateOfCharge: 0,
  odometer: 0,
  lastSeenOnline: '',
  lastMovement: '',
  iotImei: '',
  rentalStatus: '',
  category: null,
};

export const setupVehicleSchemaDefault: ObjectSchema<Partial<SetupVehicleFormState>> =
  object<SetupVehicleFormState>().shape({
    id: string().required(),
    brandId: string().required(),
    name: string().required(),
    licensePlate: string().required(),
    qrCode: string().required(),
    vin: string().required(),
    categoryId: string(),
    serviceState: string().nullable(),
    iotStatus: string().nullable(),
    batteryLevel: string().nullable(),
    powerStatus: string().nullable(),
    chargingStatus: string().nullable(),
    stateOfCharge: number().nullable(),
    odometer: number().nullable(),
    lastSeenOnline: string(),
    lastMovement: string(),
    iotImei: string().nullable(),
    rentalStatus: string().nullable(),
    category: object<Partial<VehicleCategoryDto>>()
      .shape({
        name: string().nullable().defined(),
        vehicleType: mixed<VehicleType>()
          .oneOf(['SCOOTER', 'BIKE', 'MOPED'] as const)
          .nullable()
          .defined(),
        branch: object<VehicleBranchDto>().shape({
          timezone: string().nullable().defined(),
        }),
      })
      .nullable(),
  });

type SetupVehicleFormContextType = {
  schema: ObjectSchema<Partial<SetupVehicleFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupVehicleFormState>>>;
  methods?: UseFormReturn<SetupVehicleFormState>;
};

const createBranchFormContextDefault = {
  schema: setupVehicleSchemaDefault,
  setSchema: noop,
} satisfies SetupVehicleFormContextType;

export const SetupVehicleFormContext = createContext<SetupVehicleFormContextType>(
  createBranchFormContextDefault
);

export const useSetupVehicleForm = () => {
  const { methods } = useContext(SetupVehicleFormContext);
  if (!methods) {
    throw new Error('SetupVehicleFormContext is empty');
  }

  return methods;
};

export const useManageSetupVehicleSchema = () => {
  const { setSchema } = useContext(SetupVehicleFormContext);

  return { setSchema };
};
