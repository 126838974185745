import { Box } from '@mui/material';
import { MasterCardIcon } from './MastercardIcon';
import { VisaCardIcon } from './VisaCardIcon';
import { DefaultIcon } from './DefaultIcon';

type Props = {
  cardType: string;
  isDefault: boolean;
};

export const PaymentMethodCardType = ({ cardType, isDefault }: Props) => {
  const generatepaymentMethodCardType = () => {
    switch (cardType) {
      case 'mc':
        return (
          <Box
            sx={{
              height: '24px',
              width: '36px',
              position: 'relative',
            }}
          >
            <MasterCardIcon width={36} height={24} />
            {isDefault ? (
              <Box sx={{ position: 'absolute', top: '-8px', right: '-8px' }}>
                <DefaultIcon width={16} height={16} />
              </Box>
            ) : (
              ''
            )}
          </Box>
        );
      case 'visa':
        return (
          <Box
            sx={{
              height: '24px',
              width: '36px',
              position: 'relative',
            }}
          >
            <VisaCardIcon width={36} height={24} />

            {isDefault ? (
              <Box sx={{ position: 'absolute', top: '-8px', right: '-8px' }}>
                <DefaultIcon width={16} height={16} />
              </Box>
            ) : (
              ''
            )}
          </Box>
        );
      default:
        break;
    }
  };

  return <Box>{generatepaymentMethodCardType()}</Box>;
};
