// System
import { Button, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';

// React
import { useEffect } from 'react';
import { BaseSyntheticEvent, useRef } from 'react';

// Components
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Form
import { useRentalEndRideForm } from './RentalEndRideFormContext';

// Redux
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

// Styles
import Styles from './RentalEndRideModalDialog.module.scss';

export const RentalEndRideModalDialog = () => {
  const methods = useRentalEndRideForm();
  const { reset } = methods;
  const {
    showEndRideModalDialog,
    handleEndRentalModalDialog,
    handleSendEndRideCommand,
    selectedRental,
  } = useRentalsDrawer();
  const formDataRef = useRef({
    duration: 0,
  });

  useEffect(() => {
    if (!showEndRideModalDialog) {
      reset();
    }
  }, [showEndRideModalDialog]);

  const handleChange = (e: BaseSyntheticEvent) => {
    formDataRef.current.duration = parseFloat(e.target.value);
  };

  function convertMinutesToTime(duration: number) {
    const durationInMinutes = duration / 60000;
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.floor((durationInMinutes * 60) % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <ClosableModal
      name="endRide"
      title="End Ride"
      open={showEndRideModalDialog}
      onClose={() => handleEndRentalModalDialog(false)}
      className={Styles.RentalEndRideModalDialog}
    >
      <>
        <Stack direction="row" justifyContent="space-between" className={Styles.Text}>
          <Typography variant="subtitle2">Current duration</Typography>
          <Typography variant="subtitle2">
            {convertMinutesToTime(selectedRental?.duration)}
          </Typography>
        </Stack>
        <CreateDialogLayout>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSendEndRideCommand(formDataRef?.current?.duration);
            }}
          >
            <TextField
              name="actualDuration"
              label="Actual duration"
              helperText="Enter the actual rental duration and submit for accurate tracking."
              type="number"
              onChange={handleChange}
            />
            <CreateDialogFooterLayout>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => handleEndRentalModalDialog(false)}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                {' '}
                Confirm
              </Button>
            </CreateDialogFooterLayout>
          </form>
        </CreateDialogLayout>
      </>
    </ClosableModal>
  );
};
