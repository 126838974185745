// System
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';

// Components
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';

// Features
import { AviatorRoleDetails } from './AviatorRoleDetails';
import { SetupAviatorRoleDrawerHeader } from './SetupAviatorRoleDrawerHeader';
import { SetupAviatorRoleDrawerFooter } from './SetupAviatorRoleDrawerFooter';

// Redux
import { useDispatch } from 'react-redux';
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

export const SetupAviatorRoleDrawerContent: React.FC = () => {
  const dispatch = useDispatch();
  const { setShowAviatorRoleDrawer } = useAviatorRoleDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout
          currentSlug="/users"
          onClose={() => dispatch(setShowAviatorRoleDrawer(false))}
        >
          <SetupAviatorRoleDrawerHeader />
        </DrawerHeaderLayout>
      }
      footer={<SetupAviatorRoleDrawerFooter />}
    >
      <Stack>
        <Divider />
        <AviatorRoleDetails />
      </Stack>
    </TabDrawerLayout>
  );
};
