import { noop } from '@utils/noop';
import { UseFormReturn } from 'react-hook-form';
import { Dispatch, createContext, useContext } from 'react';
import { ObjectSchema, object, string } from 'yup';

export type SetupReplaceQRFormStateDTO = {
  qrCode: string | null;
};

export const replaceQRFormStateDefault: SetupReplaceQRFormStateDTO = {
  qrCode: null,
};

export const replaceQRSchemaDefault: ObjectSchema<Partial<SetupReplaceQRFormStateDTO>> = object<
  Partial<SetupReplaceQRFormStateDTO>
>().shape({
  qrCode: string().required('QR Code is required'),
});

type ReplaceQRFormContextType = {
  schema: ObjectSchema<Partial<SetupReplaceQRFormStateDTO>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupReplaceQRFormStateDTO>>>;
  methods?: UseFormReturn<SetupReplaceQRFormStateDTO>;
};

const replaceQRFormContextDefault = {
  schema: replaceQRSchemaDefault,
  setSchema: noop,
} satisfies ReplaceQRFormContextType;

export const ReplaceQRFormContext = createContext<ReplaceQRFormContextType>(
  replaceQRFormContextDefault
);

export const useSetupReplaceQRForm = () => {
  const { methods } = useContext(ReplaceQRFormContext);
  if (!methods) {
    throw new Error('ReplaceQRFormContext is empty');
  }

  return methods;
};

export const useManageReplaceQRSchema = () => {
  const { setSchema } = useContext(ReplaceQRFormContext);

  return { setSchema };
};
