import { FormProvider } from 'react-hook-form';

import { useSetupBranchForm } from '@Forms/branches/SetupBranchFormContext';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

import { GeneralInformation } from './GeneralInformation';
import { TimezoneAndCoordinates } from './TimezoneAndCoordinates';
import { BillingInfo } from './BillingInfo';
import { useBranches } from '@Modal/hooks/branches/useBranch';

export const PrimaryInformation = () => {
  const methods = useSetupBranchForm();
  const { selectedBranch } = useBranches();
  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <GeneralInformation />
          <TimezoneAndCoordinates />
          {selectedBranch?.level === 2 && <BillingInfo />}
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
