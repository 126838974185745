import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupIotModuleFormState,
  setupIotModuleSchemaDefault,
  SetupIotModuleFormContext,
  setupIotModuleFormStateDefault,
} from '@Forms/vehicles/SetupIotModuleFormContext';

export const SetupIotModuleFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<SetupIotModuleFormState>>>(
    setupIotModuleSchemaDefault
  );
  const methods = useForm({
    defaultValues: setupIotModuleFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupIotModuleFormContext.Provider value={value}>
      {children}
    </SetupIotModuleFormContext.Provider>
  );
};
