import { noop } from '@utils/noop';
import { UseFormReturn } from 'react-hook-form';
import { Dispatch, createContext, useContext } from 'react';
import { ObjectSchema, object, string, number } from 'yup';

export type SetupLinkIoTFormStateDTO = {
  iotImei: string | null;
};

export const linkIoTFormStateDefault: SetupLinkIoTFormStateDTO = {
  iotImei: null,
};

export const linkIoTSchemaDefault: ObjectSchema<Partial<SetupLinkIoTFormStateDTO>> = object<
  Partial<SetupLinkIoTFormStateDTO>
>().shape({
  iotImei: string().required('QR Code is required'),
});

type LinkIoTFormContextType = {
  schema: ObjectSchema<Partial<SetupLinkIoTFormStateDTO>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupLinkIoTFormStateDTO>>>;
  methods?: UseFormReturn<SetupLinkIoTFormStateDTO>;
};

const linkIoTFormContextDefault = {
  schema: linkIoTSchemaDefault,
  setSchema: noop,
} satisfies LinkIoTFormContextType;

export const LinkIoTFormContext = createContext<LinkIoTFormContextType>(linkIoTFormContextDefault);

export const useSetupLinkIoTForm = () => {
  const { methods } = useContext(LinkIoTFormContext);
  if (!methods) {
    throw new Error('LinkIoTFormContext is empty');
  }

  return methods;
};

export const useManageLinkIoTSchema = () => {
  const { setSchema } = useContext(LinkIoTFormContext);

  return { setSchema };
};
