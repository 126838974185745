// System
import { Box, Button, Stack, Typography } from '@mui/material';

// Hooks
import { useTheme } from '@mui/material/styles';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

// Types
import { CSVFileType } from '@Modal/reducers/vehicles/types';

type Props = {
  file: CSVFileType;
  error: string;
  onUpload?: () => void;
};

export const SingleFileUploadErrorPlaceholder: React.FC<Props> = ({ file, error, onUpload }) => {
  const { palette } = useTheme();
  const ellipsis = useEllipsis();
  const name = typeof file === 'string' ? file : file?.name;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      width="100%"
    >
      <Stack direction="column">
        <Typography color={palette.grey[600]} variant="body2" fontWeight="bold">
          <Box sx={{ ...ellipsis, maxWidth: '300px' }}>{name}</Box>
        </Typography>
        <Typography color={palette.error.dark} variant="caption">
          {error}
        </Typography>
      </Stack>
      <Button variant="contained" onClick={onUpload} size="small" sx={{ height: '42px' }}>
        Upload new file
      </Button>
    </Stack>
  );
};
