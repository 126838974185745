// System
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { defaultIotModuleListFilter } from '@utils/tables/vehicles/iotListFilter';
import { GetIotParams, VehicleCategoryType } from '../types';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

export const getVehicleIotModulesList = createAsyncThunk(
  'vehicles/getVehicleIotModulesList',
  async (params: GetIotParams, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get('/v1/iot', {
        params:
          params?.brandId !== 'undefined' && params?.brandId
            ? { ...params.selectedFilters, brandId: params.brandId }
            : { ...params.selectedFilters },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

// Type
const initialState = {
  vehiclesIOTModules: [],
  selectedFilters: defaultIotModuleListFilter,
  loadingIotContent: true,
  iotPagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const iotTableSlice = createSlice({
  name: 'iotTable',

  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.iotPagination.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.iotPagination.rowsPerPage = payload;
    },
    setIotPagination: (state, { payload }) => {
      state.iotPagination = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultIotModuleListFilter;
    },
    setVehicleIOTModulesList: (state, action: PayloadAction<VehicleCategoryType>) => {
      // @ts-ignore
      state.vehiclesIOTModules = [action.payload, ...state.vehiclesIOTModules];
    },
  },
  extraReducers: {
    [getVehicleIotModulesList.fulfilled?.type]: (state, { payload }) => {
      const { items, pageInfo } = payload;
      state.vehiclesIOTModules = items;
      state.loadingIotContent = false;

      state.iotPagination = {
        ...state.iotPagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
    [getVehicleIotModulesList.rejected?.type]: (state) => {
      state.vehiclesIOTModules = [];
      state.loadingIotContent = false;
    },
  },
});

export const { reducer: iotTableReducer } = iotTableSlice;
export const {
  setPage,
  setIotPagination,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
  setVehicleIOTModulesList,
} = iotTableSlice.actions;
