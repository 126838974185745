// System
import { Box } from '@mui/system';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useOperatorFilters } from '@Modal/hooks/operator/useOperatorFilters';

// Styles
const Style = {
  ContainerBox: {
    width: '52px',
    height: '52px',
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: '#ffff',
    boxShadow: '0px 17.333px 34.667px -4.333px rgba(145, 158, 171, 0.16)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:active': {
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;',
    },
  },
  FilterCTA: {
    color: '#FF5630',
    width: '10px',
    height: '10px',
    position: 'relative',
    bottom: '10px',
    left: '12px',
  },
};

type Props = {
  areFiltersActive: boolean;
};

const FilterCTA: React.FC<Props> = ({ areFiltersActive }) => {
  const { handleOperatorFilterCTAClick } = useOperatorFilters();
  return (
    <Box sx={Style.ContainerBox} onClick={handleOperatorFilterCTAClick} tabIndex={0}>
      <AssetIcon src="icons/maps/ic_filter_cta" size="xxxxl" sx={{ position: 'absolute' }} />
      {/* {areFiltersActive ? <AssetIcon src="icons/maps/ic_badge" sx={Style.FilterCTA} /> : null} */}
    </Box>
  );
};

export default FilterCTA;
