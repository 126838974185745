import { Drawer } from '@mui/material';

// Form
import { SetupAviatorRoleDrawerContent } from './SetupAviatorRoleDrawerContent';

// Redux
import { useDispatch } from 'react-redux';
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

export const SetupAviatorRoleDrawer: React.FC = () => {
  const { showAviatorRoleDrawer, setShowAviatorRoleDrawer } = useAviatorRoleDrawer();
  const dispatch = useDispatch();

  return (
    <Drawer
      anchor="right"
      open={showAviatorRoleDrawer}
      onClose={() => dispatch(setShowAviatorRoleDrawer(false))}
    >
      <SetupAviatorRoleDrawerContent />
    </Drawer>
  );
};
