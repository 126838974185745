import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { EnumFilterValue } from '@contracts/common/EnumFilterValue';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { SortParams } from '@contracts/common/params/SortParams';
import {
  BatteryLevel,
  IotStatus,
  PowerStatus,
  RentalStatus,
  VehicleServiceState,
  VehicleStatus,
} from '@contracts/domain/keys';
import { OptionalVehicleType } from '@contracts/domain/extendedKeys';

export const vehicleListColumns = [
  'vehicle.id',
  'vehicle.name',
  'vehicle.licensePlate',
  'vehicle.vehicleStatus',
  'vehicle.qrCode',
  'vehicle.serviceState',
  'vehicle.rentalStatus',
  'vehicle.iotStatus',
  // 'vehicle.batteryLevel',
  // 'vehicle.powerStatus',
  'vehicle.stateOfCharge',
  // 'vehicle.odometer',
  // 'vehicle.vin',
  'vehicle.iotImei',
  'vehicle.lastSeenOnline',
  'category.vehicleType',
  'category.name',
] as const;

export type VehicleListColumns = (typeof vehicleListColumns)[number];

export type VehicleListFilter = {
  'vehicle.id': number | undefined;
  'vehicle.name': string | undefined;
  'vehicle.licensePlate': string | undefined;
  'vehicle.vehicleStatus': EnumFilterValue<VehicleStatus>;
  'vehicle.qrCode': string | undefined;
  // 'vehicle.vin': string | undefined;
  'vehicle.serviceState': EnumFilterValue<VehicleServiceState>;
  'vehicle.rentalStatus': EnumFilterValue<RentalStatus>;
  'vehicle.iotStatus': EnumFilterValue<IotStatus>;
  // 'vehicle.batteryLevel': EnumFilterValue<BatteryLevel>;
  'vehicle.iotImei': string | undefined;
  // 'vehicle.powerStatus': EnumFilterValue<PowerStatus>;
  'category.vehicleType': EnumFilterValue<OptionalVehicleType>;
  'vehicle.categoryId': (number | undefined)[];
  limit: number;
};

export const defaultVehicleListFilter: VehicleListFilter = {
  'vehicle.id': undefined,
  'vehicle.name': undefined,
  'vehicle.licensePlate': undefined,
  'vehicle.vehicleStatus': {},
  'vehicle.qrCode': undefined,
  // 'vehicle.vin': undefined,
  'vehicle.serviceState': {},
  'vehicle.rentalStatus': {},
  'vehicle.iotStatus': {},
  // 'vehicle.batteryLevel': {},
  'vehicle.iotImei': undefined,
  // 'vehicle.powerStatus': {},
  'category.vehicleType': {},
  'vehicle.categoryId': [],
  limit: 25,
};

export type VehicleListSortColumns =
  | 'vehicle.id'
  | 'vehicle.stateOfCharge'
  // | 'vehicle.odometer'
  | 'vehicle.lastSeenOnline';
export type VehicleListSort = SortParams<VehicleListSortColumns> | undefined;
export const defaultVehicleListSort: VehicleListSort = undefined;

export const vehicleListColumnVisibilitySettings = {
  'vehicle.id': ColumnVisibilityType.Visible,
  'vehicle.name': ColumnVisibilityType.CanBeHidden,
  'vehicle.licensePlate': ColumnVisibilityType.CanBeHidden,
  'vehicle.vehicleStatus': ColumnVisibilityType.CanBeHidden,
  'vehicle.qrCode': ColumnVisibilityType.CanBeHidden,
  'vehicle.serviceState': ColumnVisibilityType.CanBeHidden,
  'vehicle.rentalStatus': ColumnVisibilityType.CanBeHidden,
  'vehicle.iotStatus': ColumnVisibilityType.CanBeHidden,
  // 'vehicle.batteryLevel': ColumnVisibilityType.CanBeHidden,
  // 'vehicle.powerStatus': ColumnVisibilityType.CanBeHidden,
  'vehicle.iotImei': ColumnVisibilityType.CanBeHidden,
  // 'vehicle.odometer': ColumnVisibilityType.CanBeHidden,
  'vehicle.stateOfCharge': ColumnVisibilityType.CanBeVisible,
  // 'vehicle.vin': ColumnVisibilityType.CanBeVisible,
  'vehicle.lastSeenOnline': ColumnVisibilityType.CanBeVisible,
  'category.name': ColumnVisibilityType.CanBeVisible,
  'category.vehicleType': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<VehicleListColumns>;

export const defaultVehicleListColumnVisibility = defaultColumnVisibilityParams(
  vehicleListColumnVisibilitySettings
);
export const vehicleListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  vehicleListColumns,
  vehicleListColumnVisibilitySettings
);
