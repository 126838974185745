import { TableBody, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  sx?: SystemStyleObject<Theme>;
  children: JSX.Element | JSX.Element[];
};

export const TableBodyEx: React.FC<Props> = ({ sx, children }) => {
  return <TableBody sx={[{ maxHeight: '60vh' }, sx ?? {}]}>{children}</TableBody>;
};
