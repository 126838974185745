// System
import { SystemStyleObject } from '@mui/system';
import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';

// Components
import Image from '@Components/image/Image';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// Features
import { IotStatusLabel } from '@Features/vehicles/shared/IotStatus';
import { BatteryLevelIconLabel } from '@Features/vehicles/shared/BatteryLevel';
import { VehicleServiceStateLabel } from '@Features/vehicles/shared/VehicleServiceState';

// Keys
import { BatteryLevel, IotStatus, VehicleServiceState } from '@contracts/domain/keys';

// Redux
import { VehicleItemType } from '@Modal/reducers/vehicles/types';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';
import { useRentalStatusTitle } from '@Features/vehicles/shared/RentalStatus';
import { useRedis } from '@Modal/hooks/redis/useRedis';

// Styles
import Styles from './VehicleCard.module.scss';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      height: '120px',
      padding: '16px',
      backgroundColor: palette.grey['100'],
      ...roundedBorder,
    },
    imageBox: {
      height: '90px',
      width: '90px',
      ...roundedBorder,
      backgroundColor: 'white',
    },
    align: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

type Props = {
  vehicleData?: VehicleItemType;
};

export const VehicleCard: React.FC<Props> = () => {
  const styles = useSx();

  // Redux
  const { selectedVehicleData, selectedVehicleServiceState } = useVehiclesDrawer();
  const { rentalStatus, category, qrCode } = selectedVehicleData;

  const { selectedRedisVehicle } = useRedis();

  return (
    <Stack className={Styles.VehicleCard}>
      <Stack className={Styles.ImageBox} alignItems="center" justifyContent="center">
        <Image
          alt="empty_content"
          src={
            category?.vehicleType === 'BIKE'
              ? '/assets/illustrations/Bike_category.png'
              : category?.vehicleType === 'SCOOTER'
              ? '/assets/illustrations/scooter_category.png'
              : '/assets/illustrations/moped_category.jpg'
          }
          sx={{
            ...(category?.vehicleType === 'BIKE' && { width: '70px', height: '45px' }),
          }}
        />
      </Stack>
      <Stack direction="column" sx={{ width: '80%' }} spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <TruncateText
            text={qrCode}
            maxLength={8}
            textStyles={{ fontWeight: 'bold', fontSize: '18px', lineHeight: '28px' }}
          />
          <Stack flexDirection="row" alignItems="center">
            <Typography variant="body2" fontWeight={'500'} sx={styles.align}>
              {/* @ts-ignore */}
              {useRentalStatusTitle()[rentalStatus ?? 'UNKNOWN']}
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
            <BatteryLevelIconLabel
              level={selectedRedisVehicle?.deviceInfo?.batteryLevel as BatteryLevel}
              batteryPercentage={selectedRedisVehicle?.deviceInfo?.batteryStage ?? undefined}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" sx={styles.align}>
            IoT: <IotStatusLabel status={selectedVehicleData?.iotStatus as IotStatus} />
          </Typography>
          <Stack direction="row">
            <AtLocator type="special-field" name="serviceStatus" box>
              <Box sx={{ marginBottom: -0.25, marginRight: -1 }}>
                <VehicleServiceStateLabel
                  status={selectedVehicleServiceState as VehicleServiceState}
                  variant="ghost"
                />
              </Box>
            </AtLocator>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
