// System
import React from 'react';
import { Box } from '@mui/system';
import { Checkbox, Divider, Typography } from '@mui/material';

// Styles
import Styles from './OperatorFilterLabelCard.module.scss';

// Types
type LabelDataType = {
  title: string;
  value: string;
};

type Props = {
  helperTxt: string;
  labelData: LabelDataType;
  children: React.ReactNode;
  checkBockHandler: (arg: string | {}) => void;
  isChecked?: boolean;
};

export const OperatorFilterLabelCard: React.FC<Props> = ({
  helperTxt,
  labelData,
  children,
  checkBockHandler,
  isChecked,
}) => {
  const { title, value } = labelData;

  return (
    <Box className={Styles.OperatorFilterLabelCard}>
      <Box className={Styles.MainContainer}>
        <Box className={Styles.BrandTxtContainer}>
          <Typography fontSize={14} fontWeight={600}>
            {title}
          </Typography>
          <Typography color="#637381" fontSize={14} fontWeight={400}>
            {helperTxt}
          </Typography>
        </Box>
        <Checkbox
          className={Styles.Checkbox}
          checked={isChecked}
          onClick={() => checkBockHandler(value)}
        />
      </Box>
      <Divider />
      <Box className={Styles.FilterDataContainer}>{children}</Box>
    </Box>
  );
};
