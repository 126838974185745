// System
import React, { useEffect } from 'react';
import { Box } from '@mui/system';

// Hooks
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useOperatorFilters } from '@Modal/hooks/operator/useOperatorFilters';

// Components
import { OperatorFilterTag } from '@Components/operator/OperatorFilterTag';
import { OperatorFilterLabelCard } from '@Components/operator/OperatorFilterLabelCard/OperatorFilterLabelCard';

// Features
import { ToggleableContentMobile } from '@Features/maps/Filters/ToggleableContentOperator/ToggleableContentOperator';

// Types
import { BranchesType } from '@Modal/reducers/branches/types';
import { LiveMapBranchFilterType, LiveMapBrandFilterType } from '@Modal/hooks/vehicles/types';

type Props = {
  branches: LiveMapBranchFilterType[];
  handleFilterVehiclesByBrand: (arg: LiveMapBrandFilterType) => void;
  handleFilterVehiclesByBranch: (arg: BranchesType) => void;
};

export const OperatorBranches: React.FC<Props> = ({
  branches,
  handleFilterVehiclesByBrand,
  handleFilterVehiclesByBranch,
}) => {
  const { handleFetchBrands } = useBranches();
  const { handleGetAllBranchesForOperator } = useOperatorFilters();

  const handleFetchBrandsAndBranches = async () => {
    await handleFetchBrands();
    handleGetAllBranchesForOperator();
  };

  useEffect(() => {
    handleFetchBrandsAndBranches();
  }, []);

  return (
    <Box>
      <ToggleableContentMobile name="Branches" key="OperatorBranches">
        {branches.map((brand) => {
          return (
            <OperatorFilterLabelCard
              isChecked={brand.isChecked}
              key={brand?.id}
              labelData={{
                value: brand.id,
                title: brand.title,
              }}
              helperTxt={`All scooters located in ${brand.title}`}
              checkBockHandler={() => handleFilterVehiclesByBrand(brand)}
            >
              {brand.childBranches.map((branch) => {
                return (
                  <OperatorFilterTag
                    key={branch?.id}
                    isChecked={branch?.isChecked}
                    onClick={() => handleFilterVehiclesByBranch(branch)}
                    name={branch.name}
                  />
                );
              })}
            </OperatorFilterLabelCard>
          );
        })}
      </ToggleableContentMobile>
    </Box>
  );
};
