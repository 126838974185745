import { IconButton, useTheme, Theme, Stack, Dialog, Box } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { AssetIcon } from '@Components/shared/images/AssetIcon';

type Props = {
  sx?: SystemStyleObject<Theme>;
  header: JSX.Element;
  footer: JSX.Element;
  name: string;
  open: boolean;
  onClose?: () => void;
  children: JSX.Element;
};

export const FullScreenClosableModal = ({
  name,
  open,
  header,
  footer,
  onClose,
  children,
  sx,
}: Props) => {
  const { palette } = useTheme();
  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-at-modal={name}
      sx={{ marginBottom: '0' }}
    >
      <Stack
        data-at-container="Header"
        spacing={0}
        sx={[
          { bgcolor: 'background.paper', borderRadius: 2, width: '100%', height: '100%' },
          sx ?? {},
        ]}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          padding={2}
        >
          {header}
          <IconButton
            aria-label="close"
            onClick={onClose}
            color="inherit"
            data-at-button="Close"
            sx={{
              background: 'transparent',
              visibility: onClose ? 'visible' : 'collapse',
              color: palette.grey[500],
              marginTop: 3.5,
            }}
          >
            <AssetIcon src="icons/maps/ic_close" size="m" />
          </IconButton>
        </Stack>
        <Box height="100%">{children}</Box>
        <Box data-at-container="Footer" padding={2}>
          {footer}
        </Box>
      </Stack>
    </Dialog>
  );
};
