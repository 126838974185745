import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import { UpdateRequest } from '@contracts/api/dto/common/UpdateRequest';
import { UpdateIotModuleDto } from '@contracts/api/dto/vehicles/iot/UpdateIotModuleDto';

// Types

// GET
export const getSelectedIot = createAsyncThunk(
  'vehicle/getSelectedIot',
  async (iotId: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/iot/${iotId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT
export const updateSelectedIot = createAsyncThunk(
  'vehicle/updateSelectedIot',
  async (vehicleData: UpdateRequest<UpdateIotModuleDto>, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/iot/${vehicleData.id}`, {
        ...vehicleData.body,
      })
      .then(() => {
        return thunkApi.fulfillWithValue(vehicleData.body);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  selectedIot: {},
  showCancelIotDrawer: false,
  showSelectedIotDrawer: false,
};

const iotDrawerSlice = createSlice({
  name: 'vehicleIotDrawer',
  initialState,
  reducers: {
    setShowSelectedIotDrawer: (state, { payload }) => {
      state.showSelectedIotDrawer = payload;
    },
    setShowCancelIotDrawer: (state, { payload }) => {
      state.showCancelIotDrawer = payload;
    },
  },
  extraReducers: {
    [getSelectedIot.fulfilled?.type]: (state, { payload }) => {
      state.selectedIot = payload;
      state.showSelectedIotDrawer = true;
    },
    [updateSelectedIot.fulfilled?.type]: (state, { payload }) => {
      state.selectedIot = payload;
      state.showSelectedIotDrawer = false;
    },
  },
});

export const { reducer: iotDrawerReducer } = iotDrawerSlice;
export const { setShowSelectedIotDrawer, setShowCancelIotDrawer } = iotDrawerSlice.actions;
