type LngLat = {
  lat: number;
  lng: number;
};

export const getAddressFromLatLng = async (latLng: LngLat): Promise<string> => {
  const geoCoder = new google.maps.Geocoder();

  const response = await geoCoder.geocode({ location: latLng });

  if (response && response.results && response.results[0]) {
    return response.results[0].formatted_address;
  } else {
    return 'N/A';
  }
};
