import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';

export const roleListColumns = [
  'role.id',
  'role.name',
  'role.description',
  // 'role.permissions',
  'role.isEditable',
] as const;

export type RoleListColumns = (typeof roleListColumns)[number];

export type RoleListFilter = {
  'role.id': string | undefined;
  'role.name': string | undefined;
  'role.description': string | undefined;
  // 'role.permissions': string | undefined;
  'role.isEditable': string | undefined;
};

export const defaultRoleListFilter: RoleListFilter = {
  'role.id': undefined,
  'role.name': undefined,
  'role.description': undefined,
  // 'role.permissions': undefined,
  'role.isEditable': undefined,
};

export const roleListColumnVisibilitySettings = {
  'role.id': ColumnVisibilityType.Visible,
  'role.name': ColumnVisibilityType.CanBeHidden,
  'role.description': ColumnVisibilityType.CanBeHidden,
  // 'role.permissions': ColumnVisibilityType.CanBeHidden,
  'role.isEditable': ColumnVisibilityType.CanBeHidden,
} satisfies ColumnVisibilitySettings<RoleListColumns>;

export const defaultRoleListColumnVisibility = defaultColumnVisibilityParams(
  roleListColumnVisibilitySettings
);

export const roleListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  roleListColumns,
  roleListColumnVisibilitySettings
);
