// System
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Button, Divider, TextField, Typography } from '@mui/material';

// Components
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Types
import { AppDispatch } from 'src/app/store';
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Styles
import Styles from './SetupOperatorManualQRDrawer.module.scss';

export const SetupOperatorManualQRDrawer: React.FC = () => {
  const { showOperatorManualQRModal, handleGetScannedVehicle, handleOpenQRScanModal } =
    useOperatorScan();

  const dispatch = useDispatch<AppDispatch>();

  const [qrValue, setQRValue] = useState('');

  const handleCloseModal = () => {
    dispatch(handleOpenQRScanModal(false));
    setQRValue('');
  };

  return (
    <SetupDrawer
      name="SetupOperatorManualQRDrawer"
      open={showOperatorManualQRModal}
      onClose={handleCloseModal}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorManualQRDrawer}>
        <Box className={Styles.ScannerHeader}>
          <Typography fontWeight={900} fontSize={'20px'}>
            Enter code manually
          </Typography>
          <Box role="button" onClick={handleCloseModal}>
            <AssetIcon src="x-close" className={Styles.AssetIcon} />
          </Box>
        </Box>
        <Divider />
        <Box className={Styles.SubContainer}>
          <Box className={Styles.GuideContainer}>
            <Box className={Styles.MiniContainer}>
              <AssetIcon src="icons/operator/ic_qr_scan" className={Styles.AssetIcon} />
              <Box className={Styles.BadgeContainer}>
                <Typography color={'#FFF'} fontWeight={600} fontSize={12}>
                  QR CODE
                </Typography>
              </Box>
            </Box>
            <Typography textAlign={'center'}>
              Enter 5 digit code which is placed below the QR code on vehicle
            </Typography>
          </Box>
          <Box className={Styles.FormContainer}>
            <TextField
              onChange={(e) => setQRValue(e.currentTarget.value)}
              inputProps={{ maxLength: 5 }}
              type="text"
              className={Styles.InputContainer}
              label="Enter QR"
              value={qrValue}
            />
            <Button
              disabled={qrValue.length < 5}
              className={qrValue.length < 5 ? Styles.SubmitCTA : Styles.SubmitCTA_Active}
              variant={'contained'}
              onClick={() => {
                handleGetScannedVehicle(qrValue);
                setQRValue('');
              }}
            >
              Confrim
            </Button>
          </Box>
        </Box>
      </Box>
    </SetupDrawer>
  );
};
