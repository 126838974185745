// System
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useLinkVehicleIot } from '@Modal/hooks/vehicles/vehicles/useLinkVehicleIot';

export const CreateVehicleIotUnlinkConfirmDialog = () => {
  const { handleUnlinkVehicleIot, iotDeleteFormShown, handleShowIotDeleteDialog } =
    useLinkVehicleIot();

  return (
    <>
      {iotDeleteFormShown && (
        <ClosableModal
          name="BranchOperationStatus"
          title="Unlink IoT from current vehicle?"
          open={iotDeleteFormShown}
          onClose={() => handleShowIotDeleteDialog(false)}
          sx={{
            width: '592px',
          }}
        >
          <CreateDialogLayout>
            <Typography
              variant="body1"
              sx={{
                marginY: 2,
              }}
            >
              Are you sure you want to unlink IoT from current vehicle?
            </Typography>
            <CreateDialogFooterLayout>
              <AtLocator type="button" name="Cancel">
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => handleShowIotDeleteDialog(false)}
                >
                  Cancel
                </Button>
              </AtLocator>
              <AtLocator type="button" name="Submit">
                <Button
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={handleUnlinkVehicleIot}
                >
                  Unlink
                </Button>
              </AtLocator>
            </CreateDialogFooterLayout>
          </CreateDialogLayout>
        </ClosableModal>
      )}
    </>
  );
};
