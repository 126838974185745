import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateAviatorUserFormContext,
  CreateAviatorUserFormState,
  createAviatorUserFormStateDefault,
  createAviatorUserSchemaDefault,
} from '@Forms/roles/CreateAviatorUserFormContext';

export const CreateAviatorUserFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateAviatorUserFormState>>>(
    createAviatorUserSchemaDefault
  );
  const methods = useForm({
    defaultValues: createAviatorUserFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateAviatorUserFormContext.Provider value={value}>
      {children}
    </CreateAviatorUserFormContext.Provider>
  );
};
