import { RHFTextField } from '@Components/hook-form';
import { Button, Stack, Typography } from '@mui/material';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useLinkVehicleIot } from '@Modal/hooks/vehicles/vehicles/useLinkVehicleIot';

export const IotAssignForm: React.FC = () => {
  const roundedBorder = useRoundedBorder();

  const { handleSubmitLinkIOT, submittable, handleCreateCancel } = useLinkVehicleIot();

  return (
    <Stack
      direction="column"
      gap={2.5}
      sx={{
        padding: 3,
        paddingTop: 2.5,
        ...roundedBorder,
      }}
    >
      <AtLocator type="readonly-text" name="linkIotText">
        <Typography variant="h6">Link IoT</Typography>
      </AtLocator>
      <AtLocator type="special-field" name="imei">
        <RHFTextField label="IMEI Code" variant="outlined" name="imei" />
      </AtLocator>
      <Stack direction="row" justifyContent="flex-end" gap={1.5}>
        <AtLocator type="button" name="cancel">
          <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
            Cancel
          </Button>
        </AtLocator>
        <AtLocator type="button" name="link">
          <Button
            variant="contained"
            onClick={handleSubmitLinkIOT}
            type="submit"
            disabled={!submittable}
          >
            Link
          </Button>
        </AtLocator>
      </Stack>
    </Stack>
  );
};
