// System
import { SystemStyleObject } from '@mui/system';
import { Stack, Box, Theme, Divider } from '@mui/material';

// Styles
import Styles from './TabsDrawerLayout.module.scss';

// Types
type Props = {
  sx?: SystemStyleObject<Theme>;
  header: JSX.Element;
  children: JSX.Element | JSX.Element[];
};

export const TabsDrawerLayout: React.FC<Props> = ({ header, children, sx }) => {
  return (
    <Stack className={Styles.TabsDrawerLayout}>
      <Box className={Styles.SubHeaderContainer}>
        <Divider />
        <Box className={Styles.SubHeader}>{header}</Box>
        <Divider />
      </Box>
      <Box className={Styles.Content}>{children}</Box>
    </Stack>
  );
};
