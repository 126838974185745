import { ReactNode } from 'react';
import { Button, ButtonProps } from '@mui/material';

type Props = {
  children: ReactNode;
  onClick?: () => void;
  color?: ButtonProps['color'];
};

export const CancelDialogConfirmAction: React.FC<Props> = ({ children, onClick, color }) => {
  return (
    <Button variant="contained" onClick={onClick} color={color}>
      {children}
    </Button>
  );
};
