import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupAddUserFormContext,
  SetupAddUserFormState,
  setupAddUserFormStateDefault,
  setupAddUserSchemaDefault,
} from '@Forms/roles/SetupAddUserFormContext';

export const SetupAddUserFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<SetupAddUserFormState>>>(setupAddUserSchemaDefault);
  const methods = useForm({
    defaultValues: setupAddUserFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupAddUserFormContext.Provider value={value}>{children}</SetupAddUserFormContext.Provider>
  );
};
