import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import { CreateVehicleIotModuleDataType } from '../types';

// POST
export const createVehicleIOTModule = createAsyncThunk(
  'vehicle/createVehicleIOTModule',
  async (iotData: CreateVehicleIotModuleDataType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post('/v1/iot', {
        ...iotData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  showCreateVehicleIOTModuleForm: false,
  showCancelVehicleIOTModuleForm: false,
};

const createVehicleIOTModuleSlice = createSlice({
  name: 'createIOTModule',
  initialState,
  reducers: {
    setShowCreateVehicleIOTModuleForm: (state, { payload }) => {
      state.showCreateVehicleIOTModuleForm = payload;
    },
    setShowCancelVehicleIOTModuleForm: (state, { payload }) => {
      state.showCancelVehicleIOTModuleForm = payload;
    },
  },
  extraReducers: {
    [createVehicleIOTModule.fulfilled?.type]: (state) => {
      state.showCreateVehicleIOTModuleForm = false;
    },
  },
});

export const { reducer: createVehicleIOTModuleReducer } = createVehicleIOTModuleSlice;
export const { setShowCreateVehicleIOTModuleForm, setShowCancelVehicleIOTModuleForm } =
  createVehicleIOTModuleSlice.actions;
