// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowAvTransactionDrawer,
  setSelectedAvTransaction,
} from '@Modal/reducers/transaction/drawer/avTransactionDrawerSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { TransactionItemDto } from '@contracts/api/dto/transaction/TransactionDto';

export const useAvTransactionDrawer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { showAvTransactionDrawer, selectedAvTransactionData } = useSelector(
    (state: StoreInterface) => state?.avTransactionDrawer
  );

  const handleAvTransactionRowClick = async (transaction: TransactionItemDto) => {
    await dispatch(setSelectedAvTransaction(transaction));
    dispatch(setShowAvTransactionDrawer(true));
  };

  return {
    // Store
    showAvTransactionDrawer,
    selectedAvTransactionData,
    // Actions
    setSelectedAvTransaction,
    setShowAvTransactionDrawer,
    handleAvTransactionRowClick,
  };
};
