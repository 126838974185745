import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';
import { CreateCustomerNoteType, CustomerNoteType } from '../types';

// GET
export const getCustomerNotes = createAsyncThunk(
  'customer/getCustomerNotes',
  async (id: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/customers/${id}/notes`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST
export const createCustomerNote = createAsyncThunk(
  'customer/createCustomerNote',
  async (customerData: CreateCustomerNoteType, thunkApi) => {
    return axiosCustomerManagementInstance
      .post(`/customers/${customerData.id}/notes`, {
        ...customerData.newNote,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// DELETE
export const deleteCustomerNote = createAsyncThunk(
  'customer/deleteCustomerNote',
  async (noteId: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .delete(`/customers/notes/${noteId}`)
      .then(() => {
        return thunkApi.fulfillWithValue(noteId);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  customerNotes: [],
  selectedNoteId: 0,
  showCreateCustomerNote: false,
  showDeleteCustomerNote: false,
  showCustomerNoteCancel: false,
};

const customerNotesSlice = createSlice({
  name: 'createCategory',
  initialState,
  reducers: {
    setCustomerNotes: (state, { payload }) => {
      state.customerNotes = payload;
    },
    setShowCreateCustomerNote: (state, { payload }) => {
      state.showCreateCustomerNote = payload;
    },
    setShowCancelCustomerNote: (state, { payload }) => {
      state.showCustomerNoteCancel = payload;
    },
    setShowDeleteCustomerNote: (state, { payload }) => {
      state.showDeleteCustomerNote = payload;
    },
    setSelectedNoteId: (state, { payload }) => {
      state.selectedNoteId = payload;
    },
  },
  extraReducers: {
    [getCustomerNotes.fulfilled?.type]: (state, { payload }) => {
      state.customerNotes = payload;
    },
    [createCustomerNote.fulfilled?.type]: (state, { payload }) => {
      // @ts-ignore
      state.customerNotes = [payload, ...state.customerNotes];
      state.showCreateCustomerNote = false;
    },
    [deleteCustomerNote.fulfilled?.type]: (state, { payload }) => {
      const updatedCustomerNotes = state.customerNotes.filter(
        (note: CustomerNoteType) => note.id !== payload
      );
      // @ts-ignore
      state.customerNotes = updatedCustomerNotes;
      state.showDeleteCustomerNote = false;
    },
  },
});

export const { reducer: customerNotesReducer } = customerNotesSlice;
export const {
  setCustomerNotes,
  setSelectedNoteId,
  setShowCreateCustomerNote,
  setShowDeleteCustomerNote,
  setShowCancelCustomerNote,
} = customerNotesSlice.actions;
