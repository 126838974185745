import { useCallback, useRef, useState } from 'react';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { Box, Avatar, Button, Popover, Stack } from '@mui/material';
import Cookies from 'js-cookie';

import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';
import { RolesIndex } from '@Pages/roles.enum';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

const AccountDropDown = () => {
  const { handleAccountLogout } = useAuthorization();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  return (
    <>
      {currentUser && (
        <Box>
          <Box ref={anchorRef}>
            <Button
              variant="contained"
              color="inherit"
              startIcon={<Avatar sx={{ height: 20, width: 20 }} />}
              endIcon={<AssetIcon src="chevron-down" size="s" />}
              onClick={handleClick}
            >
              {currentUser.firstName ? currentUser.firstName : null}
            </Button>
          </Box>
          <Popover
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ marginTop: 1 }}
          >
            <Box
              sx={{
                width: '329px',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(99, 115, 129)' }}>
                  User ID
                </Stack>
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(0, 0, 0)' }}>
                  {currentUser.id ? <TruncateText text={currentUser.id} maxLength={8} /> : null}
                </Stack>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(99, 115, 129)' }}>
                  Full Name
                </Stack>
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(0, 0, 0)' }}>
                  {currentUser.firstName && currentUser.lastName
                    ? `${currentUser.firstName} ${currentUser.lastName}`
                    : null}
                </Stack>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(99, 115, 129)' }}>
                  Email
                </Stack>
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(0, 0, 0)' }}>
                  {currentUser.email ? currentUser.email : null}
                </Stack>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(99, 115, 129)' }}>
                  Role
                </Stack>
                <Stack sx={{ fontSize: '14px', lineHeight: '24px', color: 'rgb(0, 0, 0)' }}>
                  {currentUser.role ? RolesIndex[currentUser.role] : null}
                </Stack>
              </Stack>
              <Button
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '8px',
                  backgroundColor: 'rgb(223, 227, 232)',
                }}
                onClick={() => handleAccountLogout()}
              >
                <Box color="rgb(33, 43, 54)">Log out</Box>
              </Button>
            </Box>
          </Popover>
        </Box>
      )}
    </>
  );
};

export default AccountDropDown;
