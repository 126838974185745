import { useState } from 'react';

//  System
import { Stack } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Button, Theme, InputAdornment } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Components
import { RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import FormProvider from '@Components/hook-form/FormProvider';
import { useBalanceModalDialogForm } from './BalanceModalDialogFormContext';

// Redux
import { useCustomerBalance } from '@Modal/hooks/customers/balance/useCustomerBalance';
import { useDispatch } from 'react-redux';

const useSx = () => {
  const roundedBorder = useRoundedBorder();

  return {
    buttonsContainer: {
      height: '52px',
      border: '1px solid',
      borderColor: 'rgba(145, 158, 171, 0.24)',
      alignItems: 'center',
      padding: '4px',
      ...roundedBorder,
    },
    creditButton: {
      width: '50%',
      height: '44px',
      color: 'rgb(99, 115, 129)',
    },
    expDate: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      color: 'rgb(99, 115, 129)',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const BalanceModalDialog = () => {
  const methods = useBalanceModalDialogForm();
  const [creditAction, setCreditAction] = useState('CREDIT');
  const styles = useSx();

  const { handleCancelManageCredits, setWalletCreditType } = useCustomerBalance();

  const dispatch = useDispatch();

  const { showBalanceModalDialog, handleConfirmCreateCredit } = useCustomerBalance();

  const handleCreditActionChange = (newAction: string) => {
    dispatch(setWalletCreditType(newAction));
    setCreditAction(newAction);
    methods.reset();
  };

  return (
    <ClosableModal
      name="BalanceModalDialog"
      title="Manage credits"
      open={showBalanceModalDialog}
      onClose={handleCancelManageCredits}
      sx={{ width: '592px' }}
    >
      <FormProvider methods={methods}>
        <CreateDialogLayout>
          <Stack direction="column" spacing={4}>
            <Stack direction="row" sx={styles.buttonsContainer}>
              <Button
                variant="text"
                sx={{
                  ...styles.creditButton,
                  color: creditAction === 'CREDIT' ? 'rgb(33, 43, 54)' : 'rgb(99, 115, 129)',
                  backgroundColor: creditAction === 'CREDIT' ? 'rgb(223, 227, 232)' : 'transparent',
                }}
                onClick={() => handleCreditActionChange('CREDIT')}
              >
                Add credit
              </Button>
              <Button
                variant="text"
                sx={{
                  ...styles.creditButton,
                  color: creditAction === 'CORRECTION' ? 'rgb(33, 43, 54)' : 'rgb(99, 115, 129)',
                  backgroundColor:
                    creditAction === 'CORRECTION' ? 'rgb(223, 227, 232)' : 'transparent',
                }}
                onClick={() => handleCreditActionChange('CORRECTION')}
              >
                Remove credit
              </Button>
            </Stack>
            <RHFTextField
              onFocus={(e) =>
                e.target.addEventListener(
                  'wheel',
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              label="Enter funds"
              helperText="Caption text, description, notification"
              type="number"
              defaultValue="0.00"
              sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: '400', color: 'red' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AssetIcon
                      src="currency_gel"
                      sx={{ width: '18px', height: '18px', color: 'rgb(99, 115,  129)' }}
                    />
                  </InputAdornment>
                ),
              }}
              onWheel={(Event) => Event.preventDefault()}
              name="funds"
              placeholder="0.0"
            />

            <RHFTextField
              name="description"
              multiline
              minRows={4}
              placeholder="Credit description"
            />
          </Stack>
          <CreateDialogFooterLayout>
            <AtLocator type="button" name="Cancel">
              <Button variant="outlined" color="inherit" onClick={handleCancelManageCredits}>
                Cancel
              </Button>
            </AtLocator>
            <AtLocator type="button" name="Submit">
              <Button type="submit" variant="contained" onClick={handleConfirmCreateCredit}>
                Confirm
              </Button>
            </AtLocator>
          </CreateDialogFooterLayout>
        </CreateDialogLayout>
      </FormProvider>
    </ClosableModal>
  );
};
