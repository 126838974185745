// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { BaseSyntheticEvent, useCallback, useMemo } from 'react';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  getCustomerWallets,
  setShowBalanceModalDialog,
  setShowCancelBalanceModalDialog,
  setSelectedWalletId,
  createWalletCredit,
  setWalletCreditType,
  customerSettleBalance,
  setSelectedWallet,
} from '@Modal/reducers/customers/balance/customerBalanceSlice';
import { getSelectedCustomerBalanceTransactions } from '@Modal/reducers/customers/balance/customerBalanceSlice';
import { useCustomersDrawer } from '../useCustomerDrawer';

// Forms
import { useBalanceModalDialogForm } from '@Forms/customers/balance/BalanceModalDialogFormContext';

export const useCustomerBalance = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const {
    showBalanceModalDialog,
    showCancelBalanceModalDialog,
    customerWallets,
    selectedWalletId,
    walletCreditType,
    selectedWallet,
    selectedCustomerBalanceTransactions,
  } = useSelector((state: StoreInterface) => state?.customerBalance);
  const { control, handleSubmit, reset } = useBalanceModalDialogForm();
  const { isDirty } = useFormState({ control });

  const { selectedCustomerData } = useCustomersDrawer();
  const { enqueueSnackbar } = useSnackbar();

  const fetchSelectedCustomerBalanceTransactions = useCallback(() => {
    dispatch(getSelectedCustomerBalanceTransactions(selectedCustomerData.id))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedWallet]);

  const handleCustomerSettleBalance = useMemo(
    () => async (e?: BaseSyntheticEvent) => {
      if (
        selectedCustomerData &&
        selectedCustomerData?.id &&
        selectedWallet &&
        selectedWallet?.branchId
      ) {
        dispatch(
          customerSettleBalance({
            customerId: selectedCustomerData.id,
            branchId: selectedWallet.branchId,
          })
        )
          .unwrap()
          .then(() => {
            setTimeout(() => {
              // TODO update - connecet to socket
              fetchCustomerWallets();
              fetchSelectedCustomerBalanceTransactions();
            }, 5000);
            reset();
            enqueueSnackbar('Balance successfully settled.', {
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      }
    },
    [selectedWallet, walletCreditType]
  );

  const fetchCustomerWallets = useCallback(() => {
    dispatch(getCustomerWallets(selectedCustomerData.id))
      .unwrap()
      .then(() => reset())
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedCustomerData.id, handleCustomerSettleBalance]);

  // Open Dialog Form
  const handleManageCredits = () => {
    return dispatch(setShowBalanceModalDialog(true));
  };
  // Close Dialog Form
  const handleCancelManageCredits = () => {
    if (isDirty) {
      return dispatch(setShowCancelBalanceModalDialog(true));
    } else {
      reset();
      return dispatch(setShowBalanceModalDialog(false));
    }
  };

  // Handle cancel modla dialog
  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelBalanceModalDialog(false));
      dispatch(setShowBalanceModalDialog(true));
    } else {
      reset();
      dispatch(setShowCancelBalanceModalDialog(false));
      dispatch(setShowBalanceModalDialog(false));
    }
  };

  // Handle wallet id change when changing wallet in dropdown
  const handleWalletChange = (id: string) => {
    dispatch(setSelectedWalletId(id));
  };

  // Handle create credit
  const handleConfirmCreateCredit = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          createWalletCredit({
            amount: formData.funds,
            walletId: selectedWallet.id,
            description: formData.description,
            walletCreditType: walletCreditType,
          })
        )
          .unwrap()
          .then(() => {
            fetchCustomerWallets();
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    [walletCreditType, selectedWallet]
  );

  const groupTransactionsByDateCategory = () => {
    const groupedTransactions: any = {};

    selectedCustomerBalanceTransactions?.forEach((transaction) => {
      const category = transaction.datecategory;

      if (!groupedTransactions[category]) {
        groupedTransactions[category] = [];
      }

      groupedTransactions[category].push(transaction);
    });

    return groupedTransactions;
  };

  return {
    // State
    customerWallets,
    selectedWalletId,
    selectedWallet,
    showBalanceModalDialog,
    showCancelBalanceModalDialog,
    selectedCustomerBalanceTransactions,

    // Actions
    handleWalletChange,
    setSelectedWalletId,
    setWalletCreditType,
    setSelectedWallet,
    handleManageCredits,
    fetchCustomerWallets,
    handleCancelTakeMeBack,
    handleCancelManageCredits,
    setShowBalanceModalDialog,
    handleConfirmCreateCredit,
    handleCustomerSettleBalance,
    groupTransactionsByDateCategory,
    setShowCancelBalanceModalDialog,
    fetchSelectedCustomerBalanceTransactions,
  };
};
