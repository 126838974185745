import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { EnumFilterValue } from '@contracts/common/EnumFilterValue';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { SortParams } from '@contracts/common/params/SortParams';
import {
  AcceptanceStatus,
  AppLanguage,
  CustomerStatus,
  VerificationStatus,
} from '@contracts/domain/keys';

export const customerListColumns = [
  'customer.id',
  'customer.firstName',
  'customer.lastName',
  'customer.email',
  'customer.phone',
  // 'customer.verifiedEmail',
  // 'customer.verifiedPhone',
  'customer.userAppLanguage',
  'customer.userAppVersion',
  // 'customer.dob',
  'customer.createdAt',
  'customer.newsletter',
  'customer.termsAndConditions',
  'customer.status',
] as const;

export type CustomerListColumns = (typeof customerListColumns)[number];

export type CustomerListFilter = {
  'customer.id': number | undefined;
  'customer.firstName': string | undefined;
  'customer.lastName': string | undefined;
  'customer.email': string | undefined;
  'customer.phone': string | undefined;
  // 'customer.verifiedEmail': EnumFilterValue<VerificationStatus>;
  // 'customer.verifiedPhone': EnumFilterValue<VerificationStatus>;
  'customer.userAppVersion': string | undefined;
  'customer.newsletter': EnumFilterValue<AcceptanceStatus>;
  'customer.termsAndConditions': EnumFilterValue<AcceptanceStatus>;
  'customer.userAppLanguage': EnumFilterValue<AppLanguage>;
  'customer.status': EnumFilterValue<CustomerStatus>;
  limit: number;
};

export const defaultCustomerListFilter: CustomerListFilter = {
  'customer.id': undefined,
  'customer.firstName': undefined,
  'customer.lastName': undefined,
  'customer.email': undefined,
  'customer.phone': undefined,
  // 'customer.verifiedEmail': {},
  // 'customer.verifiedPhone': {},
  'customer.userAppVersion': undefined,
  'customer.newsletter': {},
  'customer.termsAndConditions': {},
  'customer.userAppLanguage': {},
  'customer.status': {},
  limit: 25,
};

export type CustomerListSortColumns = 'customer.id' | 'customer.dob' | 'customer.createdAt';

export type CustomerListSort = SortParams<CustomerListSortColumns> | undefined;
export const defaultCustomerListSort: CustomerListSort = undefined;

export const customerListColumnVisibilitySettings = {
  'customer.id': ColumnVisibilityType.Visible,
  'customer.status': ColumnVisibilityType.CanBeHidden,
  'customer.firstName': ColumnVisibilityType.CanBeHidden,
  'customer.lastName': ColumnVisibilityType.CanBeHidden,
  'customer.email': ColumnVisibilityType.CanBeHidden,
  'customer.phone': ColumnVisibilityType.CanBeHidden,
  'customer.createdAt': ColumnVisibilityType.CanBeHidden,
  // 'customer.verifiedEmail': ColumnVisibilityType.CanBeVisible,
  // 'customer.verifiedPhone': ColumnVisibilityType.CanBeVisible,
  'customer.userAppLanguage': ColumnVisibilityType.CanBeVisible,
  'customer.userAppVersion': ColumnVisibilityType.CanBeVisible,
  // 'customer.dob': ColumnVisibilityType.CanBeVisible,
  'customer.newsletter': ColumnVisibilityType.CanBeVisible,
  'customer.termsAndConditions': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<CustomerListColumns>;

export const defaultCustomerListColumnVisibility = defaultColumnVisibilityParams(
  customerListColumnVisibilitySettings
);

export const customerListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  customerListColumns,
  customerListColumnVisibilitySettings
);
