import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { Button, Popover, Stack, Typography, useTheme } from '@mui/material';
import { TextField } from '@mui/material';

type Props = {
  title: string;
  onApply: (date?: string | null) => void;
  onReset: () => void;
};

export const SingleDateTableColumnHeader: React.FC<Props> = ({ title, onApply, onReset }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<string | null>('');
  const anchorRef = useRef<HTMLDivElement>(null);
  const { palette } = useTheme();

  useEffect(() => {
    setDate('');
  }, []);

  const handleDateChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDate(event.target.value || '');
    },
    [setDate]
  );

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleApplyClick = useCallback(() => {
    const convertedDate = date ? new Date(date).toISOString() : undefined;
    onApply(convertedDate);
  }, [onApply, date]);

  const handleResetClick = useCallback(() => {
    setDate('');

    onReset && onReset();
  }, [onReset]);

  return (
    <>
      <Stack
        ref={anchorRef}
        sx={{
          backgroundColor: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)',
        }}
      >
        <Button
          color="inherit"
          endIcon={<AssetIcon src="search-lg" size="m" sx={{ bgcolor: palette.primary.main }} />}
          onClick={handleClick}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': { backgroundColor: 'inherit' },
          }}
        >
          <Typography
            sx={{ fontSize: '14px', lineHeight: '24px', color: '#637381', fontWeight: '600' }}
          >
            {title ? title : ''}
          </Typography>
        </Button>
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ marginTop: 1 }}
      >
        <Stack sx={{ width: '209px', height: 'auto', padding: '8px', gap: '8px' }}>
          <TextField
            size="small"
            label="Start Date"
            type="date"
            data-at-text-field="StartDateFilter"
            value={date}
            onChange={handleDateChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Stack sx={{ flexDirection: 'row', justifyContent: 'center', marginTop: '8px' }}>
            <Button
              size="small"
              variant="contained"
              data-at-button="Apply"
              onClick={handleApplyClick}
            >
              Apply
            </Button>
            <Button
              size="small"
              variant="text"
              color="inherit"
              data-at-button="Reset"
              onClick={handleResetClick}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
