import { ComponentType, Dispatch, SetStateAction, createContext } from 'react';

import { AlertPresenterProps } from '@Components/props/alerts/AlertPresenterProps';
import { Alert } from '@contracts/domain/Alert';
import { noop } from '@utils/noop';

type AlertStoreContextType = {
  AlertPresenter: ComponentType<AlertPresenterProps>;
  alert?: Alert;
  setAlert: Dispatch<SetStateAction<Alert | undefined>>;
};

const alertStoreContextDefault: AlertStoreContextType = {
  AlertPresenter: () => null,
  setAlert: noop,
};

export const AlertStoreContext = createContext<AlertStoreContextType>(alertStoreContextDefault);
