import axios from 'axios';
import { getCookieAuthToken, getCookieRefreshToken } from './cookieHandlers';

const axiosBranchesVehiclesInstance = axios.create({
  baseURL: process.env.REACT_APP_BRANCHES_VEHICLES_API_PROXY_URL,
});

axiosBranchesVehiclesInstance.interceptors.request.use(
  (request) => {
    const cookieRefreshToken = getCookieRefreshToken();

    if (cookieRefreshToken) {
      request.headers.authorization = `${cookieRefreshToken}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const axiosCustomerManagementInstance = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_MANAGEMENT_API_PROXY_URL,
});

const axiosRentalsInstance = axios.create({
  baseURL: process.env.REACT_APP_VEHICLES_RENTALS_API_PROXY_URL,
});

const axiosAuthorizationInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTHORIZATION_API_PROXY_URL,
});

const axiosPaymentsInstance = axios.create({
  baseURL: process.env.REACT_APP_PAYMENTS_API_PROXY_URL,
});

const axiosMediaUrl = process.env.REACT_APP_MEDIA_PROXY_URL;

const axiosInstances = [
  axiosRentalsInstance,
  axiosAuthorizationInstance,
  axiosBranchesVehiclesInstance,
  axiosCustomerManagementInstance,
  axiosPaymentsInstance,
];

axiosInstances.forEach((instance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  );
});

export {
  axiosRentalsInstance,
  axiosBranchesVehiclesInstance,
  axiosCustomerManagementInstance,
  axiosAuthorizationInstance,
  axiosPaymentsInstance,
  axiosMediaUrl,
};
