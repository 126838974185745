import { Box, Typography } from '@mui/material';

type Props = {
  label: string;
};

export const RentalStateLabel = ({ label }: Props) => {
  const generateStateLabel = () => {
    switch (label) {
      case 'ACTIVE':
        return (
          <Box
            sx={{
              background: '#36B37E',
              color: '#ffff',
              padding: '12px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '30px',
            }}
          >
            <Typography fontSize={'12px'} fontWeight={'bold'}>
              {label}
            </Typography>
          </Box>
        );
      case 'PAUSED':
        return (
          <Box
            sx={{
              background: 'rgb(255, 171, 0)',
              color: '#ffff',
              padding: '12px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '30px',
            }}
          >
            <Typography fontSize={'12px'} fontWeight={'bold'}>
              {label}
            </Typography>
          </Box>
        );

      case 'ENDED':
        return (
          <Box
            sx={{
              background: '#0E445C',
              color: '#ffff',
              padding: '12px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '70px',
              height: '30px',
            }}
          >
            <Typography fontSize={'12px'} fontWeight={'bold'}>
              {label}
            </Typography>
          </Box>
        );

      case 'CANCELLED':
        return (
          <Box
            sx={{
              background: '#FF5630',
              color: '#ffff',
              padding: '12px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '90px',
              height: '30px',
            }}
          >
            <Typography fontSize={'12px'} fontWeight={'bold'}>
              {label}
            </Typography>
          </Box>
        );

      default:
        break;
    }
  };

  return <Box>{generateStateLabel()}</Box>;
};
