// System
import { Box } from '@mui/system';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, ListItemIcon, ListItemText } from '@mui/material';

import { NavItemProps } from '../types';

// Styles
import Styles from './NavSectionMini.module.scss';

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ item, active, isExternalLink }, ref) => {
    const { title, path, icon } = item;
    const renderContent = (
      <Box ref={ref} className={active ? Styles.NavMini_Active : Styles.NavMini}>
        {icon && (
          <Box>
            <ListItemIcon className={Styles.NavIcon}>{icon}</ListItemIcon>
          </Box>
        )}
        <ListItemText className={Styles.ListItemText} primary={`${title}`} />
      </Box>
    );

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Link href={path} target="_blank" rel="noopener" underline="none">
            {renderContent}
          </Link>
        );

      // Default
      return (
        <Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>
      );
    };

    return renderItem();
  }
);

export default NavItem;
