// System
import { Stack, Typography, Box } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';

export const NoCardLayout = () => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Stack direction="column" alignItems="center" justifyContent="center" height={'90%'}>
        <AssetIcon
          src="credit-card-x"
          sx={{
            height: '56px',
            width: '56px',
            color: 'rgba(145, 158, 171, 0.8)',
            marginBottom: '10px',
          }}
        />
        <Typography
          sx={{ fontSize: '18px', lineHeight: '28px', fontWeight: '700', marginTop: '10px' }}
        >
          No payments available
        </Typography>
        <Typography sx={{ fontSize: '14px', lineHeight: '24px' }}>
          Customer haven’t added any payment methods yet
        </Typography>
      </Stack>
    </Box>
  );
};