import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import {
  SelectBox,
  SelectBoxDropDownLayout,
  SelectBoxProvider,
  useManageSelectBox,
} from '@Components/shared/selects/SelectBox';
import { KeyValue } from '@contracts/view/KeyValue';
import { Button } from '@mui/material';
import { useCallback } from 'react';

type Props<T extends string> = {
  name: string;
  options: KeyValue<T, string>[];
  value: T;
  onChange: (v: T) => void;
};

type DropDownProps<T extends string> = {
  name: string;
  options: KeyValue<T, string>[];
  onChange: (v: T) => void;
};

const DropDown = <T extends string>({ name, onChange, options }: DropDownProps<T>) => {
  const { handleClose } = useManageSelectBox();
  const handleChange = useCallback(
    (value: T) => handleClose(() => onChange?.(value)),
    [onChange, handleClose]
  );

  return (
    <>
      {options.map(({ key, value }) => (
        <MenuItemEx name={name} key={key} onClick={handleChange(key)}>
          {value}
        </MenuItemEx>
      ))}
    </>
  );
};

const SelectContent = <T extends string>({ name, value, onChange, options }: Props<T>) => {
  const { handleOpen } = useManageSelectBox();

  return (
    <SelectBox
      name={name}
      input={
        <Button
          variant="contained"
          color="inherit"
          size="small"
          endIcon={<AssetIcon src="chevron-down" size="s" />}
          onClick={handleOpen}
        >
          {options.find(({ key }) => key === value)?.value}
        </Button>
      }
      dropDown={
        <SelectBoxDropDownLayout sx={{ maxHeight: '500px', width: '211px' }}>
          <DropDown<T> name={name} onChange={onChange} options={options} />
        </SelectBoxDropDownLayout>
      }
    />
  );
};

export const ButtonSelect = <T extends string>(props: Props<T>) => {
  return (
    <SelectBoxProvider>
      <SelectContent {...props} />
    </SelectBoxProvider>
  );
};
