import { BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';

const vehicleIconPath = (vehicleType: string, stateOfCharge: string) =>
  `assets/icons/maps/ic_${vehicleType}_${stateOfCharge}.svg`;

const iotIconPath = (vehicleType: string, iotStatus: string) =>
  `assets/icons/maps/ic_${vehicleType}_iot_${iotStatus}.svg`;

const vehicleBatteryIconPath = (stateOfCharge: string) =>
  `assets/icons/maps/ic_battery_${stateOfCharge}.svg`;

const vehicleRentalStatusIconPath = (rentalStatus: string, vehicleType: string) =>
  `assets/icons/maps/ic_${rentalStatus}_${vehicleType}.svg`;

const vehicleServiceStateIconPath = (vehicleType: string, serviceState: string) =>
  `assets/icons/maps/ic_${vehicleType}_${serviceState?.toLowerCase()}.svg`;

const vehicleIotIconPath = (onlineStatus: string) => `assets/icons/maps/ic_iot_${onlineStatus}.svg`;

const googleMarkerPath = (position: string) => `assets/icons/maps/google_marker_${position}.svg`;

const BATTERY_LEVEL_HIGH = 'high';
const BATTERY_LEVEL_MEDIUM = 'medium';
const BATTERY_LEVEL_LOW = 'low';
const BATTERY_LEVEL_CRITICAL = 'critical';
const BATTERY_LEVEL_UNKNOWN = 'unknown';

const IN_RIDE_STATUS = 'DRIVING';

const SERVICE_STATE_LOST = 'LOST';
const SERVICE_STATE_OUT_OF_ORDER = 'OUT_OF_ORDER';
const SERVICE_STATE_READY_FOR_DEPLOYMENT = 'READY_FOR_DEPLOYMENT';

const BATTERY_LEVEL_HIGH_COLOR = '#1B806A';
const BATTERY_LEVEL_MEDIUM_COLOR = '#FFAB00';
const BATTERY_LEVEL_LOW_COLOR = '#B71D18';
const BATTERY_LEVEL_CRITICAL_COLOR = '#B71D18';
const BATTERY_LEVEL_UNKNOWN_COLOR = '#919EABCC';

type IconServiceStateType = {
  outOfOrder: string[];
  operational: string[];
};

export const currentLocationPin = () => `assets/icons/maps/ic_operator_location.svg`;

export const generateRentalLocationIcons = (position: string) => {
  if (position === 'A') {
    return googleMarkerPath('A');
  } else {
    return googleMarkerPath('B');
  }
};

export const generateVehicleIcon = (batteryLevel: string, vehicleType: string) => {
  if (batteryLevel === BATTERY_LEVEL_HIGH) {
    return vehicleIconPath(vehicleType?.toLocaleLowerCase(), 'full');
  }
  if (batteryLevel === BATTERY_LEVEL_MEDIUM) {
    return vehicleIconPath(vehicleType?.toLocaleLowerCase(), 'medium');
  }

  if (batteryLevel === BATTERY_LEVEL_LOW) {
    return vehicleIconPath(vehicleType?.toLocaleLowerCase(), 'low');
  }

  if (batteryLevel === BATTERY_LEVEL_CRITICAL) {
    return vehicleIconPath(vehicleType?.toLocaleLowerCase(), 'critical');
  }

  if (batteryLevel === BATTERY_LEVEL_UNKNOWN) {
    return vehicleIconPath(vehicleType?.toLocaleLowerCase(), 'unknown');
  }

  return vehicleIconPath(vehicleType?.toLocaleLowerCase(), 'full');
};

export const generateIotOfflineIcon = (iotStatus: string, vehicleType: string) => {
  return vehicleIconPath(vehicleType?.toLocaleLowerCase(), iotStatus);
};

export const generateRentalStatusIcon = (rentalStatus: string, vehicleType: string) => {
  if (rentalStatus === IN_RIDE_STATUS) {
    return vehicleRentalStatusIconPath('ongoing', vehicleType?.toLocaleLowerCase());
  }
};

export const generateServiceStateIcon = (serviceState: string, vehicleType: string) => {
  if (serviceState === SERVICE_STATE_LOST) {
    return vehicleServiceStateIconPath(vehicleType?.toLocaleLowerCase(), SERVICE_STATE_LOST);
  }

  if (serviceState === SERVICE_STATE_READY_FOR_DEPLOYMENT) {
    return vehicleServiceStateIconPath(
      vehicleType?.toLocaleLowerCase(),
      SERVICE_STATE_OUT_OF_ORDER
    );
  }
};

export const generateBatteryteIcon = (batteryLevel: string) => {
  if (batteryLevel === BATTERY_LEVEL_HIGH) {
    return vehicleBatteryIconPath('full');
  }

  if (batteryLevel === BATTERY_LEVEL_MEDIUM) {
    return vehicleBatteryIconPath('medium');
  }

  if (batteryLevel === BATTERY_LEVEL_LOW) {
    return vehicleBatteryIconPath('low');
  }

  if (batteryLevel === BATTERY_LEVEL_CRITICAL) {
    return vehicleBatteryIconPath('critical');
  }

  if (batteryLevel === BATTERY_LEVEL_UNKNOWN) {
    return vehicleBatteryIconPath('unknown');
  }

  return vehicleBatteryIconPath('full');
};

export const generateIotIcon = (status: string) => {
  if (status === 'online') {
    return vehicleIotIconPath('on');
  }

  if (status === 'offline') {
    return vehicleIotIconPath('off');
  }

  if (status === 'unknown') {
    return vehicleIotIconPath('unknown');
  }

  return vehicleIotIconPath('unknown');
};

export const generateTxtColor = (batteryLevel: string) => {
  if (batteryLevel === BATTERY_LEVEL_HIGH) {
    return BATTERY_LEVEL_HIGH_COLOR;
  }
  if (batteryLevel === BATTERY_LEVEL_MEDIUM) {
    return BATTERY_LEVEL_MEDIUM_COLOR;
  }

  if (batteryLevel === BATTERY_LEVEL_LOW) {
    return BATTERY_LEVEL_LOW_COLOR;
  }

  if (batteryLevel === BATTERY_LEVEL_CRITICAL) {
    return BATTERY_LEVEL_CRITICAL_COLOR;
  }

  if (batteryLevel === BATTERY_LEVEL_UNKNOWN) {
    return BATTERY_LEVEL_UNKNOWN_COLOR;
  }

  return BATTERY_LEVEL_HIGH_COLOR;
};

export const generateBatteryTxt = (batteryLevel: string) => {
  if (batteryLevel === BATTERY_LEVEL_HIGH) {
    return 'High';
  }
  if (batteryLevel === BATTERY_LEVEL_MEDIUM) {
    return 'Medium';
  }

  if (batteryLevel === BATTERY_LEVEL_LOW) {
    return 'Low';
  }

  if (batteryLevel === BATTERY_LEVEL_CRITICAL) {
    return 'Critical';
  }

  if (batteryLevel === BATTERY_LEVEL_UNKNOWN) {
    return 'N/A';
  }

  return 'High';
};

export const generateIconByPriority = (
  veh: BackofficeLiveVehicle,
  serviceStates: IconServiceStateType
) => {
  switch (true) {
    case veh?.rentalStatus === IN_RIDE_STATUS:
      return generateRentalStatusIcon(
        IN_RIDE_STATUS,
        veh?.category?.vehicleType?.toLocaleLowerCase()
      );

    case veh?.serviceState === SERVICE_STATE_LOST:
      return generateServiceStateIcon(
        SERVICE_STATE_LOST,
        veh?.category?.vehicleType?.toLocaleLowerCase()
      );

    case veh?.iotStatus === 'offline':
      return iotIconPath(veh?.category?.vehicleType?.toLocaleLowerCase(), veh?.iotStatus);

    case veh?.deviceInfo?.batteryLevel === BATTERY_LEVEL_CRITICAL ||
      veh?.deviceInfo?.batteryLevel === BATTERY_LEVEL_LOW:
      return generateVehicleIcon(
        veh?.deviceInfo?.batteryLevel?.toLowerCase(),
        veh?.category?.vehicleType?.toLocaleLowerCase()
      );

    case !serviceStates?.operational?.includes(veh?.serviceState) &&
      serviceStates?.outOfOrder?.includes(veh?.serviceState):
      return generateServiceStateIcon(
        SERVICE_STATE_READY_FOR_DEPLOYMENT,
        veh?.category?.vehicleType?.toLocaleLowerCase()
      );

    case veh?.deviceInfo?.batteryLevel === BATTERY_LEVEL_MEDIUM:
      return generateVehicleIcon(
        veh?.deviceInfo?.batteryLevel?.toLowerCase(),
        veh?.category?.vehicleType?.toLocaleLowerCase()
      );

    case serviceStates?.operational?.includes(veh?.serviceState) &&
      !serviceStates?.outOfOrder?.includes(veh?.serviceState):
      return generateVehicleIcon(
        // @ts-ignore
        veh?.deviceInfo?.batteryLevel?.toLowerCase(),
        veh?.category?.vehicleType?.toLocaleLowerCase()
      );

    default:
      return vehicleIconPath(veh?.category?.vehicleType?.toLocaleLowerCase(), 'unknown');
  }
};
