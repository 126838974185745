import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';

import { AlertButtonProps } from '@Components/props/alerts/AlertButtonProps';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const SetupSuccessAlertButton: React.FC<AlertButtonProps> = ({ onClick }) => {
  const { palette } = useTheme();
  return (
    <AtLocator type="button" name="Setup">
      <Button
        aria-label="setup"
        sx={{
          backgroundColor: palette.success.main,
          color: palette.success.lighter,
        }}
        variant="contained"
        color="success"
        size="small"
        onClick={onClick}
      >
        Setup
      </Button>
    </AtLocator>
  );
};
