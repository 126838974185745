import {  createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  showCreateBrandForm: false,
  showCancelBrandForm:false,
};

const createBrandSlice = createSlice({
  name: 'createBrand',
  initialState,
  reducers: {
    setShowCreateBrandForm: (state, { payload }) => {
      state.showCreateBrandForm = payload;
    },
    setShowCancelBrandForm: (state, { payload }) => {
      state.showCancelBrandForm = payload;
    },
  }
});

export const { reducer: createBrandReducer } = createBrandSlice;
export const {
  setShowCreateBrandForm,
  setShowCancelBrandForm
} = createBrandSlice.actions;
