import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRentalsInstance } from '@utils/axiosInstances';
import { GetRentalsParams } from './types';

export const getRentalsList = createAsyncThunk(
  'rentals/getRentals',
  async (params: GetRentalsParams, thunkApi) => {
    return axiosRentalsInstance
      .get(`rentals`, {
        params:
          params?.brandId !== 'undefined' && params?.brandId
            ? {
                ...params.selectedFilters,
                brandId: params?.brandId,
                startTimeOrder: params?.sortingFilter,
              }
            : { ...params.selectedFilters },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error);
      });
  }
);

// Type
const initialState = {
  rentals: [],
  loadingContent: true,
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const rentalsSlice = createSlice({
  name: 'rentals',
  initialState,
  reducers: {
    setRentalsList: (state, { payload }) => {
      state.rentals = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
  },
  extraReducers: {
    [getRentalsList.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;

      state.rentals = items;
      state.loadingContent = false;

      state.pagination = {
        ...state.pagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
  },
});

export const { reducer: rentalsReducer } = rentalsSlice;
export const { setRentalsList, setPagination } = rentalsSlice.actions;
