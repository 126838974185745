import React from 'react';

import { Grid } from '@mui/material';

import { RHFSwitch } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const StartRentalRequirements = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={0} columns={8} justifyContent="flex-start">
      <Grid item xs={4}>
        <AtLocator type="special-field" name="signedUp">
          <RHFSwitch label="Signed up" name="signedUp" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="emailAddressSpecified">
          <RHFSwitch label="Email address specified" name="emailAddressSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="phoneNumberSpecified">
          <RHFSwitch label="Phone number specified" name="phoneNumberSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="firstNameSpecified">
          <RHFSwitch label="First name specified" name="firstNameSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="lastNameSpecified">
          <RHFSwitch label="Last name specified" name="lastNameSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="dateOfBirthSpecified">
          <RHFSwitch label="Date of birth specified" name="dateOfBirthSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="minAgeAccordance">
          <RHFSwitch label="Minimum age" name="minAgeAccordance" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="streetSpecified">
          <RHFSwitch label="Street specified" name="streetSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="postcodeSpecified">
          <RHFSwitch label="Postcode specified" name="postcodeSpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="citySpecified">
          <RHFSwitch label="City specified" name="citySpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="countrySpecified">
          <RHFSwitch label="Country specified" name="countrySpecified" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="creditCardAttached">
          <RHFSwitch label="Credit card attached" name="creditCardAttached" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="paymentMethodSelected">
          <RHFSwitch label="Payment method selected" name="paymentMethodSelected" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="paidSignUpFeeViaCreditCard">
          <RHFSwitch label="Paid sign up fee via credit card" name="paidSignUpFeeViaCreditCard" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="enableCreditCardPreAuthorization">
          <RHFSwitch
            label="Enable credit card pre-authorization"
            name="enableCreditCardPreAuthorization"
          />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="settleNegativeBalance">
          <RHFSwitch label="Settle negative balance" name="settleNegativeBalance" />
        </AtLocator>
      </Grid>
    </Grid>
  );
};
