// System
import { useSnackbar } from 'notistack';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useVehicleCommands } from '@Hooks/shared/useVehicleCommands';

// Actions
import {
  setShowOperatorQRModal,
  setShowOperatorScanModal,
  setOperatorScannedVehicles,
  getSelectedOperatorScanVehicleByQR,
  setShowOperatorCancelModal,
} from '@Modal/reducers/operator/operatorScan/operatorScanSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useOperatorVehicles } from './useOperatorVehicles';
import {
  setShowServiceStateModal,
  putMultipleOperatorVehicleState,
} from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

export const useOperatorScan = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const socketId = localStorage.getItem('socketId');

  const {
    showOperatorManualQRModal,
    showOperatorScanModal,
    scannedVehicle,
    scannedVehicles,
    showOperatorScanModalCancel,
  } = useSelector((state: StoreInterface) => state?.operatorScan);

  const { handleSendCommands } = useVehicleCommands();
  const { handleSetShowServiceStateDrawer } = useOperatorVehicles();

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Fetch scanned vehicle
  const handleGetScannedVehicle = (qrCode: string) => {
    const splitQRCode: string = qrCode?.split('/')[qrCode?.split('/').length - 1];

    dispatch(getSelectedOperatorScanVehicleByQR(splitQRCode.toUpperCase()))
      .unwrap()
      .then((result) => {
        const isVehicleScanned = scannedVehicles.find((veh) => veh.id === result?.id);

        if (isVehicleScanned?.id) {
          enqueueSnackbar('Vehicle is already scanned', {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        } else {
          const updatedScannedVehicles = [result, ...scannedVehicles];
          dispatch(setOperatorScannedVehicles(updatedScannedVehicles));
          dispatch(setShowOperatorQRModal(false));
        }
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  // Update scanned vehicles service state
  const handleUpdateOperatorMultipleVehiclesState = (state: string) => {
    dispatch(
      putMultipleOperatorVehicleState({
        vehicleIds: scannedVehicles.map((veh) => veh.id),
        serviceState: state,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setShowServiceStateModal(false));
        enqueueSnackbar(`Vehicle state updated to ${state}`, {
          autoHideDuration: 3500,
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 5000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  // Action handlers
  const handleOpenScanModal = (status: boolean) => dispatch(setShowOperatorScanModal(status));
  const handleOpenQRScanModal = (status: boolean) => dispatch(setShowOperatorQRModal(status));
  const handleShowCancelModalDialog = () => {
    if (scannedVehicles?.length > 0) {
      dispatch(setShowOperatorCancelModal(true));
    } else {
      dispatch(setShowOperatorScanModal(false));
    }
  };

  const handleScanCancelModal = (action: string) => {
    if (action === 'back') {
      dispatch(setShowOperatorCancelModal(false));
      dispatch(setShowOperatorScanModal(true));
    } else {
      dispatch(setShowOperatorCancelModal(false));
      dispatch(setShowOperatorScanModal(false));
      dispatch(setOperatorScannedVehicles([]));
    }
  };

  const removeScannedVehicle = (id: string) => {
    const updatedScannedVehicles = scannedVehicles.filter((veh) => veh.id !== id);
    dispatch(setOperatorScannedVehicles(updatedScannedVehicles));
  };

  const cleanScannedVehicles = () => dispatch(setOperatorScannedVehicles([]));

  const handleOpenServiceStateModal = () => {
    if (scannedVehicles?.length > 0) {
      handleSetShowServiceStateDrawer(true);
    } else {
      enqueueSnackbar('You dont have scanned vehicles', {
        autoHideDuration: 3000,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const handleScannedVehiclesDeploy = () => {
    const imeiList = scannedVehicles?.length > 0 ? scannedVehicles?.map((veh) => veh.iotImei) : [];

    if (imeiList.length > 0) {
      handleSendCommands(socketId, 'stop', imeiList);
      handleUpdateOperatorMultipleVehiclesState('FUNCTIONAL');
      cleanScannedVehicles();
      dispatch(setShowOperatorScanModal(false));
    }
  };

  const handleScannedVehiclesApproach = () => {
    const imeiList = scannedVehicles?.length > 0 ? scannedVehicles?.map((veh) => veh.iotImei) : [];

    if (imeiList.length > 0) {
      handleSendCommands(socketId, 'start', imeiList);
      dispatch(setShowOperatorScanModal(false));
    }
  };

  return {
    // Store
    scannedVehicle,
    scannedVehicles,
    showOperatorScanModal,
    showOperatorManualQRModal,
    showOperatorScanModalCancel,
    // Actions
    handleOpenScanModal,
    removeScannedVehicle,
    cleanScannedVehicles,
    handleOpenQRScanModal,
    handleScanCancelModal,
    handleGetScannedVehicle,
    handleOpenServiceStateModal,
    handleShowCancelModalDialog,
    handleScannedVehiclesDeploy,
    handleScannedVehiclesApproach,
    handleUpdateOperatorMultipleVehiclesState,
  };
};
