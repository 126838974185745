// System
import { useSelector, useDispatch } from 'react-redux';
import { StoreInterface } from 'src/app/types';

// Redux
import { setRedisVehicle } from '@Modal/reducers/redis/redisSlice';

// Types
import { UseRedis } from './types';
import { BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';

export const useRedis = (): UseRedis => {
  const dispatch = useDispatch();
  const { selectedRedisVehicle } = useSelector((state: StoreInterface) => state.redis);

  const handleSetRedisVehicle = (vehicle?: BackofficeLiveVehicle) => {
    return dispatch(setRedisVehicle(vehicle));
  };

  return {
    // State
    selectedRedisVehicle,
    // Actions
    handleSetRedisVehicle,
  };
};
