// System
import { Stack, Typography, Box } from '@mui/material';

// Components
import { RentalStateLabel } from '../RentalStateLabel/RentalStateLabel';
import TruncateText from '../../TruncateText/TruncateText';

// Redux
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

// Styles
import Styles from './SetupRentalHeader.module.scss';

// Types
type Props = {
  onClose?: () => void;
};

export const SetupRentalHeader: React.FC<Props> = ({ onClose }: Props) => {
  const { selectedRental } = useRentalsDrawer();
  const { id, state } = selectedRental;

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Box className={Styles.SetupRentalHeader}>
        <Typography variant="h6">Rental </Typography>
        <TruncateText
          text={id}
          maxLength={8}
          textStyles={{ color: 'black', fontSize: '18px', lineHeight: '28px', fontWeight: '700' }}
        />
        <RentalStateLabel label={state} />
      </Box>
    </Stack>
  );
};
