import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// Types
import { CreateVehicleDataType } from '../types';

// POST
export const createVehicle = createAsyncThunk(
  'vehicle/createNewVehicle',
  async (vehicleData: CreateVehicleDataType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post('/v1/vehicles', {
        ...vehicleData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  showCreateVehicleForm: false,
  showCancelVehicleForm: false,
};

const createVehicleSlice = createSlice({
  name: 'createVehicle',
  initialState,
  reducers: {
    setShowCreateVehicleForm: (state, { payload }) => {
      state.showCreateVehicleForm = payload;
    },
    setShowCancelVehicleForm: (state, { payload }) => {
      state.showCancelVehicleForm = payload;
    },
  },
  extraReducers: {
    [createVehicle.fulfilled?.type]: (state) => {
      state.showCreateVehicleForm = false;
    },
  },
});

export const { reducer: createVehicleReducer } = createVehicleSlice;
export const { setShowCreateVehicleForm, setShowCancelVehicleForm } = createVehicleSlice.actions;
