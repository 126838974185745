// System
import { Button } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import FormProvider, { RHFTextField } from '@Components/hook-form';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Form
import { useCreateCustomerNoteForm } from '../CreateCustomerNoteFormContext';

// Redux
import { useCustomerNotes } from '@Modal/hooks/customers/notes/useCustomerNotes';

// Styles
import Styles from './addCustomerNoteModalDialog.module.scss';

export const AddCustomerNoteModalDialog = () => {
  const methods = useCreateCustomerNoteForm();
  const { showCreateCustomerNote, handleCreateCancel, handleSubmitCustomerNote } =
    useCustomerNotes();

  return (
    <>
      {showCreateCustomerNote && (
        <ClosableModal
          name="addNote"
          title="Add note"
          open={showCreateCustomerNote}
          onClose={handleCreateCancel}
          className={Styles.addCustomerNoteModalDialog}
        >
          <FormProvider methods={methods}>
            <CreateDialogLayout>
              <RHFTextField
                name="note"
                multiline
                minRows={4}
                maxRows={9}
                placeholder="Note description"
                inputProps={{ maxLength: 300 }}
                helperText="Maximum 300 characters"
              />
              <CreateDialogFooterLayout>
                <AtLocator type="button" name="Cancel">
                  <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
                    Cancel
                  </Button>
                </AtLocator>
                <AtLocator type="button" name="Submit">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(e) => handleSubmitCustomerNote(e)}
                  >
                    Add
                  </Button>
                </AtLocator>
              </CreateDialogFooterLayout>
            </CreateDialogLayout>
          </FormProvider>
        </ClosableModal>
      )}
    </>
  );
};
