import { useEffect } from 'react';

// System
import { Stack } from '@mui/material';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Components
import { NoCardLayout } from './NoCardLayout';
import { PaymentCard } from './PaymentCard';
import { useCustomerPaymentMethods } from '@Modal/hooks/customers/paymentMethods/usePaymentMethods';

// Redux
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

export const PaymentMethods = () => {
  const { selectedCustomerData } = useCustomersDrawer();
  const { paymentMethods, handleGetCustomerPaymentMethods } = useCustomerPaymentMethods();

  useEffect(() => {
    handleGetCustomerPaymentMethods(selectedCustomerData.id);
  }, []);

  return (
    <>
      {paymentMethods.length === 0 ? (
        <NoCardLayout />
      ) : (
        <DrawerTabPanelLayout>
          <DrawerTabSectionLayout>
            <Stack spacing={2} sx={{ marginTop: '16px' }}>
              {(paymentMethods ?? []).map((item) => {
                return (
                  <PaymentCard
                    id={item.id}
                    cardType={item.cardType}
                    mask={item.mask}
                    cardExpireDate={item.cardExpireDate}
                    isDefault={item.isDefault}
                  />
                );
              })}
            </Stack>
          </DrawerTabSectionLayout>
        </DrawerTabPanelLayout>
      )}
    </>
  );
};
