// System
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Hooks
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

// Types
import { AppDispatch } from 'src/app/store';

export const SetupAviatorRoleDeleteModalDialog = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    showAviatorRoleDeleteModalDialog,
    setShowAvaitorRoleDeleteModal,
    handleAviatorRoleDelete,
  } = useAviatorRoleDrawer();

  return (
    <ClosableModal
      name="SetupAviatorRoleDeleteModalDialog"
      title="Delete Aviator Role"
      open={showAviatorRoleDeleteModalDialog}
      onClose={() => dispatch(setShowAvaitorRoleDeleteModal(false))}
      sx={{ width: '90%' }}
    >
      <CreateDialogLayout>
        <Typography variant="body1"> Are you sure you want to delete this role?</Typography>
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => dispatch(setShowAvaitorRoleDeleteModal(false))}
            >
              Cancel
            </Button>
          </AtLocator>
          <Button variant="contained" color="error" onClick={handleAviatorRoleDelete}>
            Delete
          </Button>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </ClosableModal>
  );
};
