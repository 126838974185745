import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export type CreateRentalsNoteFormState = {
  note: string;
};

export const CreateRentalsNoteFormStateDefault: CreateRentalsNoteFormState = {
  note: '',
};

export const CreateRentalsNoteSchemaDefault: ObjectSchema<Partial<CreateRentalsNoteFormState>> =
  object<Partial<CreateRentalsNoteFormState>>().shape({
    note: string()
    .required('Note is required')
    .test('min-characters', 'Note must be at least 2 non-space characters long', (value) => {
      const nonSpaceChars = value.replace(/\s/g, '').length;
      return nonSpaceChars >= 2;
    }),
  });

type CreateRentalsNoteFormContextType = {
  schema: ObjectSchema<Partial<CreateRentalsNoteFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateRentalsNoteFormState>>>;
  methods?: UseFormReturn<CreateRentalsNoteFormState>;
};

const createRentalsNoteFormContextDefault = {
  schema: CreateRentalsNoteSchemaDefault,
  setSchema: noop,
} satisfies CreateRentalsNoteFormContextType;

export const CreateRentalsNoteFormContext = createContext<CreateRentalsNoteFormContextType>(
  createRentalsNoteFormContextDefault
);

export const useCreateRentalsNoteForm = () => {
  const { methods } = useContext(CreateRentalsNoteFormContext);
  if (!methods) {
    throw new Error('CreateRentalsNoteFormContext is empty');
  }

  return methods;
};

export const useManageCreateRentalsSchema = () => {
  const { setSchema } = useContext(CreateRentalsNoteFormContext);

  return { setSchema };
};
