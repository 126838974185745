// System
import { SystemStyleObject } from '@mui/system';
import { Avatar, IconButton, Stack, Theme, Typography, useTheme } from '@mui/material';

// Components
import Iconify from '@Components/iconify';
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';

// Redux
import { useVehicleNotes } from '@Modal/hooks/vehicles/notes/useVehicleNotes';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      minHeight: '66px',
      padding: 2,
      backgroundColor: palette.grey['100'],
      ...roundedBorder,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

type props = {
  text?: string;
  id?: string;
  createdAt: string;
  vehicleId?: string;
};

export const VehicleNoteCard = ({ text, id, createdAt }: props) => {
  const styles = useSx();
  const { palette } = useTheme();

  const { handleDeleteNoteClick } = useVehicleNotes();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.container}
      spacing={2}
    >
      <Stack direction="row" spacing={1}>
        <Avatar>JD</Avatar>
        <Stack sx={{ width: '430px', overflow: 'auto' }}>
          <Typography variant="subtitle2">{text}</Typography>
          <Stack direction="row">
            <Typography variant="caption" color={palette.grey.A700}>
              John doe
            </Typography>
            <Iconify icon="map:circle" color={palette.grey.A700} padding={1} />
            <Typography variant="caption" color={palette.grey.A700}>
              <DateLabel date={new Date(createdAt)} timezone={undefined} format="long" />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <IconButton onClick={() => handleDeleteNoteClick(id)}>
        <AssetIcon src="trash-02" size="xxxl" />
      </IconButton>
    </Stack>
  );
};
