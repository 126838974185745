import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowProviderDrawer,
  setShowCancelProviderDrawer,
  setSelectedProvider,
  setShowDeleteProviderModal,
} from '@Modal/reducers/providers/drawer/providerDrawerSlice';
// Form
import { useFormState } from 'react-hook-form';
import {
  useManageSetupProviderSchema,
  useSetupProviderForm,
} from '@Forms/providers/SetupProviderFormContext';

// System
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import {
  // useDeleteProviderMutation,
  useGetProviderLazyQuery,
  useGetProvidersQuery,
  useUpdateProviderMutation,
} from '@Src/generated.graphql';
import { useSetupProviderUseCaseMapper } from '@Hooks/providers/SetupProvider/useSetupProviderUseCaseMapper';
import { useSetupProviderUseCaseSchema } from '@Hooks/providers/SetupProvider/useSetupProviderUseCaseSchema';

export const useProviderDrawer = () => {
  const [getProvider] = useGetProviderLazyQuery();
  // const [deleteProvider] = useDeleteProviderMutation();
  const { refetch: refetchProviders } = useGetProvidersQuery();
  const [updateProvider] = useUpdateProviderMutation();

  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const {
    showProviderDrawer,
    showCancelProviderDrawer,
    selectedProviderData,
    showDeleteProviderModal,
  } = useSelector((state: StoreInterface) => state?.providerDrawer);

  const { setSchema } = useManageSetupProviderSchema();
  const mapper = useSetupProviderUseCaseMapper(selectedProviderData);
  const schema = useSetupProviderUseCaseSchema();

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelProviderDrawer(true));
    } else {
      return dispatch(setShowProviderDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelProviderDrawer(false));
      dispatch(setShowProviderDrawer(true));
    } else {
      reset();
      dispatch(setShowCancelProviderDrawer(false));
      dispatch(setShowProviderDrawer(false));
    }
  };

  const handleCancelChanges = () => {
    dispatch(setShowCancelProviderDrawer(false));
    dispatch(setShowProviderDrawer(false));
    reset();
  };

  const setProviderDrawerMapperHandler = useCallback(async () => {
    setSchema(schema);
    const formState = await mapper.toFormState(selectedProviderData.id);
    reset(formState);
  }, [selectedProviderData]);

  useEffect(() => {
    if (selectedProviderData) {
      setProviderDrawerMapperHandler();
    }
  }, [selectedProviderData, setProviderDrawerMapperHandler]);

  // Form Controls
  const { control, handleSubmit, reset } = useSetupProviderForm();
  const { isDirty, errors } = useFormState({ control });

  const handleProviderRowClick = async (id: string) => {
    try {
      const { data } = await getProvider({ variables: { providerId: id } });
      if (data && data.provider) {
        await dispatch(setSelectedProvider(data.provider));
      }
    } catch (error) {
      enqueueSnackbar(error, {
        autoHideDuration: 2000,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } finally {
      dispatch(setShowProviderDrawer(true));
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      // await deleteProvider({
      //   variables: { providerId: selectedProviderData.id },
      // });

      enqueueSnackbar('Provider was successfully deleted', {
        variant: 'success',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

      await refetchProviders();
      dispatch(setShowDeleteProviderModal(false));
      dispatch(setShowProviderDrawer(false));
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const handleSubmitGeneralForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        let betSuggestions = formData.betSuggestions;

        if (typeof betSuggestions === 'string') {
          // @ts-ignore
          betSuggestions = betSuggestions.split(',').map((num) => Number(num.trim()));
        }

        const data = {
          ...formData,
          betSuggestions,
        };

        try {
          await updateProvider({
            // @ts-ignore
            variables: { providerId: selectedProviderData?.id, input: data },
          });

          enqueueSnackbar('Provider was successfully updated', {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });

          await refetchProviders();

          dispatch(setShowProviderDrawer(false));
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    []
  );

  return {
    // Store
    showProviderDrawer,
    selectedProviderData,
    showDeleteProviderModal,
    showCancelProviderDrawer,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    setSelectedProvider,
    setShowProviderDrawer,
    setShowDeleteProviderModal,
    setShowCancelProviderDrawer,
    handleCreateCancel,
    handleCancelChanges,
    handleProviderRowClick,
    handleCancelTakeMeBack,
    handleSubmitGeneralForm,
    handleDeleteConfirmation,
  };
};
