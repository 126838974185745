import React from 'react';
import { Button, Stack } from '@mui/material';

// Styles
import Styles from './SetupProviderDrawerFooter.module.scss';

// Types
type Props = {
  saveDisabled?: boolean;
  onSaveClick?: () => void;
  cancelDisabled?: boolean;
  onCancelClick?: () => void;
  onDeleteClick?: () => void;
};

export const SetupProviderDrawerFooter: React.FC<Props> = ({
  cancelDisabled = false,
  onCancelClick,
  saveDisabled = false,
  onSaveClick,
  onDeleteClick,
}) => {
  return (
    <Stack className={Styles.SaveSettingsButtonPanel}>
      <Button type="submit" color="error" onClick={onDeleteClick}>
        Delete
      </Button>
      <Stack className={Styles.SaveCancelContainer}>
        <Button
          className={Styles.SaveCTA}
          type="submit"
          disabled={saveDisabled}
          onClick={onSaveClick}
        >
          Save settings
        </Button>
        <Button
          className={Styles.CancelCTA}
          variant="outlined"
          disabled={cancelDisabled}
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
