import { useCallback, useContext } from 'react';
import { DrawingManager, Polygon } from '@react-google-maps/api';

import { EditStatus } from '@contracts/common/EditStatus';
import { MapZoneType } from '@contracts/domain/keys';
import { getPathType } from '@utils/map/geoJSON';

import { mapZoneTypeColors } from '../../shared/MapZoneType/useMapZoneType';
import { SetupMapZoneContext } from '../SetupMapZoneContext';

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  holeColor: string;
  fillOpacity: number;
};

const PolygonView: React.FC<PolygonProps> = ({
  targetMapZoneType,
  strokeColor,
  fillColor,
  holeColor,
  fillOpacity,
}) => {
  const { mapPaths } = useContext(SetupMapZoneContext);

  const filteredMapPathes = mapPaths
    .map((zone, index) => [zone, index] as const)
    .filter(
      ([zone]) => zone.mapZoneType === targetMapZoneType && zone.editStatus !== EditStatus.Deleted
    );

  if (filteredMapPathes.length === 0) {
    return null;
  }

  // if (activeMapZoneType !== 'ALL' && activeMapZoneType !== targetMapZoneType) {
  //   return null;
  // }

  return (
    <>
      {filteredMapPathes.map(([mapPath, index]) => (
        <Polygon
          key={index}
          paths={mapPath.path}
          options={{
            ...polygonOptions,
            fillColor: mapPath.type === 'ring' ? fillColor : holeColor,
            strokeColor,
            fillOpacity,
          }}
        />
      ))}
    </>
  );
};

const Drawing: React.FC<{ zoneType: MapZoneType; onFinish?: (index: number) => void }> = ({
  zoneType,
  onFinish,
}) => {
  const { mapPaths, setMapPaths } = useContext(SetupMapZoneContext);

  const color = mapZoneTypeColors.find(({ type }) => type === zoneType);

  const onPolygonComplete = useCallback(
    (polygon: google.maps.Polygon) => {
      const path = polygon
        .getPath()
        .getArray()
        .map((p) => p.toJSON());

      const direction = getPathType(path);
      if (direction === 'ring') {
        path.reverse();
      }
      polygon.setMap(null);
      if (path.length < 3) {
        return;
      }
      setMapPaths([
        ...mapPaths,
        { editStatus: EditStatus.Added, path, type: 'hole', mapZoneType: zoneType, id: '' },
      ]);
      onFinish?.(mapPaths.length);
    },
    [setMapPaths, mapPaths, zoneType, onFinish]
  );

  if (color === undefined) {
    return null;
  }

  return (
    <DrawingManager
      onPolygonComplete={onPolygonComplete}
      options={{
        drawingControl: true,
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          fillOpacity: color.opacity,
          strokeColor: color.color,
          fillColor: color.holeColor,
          strokeOpacity: 1,
          strokeWeight: 1,
          editable: false,
          draggable: false,
          clickable: false,
        },
      }}
    />
  );
};

export const MapCutMode: React.FC<{ onFinish?: (index: number) => void }> = ({ onFinish }) => {
  return (
    <>
      {mapZoneTypeColors.map((zoneType) => (
        <PolygonView
          key={zoneType.type}
          targetMapZoneType={zoneType.type}
          strokeColor={zoneType.color}
          fillColor={zoneType.fillColor ?? zoneType.color}
          holeColor={zoneType.holeColor ?? zoneType.color}
          fillOpacity={zoneType.opacity}
        />
      ))}
      {/* {activeMapZoneType !== 'ALL' && <Drawing zoneType={activeMapZoneType} onFinish={onFinish} />} */}
    </>
  );
};
