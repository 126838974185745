import { useCallback } from 'react';

// System
import { useSnackbar } from 'notistack';

// Redux
import { useDispatch, useSelector } from 'react-redux';

import {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  resetSelectedFilters,
} from '@Modal/reducers/branches/branchTableSlice';
import { fetchBranches, setLoadingBranchContent } from '@Modal/reducers/branches/branchSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useBranches } from '../branches/useBranch';
import { FilterItemType } from '@Modal/reducers/types';
import { Paginable } from '@contracts/common/traits/Paginable';

export const useBranchesTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const { rowsPerPage, columnVisibility, columnVisibilityOptions, page, selectedFilters } =
    useSelector((state: StoreInterface) => state?.branchesTable);

  const { activeBrandId, loadingContent } = useBranches();
  const { branches, setPagination } = useBranches();

  const secondLevelBranches = branches.filter((va) => va?.parentId === activeBrandId);
  const secondLevelBranchIDs = secondLevelBranches.map((va) => va.id);

  const thirdLevelBranches = branches.filter(
    (va) => va.level === 3 && secondLevelBranchIDs.includes(va.parentId)
  );

  const babyBranches = [...secondLevelBranches, ...thirdLevelBranches];

  const fetchBranchesTableContent = useCallback(() => {
    dispatch(setLoadingBranchContent(true));
    dispatch(fetchBranches({ selectedFilters, brandId: activeBrandId }))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error.message, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, [selectedFilters, activeBrandId]);

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setSelectedFilters(filter));
  };

  const handleTablePageChange = (filter: FilterItemType, pagination: Paginable) => {
    dispatch(setSelectedFilters(filter));
    dispatch(setPagination(pagination));
  };

  const handleResetFilters = () => {
    dispatch(resetSelectedFilters());
  };

  return {
    // State
    page,
    branches,
    rowsPerPage,
    babyBranches,
    loadingContent,
    selectedFilters,
    columnVisibility,
    columnVisibilityOptions,
    // Actions
    setPage,
    setRowsPerPage,
    setSelectedFilters,
    handleFilterSelect,
    handleResetFilters,
    handleTablePageChange,
    fetchBranchesTableContent,
  };
};
