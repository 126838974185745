import { Box, Popover } from '@mui/material';
import { MouseEventHandler, ReactNode, useCallback, useContext, useMemo } from 'react';
import { ActionWithPopoverContext } from './ActionWithPopoverContext';

type Props = {
  name: string;
  content: JSX.Element;
  children: (onClick?: MouseEventHandler<HTMLButtonElement>, id?: string) => ReactNode;
};

export const ActionWithPopoverContent: React.FC<Props> = ({ name, content, children }) => {
  const [anchorEl, setAnchorEl] = useContext(ActionWithPopoverContext);

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const id = useMemo(() => (open ? name : undefined), [open, name]);

  return (
    <>
      {children(handleClick, id)}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        data-at-popover="action"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: 1 }}>{content}</Box>
      </Popover>
    </>
  );
};
