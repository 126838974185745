import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ResetRolePasswordFormContext,
  ResetRolePasswordFormState,
  ResetRolePasswordFormStateDefault,
  ResetRolePasswordSchemaDefault,
} from '@Forms/roles/ResetRolePasswordFormContext';

export const ResetRolePasswordFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<ResetRolePasswordFormState>>>(
    ResetRolePasswordSchemaDefault
  );
  const methods = useForm({
    defaultValues: ResetRolePasswordFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <ResetRolePasswordFormContext.Provider value={value}>
      {children}
    </ResetRolePasswordFormContext.Provider>
  );
};
