import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import {
  fetchBrands,
  setPagination,
  setSelectedBrand,
  setSelectedBranch,
  setShowUpdateStatusModal,
  fetchAllBranches,
  setCloseUpdateStatusModal,
} from '../../reducers/branches/branchSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

export const useBranches = () => {
  const {
    brands,
    branches,
    allBranches,
    pagination,
    selectedBrand,
    selectedBranch,
    branchChanged,
    activeBrandId,
    branchStatuses,
    selectedStatus,
    loadingContent,
    showUpdateStatusModal,
  } = useSelector((state: StoreInterface) => state?.branchReducer);

  const dispatch = useDispatch<AppDispatch>();

  const handleFetchBrands = () => {
    return dispatch(fetchBrands());
  };

  const handleFetchAllBranches = () => {
    return dispatch(fetchAllBranches());
  };

  return {
    brands,
    branches,
    allBranches,
    pagination,
    setPagination,
    selectedBrand,
    branchChanged,
    activeBrandId,
    selectedBranch,
    branchStatuses,
    selectedStatus,
    loadingContent,
    setSelectedBrand,
    setSelectedBranch,
    handleFetchBrands,
    showUpdateStatusModal,
    handleFetchAllBranches,
    setShowUpdateStatusModal,
    setCloseUpdateStatusModal,
  };
};
