import { RadioCheckedIcon, RadioIcon } from '@Components/theme/overrides/CustomIcons';
import { Button, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = {
  text: string;
  sx?: SystemStyleObject<Theme>;
  checked: boolean;
};

export const RadioTab: React.FC<Props> = ({ checked, text, sx }) => {
  const Icon = checked ? RadioCheckedIcon : RadioIcon;

  return (
    <Button variant="outlined" color={checked ? 'primary' : 'inherit'} startIcon={<Icon />} sx={sx}>
      {text}
    </Button>
  );
};
