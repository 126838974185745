import React from 'react';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { CreateVehicleForm } from './CreateVehicleForm';
import { useCreateVehicle } from '@Modal/hooks/vehicles/vehicles/useCreateVehicle';

// Styles
import Styles from './CreateVehicleContent.module.scss';

export const CreateVehicleModalDialog = () => {
  const { showCreateVehicleForm, handleCreateCancel } = useCreateVehicle();

  return (
    <>
      {showCreateVehicleForm && (
        <ClosableModal
          name="CreateVehicle"
          title="Add vehicle"
          open={showCreateVehicleForm}
          onClose={handleCreateCancel}
          className={Styles.CreateVehicleModalDialog}
        >
          <CreateVehicleForm />
        </ClosableModal>
      )}
    </>
  );
};
