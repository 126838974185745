import { RHFTextField } from '@Components/hook-form';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { Theme } from '@emotion/react';
import { InputAdornment } from '@mui/material';
import { Box, SystemStyleObject } from '@mui/system';
import { ComponentProps, useState } from 'react';
import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

type Props = Omit<ComponentProps<typeof RHFTextField>, 'sx'> & {
  sx?: SystemStyleObject<Theme>;
  dotPlaceholder?: {
    amount: number;
  };
};

export const ShowablePasswordInputField: React.FC<Props> = ({
  sx,
  name,
  InputLabelProps,
  inputProps,
  placeholder,
  dotPlaceholder,
  label,
  helperText,
  onChange,
  ...other
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { control } = useFormContext();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          name={name}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ cursor: 'pointer' }} onClick={handleTogglePasswordVisibility}>
                  <AssetIcon
                    src={showPassword ? 'eye-off' : 'eye'}
                    size="xxxl"
                    sx={{ color: 'text.primary' }}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
