// System
import React from 'react';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { NoTableContentLayout } from '@Components/no-content-layout/NoTableContentLayout/NoTableContentLayout';

// Hooks
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Redux
import {
  setShowCreateOperatorNotes,
  setShowOperatorVehicleNotes,
} from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { OperatorNoteCard } from '@Components/operator/OperatorNoteCard';

// Styles
import Styles from './SetupOperatorNotesDrawer.module.scss';

export const SetupOperatorNotesDrawer: React.FC = () => {
  const { vehicleNotes, showOperatorVehicleNotes } = useOperatorVehicles();

  const dispatch = useDispatch<AppDispatch>();

  return (
    <SetupDrawer
      name="SetupOperatorNotesDrawer"
      open={showOperatorVehicleNotes}
      onClose={() => dispatch(setShowOperatorVehicleNotes(false))}
      anchor="bottom"
    >
      <Box className={Styles.SetupOperatorNotesDrawer}>
        <Box className={Styles.HeaderContainer}>
          <Typography fontSize={18} fontWeight={900}>
            Notes
          </Typography>
          <Box
            className={Styles.CloseIcon}
            onClick={() => dispatch(setShowOperatorVehicleNotes(false))}
          >
            <AssetIcon src="x-close" size="xxxxl" />
          </Box>
        </Box>
        <Divider />
        <Box>
          {vehicleNotes?.length > 0 ? (
            <Box className={Styles.NotesContainer}>
              {vehicleNotes.map((note) => {
                return <OperatorNoteCard key={note?.id} note={note} />;
              })}
            </Box>
          ) : (
            <NoTableContentLayout
              title="No notes available"
              subTitle="It appears that there are no notes available at the moment. You can create notes anytime"
            />
          )}
        </Box>
        <Box className={Styles.Footer}>
          <Button
            onClick={() => dispatch(setShowCreateOperatorNotes(true))}
            className={Styles.AddNoteCTA}
            variant="contained"
          >
            Add Note
          </Button>
        </Box>
      </Box>
    </SetupDrawer>
  );
};
