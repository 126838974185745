// System
import { Button, Typography } from '@mui/material';

// Components
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';
import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';

// Redux
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';

export const ConfirmTransactionRefundModalDialog = () => {
  const {
    showConfirmTransactionRefundModalDialog,
    handleConfirmTransactionRefundModalDialog,
    handleCreateTransactionRefund,
  } = useTransactionsDrawer();

  return (
    <>
      <ClosableModal
        name="confirmTransactionRefund"
        title="Refund"
        open={showConfirmTransactionRefundModalDialog}
        onClose={() => handleConfirmTransactionRefundModalDialog(false)}
        sx={{ width: '592px' }}
      >
        <CreateDialogLayout>
          <Typography>Are you sure you want to refund this transaction?</Typography>
          <CreateDialogFooterLayout>
            <AtLocator type="button" name="Cancel">
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => handleConfirmTransactionRefundModalDialog(false)}
              >
                Cancel
              </Button>
            </AtLocator>
            <AtLocator type="button" name="Submit">
              <Button
                type="submit"
                variant="contained"
                onClick={handleCreateTransactionRefund}
              >
                Confirm
              </Button>
            </AtLocator>
          </CreateDialogFooterLayout>
        </CreateDialogLayout>
      </ClosableModal>
    </>
  );
};
