import { ColorCircleLabel } from '@Components/shared/labels/ColorCircleLabel';
import { IotStatus } from '@contracts/domain/keys';

import { useIotStatusColor, useIotStatusTitle } from './useIotStatus';

type Props = { status?: IotStatus };

export const IotStatusLabel: React.FC<Props> = ({ status }) => {
  const color = useIotStatusColor()[status ?? 'unknown'];
  const title = useIotStatusTitle()[status ?? 'unknown'];

  return <ColorCircleLabel color={color} title={title} variant="ghost" />;
};
