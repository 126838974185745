import { useSendCommandDialog } from '@Features/providers/store/dialogs/useSendCommandDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { BaseSyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema, number, object } from 'yup';

const validationErrorMessage = 'The maximum speed must be between 0 and 35km';
const schema: ObjectSchema<{ maxSpeed: number }> = object<{ maxSpeed: number }>().shape({
  maxSpeed: number().required().max(35, validationErrorMessage).typeError(validationErrorMessage),
});

export const useSetupVehicleMaxSpeed = () => {
  const { enqueueSnackbar } = useSnackbar();
  // @TODO: Refactor this using FSD approach
  // const sendCommand = useSendCommand((state) => state.post);
  // const vehicleIotImei = useGetVehicle((state) => state.data?.iotImei);
  const [openDialogName, handleCloseDialog] = useSendCommandDialog((state) => [
    state.openDialogName,
    state.closeDialog,
  ]);

  const methods = useForm({
    defaultValues: { maxSpeed: undefined },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    methods.reset({ maxSpeed: undefined });
    handleCloseDialog();
  };

  const handleConfirm = (e: BaseSyntheticEvent) => {
    methods.handleSubmit(async ({ maxSpeed }) => {
      methods.reset({ maxSpeed: undefined });
      handleClose();
      try {
        // await sendCommand({
        //   command: 'set_max_speed',
        //   imei: vehicleIotImei ?? null,
        //   maxSpeed,
        // });
        enqueueSnackbar('Command has been successfully executed', {
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      } catch (error) {
        enqueueSnackbar('Command was not executed.', {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    })(e);
  };

  return {
    openDialogName,
    methods,
    handleClose,
    handleConfirm,
  };
};
