import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { useTabs } from '@Components/shared/hooks/useTabs';
import { TabEx } from '@Components/shared/selects/TabEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabsDrawerLayout } from '@Components/shared/layouts/TabsDrawerLayout';

import { NoMovement } from './tabs/NoMovement';
import { Commands } from './tabs/Commands';
import { RentalRequirements } from './tabs/RentalRequirements';
import { Details } from './tabs/Details';

const useSx = () => {
  return {
    panel: { padding: 0, height: '100%' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupVehicleCategoryContent: React.FC = () => {
  const { currentTab, handleSwithTab } = useTabs('1');
  const styles = useSx();

  return (
    <TabContext value={currentTab}>
      <TabsDrawerLayout
        header={
          <AtLocator type="tab-list" name="SetupVehicleBranch">
            <TabList onChange={handleSwithTab} aria-label="Information tabs">
              <TabEx label="Details" value="1" />
              <TabEx label="Commands" value="2" />
              <TabEx label="Rental requirements" value="3" />
              <TabEx label="No movement" value="4" />
            </TabList>
          </AtLocator>
        }
      >
        <TabPanel value="1" sx={styles.panel}>
          <Details />
        </TabPanel>
        <TabPanel value="2" sx={styles.panel}>
          <Commands />
        </TabPanel>
        <TabPanel value="3" sx={styles.panel}>
          <RentalRequirements />
        </TabPanel>
        <TabPanel value="4" sx={styles.panel}>
          <NoMovement />
        </TabPanel>
      </TabsDrawerLayout>
    </TabContext>
  );
};
