import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupCustomerContent } from './SetupCustomerContent';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

export const SetupCustomerDrawer: React.FC = () => {
  const { showCustomerDrawer, handleCreateCancel } = useCustomersDrawer();

  return (
    <SetupDrawer name="SetupCustomer" open={showCustomerDrawer} onClose={handleCreateCancel}>
      <SetupCustomerContent />
    </SetupDrawer>
  );
};
