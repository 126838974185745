import { BaseSyntheticEvent, useMemo } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowCreateRoundConfigForm,
  setShowCancelRoundConfigForm,
} from '@Modal/reducers/rounds/createRoundConfig/CreateRoundConfigSlice';

// Form
import { useFormState } from 'react-hook-form';
import { useCreateRoundConfigForm } from '@Forms/rounds/CreateRoundConfigFormContext';

// System
import { useSnackbar } from 'notistack';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useCreateAviatorRoundConfigsMutation } from '@Src/generated.graphql';

export const useCreateRoundConfig = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const [createRoundConfig] = useCreateAviatorRoundConfigsMutation();

  const { showCancelRoundConfigForm, showCreateRoundConfigForm } = useSelector(
    (state: StoreInterface) => state?.createRoundConfig
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelRoundConfigForm(true));
    } else {
      return dispatch(setShowCreateRoundConfigForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelRoundConfigForm(false));
      dispatch(setShowCreateRoundConfigForm(true));
    } else {
      reset();
      dispatch(setShowCancelRoundConfigForm(false));
      dispatch(setShowCreateRoundConfigForm(false));
    }
  };

  // Form Controls
  const { control, handleSubmit, reset } = useCreateRoundConfigForm();
  const { isDirty, errors } = useFormState({ control });

  const handleSubmitCreateRoundConfig = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        try {
          await createRoundConfig({
            // @ts-ignore
            variables: { input: formData },
          });

          enqueueSnackbar('Round config was successfully created', {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });

          reset();
          dispatch(setShowCreateRoundConfigForm(false));
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      })(e);
    },
    [createRoundConfig]
  );

  return {
    // Store
    showCancelRoundConfigForm,
    showCreateRoundConfigForm,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    handleCancelTakeMeBack,
    setShowCreateRoundConfigForm,
    handleSubmitCreateRoundConfig,
  };
};
