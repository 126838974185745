import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import { ManageVehicleIotType, UpdateVehicleType, UpdateVehicleServiceStateType } from '../types';
import { SendVehicleDashboardCommandDTO } from '../iot/dto';

// GET
export const getSelectedVehicle = createAsyncThunk(
  'vehicle/getSelectedVehicle',
  async (vehicleID: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/vehicles/${vehicleID}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// PUT
export const updateVehicle = createAsyncThunk(
  'vehicle/updateVehicleData',
  async (vehicleData: UpdateVehicleType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/vehicles/${vehicleData.vehicleID}`, {
        ...vehicleData.vehicleFormState,
        referrer: 'backoffice',
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const updateVehicleServiceState = createAsyncThunk(
  'vehicle/updateVehicleServiceState',
  async (vehicleData: UpdateVehicleServiceStateType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/vehicles/${vehicleData.vehicleID}/service-state`, {
        serviceState: vehicleData.vehicleServiceState,
        referrer: 'backoffice',
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const manageVehicleIot = createAsyncThunk(
  'vehicle/manageVehicleIot',
  async (vehicleIotData: ManageVehicleIotType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/vehicles/${vehicleIotData.vehicleID}/manage-iot`, {
        ...vehicleIotData.vehicleFormState,
      })
      .then(() => {
        const operationType = vehicleIotData.vehicleFormState.operationType;
        return thunkApi.fulfillWithValue({
          imei: operationType == 'unlink' ? null : vehicleIotData.vehicleFormState.imei,
        });
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST IoT Command
export const postIoTDashboardCommand = createAsyncThunk(
  'operator/postOperatorIoTCommand',
  async (sendCommandDTO: SendVehicleDashboardCommandDTO, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post(`/v1/iot/send-command`, {
        ...sendCommandDTO,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showVehicleDrawer: false,
  showCancelVehicleDrawer: false,
  selectedVehicleData: {},
  selectedVehicleServiceState: null,
  iotFormShown: false,
  iotCancelShown: false,
  iotDeleteFormShown: false,
};

const vehiclesDrawerSlice = createSlice({
  name: 'vehicleDrawer',
  initialState,
  reducers: {
    setShowVehicleDrawer: (state, { payload }) => {
      state.showVehicleDrawer = payload;
    },
    setSelectedVehicleServiceState: (state, { payload }) => {
      state.selectedVehicleServiceState = payload;
    },
    setShowCancelVehicleDrawer: (state, { payload }) => {
      state.showCancelVehicleDrawer = payload;
    },
    setSelectedVehicle: (state, { payload }) => {
      state.selectedVehicleData = payload;
    },
    setShowIOTForm: (state, { payload }) => {
      state.iotFormShown = payload;
    },
    setShowIOTCancel: (state, { payload }) => {
      state.iotCancelShown = payload;
    },
    setShowIOTDelete: (state, { payload }) => {
      state.iotDeleteFormShown = payload;
    },
  },
  extraReducers: {
    [getSelectedVehicle.fulfilled?.type]: (state, action) => {
      state.showVehicleDrawer = true;
      state.selectedVehicleData = action.payload;
      state.selectedVehicleServiceState = action.payload.serviceState;
    },
    [updateVehicle.fulfilled?.type]: (state, action) => {
      state.selectedVehicleData = { ...state?.selectedVehicleData, ...action.payload };
      state.showVehicleDrawer = false;
    },
    [manageVehicleIot.fulfilled?.type]: (state, action) => {
      state.selectedVehicleData = { ...state?.selectedVehicleData, ...action.payload };
      state.showVehicleDrawer = false;
      state.iotDeleteFormShown = false;
      state.iotFormShown = false;
    },
  },
});

export const { reducer: vehiclesDrawerReducer } = vehiclesDrawerSlice;
export const {
  setSelectedVehicleServiceState,
  setShowCancelVehicleDrawer,
  setShowVehicleDrawer,
  setShowIOTForm,
  setShowIOTCancel,
  setShowIOTDelete,
} = vehiclesDrawerSlice.actions;
