import { Button, Stack } from '@mui/material';
import React from 'react';
import Styles from './ConfirmButtonPanel.module.scss';
import { useTransactionsDrawer } from '@Modal/hooks/transactions/useTransactionDrawer';

type Props = {
  saveDisabled?: boolean;
  onSaveClick?: () => void;
  cancelDisabled?: boolean;
  onCancelClick?: () => void;
};

export const ConfirmButtonPanel: React.FC<Props> = ({ onSaveClick }) => {
  const { selectedTransactionData } = useTransactionsDrawer();
  return (
    <Stack className={Styles.ConfirmButtonPanel}>
      {selectedTransactionData.status !== 'REJECTED' && (
        <Button variant="contained" type="submit" onClick={onSaveClick} className={Styles.Button}>
          Refund
        </Button>
      )}
    </Stack>
  );
};
