type Options = {
  group?: string;
  numerals: string[];
};

const defaultOptions: Options = {
  numerals: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

type ParseOptions = {
  sign?: boolean;
  group?: boolean;
};

const defaulParseOptions: ParseOptions = {
  sign: true,
};

const toRegExpChar = (char?: string): string => {
  return /\s/.test(char ?? '') ? '\\s' : char || '';
};

export const correctIntegerString = (
  parseOptions: ParseOptions = defaulParseOptions,
  options: Options = defaultOptions
) => {
  const symbols = [
    ...options.numerals,
    ...(parseOptions.group && options.group ? [options.group] : []),
    ...(parseOptions.sign ? ['+-'] : []),
  ].map((s) => toRegExpChar(s));
  const correctRegExp = new RegExp(`[^${symbols.join('')}]`, 'g');

  return (text: string) => text.replace(correctRegExp, '');
};
