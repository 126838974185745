import { FullScreenClosableModal } from '@Components/shared/dialogs/FullScreenClosableModal';

import { SetupMapZoneHeader } from './SetupMapZoneHeader';
import { SetupMapZoneContent } from './SetupMapZoneContent';
import { SetupMapZoneFooter } from './SetupMapZoneFooter';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useDispatch } from 'react-redux';

export const SetupMapZoneModalDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { showMapZoneDialog, isUpdateCTAActive, setShowCancelMapZoneDialog, setShowMapZoneDialog } =
    useBranchesMaps();

  const handleCancelMapZoneDialog = () => {
    if (!isUpdateCTAActive()) {
      dispatch(setShowCancelMapZoneDialog(!isUpdateCTAActive()));
    } else {
      dispatch(setShowMapZoneDialog(false));
    }
  };

  return (
    <FullScreenClosableModal
      header={<SetupMapZoneHeader />}
      footer={<SetupMapZoneFooter />}
      open={showMapZoneDialog}
      name="SetupMapZone"
      onClose={handleCancelMapZoneDialog}
    >
      <SetupMapZoneContent />
    </FullScreenClosableModal>
  );
};
