import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// Types
import { unblockBlockCustomerPropsType } from '../types';

// Put (update customer status)
export const blockUnblockCustomerRequest = createAsyncThunk(
  'customer/unblockCustomerRequest',
  async (props: unblockBlockCustomerPropsType, thunkApi) => {
    const requestBody = {
      status: props.status,
    };
    return axiosCustomerManagementInstance
      .put(`/customers/${props.id}/status`, requestBody)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const initialState = {
  showBlockCustomerModalDialog: false,
  showUnblockCustomerModalDialog: false,
  forceBlock: false,
  isBlocked: false,
};

const blockUnblockCustomerSlice = createSlice({
  name: 'blockCustomer',
  initialState,
  reducers: {
    setShowBlockCustomerModalDialog: (state, { payload }) => {
      state.showBlockCustomerModalDialog = payload;
    },
    setShowUnblockCustomerModalDialog: (state, { payload }) => {
      state.showUnblockCustomerModalDialog = payload;
    },
    setForceBlock: (state, { payload }) => {
      state.forceBlock = payload;
    },
    setIsBlocked: (state, { payload }) => {
      state.isBlocked = payload;
    },
  },
  extraReducers: {
    [blockUnblockCustomerRequest.fulfilled?.type]: (state) => {
      state.showBlockCustomerModalDialog = false;
    },
  },
});

export const { reducer: blockUnblockCustomerReducer } = blockUnblockCustomerSlice;
export const {
  setShowBlockCustomerModalDialog,
  setForceBlock,
  setIsBlocked,
  setShowUnblockCustomerModalDialog,
} = blockUnblockCustomerSlice.actions;
