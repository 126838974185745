import { CustomerStatus } from '@contracts/domain/keys';
import { KeyValue } from '@contracts/view/KeyValue';
import { useTheme } from '@mui/material';
import { CSSProperties, useMemo } from 'react';

export const useCustomerStatusColor = (): Record<CustomerStatus, CSSProperties['color']> => {
  const { palette } = useTheme();
  return useMemo(
    () => ({
      active: palette.success.main,
      blocked: palette.grey[600],
      deleted: palette.error.main,
      deleteRequest: palette.error.dark,
    }),
    [palette]
  );
};

export const useCustomerStatusTitle = (): Record<CustomerStatus, string> => {
  return useMemo(
    () => ({
      active: 'Active',
      blocked: 'Blocked',
      deleted: 'Deleted',
      deleteRequest: 'Delete Request',
    }),
    []
  );
};

export const useCustomerStatusOptions = (): KeyValue<CustomerStatus, string>[] => {
  const titles = useCustomerStatusTitle();
  return useMemo(
    () => (Object.keys(titles) as CustomerStatus[]).map((key) => ({ key, value: titles[key] })),
    [titles]
  );
};
