// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useMemo } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { useVehiclesDrawer } from '../vehicles/useVehicleDrawer';

// Actions
import {
  setVehicleNotes,
  getVehicleNotes,
  setSelectedNoteId,
  createVehicleNote,
  deleteVehicleNote,
  setShowCreateVehicleNote,
  setShowDeleteVehicleNote,
  setShowCancelVehicleNote,
} from '@Modal/reducers/vehicles/notes/vehicleNotesSlice';

// Forms
import { useCreateVehicleNoteForm } from '@Forms/vehicles/CreateVehicleNoteFormContext';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import Cookies from 'js-cookie';

export const useVehicleNotes = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { vehicleNotes, showCreateVehicleNote, showDeleteVehicleNote, selectedNoteId } =
    useSelector((state: StoreInterface) => state?.vehicleNotes);

  const { selectedVehicleData } = useVehiclesDrawer();
  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Form
  const { control, handleSubmit, reset } = useCreateVehicleNoteForm();
  const { isDirty, errors } = useFormState({ control });

  const handleGetVehicleNotes = () => {
    if (selectedVehicleData) {
      dispatch(getVehicleNotes(selectedVehicleData.id))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error?.message, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  };

  // Note Form Handlers
  const handleCreateNoteForm = (status: boolean) => dispatch(setShowCreateVehicleNote(status));
  const handleDeleteNoteForm = (status: boolean) => dispatch(setShowDeleteVehicleNote(status));

  const cookieVal = Cookies.get('currentUser');
  const currentUser = cookieVal ? JSON.parse(cookieVal) : null;

  const handleSubmitVehicleNote = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          createVehicleNote({
            id: selectedVehicleData.id,
            newNote: { ...formData, authorId: currentUser?.id },
          })
        )
          .unwrap()
          .then(() => reset())
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    [selectedVehicleData]
  );

  const deleteVehicleNoteHandler = () => {
    return dispatch(deleteVehicleNote(selectedNoteId))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  // Handle Form Cancels
  const handleCreateCancel = () => {
    return dispatch(setShowCreateVehicleNote(false));
  };

  // Handle Select NoteID
  const handleVehicleNoteClick = (id: string) => {
    dispatch(setShowCreateVehicleNote(true));
    dispatch(setSelectedNoteId(id));
  };

  const handleDeleteNoteClick = (id: string | undefined) => {
    dispatch(setShowDeleteVehicleNote(true));
    dispatch(setSelectedNoteId(id));
  };

  return {
    // Store
    vehicleNotes,
    showCreateVehicleNote,
    showDeleteVehicleNote,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    setVehicleNotes,
    handleCreateCancel,
    handleCreateNoteForm,
    handleDeleteNoteForm,
    handleDeleteNoteClick,
    handleGetVehicleNotes,
    handleVehicleNoteClick,
    handleSubmitVehicleNote,
    deleteVehicleNoteHandler,
  };
};
