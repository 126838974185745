// System
import { Stack } from '@mui/material';

// Redux
import { RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const SetupProviderDetails: React.FC = () => {
  return (
    <Stack direction="column" spacing={2} margin={2}>
      <AtLocator type="text-field" name="name" key="name">
        <RHFTextField name="name" label="Name" />
      </AtLocator>
      <AtLocator type="text-field" name="apiUrl" key="apiUrl">
        <RHFTextField name="apiUrl" label="Api Url" />
      </AtLocator>
      <AtLocator type="text-field" name="maxWin" key="maxWin">
        <RHFTextField name="maxWin" label="Maximum Win" />
      </AtLocator>
      <AtLocator type="text-field" name="minBet" key="minBet">
        <RHFTextField name="minBet" label="Minimum Bet" />
      </AtLocator>
      <AtLocator type="text-field" name="maxBet" key="maxBet">
        <RHFTextField name="maxBet" label="Maximum Bet" />
      </AtLocator>
      <AtLocator type="text-field" name="betSuggestions" key="betSuggestions">
        <RHFTextField name="betSuggestions" label="Bet Suggestions" />
      </AtLocator>
      <AtLocator type="text-field" name="minAutoCashout" key="minAutoCashout">
        <RHFTextField name="minAutoCashout" label="Minimium Auto Cashout" />
      </AtLocator>
      <AtLocator type="text-field" name="maxAutoCashout" key="maxAutoCashout">
        <RHFTextField name="maxAutoCashout" label="Maximum Auto Cashout" />
      </AtLocator>
      <AtLocator type="text-field" name="defaultAutoCashout" key="defaultAutoCashout">
        <RHFTextField name="defaultAutoCashout" label="Default Auto Cashout" />
      </AtLocator>
      <AtLocator type="text-field" name="language" key="language">
        <RHFTextField name="language" label="Language" />
      </AtLocator>
      <AtLocator type="text-field" name="currency" key="currency">
        <RHFTextField name="currency" label="Currency" />
      </AtLocator>
      <AtLocator type="text-field" name="publicKey" key="publicKey">
        <RHFTextField name="publicKey" label="Public Key" />
      </AtLocator>
      <AtLocator type="text-field" name="privateKey" key="privateKey">
        <RHFTextField name="privateKey" label="Private Key" />
      </AtLocator>
    </Stack>
  );
};
