// System
import { Box } from '@mui/system';

// Features
import { Divider } from '@mui/material';
import { ProviderList } from '@Features/provider/ProvidersList';
import { ProvidersTitle } from '@Features/provider/ProvidersTitle/ProvidersTitle';

export const ProvidersPage = () => {
  return (
    <Box>
      <Box>
        <ProvidersTitle />
        <Divider />
        <ProviderList />
      </Box>
    </Box>
  );
};
