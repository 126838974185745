
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { CreateBrandForm } from './CreateBrandForm';
import { useCreateBrand } from '@Modal/hooks/branches/useCreateBrand';

export const CreateBrandModalDialog: React.FC = () => {
  const { handleCreateCancel, showCreateBrandForm } = useCreateBrand();
  return (
    <>
      {showCreateBrandForm && (
        <ClosableModal name="CreateBrand" title="Create brand" open={showCreateBrandForm} onClose={handleCreateCancel}>
          <CreateBrandForm />
        </ClosableModal>
      )}
    </>
  );
};
