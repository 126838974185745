// System
import { Stack, Typography, Button } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Styles
import Styles from './NoTableContentLayout.module.scss';

type Props = {
  title: string;
  subTitle: string;
  ctaTxt?: string;
  ctaClick?: () => void;
};

export const NoTableContentLayout: React.FC<Props> = ({ title, subTitle, ctaTxt, ctaClick }) => {
  return (
    <Stack className={Styles.NoTableContentLayout}>
      <AssetIcon src="ic_noTableContent" className={Styles.Icon} />
      <Typography variant={'h3'}>{title}</Typography>
      <Typography variant={'body1'} className={Styles.Text}>
        {subTitle}
      </Typography>
      {ctaTxt ? (
        <Button type="submit" variant="contained" className={Styles.Btn} onClick={ctaClick}>
          <Typography className={Styles.BtnText}>{ctaTxt}</Typography>
        </Button>
      ) : null}
    </Stack>
  );
};
