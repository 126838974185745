// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useMemo } from 'react';

// Types
import { StoreInterface } from 'src/app/types';

// Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  setShowAddUserModalDialog,
  setShowCancelAddUserModalDialog,
} from '@Modal/reducers/roles/addUserSlice';
import { useRolesTable } from './useRolesTable';
import { addUser } from '@Modal/reducers/roles/addUserSlice';

// Form
import { AppDispatch } from 'src/app/store';
import { useSetupAddUserForm } from '@Forms/roles/SetupAddUserFormContext';

export const useAddUser = () => {
  // Redux
  const { showAddUserModalDialog, showCancelAddUserModalDialog } = useSelector(
    (state: StoreInterface) => state?.addUser
  );

  const { fetchRolesTableContent } = useRolesTable();

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, handleSubmit, reset, getValues } = useSetupAddUserForm();
  const { isDirty, errors, touchedFields } = useFormState({ control });

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelAddUserModalDialog(true));
    } else {
      return dispatch(setShowAddUserModalDialog(false));
    }
  };

  const handleCancelTakeMeBack = () => {
    reset();
    dispatch(setShowCancelAddUserModalDialog(false));
    dispatch(setShowAddUserModalDialog(false));
  };

  const handleSubmitAddUser = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      e?.preventDefault();
      return handleSubmit(async (formData) => {
        dispatch(addUser(formData))
          .unwrap()
          .then(() => {
            dispatch(setShowAddUserModalDialog(false));
            fetchRolesTableContent();
            enqueueSnackbar('User is successfully created', {
              variant: 'success',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
            reset();
          })
          .catch((error: string) => {
            reset();
            enqueueSnackbar(error, {
              variant: 'error',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  return {
    // State
    submittable: isDirty && Object.values(errors).length == 0,
    showAddUserModalDialog,
    showCancelAddUserModalDialog,
    touchedFields,
    // Actions
    setShowAddUserModalDialog,
    setShowCancelAddUserModalDialog,
    handleCreateCancel,
    handleCancelTakeMeBack,
    handleSubmitAddUser,
  };
};
