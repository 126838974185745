import React from 'react';
import { Grid } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { RHFSwitch } from '@Components/hook-form';

export const CloseCommands = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={0} columns={8} justifyContent="flex-start">
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Stop">
          <RHFSwitch label="Stop" name="commands.close.stop" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="Refresh">
          <RHFSwitch label="Refresh" name="commands.close.refresh" />
        </AtLocator>
      </Grid>
      <Grid item xs={4}>
        <AtLocator type="special-field" name="AlarmOn">
          <RHFSwitch label="Alarm On" name="commands.close.alarmOn" />
        </AtLocator>
      </Grid>
    </Grid>
  );
};
