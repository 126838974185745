// System
import parseISO from 'date-fns/parseISO';
import { Box, Typography } from '@mui/material';

// Components
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { DateLabel } from '@Components/shared/labels/DateLabel';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';

// GraphQL
import { usePaginatedAviatorRoundTransactionsQuery } from '@Src/generated.graphql';

// Hook
import { useRoundDrawer } from '@Modal/hooks/rounds/useRoundDrawer';

// Styles
import Styles from './RoundTransactions.module.scss';

export const RoundTransactions = () => {
  const { selectedRoundData } = useRoundDrawer();
  const { data, loading } = usePaginatedAviatorRoundTransactionsQuery({
    variables: {
      limit: Number.MAX_SAFE_INTEGER,
      page: 1,
      filter: {
        roundId: selectedRoundData?._id,
      },
    },
  });

  return (
    <>
      {loading && <AssetIcon src="rental-loading" className={Styles.LoadingIcon} />}
      <Box className={Styles.RoundTransactions}>
        {data?.paginatedAviatorRoundTransactions?.docs?.map(
          ({ playerId, win, cashoutDate, betAmount }) => (
            <Box className={Styles.RoundTransactionContainer}>
              <Box sx={{ width: '140px' }}>
                <Typography variant="subtitle2">
                  {cashoutDate && (
                    <DateLabel date={new Date(parseISO(cashoutDate))} format="long" />
                  )}
                </Typography>
              </Box>
              <Box sx={{ width: '90px' }}>
                <TruncateText text={playerId} maxLength={6} />
              </Box>
              <Typography width="70px">Bet: {betAmount}</Typography>
              <Box className={win && win > 0 ? Styles.WinLabel : Styles.LooseLabel}>
                <Typography variant="subtitle2">{win && win > 0 ? 'Win' : 'Lose'}</Typography>
              </Box>
              <Typography width="70px" variant="subtitle2">
                {win}
              </Typography>
            </Box>
          )
        )}
      </Box>
    </>
  );
};
