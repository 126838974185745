import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { Stack } from '@mui/system';
import { FormProvider } from 'react-hook-form';
import { useSetupCustomerForm } from '@Forms/customers/SetupCustomerFormContext';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { PersonalInformation } from './PersonalInformation';
import { Contact } from './Contact';
import { Address } from './Address';
import { DeviceInfo } from './DeviceInfo';
import { AdditionalInfo } from './AdditionalInfo';
import { AddTag } from './AddTag';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

type ItemProps = {
  id: string;
  tag: string;
  createdAt: string;
};

export const Details: React.FC = () => {
  const { selectedCustomerData } = useCustomersDrawer();
  const { status } = selectedCustomerData;
  const methods = useSetupCustomerForm();
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    axiosCustomerManagementInstance
      .get('/Tags')
      .then((res) => {
        const mappedOptions = res.data.items.map(({ id, tag, createdAt }: ItemProps) => ({
          value: id,
          label: tag,
          createdAt,
        }));
        setOptions(mappedOptions);
      })
      .catch((error: string) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  }, []);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Stack direction="column" spacing={3}>
            <PersonalInformation status={(status ?? 'active') !== 'active'} />
            <Contact status={(status ?? 'active') !== 'active'} />
            {/* <AddTag tags={options} /> */}
            {/* <Address /> */}
            <AdditionalInfo />
            <DeviceInfo />
          </Stack>
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
