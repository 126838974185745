import React, { useCallback, useEffect, useMemo, useState } from 'react';

// System
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { Polygon, Marker } from '@react-google-maps/api';

// Utils
import { toGoogleMapsMultiPoligon } from '@utils/map/geoJSON';
import {
  generateBatteryteIcon,
  generateIotIcon,
  generateTxtColor,
  currentLocationPin,
} from '@Components/shared/maps/CustomMarkerIcons';
import {
  HashTable,
  // socket
} from '@utils/services/liveMapSocket';

// Components
import FilterCTA from '../OperatorMap/FilterCTA/FilterCTA';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';
import SearchBarOperator from '../OperatorMap/SearchBarOperator/SearchBarOperator';
import GoogleMarkersOperator from '@Components/GoogleMarkers/GoogleMarkersOperator';
import { MapOptionsOperator } from '@Components/shared/maps/GoogleMapsContainer/MapOptions';

// Contracts
import { MapZoneType } from '@contracts/domain/keys';
import { AllMapZoneType } from '@contracts/domain/extendedKeys';

// Features
import { SetupOperatorFiltersDrawer } from '@Features/operator/OperatorDrawers';
import { mapZoneTypeColors } from '@Features/branches/shared/MapZoneType/useMapZoneType';
import { GoogleMapsContainerMobile } from '@Components/shared/maps/GoogleMapsContainer/GoogleMapsContainerMobile';

// Hooks
import { useLiveMap } from '@Hooks/shared/useLiveMap';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { ScanOperator } from '../OperatorMap/ScanOperator/ScanOperator';
import { useVehicleMap } from '@Modal/hooks/vehicles/vehicles/useVehicleMap';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';

// Types
import { BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';
import { MapZoneItemType } from '@Modal/reducers/branches/types';
import { setAreLiveMapFiltersChecked } from '@Modal/reducers/vehicles/vehicles/vehicleMapSlice';

// Styles
const SearchFilterContainerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: '50px',
  display: 'flex',
  flexDirection: 'row',
  height: 'fit-content',
  gap: '20px',
  padding: '16px',
  width: '100vw',
  transition: 'all 0.5s ease-out',
  justifyContent: 'center',
};

const ScanContainerStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'row',
  height: 'fit-content',
  gap: '20px',
  padding: '16px',
  width: '100vw',
  transition: 'all 0.5s ease-out',
  justifyContent: 'center',
};

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  mapZones: any[];
  mapZoneType: AllMapZoneType;
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  fillOpacity: number;
};
const PolygonView: React.FC<PolygonProps> = ({
  mapZones,
  targetMapZoneType,
  strokeColor,
  fillColor,
  fillOpacity,
}) => {
  const everyMapZoneOfBrand = mapZones.filter(
    (zone) => zone.value.mapZoneType === targetMapZoneType
  );

  const geometryPathsOfBrandZones = everyMapZoneOfBrand.map((zone) => {
    return { ...zone, paths: toGoogleMapsMultiPoligon(zone?.value.geometry) };
  });

  return (
    <>
      {geometryPathsOfBrandZones.map((zone) => {
        return (
          <Polygon
            paths={zone.paths}
            options={{ ...polygonOptions, fillColor, strokeColor, fillOpacity }}
          />
        );
      })}
    </>
  );
};

export type VehicleItemType = {
  vehicleType: string;
};

type Props = {
  mapZonesList: any;
  mapZoneType: AllMapZoneType;
  setBound: React.Dispatch<React.SetStateAction<string>>;
};

export const MapZoneViewerOperator: React.FC<Props> = ({ mapZoneType, setBound }) => {
  const dispatch = useDispatch();

  // Hooks
  const { mapZoneList } = useBranchesMaps();
  const { handleSetApproachedVehicle } = useOperatorVehicles();

  const { areFiltersChecked } = useVehicleMap();

  // Live map
  const [hashVehicles, setHashVehicles] = useState<HashTable>({});
  const [filteredVehicleList, setFilteredVehicleList] = useState<HashTable>({});
  const [searchedVehicleList, setSearchedVehicleList] = useState<HashTable>({});

  const vehiclesToShow = areFiltersChecked ? filteredVehicleList : hashVehicles;

  const handleSetStateChange = useCallback(() => {
    if (areFiltersChecked) {
      return {
        state: filteredVehicleList,
        setState: setFilteredVehicleList,
      };
    } else {
      return {
        state: hashVehicles,
        setState: setHashVehicles,
      };
    }
  }, [areFiltersChecked, filteredVehicleList, hashVehicles]);

  const { getBackOfficeVehicles, omniPositionTracking, forceRerender, omniUpdate } = useLiveMap(
    handleSetStateChange().state,
    handleSetStateChange().setState
  );

  // Selectors & States
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMapZoneList, setFilteredMapZoneList] = useState<MapZoneItemType[]>(mapZoneList);

  const [centerLocation, setCenterLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const [zoom, setZoom] = useState<number | undefined>(18);

  useEffect(() => {
    setFilteredMapZoneList(mapZoneList);
  }, [mapZoneList]);

  useEffect(() => {
    getBackOfficeVehicles();
  }, []);

  // useEffect(() => {
  //   if (Object.keys(hashVehicles)?.length > 0) {
  //     socket.on('vehicleUpdate', omniUpdate);
  //     socket.on('OmniPositionTracking', omniPositionTracking);
  //   }
  // }, [forceRerender, areFiltersChecked]);

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCenterLocation({ lat: latitude, lng: longitude });
          },
          (error) => {
            throw error.message;
          }
        );
      } else {
        throw 'Geolocation is not supported by your browser.';
      }
    };

    getUserLocation();
  }, []);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
        console.log('Succes on getting user location');
      },
      (error) => {
        console.error('Error getting user location:', error);
      },
      { enableHighAccuracy: true, maximumAge: 2000, timeout: 10000 }
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const handleSearch = (query: string) => {
    const filteredHashTable: { [key: string]: BackofficeLiveVehicle } = {};

    const lowercaseQuery = query.toLowerCase();

    const vehicleDataToSearch = areFiltersChecked ? filteredVehicleList : hashVehicles;

    for (const [imei, vehicle] of Object.entries(vehicleDataToSearch)) {
      const { qrCode, vin, licensePlate, name } = vehicle || {};

      if (
        qrCode?.toLowerCase().startsWith(lowercaseQuery) ||
        vin?.toLowerCase().startsWith(lowercaseQuery) ||
        licensePlate?.toLowerCase().startsWith(lowercaseQuery) ||
        name?.toLowerCase().startsWith(lowercaseQuery)
      ) {
        filteredHashTable[imei] = vehicle;
      }
    }

    dispatch(setAreLiveMapFiltersChecked(Object.keys(vehicleDataToSearch)?.length > 0));
    return setSearchedVehicleList(query?.length > 0 ? filteredHashTable : {});
  };

  const handleCenterMap = () => {
    if (currentLocation) {
      return setCenterLocation({ lat: currentLocation?.lat, lng: currentLocation?.lng });
    }
  };

  const MemoizedVehiclesToShow = useMemo(() => {
    if (areFiltersChecked) {
      if (Object.keys(searchedVehicleList)?.length > 0) {
        return searchedVehicleList;
      }

      return filteredVehicleList;
    } else {
      if (Object.keys(searchedVehicleList)?.length > 0) {
        return { ...hashVehicles, ...searchedVehicleList };
      }

      return hashVehicles;
    }
  }, [areFiltersChecked, forceRerender, omniPositionTracking]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      {currentLocation ? (
        <>
          <GoogleMapsContainerMobile
            setBound={setBound}
            setCenter={() => console.log('Centered')}
            center={centerLocation}
            zoom={zoom}
            setZoom={setZoom}
            mapOptions={MapOptionsOperator}
            mapContainerStyle={{
              height: '100vh',
              width: '100vw !important',
              transition: 'transform 0.5s ease-out',
            }}
          >
            <>
              {mapZoneTypeColors.map((zoneType) => (
                <PolygonView
                  key={zoneType.type}
                  targetMapZoneType={zoneType.type}
                  mapZoneType={mapZoneType}
                  mapZones={mapZoneList}
                  strokeColor={zoneType.color}
                  fillColor={zoneType.fillColor ?? zoneType.color}
                  fillOpacity={zoneType.opacity}
                />
              ))}
              {currentLocation && (
                <Marker
                  key={`${currentLocation.lat}-${currentLocation.lng}`} // Change the key when position changes
                  icon={{
                    url: currentLocationPin(),
                    // @ts-ignore
                    scaledSize: new google.maps.Size(zoom * 6, zoom * 6),
                  }}
                  position={currentLocation}
                  title="Your Location"
                />
              )}
              <GoogleMarkersOperator
                markers={MemoizedVehiclesToShow}
                clustering={!areFiltersChecked}
              />
            </>
          </GoogleMapsContainerMobile>
          <Box sx={SearchFilterContainerStyle}>
            <SearchBarOperator
              onSearch={handleSearch}
              searchedVehicles={searchedVehicleList}
              generateBatteryteIcon={generateBatteryteIcon}
              generaTxtColor={generateTxtColor}
              generateIotIcon={generateIotIcon}
              // @ts-ignore
              handleRowClick={handleSetApproachedVehicle}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
            />
            <FilterCTA
              areFiltersActive={
                Object.keys(filteredVehicleList)?.length !== Object.keys(hashVehicles)?.length
              }
            />
          </Box>
          <Box sx={ScanContainerStyle}>
            <ScanOperator handleCenterMap={handleCenterMap} />
          </Box>
          <SetupOperatorFiltersDrawer
            originalVehicles={hashVehicles}
            mapZonesOnMap={filteredMapZoneList}
            vehiclesOnMap={filteredVehicleList}
            vehiclesLength={Object.keys(vehiclesToShow)?.length}
            // @ts-ignore
            setVehiclesOnMap={setFilteredVehicleList}
            setFilteredMapZoneList={setFilteredMapZoneList}
          />
        </>
      ) : (
        <LoadingScreen />
      )}
    </Box>
  );
};
