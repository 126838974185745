import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { CreatePricingForm } from './CreatePricingForm';
import { useCreatePricing } from '@Modal/hooks/pricing/useCreatePricing';

export const CreatePricingModalDialog = () => {
  const { showCreatePricingForm, handleCreateCancel } = useCreatePricing();

  return (
    <>
      {showCreatePricingForm && (
        <ClosableModal
          name="PricingCreation"
          title="Add Pricing"
          open={showCreatePricingForm}
          onClose={handleCreateCancel}
        >
          <CreatePricingForm />
        </ClosableModal>
      )}
    </>
  );
};
