// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { UpdateVehicleServiceStateType } from '@Modal/reducers/vehicles/types';
import { SendVehicleDashboardCommandDTO } from '@Modal/reducers/vehicles/iot/dto';

// Actions
import {
  updateVehicle,
  updateVehicleServiceState,
  getSelectedVehicle,
  setShowVehicleDrawer,
  setShowCancelVehicleDrawer,
  setSelectedVehicleServiceState,
  postIoTDashboardCommand,
} from '@Modal/reducers/vehicles/vehicles/vehiclesDrawerSlice';
import {
  useSetupVehicleForm,
  useManageSetupVehicleSchema,
} from '@Forms/vehicles/SetupVehicleFormContext';
import { getVehiclesList } from '@Modal/reducers/vehicles/vehiclesSlice';
import { getSelectedVehicleCategoryDashboardCommands } from '@Modal/reducers/vehicles/categories/vehicleCategoryDrawerSlice';

// Redux
import { useVehicles } from '../useVehicles';
import { useBranches } from '@Modal/hooks/branches/useBranch';

// Hooks
import { useRedis } from '@Modal/hooks/redis/useRedis';
import { useSetupVehicleUseCaseSchema } from '@Hooks/vehicles/SetupVehicle/useSetupVehicleUseCaseSchema';
import { useSetupVehicleUseCaseMapper } from '@Hooks/vehicles/SetupVehicle/useSetupVehicleUseCaseMapper';
import { useLiveMap } from '@Hooks/shared/useLiveMap';
import useResponsive from '@Hooks/shared/useResponsive';

export const useVehiclesDrawer = () => {
  const { selectedFilters } = useSelector((state: StoreInterface) => state?.vehiclesTable);
  const isMobile = useResponsive('down', 'sm');

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const {
    showCancelVehicleDrawer,
    showVehicleDrawer,
    selectedVehicleData,
    selectedVehicleServiceState,
  } = useSelector((state: StoreInterface) => state?.vehicleDrawer);

  const { activeBrandId } = useBranches();
  const { vehicles, setVehiclesList } = useVehicles();

  // Other
  const { enqueueSnackbar } = useSnackbar();
  const { handleSetRedisVehicle } = useRedis();

  // Form Controls
  const { control, handleSubmit, reset } = useSetupVehicleForm();
  const { setSchema } = useManageSetupVehicleSchema();

  const mapper = useSetupVehicleUseCaseMapper(selectedVehicleData);
  const schema = useSetupVehicleUseCaseSchema();

  const { isDirty, errors } = useFormState({ control });

  const handleVehicleRowClick = async (id: string) => {
    return dispatch(getSelectedVehicle(id))
      .unwrap()
      .then((selectedVehicleData) => {
        return dispatch(
          getSelectedVehicleCategoryDashboardCommands(selectedVehicleData.categoryId)
        );
      })
      .catch((error: string) => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Send IoT Command
  const sendIoTDashboardCommandHandler = async (IoTData: SendVehicleDashboardCommandDTO) => {
    return dispatch(postIoTDashboardCommand(IoTData))
      .unwrap()
      .then(async () => {
        enqueueSnackbar('Command sent', {
          autoHideDuration: 3000,
          variant: 'success',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      })
      .catch((error: string) => {
        enqueueSnackbar(error, {
          autoHideDuration: 2000,
          variant: 'error',
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  const setVehicleDrawerMapperHandler = useCallback(async () => {
    setSchema(schema);
    const formState = await mapper.toFormState(selectedVehicleData.id, activeBrandId);
    reset(formState);
  }, [selectedVehicleData]);

  useEffect(() => {
    if (selectedVehicleData) {
      setVehicleDrawerMapperHandler();
    }
  }, [selectedVehicleData, setVehicleDrawerMapperHandler]);

  // Form Submit
  const handleSubmitGeneralForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          updateVehicle({
            vehicleID: selectedVehicleData?.id,
            vehicleFormState: {
              licensePlate: formData.licensePlate,
              qrCode: formData.qrCode,
              vin: formData.vin,
              categoryId: formData.categoryId,
              iotImei: formData.iotImei,
              rentalStatus: formData.rentalStatus,
              name: formData.name,
            },
          })
        )
          .unwrap()
          .then(() => {
            reset();
            const updatedVehicles = vehicles.map((veh) => {
              return veh?.id === selectedVehicleData?.id ? { ...veh, ...formData } : veh;
            });
            dispatch(setVehiclesList(updatedVehicles));
          })
          .catch((error: string) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: isMobile ? 'top' : 'bottom',
                horizontal: isMobile ? 'center' : 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  // Handle service state update from vehicle drawer header
  const handleServiceStateUpdate = async (vehicleData: UpdateVehicleServiceStateType) => {
    return dispatch(
      updateVehicleServiceState({
        vehicleID: vehicleData.vehicleID,
        vehicleServiceState: vehicleData.vehicleServiceState,
      })
    )
      .unwrap()
      .then(() => {
        reset();
        dispatch(getVehiclesList({ selectedFilters, brandId: activeBrandId }));
        enqueueSnackbar('Service state was successfully updated', {
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      })
      .catch((error: string) => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'center' : 'right',
          },
        });
      });
  };

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelVehicleDrawer(true));
    } else {
      reset();
      handleSetRedisVehicle(undefined);
      return dispatch(setShowVehicleDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelVehicleDrawer(false));
      dispatch(setShowVehicleDrawer(true));
    } else {
      reset();
      dispatch(setShowCancelVehicleDrawer(false));
      dispatch(setShowVehicleDrawer(false));
    }
  };

  return {
    // State
    showVehicleDrawer,
    selectedVehicleData,
    showCancelVehicleDrawer,
    selectedVehicleServiceState,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    handleCreateCancel,
    setShowVehicleDrawer,
    handleVehicleRowClick,
    handleCancelTakeMeBack,
    handleSubmitGeneralForm,
    handleServiceStateUpdate,
    setShowCancelVehicleDrawer,
    sendIoTDashboardCommandHandler,
    setSelectedVehicleServiceState,
  };
};
