// System
import { ReactElement } from 'react';
import { Stack, Typography, duration } from '@mui/material';

// Styles
import Styles from './DetailsItem.module.scss';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

type props = {
  title: string;
  state: number | null;
  icon: ReactElement;
};

export function convertMinutesToTime(durationInMinutes: number) {
  const totalSeconds = durationInMinutes * 60;

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export const DetailsItem = ({ title, state, icon }: props) => {
  const { selectedRental } = useRentalsDrawer();

  return (
    <Stack direction="row">
      <Stack justifyContent="center" alignItems="center" className={Styles.DetailsItem}>
        {icon}
      </Stack>
      <Stack direction="column" justifyContent="center">
        <Typography fontSize={'14px'} variant="subtitle2">
          {title}
        </Typography>
        <Typography fontSize={'12px'} variant="body2">
          {selectedRental.state === 'ACTIVE'
            ? 'Rental is Active'
            : state !== null
            ? convertMinutesToTime(state)
            : 'N/A'}
        </Typography>
      </Stack>
    </Stack>
  );
};
