// System
import React from 'react';
import { Box } from '@mui/system';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import {
  setShowCommandsModal,
  setShowIoTLinkModal,
  setShowReplaceQRModal,
  setShowServiceStateModal,
} from '@Modal/reducers/operator/operatorVehicles/opratorVehiclesSlice';

// Styles
import Styles from './OperatorVehicleOptions.module.scss';

export const OperatorVehicleOptions = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Options Data
  const optionsData = [
    {
      ttl: 'Send command',
      icon: 'ic_send_command',
      onClick: () => dispatch(setShowCommandsModal(true)),
    },
    {
      ttl: 'Service state',
      icon: 'ic_service_state',
      onClick: () => dispatch(setShowServiceStateModal(true)),
    },
    {
      ttl: 'Link IoT',
      icon: 'ic_cloud_bank',
      onClick: () => dispatch(setShowIoTLinkModal(true)),
    },
    {
      ttl: 'Replace QR',
      icon: 'ic_reboot',
      onClick: () => dispatch(setShowReplaceQRModal(true)),
    },
  ];
  return (
    <Box className={Styles.OperatorVehicleOptions}>
      {optionsData.map((option) => {
        return (
          <Box className={Styles.OptionCard} onClick={() => option?.onClick()} key={option.ttl}>
            <Box className={Styles.Icon}>
              <AssetIcon
                src={`icons/operator/${option.icon}`}
                sx={{ width: '24px', height: '24px' }}
              />
            </Box>
            <Typography className={Styles.CommandLabel}>{option.ttl}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
