import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const freebetUsageListColumns = [
  'freebetUsage._id',
  'freebetUsage.campaignId',
  'freebetUsage.usageId',
  'freebetUsage.providerId',
  'freebetUsage.roundId',
  'freebetUsage.gameId',
  'freebetUsage.win',
  'freebetUsage.createdAt',
  'freebetUsage.updatedAt',
] as const;

export type FreebetUsageListColumns = (typeof freebetUsageListColumns)[number];

export type FreebetUsageListFilter = {
  'freebetUsage._id': string | undefined;
  'freebetUsage.campaignId': string | undefined;
  'freebetUsage.usageId': string | undefined;
  'freebetUsage.providerId': string | undefined;
  'freebetUsage.roundId': string | undefined;
  'freebetUsage.gameId': number | undefined;
  'freebetUsage.win': number | undefined;
  'freebetUsage.createdAt': string | undefined;
  'freebetUsage.updatedAt': string | undefined;
};

export const defaultFreebetUsageListFilter: FreebetUsageListFilter = {
  'freebetUsage._id': undefined,
  'freebetUsage.campaignId': undefined,
  'freebetUsage.usageId': undefined,
  'freebetUsage.providerId': undefined,
  'freebetUsage.roundId': undefined,
  'freebetUsage.gameId': undefined,
  'freebetUsage.win': undefined,
  'freebetUsage.createdAt': undefined,
  'freebetUsage.updatedAt': undefined,
};

export const freebetUsageListColumnVisibilitySettings = {
  'freebetUsage._id': ColumnVisibilityType.Visible,
  'freebetUsage.campaignId': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.usageId': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.providerId': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.roundId': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.gameId': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.win': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.createdAt': ColumnVisibilityType.CanBeHidden,
  'freebetUsage.updatedAt': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<FreebetUsageListColumns>;

export const defaultFreebetUsageListColumnVisibility = defaultColumnVisibilityParams(
  freebetUsageListColumnVisibilitySettings
);

export const freebetUsageListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  freebetUsageListColumns,
  freebetUsageListColumnVisibilitySettings
);
