import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';

import { CreateIotModuleForm } from './CreateIotModuleForm';
import { useCreateVehicleIOTModule } from '@Modal/hooks/vehicles/iot/useCreateVehicleIotModule';

// Styles
import Styles from './CreateIotModuleModalDialog.module.scss';

export const CreateIotModuleModalDialog = () => {
  const { showCreateVehicleIOTModuleForm, handleCreateCancel } = useCreateVehicleIOTModule();

  return (
    <>
      {showCreateVehicleIOTModuleForm && (
        <ClosableModal
          name="CreateIotModule"
          title="Add IoT"
          open={showCreateVehicleIOTModuleForm}
          onClose={handleCreateCancel}
          className={Styles.CreateIotModuleModalDialog}
        >
          <CreateIotModuleForm />
        </ClosableModal>
      )}
    </>
  );
};
