import { BranchStatus } from '@contracts/domain/keys';
import { BranchRow } from '@contracts/domain/BranchRow';
import { Sortable } from '@contracts/common/traits/Sortable';
import { SortDirection } from '@contracts/common/SortDirection';
import { Paginable } from '@contracts/common/traits/Paginable';
import { Filterable } from '@contracts/common/traits/Filterable';
import { SortParams } from '@contracts/common/params/SortParams';
import { EnumFilterValue } from '@contracts/common/EnumFilterValue';

export type BranchListFilter = {
  id: number | undefined;
  name: string | undefined;
  status: EnumFilterValue<BranchStatus>;
  parentName: string | undefined;
  limit: number;
};
export const defaultBranchListFilter: BranchListFilter = {
  id: undefined,
  name: undefined,
  status: {},
  parentName: undefined,
  limit: 25,
};

export type BranchListSort = SortParams<keyof BranchRow>;
export const defaultBranchListSort: BranchListSort = ['id', SortDirection.Asc];

export type BranchTableContextType = {
  items: BranchRow[];
  pagination: Paginable;
  filter: Filterable<BranchListFilter>;
  sort: Sortable<keyof BranchRow>;
};
