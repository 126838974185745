import { useCallback, useRef, useState, ComponentType, CSSProperties } from 'react';

import { Box, Popover, SelectChangeEvent, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import Scrollbar from '@Components/scrollbar/Scrollbar';

import { Brand } from '@contracts/domain/Brand';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';

import { useDispatch } from 'react-redux';
import { setSelectedBrand } from '@Modal/reducers/branches/branchSlice';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { CreateBrandButton } from './CreateBrandButton';

type InputComponentProps = {
  open: boolean;
  value?: Brand;
  onClick: () => void;
  sx?: SystemStyleObject<Theme>;
};

type Props = {
  popupWidth?: CSSProperties['width'];
  sx?: SystemStyleObject<Theme>;
  options: Brand[];
  inputComponent: ComponentType<InputComponentProps>;
  onChange?: (event: SelectChangeEvent<Brand['id']>, child?: React.ReactNode) => void;
  onCreateClick?: () => void;
};

export const BrandSelect: React.FC<Props> = ({
  sx,
  popupWidth,
  options,
  inputComponent: Input,
  onCreateClick,
}) => {
  const { selectedBrand } = useBranches();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const ellipsis = useEllipsis();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCreateButtonClick = useCallback(() => {
    setOpen(false);
    onCreateClick?.();
  }, [onCreateClick]);

  const dropDown = options.map((brand) => (
    <MenuItemEx
      key={brand.id}
      name="Brand"
      value={brand.id}
      selected={brand.id === selectedBrand?.id}
      sx={{ borderRadius: 0.75 }}
      onClick={() => {
        dispatch(setSelectedBrand(brand));
        setOpen(false);
      }}
    >
      <AtLocator type="readonly-text" name="name">
        <Box sx={{ width: '100%', ...ellipsis }}>{brand.name}</Box>
      </AtLocator>
    </MenuItemEx>
  ));

  return (
    <Box sx={[{ boxShadow: 1, borderRadius: 2, width: popupWidth }, sx ?? {}]}>
      <Box ref={anchorRef} width="100%">
        <Input value={selectedBrand} onClick={handleClick} open={open} />
      </Box>
      <AtLocator type="popover" name="BrandSelect">
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 1 }}
        >
          <Box sx={{ width: popupWidth }}>
            <Scrollbar
              autoHide={false}
              forceVisible="y"
              sx={{
                maxHeight: '400px',
                padding: 1,
                paddingBottom: 0,
              }}
            >
              {dropDown}
            </Scrollbar>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', padding: 1 }}
            >
              <CreateBrandButton onClick={handleCreateButtonClick} />
            </Box>
          </Box>
        </Popover>
      </AtLocator>
    </Box>
  );
};
