import { UseState, defaultUseState } from '@contracts/view/UseState';
import { CSSProperties, createContext } from 'react';

export type GoogleMapsContainerContextType = {
  zoom: UseState<number | undefined>;
  mapContainerStyle?: CSSProperties;
  center: UseState<google.maps.LatLngLiteral | undefined>;
  options: google.maps.MapOptions;
};

const defaultGoogleMapsContainerContextValue: GoogleMapsContainerContextType = {
  zoom: defaultUseState<number | undefined>(undefined),
  center: defaultUseState<google.maps.LatLngLiteral | undefined>(undefined),
  options: {},
};

export const GoogleMapsContainerContext = createContext<GoogleMapsContainerContextType>(
  defaultGoogleMapsContainerContextValue
);
