import { Alert, Stack } from '@mui/material';

import { AlertProps } from '@Components/props/alerts/AlertProps';
import { AtLocator } from '@Components/shared/locators/AtLocator';

export const SuccessAlert: React.FC<AlertProps> = ({ message, onClose, actions }) => {
  const messageText = typeof message === 'object' ? JSON.stringify(message) : message;

  return (
    <AtLocator type="alert" name="SuccessAlert">
      <Alert
        action={
          actions && (
            <Stack direction="row" spacing={1}>
              {actions}
            </Stack>
          )
        }
        onClose={onClose}
        sx={{
          mb: 2,
          width: '60%',
          position: 'absolute',
          left: '20%',
          bottom: '4%',
        }}
      >
        {messageText}
      </Alert>
    </AtLocator>
  );
};
