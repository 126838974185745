// Redux
import { useSelector } from 'react-redux';

// Services
import {
  setVehiclePagination,
  setCategoryPagination,
  setVehicleCategoriesList,
  getVehicleCategoriesList,
} from '@Modal/reducers/vehicles/vehiclesSlice';

// Redux
import { setVehiclesList } from '@Modal/reducers/vehicles/vehiclesSlice';

// Types
import { StoreInterface } from 'src/app/types';

export const useVehicles = () => {
  const {
    vehicles,
    vehicleCategories,
    vehiclePagination,
    categoryPagination,
    loadingVehiclesContent,
    loadingCategoriesContent,
  } = useSelector((state: StoreInterface) => state?.vehicles);

  return {
    // Store
    vehicles,
    vehiclePagination,
    vehicleCategories,
    categoryPagination,
    loadingVehiclesContent,
    loadingCategoriesContent,
    // Actions
    setVehiclesList,
    setVehiclePagination,
    setCategoryPagination,
    setVehicleCategoriesList,
    getVehicleCategoriesList,
  };
};
