// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useCallback, useMemo } from 'react';

// Types
import { StoreInterface } from 'src/app/types';

// Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  setShowCreatePricingForm,
  setShowCancelPricingForm,
} from '@Modal/reducers/pricing/createPricingSlice';
import { usePricingTable } from './usePricingTable';
import { createPricing } from '@Modal/reducers/pricing/createPricingSlice';

// Form
import { AppDispatch } from 'src/app/store';
import { useCreatePricingForm } from '@Forms/pricing/CreatePricing/CreatePricingFormContext';

export const useCreatePricing = () => {
  // Redux
  const { showCreatePricingForm, showCancelPricingForm } = useSelector(
    (state: StoreInterface) => state?.createPricing
  );

  const { fetchPricingTableContent } = usePricingTable();

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  // Form Controls
  const { control, handleSubmit, reset, getValues } = useCreatePricingForm();
  const { isDirty, errors, touchedFields } = useFormState({ control });

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelPricingForm(true));
    } else {
      return dispatch(setShowCreatePricingForm(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelPricingForm(false));
      dispatch(setShowCreatePricingForm(true));
    } else {
      reset();
      dispatch(setShowCancelPricingForm(false));
      dispatch(setShowCreatePricingForm(false));
    }
  };

  const handleSubmitCreatePricing = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(createPricing(formData))
          .unwrap()
          .then(() => {
            fetchPricingTableContent();
            handleCancelTakeMeBack('confirm');
            enqueueSnackbar('Pricing is successfully created', {
              variant: 'success',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          })
          .catch((error: string) => {
            reset();
            enqueueSnackbar(error, {
              variant: 'error',
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  const getFormValues = useCallback(() => {
    return getValues();
  }, [touchedFields]);

  return {
    // State
    submittable: isDirty && Object.values(errors).length == 0,
    showCreatePricingForm,
    showCancelPricingForm,
    touchedFields,
    formValues: getFormValues(),
    // Actions
    setShowCancelPricingForm,
    setShowCreatePricingForm,
    handleCreateCancel,
    handleCancelTakeMeBack,
    handleSubmitCreatePricing,
  };
};
