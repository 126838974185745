// System
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-hook-form';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  getSelectedVehicleCategory,
  getSelectedVehicleCategoryDashboardCommands,
  updateSelectedVehicleCategory,
  setShowSelectedCategoryDrawer,
  setShowCancelVehicleCategoryDrawer,
  setShowSpeedSettingsFilters,
  setShowAutoEndRentalInput,
} from '@Modal/reducers/vehicles/categories/vehicleCategoryDrawerSlice';

// Redux
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';
import { updateVehicleCategoriesList } from '@Modal/reducers/vehicles/vehiclesSlice';
// import { getVehicleDisplayNames, getVehicleTypes } from '@Modal/reducers/generalSlice';

// Hooks
import {
  useManageSetupCategorySchema,
  useSetupCategoryForm,
} from '@Forms/vehicles/SetupCategoryFormContext';
import {
  useSetupCategoryUseCaseMapper,
  useSetupCategoryUseCaseSchema,
} from '@Hooks/vehicles/SetupCategory';
import { useVehicles } from '../useVehicles';

export const useVehicleCategoryDrawer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { vehicleDisplayNames, vehicleTypes } = useGeneralStore();
  const { vehicleCategories } = useVehicles();
  const {
    showCancelCategoryDrawer,
    showSelectedCategoryDrawer,
    selectedVehicleCategory,
    showSpeedSettingsFilters,
    showAutoEndRentalInput,
    selectedVehicleCategoryDashboardCommands,
  } = useSelector((state: StoreInterface) => state?.vehicleCategoryDrawer);

  // Other
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   if (!(vehicleTypes.length > 0)) dispatch(getVehicleTypes());
  //   if (!(vehicleDisplayNames.length > 0)) dispatch(getVehicleDisplayNames());
  // }, []);

  // Form Controls
  const { control, reset, handleSubmit } = useSetupCategoryForm();
  const { setSchema } = useManageSetupCategorySchema();

  const mapper = useSetupCategoryUseCaseMapper(selectedVehicleCategory);
  const schema = useSetupCategoryUseCaseSchema();

  const { isDirty, errors } = useFormState({ control });

  const handleVehicleCategoryClick = async (id: string) => {
    return dispatch(getSelectedVehicleCategory(id))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error?.message, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleUpdateDashboardCommands = async (id: string) => {
    return dispatch(getSelectedVehicleCategoryDashboardCommands(id))
      .unwrap()
      .catch((error) => {
        enqueueSnackbar(error?.message, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const setVehicleCategoryDrawerMapperHandler = useCallback(async () => {
    setSchema(schema);
    const formState = await mapper.toFormState();
    reset(formState);
  }, [selectedVehicleCategory]);

  useEffect(() => {
    if (selectedVehicleCategory) {
      setVehicleCategoryDrawerMapperHandler();
    }
  }, [selectedVehicleCategory, setVehicleCategoryDrawerMapperHandler]);

  // Form Submit
  const handleSubmitVehicleCategoryForm = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        const dto = mapper.toDto(formData);

        dispatch(updateSelectedVehicleCategory(dto))
          .unwrap()
          .then(() => {
            const updatedVehicleCategories = vehicleCategories.map((categ) => {
              return categ?.id === dto?.id ? { ...categ, ...dto.body } : categ;
            });

            // @ts-ignore
            dispatch(updateVehicleCategoriesList(updatedVehicleCategories));
            reset();
          })
          .catch((error: string) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    []
  );

  // Handle Form Cancels
  const handleCreateCancel = () => {
    if (isDirty) {
      return dispatch(setShowCancelVehicleCategoryDrawer(true));
    } else {
      reset();
      return dispatch(setShowSelectedCategoryDrawer(false));
    }
  };

  const handleCancelTakeMeBack = (action: string) => {
    if (action === 'back') {
      dispatch(setShowCancelVehicleCategoryDrawer(false));
      dispatch(setShowSelectedCategoryDrawer(true));
    } else {
      reset();
      dispatch(setShowCancelVehicleCategoryDrawer(false));
      dispatch(setShowSelectedCategoryDrawer(false));
    }
  };

  return {
    // State
    showAutoEndRentalInput,
    selectedVehicleCategory,
    showSpeedSettingsFilters,
    showCancelCategoryDrawer,
    showSelectedCategoryDrawer,
    selectedVehicleCategoryDashboardCommands,
    submittable: isDirty && Object.values(errors).length == 0,
    // Actions
    setShowAutoEndRentalInput,
    setShowSpeedSettingsFilters,
    setShowSelectedCategoryDrawer,
    setShowCancelVehicleCategoryDrawer,
    handleCreateCancel,
    handleCancelTakeMeBack,
    handleVehicleCategoryClick,
    handleUpdateDashboardCommands,
    handleSubmitVehicleCategoryForm,
  };
};
