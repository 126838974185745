export const MapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  rotateControl: false,
  scaleControl: true,
  fullscreenControl: false,
  mapTypeId: 'roadmap',
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite'],
  },
  zoomControl: true,
  gestureHandling: 'greedy',
};

export const MapOptionsOperator: google.maps.MapOptions = {
  streetViewControl: false,
  rotateControl: true,
  scaleControl: true,
  fullscreenControl: false,
  mapTypeId: 'roadmap',
  mapTypeControl: true,
  mapTypeControlOptions: { mapTypeIds: [] },
  zoomControl: false,
  gestureHandling: 'greedy',
};

export const MapOptionsDefault: google.maps.MapOptions = {
  streetViewControl: false,
  rotateControl: false,
  scaleControl: true,
  fullscreenControl: false,
  mapTypeId: 'roadmap',
  mapTypeControl: false,
  mapTypeControlOptions: { mapTypeIds: [] },
  zoomControl: true,
};
