import { ComponentProps, ReactElement, cloneElement } from 'react';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { Tooltip } from '@mui/material';

type ChildrenProps = ComponentProps<typeof Tooltip>;

export type AtTooltipLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtTooltipLocator: React.FC<AtTooltipLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-tooltip': name } as DataAttributeProps);
};
