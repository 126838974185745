// System
import { Box, Stack, Typography } from '@mui/material';

// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';
import { TransactionStatus } from '@Features/transactions/SetupTransaction/information/TransactionStatus';

// Redux
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

// Styles
import Styles from './RentalTransactionCard.module.scss';

// TODO replace any with proper type
export const RentalTransactionCard: React.FC<any> = ({ amount, date, currency, id, status }) => {
  const { selectedCustomerData } = useCustomersDrawer();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={Styles.RentalTransactionCard}
      spacing={2}
    >
      <Box className={Styles.CurrenctContainer}>
        <Typography variant="subtitle2" className={Styles.CurrencyFont}>
          {currency} {amount}
        </Typography>
        <Typography variant="caption" className={Styles.DateLabelFont}>
          <DateLabel date={new Date(date)} format="long" timezone={undefined} />
        </Typography>
      </Box>
      <Box className={Styles.FullNameContainer}>
        <Typography variant="subtitle2" className={Styles.FullNameFont}>
          {selectedCustomerData?.accountDetails[0]?.firstName}{' '}
          {selectedCustomerData?.accountDetails[0]?.lastName}
        </Typography>
        <Box className={Styles.IdContainer}>
          <Typography className={Styles.IdLabelFont}>ID:</Typography>
          <TruncateText
            text={id}
            maxLength={8}
            textStyles={{ fontSize: '12px', lineHeight: '16px', fontWeight: '400' }}
          />
        </Box>
      </Box>
      <Box className={Styles.StatusContainer}>
        <TransactionStatus status={status} />
      </Box>
    </Stack>
  );
};
