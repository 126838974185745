import {
  defaultFreebetListFilter,
  freebetListColumnVisibilityOptions,
  defaultFreebetListColumnVisibility,
} from '@utils/tables/freebets/freebetsListFilter';
// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  page: 1,
  total: 0,
  limit: 25,
  freebetTableFilters: {},
  selectedFilters: defaultFreebetListFilter,
  columnVisibility: defaultFreebetListColumnVisibility,
  columnVisibilityOptions: freebetListColumnVisibilityOptions,
};

const freebetTableSlice = createSlice({
  name: 'freebetTable',
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setFreebetTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { freebetTableFilters } = state;
      const convertedValue = typeof value !== 'object' ? value : value[0];

      const newFreebetTableFilters = {
        ...freebetTableFilters,
        [codeName]: convertedValue,
      };
      state.freebetTableFilters = newFreebetTableFilters;
    },
  },
  extraReducers: {},
});

export const { reducer: freebetTableReducer } = freebetTableSlice;
export const { setColumnVisibility, setLimit, setPage, setTotal, setFreebetTableFilters } =
  freebetTableSlice.actions;
