// System
import { Button, Typography } from '@mui/material';

// Redux
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

// Styles
import Styles from './RentalEndRideButton.module.scss';

export const RentalEndRideButton: React.FC = () => {
  const { selectedRental, handleEndRentalModalDialog, handleUpdateSelectedRentalInfo } =
    useRentalsDrawer();
  const { state } = selectedRental;

  return (
    <>
      {state !== 'ENDED' && state !== 'CANCELLED' ? (
        <Button
          variant="contained"
          color="error"
          className={Styles.RentalEndRideButton}
          onClick={() => {
            handleUpdateSelectedRentalInfo();
            handleEndRentalModalDialog(true);
          }}
        >
          <Typography className={Styles.TextFont}>End Ride</Typography>
        </Button>
      ) : null}
    </>
  );
};
