import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

// Hooks
import { useRoles } from '@Hooks/shared/useRoles';
import useResponsive from '@Hooks/shared/useResponsive';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';

// System
import { useDispatch } from 'react-redux';

// Components
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';

// Redux
import {
  getMapZoneTypes,
  getTimeZones,
  getCurrencies,
  getVehicleServiceStates,
} from '@Modal/reducers/generalSlice';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { fetchBrands } from '@Modal/reducers/branches/branchSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const isDesktop = useResponsive('up', 'lg');

  const { handleDecodeToken } = useAuthorization();
  const { availableRoutes, loading } = useRoles();
  const safeRoutes: string[] = availableRoutes.map((obj: any) => obj.path);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  // const { timezones, mapZoneTypes, currencies, vehicleServiceStates } = useGeneralStore();

  const { brands } = useBranches();

  const location = useLocation();
  const isMapPage = location.pathname === '/map';
  const { pathname } = useLocation();

  const pagesToHideHeader = ['/operator'];

  const hideHeader = pagesToHideHeader.includes(pathname);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (!timezones.length) {
  //     dispatch(getTimeZones());
  //   }

  //   if (!mapZoneTypes.length) {
  //     dispatch(getMapZoneTypes());
  //   }

  //   if (!currencies.length) {
  //     dispatch(getCurrencies());
  //   }

  //   if (!vehicleServiceStates.length) {
  //     dispatch(getVehicleServiceStates());
  //   }

  //   if (!brands.length) {
  //     dispatch(fetchBrands());
  //   }
  // }, []);

  useEffect(() => {
    handleDecodeToken();
  }, []);

  useEffect(() => {}, []);

  return (
    <>
      {!hideHeader && <Header onOpenNav={handleOpen} />}

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
          maxHeight: '100vh',
        }}
      >
        {!hideHeader && isDesktop ? (
          <NavMini />
        ) : (
          <NavVertical openNav={open} onCloseNav={handleClose} />
        )}

        <Main
          sx={{
            paddingX: isMapPage ? '0px' : '16px',
            paddingTop: isMapPage ? '64px' : '70px',
            overflowY: 'scroll',
          }}
        >
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
