import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CreateIotModuleFormContext,
  CreateIotModuleFormState,
  createIotModuleFormStateDefault,
  createIotModuleSchemaDefault,
} from '@Forms/vehicles/CreateIotModuleFormContext';

export const CreateIotModuleFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<CreateIotModuleFormState>>>(
    createIotModuleSchemaDefault
  );
  const methods = useForm({
    defaultValues: createIotModuleFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <CreateIotModuleFormContext.Provider value={value}>
      {children}
    </CreateIotModuleFormContext.Provider>
  );
};
