import { Box, Typography } from '@mui/material';

type Props = {
  status: string;
};

export const TransactionStatus = ({ status }: Props) => {
  const generateTransactionStatus = () => {
    switch (status) {
      case 'COMPLETED':
        return (
          <Box
            sx={{
              padding: '2px 8px',
              position: 'relative',
              backgroundColor: 'rgba(54, 179, 126)',
              borderRadius: '6px',
              width: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white', fontWeight: '700' }}
            >
              Successful
            </Typography>
          </Box>
        );
      case 'REJECTED':
        return (
          <Box
            sx={{
              padding: '2px 8px',
              position: 'relative',
              backgroundColor: 'rgba(255, 86, 48)',
              borderRadius: '6px',
              width: '92px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white', fontWeight: '700' }}
            >
              Unsuccessful
            </Typography>
          </Box>
        );
      case 'REFUNDED':
        return (
          <Box
            sx={{
              padding: '2px 8px',
              position: 'relative',
              backgroundColor: '#0E445C',
              borderRadius: '6px',
              width: '92px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '12px', lineHeight: '20px', color: 'white', fontWeight: '700' }}
            >
              Refunded
            </Typography>
          </Box>
        );
      default:
        break;
    }
  };

  return <Box>{generateTransactionStatus()}</Box>;
};
