import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  showCreateProviderForm: false,
  showCancelProviderForm: false,
};

const createProviderSlice = createSlice({
  name: 'createProvider',
  initialState,
  reducers: {
    setShowCreateProviderForm: (state, { payload }) => {
      state.showCreateProviderForm = payload;
    },
    setShowCancelProviderForm: (state, { payload }) => {
      state.showCancelProviderForm = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: createProviderReducer } = createProviderSlice;
export const { setShowCreateProviderForm, setShowCancelProviderForm } = createProviderSlice.actions;
