// System
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Instances
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

const initialState = {
  scannedVehicles: [],
  scannedVehicle: null,
  showOperatorScanModal: false,
  showOperatorManualQRModal: false,
  showOperatorScanModalCancel: false,
};

// GET Selected Operator Vehicle
export const getSelectedOperatorScanVehicleByQR = createAsyncThunk(
  'operatorScan/getSelectedOperatorVehicle',
  async (qrCode: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/vehicles/qr/${qrCode}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

const operatorScanSlice = createSlice({
  name: 'operatorScanSlice',
  initialState,
  reducers: {
    setShowOperatorScanModal: (state, { payload }) => {
      state.showOperatorScanModal = payload;
    },
    setShowOperatorQRModal: (state, { payload }) => {
      state.showOperatorManualQRModal = payload;
      state.showOperatorScanModal = !payload;
    },
    setOperatorScannedVehicles: (state, { payload }) => {
      state.scannedVehicles = payload;
    },
    setShowOperatorCancelModal: (state, { payload }) => {
      state.showOperatorScanModalCancel = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: operatorScanReducer } = operatorScanSlice;
export const {
  setShowOperatorQRModal,
  setShowOperatorScanModal,
  setOperatorScannedVehicles,
  setShowOperatorCancelModal,
} = operatorScanSlice.actions;
