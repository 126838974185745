import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TransactionRefundFormContext,
  TransactionRefundFormState,
  TransactionRefundFormStateDefault,
  TransactionRefundSchemaDefault,
} from '@Forms/transactions/TransactionRefundFormContext';

export const TransactionRefundFormProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const [schema, setSchema] = useState<ObjectSchema<Partial<TransactionRefundFormState>>>(
    TransactionRefundSchemaDefault
  );
  const methods = useForm({
    defaultValues: TransactionRefundFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <TransactionRefundFormContext.Provider value={value}>
      {children}
    </TransactionRefundFormContext.Provider>
  );
};
