import { TableColumnDescription } from '@contracts/view/TableColumnDescription';
import { TableCellEx, TableRowEx } from '../../components';

type Row = Record<string, unknown>;
type Props<R extends Row, C extends TableColumnDescription<R, any, any>> = {
  id: number | string;
  columns: any[];
  row: R;
  onClick?: () => void;
};

export const TableRowPresenter = <R extends Row, C extends TableColumnDescription<R, any, any>>({
  id,
  columns,
  row,
  onClick,
}: Props<R, C>) => {
  return (
    <TableRowEx onClick={onClick} data-at-table-row-id={id}>
      {columns.map(({ name, sx, body: Body }) => (
        <TableCellEx key={name} sx={sx} data-at-table-cell={name}>
          <Body row={row} />
        </TableCellEx>
      ))}
    </TableRowEx>
  );
};
