import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import dayjs from 'dayjs';
import { useAnalyticsTable } from '@Modal/hooks/analytics/useAnalyticsTable';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { formatDateForChart } from '../DateGeneratorForAnalyticsTable/DateGeneratorForAnalyticsTable';
import { useDispatch } from 'react-redux';

export const AnalyticsChart: React.FC = () => {
  const dispatch = useDispatch();
  const { tableFilters, setTableFilters, chartXAxis, chartYAxis, chartValue, setChartValue } =
    useAnalyticsTable();
  const [chartStartDate, setChartStartDate] = useState<string>(tableFilters?.tableStartDate);
  const [chartEndDate, setChartEndDate] = useState<string>(tableFilters?.tableEndDate);

  const handleStartDateChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChartStartDate(event?.target?.value);
    },
    []
  );

  const handleEndDateChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setChartEndDate(event?.target?.value);
    },
    []
  );

  const handleConfirm = () => {
    const updatedChartEndDate = new Date(chartEndDate).setHours(23, 59, 59, 0);
    dispatch(setTableFilters({ codeName: 'tableStartDate', value: chartStartDate }));
    dispatch(setTableFilters({ codeName: 'tableEndDate', value: updatedChartEndDate }));
  };

  useEffect(() => {
    if (tableFilters?.tableStartDate) setChartStartDate(tableFilters?.tableStartDate);
    if (tableFilters?.tableEndDate) setChartEndDate(tableFilters?.tableEndDate);
  }, [tableFilters]);

  // @ts-ignore
  const chartXAxisConverted = chartXAxis?.map((date) => new Date(date));

  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <Box sx={{ width: '1300px', marginLeft: '20px' }}>
        <LineChart
          sx={{ padding: '10px' }}
          xAxis={[
            {
              label: 'Date',
              data: chartXAxisConverted,
              tickInterval: chartXAxis,
              scaleType: 'time',
              valueFormatter: (date) => dayjs(date).format('MMM D'),
            },
          ]}
          series={[
            {
              // @ts-ignore
              data: chartYAxis,
            },
          ]}
          width={1200}
          height={500}
        />
      </Box>
      <Stack marginTop="16px" gap={3}>
        <TextField
          select
          value={chartValue}
          onChange={(e) => dispatch(setChartValue(e?.target?.value))}
        >
          <MenuItemEx key="BetAmount" value="totalBetAmount" name="BetAmount">
            Bet Amount
          </MenuItemEx>
          <MenuItemEx key="Bets" value="totalBets" name="Bets">
            Bets
          </MenuItemEx>
          <MenuItemEx key="Won" value="totalWonAmount" name="Won">
            Won
          </MenuItemEx>
          <MenuItemEx key="Round" value="numberOfRounds" name="Round">
            Round
          </MenuItemEx>
        </TextField>
        <Typography sx={{ color: 'grey', marginLeft: '5px' }}>Date Filter</Typography>
        <TextField
          size="small"
          label="Start Date"
          type="date"
          data-at-text-field="StartDateFilter"
          value={formatDateForChart(chartStartDate)}
          onChange={handleStartDateChange}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          size="small"
          label="End Date"
          type="date"
          data-at-text-field="StartDateFilter"
          value={formatDateForChart(chartEndDate)}
          onChange={handleEndDateChange}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />

        <Button type="submit" variant="contained" onClick={handleConfirm}>
          Filter Date
        </Button>
      </Stack>
    </Stack>
  );
};
