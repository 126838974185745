enum FilterTitlesEnum {
  serviceState = 'serviceState',
  operational = 'operational',
  outOfOrder = 'outOfOrder',
  autoServiceState = 'autoServiceState',
  batteryLevel = 'batteryLevel',
  iotStatus = 'iotStatus',
  rentalStatus = 'rentalStatus',
  powerStatus = 'powerStatus',
  areaType = 'areaType',
  Branch = 'Branch',
}

export enum VehicleServiceStateEnum {
  FUNCTIONAL = 'FUNCTIONAL',
  INSPECT = 'INSPECT',
  READY_FOR_DEPLOYMENT = 'READY_FOR_DEPLOYMENT',
  COLLECT = 'COLLECT',
  SERVICE_ON_SITE = 'SERVICE_ON_SITE',
  SERVICE_WORKSHOP = 'SERVICE_WORKSHOP',
  REPAIR = 'REPAIR',
  MISSING_SPARE_PART = 'MISSING_SPARE_PART',
  IMPOUNDED = 'IMPOUNDED',
  SUBMERGED = 'SUBMERGED',
  HIBERNATION = 'HIBERNATION',
  LOST = 'LOST',
  SCRAP = 'SCRAP',
  OTHER = 'OTHER',
  APPROACH = 'APPROACH',
}

enum BatteryLevel {
  UNKNOWN = 'UNKNOWN',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  CRITICAL = 'CRITICAL',
}

enum IotModuleState {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  RETIRED = 'RETIRED',
  NEW = 'NEW',
}

enum RentalStatusEnum {
  UNKNOWN = 'UNKNOWN',
  RIDE_READY = 'RIDE_READY',
  DRIVING = 'DRIVING',
  PARKING = 'PARKING',
}

enum VehiclePowerStatus {
  UNKNOWN = 'UNKNOWN',
  ON = 'ON',
  OFF = 'OFF',
}

enum MapZoneTypeEnum {
  OPERATIONAL = 'OPERATIONAL',
  NO_PARKING = 'NO_PARKING',
  NO_PARKING_NO_RIDING = 'NO_PARKING_NO_RIDING',
  SLOW = 'SLOW',
  HIGH_RISK = 'HIGH_RISK',
}

export const FiltersLabel: { [key: string]: string } = {
  [FilterTitlesEnum.Branch]: 'Branch',
  [FilterTitlesEnum.serviceState]: 'Service State',
  [FilterTitlesEnum.operational]: 'Operational',
  [FilterTitlesEnum.outOfOrder]: 'Out of order',
  [FilterTitlesEnum.autoServiceState]: 'Auto-Service State',
  [FilterTitlesEnum.batteryLevel]: 'Battery Level',
  [FilterTitlesEnum.iotStatus]: 'IoT Status',
  [FilterTitlesEnum.rentalStatus]: 'Rental Status',
  [FilterTitlesEnum.powerStatus]: 'Power Status',
  [FilterTitlesEnum.areaType]: 'Area Type',
  [VehicleServiceStateEnum.FUNCTIONAL]: 'Functional',
  [VehicleServiceStateEnum.INSPECT]: 'Inspect',
  [VehicleServiceStateEnum.READY_FOR_DEPLOYMENT]: 'Ready for deployment',
  [VehicleServiceStateEnum.COLLECT]: 'Collect',
  [VehicleServiceStateEnum.SERVICE_ON_SITE]: 'Service (on site)',
  [VehicleServiceStateEnum.SERVICE_WORKSHOP]: 'Service (workshop)',
  [VehicleServiceStateEnum.REPAIR]: 'Repair',
  [VehicleServiceStateEnum.MISSING_SPARE_PART]: 'Missing spare part',
  [VehicleServiceStateEnum.IMPOUNDED]: 'Impounded',
  [VehicleServiceStateEnum.SUBMERGED]: 'Submerged',
  [VehicleServiceStateEnum.HIBERNATION]: 'Hibernation',
  [VehicleServiceStateEnum.LOST]: 'Lost',
  [VehicleServiceStateEnum.SCRAP]: 'Scrap',
  [VehicleServiceStateEnum.OTHER]: 'Other',
  [VehicleServiceStateEnum.APPROACH]: 'Approach',
  [BatteryLevel.CRITICAL]: 'Critical',
  [BatteryLevel.HIGH]: 'High',
  [BatteryLevel.LOW]: 'Low',
  [BatteryLevel.MEDIUM]: 'Medium',
  [BatteryLevel.UNKNOWN]: 'Unknown',
  [IotModuleState.ASSIGNED]: 'Assigned',
  [IotModuleState.NEW]: 'New',
  [IotModuleState.RETIRED]: 'Retired',
  [IotModuleState.UNASSIGNED]: 'Unassigned',
  [RentalStatusEnum.DRIVING]: 'Driving',
  [RentalStatusEnum.PARKING]: 'Parking',
  [RentalStatusEnum.RIDE_READY]: 'Ride Ready',
  [VehiclePowerStatus.OFF]: 'Off',
  [VehiclePowerStatus.ON]: 'On',
  [MapZoneTypeEnum.HIGH_RISK]: 'High Risk',
  [MapZoneTypeEnum.NO_PARKING]: 'No-Parking',
  [MapZoneTypeEnum.NO_PARKING_NO_RIDING]: 'No-Parking & No-Riding',
  [MapZoneTypeEnum.OPERATIONAL]: 'Operational',
  [MapZoneTypeEnum.SLOW]: 'Slow',
};
