import {
  SelectBox,
  SelectBoxProvider,
  SelectBoxTextFieldInput,
  useManageSelectBox,
} from '../SelectBox';

type Props = {
  name: string;
  placeholder: string;
  children: JSX.Element;
  error?: boolean;
  helperText?: React.ReactNode;
};

const CheckmarkSelectBox: React.FC<Props> = ({
  name,
  children,
  placeholder,
  error,
  helperText,
}) => {
  const { handleOpen } = useManageSelectBox();
  return (
    <SelectBox
      name={name}
      fullWidth
      input={
        <SelectBoxTextFieldInput
          onClick={handleOpen}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      }
      dropDown={children}
    />
  );
};

export const CheckmarkSelect: React.FC<Props> = (props) => {
  return (
    <SelectBoxProvider>
      <CheckmarkSelectBox {...props} />
    </SelectBoxProvider>
  );
};
