import { PricingItemType } from '@Modal/reducers/pricing/types';
import { SetupPricingFormStateDTO } from '@Forms/pricing/PricingDrawer/SetupPricingFormContext';

export const useSetupPricingUseCaseMapper = () => {
  return {
    toFormState: (pricing: PricingItemType): SetupPricingFormStateDTO => {
      return {
        unlockPrice: pricing.unlockPrice,
        minutePrice: pricing.minutePrice,
        parkingPrice: pricing.parkingPrice,
      };
    },
  };
};
