import { ComponentProps, useCallback, useState, useEffect } from 'react';
import { TextField } from '@mui/material';

import { IntegerFormatOptions, useIntegerFormat } from '@Components/shared/hooks/useIntegerFormat';
import { CustomChangeEventHandler } from '@contracts/view/CustomChangeEvent';
import { TextFileldChangeEventHandler } from '@contracts/view/TextFileldChangeEvent';

type Props = Omit<ComponentProps<typeof TextField>, 'value' | 'onBlur' | 'onChange'> & {
  value: number | null;
  formatOptions?: IntegerFormatOptions;
  onChange?: CustomChangeEventHandler<number | null>;
  onBlur?: CustomChangeEventHandler<number | null>;
};
export const NullableIntegerField: React.FC<Props> = ({
  value,
  formatOptions,
  onBlur,
  onChange,
  ...rest
}) => {
  const { correct, format, validate, parse } = useIntegerFormat(formatOptions ?? {});

  const [draftValue, setDraftValue] = useState(value);
  const [text, setText] = useState(value !== null && Number.isFinite(value) ? format(value) : '');

  useEffect(() => {
    if (!Number.isFinite(value) && Number.isFinite(draftValue)) {
      setDraftValue(value);
      setText('');
      return;
    }
    if (value !== null && Number.isFinite(value) && !Number.isFinite(draftValue)) {
      setDraftValue(value);
      setText(format(value));
      return;
    }

    if (value !== null && Number.isFinite(value) && value !== draftValue) {
      setDraftValue(value);
      setText(format(value));
    }
  }, [value]);

  const handleBlur: TextFileldChangeEventHandler = useCallback(
    (e) => {
      const corrected = correct(e.target.value);
      if (!validate(corrected)) {
        setText(value !== null && Number.isFinite(value) ? format(value) : '');
        setDraftValue(value);
        onBlur?.({ target: { value } });
        return;
      }

      const intValue = parse(corrected);
      if (!Number.isFinite(intValue) || intValue === draftValue) {
        setText(value !== null && Number.isFinite(value) ? format(value) : '');
        setDraftValue(value);
        onBlur?.({ target: { value } });
        return;
      }

      setDraftValue(intValue);
      if (intValue === value) {
        setText(Number.isFinite(value) ? format(value) : '');
        onBlur?.({ target: { value } });
        return;
      }

      setText(format(intValue));
      onChange?.({ target: { value: intValue } });
      onBlur?.({ target: { value: intValue } });
    },
    [correct, onChange, parse, setText, setDraftValue, draftValue, validate, value, format, onBlur]
  );

  const handleChange: TextFileldChangeEventHandler = useCallback(
    (e) => {
      const corrected = correct(e.target.value);
      setText(corrected);

      if (corrected === '' && value !== null) {
        setDraftValue(null);
        onChange?.({ target: { value: null } });
        return;
      }

      if (!validate(corrected)) {
        return;
      }

      const intValue = parse(corrected);
      if (!Number.isFinite(intValue) || intValue === draftValue) {
        return;
      }

      setDraftValue(intValue);
      if (intValue === value) {
        return;
      }

      onChange?.({ target: { value: intValue } });
    },
    [correct, onChange, parse, setText, setDraftValue, draftValue, validate, value]
  );

  return <TextField value={text} {...rest} onChange={handleChange} onBlur={handleBlur} />;
};
