import { ButtonProps } from '@mui/material';

import { CancelDialogRejectAction } from './CancelDialogRejectAction';
import { CancelDialogConfirmAction } from './CancelDialogConfirmAction';

type Props = {
  onConfirm?: () => void;
  onReject?: () => void;
  color?: ButtonProps['color'];
};

export const CancelDialogActions: React.FC<Props> = ({ onConfirm, onReject, color }) => {
  return (
    <>
      <CancelDialogRejectAction onClick={onReject}>Take me back</CancelDialogRejectAction>
      <CancelDialogConfirmAction onClick={onConfirm} color={color}>
        Yes cancel
      </CancelDialogConfirmAction>
    </>
  );
};
