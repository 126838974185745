import {
  defaultCampaignListFilter,
  campaignListColumnVisibilityOptions,
  defaultCampaignListColumnVisibility,
} from '@utils/tables/campaigns/campaignListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  limit: 25,
  page: 1,
  total: 0,
  campaignTableFilters: {},
  selectedFilters: defaultCampaignListFilter,
  columnVisibility: defaultCampaignListColumnVisibility,
  columnVisibilityOptions: campaignListColumnVisibilityOptions,
};

const campaignTableSlice = createSlice({
  name: 'campaignTable',
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setCampaignTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { campaignTableFilters } = state;
      const convertedValue = typeof value !== 'object' ? value : value[0];

      const newCampaignTableFilters = {
        ...campaignTableFilters,
        [codeName]: convertedValue,
      };
      state.campaignTableFilters = newCampaignTableFilters;
    },
  },
  extraReducers: {},
});

export const { reducer: campaignTableReducer } = campaignTableSlice;
export const { setColumnVisibility, setLimit, setPage, setTotal, setCampaignTableFilters } =
  campaignTableSlice.actions;
