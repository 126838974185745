import { Comparer } from '@contracts/common/Comparer';

export const nullableComparer = <T>(comparer: Comparer<T>): Comparer<T | null | undefined> => {
  return (a, b) => {
    if (a === null || a === undefined) {
      return 1;
    }
    if (b === null || b === undefined) {
      return -1;
    }
    return comparer(a, b);
  };
};
