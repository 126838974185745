import React, { ReactElement } from 'react';
import { Stack, Theme, Typography, useTheme } from '@mui/material';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { SystemStyleObject } from '@mui/system';

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      height: '50px',
      width: '50px',
      backgroundColor: palette.background.default,
      marginRight: 1,
      ...roundedBorder,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

type props = {
  title: string;
  state: string | ReactElement;
  icon: ReactElement;
};

export const DetailsItem = ({ title, state, icon }: props) => {
  const styles = useSx();

  return (
    <Stack direction="row">
      <Stack justifyContent="center" alignItems="center" sx={styles.container}>
        {icon}
      </Stack>
      <Stack direction="column" justifyContent="center">
        <Typography variant="subtitle2">{title}</Typography>
        <Typography variant="subtitle2">{state}</Typography>
      </Stack>
    </Stack>
  );
};
