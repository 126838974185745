import { useCallback, useContext, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

import { GoogleMapsContainerContext } from './GoogleMapsContainerContext';

export const GoogleMapsContainer: React.FC<{
  children?: JSX.Element | (JSX.Element | boolean)[] | undefined | null | boolean;
  onClick?: ((e: google.maps.MapMouseEvent) => void) | undefined;
}> = ({ children, onClick }) => {
  const {
    zoom: [zoom, setZoom],
    center: [center, setCenter],
    options: mapOptions,
    mapContainerStyle,
  } = useContext(GoogleMapsContainerContext);
  const [defaultZoom] = useState(zoom);
  const [defaultCenter] = useState(center);

  const onLoad = useCallback((map: google.maps.Map) => {
    map.addListener('zoom_changed', () => {
      setZoom(map.getZoom());
    });
    map.addListener('center_changed', () => {
      setCenter(map.getCenter()?.toJSON());
    });
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={defaultZoom}
      center={defaultCenter}
      options={mapOptions}
      onLoad={onLoad}
      onClick={onClick}
    >
      {children}
    </GoogleMap>
  );
};
