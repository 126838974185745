/* eslint-disable object-shorthand */
import { ObjectSchema, object, string } from 'yup';

import { useMemo } from 'react';
import { SetupReplaceQRFormStateDTO } from '@Forms/operator/SetupReplaceQRFormContext';

export const useSetupReplaceQRUseCaseSchema = (): ObjectSchema<
  Partial<SetupReplaceQRFormStateDTO>
> => {
  return useMemo(
    () =>
      object<SetupReplaceQRFormStateDTO>().shape({
        qrCode: string().required('QR code is required'),
      }),
    []
  );
};
