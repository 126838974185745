import { Stack, Typography } from '@mui/material';

import { RHFSelect, RHFTextField } from '@Components/hook-form';

import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useBranchForParentList } from '@Hooks/branches/useBranchForParentList';

const useDropDown = () => {
  const { selectedBranch } = useBranches();

  const branchesForParent = useBranchForParentList()?.filter(
    ({ level: l }) => l === selectedBranch?.level - 1
  );
  const branchesForParentDropDown = branchesForParent?.map(({ id, name }) => (
    <MenuItemEx key={id} value={id} name="Branch">
      {name}
    </MenuItemEx>
  ));
  return {
    branchesForParent: branchesForParentDropDown,
  };
};

export const GeneralInformation = () => {
  const { activeBrandId } = useBranches();
  const dropDown = useDropDown();

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <AtLocator type="readonly-text" name="GeneralInformation">
          <Typography variant="button">General information</Typography>
        </AtLocator>
        <AtLocator type="readonly-text" name="id">
          <Typography variant="body2" color="textSecondary">
            ID: {activeBrandId}
          </Typography>
        </AtLocator>
      </Stack>
      <Stack direction="column" spacing={1.5}>
        <AtLocator type="text-field" name="name">
          <RHFTextField name="name" id="setup-branch-name" label="Name" variant="outlined" />
        </AtLocator>
        <AtLocator type="select-field" name="parentId">
          <RHFSelect label="Parent" id="setup-branch-name" name="parentId" variant="outlined">
            {dropDown.branchesForParent}
          </RHFSelect>
        </AtLocator>
      </Stack>
    </Stack>
  );
};
