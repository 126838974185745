// System
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Utils
import { asEditable } from '@contracts/common/EditStatus';
import { fromGeoJSONMultiPoligon } from '@utils/map/geoJSON';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';
import { CreateMapZoneDto } from '@contracts/api/dto/maps/CreateMapZoneDto';
import { UpdateMapZoneDto } from '@contracts/api/dto/maps/UpdateMapZoneDto';

// Types
import { MapZoneItemType } from './types';

export const getMapZonesList = createAsyncThunk(
  'branches/getMapZonesList',
  async (params: any, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`/v1/map-zones`, {
        params,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const createMapZone = createAsyncThunk(
  'branches/createMapZone',
  async (data: CreateMapZoneDto, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post(`/v1/map-zones`, {
        ...data,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const updateMapZone = createAsyncThunk(
  'branches/updateMapZone',
  async (data: UpdateMapZoneDto, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/map-zones/${data.id}`, {
        branchId: data.branchId,
        categoryId: data.categoryId,
        type: data.type,
        geometry: data.geometry,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const deleteMapZone = createAsyncThunk(
  'branches/deleteMapZone',
  async (id: string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .delete(`/v1/map-zones/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const clearMapZones = createAsyncThunk(
  'branches/clearMapZones',
  async (id: string, thunkApi) => {
    return (
      axiosBranchesVehiclesInstance
        // TODO update url when endpoint will be created
        .delete(`-------API------${id}`)
        .then((res) => {
          return thunkApi.fulfillWithValue(res.data);
        })
        .catch((error) => {
          return thunkApi.rejectWithValue(error?.message);
        })
    );
  }
);

// Type
const initialState = {
  mapPaths: [],
  mapZoneList: [],
  newMapZoneList: [],
  activeMapZoneType: 'ALL',
  mode: 'view',
  showMapZoneDialog: false,
  activeCategoryId: null,
  defaultCenter: {
    lat: 41.716667,
    lng: 44.783333,
  },
  defaultZoom: 13,
  showDeleteMapZoneDialog: false,
  selectedMapZoneIndex: null,
  selectedMapZoneId: null,
  showCancelMapZoneDialog: false,
  showClearMapZoneDialog: false,
};

const mapZoneSlice = createSlice({
  name: 'branchMaps',
  initialState,
  reducers: {
    setMapZoneList: (state, { payload }) => {
      state.mapZoneList = payload;
    },
    setMapPaths: (state, { payload }) => {
      state.mapPaths = payload;
    },
    setNewMapZoneList: (state, { payload }) => {
      state.mapZoneList = payload;
    },
    setActiveMapZoneType: (state, { payload }) => {
      state.activeMapZoneType = payload;
    },
    setActiveCategoryId: (state, { payload }) => {
      state.activeCategoryId = payload;
    },
    setShowMapZoneDialog: (state, { payload }) => {
      state.showMapZoneDialog = payload;
    },
    setShowDeleteMapZoneDialog: (state, { payload }) => {
      state.showDeleteMapZoneDialog = payload;
    },
    setShowClearMapZoneDialog: (state, { payload }) => {
      state.showClearMapZoneDialog = payload;
    },
    setShowCancelMapZoneDialog: (state, { payload }) => {
      state.showCancelMapZoneDialog = payload;
    },
    setShowMap: (state, { payload }) => {
      state.showDeleteMapZoneDialog = payload;
    },
    setSelectedMapZoneIndex: (state, { payload }) => {
      state.selectedMapZoneIndex = payload;
    },
    setSelectedMapZoneId: (state, { payload }) => {
      state.selectedMapZoneId = payload;
    },
    setChangeMapMode: (state, { payload }) => {
      state.mode = payload;
    },
  },
  extraReducers: {
    [getMapZonesList.fulfilled?.type]: (state, action) => {
      const { items } = action.payload;

      const modifiedListData = items
        ? items
            .map((zone: MapZoneItemType) => ({
              id: zone.id,
              categoryId: zone.categoryId,
              mapZoneType: zone.type,
              brandId: zone.brandId,
              geometry: fromGeoJSONMultiPoligon(zone.geometry.coordinates),
            }))
            .map(asEditable)
        : [];

      state.mapZoneList = modifiedListData;
    },
    [updateMapZone.fulfilled?.type]: (state, action) => {
      const { items } = action.payload;

      const modifiedListData = items
        ? items
            .map((zone: MapZoneItemType) => ({
              id: zone.id,
              categoryId: zone.categoryId,
              mapZoneType: zone.type,
              brandId: zone.brandId,
              geometry: fromGeoJSONMultiPoligon(zone.geometry.coordinates),
            }))
            .map(asEditable)
        : [];

      state.mapZoneList = modifiedListData;
      // state.showMapZoneDialog = false;
    },
  },
});

export const { reducer: mapZoneReducer } = mapZoneSlice;
export const {
  setMapPaths,
  setMapZoneList,
  setChangeMapMode,
  setNewMapZoneList,
  setActiveCategoryId,
  setActiveMapZoneType,
  setSelectedMapZoneId,
  setShowMapZoneDialog,
  setSelectedMapZoneIndex,
  setShowDeleteMapZoneDialog,
  setShowCancelMapZoneDialog,
  setShowClearMapZoneDialog,
} = mapZoneSlice.actions;
