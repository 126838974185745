import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelModal,
  CancelDialogMessageLayout,
} from '@Components/shared/dialogs/CancelDialog';

export const SimpleCreateBranchCancelModalDialog: React.FC = () => {
  // const { canceling } = useCreateBranchUseCaseStatus(CreateBranchDialogType.Simple);
  // const { handleConfirmCancel, handleRejectCancel } = useCreateBranchUseCase();
  return (
    <>
      {/* {canceling && (
        <CancelModal open={canceling}>
          <ImageCancelDialogLayout
            title="Cancel branch changes?"
            actions={
              <CancelDialogActions onReject={handleRejectCancel} onConfirm={handleConfirmCancel} />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made in branch settings
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )} */}
    </>
  );
};
