import { useTheme } from '@mui/material';

import { LabelVariant } from '@Components/label';
import { VehicleServiceState } from '@contracts/domain/keys';
import { ColorCircleLabel } from '@Components/shared/labels/ColorCircleLabel';
import { useVehicleServiceStateColor, useVehicleServiceStateTitle } from './useVehicleServiceState';

// @TODO: Refactor this component using FSD approach

type Props = {
  status?: VehicleServiceState;
  variant: LabelVariant | 'ghost';
};

export const VehicleServiceStateLabel: React.FC<Props> = ({ status }) => {
  const { palette } = useTheme();
  const title = useVehicleServiceStateTitle()[status ?? 'Unknown'];
  const colors = useVehicleServiceStateColor();
  const color = status ? colors[status] : palette.action.disabled;
  return <ColorCircleLabel color={color} title={title} variant="ghost" />;
};
