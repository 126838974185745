import { MenuItem, MenuItemProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = Omit<MenuItemProps, 'sx'> & {
  sx?: SystemStyleObject<Theme>;
  name: string;
};

export const MenuItemEx: React.FC<Props> = ({ name, value, sx, ...rest }) => {
  return (
    <MenuItem
      {...rest}
      data-at-menu-item={name}
      data-at-item-value={value}
      sx={[{ '& .MuiMenuItem-root': { textTransform: 'none' } }, sx ?? {}]}
    />
  );
};
