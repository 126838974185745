import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelDialogMessageLayout,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';
import { useCreatePricing } from '@Modal/hooks/pricing/useCreatePricing';

export const CreatePricingCancelModalDialog: React.FC = () => {
  const { showCancelPricingForm, handleCancelTakeMeBack } = useCreatePricing();
  return (
    <>
      {showCancelPricingForm && (
        <CancelModal open={showCancelPricingForm}>
          <ImageCancelDialogLayout
            title="Cancel create pricing?"
            actions={
              <CancelDialogActions
                onReject={() => handleCancelTakeMeBack('back')}
                onConfirm={() => handleCancelTakeMeBack('cancel')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to delete all the changes you have made while create pricing
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
