import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import { EnumFilterValue } from '@contracts/common/EnumFilterValue';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';
import { SortParams } from '@contracts/common/params/SortParams';

export const pricingListColumns = [
  'pricing.id',
  'pricing.name',
  'pricing.secondLvlBranch',
  'pricing.thirdLvlBranch',
  'pricing.vehicleType',
  'pricing.vehicleCategory',
  'pricing.unlockPrice',
  'pricing.minutePrice',
  'pricing.parkingPrice',
] as const;

export type PricingListColumns = (typeof pricingListColumns)[number];

export type PricingListFilter = {
  'pricing.id': string | undefined;
  'pricing.name': string | undefined;
  'pricing.secondLvlBranch': EnumFilterValue<string>;
  'pricing.thirdLvlBranch': EnumFilterValue<string>;
  'pricing.vehicleType': EnumFilterValue<string>;
  'pricing.vehicleCategory': EnumFilterValue<string>;
  'pricing.unlockPrice': string | undefined;
  'pricing.minutePrice': string | undefined;
  'pricing.parkingPrice': string | undefined;
  limit: number;
};

export const defaultPricingListFilter: PricingListFilter = {
  'pricing.id': undefined,
  'pricing.unlockPrice': undefined,
  'pricing.minutePrice': undefined,
  'pricing.parkingPrice': undefined,
  'pricing.name': undefined,
  'pricing.secondLvlBranch': {},
  'pricing.thirdLvlBranch': {},
  'pricing.vehicleType': {},
  'pricing.vehicleCategory': {},
  limit: 25,
};

export type PricingListSortColumns =
  | 'pricing.id'
  | 'pricing.unlockPrice'
  | 'pricing.minutePrice'
  | 'pricing.parkingPrice';
export type PricingListSort = SortParams<PricingListSortColumns> | undefined;
export const defaultPricingListSort: PricingListSort = undefined;

export const pricingListColumnVisibilitySettings = {
  'pricing.id': ColumnVisibilityType.Visible,
  'pricing.name': ColumnVisibilityType.Visible,
  'pricing.secondLvlBranch': ColumnVisibilityType.Visible,
  'pricing.thirdLvlBranch': ColumnVisibilityType.Visible,
  'pricing.vehicleType': ColumnVisibilityType.Visible,
  'pricing.vehicleCategory': ColumnVisibilityType.Visible,
  'pricing.unlockPrice': ColumnVisibilityType.Visible,
  'pricing.minutePrice': ColumnVisibilityType.Visible,
  'pricing.parkingPrice': ColumnVisibilityType.Visible,
} satisfies ColumnVisibilitySettings<PricingListColumns>;

export const defaultPricingListColumnVisibility = defaultColumnVisibilityParams(
  pricingListColumnVisibilitySettings
);
export const pricingListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  pricingListColumns,
  pricingListColumnVisibilitySettings
);
