import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { Table, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = DataAttributeProps & {
  name: string;
  children: JSX.Element | JSX.Element[];
  sx?: SystemStyleObject<Theme>;
};

export const TableEx: React.FC<Props> = ({ children, sx, name, ...dataProps }) => {
  return (
    <Table sx={[{ width: '100%' }, sx ?? {}]} stickyHeader data-at-table={name} {...dataProps}>
      {children}
    </Table>
  );
};
