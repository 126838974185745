// System
import { SystemStyleObject } from '@mui/system';
import { Stack, ToggleButtonGroup, ToggleButton, Theme } from '@mui/material';

// Components
import { MapEditorMode } from './MapEditorMode';
import { ButtonSelect } from '../shared/ButtonSelect';

// Redux
import { useDispatch } from 'react-redux';
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';
import { useGeneralStore } from '@Modal/hooks/useGeneralStore';

const useSx = () => {
  return {
    button: { paddingY: 0.25 },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupMapZoneHeader: React.FC = () => {
  const sx = useSx();
  const dispatch = useDispatch();
  const { setChangeMapMode, mode, activeMapZoneType, setActiveMapZoneType } = useBranchesMaps();
  const { mapZoneTypes } = useGeneralStore();
  const handleTabChange = (_: React.MouseEvent<HTMLElement>, newTab: MapEditorMode) =>
    dispatch(setChangeMapMode(newTab));

  // @ts-ignore
  const isAddDisabled = activeMapZoneType === 'ALL';
  const newOptions = mapZoneTypes.concat({ key: 'ALL', value: 'Show All' });

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <ToggleButtonGroup color="primary" value={mode} exclusive onChange={handleTabChange}>
        <ToggleButton value="view" sx={sx.button}>
          View
        </ToggleButton>
        <ToggleButton value="edit" sx={sx.button}>
          Edit
        </ToggleButton>
        <ToggleButton disabled={isAddDisabled} value="add" sx={sx.button}>
          Add
        </ToggleButton>
        <ToggleButton value="cut" sx={sx.button}>
          Cut
        </ToggleButton>
        <ToggleButton value="delete" sx={sx.button}>
          Delete
        </ToggleButton>
      </ToggleButtonGroup>
      <ButtonSelect
        name="MapZoneType"
        value={activeMapZoneType}
        onChange={(type: string) => dispatch(setActiveMapZoneType(type))}
        options={newOptions}
      />
    </Stack>
  );
};
