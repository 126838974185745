// Redux
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// Types
import { CreateWalletCreditType, CustomerSettleBalanceType } from '../types';

// GET
export const getSelectedCustomerBalanceTransactions = createAsyncThunk(
  'customers/getCustomerBalance',
  async (id: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/transactions/transactions-history/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

// GET WALLETS
export const getCustomerWallets = createAsyncThunk(
  'customers/getCustomerWallets',
  async (id: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/wallet?customerId=${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

// POST CREDIT
export const createWalletCredit = createAsyncThunk(
  'customer/createWalletCredit',
  async (walletData: CreateWalletCreditType, thunkApi) => {
    return axiosCustomerManagementInstance
      .post(`/wallet-credit`, {
        ...walletData,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// POST Settle Balance
export const customerSettleBalance = createAsyncThunk(
  'customer/settleBalance',
  async (settleBalanceData: CustomerSettleBalanceType, thunkApi) => {
    const { customerId, branchId } = settleBalanceData;
    return axiosCustomerManagementInstance
      .post(`/wallet/settle/${customerId}`, { branchId })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  selectedCustomerBalanceTransactions: [],
  customerWallets: [],
  selectedWalletId: '',
  selectedWallet: {},
  walletCreditType: 'CREDIT',
  showBalanceModalDialog: false,
  showCancelBalanceModalDialog: false,
};

const customerBalanceSlice = createSlice({
  name: 'customerBalance',
  initialState,
  reducers: {
    setCustomerWallets: (state, { payload }) => {
      state.customerWallets = payload;
    },
    setShowBalanceModalDialog: (state, { payload }) => {
      state.showBalanceModalDialog = payload;
    },
    setShowCancelBalanceModalDialog: (state, { payload }) => {
      state.showCancelBalanceModalDialog = payload;
    },
    setSelectedWalletId: (state, { payload }) => {
      state.selectedWalletId = payload;
    },
    setSelectedWallet: (state, { payload }) => {
      state.selectedWallet = payload;
    },
    setWalletCreditType: (state, { payload }) => {
      state.walletCreditType = payload;
    },
  },
  extraReducers: {
    [getSelectedCustomerBalanceTransactions.fulfilled?.type]: (state, action) => {
      state.selectedCustomerBalanceTransactions = action?.payload?.items;
    },
    [createWalletCredit.fulfilled?.type]: (state, { payload }) => {
      state.showBalanceModalDialog = false;
    },
    [getCustomerWallets.fulfilled?.type]: (state, action) => {
      state.customerWallets = action.payload;
      if (action.payload.length > 0) {
        state.selectedWalletId = action.payload[0].id;
      } else {
        state.selectedWalletId = '';
      }
      state.selectedWallet = action.payload[0];
    },
    [createWalletCredit.fulfilled?.type]: (state, { payload }) => {
      state.showBalanceModalDialog = false;
    },
    [getCustomerWallets.fulfilled?.type]: (state, action) => {
      state.customerWallets = action.payload;
      if (action.payload.length > 0) {
        state.selectedWalletId = action.payload[0].id;
      } else {
        state.selectedWalletId = '';
      }
      state.selectedWallet = action.payload[0];
    },
  },
});

export const { reducer: customerBalanceReducer } = customerBalanceSlice;
export const {
  setShowBalanceModalDialog,
  setShowCancelBalanceModalDialog,
  setSelectedWalletId,
  setSelectedWallet,
  setCustomerWallets,
  setWalletCreditType,
} = customerBalanceSlice.actions;
