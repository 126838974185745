import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const ThrottleOn = ({ width, height }: CardProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#D8FBDE" />
    <path
      d="M18.8333 9.66666L11.4112 18.5732C11.1205 18.922 10.9752 19.0964 10.9729 19.2437C10.971 19.3718 11.0281 19.4936 11.1277 19.5741C11.2423 19.6667 11.4693 19.6667 11.9233 19.6667H18L17.1666 26.3333L24.5888 17.4268C24.8794 17.078 25.0248 16.9036 25.027 16.7563C25.0289 16.6282 24.9719 16.5064 24.8723 16.4259C24.7577 16.3333 24.5307 16.3333 24.0766 16.3333H18L18.8333 9.66666Z"
      stroke="#36B37E"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
