import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, number } from 'yup';

export type CreateRoundConfigFormState = {
  bettingTime: number | null;
  waitingTime: number | null;
  maxCrashPoint: number | null;
  margin: number | null;
};

export const createRoundConfigFormStateDefault: CreateRoundConfigFormState = {
  bettingTime: null,
  waitingTime: null,
  maxCrashPoint: null,
  margin: null,
};

export const createRoundConfigSchemaDefault: ObjectSchema<Partial<CreateRoundConfigFormState>> =
  object<Partial<CreateRoundConfigFormState>>().shape({
    bettingTime: number().required('Betting time is required field'),
    waitingTime: number().required('Waiting time is required field'),
    maxCrashPoint: number().required('Max crash point is required field'),
    margin: number().required('Margin is required field'),
  });

type CreateRoundConfigFormContextType = {
  schema: ObjectSchema<Partial<CreateRoundConfigFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateRoundConfigFormState>>>;
  methods?: UseFormReturn<CreateRoundConfigFormState>;
};

const createRoundConfigFormContextDefault = {
  schema: createRoundConfigSchemaDefault,
  setSchema: noop,
} satisfies CreateRoundConfigFormContextType;

export const CreateRoundConfigFormContext = createContext<CreateRoundConfigFormContextType>(
  createRoundConfigFormContextDefault
);

export const useCreateRoundConfigForm = () => {
  const { methods } = useContext(CreateRoundConfigFormContext);
  if (!methods) {
    throw new Error('CreateProviderFormContext is empty');
  }

  return methods;
};

export const useManageCreateProviderSchema = () => {
  const { setSchema } = useContext(CreateRoundConfigFormContext);

  return { setSchema };
};
