import useResponsive from '@Hooks/shared/useResponsive';
import { Stack, Typography, Button } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { useDispatch } from 'react-redux';

export const AnalyticsTitle: React.FC = () => {
  const isDesktop = useResponsive('up', 'sm');
  const dispatch = useDispatch();

  return (
    <AtLocator type="container" name="analyticsTitle">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingBottom: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Analytics</Typography>
        </Stack>
      </Stack>
    </AtLocator>
  );
};
