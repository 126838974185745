import {
  defaultRoleListFilter,
  roleListColumnVisibilityOptions,
  defaultRoleListColumnVisibility,
} from '@utils/tables/users/roleListFilter';
// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultRoleListFilter,
  columnVisibility: defaultRoleListColumnVisibility,
  columnVisibilityOptions: roleListColumnVisibilityOptions,
};

const roleTableSlice = createSlice({
  name: 'roleTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: roleTableReducer } = roleTableSlice;
export const { setColumnVisibility } = roleTableSlice.actions;
