/* eslint-disable no-useless-escape */
/* eslint-disable object-shorthand */
import { ObjectSchema, number, object, string } from 'yup';
import { useMemo } from 'react';
import { SetupIotModuleFormState } from '@Forms/vehicles/SetupIotModuleFormContext';

export const useSetupIotModuleUseCaseSchema = (): ObjectSchema<
  Partial<SetupIotModuleFormState>
> => {
  return useMemo(
    () =>
      object<SetupIotModuleFormState>().shape({
        id: string().required('ID vehicle is required'),
        iotType: string().required('IoT Type is requried'),
        host: string()
          .required('Host is required')
          .matches(/^[\w\.-]+/, 'Host should not include special symbols except "-" and "."')
          .max(255),
        port: number()
          .typeError('Port is required')
          .required('Port is required')
          .max(65535, 'Port value should be applied from (0-65535)'),
        username: string()
          .matches(
            /^[\w !"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~]+/,
            'User should not include special symbols'
          )
          .min(8)
          .max(30)
          .nullable()
          .transform((value) => value || null),
        password: string()
          .matches(
            /^[\w !"#$%&'()*+,\-.\/:;<=>?@[\]\\^_`{|}~]+/,
            'User should not include special symbols'
          )
          .min(8)
          .max(30)
          .nullable()
          .transform((value) => value || null),
      }),
    []
  );
};
