export enum Roles {
  Customer = 'CUSTOMER',
  // SuperAdmin = 'SUPER_ADMIN',
  SuperAdmin = 'SuperAdmin',
  Admin = 'ADMIN',
  FleetManager = 'FLEET_MANAGER',
  FleetOperator = 'FLEET_OPERATOR',
  FleetMechanic = 'FLEET_MECHANIC',
  CustomerSupportL1 = 'CUSTOMER_SUPPORT_L1',
  CustomerSupportL2 = 'CUSTOMER_SUPPORT_L2',
  DataAnalyst = 'DATA_ANALYST',
  Accountant = 'ACCOUNTANT',
  MarketingManager = 'MARKETING_MANAGER',
}

export enum RolesForAddUser {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  FleetManager = 'FLEET_MANAGER',
  FleetOperator = 'FLEET_OPERATOR',
  FleetMechanic = 'FLEET_MECHANIC',
  CustomerSupportL1 = 'CUSTOMER_SUPPORT_L1',
  CustomerSupportL2 = 'CUSTOMER_SUPPORT_L2',
  DataAnalyst = 'DATA_ANALYST',
  Accountant = 'ACCOUNTANT',
  MarketingManager = 'MARKETING_MANAGER',
}

interface RolesIndex {
  [key: string]: string;
}

export const RolesIndex: RolesIndex = {
  CUSTOMER: 'Customer',
  // SUPER_ADMIN: 'Super admin',
  SuperAdmin: 'Super Admin',
  ADMIN: 'Admin',
  FLEET_MANAGER: 'Fleet manager',
  FLEET_OPERATOR: 'Fleet operator',
  FLEET_MECHANIC: 'Fleet mechanic',
  CUSTOMER_SUPPORT_L1: 'Customer support L1',
  CUSTOMER_SUPPORT_L2: 'Customer support L2',
  DATA_ANALYST: 'Data analyst',
  ACCOUNTANT: 'Accountant',
  MARKETING_MANAGER: 'Marketing manager',
};
