import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { TableCell, TableCellProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type Props = DataAttributeProps & {
  children: JSX.Element | JSX.Element[];
  sx?: SystemStyleObject<Theme>;
  align?: TableCellProps['align'];
};
export const TableHeadCellEx: React.FC<Props> = ({
  children,
  sx,
  align = 'left',
  ...dataProps
}) => {
  return (
    <TableCell sx={sx} {...dataProps} align={align ?? 'left'}>
      {children}
    </TableCell>
  );
};
