import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

import { UpdatePricingDTO } from './types';

// GET
export const getSinglePricing = createAsyncThunk(
  'pricing/getSinglePricing',
  async (id: number | string, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`v1/pricing/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

// PUT
export const updatePricing = createAsyncThunk(
  'pricing/updatePricingData',
  async (pricingData: UpdatePricingDTO, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .put(`/v1/pricing/${pricingData.pricingId}`, {
        ...pricingData.pricingFormState,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  openPricingDrawer: false,
  showCancelPricing: false,
  selectedPricing: null,
};

const pricingDrawerSlice = createSlice({
  name: 'pricingDrawer',
  initialState,
  reducers: {
    setShowPricingDrawer: (state, { payload }) => {
      state.openPricingDrawer = payload;
    },
    setShowCancelPricingDrawer: (state, { payload }) => {
      state.showCancelPricing = payload;
    },
    setSelectedPricing: (state, { payload }) => {
      state.selectedPricing = payload;
    },
  },
  extraReducers: {
    [getSinglePricing.fulfilled?.type]: (state, action) => {
      state.selectedPricing = action.payload;
    },
  },
});

export const { reducer: pricingDrawerReducer } = pricingDrawerSlice;
export const { setShowPricingDrawer, setSelectedPricing, setShowCancelPricingDrawer } =
  pricingDrawerSlice.actions;
