// System
import React from 'react';
import { Box } from '@mui/system';

// Features
import { ToggleableContentMobile } from '@Features/maps/Filters/ToggleableContentOperator/ToggleableContentOperator';

// Components
import { OperatorFilterTag } from '@Components/operator/OperatorFilterTag';
import { OperatorFilterLabelCard } from '@Components/operator/OperatorFilterLabelCard';

// Types
import { LiveMapServiceStateFilterType } from '@Modal/hooks/vehicles/types';

type Props = {
  serviceState: LiveMapServiceStateFilterType[];
  outOfOrderState: LiveMapServiceStateFilterType[];
  handleFilterVehiclesByService: (arg: LiveMapServiceStateFilterType) => void;
};

export const OperatorVehicleServiceState: React.FC<Props> = ({
  handleFilterVehiclesByService,
  serviceState,
  outOfOrderState,
}) => {
  // Data
  const serviceStateOptions = [
    {
      labelData: { title: 'Operational', value: 'OPERATIONAL' },
      helperTxt: 'Filter out all operational vehicles',
      checkBockHandler: () =>
        handleFilterVehiclesByService({
          title: 'Operational',
          value: 'OPERATIONAL',
          isChecked: serviceState.every((va) => va.isChecked),
          operational: true,
          key: 'serviceState',
        }),
      filterOptions: serviceState,
      isChecked: serviceState.every((va) => va.isChecked),
    },
    {
      labelData: { title: 'Out of order', value: 'OUT_OF_ORDER' },
      helperTxt: 'Vehicles that are unavailable',
      checkBockHandler: () =>
        handleFilterVehiclesByService({
          title: 'Out of order',
          value: 'OUT_OF_ORDER',
          isChecked: outOfOrderState.every((va) => va.isChecked),
          operational: false,
          key: 'serviceState',
        }),
      filterOptions: outOfOrderState,
      isChecked: outOfOrderState.every((va) => va.isChecked),
    },
  ];

  return (
    <Box>
      <ToggleableContentMobile name="Service State" key="OperatorVehicleServiceState">
        {serviceStateOptions.map((service) => {
          return (
            <OperatorFilterLabelCard
              key={service?.helperTxt}
              labelData={{
                value: service.labelData.value,
                title: service.labelData.title,
              }}
              isChecked={service.isChecked}
              helperTxt={service.helperTxt}
              checkBockHandler={service.checkBockHandler}
            >
              {service.filterOptions.map((filterOption) => {
                return (
                  <OperatorFilterTag
                    onClick={() => handleFilterVehiclesByService(filterOption)}
                    isChecked={filterOption.isChecked}
                    name={filterOption?.title}
                  />
                );
              })}
            </OperatorFilterLabelCard>
          );
        })}
      </ToggleableContentMobile>
    </Box>
  );
};
