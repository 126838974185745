// System
import { FormProvider } from 'react-hook-form';
import { SystemStyleObject } from '@mui/system';
import { Button, Divider, Stack, Theme } from '@mui/material';

// Components
import { VehicleNoteCard } from './VehicleNoteCard';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';

// Form
import { useSetupVehicleForm } from '@Forms/vehicles/SetupVehicleFormContext';

// Redux
import { useVehicleNotes } from '@Modal/hooks/vehicles/notes/useVehicleNotes';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';
import { useEffect } from 'react';

const useSx = () => {
  return {
    button: {
      width: '95px',
      marginY: 2,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const VehicleNotes: React.FC = () => {
  const style = useSx();
  const methods = useSetupVehicleForm();

  const { selectedVehicleData } = useVehiclesDrawer();
  const { handleVehicleNoteClick, vehicleNotes, handleGetVehicleNotes } = useVehicleNotes();

  useEffect(() => {
    handleGetVehicleNotes();
  }, []);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Button
            variant="outlined"
            onClick={() => handleVehicleNoteClick(selectedVehicleData.id)}
            sx={style.button}
          >
            Add note
          </Button>
          <Divider />
          <Stack spacing={2}>
            {(vehicleNotes ?? []).map((item) => {
              return (
                <VehicleNoteCard
                  text={item.note}
                  id={item.id}
                  vehicleId={item.vehicleId}
                  createdAt={item.createdAt}
                />
              );
            })}
          </Stack>
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
