import { useMemo } from 'react';
import { RHFSelect } from '@Components/hook-form';
import { MenuItem, Stack, Typography } from '@mui/material';

import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';

// Redux
import { useVehicleCategoryDrawer } from '@Modal/hooks/vehicles/category/useVehicleCategoryDrawer';

const useDropDown = () => {
  // @TODO: Re-do this part using FSD approach
  // const { speedModes } = useSetupCategoryUseCaseOptions();
  // const speedModeNameDropDown = useMemo(
  //   () =>
  //     speedModes?.map(({ key, value }) => (
  //       <MenuItem key={key} value={key}>
  //         {value}
  //       </MenuItem>
  //     )),
  //   [speedModes]
  // );
  // return { speedModes: speedModeNameDropDown };
};

export const SpeedSettings: React.FC = () => {
  const { showSpeedSettingsFilters } = useVehicleCategoryDrawer();
  const dropDown = useDropDown();

  // const speedModeOptions: string[] = ['SOFT_MODE', 'MOTION_MODE'];
  const speedModeOptions: { key: string; value: string }[] = [
    { key: 'SOFT_MODE', value: 'Soft Mode' },
    { key: 'MOTION_MODE', value: 'Motion Mode' },
  ];

  return (
    <Stack direction="column" spacing={1.5}>
      <AtLocator type="readonly-text" name="SpeedSettings">
        <Typography variant="subtitle1">Speed settings</Typography>
      </AtLocator>
      {/* <AtLocator type="text-field" name="speedMode">
        <RHFSelect
          name="speedMode"
          size="small"
          id="outlined-basic"
          label="SpeedMode"
          variant="outlined"
        >
          {speedModeOptions.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </RHFSelect>
      </AtLocator>
          */}
      <AtLocator type="text-field" name="maxSpeed">
        <RHFIntegerField
          name="maxSpeed"
          size="small"
          id="outlined-basic"
          label="Max speed (km/h)"
          variant="outlined"
        />
      </AtLocator>
      <AtLocator type="text-field" name="maxSpeedSlowZone">
        <RHFIntegerField
          name="maxSpeedSlowZone"
          size="small"
          id="outlined-basic"
          label="Max speed in slow zone (km/h)"
          variant="outlined"
        />
      </AtLocator>
      <AtLocator type="text-field" name="maxDistanceWithFullBattery">
        <RHFIntegerField
          name="maxDistanceWithFullBattery"
          size="small"
          id="outlined-basic"
          label="Max distance with 100% battery (km)"
          variant="outlined"
        />
      </AtLocator>
    </Stack>
  );
};
