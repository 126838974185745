
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';
import { SetupBranchContent } from './SetupBranchContent';
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';

export const SetupBranchDrawer: React.FC = () => {
  const {showBranchDrawer,handleDrawerCancel} = useBranchDrawer();
  return (
    <SetupDrawer name="SetupBranch" open={showBranchDrawer} onClose={handleDrawerCancel}>
      <SetupBranchContent />
    </SetupDrawer>
  );
};
