// Components
import { SetupRentalTabs } from './SetupRentalTabs';
import { SetupRentalHeader } from './SetupRentalHeader/SetupRentalHeader';
import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';

// Redux
import { useDispatch } from 'react-redux';
import { setShowRentalsDrawer } from '@Modal/reducers/rentals/rentalsDrawerSlice';

export const SetupRentalContent: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout
          currentSlug="rentals"
          onClose={() => dispatch(setShowRentalsDrawer(false))}
        >
          <SetupRentalHeader onClose={() => dispatch(setShowRentalsDrawer(false))} />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={() => alert('Save Clicked')}
          saveDisabled={true}
          onCancelClick={() => dispatch(setShowRentalsDrawer(false))}
        />
      }
    >
      <SetupRentalTabs />
    </TabDrawerLayout>
  );
};
