// // System
// import { useCallback, useEffect, useState } from 'react';
// import { Checkbox, FormControlLabel, FormGroup, Radio } from '@mui/material';

// // Components
// import { TableFilterDialogLayout } from '../TableFilterDialogLayout';
// import { FilterColumnDialogContentProps } from '@Components/props/table/FilterColumnDialogContentProps';

// // Types
// import { KeyValue } from '@contracts/view/KeyValue';

// type Props<T extends string> = FilterColumnDialogContentProps<
//   Partial<Record<T, boolean | string>>,
//   { options: KeyValue<T, string | boolean>[] }
// >;

// export const EnumTableFilterDialogContent = <T extends string>({
//   options,
//   codeName,
//   handleSetFilters,
// }: Props<T>) => {
//   const [filter, setFilter] = useState<Partial<Record<T, boolean | string>>>({});

//   const handleApplyClick = useCallback(() => {
//     const selectedFilters = Object.keys(filter)
//       // @ts-ignore
//       .filter((key) => filter[key]);
//     // .map((key) => key.toUpperCase());

//     handleSetFilters && handleSetFilters({ codeName, value: selectedFilters });
//   }, [filter, handleSetFilters, codeName]);

//   const handleResetClick = useCallback(() => {
//     setFilter({});
//     handleSetFilters && handleSetFilters({ codeName, value: [] });
//   }, [handleSetFilters, codeName]);

//   return (
//     <TableFilterDialogLayout
//       onApplyClick={handleApplyClick}
//       onResetClick={handleResetClick}
//       sx={{ minWidth: '193px' }}
//     >
//       <FormGroup data-at-container="EnumFilter">
//         {options.map(({ key, value }) => (
//           <FormControlLabel
//             key={key}
//             data-at-checkbox-label={key}
//             control={
//               <Checkbox
//                 checked={!!filter[key]}
//                 data-at-checkbox={key}
//                 onChange={(e) => {
//                   const isChecked = e.target.checked;
//                   setFilter((prev) => ({ ...prev, [key]: isChecked ? value : false }));
//                 }}
//               />
//             }
//             label={typeof value === 'string' ? value : key}
//           />
//         ))}
//       </FormGroup>
//     </TableFilterDialogLayout>
//   );
// };

// System
import { useCallback, useState } from 'react';
import { FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material';

// Components
import { TableFilterDialogLayout } from '../TableFilterDialogLayout';
import { FilterColumnDialogContentProps } from '@Components/props/table/FilterColumnDialogContentProps';

// Types
import { KeyValue } from '@contracts/view/KeyValue';

type Props<T extends string> = FilterColumnDialogContentProps<
  string | boolean,
  { options: KeyValue<T, string | boolean>[] }
>;

export const EnumTableFilterDialogContent = <T extends string>({
  options,
  codeName,
  handleSetFilters,
}: Props<T>) => {
  const [selectedOption, setSelectedOption] = useState<string | boolean | undefined>(undefined);

  const handleApplyClick = useCallback(() => {
    const selectedFilters = selectedOption ? [selectedOption] : [];
    // @ts-ignore
    handleSetFilters && handleSetFilters({ codeName, value: selectedFilters });
  }, [selectedOption, handleSetFilters, codeName]);

  const handleResetClick = useCallback(() => {
    setSelectedOption(undefined);
    handleSetFilters && handleSetFilters({ codeName, value: [] });
  }, [handleSetFilters, codeName]);

  return (
    <TableFilterDialogLayout
      onApplyClick={handleApplyClick}
      onResetClick={handleResetClick}
      sx={{ minWidth: '193px' }}
    >
      <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
        <FormGroup data-at-container="EnumFilter">
          {options.map(({ key, value }) => (
            <FormControlLabel
              key={key}
              data-at-radio-label={key}
              control={<Radio data-at-radio={key} />}
              value={value}
              label={typeof key === 'string' ? key : value}
            />
          ))}
        </FormGroup>
      </RadioGroup>
    </TableFilterDialogLayout>
  );
};
