import {
  defaultTransactionListFilter,
  transactionListColumnVisibilityOptions,
  defaultTransactionListColumnVisibility,
} from '@utils/tables/transaction/transactionListFilter';
// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  limit: 25,
  page: 1,
  total: 0,
  roundTransactionsTableFilters: {},
  selectedFilters: defaultTransactionListFilter,
  columnVisibility: defaultTransactionListColumnVisibility,
  columnVisibilityOptions: transactionListColumnVisibilityOptions,
};

const transactionTableSlice = createSlice({
  name: 'transactionTable',
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setRoundTransactionsTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { roundTransactionsTableFilters } = state;
      const convertedValue = typeof value !== 'object' || value === null ? value : value[0];

      const newRoundTransactionsTableFilters = {
        ...roundTransactionsTableFilters,
        [codeName]:
          convertedValue === 'true' ? true : convertedValue === 'false' ? false : convertedValue,
      };

      state.roundTransactionsTableFilters = newRoundTransactionsTableFilters;
    },
  },
  extraReducers: {},
});

export const { reducer: transactionTableReducer } = transactionTableSlice;
export const {
  setColumnVisibility,
  setLimit,
  setPage,
  setTotal,
  setRoundTransactionsTableFilters,
} = transactionTableSlice.actions;
