import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, number, object, string } from 'yup';

export type SetupIotModuleFormState = {
  id: string;
  iotType: string | null;
  host: string;
  port: number | null;
  username: string | null;
  password: string | null;
};

export const setupIotModuleFormStateDefault: SetupIotModuleFormState = {
  id: '',
  iotType: '',
  host: '',
  port: null,
  username: '',
  password: '',
};

export const setupIotModuleSchemaDefault: ObjectSchema<Partial<SetupIotModuleFormState>> =
  object<SetupIotModuleFormState>().shape({
    id: string().required(),
    iotType: string().required(),
    host: string().required(),
    port: number().required(),
    username: string().required(),
    password: string().required(),
  });

type SetupIotModuleFormContextType = {
  schema: ObjectSchema<Partial<SetupIotModuleFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<SetupIotModuleFormState>>>;
  methods?: UseFormReturn<SetupIotModuleFormState>;
};

const iotModuleFormContextDefault = {
  schema: setupIotModuleSchemaDefault,
  setSchema: noop,
} satisfies SetupIotModuleFormContextType;

export const SetupIotModuleFormContext = createContext<SetupIotModuleFormContextType>(
  iotModuleFormContextDefault
);

export const useSetupIotModuleForm = () => {
  const { methods } = useContext(SetupIotModuleFormContext);

  if (!methods) {
    throw new Error('SetupIotFormContext is empty');
  }

  return methods;
};

export const useManageSetupIotModuleSchema = () => {
  const { setSchema } = useContext(SetupIotModuleFormContext);

  return { setSchema };
};
