// System
import React from 'react';
import { Box, Slider } from '@mui/material';

// Components
import { OperatorBatteryCard } from '@Components/operator/OperatorBatteryCard';

// Features
import { ToggleableContentMobile } from '@Features/maps/Filters/ToggleableContentOperator/ToggleableContentOperator';

// Styles
import Styles from './OperatorBatteryLevel.module.scss';
import BatteryRange from '@Components/BatteryRange/BatteryRange';

type Props = {
  batteryPercentage: { from: number; to: number };
  batteryLevelFilter: {
    batteryLevel: string;
    iconName: string;
    isChecked: boolean;
  }[];
  handleFilterByBatteryLevel: (arg: {
    batteryLevel: string;
    iconName: string;
    isChecked: boolean;
  }) => void;
  handleFilterByBatteryPercentage: (arg: { from: number; to: number }) => void;
  handleRangeValueChange: (arg1: number, arg2: string) => void;
};

export const OperatorBatteryLevel: React.FC<Props> = ({
  batteryPercentage,
  batteryLevelFilter,
  handleRangeValueChange,
  handleFilterByBatteryLevel,
  handleFilterByBatteryPercentage,
}) => {
  return (
    <Box className={Styles.OperatorBatteryLevel}>
      <ToggleableContentMobile name="Battery level" key="BatteryLevel">
        <Box className={Styles.FlexContainer}>
          {batteryLevelFilter.map((battery) => {
            return (
              <OperatorBatteryCard
                onClick={handleFilterByBatteryLevel}
                battery={battery}
                key={battery.batteryLevel}
              />
            );
          })}
        </Box>
        <Box className={Styles.RangeSliderContainer}>
          <BatteryRange
            rangeValue={batteryPercentage}
            handleFilterByBatteryPercentage={handleFilterByBatteryPercentage}
            handleRangeValueChange={handleRangeValueChange}
          />
        </Box>
      </ToggleableContentMobile>
    </Box>
  );
};
