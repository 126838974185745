import { Stack, Typography, Box } from '@mui/material';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';
import { useIotDrawer } from '@Modal/hooks/vehicles/iot/useIotDrawer';

export const SetupIotModuleHeader = () => {
  const ellipsis = useEllipsis();
  const {selectedIot} = useIotDrawer()

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Box sx={{ ...ellipsis }}>
        <Typography variant="h6">IoT {selectedIot.id}</Typography>
      </Box>
    </Stack>
  );
};
