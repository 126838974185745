import { useCallback, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

export const GoogleMapsContainerMobile: React.FC<{
  children?: JSX.Element | (JSX.Element | boolean)[] | undefined | null | boolean;
  onClick?: ((e: google.maps.MapMouseEvent) => void) | undefined;
  setBound: React.Dispatch<React.SetStateAction<any>>;
  zoom: number | undefined;
  center: google.maps.LatLngLiteral | undefined;
  setZoom: (arg: number | undefined) => void;
  setCenter: (arg: google.maps.LatLngLiteral | undefined) => void;
  mapContainerStyle: any;
  mapOptions: any;
}> = ({
  children,
  onClick,
  setBound,
  zoom,
  center,
  setZoom,
  setCenter,
  mapContainerStyle,
  mapOptions,
}) => {
  const [defaultZoom] = useState(zoom);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      map.addListener('idle', () => {
        const bounds = map.getBounds();
        const NE = bounds?.getNorthEast().toJSON();
        const SW = bounds?.getSouthWest().toJSON();

        const mapBounds = {
          westnorth: `${SW?.lng.toString()},${NE?.lat.toString()}`,
          eastnorth: `${NE?.lng.toString()},${NE?.lat.toString()}`,
          eastsouth: `${NE?.lng.toString()},${SW?.lat.toString()}`,
          westsouth: `${SW?.lng.toString()},${SW?.lat.toString()}`,
        };

        const mapBoundsQueryStr = `${mapBounds.eastnorth};${mapBounds.westnorth};${mapBounds.westsouth};${mapBounds.eastsouth}`;

        // setBound(mapBoundsQueryStr);
      });
    },
    [setZoom]
  );

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={defaultZoom}
      center={center}
      options={mapOptions}
      onLoad={onLoad}
      onClick={onClick}
    >
      {children}
    </GoogleMap>
  );
};
