// System
import { FormProvider } from 'react-hook-form';
import { SystemStyleObject } from '@mui/system';
import { Button, Stack, Divider, Theme } from '@mui/material';

// Components
import { CustomerNoteCard } from './CustomerNoteCard';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';

// Form
import { useSetupCustomerForm } from '@Forms/customers/SetupCustomerFormContext';

// Redux
import { useEffect } from 'react';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';
import { useCustomerNotes } from '@Modal/hooks/customers/notes/useCustomerNotes';

const useSx = () => {
  return {
    button: {
      width: '95px',
      marginY: 2,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const CustomerNotes: React.FC = () => {
  const style = useSx();
  const methods = useSetupCustomerForm();

  const { selectedCustomerData } = useCustomersDrawer();
  const { handleCustomerNoteClick, customerNotes, handleGetCustomerNotes } = useCustomerNotes();

  useEffect(() => {
    handleGetCustomerNotes();
  }, []);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Button
            variant="outlined"
            onClick={() => handleCustomerNoteClick(selectedCustomerData.id)}
            sx={style.button}
          >
            Add note
          </Button>
          <Divider />
          <Stack spacing={2}>
            {(customerNotes ?? []).map((item) => {
              return (
                <CustomerNoteCard
                  text={item.note}
                  id={item.id}
                  customersId={item.id}
                  createdAt={item.createdAt}
                />
              );
            })}
          </Stack>
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
