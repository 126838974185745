import { Box, Typography } from '@mui/material';

type Props = {
  label: string;
};

export const VehicleRentalsLabel = ({ label }: Props) => {
  const generateVehicleRentalsLabel = () => {
    switch (label) {
      case 'ACTIVE':
        return (
          <Box sx={{ width: '80px', display: 'flex', justifyContent: 'end' }}>
            <Box
              sx={{
                width: 'auto',
                height: 'auto',
                paddingX: '8px',
                paddingY: '2px',
                backgroundColor: 'rgba(54, 179, 126, 0.16)',
                borderRadius: '6px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  fontWeight: '700',
                  color: 'rgba(27, 128, 106, 1)',
                }}
              >
                Active
              </Typography>
            </Box>
          </Box>
        );

      case 'ENDED':
        return (
          <Box sx={{ width: '75px', display: 'flex', justifyContent: 'end' }}>
            <Box
              sx={{
                width: '53px',
                height: 'auto',
                paddingX: '8px',
                paddingY: '2px',
                backgroundColor: 'rgba(14, 68, 92, 0.12)',
                borderRadius: '6px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '20px',
                  fontWeight: '700',
                  color: 'rgba(12, 61, 82, 1)',
                }}
              >
                Ended
              </Typography>
            </Box>
          </Box>
        );

      case 'CANCELLED':
        return (
          <Box
            sx={{
              width: 'auto',
              height: 'auto',
              paddingX: '8px',
              paddingY: '2px',
              backgroundColor: 'rgba(255, 86, 48, 0.16)',
              borderRadius: '6px',
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '20px',
                fontWeight: '700',
                color: 'rgba(183, 29, 24, 1)',
              }}
            >
              Cancelled
            </Typography>
          </Box>
        );

      default:
        break;
    }
  };

  return <Box>{generateVehicleRentalsLabel()}</Box>;
};
