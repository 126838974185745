import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';
import { SetupIotModuleHeader } from './SetupIotModuleHeader';
import { SetupIotModuleDetails } from './SetupIotModuleDetails';
import { useIotDrawer } from '@Modal/hooks/vehicles/iot/useIotDrawer';

export const SetupIotModuleContent: React.FC = () => {
  const { handleCreateCancel, submittable, handleSubmitIotForm } = useIotDrawer()

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/vehicles" onClose={handleCreateCancel}>
          <SetupIotModuleHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={handleSubmitIotForm}
          saveDisabled={!submittable}
          onCancelClick={handleCreateCancel}
        />
      }
    >
      <SetupIotModuleDetails />
    </TabDrawerLayout>
  );
};
