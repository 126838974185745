import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const useEllipsis = (): SystemStyleObject<Theme> => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
};
