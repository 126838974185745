import {
  defaultPricingListFilter,
  pricingListColumnVisibilityOptions,
  defaultPricingListColumnVisibility,
} from '@utils/tables/pricing/pricingListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedFilters: defaultPricingListFilter,
  rowsPerPage: 10,
  page: 1,
  columnVisibility: defaultPricingListColumnVisibility,
  columnVisibilityOptions: pricingListColumnVisibilityOptions,
};

const pricingTableSlice = createSlice({
  name: 'pricingTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultPricingListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: pricingTableReducer } = pricingTableSlice;
export const {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  setColumnVisibility,
  resetSelectedFilters,
} = pricingTableSlice.actions;
