import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosRentalsInstance } from '@utils/axiosInstances';
import { EndRideParams } from './types';

export const getSingleRental = createAsyncThunk(
  'rentals/getSingleRental',
  async (id: string, thunkApi) => {
    return axiosRentalsInstance
      .get(`rentals/${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const getSingleRentalEvents = createAsyncThunk(
  'rentals/getSingleRentalEvents',
  async (id: string, thunkApi) => {
    return axiosRentalsInstance
      .get(`rental-events?rentalId=${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const getSelectedRentalPriceBreakdown = createAsyncThunk(
  'rentals/getRentalPriceBreakdown',
  async (id: string, thunkApi) => {
    return axiosRentalsInstance
      .get(`rentals/${id}/summary`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

export const rentalEndRide = createAsyncThunk(
  'rentals/endRide',
  async ({ rentalId, operatorClientId, updateDuration }: EndRideParams, thunkApi) => {
    return axiosRentalsInstance
      .post(`rentals/end`, {
        rentalId: rentalId,
        operatorClientId: operatorClientId,
        updateDuration: updateDuration,
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error?.message);
      });
  }
);

// Type
const initialState = {
  openRentalsDrawer: false,
  selectedRental: null,
  showEndRideModalDialog: false,
  selectedRentalPriceBreakdown: {},
  selectedRentalEvents: null,
};

const rentalsDrawerSlice = createSlice({
  name: 'rentalsDrawer',
  initialState,
  reducers: {
    setShowRentalsDrawer: (state, { payload }) => {
      state.openRentalsDrawer = payload;
    },
    setSelectedRental: (state, { payload }) => {
      state.selectedRental = payload;
    },
    setShowEndRideModalDialog: (state, { payload }) => {
      state.showEndRideModalDialog = payload;
    },
  },
  extraReducers: {
    [getSingleRental.fulfilled?.type]: (state, action) => {
      state.selectedRental = action.payload;
      state.openRentalsDrawer = true;
    },
    [getSelectedRentalPriceBreakdown.fulfilled?.type]: (state, action) => {
      state.selectedRentalPriceBreakdown = action.payload;
    },
    [getSingleRentalEvents.fulfilled?.type]: (state, action) => {
      state.selectedRentalEvents = action.payload;
    },
  },
});

export const { reducer: rentalsDrawerReducer } = rentalsDrawerSlice;
export const { setShowRentalsDrawer, setSelectedRental, setShowEndRideModalDialog } =
  rentalsDrawerSlice.actions;
