// components
import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { UploadVehicleCSVDialogForm } from './UploadVehicleCSVDialogForm';

// Redux
import { useCSV } from '@Modal/hooks/vehicles/csv/useCSV';

export const UploadVehicleCSVModalDialog: React.FC = () => {
  const { showVehicleCsvModal, handleVehicleCSVModal } = useCSV();

  return (
    <ClosableModal
      name="UploadVehicleCSV"
      title="Import file"
      open={showVehicleCsvModal}
      onClose={() => handleVehicleCSVModal(false)}
    >
      <UploadVehicleCSVDialogForm />
    </ClosableModal>
  );
};
