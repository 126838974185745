import { ComponentProps, ReactElement, cloneElement } from 'react';
import { Modal } from '@mui/material';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Modal>;

export type AtModalLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtModalLocator: React.FC<AtModalLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-modal': name });
};
