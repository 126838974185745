import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedProviderData: {},
  showDeleteProviderModal: false,
  showProviderDrawer: false,
  showCancelProviderDrawer: false,
};

const providerDrawerSlice = createSlice({
  name: 'providerDrawer',
  initialState,
  reducers: {
    setShowProviderDrawer: (state, { payload }) => {
      state.showProviderDrawer = payload;
    },
    setShowDeleteProviderModal: (state, { payload }) => {
      state.showDeleteProviderModal = payload;
    },
    setShowCancelProviderDrawer: (state, { payload }) => {
      state.showCancelProviderDrawer = payload;
    },
    setSelectedProvider: (state, { payload }) => {
      state.selectedProviderData = payload;
    },
  },
  extraReducers: {},
});

export const { reducer: providerDrawerReducer } = providerDrawerSlice;
export const {
  setShowProviderDrawer,
  setShowCancelProviderDrawer,
  setSelectedProvider,
  setShowDeleteProviderModal,
} = providerDrawerSlice.actions;
