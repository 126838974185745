import { useCallback } from 'react';

import { KeyValue } from '@contracts/view/KeyValue';
import { Checkbox, CheckboxProps, FormControlLabel, FormGroup } from '@mui/material';

type Props<T> = {
  options: KeyValue<T, JSX.Element | string>[];
  checkedValues: T[];
  onChange?: (values: T) => void;
};

export const CheckmarkSelectDropDownContent = <T extends string>({
  options,
  checkedValues,
  onChange,
}: Props<T>) => {
  const handleChange = useCallback(
    (key: T): CheckboxProps['onChange'] =>
      () => {
        onChange?.(key);
      },
    [checkedValues]
  );

  return (
    <FormGroup>
      {options.map(({ key, value }) => (
        <FormControlLabel
          key={key}
          control={<Checkbox checked={checkedValues.includes(key)} onChange={handleChange(key)} />}
          label={value}
        />
      ))}
    </FormGroup>
  );
};
