import { ClosableModal } from '@Components/shared/dialogs/ClosableModal';
import { CreateUserForm } from './CreateUserForm';

// Styles
import { useCreateAviatorUser } from '@Modal/hooks/users/useCreateUser';

export const CreateUserModalDialog = () => {
  const { showCreateUserForm, handleCreateCancel } = useCreateAviatorUser();

  return (
    <>
      {showCreateUserForm && (
        <ClosableModal
          name="CreateRole"
          title="Create User"
          open={showCreateUserForm}
          onClose={handleCreateCancel}
        >
          <CreateUserForm />
        </ClosableModal>
      )}
    </>
  );
};
