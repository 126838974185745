import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosCustomerManagementInstance } from '@utils/axiosInstances';

// Types

// GET
export const getTransactionsList = createAsyncThunk(
  'customers/getTransactionsList',
  // TODO change any
  async (params: any, thunkApi) => {
    return axiosCustomerManagementInstance
      .get('/transactions', {
        params:
          params?.brandId !== 'undefined' && params?.brandId
            ? { ...params.selectedFilters, brandId: params.brandId }
            : { ...params.selectedFilters },
      })
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error || 'Rejected');
      });
  }
);

export const getCustomerTransactionsList = createAsyncThunk(
  'transactions/getCustomerTransactionsList',
  async (id: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/transactions/find?customerId=${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const getRentalTransactionsList = createAsyncThunk(
  'transactions/getRentalTransactionsList',
  async (id: string, thunkApi) => {
    return axiosCustomerManagementInstance
      .get(`/transactions/find?rentalId=${id}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data.items);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  transactions: [],
  customerTransactions: [],
  rentalTransactions: [],
  loadingContent: true,
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionsList: (state, { payload }) => {
      state.transactions = payload;
    },
    setTransactionPagination: (state, { payload }) => {
      state.pagination = payload;
      console.log('payload', payload);
    },
    setCustomerTransactions: (state, { payload }) => {
      state.customerTransactions = payload;
    },
    setRentalTransactions: (state, { payload }) => {
      state.rentalTransactions = payload;
    },
  },
  extraReducers: {
    [getTransactionsList.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;

      state.transactions = items;
      state.loadingContent = false;

      state.pagination = {
        ...state.pagination,
        count: pageInfo.totalCount,
        rowsPerPage: pageInfo.limit,
      };
    },
    [getCustomerTransactionsList.fulfilled?.type]: (state, action) => {
      state.customerTransactions = action.payload;
    },
    [getRentalTransactionsList.fulfilled?.type]: (state, action) => {
      state.rentalTransactions = action.payload;
    },
    [getTransactionsList.rejected?.type]: (state) => {
      state.transactions = [];
      state.loadingContent = false;
    },
    [getCustomerTransactionsList.rejected?.type]: (state) => {
      state.customerTransactions = [];
    },
    [getRentalTransactionsList.rejected?.type]: (state) => {
      state.rentalTransactions = [];
    },
  },
});

export const { reducer: transactionsReducer } = transactionsSlice;
export const {
  setTransactionsList,
  setCustomerTransactions,
  setRentalTransactions,
  setTransactionPagination,
} = transactionsSlice.actions;
