// System
import { useDispatch } from 'react-redux';
import { DrawingManager, Polygon } from '@react-google-maps/api';

// Contracts
import { MapZoneType } from '@contracts/domain/keys';
import { EditStatus } from '@contracts/common/EditStatus';

// Types
import { mapZoneTypeColors } from '../../shared/MapZoneType/useMapZoneType';

// Hooks
import { useBranchesMaps } from '@Modal/hooks/branches/useBranchMap';

// Utils
import { getPathType } from '@utils/map/geoJSON';

const polygonOptions = {
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  geodesic: false,
  zIndex: 1,
};

type PolygonProps = {
  targetMapZoneType: MapZoneType;
  strokeColor: string;
  fillColor: string;
  holeColor: string;
  fillOpacity: number;
};

const PolygonView: React.FC<PolygonProps> = ({
  targetMapZoneType,
  strokeColor,
  fillColor,
  holeColor,
  fillOpacity,
}) => {
  const { activeMapZoneType, mapPaths } = useBranchesMaps();

  const filteredMapPathes = mapPaths
    .map((zone, index) => [zone, index] as const)
    .filter(
      ([zone]) => zone?.mapZoneType === targetMapZoneType && zone.editStatus !== EditStatus.Deleted
    );

  if (filteredMapPathes.length === 0) {
    return null;
  }

  if (activeMapZoneType !== targetMapZoneType) {
    return null;
  }

  return (
    <>
      {filteredMapPathes.map(([mapPath, index]) => (
        <Polygon
          key={index}
          paths={mapPath.path}
          options={{
            ...polygonOptions,
            fillColor: mapPath.type === 'ring' ? fillColor : holeColor,
            strokeColor,
            fillOpacity,
          }}
        />
      ))}
    </>
  );
};

const Drawing: React.FC<{ zoneType: MapZoneType; onFinish?: (index: number) => void }> = ({
  zoneType,
  onFinish,
}) => {
  const dispatch = useDispatch();
  const { mapPaths, activeCategoryId, activeMapZoneType, setMapPaths } = useBranchesMaps();

  const color = mapZoneTypeColors.find(({ type }) => type === zoneType);

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const path = polygon
      .getPath()
      .getArray()
      .map((p) => p.toJSON());

    const direction = getPathType(path);
    if (direction === 'hole') {
      path.reverse();
    }
    polygon.setMap(null);

    const updatedMapPaths = [
      ...mapPaths,
      {
        path,
        editStatus: EditStatus.Added,
        mapZoneType: activeMapZoneType,
        type: 'ring',
        categoryId: activeCategoryId,
      },
    ];

    dispatch(setMapPaths(updatedMapPaths));

    onFinish?.(updatedMapPaths.length);
  };

  if (color === undefined) {
    return null;
  }

  return (
    <DrawingManager
      onPolygonComplete={onPolygonComplete}
      options={{
        drawingControl: true,
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControlOptions: {
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          fillOpacity: color.opacity,
          strokeColor: color.color,
          fillColor: color.fillColor ?? color.color,
          strokeOpacity: 1,
          strokeWeight: 1,
          editable: false,
          draggable: false,
          clickable: false,
        },
      }}
    />
  );
};

export const MapAddMode: React.FC<{ onFinish?: (index: number) => void }> = ({ onFinish }) => {
  const { activeMapZoneType } = useBranchesMaps();

  return (
    <>
      {mapZoneTypeColors.map((zoneType) => (
        <PolygonView
          key={zoneType.type}
          targetMapZoneType={zoneType.type}
          strokeColor={zoneType.color}
          fillColor={zoneType.fillColor ?? zoneType.color}
          holeColor={zoneType.holeColor ?? zoneType.color}
          fillOpacity={zoneType.opacity}
        />
      ))}
      <Drawing zoneType={activeMapZoneType} onFinish={onFinish} />
    </>
  );
};
