import { Stack, Typography, Theme, Box } from '@mui/material';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { SystemStyleObject } from '@mui/system';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

const useSx = () => {
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      width: '100%',
      padding: '16px',
      backgroundColor: 'rgba(145,158,171, 0.08)',
      ...roundedBorder,
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const AdditionalDetails = () => {
  const { selectedRental } = useRentalsDrawer();
  const styles = useSx();

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="subtitle2" color="black">
        Additional Details
      </Typography>
      <Stack direction="column" spacing={2}>
        <AtLocator type="readonly-text" name="rating">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" data-at-readnly-text="Label" color="#637381">
              Rating
            </Typography>
            <Typography variant="subtitle2" data-at-readnly-text="Value">
              {selectedRental?.rentalSummary?.rate ? `${selectedRental.rentalSummary.rate}` : 'Not Rated'}
            </Typography>
          </Stack>
        </AtLocator>
        <Box sx={styles.container}>
          <Stack direction="column" spacing={1}>
            <Typography variant="body2" sx={{ color: '#637381' }}>
              {selectedRental.rentalSummary !== null ? selectedRental.rentalSummary.note : 'No Comment'}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
