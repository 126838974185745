import { useCallback, useMemo } from 'react';

// System
import { useSnackbar } from 'notistack';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// import {
import {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  setColumnVisibility,
  resetSelectedFilters,
} from '@Modal/reducers/transactions/transactionTableSlice';
import { getTransactionsList } from '@Modal/reducers/transactions/transactionsSlice';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';
import { useTransactions } from './useTransactions';
import { useBranches } from '../branches/useBranch';
import { FilterItemType } from '@Modal/reducers/types';
import { Paginable } from '@contracts/common/traits/Paginable';

export const useTransactionTable = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { enqueueSnackbar } = useSnackbar();

  const { rowsPerPage, columnVisibility, columnVisibilityOptions, page, selectedFilters } =
    useSelector((state: StoreInterface) => state?.transactionsTable);

  const { transactions, loadingContent, setTransactionPagination, pagination } = useTransactions();

  const { activeBrandId } = useBranches();

  const fetchTransactionsTableContent = useCallback(() => {
    if (activeBrandId) {
      dispatch(getTransactionsList({ selectedFilters, brandId: activeBrandId }))
        .unwrap()
        .catch((error) => {
          enqueueSnackbar(error, {
            autoHideDuration: 2000,
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    }
  }, [selectedFilters, activeBrandId]);

  const handleFilterSelect = (filter: FilterItemType) => {
    dispatch(setSelectedFilters(filter));
  };

  const visiblityCustomizable = useMemo(
    () => ({
      value: columnVisibility,
      options: columnVisibilityOptions,
      apply: (value: string[]) => handleApplyColumnVisibility(value),
    }),
    [columnVisibility, columnVisibilityOptions]
  );

  const transactionsListFilters = useMemo(() => {
    const columnVisibilityStrArr = Array.from(Object.values(columnVisibility)).map((filt) => filt);
    return columnVisibilityStrArr as string[];
  }, [columnVisibility]);

  const handleApplyColumnVisibility = (column: string[]) => {
    const newVisibleColumns = [...transactionsListFilters, ...column];
    if (transactionsListFilters.filter((col) => column.includes(col)).length > 0) {
      const filteredNewVisibleColumns = transactionsListFilters.filter((col) => col !== column[0]);
      dispatch(setColumnVisibility(filteredNewVisibleColumns));
    } else {
      dispatch(setColumnVisibility(newVisibleColumns));
    }
  };
  const handleResetFilters = () => {
    dispatch(resetSelectedFilters());
  };

  const handleTransactionTablePageChange = (filter: FilterItemType, pagination: Paginable) => {
    dispatch(setSelectedFilters(filter));
    dispatch(setTransactionPagination(pagination));
  };

  return {
    // State
    page,
    pagination,
    rowsPerPage,
    transactions,
    loadingContent,
    selectedFilters,
    columnVisibility,
    handleResetFilters,
    visiblityCustomizable,
    transactionsListFilters,
    columnVisibilityOptions,
    // Actions
    setPage,
    setRowsPerPage,
    setSelectedFilters,
    handleFilterSelect,
    handleApplyColumnVisibility,
    fetchTransactionsTableContent,
    handleTransactionTablePageChange,
  };
};
