import { GoogleMapsMultiPoligon, GoogleMapsPath } from '@contracts/common/map/GoogleMaps';
import { GeoJSONMultiPoligon, GeoJSONPath } from '@contracts/common/map/GeoJSON';

import { MapPathType } from '@contracts/common/map/MapPathType';
import { SemiGeoJSONMultiPoligon } from '@contracts/common/map/SemiGeoJSON';

export const toGoogleMapsPath = (path: GeoJSONPath): GoogleMapsPath => {
  return path.map(([lng, lat]) => ({ lng, lat })).filter((_, i) => i < path.length - 1);
};

export const fromGoogleMapsPath = (path: GoogleMapsPath): GeoJSONPath => {
  const pre: GeoJSONPath = path.map(({ lng, lat }) => [lng, lat]);
  return [...pre, pre[0]];
};

export const getPathType = (path: GoogleMapsPath): MapPathType =>
  google?.maps?.geometry?.spherical.computeSignedArea(path) < 0 ? 'hole' : 'ring';

const epsilon = 1e-6;

export const areEqualPaths = (path1: GoogleMapsPath, path2: GoogleMapsPath): boolean => {
  if (path1.length !== path2.length) {
    return false;
  }

  return path1
    .map((p1, i) => {
      const p2 = path2[i];
      return Math.abs(p1.lat - p2.lat) < epsilon && Math.abs(p1.lng - p2.lng) < epsilon;
    })
    .reduce((acc, cur) => acc && cur, true);
};

export const fromGoogleMapsMultiPoligon = (
  geometry: GoogleMapsMultiPoligon
): SemiGeoJSONMultiPoligon => {
  return [
    geometry.filter((path) => getPathType(path) === 'ring').map((path) => fromGoogleMapsPath(path)),
    geometry.filter((path) => getPathType(path) === 'hole').map((path) => fromGoogleMapsPath(path)),
  ];
};

export const fromGeoJSONMultiPoligon = (geometry: GeoJSONMultiPoligon): SemiGeoJSONMultiPoligon => {
  return [geometry.map(([polygon]) => polygon), geometry.flatMap(([, ...polygon]) => polygon)];
};

export const toGoogleMapsMultiPoligon = (
  geometry: SemiGeoJSONMultiPoligon
): GoogleMapsMultiPoligon => {
  return geometry[0]
    ? [...geometry[0].map(toGoogleMapsPath), ...geometry[1].map(toGoogleMapsPath)]
    : [];
};

