import { Box, Theme, Popover, PopoverProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useCallback, useContext } from 'react';
import { SelectBoxContext } from './SelectBoxContext';

type Props = {
  name: string;
  fullWidth?: boolean;
  input: JSX.Element;
  dropDown: JSX.Element;
  popoverSx?: SystemStyleObject<Theme>;
  popoverProps?: Partial<PopoverProps>;
};

export const SelectBox: React.FC<Props> = ({
  name,
  fullWidth,
  input,
  dropDown,
  popoverSx,
  popoverProps,
}) => {
  const { anchorRef, open, setOpen } = useContext(SelectBoxContext);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Box>
      <Box ref={anchorRef} data-at-container={name} display={fullWidth ? 'block' : 'inline-block'}>
        {input}
      </Box>
      <Popover
        open={open}
        data-at-popover={name}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={[{ marginTop: 1 }, popoverSx ?? {}]}
        {...popoverProps}
      >
        {dropDown}
      </Popover>
    </Box>
  );
};
