// System
import { SystemStyleObject } from '@mui/system';
import { IconButton, Stack, Theme, Typography, useTheme } from '@mui/material';

// Components
import { PaymentMethodCardType } from './PaymentMethodCardType';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { useRoundedBorder } from '@Components/shared/styles/useRoundedBorder';
import { useCustomerPaymentMethods } from '@Modal/hooks/customers/paymentMethods/usePaymentMethods';
import { PaymentMethodCardProps } from '@Modal/reducers/customers/types';

// Redux

const useSx = () => {
  const { palette } = useTheme();
  const roundedBorder = useRoundedBorder();

  return {
    container: {
      minHeight: '66px',
      padding: 2,
      backgroundColor: palette.grey['100'],
      width: '560px',
      overflow: 'clip',
      ...roundedBorder,
    },
    cardNumber: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '600',
      color: 'rgb(33, 43, 54)',
    },
    expDate: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: '400',
      color: 'rgb(99, 115, 129)',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const PaymentCard = ({
  id,
  created_at,
  updated_at,
  accountId,
  cardType,
  mask,
  cardExpireDate,
  isDefault,
}: PaymentMethodCardProps) => {
  const styles = useSx();

  const { handleDeletePaymentMethodClick } = useCustomerPaymentMethods();

  const handleDeleteClick = (id: number) => {
    handleDeletePaymentMethodClick(id);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.container}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <PaymentMethodCardType cardType={`${cardType}`} isDefault={isDefault} />
        <Stack direction="column" sx={{ width: '430px', overflow: 'auto' }}>
          <Typography sx={styles.cardNumber}>{mask}</Typography>
          <Typography sx={styles.expDate}>Exp Date {cardExpireDate}</Typography>
        </Stack>
      </Stack>
      <IconButton onClick={() => handleDeleteClick(id)}>
        <AssetIcon src="trash-02" size="xxxl" sx={{ color: '#637381' }} />
      </IconButton>
    </Stack>
  );
};
