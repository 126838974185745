import Image from '@Components/image/Image';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { Stack, SystemStyleObject } from '@mui/system';

type Props = {
  title: JSX.Element | string;
  children?: JSX.Element;
  onClose?: () => void;
  actions: JSX.Element | JSX.Element[];
  sx?: SystemStyleObject<Theme>;
};

export const ImageCancelDialogLayout: React.FC<Props> = ({ title, children, actions, sx }) => {
  return (
    <Box sx={[{ bgcolor: 'background.paper', borderRadius: 2, width: '592px' }, sx ?? {}]}>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Image alt="tree_view" src="/assets/icons/components/ic_tree_view.png" />
        <Box padding={2}>
          <Typography variant="h3" align="center">
            {title}
          </Typography>
        </Box>
        {children}
        <Divider sx={{ width: '100%' }} />
        <Stack
          direction="row"
          width="100%"
          padding={2}
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {actions}
        </Stack>
      </Stack>
    </Box>
  );
};
