import {
  defaultTransactionListFilter,
  transactionListColumnVisibilityOptions,
  defaultTransactionListColumnVisibility,
} from '@utils/tables/transactions/transactionListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  page: 1,
  rowsPerPage: 10,
  selectedFilters: defaultTransactionListFilter,
  columnVisibility: defaultTransactionListColumnVisibility,
  columnVisibilityOptions: transactionListColumnVisibilityOptions,
};

const transactionTableSlice = createSlice({
  name: 'transactionTable',
  initialState,
  reducers: {
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultTransactionListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: transactionsTableReducer } = transactionTableSlice;
export const {
  setPage,
  setRowsPerPage,
  setSelectedFilters,
  setColumnVisibility,
  resetSelectedFilters,
} = transactionTableSlice.actions;
