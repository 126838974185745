import { TextField, InputAdornment, Box, Typography } from '@mui/material';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

// Components
import Scrollbar from '@Components/scrollbar/Scrollbar';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

// Types
import { BackofficeLiveVehicle } from '@Modal/reducers/vehicles/types';
import { useOperatorVehicles } from '@Modal/hooks/operator/useOperatorVehicles';
import { HashTable } from '@utils/services/liveMapSocket';

const inputStyles = {
  background: '#fff',
  borderRadius: '8px',
  width: '75vw',
  height: '52px',
  boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.16)',
  '& fieldset': {
    display: 'none',
  },
};

type VehicleClickType = {
  status: boolean;
  vehicleInfo: BackofficeLiveVehicle;
};

type Props = {
  onSearch: (arg: string) => void;
  generateBatteryteIcon: (arg: string) => void;
  generaTxtColor: (arg: string) => void;
  generateIotIcon: (arg: string) => void;
  handleRowClick: ({ status, vehicleInfo }: VehicleClickType) => void;
  searchQuery: string;
  setSearchQuery: (arg: string) => void;
  searchedVehicles: HashTable;
};

export const SearchBarOperator: React.FC<Props> = ({
  onSearch,
  searchedVehicles,
  generateBatteryteIcon,
  generaTxtColor,
  generateIotIcon,
  searchQuery,
  setSearchQuery,
}) => {
  const ellipsis = useEllipsis();
  const { handleSetApproachedVehicle } = useOperatorVehicles();

  const handleSearch = (txt: string) => {
    // Call the onSearch callback with the searchQuery
    setSearchQuery(txt);
    onSearch(txt);
  };

  const clearSearch = () => {
    setSearchQuery('');
    onSearch('');
  };
  const dropDown = Object.keys(searchedVehicles).map((veh: any) => (
    <MenuItemEx
      key={searchedVehicles[veh].id}
      name="searchedVehicles[veh]"
      value={searchedVehicles[veh].id}
      sx={{ borderRadius: 0.75 }}
      onClick={() => {
        handleSetApproachedVehicle(true, searchedVehicles[veh]);
        clearSearch();
      }}
    >
      <AtLocator type="readonly-text" name="name">
        <Box sx={{ width: '100%', ...ellipsis }}>
          <Typography>{searchedVehicles[veh]?.qrCode}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              alignItems: 'center',
              borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
              height: '40px',
              padding: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <img
                src={`${generateBatteryteIcon(searchedVehicles[veh]?.deviceInfo?.batteryLevel)}`}
                width={18}
                height={18}
                alt="battery"
              />
              <Typography
                //  @ts-ignore
                sx={{
                  fontSize: '14px',
                  color: generaTxtColor(searchedVehicles[veh]?.deviceInfo?.batteryLevel),
                  textTransform: 'capitalize',
                  fontWeight: '700',
                }}
              >
                {` ${
                  searchedVehicles[veh]?.deviceInfo?.batteryLevel === 'unknown'
                    ? 'N/A'
                    : `${searchedVehicles[veh]?.deviceInfo?.batteryStage}%`
                }`}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ color: '#637381', fontSize: '12px' }}>IoT:</Typography>
              <img
                src={`${generateIotIcon(searchedVehicles[veh]?.deviceInfo?.iotStatus)}`}
                width={4}
                height={4}
                alt="battery"
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  textTransform: 'capitalize',
                }}
              >
                {` ${searchedVehicles[veh]?.deviceInfo?.iotStatus}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AtLocator>
    </MenuItemEx>
  ));

  return (
    <Box>
      <TextField
        value={searchQuery}
        onChange={(e) => handleSearch(e?.target?.value)}
        sx={{ ...inputStyles, borderColor: 'red !important' }}
        placeholder="Qr, License plate, Vin or name"
        InputProps={{
          style: inputStyles,
          startAdornment: (
            <InputAdornment position="start">
              <AssetIcon
                src="icons/maps/ic_search"
                sx={{ color: 'black', height: '18px', width: '18px', marginTop: '2px' }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchQuery && (
                <ClearIcon
                  style={{ color: 'black', height: '18px', width: '18px' }}
                  onClick={() => clearSearch()}
                />
              )}
            </InputAdornment>
          ),
        }}
      />

      {Object.keys(searchedVehicles)?.length && searchQuery?.length ? (
        <AtLocator type="popover" name="VehicleSearch">
          <Box
            sx={{
              width: '290px',
              backgroundColor: 'white',
              marginTop: '5px',
              borderRadius: '8px',
            }}
          >
            <Scrollbar
              autoHide={false}
              forceVisible="y"
              sx={{
                maxHeight: '300px',
                padding: 1,
                paddingBottom: 0,
              }}
            >
              {dropDown}
            </Scrollbar>
          </Box>
        </AtLocator>
      ) : null}
    </Box>
  );
};

export default SearchBarOperator;
