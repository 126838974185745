import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box } from '@mui/system';

// Components
import { TabEx } from '@Components/shared/selects/TabEx';
import { useTabs } from '@Components/shared/hooks/useTabs';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabsDrawerLayout } from '@Components/shared/layouts/TabsDrawerLayout';
import { RoundDetails } from '../Tabs/Details/RoundDetails';
import { PlayerIds } from '../Tabs/PlayerIds/PlayerIds';
import { RoundTransactions } from '../Tabs/Transactions/RoundTransactions';

// Styles
import Styles from './SetupRoundTabs.module.scss';

export const SetupRoundTabs: React.FC = () => {
  const { currentTab, handleSwithTab } = useTabs('1');

  return (
    <Box className={Styles.SetupRoundTabs}>
      <TabContext value={currentTab}>
        <TabsDrawerLayout
          header={
            <AtLocator type="tab-list" name="SetupRound">
              <TabList
                className={Styles.TablList}
                onChange={handleSwithTab}
                aria-label="Information tabs"
              >
                <TabEx label="Details" value="1" />
                <TabEx label="Player IDs" value="2" />
                <TabEx label="Transactions" value="3" />
              </TabList>
            </AtLocator>
          }
        >
          <TabPanel value="1" className={Styles.Panel}>
            <RoundDetails />
          </TabPanel>
          <TabPanel value="2" className={Styles.Panel}>
            <PlayerIds />
          </TabPanel>
          <TabPanel value="3" className={Styles.Panel}>
            <RoundTransactions />
          </TabPanel>
        </TabsDrawerLayout>
      </TabContext>
    </Box>
  );
};
