import { TableHeadEx, TableHeadRowEx } from '../../components';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const TableHeadPresenter: React.FC<Props> = ({ children }) => {
  return (
    <TableHeadEx>
      <TableHeadRowEx data-at-table-header="table-header">{children}</TableHeadRowEx>
    </TableHeadEx>
  );
};
