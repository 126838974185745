// Components
import { TableColumnHeaderLayout } from '../TableColumnHeaderLayout';
import { FilterTableButton } from '@Components/shared/tables/actions';
import { EnumTableFilterDialogContent, TableFilterAction } from '@Components/shared/tables/filters';

// Types
import { FilterItemType } from '@Modal/reducers/types';

type KeyValue<T = string | boolean, V = string | boolean> = {
  key: T;
  value: V;
};

type Props<T extends string> = {
  title: string;
  options: KeyValue<string, string | boolean>[];
  codeName?: string;
  handleSetFilters: (arg: FilterItemType) => void;
};

export const EnumTableColumnHeader = <T extends string>({
  title,
  options,
  codeName,
  handleSetFilters,
}: Props<T>) => {
  return (
    <TableColumnHeaderLayout
      title={title}
      search={
        <TableFilterAction
          codeName={codeName}
          filterOptions={{ options }}
          buttonComponent={FilterTableButton}
          dialogComponent={EnumTableFilterDialogContent}
          handleSetFilters={handleSetFilters}
        />
      }
    />
  );
};
