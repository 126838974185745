// Utils
import { defaultBranchListFilter } from '@utils/tables/branches/branchListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  page: 1,
  rowsPerPage: 10,
  selectedFilters: defaultBranchListFilter,
};

const branchTableSlice = createSlice({
  name: 'branchTable',
  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { selectedFilters } = state;
      const updatedFilters = { ...selectedFilters, [codeName]: value };

      state.selectedFilters = updatedFilters;
    },
    resetSelectedFilters: (state) => {
      state.selectedFilters = defaultBranchListFilter;
    },
  },
  extraReducers: {},
});

export const { reducer: branchTableReducer } = branchTableSlice;
export const { setPage, setRowsPerPage, setSelectedFilters, resetSelectedFilters } =
  branchTableSlice.actions;
