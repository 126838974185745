import { ComponentProps, ReactElement, cloneElement } from 'react';
import { TextField } from '@mui/material';

import { DataAttributeProps } from '@Components/props/DataAttributeProps';
import { RHFTextField } from '@Components/hook-form';
import { RHFIntegerField } from '@Components/shared/fields/RHFIntegerField';
import { ShowablePasswordInputField } from '@Features/roles/SetupRoleDrawer/ShowablePasswordInputField';

type ChildrenProps =
  | ComponentProps<typeof RHFTextField>
  | ComponentProps<typeof RHFIntegerField>
  | ComponentProps<typeof TextField>
  | ComponentProps<typeof ShowablePasswordInputField>;

export type AtTextFieldLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtTextFieldLocator: React.FC<AtTextFieldLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-text-field': name });
};
