// System
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, BaseSyntheticEvent } from 'react';
import { useFormState } from 'react-hook-form';

// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

// Actions
import {
  getSelectedTransaction,
  setShowTransactionDrawer,
  setShowTransactionRefundModalDialog,
  setShowConfirmTransactionRefundModalDialog,
  createTransactionRefund,
} from '@Modal/reducers/transactions/transactionDrawerSlice';

import { useTransactionRefundForm } from '@Forms/transactions/TransactionRefundFormContext';

// Redux
import { useTransactions } from './useTransactions';
import { useTransactionTable } from './useTransactionsTable';

export const useTransactionsDrawer = () => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const {
    showTransactionDrawer,
    selectedTransactionData,
    showTransactionRefundModalDialog,
    showConfirmTransactionRefundModalDialog,
  } = useSelector((state: StoreInterface) => state?.transactionDrawer);

  const { reset, handleSubmit, control } = useTransactionRefundForm();
  const { fetchTransactionsTableContent } = useTransactionTable();
  const { isDirty } = useFormState({ control });

  // Other
  const { enqueueSnackbar } = useSnackbar();

  const handleTransactionRowClick = async (id: string) => {
    return dispatch(getSelectedTransaction(id))
      .unwrap()
      .catch((error: string) => {
        enqueueSnackbar(error, {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      });
  };

  const handleCreateTransactionRefund = useMemo(
    () => (e?: BaseSyntheticEvent) => {
      return handleSubmit(async (formData) => {
        dispatch(
          createTransactionRefund({
            id: selectedTransactionData?.externalTransactionId,
            amount: formData.refund,
          })
        )
          .unwrap()
          .then(() => {
            reset();
            enqueueSnackbar('Transaction is successfully refunded for the specified amount', {
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
            dispatch(setShowTransactionRefundModalDialog(false));
            dispatch(setShowConfirmTransactionRefundModalDialog(false));
            dispatch(setShowTransactionDrawer(false));
            fetchTransactionsTableContent();
          })
          .catch((error) => {
            enqueueSnackbar(error, {
              autoHideDuration: 2000,
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            });
          });
      })(e);
    },
    [selectedTransactionData]
  );

  const handleDrawerCancel = () => {
    return dispatch(setShowTransactionDrawer(false));
  };

  const handleTransactionRefundModalDialog = (payload: boolean) => {
    reset();
    dispatch(setShowTransactionRefundModalDialog(payload));
  };

  const handleConfirmTransactionRefundModalDialog = (payload: boolean) => {
    dispatch(setShowConfirmTransactionRefundModalDialog(payload));
  };

  return {
    // State
    showTransactionDrawer,
    selectedTransactionData,
    showTransactionRefundModalDialog,
    showConfirmTransactionRefundModalDialog,
    submittable: isDirty && selectedTransactionData.status === 'COMPLETED',
    // Actions
    setShowTransactionDrawer,
    setShowTransactionRefundModalDialog,
    setShowConfirmTransactionRefundModalDialog,
    handleDrawerCancel,
    handleTransactionRowClick,
    handleCreateTransactionRefund,
    handleTransactionRefundModalDialog,
    handleConfirmTransactionRefundModalDialog,
  };
};
