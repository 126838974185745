import * as Yup from 'yup';
import { ObjectSchema, number, object, string } from 'yup';
import { useMemo } from 'react';
import { SetupProviderFormState } from '@Forms/providers/SetupProviderFormContext';

export const useSetupProviderUseCaseSchema = (): ObjectSchema<Partial<SetupProviderFormState>> => {
  // @ts-ignore
  return useMemo(
    () =>
      object<SetupProviderFormState>().shape({
        name: string().required('name is required'),
        apiUrl: string().required('api url is required'),
        maxWin: number().required('maximum win is required'),
        minBet: number().required('minimum bet is required'),
        maxBet: number().required('maximum bet is required'),
        betSuggestions: Yup.mixed()
          .required('Bet suggestions are required')
          .test(
            'is-valid-type',
            'Bet suggestions must be a string, an array of strings, a number, or an array of numbers',
            (value) =>
              typeof value === 'string' ||
              typeof value === 'number' ||
              (Array.isArray(value) &&
                value.every((item) => typeof item === 'string' || typeof item === 'number'))
          ),
        minAutoCashout: number().required('min auto cashout is required'),
        maxAutoCashout: number().required('max auto cashout is required'),
        defaultAutoCashout: number().required('default auto cashout is required'),
        language: string().required('language is required'),
        currency: string().required('currency is required'),
        publicKey: string().required('public key is required'),
        privateKey: string().required('private key is required'),
      }),
    []
  );
};
