import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const campaignListColumns = [
  'campaign._id',
  'campaign.description',
  'campaign.providerId',
  //   'campaign.requestId',
  'campaign.startDate',
  'campaign.endDate',
  'campaign.campaignUniqueId',
  'campaign.status',
  'campaign.createdAt',
  'campaign.updatedAt',
] as const;

export type CampaignListColumns = (typeof campaignListColumns)[number];

export type CampaignListFilter = {
  'campaign._id': string | undefined;
  'campaign.description': string | undefined;
  'campaign.providerId': string | undefined;
  //   'campaign.requestId': string | undefined;
  'campaign.startDate': string | undefined;
  'campaign.endDate': string | undefined;
  'campaign.campaignUniqueId': string | undefined;
  'campaign.status': string | undefined;
  'campaign.createdAt': number | undefined;
  'campaign.updatedAt': number | undefined;
};

export const defaultCampaignListFilter: CampaignListFilter = {
  'campaign._id': undefined,
  'campaign.description': undefined,
  'campaign.providerId': undefined,
  //   'campaign.requestId': undefined,
  'campaign.startDate': undefined,
  'campaign.endDate': undefined,
  'campaign.campaignUniqueId': undefined,
  'campaign.status': undefined,
  'campaign.createdAt': undefined,
  'campaign.updatedAt': undefined,
};

export const campaignListColumnVisibilitySettings = {
  'campaign._id': ColumnVisibilityType.Visible,
  'campaign.providerId': ColumnVisibilityType.CanBeHidden,
  //   'campaign.requestId': ColumnVisibilityType.CanBeHidden,
  'campaign.startDate': ColumnVisibilityType.CanBeHidden,
  'campaign.endDate': ColumnVisibilityType.CanBeHidden,
  'campaign.campaignUniqueId': ColumnVisibilityType.CanBeHidden,
  'campaign.status': ColumnVisibilityType.CanBeHidden,
  'campaign.createdAt': ColumnVisibilityType.CanBeHidden,
  'campaign.updatedAt': ColumnVisibilityType.CanBeHidden,
  'campaign.description': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<CampaignListColumns>;

export const defaultCampaignListColumnVisibility = defaultColumnVisibilityParams(
  campaignListColumnVisibilitySettings
);

export const campaignListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  campaignListColumns,
  campaignListColumnVisibilitySettings
);
