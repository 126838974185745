import { format as formatWithoutTimezone } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';
import { useMemo } from 'react';

type Props = {
  date: Date;
  timezone?: string;
  format: keyof typeof DateFormat;
};

const DateFormat = {
  long: {
    base: 'd MMM, yyy-HH:mm',
    timezone: 'D MMM, YYYY-HH:mm',
  },
  medium: {
    base: 'd MMM, yyy',
    timezone: 'D MMM, YYYY',
  },
};

export const DateLabel: React.FC<Props> = ({ date, timezone, format }) => {
  const timezones = useMemo(() => Intl.supportedValuesOf?.('timeZone') ?? [], []);

  return timezone !== undefined && timezones.find((tz) => tz === timezone) !== undefined ? (
    <>{formatToTimeZone(date, DateFormat[format].timezone, { timeZone: timezone })}</>
  ) : (
    <>{formatWithoutTimezone(date, DateFormat[format].base)}</>
  );
};
