// System
import { FormProvider } from 'react-hook-form';

// Form
import { useSetupVehicleForm } from '@Forms/vehicles/SetupVehicleFormContext';

// Components
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';
import { DrawerTabPanelLayout } from '@Components/shared/layouts/DrawerTabPanelLayout';
import { DrawerTabSectionLayout } from '@Components/shared/layouts/DrawerTabSectionLayout';
import { EventCard } from '@Features/rentals/Events/EventCard';
import { Box, Stack } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { EventData } from 'mapbox-gl';
import { useEffect } from 'react';

// Styles
import Styles from './Events.module.scss';

export const Events: React.FC = () => {
  const methods = useSetupVehicleForm();

  const { selectedRental, selectedRentalEvents, handleFetchSelectedRentalEvents } =
    useRentalsDrawer();

  useEffect(() => {
    handleFetchSelectedRentalEvents();
  }, []);

  return (
    <FormProvider {...methods}>
      <DrawerTabPanelLayout>
        <DrawerTabSectionLayout>
          <Stack className={Styles.Events}>
            {selectedRentalEvents?.map((event: EventData) => (
              <>
                <Box className={Styles.EventCardContainer}>
                  <EventCard
                    command={event.command}
                    createdAt={event.createdAt}
                    initiator={event.initiator}
                    activeZones={event.activeZones}
                  />
                </Box>
              </>
            ))}
            {selectedRental.rentalSummary !== null ? (
              <Box className={Styles.FeedbackContainer}>
                <AssetIcon src="rental-event-divider" className={Styles.FeedbackIcon} />
                <Box className={Styles.EventCard}>
                  <EventCard
                    createdAt={`${selectedRental?.rentalSummary?.createdAt}`}
                    command="feedback"
                    initiator="Mobile"
                    activeZones={['']}
                  />
                </Box>
              </Box>
            ) : (
              ''
            )}
          </Stack>
        </DrawerTabSectionLayout>
      </DrawerTabPanelLayout>
    </FormProvider>
  );
};
