import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export type CreateBrandFormState = {
  name: string;
};

export const createBrandFormStateDefault: CreateBrandFormState = {
  name: '',
};

export const createBrandSchemaDefault: ObjectSchema<CreateBrandFormState> =
  object<CreateBrandFormState>().shape({
    name: string()
      .required()
      .matches(/^\w+( +\w+)*$/g, '* This field cannot contain blankspaces'),
  });

type CreateBrandFormContextType = {
  schema: ObjectSchema<CreateBrandFormState>;
  setSchema: Dispatch<ObjectSchema<CreateBrandFormState>>;
  methods?: UseFormReturn<CreateBrandFormState>;
};

const createBrandFormContextDefault = {
  schema: createBrandSchemaDefault,
  setSchema: noop,
} satisfies CreateBrandFormContextType;

export const CreateBrandFormContext = createContext<CreateBrandFormContextType>(
  createBrandFormContextDefault
);

export const useCreateBrandForm = () => {
  const { methods } = useContext(CreateBrandFormContext);
  if (!methods) {
    throw new Error('CreateBrandFormContext is empty');
  }

  return methods;
};

export const useManageCreateBrandSchema = () => {
  const { setSchema } = useContext(CreateBrandFormContext);

  return { setSchema };
};
