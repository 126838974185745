// Components
import { SetupPricingContent } from './SetupPricingContent/SetupPricingContent';
import { SetupDrawer } from '@Components/shared/dialogs/SetupDrawer';

// Redux
import { useDispatch } from 'react-redux';
import { usePricingDrawer } from '@Modal/hooks/pricing/usePricingDrawer';

export const SetupPricingDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { openPricingDrawer, setShowPricingDrawer } = usePricingDrawer();

  return (
    <SetupDrawer
      name="SetupPricing"
      open={openPricingDrawer}
      onClose={() => dispatch(setShowPricingDrawer(false))}
    >
      <SetupPricingContent />
    </SetupDrawer>
  );
};
