// System
import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Types
type Props = {
  title: string;
  amount: number;
  onClick: () => void;
};

// Styles
import Styles from './OperatorReportsOption.module.scss';

export const OperatorReportsOption: React.FC<Props> = ({ title, amount, onClick }) => {
  return (
    <Box className={Styles.OperatorReportsOption} onClick={onClick}>
      <Box className={Styles.SubContainer}>
        <Typography fontSize={'13px'} fontWeight={600}>
          {title}
        </Typography>
        <Box className={Styles.Amount}>
          <Typography fontSize={'12px'} fontWeight={700}>
            {amount}
          </Typography>
        </Box>
      </Box>
      <AssetIcon src="chevron-left" size="xxxl" sx={{ rotate: '180deg', color: '#000000' }} />
    </Box>
  );
};
