import 'simplebar-react/dist/simplebar.min.css';

import { Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from '@Components/scroll-to-top';
import { SettingsProvider } from '@Components/settings';
import SnackbarProvider from '@Components/snackbar';

import { ThemeProvider } from '@Components/theme';
import { StoreContainer } from '@Components/root/StoreContainer';
import { LoadingScreen } from '@Components/shared/loaders/LoadingScreen';

import { Provider } from 'react-redux';

import { store } from '../../../../app/store';
import { dialogs } from './dialogs';

export const RootContainer: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <SettingsProvider>
          <BrowserRouter>
            <ScrollToTop />
            <ThemeProvider>
              <SnackbarProvider>
                <StoreContainer>
                  {dialogs}
                  <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
                </StoreContainer>
              </SnackbarProvider>
            </ThemeProvider>
          </BrowserRouter>
        </SettingsProvider>
      </HelmetProvider>
    </Provider>
  );
};
