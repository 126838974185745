import { Stack } from '@mui/material';

type Props = {
  children: JSX.Element | boolean | (JSX.Element | boolean)[];
};

export const DrawerTabSectionLayout: React.FC<Props> = ({ children }) => {
  return (
    <Stack direction="column" spacing={1.5} sx={{ paddingX: 2, paddingY: 1.5 }}>
      {children}
    </Stack>
  );
};
