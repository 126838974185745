// System
import { useEffect, useMemo, useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';

// Components
import { RHFSelect, RHFTextField } from '@Components/hook-form';
import { AtLocator } from '@Components/shared/locators/AtLocator';
import { MenuItemEx } from '@Components/shared/selects/MenuItemEx';
import { useDisabledTextField } from '@Components/shared/styles/useDisabledTextField';
import { useVehiclesDrawer } from '@Modal/hooks/vehicles/vehicles/useVehicleDrawer';

// Redux
import { useBranchDrawer } from '@Modal/hooks/branches/useBranchDrawer';
import { useBranches } from '@Modal/hooks/branches/useBranch';
import { useVehicles } from '@Modal/hooks/vehicles/useVehicles';
import { useRedis } from '@Modal/hooks/redis/useRedis';
import { getAddressFromLatLng } from '@utils/map/addressDecodes';

const useDropDown = () => {
  const { activeBrandId } = useBranches();
  const { vehicleCategories } = useVehicles();
  const { selectedVehicleData } = useVehiclesDrawer();
  const { categories, getBranchUnassignedCategoriesHandler } = useBranchDrawer();

  const [apiLoading, setApiLoading] = useState(false);

  const fetchCategoriesHandler = () => {
    getBranchUnassignedCategoriesHandler();
    setApiLoading(false);
  };

  useEffect(() => {
    setApiLoading(true);
    fetchCategoriesHandler();
  }, [activeBrandId]);

  const categoriesDropDown = useMemo(
    () =>
      vehicleCategories?.map((category) => (
        <MenuItemEx key={category?.id} value={category?.id} name="Category">
          {category?.name}
        </MenuItemEx>
      )),
    [selectedVehicleData?.category, categories]
  );

  return {
    apiLoading,
    categories: categoriesDropDown,
  };
};

export const GeneralInformation = () => {
  const disabledTextField = useDisabledTextField();
  const { categories, apiLoading } = useDropDown();
  const { selectedRedisVehicle } = useRedis();

  const [lastLocation, setLastLocation] = useState<string>('');

  // Handlers
  const generateVehicleLastLocation = () => {
    if (selectedRedisVehicle) {
      return getAddressFromLatLng({
        lat: selectedRedisVehicle?.deviceInfo?.location?.latitude,
        lng: selectedRedisVehicle?.deviceInfo?.location?.longitude,
      }).then((result) => setLastLocation(result));
    } else {
      setLastLocation('N/A');
    }
  };

  if (apiLoading) {
    return null;
  }

  useEffect(() => {
    if (selectedRedisVehicle) {
      generateVehicleLastLocation();
    }
  }, []);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <AtLocator type="readonly-text" name="GeneralInformation">
          <Typography variant="button">General information</Typography>
        </AtLocator>
      </Stack>
      <Stack direction="column" spacing={1.5}>
        <AtLocator type="text-field" name="name">
          <RHFTextField name="name" id="setup-vehicle-name" label="Name" variant="outlined" />
        </AtLocator>
        <AtLocator type="select-field" name="category.name">
          <RHFSelect
            name="categoryId"
            id="setup-vehicle-category-id"
            label="Category"
            variant="outlined"
          >
            {categories}
          </RHFSelect>
        </AtLocator>
        <AtLocator type="text-field" name="licensePlate">
          <RHFTextField
            name="licensePlate"
            id="setup-vehicle-license-plate"
            label="License Plate"
            variant="outlined"
          />
        </AtLocator>
        <AtLocator type="text-field" name="qrCode">
          <RHFTextField
            name="qrCode"
            id="setup-vehicle-qr-code"
            label="QR Code"
            variant="outlined"
          />
        </AtLocator>
        <AtLocator type="text-field" name="vin">
          <RHFTextField name="vin" id="setup-vehicle-vin" label="VIN" variant="outlined" />
        </AtLocator>
        <AtLocator type="text-field" name="lastLocation">
          <TextField
            disabled
            sx={{ ...disabledTextField }}
            id="setup-vehicle-lastLocatio"
            label="Last location"
            variant="outlined"
            value={lastLocation ? lastLocation : 'N/A'}
          />
        </AtLocator>
      </Stack>
    </Stack>
  );
};
