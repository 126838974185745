import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Utils

// Types
import { VehicleMapDataType } from '../types';
import { axiosBranchesVehiclesInstance } from '@utils/axiosInstances';

// POST
export const getVehiclesOnMap = createAsyncThunk(
  'vehicle/getVehiclesOnMap',
  async (vehicleMapData: VehicleMapDataType, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .get(`?bounds=${vehicleMapData.bounds}&brandId=${vehicleMapData.brandId}`)
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

export const getLiveMapVehicles = createAsyncThunk(
  'vehicle/getLiveMapVehicles',
  async (_, thunkApi) => {
    return axiosBranchesVehiclesInstance
      .post('/v1/vehicle-data/current/backoffice')
      .then((res) => {
        return thunkApi.fulfillWithValue(res.data);
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message || 'Rejected');
      });
  }
);

// Type
const initialState = {
  vehiclesOnMap: [],
  areFiltersChecked: false,
};

const vehicleMapSlice = createSlice({
  name: 'vehicleMapSlice',
  initialState,
  reducers: {
    setVehiclesOnMap: (state, { payload }) => {
      state.vehiclesOnMap = payload;
    },
    setAreLiveMapFiltersChecked: (state, { payload }) => {
      state.areFiltersChecked = payload;
    },
  },
  extraReducers: {
    [getLiveMapVehicles.fulfilled?.type]: (state, action) => {
      state.vehiclesOnMap = action.payload;
    },
  },
});

export const { reducer: vehiclesMapReducer } = vehicleMapSlice;
export const { setVehiclesOnMap, setAreLiveMapFiltersChecked } = vehicleMapSlice.actions;
