import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  selectedRoundData: {},
  showRoundDrawer: false,
};

const roundDrawerSlice = createSlice({
  name: 'roundDrawer',
  initialState,
  reducers: {
    setShowRoundDrawer: (state, { payload }) => {
      state.showRoundDrawer = payload;
    },
    setSelectedRound: (state, { payload }) => {
      state.selectedRoundData = payload;
    },
  },
});

export const { reducer: roundDrawerReducer } = roundDrawerSlice;
export const { setShowRoundDrawer, setSelectedRound } = roundDrawerSlice.actions;
