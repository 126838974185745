import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const ThrottleOff = ({ width, height }: CardProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#E6F0F1" />
    <path
      d="M14.6667 14.6667L11.4112 18.5732C11.1205 18.922 10.9752 19.0964 10.973 19.2437C10.9711 19.3718 11.0281 19.4936 11.1277 19.5741C11.2423 19.6667 11.4693 19.6667 11.9234 19.6667H18L17.1667 26.3333L21.3333 21.3333M21.0417 16.3333H24.0766C24.5307 16.3333 24.7577 16.3333 24.8723 16.4259C24.9719 16.5064 25.0289 16.6282 25.027 16.7563C25.0248 16.9036 24.8795 17.078 24.5888 17.4268L23.4585 18.7831M16.8106 12.0939L18.8333 9.66667L18.3335 13.6648M25.5 25.5L10.5 10.5"
      stroke="#232625"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
