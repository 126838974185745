import { TabDrawerLayout } from '@Components/shared/layouts/TabDrawerLayout';
import { SaveSettingsButtonPanel } from '@Components/shared/buttons/SaveSettingsButtonPanel';
import { DrawerHeaderLayout } from '@Components/shared/layouts/DrawerHeaderLayout';

import { SetupCustomerHeader } from './SetupCustomerHeader';
import { SetupCustomerTabs } from './SetupCustomerTabs';
import { useCustomersDrawer } from '@Modal/hooks/customers/useCustomerDrawer';

export const SetupCustomerContent: React.FC = () => {
  const { handleCreateCancel, handleSubmitGeneralForm, submittable } = useCustomersDrawer();

  return (
    <TabDrawerLayout
      header={
        <DrawerHeaderLayout currentSlug="/customers" onClose={handleCreateCancel}>
          <SetupCustomerHeader />
        </DrawerHeaderLayout>
      }
      footer={
        <SaveSettingsButtonPanel
          onSaveClick={handleSubmitGeneralForm}
          saveDisabled={!submittable}
          onCancelClick={handleCreateCancel}
        />
      }
    >
      <SetupCustomerTabs />
    </TabDrawerLayout>
  );
};
