import { Button } from '@mui/material';
import FormProvider from '@Components/hook-form';

import { AtLocator } from '@Components/shared/locators/AtLocator';

import { CreateDialogFooterLayout } from '@Components/shared/layouts/CreateDialogFooterLayout';
import { CreateDialogLayout } from '@Components/shared/layouts/CreateDialogLayout';

import { CreateRoundConfigContent } from './CreateRoundConfigContent';
import { useCreateRoundConfig } from '@Modal/hooks/rounds/useCreateRoundConfig';
import { useCreateRoundConfigForm } from '@Forms/rounds/CreateRoundConfigFormContext';

export const CreateRoundConfigForm = () => {
  const methods = useCreateRoundConfigForm();
  const { handleCreateCancel, submittable, handleSubmitCreateRoundConfig } = useCreateRoundConfig();

  return (
    <FormProvider methods={methods}>
      <CreateDialogLayout>
        <CreateRoundConfigContent />
        <CreateDialogFooterLayout>
          <AtLocator type="button" name="Cancel">
            <Button variant="outlined" color="inherit" onClick={handleCreateCancel}>
              Cancel
            </Button>
          </AtLocator>
          <AtLocator type="button" name="Submit">
            <Button
              type="submit"
              variant="contained"
              disabled={!submittable}
              onClick={handleSubmitCreateRoundConfig}
            >
              Confirm
            </Button>
          </AtLocator>
        </CreateDialogFooterLayout>
      </CreateDialogLayout>
    </FormProvider>
  );
};
