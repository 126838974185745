import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LinkIoTFormContext,
  SetupLinkIoTFormStateDTO,
  linkIoTFormStateDefault,
  linkIoTSchemaDefault,
} from '@Forms/operator/SetupLinkIoTFormContext';

export const LinkIoTFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<SetupLinkIoTFormStateDTO>>>(linkIoTSchemaDefault);
  const methods = useForm({
    defaultValues: linkIoTFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return <LinkIoTFormContext.Provider value={value}>{children}</LinkIoTFormContext.Provider>;
};
