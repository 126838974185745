// System
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Button, TextField, Typography } from '@mui/material';

// Assets
import aviatorAuth from './aviatorAuth.png';

// Hooks
import useResponsive from '@Hooks/shared/useResponsive';
import { useAuthorization } from '@Modal/hooks/authorization/useAuthorization';

// Styles
import Styles from './Authorization.module.scss';

export const AuthorizationPage = () => {
  const navigate = useNavigate();
  const { handleAuthorizationRequests, handleDecodeToken, accessToken, currentUser, allRoutes } =
    useAuthorization();
  const formDataRef = useRef({
    username: '',
    password: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formDataRef.current[name as keyof typeof formDataRef.current] = value;
  };
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    if (accessToken && !currentUser) {
      handleDecodeToken();
    }

    if (accessToken && currentUser) {
      const accessableRoutes = allRoutes.filter((route) => route.roles.includes(currentUser?.role));

      if (accessableRoutes?.length === 0) {
        enqueueSnackbar('No available pages for this role', {
          autoHideDuration: 4000,
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      } else {
        navigate(accessableRoutes[0]?.path);
      }
    }
  }, [currentUser]);

  return (
    <Box className={Styles.Authorization}>
      {isMobile ? null : (
        <Box>
          <img
            src={`${aviatorAuth}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
        </Box>
      )}

      <Box className={Styles.FormContainer}>
        <Typography className={Styles.HeaderTitle}>Sign in to the account</Typography>
        <form
          className={Styles.SubFormContainer}
          onSubmit={(event) => {
            event.preventDefault();
            handleAuthorizationRequests({
              username: formDataRef.current.username,
              password: formDataRef.current.password,
              save: true,
            });
          }}
        >
          <TextField
            className={Styles.FormInput}
            placeholder="Email address"
            label="Email address"
            name="username"
            onChange={handleChange}
          />
          <TextField
            className={Styles.FormInput}
            id="password"
            name="password"
            label="Password"
            type="password"
            onChange={handleChange}
          />
          <Button type="submit" className={Styles.FormButton} variant="contained">
            Log in
          </Button>
        </form>
      </Box>
    </Box>
  );
};
