import { CSSProperties, useMemo } from 'react';
import { useTheme } from '@mui/material';

import { IotStatus } from '@contracts/domain/keys';
import { KeyValue } from '@contracts/view/KeyValue';

export const useIotStatusTitle = (): Record<IotStatus, string> => {
  return useMemo(
    () => ({
      offline: 'Offline',
      online: 'Online',
      unknown: 'Unknown',
    }),
    []
  );
};

export const useIotStatusOptions = (): KeyValue<IotStatus, string>[] => {
  const titles = useIotStatusTitle();
  return useMemo(
    () => (Object.keys(titles) as IotStatus[]).map((key) => ({ key, value: titles[key] })),
    [titles]
  );
};

export const useIotStatusColor = (): Record<IotStatus, CSSProperties['color']> => {
  const { palette } = useTheme();
  return {
    offline: palette.error.main,
    online: palette.success.main,
    unknown: palette.action.disabled,
  };
};
