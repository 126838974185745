import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Update instance
import { axiosAuthorizationInstance } from '@utils/axiosInstances';

export const getUsersList = createAsyncThunk('users/getUsers', async (_, thunkApi) => {
  const config = {
    headers: {
      'x-private-key': process.env.REACT_APP_BACKOFFICE_PRIVATE_KEY,
    },
  };
  return axiosAuthorizationInstance
    .get('/api/v1/accounts?page=1&limit=20', config)
    .then((res) => {
      return thunkApi.fulfillWithValue(res.data);
    })
    .catch((error) => {
      return thunkApi.rejectWithValue(error);
    });
});

// Type
const initialState = {
  users: [],
  loadingContent: false,
  pagination: {
    page: 0,
    count: 0,
    rowsPerPage: 25,
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList: (state, { payload }) => {
      state.users = payload;
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload;
    },
  },
  extraReducers: {
    [getUsersList.fulfilled?.type]: (state, action) => {
      const { items, pageInfo } = action.payload;
      state.users = action.payload;
      state.loadingContent = false;
      //   state.pagination = {
      //     ...state.pagination,
      //     count: pageInfo.totalCount,
      //     rowsPerPage: pageInfo.limit,
      //   };
    },
  },
});

export const { reducer: usersReducer } = usersSlice;
export const { setUsersList, setPagination } = usersSlice.actions;
