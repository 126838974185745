import { ComponentProps, ReactElement, cloneElement } from 'react';
import { Popover } from '@mui/material';
import { DataAttributeProps } from '@Components/props/DataAttributeProps';

type ChildrenProps = ComponentProps<typeof Popover>;

export type AtPopoverLocatorProps = {
  children: ReactElement<ChildrenProps & DataAttributeProps>;
  name: string;
};

export const AtPopoverLocator: React.FC<AtPopoverLocatorProps> = ({ name, children }) => {
  return cloneElement(children, { 'data-at-popover': name });
};
