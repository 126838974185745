import { EnumFilterValue } from '@contracts/common/EnumFilterValue';

export type StateColumnFilter = EnumFilterValue<'new' | 'assigned' | 'unassigned' | 'retired'>;

export type IotType = EnumFilterValue<'okai' | 'omni' | 'niu' | 'dunasys'>;

export type IotModuleListFilter = {
  id: number | undefined;
  imei: string | undefined;
  iccid: string | undefined;
  state: StateColumnFilter;
  iotType: IotType;
  limit: number;
};

export const defaultIotModuleListFilter: IotModuleListFilter = {
  id: undefined,
  imei: undefined,
  iccid: undefined,
  state: {},
  iotType: {},
  limit: 25,
};
