import {
  defaultFreebetUsageListFilter,
  freebetUsageListColumnVisibilityOptions,
  defaultFreebetUsageListColumnVisibility,
} from '@utils/tables/freebetsUsage/freebetsUsageListFilter';

// System
import { createSlice } from '@reduxjs/toolkit';

// Type
const initialState = {
  limit: 25,
  page: 1,
  total: 0,
  freebetUsageTableFilters: {},
  selectedFilters: defaultFreebetUsageListFilter,
  columnVisibility: defaultFreebetUsageListColumnVisibility,
  columnVisibilityOptions: freebetUsageListColumnVisibilityOptions,
};

const freebetUsageTableSlice = createSlice({
  name: 'freebetUsageTable',
  initialState,
  reducers: {
    setLimit: (state, { payload }) => {
      state.limit = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setTotal: (state, { payload }) => {
      state.total = payload;
    },
    setColumnVisibility: (state, { payload }) => {
      state.columnVisibility = payload;
    },
    setFreebetUsageTableFilters: (state, { payload }) => {
      const { codeName, value } = payload;
      const { freebetUsageTableFilters } = state;

      const newFreebetUsageTableFilters = { ...freebetUsageTableFilters, [codeName]: value };
      state.freebetUsageTableFilters = newFreebetUsageTableFilters;
    },
  },
  extraReducers: {},
});

export const { reducer: freebetUsageTableReducer } = freebetUsageTableSlice;
export const { setColumnVisibility, setLimit, setPage, setTotal, setFreebetUsageTableFilters } =
  freebetUsageTableSlice.actions;
