// System
import { Box, Stack, Typography } from '@mui/material';
// Components
import { DateLabel } from '@Components/shared/labels/DateLabel';
import { TransactionStatus } from '@Features/transactions/SetupTransaction/information/TransactionStatus';
// Styles
import Styles from './CustomerTransactionCard.module.scss';

interface Props {
  currency: string;
  amount: string;
  date: string;
  type: string;
  id: string;
  status: string;
}

export const CustomerTransactionCard: React.FC<Props> = ({
  currency,
  amount,
  date,
  type,
  id,
  status,
}) => {
  return (
    <Stack className={Styles.CustomerTransactionCard}>
      <Stack>
        <Typography className={Styles.BoldFont}>
          {currency} {amount}
        </Typography>
        <Typography className={Styles.LightFont}>
          <DateLabel date={new Date(date)} format="long" />
        </Typography>
      </Stack>
      <Box className={Styles.TypeAndIdContainer}>
        <Typography className={Styles.BoldFont}>{type}</Typography>
        <Typography className={Styles.LightFont}>ID {id}</Typography>
      </Box>
      <Box className={Styles.StatusContainer}>
        <TransactionStatus status={status} />
      </Box>
    </Stack>
  );
};
