import { useMemo } from 'react';
import { ObjectSchema, object, string, number, boolean } from 'yup';

import { SetupCategoryFormState } from '@Forms/vehicles/SetupCategoryFormContext';
import {
  ApproachCommandsFormData,
  CloseCommandsFormData,
  CommandsFormData,
  DashboardCommandsFormData,
  DeployCommandsFormData,
} from '@contracts/formData/vehicles/commands/CommandsFormData';
import { NoMovementFormData } from '@contracts/formData/vehicles/NoMovementFormData';

export const useSetupCategoryUseCaseSchema = (): ObjectSchema<Partial<SetupCategoryFormState>> => {
  return useMemo(
    () =>
      object<Partial<SetupCategoryFormState>>().shape({
        id: string().required('ID is required'),
        name: string()
          .required('Name is required')
          .matches(/([A-Z]?)\w+\s?/, 'Field name should not include special symbols'),
        vehicleType: string().required('Vehicle type is required'),
        brandId: string().required(),
        displayName: string().required('Display Name type is required'),
        helmetTracking: boolean(),
        batteryMedium: number()
          .typeError('Must be a number')
          .max(100, 'Must be less than or equal to 100')
          .min(0, 'Must not be negative'),
        batteryLow: number()
          .typeError('Must be a number')
          .max(100, 'Must be less than or equal to 100')
          .min(0, 'Must not be negative'),
        batteryCritical: number()
          .typeError('Must be a number')
          .max(100, 'Must be less than or equal to 100')
          .min(0, 'Must not be negative'),
        iotBatteryLevelFilters: boolean(),
        iotBatteryMedium: number()
          .typeError('Must be a number')
          .max(100, 'Must be less than or equal to 100')
          .min(0, 'Must not be negative'),
        iotBatteryLow: number()
          .typeError('Must be a number')
          .max(100, 'Must be less than or equal to 100')
          .min(0, 'Must not be negative'),
        iotBatteryCritical: number()
          .typeError('Must be a number')
          .max(100, 'Must be less than or equal to 100')
          .min(0, 'Must not be negative'),
        speedMode: string(),
        maxSpeed: number()
          .nullable()
          .typeError('Must be a number')
          .max(300, 'Should have real value')
          .min(0, "Shoudln't be negative"),
        maxSpeedSlowZone: number()
          .nullable()
          .typeError('Must be a number')
          .max(300, 'Should have real value')
          .min(0, "Shoudln't be negative"),
        maxDistanceWithFullBattery: number()
          .nullable()
          .typeError('Must be a number')
          .max(300, 'Should have real value')
          .min(0, "Shoudln't be negative"),
        reservationDistanceMax: number().nullable().typeError('Must be a number'),
        rentalDistanceMax: number().nullable().typeError('Must be a number'),
        minBalanceWithPaymentSource: number().nullable().typeError('Must be a number'),
        minBalanceWithoutPaymentSource: number().nullable().typeError('Must be a number'),
        minAge: number().nullable().typeError('Must be a number'),
        signedUp: boolean(),
        emailAddressSpecified: boolean(),
        phoneNumberSpecified: boolean(),
        firstNameSpecified: boolean(),
        lastNameSpecified: boolean(),
        dateOfBirthSpecified: boolean(),
        minAgeAccordance: boolean(),
        streetSpecified: boolean(),
        postcodeSpecified: boolean(),
        citySpecified: boolean(),
        countrySpecified: boolean(),
        creditCardAttached: boolean(),
        paymentMethodSelected: boolean(),
        paidSignUpFeeViaCreditCard: boolean(),
        enableCreditCardPreAuthorization: boolean(),
        settleNegativeBalance: boolean(),
        vehicleIsInOperationalArea: boolean(),
        vehicleIsNotInNoParkingArea: boolean(),
        vehicleIsCharging: boolean(),
        speedIsZero: boolean(),
        ignitionMustBeTurnedOffAfterEndingRental: boolean(),
        ignitionMustBeSwitchedOffToEndRental: boolean(),
        helmetsDetected: boolean(),
        bikeLockIsLocked: boolean(),
        commands: object<CommandsFormData>()
          .shape({
            dashboard: object<DashboardCommandsFormData>({
              start: boolean(),
              stop: boolean(),
              unlockBattery: boolean(),
              unlockHelmet: boolean(),
              alarmOn: boolean(),
              setMaxSpeed: boolean(),
              reboot: boolean(),
              productionMode: boolean(),
              demoMode: boolean(),
              openSaddle: boolean(),
              openTailBox: boolean(),
            }),
            approach: object<ApproachCommandsFormData>({
              refresh: boolean(),
              alarmOn: boolean(),
              start: boolean(),
              setOutOfOrder: boolean(),
              unlockBattery: boolean(),
            }),
            deploy: object<DeployCommandsFormData>({
              refresh: boolean(),
              alarmon: boolean(),
              stop: boolean(),
              setOperational: boolean(),
            }),
            close: object<CloseCommandsFormData>({
              refresh: boolean(),
              alarmOn: boolean(),
              stop: boolean(),
            }),
          })
          .nullable(),
        noMovement: object<NoMovementFormData>({
          maxDistance: number()
            .typeError('Distance must be a number')
            .min(0, 'Distance must be higher than 0')
            .max(1000, 'Distance must be lower than 1000'),
          maxTime: number()
            .min(0, 'Time must be higher than 0')
            .max(1000, 'Time must be lower than 1000')
            .typeError('Time must be a number'),
          autoEndRental: boolean(),
          endRentalAfterMinutes: number()
            .min(0, 'End rental time must be higher than 0')
            .max(1000, 'End rental time must be lower than 1000')
            .typeError('End rental time must be a number')
            .nullable(),
          autoEndMaxCost: number()
            .min(0, 'End rental time must be higher than 0')
            .typeError('Auto End Maxomum Cost must be a number')
            .nullable(),
        }).nullable(),
      }),
    []
  );
};
