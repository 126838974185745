import { ComponentType } from 'react';

import { AlertType, AlertMap } from '@contracts/domain/Alert';
import { SuccessAlert } from '@Components/core/alerts/SuccessAlert/SuccessAlert';
import { SetupSuccessAlertButton } from '@Components/core/alerts/SuccessAlert/buttons/SetupSuccessAlertButton';
import { AlertPresenterProps } from '@Components/props/alerts/AlertPresenterProps';
import { DissmissSuccessAlertButton } from '@Components/core/alerts/SuccessAlert/buttons/DissmissSuccessAlertButton';
import { useAutoHideAlert } from '@Features/providers/store/AlertStore';

type AlertPresenterMapType = { [K in AlertType]: ComponentType<AlertPresenterProps<AlertMap[K]>> };

export const AlertPresenterMap: AlertPresenterMapType = {
  [AlertType.BranchWasCreated]: ({ value: { branchId, brandId }, onClose }) => {
    return (
      <SuccessAlert
        message="Branch was successfully created! you can set-up it now."
        actions={[
          <SetupSuccessAlertButton
            key="success"
            onClick={() => {
              onClose?.();
              // show({ branchId, brandId });
            }}
          />,
          <DissmissSuccessAlertButton key="dissmiss" onClick={onClose} />,
        ]}
      />
    );
  },
  [AlertType.BranchWasUpdated]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="Updates were successfully made!" onClose={onClose} />;
  },
  [AlertType.VehicleCVSUploaded]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return (
      <SuccessAlert message="Your CSV file has been successfully imported!" onClose={onClose} />
    );
  },
  [AlertType.VehicleCategoryUpdated]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="Updates were successfully made!" onClose={onClose} />;
  },
  [AlertType.VehicleWasCreated]: ({ value, onClose }) => {
    return (
      <SuccessAlert
        message="Vehicle was successfully added"
        actions={[
          <SetupSuccessAlertButton
            key="success"
            onClick={() => {
              onClose?.();
            }}
          />,
          <DissmissSuccessAlertButton key="dissmiss" onClick={onClose} />,
        ]}
      />
    );
  },
  [AlertType.VehicleCategoryCreated]: ({ value: { categoryId, brandId }, onClose }) => {
    return (
      <SuccessAlert
        message="Vehicle category was successfully added"
        actions={[
          <SetupSuccessAlertButton
            key="success"
            onClick={() => {
              onClose?.();
            }}
          />,
          <DissmissSuccessAlertButton key="dissmiss" onClick={onClose} />,
        ]}
      />
    );
  },
  [AlertType.VehicleWasUpdated]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="Updates were successfully made!" onClose={onClose} />;
  },
  [AlertType.IotModulesCVSUploaded]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="IoT modules successfully registered" onClose={onClose} />;
  },
  [AlertType.IotModuleWasCreated]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="IoT modules successfully registered" onClose={onClose} />;
  },
  [AlertType.IotModuleWasUpdated]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="IoT modules successfully updated" onClose={onClose} />;
  },
  [AlertType.CustomerWasUpdated]: ({ value, onClose }) => {
    useAutoHideAlert(value);
    return <SuccessAlert message="Customer successfully updated" onClose={onClose} />;
  },
};
