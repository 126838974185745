import React from 'react';

import { Stack } from '@mui/material';
import Scrollbar from '@Components/scrollbar';

import { GeneralRequirements } from './GeneralRequirements';
import { RentalRequirementsToggle } from './RentalRequirementsToggle';

export const RentalRequirements = () => {
  return (
    <Scrollbar autoHide={false} forceVisible="y">
      <Stack direction="column" spacing={1.5} sx={{ paddingX: 2, paddingY: 1.5 }}>
        <GeneralRequirements />
        <RentalRequirementsToggle />
      </Stack>
    </Scrollbar>
  );
};
