import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ObjectSchema, object, string } from 'yup';

export type DeleteCustomerFormState = {
  note: string;
};

export const DeleteCustomerFormStateDefault: DeleteCustomerFormState = {
  note: '',
};

export const DeleteCustomerSchemaDefault: ObjectSchema<Partial<DeleteCustomerFormState>> = object<
  Partial<DeleteCustomerFormState>
>().shape({
  note: string()
    .required('Note is required')
    .test('min-characters', 'Note must be at least 2 non-space characters long', (value) => {
      const nonSpaceChars = value.replace(/\s/g, '').length;
      return nonSpaceChars >= 2;
    }),
});

type DeleteCustomerFormContextType = {
  schema: ObjectSchema<Partial<DeleteCustomerFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<DeleteCustomerFormState>>>;
  methods?: UseFormReturn<DeleteCustomerFormState>;
};

const deleteCustomerFormContextDefault = {
  schema: DeleteCustomerSchemaDefault,
  setSchema: noop,
} satisfies DeleteCustomerFormContextType;

export const DeleteCustomerFormContext = createContext<DeleteCustomerFormContextType>(
  deleteCustomerFormContextDefault
);

export const useDeleteCustomerForm = () => {
  const { methods } = useContext(DeleteCustomerFormContext);
  if (!methods) {
    throw new Error('DeleteCustomerFormContext is empty');
  }

  return methods;
};

export const useManageDeleteCustomerSchema = () => {
  const { setSchema } = useContext(DeleteCustomerFormContext);

  return { setSchema };
};
