// System
import { AssetIcon } from '@Components/shared/images/AssetIcon';
import { Box, Stack } from '@mui/material';

// Icons
import { ResumeEventIcon } from './ResumeEventIcon';
import { CancelEventIcon } from './CancelEventIcon';
import { SlowModeEventIcon } from './SlowModeEventIcon';
import { HighModeEventIcon } from './HighModeEventIcon';
import { ThrottleOn } from './ThrottleOnEventIcon';
import { ThrottleOff } from './ThrottleOffEventIcon';

type Props = {
  event: string;
};

export const RentalEventIcon = ({ event }: Props) => {
  const generateRentalEventIcon = () => {
    switch (event) {
      case 'start':
        return (
          <Box
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgba(216, 251, 222, 1)',
              marginRight: '16px',
            }}
          >
            <AssetIcon
              src="rental-start"
              sx={{ width: '20px', height: '20px', zIndex: '5', color: 'rgba(54, 179, 126, 1)' }}
            />
          </Box>
        );

      case 'pause':
        return (
          <Box
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgba(49, 134, 136, 0.12)',
              marginRight: '16px',
            }}
          >
            <AssetIcon
              src="rental-park"
              sx={{ width: '20px', height: '20px', zIndex: '5', color: 'rgba(49, 134, 136, 1)' }}
            />
          </Box>
        );
      case 'resume':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgb(216, 251, 222)',
              marginRight: '16px',
            }}
          >
            <ResumeEventIcon width={35} height={35} />
          </Stack>
        );
      case 'cancel':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgb(255, 233, 213, 1)',
              marginRight: '16px',
            }}
          >
            <CancelEventIcon width={35} height={35} />
          </Stack>
        );
      case 'stop':
        return (
          <Box
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgba(255, 233, 213, 1)',
              marginRight: '16px',
            }}
          >
            <AssetIcon
              src="rental-end"
              sx={{ width: '20px', height: '20px', zIndex: '5', color: 'rgba(255, 86, 48, 1)' }}
            />
          </Box>
        );
      case 'slow_mode':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgb(49, 134, 136, 0.12)',
              marginRight: '16px',
            }}
          >
            <SlowModeEventIcon width={35} height={35} />
          </Stack>
        );
      case 'high_mode':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgb(202, 253, 245)',
              marginRight: '16px',
            }}
          >
            <HighModeEventIcon width={35} height={35} />
          </Stack>
        );
      case 'throttle_on':
        return (
          <Box
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgba(216, 251, 222)',
              marginRight: '16px',
            }}
          >
            <ThrottleOn width={35} height={35} />
          </Box>
        );
      case 'throttle_off':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgb(230, 240, 241)',
              marginRight: '16px',
            }}
          >
            <ThrottleOff width={35} height={35} />
          </Stack>
        );
      case 'feedback':
        return (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '36px',
              height: '36px',
              backgroundColor: 'rgb(216, 251, 222)',
              marginRight: '16px',
            }}
          >
            <ThrottleOff width={35} height={35} />
          </Stack>
        );
      default:
        break;
    }
  };

  return <Box>{generateRentalEventIcon()}</Box>;
};
