import { Stack, Typography } from '@mui/material';

export const FreebetsUsageTitle: React.FC = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ paddingBottom: 2 }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">Usage</Typography>
      </Stack>
    </Stack>
  );
};
