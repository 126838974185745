import React from 'react';

type CardProps = {
  width: number;
  height: number;
};

export const HighModeEventIcon = ({ width, height }: CardProps) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#CAFDF5" />
    <g clipPath="url(#clip0_24395_11820)">
      <path
        d="M22.7877 21.3333C23.4468 20.3884 23.8333 19.2394 23.8333 18C23.8333 17.5709 23.787 17.1527 23.699 16.75M13.2122 21.3333C12.5531 20.3885 12.1666 19.2394 12.1666 18C12.1666 14.7783 14.7783 12.1667 18 12.1667C18.3517 12.1667 18.6962 12.1978 19.0309 12.2575M21.7499 14.25L17.9999 18M26.3333 18C26.3333 22.6024 22.6023 26.3333 18 26.3333C13.3976 26.3333 9.66663 22.6024 9.66663 18C9.66663 13.3976 13.3976 9.66667 18 9.66667C22.6023 9.66667 26.3333 13.3976 26.3333 18ZM18.8333 18C18.8333 18.4602 18.4602 18.8333 18 18.8333C17.5397 18.8333 17.1666 18.4602 17.1666 18C17.1666 17.5398 17.5397 17.1667 18 17.1667C18.4602 17.1667 18.8333 17.5398 18.8333 18Z"
        stroke="#006C9C"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_24395_11820">
        <rect width="20" height="20" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);
