// System
import { Stack } from '@mui/material';
import { AssetIcon } from '@Components/shared/images/AssetIcon';

// Hooks
import useResponsive from '@Hooks/shared/useResponsive';
import { useRentalsDrawer } from '@Modal/hooks/rentals/useRentalsDrawer';

// Components
import { DetailsItem } from '../DetailsItem/DetailsItem';

// Styles
import Styles from './RentalInfo.module.scss';

export const RentalInfo = () => {
  const { selectedRentalPriceBreakdown } = useRentalsDrawer();

  const isDesktop = useResponsive('up', 'sm');

  return (
    <Stack
      direction={isDesktop ? 'row' : 'column'}
      justifyContent="space-between"
      alignItems={isDesktop ? 'center' : 'flex-start'}
      className={Styles.RentalInfo}
    >
      <DetailsItem
        icon={<AssetIcon src="clock-fast-forward" size="xxxl" />}
        title="Duration"
        state={
          parseFloat(selectedRentalPriceBreakdown.rideDuration) +
          parseFloat(selectedRentalPriceBreakdown.pauseDuration)
        }
      />
      <DetailsItem
        icon={<AssetIcon src="battery-charging-02" size="xxxl" />}
        title="Battery used"
        state={null}
      />
      <DetailsItem
        icon={<AssetIcon src="speedometer-01" size="xxxl" />}
        title="Distance"
        state={null}
      />
    </Stack>
  );
};
