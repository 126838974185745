import { TableColumnDescription } from '@contracts/view/TableColumnDescription';
import { TableHeadCellEx } from '../../components';

type Props<
  T extends Record<string, unknown>,
  H extends string = string,
  K extends string = string
> = {
  columns: TableColumnDescription<T, H, K>[];
};
export const TableHeadColumnsPresenter = <
  T extends Record<string, unknown>,
  H extends string = string,
  K extends string = string
>({
  columns,
}: Props<T, H, K>) => {
  return (
    <>
      {columns.map(({ name, title, sx, header: Header }) => (
        <TableHeadCellEx key={name} sx={sx} data-at-table-column={name}>
          <Header title={title} />
        </TableHeadCellEx>
      ))}
    </>
  );
};
