import {
  CancelDialogActions,
  ImageCancelDialogLayout,
  CancelDialogMessageLayout,
  CancelModal,
} from '@Components/shared/dialogs/CancelDialog';
import { useOperatorScan } from '@Modal/hooks/operator/useOperatorScan';

export const SetupOperatorScanCancelModal: React.FC = () => {
  const { showOperatorScanModalCancel, handleScanCancelModal } = useOperatorScan();
  return (
    <>
      {showOperatorScanModalCancel && (
        <CancelModal open={showOperatorScanModalCancel}>
          <ImageCancelDialogLayout
            title="Are you sure you want to cancel?"
            actions={
              <CancelDialogActions
                onReject={() => handleScanCancelModal('back')}
                onConfirm={() => handleScanCancelModal('cancel')}
              />
            }
          >
            <CancelDialogMessageLayout>
              You are about to close scan modal and undo all of the actions
            </CancelDialogMessageLayout>
          </ImageCancelDialogLayout>
        </CancelModal>
      )}
    </>
  );
};
