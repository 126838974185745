import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SetupVehicleFormState,
  setupVehicleFormStateDefault,
  setupVehicleSchemaDefault,
  SetupVehicleFormContext,
} from '@Forms/vehicles/SetupVehicleFormContext';

export const SetupVehicleFormProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [schema, setSchema] =
    useState<ObjectSchema<Partial<SetupVehicleFormState>>>(setupVehicleSchemaDefault);
  const methods = useForm({
    defaultValues: setupVehicleFormStateDefault,
    resolver: yupResolver(schema),
  });
  const value = useMemo(() => ({ schema, setSchema, methods }), [schema, setSchema, methods]);

  return (
    <SetupVehicleFormContext.Provider value={value}>{children}</SetupVehicleFormContext.Provider>
  );
};
