import { useContext, useMemo } from 'react';
import { SelectBoxContext } from './SelectBoxContext';

export const useSelectBox = () => {
  const { open } = useContext(SelectBoxContext);
  return useMemo(() => ({ open }), [open]);
};

export const useManageSelectBox = () => {
  const { setOpen } = useContext(SelectBoxContext);
  return useMemo(
    () => ({
      handleClose: (onClose?: () => void) => () => {
        setOpen(false);
        onClose?.();
      },
      handleOpen: () => setOpen(true),
    }),
    [setOpen]
  );
};
