// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowRoundDrawer,
  setSelectedRound,
} from '@Modal/reducers/rounds/drawer/roundDrawerSlice';
// System
import { useSnackbar } from 'notistack';
// Graphql
import { usePaginatedAviatorRoundsLazyQuery } from '@Src/generated.graphql';
// Types
import { AppDispatch } from 'src/app/store';
import { StoreInterface } from 'src/app/types';

export const useRoundDrawer = () => {
  // Graphql
  const [getRound] = usePaginatedAviatorRoundsLazyQuery();
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const { showRoundDrawer, selectedRoundData } = useSelector(
    (state: StoreInterface) => state?.roundDrawer
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const handleRoundRowClick = async (id: string) => {
    try {
      const { data } = await getRound({
        variables: {
          limit: 1,
          page: 0,
          filter: {
            id: id,
          },
        },
      });
      if (data && data?.paginatedAviatorRounds) {
        await dispatch(setSelectedRound(data?.paginatedAviatorRounds?.docs?.[0]));
      }
    } catch (error) {
      enqueueSnackbar(error, {
        autoHideDuration: 2000,
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } finally {
      dispatch(setShowRoundDrawer(true));
    }
  };

  return {
    // Store
    showRoundDrawer,
    selectedRoundData,
    // Actions
    setSelectedRound,
    setShowRoundDrawer,
    handleRoundRowClick,
  };
};
