import { Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SystemStyleObject } from '@mui/system';

import { AtLocator } from '@Components/shared/locators/AtLocator';
import { TabEx } from '@Components/shared/selects/TabEx';
import { useTabs } from '@Components/shared/hooks/useTabs';
import { TabsDrawerLayout } from '@Components/shared/layouts/TabsDrawerLayout';
import useResponsive from '@Hooks/shared/useResponsive';
import { Details } from './tabs/Details';
import { CustomerRentals } from './tabs/Rentals/CustomerRentals/CustomerRentals';
import { CustomerNotes } from './tabs/Notes/CustomerNotes';
import { PaymentMethods } from './tabs/PaymentMethods';
import { Balances } from './tabs/Balances';
import { CustomerTransactions } from './tabs/Transactions';

const useSx = () => {
  return {
    panel: { padding: 0, height: '100%' },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupCustomerTabs: React.FC = () => {
  const { currentTab, handleSwithTab } = useTabs('1');
  const styles = useSx();
  const isDesktop = useResponsive('up', 'sm');

  const mobileStyles = !isDesktop
    ? {
        maxWidth: 320,
        overflow: 'auto !important',
      }
    : null;

  return (
    <TabContext value={currentTab}>
      <TabsDrawerLayout
        header={
          <AtLocator type="tab-list" name="SetupCustomer">
            <TabList
              sx={{ ...mobileStyles }}
              onChange={handleSwithTab}
              aria-label="Information tabs"
            >
              <TabEx label="Details" value="1" />
              <TabEx label="Rentals" value="2" />
              <TabEx label="Notes" value="3" />
              <TabEx label="Balances" value="4" />
              {/* <TabEx label="Vouchers" value="5" /> */}
              <TabEx label="Payment methods" value="6" />
              <TabEx label="Transactions" value="8" />
            </TabList>
          </AtLocator>
        }
      >
        <TabPanel value="1" sx={styles.panel}>
          <Details />
        </TabPanel>
        <TabPanel value="2" sx={styles.panel}>
          <CustomerRentals />
        </TabPanel>
        <TabPanel value="3" sx={styles.panel}>
          <CustomerNotes />
        </TabPanel>
        <TabPanel value="4" sx={styles.panel}>
          <Balances />
        </TabPanel>
        <TabPanel value="6" sx={styles.panel}>
          <PaymentMethods />
        </TabPanel>
        <TabPanel value="8" sx={styles.panel}>
          <CustomerTransactions />
        </TabPanel>
      </TabsDrawerLayout>
    </TabContext>
  );
};
