/* eslint-disable object-shorthand */
import { ObjectSchema, object, number } from 'yup';

import { useMemo } from 'react';
import { SetupPricingFormStateDTO } from '@Forms/pricing/PricingDrawer/SetupPricingFormContext';

export const useSetupPricingUseCaseSchema = (): ObjectSchema<Partial<SetupPricingFormStateDTO>> => {
  return useMemo(
    () =>
      object<SetupPricingFormStateDTO>().shape({
        unlockPrice: number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required('Unlock price is required')
          .nullable(),
        minutePrice: number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required('Minute price is required')
          .nullable(),
        parkingPrice: number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required('Parking price is required')
          .nullable(),
      }),
    []
  );
};
