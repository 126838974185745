import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const transactionListColumns = [
  'transaction._id',
  'transaction.freebetUsageLogId',
  'transaction.freebetUsageRecordId',
  'transaction.playerId',
  'transaction.roundId',
  'transaction.betAmount',
  'transaction.isAutoCashout',
  'transaction.maxMultiplier',
  'transaction.playerRoundBetId',
  'transaction.language',
  'transaction.currency',
  'transaction.win',
  'transaction.cashoutDate',
  'transaction.multiplier',
  'transaction.createdAt',
  'transaction.roundHash',
  'transaction.serverHash',
  'transaction.clientHash',
] as const;

export type TransactionListColumns = (typeof transactionListColumns)[number];

export type TransactionListFilter = {
  'transaction._id': string | undefined;
  'transaction.freebetUsageLogId': string | undefined;
  'transaction.freebetUsageRecordId': string | undefined;
  'transaction.playerId': string | undefined;
  'transaction.roundId': string | undefined;
  'transaction.betAmount': number | undefined;
  'transaction.isAutoCashout': boolean | undefined;
  'transaction.maxMultiplier': number | undefined;
  'transaction.playerRoundBetId': string | undefined;
  'transaction.language': string | undefined;
  'transaction.currency': string | undefined;
  'transaction.win': number | undefined;
  'transaction.multiplier': number | undefined;
  'transaction.cashoutDate': string | undefined;
  'transaction.createdAt': string | undefined;
  'transaction.roundHash': string | undefined;
  'transaction.serverHash': string | undefined;
  'transaction.clientHash': string | undefined;
};

export const defaultTransactionListFilter: TransactionListFilter = {
  'transaction._id': undefined,
  'transaction.freebetUsageLogId': undefined,
  'transaction.freebetUsageRecordId': undefined,
  'transaction.playerId': undefined,
  'transaction.roundId': undefined,
  'transaction.betAmount': undefined,
  'transaction.isAutoCashout': undefined,
  'transaction.maxMultiplier': undefined,
  'transaction.playerRoundBetId': undefined,
  'transaction.language': undefined,
  'transaction.currency': undefined,
  'transaction.win': undefined,
  'transaction.multiplier': undefined,
  'transaction.cashoutDate': undefined,
  'transaction.createdAt': undefined,
  'transaction.serverHash': undefined,
  'transaction.clientHash': undefined,
  'transaction.roundHash': undefined,
};

export const transactionListColumnVisibilitySettings = {
  'transaction._id': ColumnVisibilityType.Visible,
  'transaction.freebetUsageLogId': ColumnVisibilityType.CanBeHidden,
  'transaction.freebetUsageRecordId': ColumnVisibilityType.CanBeHidden,
  'transaction.playerId': ColumnVisibilityType.CanBeHidden,
  'transaction.roundId': ColumnVisibilityType.CanBeHidden,
  'transaction.betAmount': ColumnVisibilityType.CanBeHidden,
  'transaction.win': ColumnVisibilityType.CanBeHidden,
  'transaction.multiplier': ColumnVisibilityType.CanBeHidden,
  'transaction.cashoutDate': ColumnVisibilityType.CanBeHidden,
  'transaction.isAutoCashout': ColumnVisibilityType.CanBeHidden,
  'transaction.maxMultiplier': ColumnVisibilityType.CanBeHidden,
  'transaction.playerRoundBetId': ColumnVisibilityType.CanBeVisible,
  'transaction.language': ColumnVisibilityType.CanBeHidden,
  'transaction.currency': ColumnVisibilityType.CanBeHidden,
  'transaction.createdAt': ColumnVisibilityType.CanBeHidden,
  'transaction.roundHash': ColumnVisibilityType.CanBeVisible,
  'transaction.serverHash': ColumnVisibilityType.CanBeVisible,
  'transaction.clientHash': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<TransactionListColumns>;

export const defaultTransactionListColumnVisibility = defaultColumnVisibilityParams(
  transactionListColumnVisibilitySettings
);

export const transactionListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  transactionListColumns,
  transactionListColumnVisibilitySettings
);
