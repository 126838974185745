// System
import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Stack, Typography } from '@mui/material';

// Components
import { useEllipsis } from '@Components/shared/styles/useEllipsis';

// Features
import TruncateText from '@Features/rentals/shared/TruncateText/TruncateText';
import { useAviatorRoleDrawer } from '@Modal/hooks/aviatorRoles/useAviatorRoleDrawer';

const useSx = () => {
  return {
    id: {
      fontWeight: '700',
      fontSize: '18px',
      lineHeight: '28px',
      marginLeft: '',
    },
  } satisfies Record<string, SystemStyleObject<Theme>>;
};

export const SetupAviatorRoleDrawerHeader = () => {
  const ellipsis = useEllipsis();
  const styles = useSx();
  const { selectedAviatorRoleData } = useAviatorRoleDrawer();

  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Stack spacing={1} direction="row" sx={{ ...ellipsis }}>
        <Typography variant="h6">Role:</Typography>{' '}
        <TruncateText text={selectedAviatorRoleData.id} maxLength={8} textStyles={styles.id} />
      </Stack>
    </Stack>
  );
};
