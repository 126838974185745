import { ColumnVisibilitySettings } from '@contracts/common/ColumnVisibilitySettings';
import { ColumnVisibilityType } from '@contracts/common/ColumnVisibilityType';
import {
  defaultColumnVisibilityOptions,
  defaultColumnVisibilityParams,
} from '@contracts/common/params/ColumnVisibilityParams';

export const freebetListColumns = [
  'freebet._id',
  'freebet.campaignId',
  'freebet.requestId',
  'freebet.providerId',
  'freebet.playerId',
  'freebet.usage',
  'freebet.totalWin',
  'freebet.validityDate',
  'freebet.description',
  'freebet.count',
  'freebet.minMultiplier',
  'freebet.betNominal',
  'freebet.status',
  'freebet.createdAt',
] as const;

export type FreebetListColumns = (typeof freebetListColumns)[number];

export type FreebetListFilter = {
  'freebet._id': string | undefined;
  'freebet.campaignId': string | undefined;
  'freebet.requestId': string | undefined;
  'freebet.providerId': string | undefined;
  'freebet.playerId': string | undefined;
  'freebet.usage': number | undefined;
  'freebet.totalWin': number | undefined;
  'freebet.validityDate': string | undefined;
  'freebet.description': string | undefined;
  'freebet.count': number | undefined;
  'freebet.minMultiplier': number | undefined;
  'freebet.betNominal': number | undefined;
  'freebet.status': string | undefined;
  'freebet.createdAt': string | undefined;
};

export const defaultFreebetListFilter: FreebetListFilter = {
  'freebet._id': undefined,
  'freebet.campaignId': undefined,
  'freebet.requestId': undefined,
  'freebet.providerId': undefined,
  'freebet.playerId': undefined,
  'freebet.usage': undefined,
  'freebet.totalWin': undefined,
  'freebet.validityDate': undefined,
  'freebet.description': undefined,
  'freebet.count': undefined,
  'freebet.minMultiplier': undefined,
  'freebet.betNominal': undefined,
  'freebet.status': undefined,
  'freebet.createdAt': undefined,
};

export const freebetListColumnVisibilitySettings = {
  'freebet._id': ColumnVisibilityType.Visible,
  'freebet.campaignId': ColumnVisibilityType.CanBeHidden,
  'freebet.requestId': ColumnVisibilityType.CanBeHidden,
  'freebet.providerId': ColumnVisibilityType.CanBeHidden,
  'freebet.playerId': ColumnVisibilityType.CanBeHidden,
  'freebet.usage': ColumnVisibilityType.CanBeHidden,
  'freebet.totalWin': ColumnVisibilityType.CanBeHidden,
  'freebet.validityDate': ColumnVisibilityType.CanBeHidden,
  'freebet.description': ColumnVisibilityType.CanBeVisible,
  'freebet.count': ColumnVisibilityType.CanBeVisible,
  'freebet.minMultiplier': ColumnVisibilityType.CanBeVisible,
  'freebet.betNominal': ColumnVisibilityType.CanBeVisible,
  'freebet.status': ColumnVisibilityType.CanBeVisible,
  'freebet.createdAt': ColumnVisibilityType.CanBeVisible,
} satisfies ColumnVisibilitySettings<FreebetListColumns>;

export const defaultFreebetListColumnVisibility = defaultColumnVisibilityParams(
  freebetListColumnVisibilitySettings
);

export const freebetListColumnVisibilityOptions = defaultColumnVisibilityOptions(
  freebetListColumns,
  freebetListColumnVisibilitySettings
);
