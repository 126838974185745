import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';

// System
import { Stack, Typography, FormControl, MenuItem, Button } from '@mui/material';
import Select from '@mui/material/Select';

// Redux
import { useCustomerBalance } from '@Modal/hooks/customers/balance/useCustomerBalance';
import { useDispatch } from 'react-redux';

export const BalanceCardSelect = () => {
  const dispatch = useDispatch();
  const { control, setValue } = useForm();
  const { handleManageCredits, customerWallets, handleCustomerSettleBalance, setSelectedWallet } =
    useCustomerBalance();

  useEffect(() => {
    const wallets = customerWallets ?? [];
    if (wallets?.length > 0) {
      setValue('balanceCardSelect', wallets[0].id);
    } else {
      setValue('balanceCardSelect', '');
    }
  }, [customerWallets, setValue, handleCustomerSettleBalance]);

  return (
    <Stack direction="column" spacing={2}>
      <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: '600', color: 'black' }}>
        Balances
      </Typography>
      <FormControl>
        <Controller
          name="balanceCardSelect"
          control={control}
          defaultValue={
            customerWallets && customerWallets?.length > 0 ? `${customerWallets[0]?.name}` : ''
          }
          render={({ field }) => (
            <Select
              {...field}
              id="setup-branch-name"
              name="parentId"
              variant="outlined"
              sx={{ '& .MuiInputLabel-root': { display: 'none' }, color: 'rgb(99, 115, 129)' }}
            >
              {(customerWallets ?? []).map((wallet) => (
                <MenuItem
                  key={wallet?.id}
                  value={wallet?.id}
                  onClick={() => dispatch(setSelectedWallet(wallet))}
                >
                  {wallet?.name} / {wallet?.balance} {wallet?.currency}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <Stack direction="row" sx={{ justifyContent: 'start', alignItems: 'center' }} spacing={2}>
        <Button
          variant="outlined"
          sx={{ color: 'rgb(49, 134, 136)' }}
          onClick={handleManageCredits}
        >
          Manage Credits
        </Button>
        <Button
          variant="outlined"
          disabled={customerWallets && parseFloat(customerWallets[0]?.balance) >= 0}
          sx={{
            borderColor: 'rgba(145, 158, 171, 0.32)',
            color: 'rgb(33, 43, 54)',
            '&:hover': { borderColor: 'rgba(145, 158, 171, 0.32)' },
          }}
          onClick={handleCustomerSettleBalance}
        >
          Settle Balance
        </Button>
      </Stack>
    </Stack>
  );
};
