export const DateGeneratorForAnalyticsTable = () => {
  const startDate = () => {
    const date = new Date();
    date.setDate(1);
    date.setHours(23, 59, 59, 0);
    return date.toISOString();
  };

  const endDate = () => {
    const date = new Date();
    date.setHours(23, 59, 59, 0);
    return date.toISOString();
  };

  return {
    startDate: startDate(),
    endDate: endDate(),
  };
};

export const formatDateForChart = (date: string) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
