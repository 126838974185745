import { ObjectSchema, object, string } from 'yup';
import { noop } from '@utils/noop';
import { Dispatch, createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type CreateAviatorUserFormState = {
  email: string;
  password: string;
  providerId: string;
  roleIds: string[] | string;
};

export const createAviatorUserFormStateDefault: CreateAviatorUserFormState = {
  email: '',
  password: '',
  providerId: '',
  roleIds: '',
};

export const createAviatorUserSchemaDefault: ObjectSchema<Partial<CreateAviatorUserFormState>> =
  object<Partial<CreateAviatorUserFormState>>().shape({
    email: string().email().required('email is required field'),
    password: string().required('password win is required field'),
    providerId: string().required('provider id is required field'),
    roleIds: string().required('roleIds is required field'),
  });

type CreateAviatorUserFormContextType = {
  schema: ObjectSchema<Partial<CreateAviatorUserFormState>>;
  setSchema: Dispatch<ObjectSchema<Partial<CreateAviatorUserFormState>>>;
  methods?: UseFormReturn<CreateAviatorUserFormState>;
};

const createAviatorUserFormContextDefault = {
  schema: createAviatorUserSchemaDefault,
  setSchema: noop,
} satisfies CreateAviatorUserFormContextType;

export const CreateAviatorUserFormContext = createContext<CreateAviatorUserFormContextType>(
  createAviatorUserFormContextDefault
);

export const useCreateAviatorUserForm = () => {
  const { methods } = useContext(CreateAviatorUserFormContext);
  if (!methods) {
    throw new Error('CreateAviatorUserFormContext is empty');
  }

  return methods;
};

export const useManageCreateAviatorUserSchema = () => {
  const { setSchema } = useContext(CreateAviatorUserFormContext);

  return { setSchema };
};
